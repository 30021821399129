import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import MainNavigation from "../Navigation/MainNavigation";
import Modal from "../commons/wrapped-components/Modal";
import { translate } from "../../redux/reducers/languageReducer";
import * as roles from "../../constants/roles";
import { getRoleById } from "../../general/general";
import { general } from "../../general/global";
import AddMemberPopUP from "./AddMemberPopUP";
import {
  updateRemoveUserformSpace,
  removeMemberSubWorkspace,
  removeMemberWorkspaceAPI,
  removeMemberWorkspace,
  getUserListBySubspaceId,
  showMoreSubspaceUsers,
  resetSubspaceUserList
} from "../../redux/actions/workspaceActions";
import { showToast } from "../commons/Toast";
import * as toastType from "../../constants/toastTypes";
import { ShowMoreButton } from "./ShowMoreButton";
import InputSearch from "../commons/wrapped-components/inputs/InputSearch";
import { CreateButton } from "./CreateButton";

const SubWorkspaceListAddmember = props => {

  if (props.subspaceUsers === undefined || !props.subspaceUsers?.users) {
    props.history.push("/exit");
  }

  const [showAddMembers, setShowAddMembers] = useState(false);
  const [userNameDelete, setUserNameDelete] = useState("");
  const [showDeleteMember, setShowDeleteMember] = useState(false);
  const [userIDDelete, setUserIDDelete] = useState("");
  const [searchValue, setSearchValue] = useState('');
  const [existActivePass, setExistActivePass] = useState(false);

  const { users, hasNextPage, limit, totalCount, totalPages } = props.subspaceUsers;

  const loadData = async () => {
    await props.onGetUserListBySubspaceId('');
  }

  useEffect(() => {
    setSearchValue('');
    loadData();
    return () => {
      props.onResetSubspaceUserList();
    }
  }, [props.subspaceFocused.usersCount, props.workspaces]);

  const renderModalDelete = () => (
    <Modal
      title={`${props.dictionary.s("remove")} ${userNameDelete}?`}
      dialogClassName="safely-modal renderModalCreatSubSpace"
      showModal={showDeleteMember}
      onHide={() => {
        setShowDeleteMember(false);
        setExistActivePass(false);
      }}
      onSave={async () => {
        let response = {};
        const newWorkSpacesProps = JSON.parse(JSON.stringify(props.workSpacesProps));
        if (props.spaceFocused.defaultSubspaceId === props.subspaceFocused._id) {
          response = await props.onRemoveMemberWorkspaceAPI(userIDDelete, props.workSpaceId, existActivePass);
          if (response.status === false && response.systemError === "Safely pass is active")
            setExistActivePass(true);
          if (response && response.status) {
            newWorkSpacesProps.workspaceFocused.users = newWorkSpacesProps.workspaceFocused.users.filter(u => u.id !== userIDDelete);
            newWorkSpacesProps.workspaceFocused.subspaces = response.data.subspaces;
            await props.onGetUserListBySubspaceId('');
          }
        } else {
          response = await props.onRemoveMemberSubWorkspace(userIDDelete, props.workSpaceId, props.subspaceFocused._id);
          if (response && response.status) {
            newWorkSpacesProps.workspaceFocused.subspaces = response.data.subspaces;
            await props.onGetUserListBySubspaceId('');
          }
        }

        if (response && response.status) {
          await props.onUpdateRemoveUserformSpace(userIDDelete, props.workSpaceId, props.subspaceFocused._id, newWorkSpacesProps);
          showToast(toastType.SUCCESS, props.dictionary.s('userDeleted'));
          setShowDeleteMember(false);
        }
      }}
      onClose={() => {
        setShowDeleteMember(false);
        setExistActivePass(false);
      }}
      textButtonSave={props.loading ? "..." : props.dictionary.s("removebutton")}
      textButtonClose={props.dictionary.s("cancel")}
      propertyButtonSave={{
        block: false,
        style: {
          backgroundColor: '#E67070',
          color: general.theme.whiteFontColor,
          border: `1px solid #E67070`
        }
      }}
      bsStyleButtonSave="secondary"
      buttonCloseProperties={{
        block: false,
        style: {
          backgroundColor: general.theme.whiteColor,
          border: `1px solid ${general.theme.primaryColor}`,
          color: general.theme.primaryColor
        }
      }}
      properties={{ centered: true }}
      body={
        <div className="removeText">
          {
            existActivePass ?
              <>
                <span>{`${userNameDelete} `}</span>{props.dictionary.s("messageUserHasActivedPass")}
                <span>{` ${props.subspaceFocused.name.replace(/\s?\(([A-Za-z])efault\)/, '')}. `}</span>{props.dictionary.s("areYouSureWantToContinue")}
              </> :
              props.spaceFocused.defaultSubspaceId === props.subspaceFocused._id ?
                <>
                  {props.dictionary.s("youAreAboutToRemove")}<span>{` ${userNameDelete} `}</span>
                  {props.dictionary.s("fromSpace")}<span>{` ${props.subspaceFocused.name.replace(/\s?\(([A-Za-z])efault\)/, '')}. `}</span>
                  {props.dictionary.s("areYouSureWantToContinue")}
                </> :
                <>
                  {props.dictionary.s("areyousureyouneedremove")}<span>{` ${userNameDelete} `}</span>
                  {props.dictionary.s("fromSubspace")}<span>{` ${props.subspaceFocused.name.replace(/\s?\(([A-Za-z])efault\)/, '')}?`}</span>
                </>
          }
        </div>}
      isLoadingButton={props.loading}
    />
  );
  const closeAddmember = () => {
    setShowAddMembers(false);
  };
  const getMemberList = memberData => {
    return (
      <div className="subspace-member-block">
        <div className="subspace-member-image">
          <img src={memberData.picture} />
        </div>
        <div className="subspace-member-name">
          {memberData.name + ' '}  {memberData.lastName}
          <div className="subspace-member-role">
            {props.dictionary.s(getRoleById(memberData.roleId))}
          </div>
          {
            (props.spaceFocused.roleId === roles.ADMIN) &&
            (props.spaceFocused.spaceOwner !== memberData.id) &&
            (props.subspaceFocused.subspaceOwner !== memberData.id) &&
            (
              <span
                className="closeMember"
                onClick={() => {
                  setUserNameDelete(memberData.name + ' ' + memberData.lastName);
                  setUserIDDelete(memberData.id)
                  setShowDeleteMember(true);
                }}
              >
                <i className="fa fa-times" aria-hidden="true"></i>
              </span>
            )
          }
        </div>
      </div>
    );
  };

  const handleSearchSubspaceUsers = async (value) => {
    setSearchValue(value);
    await props.onGetUserListBySubspaceId(value);
  }

  const handleShowMoreSubspaceUsers = async () => {
    await props.onShowMoreSubspaceUsers();
  }

  const onAddMembers = () => {
    setShowAddMembers(true);
    if (searchValue !== '')
      handleSearchSubspaceUsers('');
  }

  return (
    <MainNavigation title={props.title}>
      <Container fluid>
        <Row>
          {renderModalDelete()}
          <AddMemberPopUP
            showAddMembers={showAddMembers}
            closeAddmember={closeAddmember}
            type="addMember"
          />
        </Row>
        <Row className="space-header space-header-addmember">
          <Col xs={12} className="header-title header-subtitle">
            <div className="subtitle-header">
              <h4 className='pb-2'>{props.subspaceFocused.name}</h4>
              <span>
                {props.subspaceUsers?.users
                  ? props.subspaceUsers?.users.length
                  : "0"}{" "}
                {props.subspaceUsers?.users
                  ? `${props.subspaceUsers?.users.length <= 1
                    ? props.dictionary.s("member")
                    : props.dictionary.s("members")
                  }`
                  : props.dictionary.s("member")}
              </span>
            </div>
          </Col>
          <Col xs={12} className="header-title">
            <h4>{props.dictionary.s("members")}</h4>
          </Col>
          <Col xs={12} sm={6}
            className="input-search-container searchSpace"
          >
            <InputSearch
              placeholder={props.dictionary.s("searchMember")}
              searchValue={searchValue}
              onChangeText={setSearchValue}
              handleSearch={handleSearchSubspaceUsers}
            />
          </Col>
          {(props.spaceFocused.roleId === roles.ADMIN) &&
            (props.spaceFocused.defaultSubspaceId !== props.subspaceFocused._id) && (
              <Col
                xs={12}
                sm={6}
                className="header-cta-wrapper d-flex justify-content-end header-cta-wrapper-addmember"
              >
                <CreateButton
                  dictionary={props.dictionary}
                  onClick={onAddMembers}
                  text='addMembers'
                />
              </Col>
            )}
        </Row>
        <Row className="sub-space-wrapper">
          {users.length > 0 ?
            users.map((item, index) => {
              return (
                <Col xs={12} md={4} sm={12} lg={3} key={index}>
                  {getMemberList(item)}
                </Col>
              );
            })
            : (
              <Col xs={12} className="noSubspacesFound text-center p-4">
                {
                  users.length === 0 && props.loading ?
                    props.dictionary.s("loading") + '...'
                    : users.length === 0 && searchValue !== '' && !props.loading &&
                    props.dictionary.s("noSubspacesFound")
                }
              </Col>
            )}
          <ShowMoreButton
            handleShowMore={handleShowMoreSubspaceUsers}
            dictionary={props.dictionary}
            hasNextPage={hasNextPage}
            totalPages={totalPages}
          />
        </Row>
      </Container >
    </MainNavigation >
  );
};

const mapStateToProps = state => {
  const user = state.user;
  return {
    userId: user.id,
    workspaces: state.workspace.spacesListWithPagination.spaces,
    workSpacesProps: state.workspace,
    workSpaceId: state.workspace.spaceFocused._id,
    accesstokenidp: state.user.accesstokenidp,
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    },
    subspaceUsers: state.workspace.subspaceUsers,
    loading: state.workspace.loading,
    spaceFocused: state.workspace.spaceFocused,
    subspaceFocused: state.workspace.subspaceFocused
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateRemoveUserformSpace: (userIDDelete, workSpaceId, subSpaceId, workSpacesProps) =>
    dispatch(updateRemoveUserformSpace(userIDDelete, workSpaceId, subSpaceId, workSpacesProps)),
  onRemoveMemberSubWorkspace: (userIds, workSpaceId, subspaceId) =>
    dispatch(removeMemberSubWorkspace(userIds, workSpaceId, subspaceId)),
  onRemoveMemberWorkspaceAPI: (userId, workSpaceId, checkout) =>
    dispatch(removeMemberWorkspaceAPI(userId, workSpaceId, checkout)),
  onRemoveMemberWorkspace: (userId, workSpaceId) =>
    dispatch(removeMemberWorkspace(userId, workSpaceId)),
  onGetUserListBySubspaceId: (name) =>
    dispatch(getUserListBySubspaceId(name)),
  onShowMoreSubspaceUsers: () => dispatch(showMoreSubspaceUsers()),
  onResetSubspaceUserList: () => dispatch(resetSubspaceUserList())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubWorkspaceListAddmember);