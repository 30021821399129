import _themes from "../themes";
import * as roles from "../constants/roles";
export var theme = _themes.greenTheme;

export const getRoleId = (workspace, userId) => {
  let roleId = roles.USER;
  if (workspace.users) {
    const user = workspace.users.find(x => x.id === userId);
    if (user) roleId = user.roleId;
  }
  return roleId;
};

export const getDatesByPeriod = period => {
  let day = new Date().getDate();
  let year = new Date().getFullYear();
  let month = new Date().getMonth();
  let startdate = new Date(year, month, day, 0, 0, 0);
  let enddate = new Date(year, month, day, 23, 59, 59);

  switch (period) {
    case "today":
      return { startDate: startdate, endDate: new Date() };
    case "yesterday":
      return {
        startDate: new Date(startdate.setDate(startdate.getDate() - 1)),
        endDate: new Date(enddate.setDate(enddate.getDate() - 1))
      };
    case "currentWeek":
      return {
        startDate: new Date(
          startdate.setDate(startdate.getDate() - startdate.getDay())
        ),
        endDate: new Date()
      };
    // enddate.setDate(enddate.getDate() - enddate.getDay()+6
    case "lastWeek":
      return {
        startDate: new Date(
          startdate.setDate(startdate.getDate() - startdate.getDay() - 7)
        ),
        endDate: new Date(
          enddate.setDate(enddate.getDate() - enddate.getDay() - 1)
        )
      };
    case "currentMonth":
      return {
        startDate: new Date(startdate.getFullYear(), startdate.getMonth(), 1),
        endDate: new Date()
      };
    case "lastMonth":
      return {
        startDate: new Date(
          startdate.getFullYear(),
          startdate.getMonth() - 1,
          1
        ),
        endDate: new Date(
          enddate.getFullYear(),
          enddate.getMonth(),
          0,
          23,
          59,
          59
        )
      };
    case "currentYear":
      return {
        startDate: new Date(startdate.getFullYear(), 0, 1),
        endDate: new Date()
      };
    case "lastYear":
      return {
        startDate: new Date(startdate.getFullYear() - 1, 0, 1),
        endDate: new Date(new Date().getFullYear() - 1, 11, 31, 23, 59, 59)
      };
    case "all":
      return { startDate: "", endDate: "" };
    case "custom":
      return { startDate: "", endDate: new Date() };
    default:
      return { startDate: enddate, endDate: enddate };
  }
};

// function to return role in text form from role ID
export const getRoleById = roleId => {
  switch (roleId) {
    case "0":
      return "Admin";
    case "1":
      return "Monitor";
    case "2":
      return "User";
    default:
      return "Visitor";
  }
};

export const str_truncate = (str, length, ending) => {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export const hashUrl = url => {
  var search = url.split("#").pop();
  return JSON.parse(
    '{"' +
    decodeURI(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"') +
    '"}'
  );
};

//mobile detect
const IsChromeAndroid = () => {
  const ua = navigator.userAgent;
  if (/Linux|Android/i.test(ua) && /Chrome/i.test(ua)) return true;

  return false;
};

const IsSafari = () => {
  const ua = navigator.userAgent;
  if (/webOS|iPhone|iPad|iPod|Mac OS/i.test(ua) && /Safari/i.test(ua))
    return true;

  return false;
};

export const isSafari = IsSafari();
export const isChromeAndroid = IsChromeAndroid();

export const fileClone = (file, name, type) => {
  return new File([file], name, { type: type || file.type });
};

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

export async function dataURLtoFile(dataUrl, filename, mime) {
  const response = await fetch(dataUrl);
  const blob = await response.blob();
  return fileClone(blob, filename, mime);
}

export async function getBase64(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
}

export async function getBase64Png(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file, "image/png");
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
}
const formatNumber = number => `0${number}`.slice(-2);

export const getRemaining = time => {
  const hours = Math.floor(time / 3600);
  const mins = Math.floor((time - hours * 3600) / 60);
  const secs = time - mins * 60;
  return {
    hours: formatNumber(hours),
    mins: formatNumber(mins),
    secs: formatNumber(secs)
  };
};

export const diffSeconds = (startTime, endTime = new Date()) => {
  const diff = Math.abs(endTime.getTime() - new Date(startTime).getTime());

  return Math.round(diff / 1000);
};

export const isAdminOrMonitor = (userId, workSpace) => {
  if (!workSpace) return false;
  const user = workSpace.users.find(userData => userData.id === userId);
  return user.roleId === roles.ADMIN || user.roleId === roles.MONITOR;
};

export const filterWorkspaceUserIsAdmin = (userId, workspaceList) => {
  if (!workspaceList) return [];
  const workspacesFiltered = [];
  workspaceList.forEach((workspace, index) => {
    if (workspace.users.find(x => x.id === userId && x.roleId === "0")) {
      workspacesFiltered.push(workspace);
    }
  });
  return workspacesFiltered;
};

export const replaceDiacritics = (_text) => {
  return _text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
};

//====Use this function to filter data with accents characters
export const filteredData = (input, data = [], searchProperty) => {
  if (!data.length) return;

  let SortData = data.sort(function (a, b) {
    var nameA = a[searchProperty].toUpperCase();
    var nameB = b[searchProperty].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  let filteredInput = SortData;
  if (input && input.length > 0) {
    const newInput = replaceDiacritics(input.toLowerCase());
    filteredInput = SortData.filter((d) =>
      replaceDiacritics(d[searchProperty].toLowerCase()).includes(newInput)
    );
  }
  return filteredInput;
};

export const copyObject = (_obj) => JSON.parse(JSON.stringify(_obj));