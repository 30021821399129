

import React, { useEffect,useState } from "react";
import "../../styles/workspace.css";
import { connect } from "react-redux";
import Button from "../commons/styled-components/general/Button";
import { translate } from "../../redux/reducers/languageReducer";
import Switch from "../commons/wrapped-components/inputs/Switch";
import { general } from "../../general/global";
import ColorPicker from "../commons/wrapped-components/ColorPicker";
import { addWorkspaceSettings } from "../../redux/actions/workspaceActions";
import { Row, Col } from "react-bootstrap";
import { ReactComponent as AddUserIcon } from "../../icons/Icon_AddUser.svg";
import { ReactComponent as SelfFromIcon } from "../../icons/Icon_Form.svg";
import { ReactComponent as VerificationFromIcon } from "../../icons/Icon_Verification.svg";
import { ReactComponent as ColorIcon } from "../../icons/Icon_Colorr.svg";
import { ReactComponent as AddUsersIcon } from "../../icons/Icon_AddUserr_White.svg";
import { withRouter } from "react-router-dom";
import { toastSuccess, toastDismissAll } from "../commons/Toast";
import { updateWorkSpacePreferences } from "../../redux/actions/workspaceActions";
import { changeSafelyPassStatus } from "../../redux/actions/operationActions";

const WorkspacePreferences = props => {
  const [selfDeclarationValue, setSelfDeclarationValue] = useState(
    props.workspaceFocused.settings.selfDeclaration
      ? props.workspaceFocused.settings.selfDeclaration
      : false
  );
  const [verificationPointValue, setVerificationPointValue] = useState(
    props.workspaceFocused.settings.verificationPoint
      ? props.workspaceFocused.settings.verificationPoint
      : false
  );
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(
    props.workspaceFocused.settings.backgroundColor
      ? props.workspaceFocused.settings.backgroundColor
      : "#C3DF9A"
  );
  const handleClick = () => setDisplayColorPicker(!displayColorPicker);
  const handleClose = () => setDisplayColorPicker(false);
  const onChangeColor = getColor => setColor(getColor.hex);
  const [clickSaveButton, setClickSaveButton] = useState(false);
  useEffect(() => {
    setSelfDeclarationValue(
      props.workspaceFocused.settings.selfDeclaration
        ? props.workspaceFocused.settings.selfDeclaration
        : false
    );
    setColor(
      props.workspaceFocused.settings.backgroundColor
        ? props.workspaceFocused.settings.backgroundColor
        : "#C3DF9A"
    );
    setVerificationPointValue(
      props.workspaceFocused.settings.verificationPoint
        ? props.workspaceFocused.settings.verificationPoint
        : false
    );

  }, [props.workspaceFocused.settings]);

  return (
    <div>
      <div className="workspace-preferencess-title">
        {props.dictionary.s("preferences")}
      </div>
      <Row>
        <Col
          xs={8}
          className="workspace-preferencess-text-block"
        >
          <AddUserIcon />
          {props.dictionary.s("inviteAUserToMyWorkspace")}
        </Col>
        <Col
          xs={4}
          className="workspace-preferencess-icon-block"
        >
          <div
            className="space-name-edit-cricle dispalyLine"
            onClick={() => props.history.push("/workspace-configuration")}
          >
            <AddUsersIcon className="sentinvetationspace" />
          </div>
        </Col>
        <Col
          xs={8}
          className="workspace-preferencess-text-block"
        >
          <SelfFromIcon />
          {props.dictionary.s("selfDeclarationFrom")}
          <i
            className="fa fa-pencil workspace-form-eidt"
            onClick={() => props.history.push("/editVerification")}
          />
        </Col>
        <Col
          xs={4}
          className="workspace-preferencess-icon-block"
        >
          <Switch
            id={`switch_self_declaration`}
            isOn={selfDeclarationValue}
            onChange={() => setSelfDeclarationValue(!selfDeclarationValue)}
            onColor={general.theme.primaryColor}
            offColor="#808080"
            width={38}
            height={22}
            handleDiameter={17}
          />
        </Col>
        <Col
          xs={8}
          className="workspace-preferencess-text-block"
        >
          <VerificationFromIcon />
          {props.dictionary.s("verificationForm")}
          <i
            className="fa fa-pencil workspace-form-eidt"
            onClick={() => props.history.push("/editcheckpoint")}
          />
        </Col>
        <Col
          xs={4}
          className="workspace-preferencess-icon-block"
        >
          <Switch
            id={`verification_point`}
            isOn={verificationPointValue}
            onChange={() => setVerificationPointValue(!verificationPointValue)}
            onColor={general.theme.primaryColor}
            offColor="#808080"
            width={38}
            height={22}
            handleDiameter={17}
          />
        </Col>
        <Col
          xs={8}
          className="workspace-preferencess-text-block"
        >
          <ColorIcon />
          {props.dictionary.s("spaceColor")}
        </Col>
        <Col
          xs={4}
          className="workspace-preferencess-icon-block"
        >
          <div className="space-colorPicker">
            <ColorPicker
              handleClick={handleClick}
              handleClose={handleClose}
              onChangeColor={onChangeColor}
              displayColorPicker={displayColorPicker}
              color={color}
              width={25}
              height={25}
              borderRadius="5px"
              marginRight="5px"
            />
          </div>
        </Col>
        <Col
          xs={12}
          className="workspace-preferencess-save-button"
        >
          <Button
            className="nextButton"
            width="150px"
            bgcolor="#665DFF"
            borderradius="4px"
            onClick={async () => {
              toastDismissAll();
              if (!clickSaveButton) {
                setClickSaveButton(true);
                const workspaceId = props.workspaceFocused._id;
                const selfDeclaration = selfDeclarationValue;
                const verificationPoint = verificationPointValue;
                const location = props.workspaceFocused.settings.locationIsRequired;
                const colorHex = color;

                if (!props.isSafelyPassActivated) {
                  if (
                    props.workspaceFocusedForSafelyPass._id ===
                    props.workspaceFocused._id
                  ) {
                    props.onChangeSafelyPassStatus("inactive");
                  }
                }
                await props
                  .onAddWorkspaceSettings(
                    workspaceId,
                    selfDeclaration,
                    verificationPoint,
                    location,
                    colorHex,
                    props.accesstokenidp
                  )
                  .then(responceAddSpace => {
                    props.onUpdateWorkSpacePreferences(responceAddSpace.data,props.workSpacesProps);
                    setClickSaveButton(false);
                    toastSuccess(props.dictionary.s("workspaceSettingsUpdateMessage"));
                  });
              }
            }}
          >
            {clickSaveButton ? props.dictionary.s("loading") + ' ...' : props.dictionary.s("saveChanges")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => {
  const { updatingWorkspace } = state.workspace;
  return {
    updatingWorkspace,
    locationServiceWhileUsing: state.onboarding.locationServiceWhileUsing,
    user: state.user,
    userId: state.user.id,
    workSpacesProps:state.workspace,
    accesstokenidp: state.user.accesstokenidp,
    password: state.onboarding.password,
    accessToken: state.user.accesstoken,
    connectionId: state.user.connectionId,
    workspaceCreated: state.workspace.workspaceCreated,
    lang: state.language.lang,
    workspaceFocused: state.workspace.spaceFocused,
    isSafelyPassActivated: state.operation.safelyPassStatus === "granted",
    workspaceFocusedForSafelyPass:
      state.workspace.workspaceFocusedForSafelyPass,
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    }
  };
};

const mapDispatchToProps = dispatch => ({
  onAddWorkspaceSettings: (
    workspaceId,
    selfDeclaration,
    verificationPoint,
    locationIsRequired,
    backgroundColor,
    accesstokenidp
  ) =>
    dispatch(
      addWorkspaceSettings(
        workspaceId,
        selfDeclaration,
        verificationPoint,
        locationIsRequired,
        backgroundColor,
        accesstokenidp
      )
    ),
    onUpdateWorkSpacePreferences: (data, workSpacesProps) =>
    dispatch(updateWorkSpacePreferences(data, workSpacesProps)),
    onChangeSafelyPassStatus: status => dispatch(changeSafelyPassStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WorkspacePreferences));