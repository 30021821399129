import React from 'react';
import InputButtonGroup from './InputButtonGroup';
import InputSelect from './InputSelect';
import '../../../../styles/inputs/Inputs.css';
import * as inputTypes from "../../../../constants/inputTypes";

export const validate = (inputList) => {
    const inputs = inputList.filter(i => i.type === inputTypes.DROPDOWN);

    if (inputs.length) {
        if (inputs.find(x => x.content?.options?.find(y => y.value === ''))) return 'addOptionLabel';
        else if (inputs.find(x => x.content?.options?.find(y => y.isInvalidWord === true))) return 'sameLabelDescription';
        else if (!inputs.find(x => x.content?.options?.find(y => y.denyAccess === false))) return 'GrantSafelyPassError';
    }

    return '';
};

const DropDownList = ({ id, label, value, options, onChangeOptions, onChangeText, onValueChange, dictionary, isExecution, required }) => {

    if (isExecution)
        return (
            <InputSelect
                className="dropdown-list"
                label={label}
                value={options.find(o => o.value === value)}
                searchable={true}
                options={options}
                onChange={(op) => onValueChange(id, op.value)}
                valueKey="id"
                labelKey="value"
                clearable={false}
                required={required}
            />
        )
    else
        return (
            <InputButtonGroup
                id={id}
                inputLabel={label}
                buttonList={options}
                setButtonList={onChangeOptions}
                onChangeText={onChangeText}
                dictionary={dictionary}
                minimumItemsTextError='minimumOptionValidation'
                optionsTitleText='optionsAndActions'
                addAnotherItemText='addAnotherOption'
                itemLabelExistsTextError='thisOptionLabelAlreadyExists'
                addItemLabelText='addOptionLabel'
                characterLimit={false}
            />
        )
}

export default DropDownList;