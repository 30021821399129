import { initializedFirebase } from "./initializeFirebaseApp";
import fcmConfig from "./firebase.fcm.credentials";
import firebase from "firebase";

let instance = null;
export default class Notifications {
    constructor() {
        if (instance) {
            return instance;
        }

        instance = this;

        this.initializedFirebaseApp = null;
        this.messaging = null;

        if (firebase.messaging.isSupported() && Notification.permission !== 'denied') {
            this.initializedFirebaseApp = initializedFirebase;
            this.messaging = this.initializedFirebaseApp.messaging();
            this.messaging.usePublicVapidKey(fcmConfig.pairKey);
        }
    }

    isSupported() {
        if (firebase.messaging.isSupported() && Notification.permission !== 'denied') {
            return true;
        }
        return false ;
    }



    async getToken() {
        let fcmToken = localStorage.getItem('fcmToken');
        if (!fcmToken) {
            fcmToken = await this.messaging.getToken();
            if (fcmToken) {
                localStorage.setItem('fcmToken', fcmToken);
            }
        }

        return fcmToken;
    }

    async requestPermission() {
        try {
            await this.messaging.requestPermission();
            await this.getToken();
        } catch (error) {
            console.log('request permission with erros', error);
        }
    }

    createNotificationListeners(onNotification = () => { }) {
        const unsubscribe = this.messaging.onMessage(message => {
            onNotification(message);
        });

        return unsubscribe;
    }
}