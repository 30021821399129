import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { general } from '../../general/global';
import InputNumber from "../commons/wrapped-components/inputs/inputNumber";
import Modal from "../commons/wrapped-components/Modal";

const MaximumOccupancy = ({ title, showModalMaxOccupancy, onHideMaxOccupancy, onSaveMaxOccupancy, onCloseMaxOccupancy, setMaximumOccupancy, maximumOccupancy, loading, dictionary, errorMessage }) => {
    const renderModalUpdateMaxOccupancy = () => (
        <Modal
            title={title}
            dialogClassName="safely-modal edit-max-occupancy"
            showModal={showModalMaxOccupancy}
            onHide={() => { onHideMaxOccupancy(); }}
            onSave={() => { onSaveMaxOccupancy(); }}
            onClose={() => { onCloseMaxOccupancy(); }}
            textButtonSave={loading ? "..." : dictionary.s("updateMaximumOccupancy")}
            textButtonClose={dictionary.s("cancel")}
            propertyButtonSave={{
                block: false,
                style: {
                    backgroundColor: general.theme.primaryColor,
                    color: general.theme.whiteFontColor,
                    border: `1px solid ${general.theme.primaryColor}`
                }
            }}
            bsStyleButtonSave="secondary"
            buttonCloseProperties={{
                block: false,
                style: {
                    backgroundColor: general.theme.whiteColor,
                    border: `1px solid ${general.theme.primaryColor}`,
                    color: general.theme.primaryColor
                }
            }}
            properties={{
                centered: true
            }}
            body={
                <>
                    <InputNumber
                        label={dictionary.s('maximumOcuppancy')}
                        labelClassName="create-subspace-title mb-0"
                        inputClassName="form-control"
                        onChangeText={(value) => setMaximumOccupancy(value)}
                        value={maximumOccupancy}
                        min="0"
                        onKeyPress={event => {
                            event = (event) ? event : window.event;
                            var charCode = (event.which) ? event.which : event.keyCode;
                            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                                event.preventDefault();
                                return false;
                            }
                            return true;
                        }}
                    />

                    {(errorMessage) && (
                        <div
                            className="label-div"
                            style={{
                                marginHorizontal: 20,
                                marginVertical: 10,
                                textAlign: "center",
                                color: general.theme.redColor
                            }}
                        >
                            <label>{errorMessage}</label>
                        </div>
                    )}
                </>
            }
            isLoadingButton={loading}
        />
    );
    return renderModalUpdateMaxOccupancy();
}

MaximumOccupancy.defaultProps = {
    showModalOccupancy: false,
    loading: false,
}

MaximumOccupancy.propTypes = {
    title: PropTypes.string.isRequired,
    showModalMaxOccupancy: PropTypes.bool.isRequired,
    onHideMaxOccupancy: PropTypes.func.isRequired,
    onSaveMaxOccupancy: PropTypes.func.isRequired,
    onCloseMaxOccupancy: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    setMaximumOccupancy: PropTypes.func.isRequired,
    errorMessage: PropTypes.string
}

export default MaximumOccupancy;
