import {
    SHOW_TOAST_SUCCESS_MESSAGE,
    SHOW_TOAST_ERROR_MESSAGE,
    DESACTIVE_TOAST
} from "../actions/types";

export const initialState = {
    message: "",
    isActive: false,
    isSuccess: false,
    isError: false
};

export default (state, action) => {
    const { type, payload } = action;
    if (typeof state === "undefined") {
      return initialState;
    }
    switch (type) {
        case SHOW_TOAST_SUCCESS_MESSAGE:
            return {
                ...state,
                message: payload.message,
                isSuccess: true,
                isActive: true
            };
        case SHOW_TOAST_ERROR_MESSAGE:
            return {
                ...state,
                message: payload.message,
                isError: true,
                isActive: true
            };
        case DESACTIVE_TOAST:
            return {
                ...state,
                isActive: false,
                isSuccess: false,
                isError: false,
                message: ""
            };
        default:
            return state;
    }
};