import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import uuid from 'react-uuid';
import AmazonStorage from '../../amazonStorage';
import { general } from '../../general/global';
import { ReactComponent as NewIcon } from "../../icons/Icon_newDoc.svg";
import { exists, PDFLocation } from '../../redux/actions/transactionsActions';
import { translate } from '../../redux/reducers/languageReducer';
import Button from '../commons/styled-components/general/Button';
import Label from '../commons/styled-components/general/Label';
import InputFile from '../commons/wrapped-components/inputs/InputFile';
import InputText from '../commons/wrapped-components/inputs/InputText';
import Space from '../commons/wrapped-components/Space';
import MainNavigation from '../Navigation/MainNavigation';

const UploadTransaction = ({
    title,
    loading,
    workspaceFocused,
    dictionary,
    history,
    transactionExist,
    saveLocation,
}) => {
    const [transactionName, setTransactionName] = useState('');
    const [PDFData, setPDFData] = useState(null);
    const [File, setFile] = useState(null);
    const [error, setError] = useState('');

    const disableButton = !(PDFData !== null && transactionName.length > 0);

    const onChangeTransactionName = (value) => {
        setTransactionName(value);
        setError('');
    }

    const loadendFile = async (file, previewURL) => {
        setPDFData(previewURL);
        setFile(file);
    }

    const createTransaction = async () => {
        const resp = await transactionExist(
            workspaceFocused._id,
            transactionName
        );
        if (!resp.data.length) {
            const { Location } = await new AmazonStorage().uploadFile(
                PDFData,
                File,
                `transactions/${workspaceFocused._id}/transactionForms/${uuid()}/`
            );
            await saveLocation({ location: Location, name: transactionName });
            history.push('/createTransaction');
        }
        else
            setError(dictionary.s('renameTransactionError'));
    }

    return (
        <MainNavigation title={title}>
            <Container fluid className="safely-views">
                <Row>
                    <Col><Space data={workspaceFocused} /></Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Label className="edit-form-header" style={{ paddingTop: '30px' }}>{dictionary.s('createTransaction')}</Label>
                    </Col>
                    <Col xs={12}>
                        <InputText
                            maxLength="50"
                            placeholder={dictionary.s('transactionName')}
                            value={transactionName}
                            onChangeText={onChangeTransactionName}
                        />
                        {error && <Label className="required">{error}</Label>}
                    </Col>
                </Row>
                <Row>
                    <Col className="transaction-upload">
                        <InputFile
                            icon={<NewIcon />}
                            onLoadend={loadendFile}
                            captionText={dictionary.s('UploadPDFFiles')}
                            buttonLabel={dictionary.s('UploadFile')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right" xs={12}>
                        <Button
                            className="cancel-btn"
                            onClick={() => history.push('/transactions')}
                        >
                            {dictionary.s("cancel")}
                        </Button>
                        <Button
                            className="save-btn"
                            onClick={createTransaction}
                            style={{
                                backgroundColor: (disableButton ? general.theme.grayColor : general.theme.primaryColor),
                                borderColor: (disableButton ? general.theme.grayColor : general.theme.primaryColor),
                            }}
                            disabled={disableButton}
                        >
                            {loading ?
                                <span>
                                    {dictionary.s("loading")}...{" "}
                                    <i
                                        className="fa fa-spinner fa-spin"
                                        style={{ animation: "fa-spin 1s infinite linear" }}
                                        aria-hidden="true"
                                    ></i>
                                </span> : dictionary.s('next')}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </MainNavigation >
    );
};

const mapStateToProps = state => ({
    dictionary: {
        s: key => translate(state.language.dictionary, key)
    },
    workspaceFocused: state.workspace.spaceFocused,
    loading: state.transactions.loading,
});

const mapDispatchToProps = dispatch => ({
    transactionExist: (workspaceId, transactionName) => dispatch(exists(workspaceId, transactionName)),
    saveLocation: (payload) => dispatch(PDFLocation(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadTransaction);