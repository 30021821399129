import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { ReactComponent as IconCheckInto } from "../../icons/Icon_Checkin.svg";
import { ReactComponent as IconCheckOut } from "../../icons/Icon_Checkout.svg";

const BtnCheckIntoSusbapace = ({ type, dictionary, history, showCheckOutSubspaceModal, deviceType }) => {
    const handleClick = () => {
        if (window.location.hash === '#/workspacelist' && type === 'out') {
            showCheckOutSubspaceModal();
        } else {
            history.push('/checkIntoSubspace');
        }
    }
    return (
        <Button
            variant={type === 'into' ? "success" : 'danger'}
            className={`btn-check-${type === 'into' ? type : 'out'}-subspace`}
            onClick={handleClick}>
            {type === 'into' ? <IconCheckInto /> : <IconCheckOut />} {type === 'into' ? (deviceType !== 'phone' ? dictionary.s('checkIntoSubspace') : '') : (deviceType !== 'phone' ? dictionary.s('checkOutOfSubspace') : '')}
        </Button>
    )
}

BtnCheckIntoSusbapace.defaultProps = {
    deviceType: '',
}

BtnCheckIntoSusbapace.propTypes = {
    type: PropTypes.string.isRequired,
    dictionary: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    showCheckOutSubspaceModal: PropTypes.func,
}

export default BtnCheckIntoSusbapace;
