import React from "react";
import TermsAndConditions from "../privacyAndTerms/TermsAndConditions";
import Button from "../commons/styled-components/general/Button";
import { connect } from "react-redux";
import {
  loginWithEmailAndPassword,
  loginWithSocialMedia,
} from "../../redux/actions/loginActions";
import { changedMagicLinkCode } from '../../redux/actions/onboardingActions';
import { translate } from "../../redux/reducers/languageReducer";
import Notifications from "../../notifications";

const notifications = new Notifications();
const AcceptTerms = (props) => {
  return (
    <>
      <TermsAndConditions />
      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "20px 0px",
        }}
      >
        <Button
          onClick={async () => {
            if (props.magicLinkCode) {
              props.onChangeMagicLinkCode(null);
              let fcmToken = ''
              if (notifications.isSupported()) {
                fcmToken = await notifications.getToken();
              }
              if (props.connectionId === "Username-Password-Authentication") {
                props.onLoginWithEmailAndPassword(
                  props.emailNumber,
                  props.password,
                  props.lang,
                  props.countryCode,
                  fcmToken
                ); }
              else { props.onLoginWithSocialMedia(props.accessToken, props.lang, fcmToken); }
              props.history.push("/dashboard");
            } else { props.history.push("/Welcome"); }
          }}
        >
          {props.dictionary.s(props.loading ? "loading" : "continueAndAgree")}
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.user.accesstoken,
  emailNumber: state.onboarding.emailNumber,
  password: state.onboarding.password,
  magicLinkCode: state.onboarding.magicLinkCode,
  connectionId: state.user.connectionId,
  lang: state.language.lang,
  dictionary: {
    s: key => translate(state.language.dictionary, key)
  },
  countryCode: state.onboarding.countryCode,
});

const mapDispatchToProps = (dispatch) => ({
  onLoginWithEmailAndPassword: (email, password, lang, countryCode, fcmToken) =>
    dispatch(loginWithEmailAndPassword(email, password, lang, countryCode, fcmToken)),
  onLoginWithSocialMedia: (accessToken, lang, fcmToken) =>
    dispatch(loginWithSocialMedia(accessToken, lang, fcmToken)),
  onChangeMagicLinkCode: code => dispatch(changedMagicLinkCode(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AcceptTerms);
