export default {
  getStarted: "¡Comencemos!",
  signin: "Iniciar Sesión",
  poweredBy: "Powered by",
  cellPhoneNumer: "Número celular",
  emailOrPhoneNumber: "Correo electrónico o número celular",
  emailIDOrPhoneNumber: "Correo electrónico o número celular",
  getASafelyLink: "Obtener un link Safely",
  or: "o",
  orSigninWith: "o ingresar con",
  dontHaveAnAccount: "¿Aún no tienes una cuenta?",
  termAndConditions: "Términos y Condiciones de uso",
  companys: "Empresas",
  email: "Correo electrónico",
  emailExample: "youremail@mail.com",
  password: "Contraseña",
  rememberMe: "¿Recordarme?",
  welcome: "¡Bienvenido!",
  verifiedAccount:
    "Tu cuenta ya ha sido verificada. Realiza la configuración de un nuevo espacio Safely.",
  createANewSafelyWorkspace: "Crear un nuevo espacio Safely",
  lookingForAnExistingSafelyWorksapce:
    "¿Estás buscando un espacio Safely ya existente?",
  askForASafelyLink: "Solicitar un link Safely",
  askForASafelyPass: "Solicitar un Safely Pass",
  tryAnotherAccount: "Intentar con otra cuenta",
  whatIsTheNameOfYourSafelyWorkspace:
    "Dale un nombre a tu nuevo espacio Safely",
  workspacesName: "Nombre del nuevo espacio",
  workspaceNameHelp:
    "El nombre de tu espacio Safely puede ser el nombre de tu empresa o equipo.",
  prev: "Anterior",
  next: "Siguiente",
  byContinuingYouAgreeToOur: "Al continuar está aceptando nuestros",
  termsAndConditions: "Términos y Condiciones de Servicio al Cliente",
  andThe: "y la",
  PrivacyPolicy: "Política de Privacidad",
  wouldYouLikeToAddSomeoneElseFromYourSafelyWorkspace:
    "Invita a otros a tu espacio",
  shareYourSafelyLink: "Compartir un link Safely",
  shareaspaceinvitationlink: "Compartir un enlace de invitación al espacio",
  inviteYourContacts: "Invitar a mis Contactos",
  omit: "Omitir",
  addAnother: "Agregar otro",
  setYourProfile: "Crea tu Perfil",
  fullName: "Nombre completo",
  yourFullName: "Escribe tu nombre completo",
  setYourSafelyWorkplace: "Configura tu espacio Safely",
  geolocationPermissionsForYourCompanysMembers:
    "Configura los permisos de geolocalización para los miembros de tu espacio Safely.",
  requestTheUsersCurrentLocationOnlyWhenATransactionIsMade:
    "Solicitar la ubicación en cada registro de manera obligatoria.",
  requestTheUsersRealTimeLocation:
    "Solicitar la ubicación en tiempo real del usuario durante su estancia en tu espacio Safely.",
  activeteGeolocationMandatory:
    "Activar la geolocalización de manera obligatoria",
  finishSigningUp: "Termine de registrarse",
  register: "Registrarme",
  signup: "Regístrate",
  loading: "Cargando",
  user: "Usuario",
  finish: "Finalizar",
  names: "Nombre(s)",
  lastName: "Apellidos",
  cellPhoneNumberValidation: "El número celular debe de ser a 10 dígitos.",
  completeAllFields: "Completa todos los campos para continuar",
  share: "Compartir",
  cancel: "Cancelar",
  copy: "Copiar",
  join: "Únirme",
  mySafelyPass: "Mi Safely Pass",
  completeAccessForm: "Completar formulario de acceso",
  geolocation: "Geolocalización",
  code: "Código",
  form: "Formulario",
  comments: "Comentarios",
  send: "Enviar",
  checkPoint: "Punto de Verificación",
  raiseAlert: "Levantar alerta",
  safelyPass: "Safely Pass",
  confirm: "Confirmar",
  accept: "Aceptar",
  required: "Requerido",
  areYouSureToSendTheInformation: "¿Está seguro de enviar la información?",
  error: "Error",
  anErrorHasOccurredDoYouWantToTryAgain:
    "¿Ha ocurrido un error, deseas volverlo a intentar?",
  yes: "Si",
  no: "No",
  youHasNotAcceptedToEnableTheLocationServices:
    "No ha aceptado habilitar los servicios de ubicación o algo salió mal durante el proceso.",
  workspaces: "Espacios",
  pleaseSelectAnyThatApplyToYou: "Por favor, selecciona cualquier opción que te aplique",
  thingsToVerifyMayInclude: "Las puntos por verificar pueden incluir",
  generateASafelyPass: "Generar Safely Pass",
  generatingASafelyPassYouConfirmThatTheInformationProvidedIsTrue:
    "Al generar un Safely Pass, confirma que la información proporcionada es verdadera y correcta según su saber y entender.",
  Verify: "Verificar",
  safelyPassActivated: "Safely Pass activado",
  safelyPassDeactivated: "Safely Pass desactivado",
  activatedSafelyPass: "Safely Pass activados",
  tryAgain: "Inténtalo de nuevo",
  yourSafelyPassHavenotBeenGenerated:
    "Lo sentimos, su Safely Pass no se generó debido a la información que divulgó en el formulario de autodeclaración.Por favor, comuníquese con su contacto designado dentro de su organización para obtener más instrucciones. ",
  yourSafelyPassVaveNotBeenActived:
    "Lo sentimos, su Safely Pass no ha sido activado debido a la información recopilada en el formulario de verificación.Por favor, comuníquese con su contacto designado dentro de su organización para obtener más instrucciones. ",
  yourSafelyPassVaveNotBeenActived1:
    "Lo sentimos, su Safely Pass no ha sido activado debido a la información recopilada en el formulario de verificación.",
  yourSafelyPassVaveNotBeenActived2:
    "Por favor, comuníquese con su contacto designado dentro de su organización para obtener más instrucciones.",
  yourSafelyPassHavenotBeenGenerated1: " Lo sentimos, su Safely Pass no se generó debido a la información que divulgó en el formulario de autodeclaración.",
  yourSafelyPassHavenotBeenGenerated2: " Por favor, comuníquese con su contacto designado dentro de su organización para obtener más instrucciones. ",
  manageUser: "Administrar Usuario",
  userName: "Nombre del usuario",
  workSpace: "Espacio",
  continueAndAgree: "Aceptar y Continuar",
  createANewWorkspace: "Crea un nuevo espacio",
  scanAWorkspaceQRCode: "Escanear el código QR de un espacio",
  shareMyWorkspaceLink: "Compartir el link de mi espacio",
  back: "Atrás",
  settings: "Configuración",
  locationIsRequired: "La ubicación es obligatoria",
  selfDeclaration: "Autodeclaración",
  editSelfDeclaration: "Editar Autodeclaración",
  TheWorkspaceYouWantToAccessMightHaveRequirementsThatNeedToBeMet:
    "El Espacio al que desea acceder puede tener requisitos que deben cumplirse.",
  addWorkspace: "Agregar espacio",
  save: "Guardar",
  thoseMayInclude: "Esos pueden incluir",
  verify: "Verificar",
  close: "Cerrar",
  verificationPoint: "Punto de verificación",
  editVerificationPoint: "Editar punto de verificación",
  areAllTheConditionsToGrantThisUserAccessToTheWorkspaceMet:
    "¿Se cumplen TODAS las condiciones para otorgar a este usuario acceso al Espacio?",
  preferences: "Preferencias",
  inviteAUserToMyWorkspace: "Invitar a un usuario a mi espacio",
  myWorkspaceQRCode: "Código QR de mi espacio",
  renameMySpace: "Renombrar mi espacio",
  spaceSettings: "Configuración del espacio",
  theWorkspaceNameIsInvalid: "El nombre del espacio no es válido.",
  download: "Descargar",
  emailOrPhoneNumberIsInvalid: "El correo electrónico o número de celular no es válido.",
  hasInviteYouToJoinTheSafely: "Te ha invitado a unirte a Safely",
  reject: "Rechazar",
  joinNow: "Unirme Ahora",
  youAlreadyBelongToTheWorkspace: "Ya perteneces a este espacio",
  theWorkspaceNameAlreadyExists: "El nombre del Espacio ya existe",
  notEmailOrNumberValid:
    "Usuario ingresado no parece ser un correo electrónico o número celular válido",
  isSeekingAccessTo: "está buscando acceso a",
  language: 'Idioma',
  english: 'Inglés',
  spanish: 'Español',
  logginIn: "Iniciando sesión",
  takePhoto: "Tomar foto",
  useWebCam: "Usar webcam",
  userVerification: "Verificación de Usuario",
  Dashbaord: "Dashbaord",
  userManagement: "Administración de usuarios",
  noResultFound: "No se encontraron resultados",
  logout: "Cerrar sesión",
  justOneMoreStepBeforeYouActivateYourSafelyPass: 'Solo un paso más antes de activar su Safely Pass.',
  pleaseProceedToTheAssignedVerificationPointToActivateYourSafelyPass: 'Vaya al punto de verificación asignado para activar su Safely Pass.',
  refresh: 'Actualizar',
  createAccount: 'Crear cuenta',
  firstName: 'Nombre',
  reports: 'Reportes',
  safelyPassesHistoryReports: 'Historial Safely Pass',
  activationDate: 'Fecha de activación',
  monitorName: 'Nombre del monitor',
  PREV: 'ANTERIOR',
  NEXT: 'SIGUIENTE',
  All: 'Todo',
  showing: 'Mostrando',
  Admin: "ADMINISTRADOR",
  Monitor: "MONITOR",
  User: "USUARIO",
  Visitor: "VISITANTE",
  browse: 'Vistazo',
  choosefile: "Escoge un Archivo",
  agreeToSafely: "Estoy de acuerdo con los",
  termsOfService: "términos de servicio de Safely Pass",
  orSignUpWith: "o regístrate con",
  welcomeBack: "Bienvenido de nuevo",
  createSpace: "Crear espacio",
  CreatenewSafelyspace: "Crear nuevo espacio de Safely",
  invitationLink: "Envía una invitación a través de un enlace a su número celular o correo electrónico",
  haveAccount: "Ya tienes una cuenta? ",
  hey: "Oye",
  hasbeencreated: "¡Ha sido creado!",
  theUserDoesNotBelongToAnyOfYourWorkspaces: "El usuario no pertenece a ninguno de tus espacios",
  scanQRCode: "Escanear código QR",
  signInToSafely: "Iniciar Sesión en Safely",
  forgotPassword: "¿Se te olvidó tu contraseña?",
  helloFriend: "¡Hola Amigo!",
  pleaseSigninWithPersonalInfo: "Para mantenerse conectado con nosotros, inicie sesión con su información personal",
  enterPersonalDetailsAndStartJourney: "Ingresa tus datos personales y comienza a usar Safely Pass",
  mySpace: "Mis espacios",
  groups: "Grupos",
  group: "Grupo",
  contactIndex: "ÍNDICE DE CONTACTO",
  global: "Global",
  today: "Hoy",
  createSafelySpace: "Crear espacio Safely",
  confirmAccount: "¡Has confirmado tu cuenta!",
  newSafelyspacefororganization: "Ya está todo listo para comenzar un nuevo espacio Safely para su organización.",
  lookingForExistingSpace: "¿Buscas un espacio existente?",
  updateYourSettings: "Actualiza tu configuración",
  almostDone: "¡Casi listo!",
  pleaseUpdateYourSettings: "Por favor, actualiza tus configuraciones",
  spaceColor: "Color del espacio",
  renameSpace: "Renombrar Espacio",
  rename: "Renombrar",
  Import: "Importar",
  ImportContacts: "Importar Contactos",
  DescriptionImportContacts: "Para importar contactos, cargue un archivo CSV",
  DownloadCSVTemplate: "Descargar platilla CSV",
  UploadFile: "Subir archivo",
  ImportedContacts: "Contactos Importados",
  InvitesSent: "Invitaciones enviadas!",
  createQrCode: "Crear código QR",
  inviteMyContacts: "Invitar a mis contactos",
  information: "Información",
  toSwitchToThisWorkSpaceYouFirstNeedToCheckoutTheActivatedSafelyPass: 'Para cambiarte a este Espacio, primero necesitas registrar tu salida en el Safely Pass activado.',
  MessageErrorDefault: "Ocurrió un error intente nuevamente más tarde",
  home: 'Inicio',
  statistics: 'Estadísticas ',
  location: 'Localización geográfica',
  createSubSpace: 'Crear Subespacio',
  subspaces: 'Subespacios',
  subspace: 'Subespacio',
  done: 'Hecho',
  addMembers: 'Agregar miembros',
  members: 'Miembros',
  member: 'Miembro',
  searchbyname: 'Buscar por nombre',
  forgotYourPassword: '¿Olvidaste tu contraseña?',
  backToSignIn: 'Regresar a Iniciar sesión',
  sendUsEmailorMobileNumber: '¡Envíenos el correo electrónico o número celular asociado con su cuenta y le enviaremos un enlace para restablecerlo!',
  verifyYourName: 'Verifica tu nombre',
  fetchedYourDetailsFrom: 'Hemos obtenido sus datos de',
  pleaseVerifyNameandupdate: ' Por favor verifique su nombre y actualice si es incorrecto.',
  verifyMobileNumber: 'Verificar número celular',
  resendPin: 'Reenviar Pin',
  resendPinIn30sec: 'Reenviar Pin en 30 segundos',
  verifyEmailId: 'Verificar correo electrónico',
  sentPinToEmailandEnterPin: 'Hemos enviado un Pin a su correo electrónico. Ingrese el Pin para continuar.',
  ThisQRCodenolongerexists: "Este código QR ya no existe",
  FlipCamera: "VOLTEAR CÁMARA",
  AligntheQRCodewiththeframetoscan: "Alinee el código QR con el marco para escanear",
  Edit: "Editar",
  sentPinToMobileandEnterPin: 'Hemos enviado un Pin a su número celular. Ingrese el Pin para continuar.',
  theWorksubspaceNameAlreadyExists: "El subespacio ya existe en el espacio",
  remove: '¿Eliminar a',
  areyousureyouneedremove: '¿Está seguro de que desea eliminar a',
  fromSubspace: 'del subespacio',
  removebutton: 'Eliminar',
  Istheuserawareoftheconditionsestablishedbythecompanytoaccesstothisspace:
    "¿Conoce el usuario las condiciones establecidas por la empresa para acceder a este espacio?",
  DidthisusermeetALLtheconditionstoaccessthisspace: "¿Este usuario cumplió TODAS las condiciones para acceder a este espacio?",
  SafelyPassisRejected: 'Safely Pass rechazado',
  scanQRCodeToActivateSafelySpace: "Escanear el código QR de un Safely Pass",
  checkinCheckoutRegister: 'Registrar Entrada / Salida',
  checkInRegister: "Registro de entradas",
  search: "Buscar",
  name: "Nombre",
  checkInTime: "Hora de entrada",
  checkOutTime: 'Hora de salida',
  space: 'Espacio',
  checkinCheckout: "Registrar Entrada / Salida",
  checkIn: "Registrar entrada",
  checkOut: "Registrar salida",
  userCheckOut: 'Dar salida a ',
  areYouSureYouNeedToCheckOutTheUser: '¿Estás seguro de que deseas registrar la salida de ',
  userNotRegistered: '¡El usuario no está registrado!',
  invalidpin: 'Pin inválido',
  pinverified: 'Pin verificado',
  verificationForm: 'Formulario de Verificación',
  editVerificationForm: 'Editar formulario de verificación',
  content: "Contenido",
  emailId: "Correo electrónico",
  mobileNumber: "Número celular",
  subSpaces: "Subespacios",
  role: 'Rol',
  selectSpace: 'Seleccionar espacio',
  cropPhoto: "Cortar foto",
  sendLinkToResetPinToMobile: "Acabamos de enviarte un SMS para restablecer tu contraseña.",
  sendLinkToResetPinToEmail: "Acabamos de enviarte un correo electrónico para restablecer tu contraseña.",
  safelyPassAlreadyActivated: "Este Safely Pass ya está activado",
  ActivatedSafelyPasses: "Safely Pass Activados",
  SafelyPassesNotGenerated: "Safely Pass No Generados",
  SafelyPassesRejected: "Safely Pass Rechazados",
  CheckedInUsingRegister: "Registros del Punto de control",
  passRequestedDate: "Fecha de solicitud",
  passRejectedDate: "Fecha de rechazo",
  editSelfDeclarationForm: "Editar formulario de autodeclaración",
  selfDeclarationForm: "formulario de autodeclaración",
  selectInput: "Seleccionar campo de captura",
  label: "Etiqueta",
  number: "Valor numérico",
  text: "Texto",
  boolean: "Sí / No",
  buttongroup: "Botón",
  denySafelyPass: 'Denegar Safely Pass',
  addAnotherInput: "Agregar otro campo de captura",
  of: 'de',
  show: 'Mostrar',
  perPage: 'por página',
  subSpaceQrcode: 'Código QR del Subespacio',
  qrCode: 'Código QR',
  accessGranted: 'Acceso Garantizado',
  passGranted: '¿Pase generado?',
  youhavecheckedintosubspaceInnovation: 'Has ingresado al subespacio ',
  accessDenied: 'Acceso Denegado',
  youhavebeendeniedaccesstosubspaceInnovation: 'Se te ha negado el acceso al subespacio',
  youhavecomeoutsubspace: 'Has salido del subespacio ',
  setUpYourSpace: 'Configura tu Espacio',
  yourLocationwillBeTracked: 'Su ubicación será monitoreada para garantizar su seguridad dentro del espacio',
  chooseUniqueColor: 'Elije un color único para identificar fácilmente el espacio',
  mobileNumberOrEmailId: 'Número celular o Correo electrónico',
  verificationFormsReport: 'Reporte de los Formularios de Verificación',
  selfDeclarationReport: 'Reporte de Autodeclaraciones',
  export: 'Exportar',
  yesterday: "Ayer",
  currentWeek: "Esta semana",
  lastWeek: "La semana pasada",
  currentMonth: "Este mes",
  lastMonth: "El mes pasado",
  currentYear: "Este año",
  lastYear: "El año pasado",
  all: "Todo",
  accessGrantedQustion: '¿Acceso garantizado?',
  dateSubmitted: 'Fecha',
  safelyPassHistory: "Historial Safely Pass",
  selfDeclarations: "Autodeclaraciones",
  verificationForms: "Formularios de Verificación",
  searchMembers: 'Buscar Miembros',
  permissionsareneededtoscanauserSafelyPass: "Se necesitan permisos para escanear el Safely Pass de un usuario",
  more: "más",
  less: "menos",
  post: "Publicaciones",
  sharephotosandvideoswithyourcolleagues: 'Comparte fotos y vídeos con tus colegas',
  addNewPost: 'Añadir nueva publicación',
  uploadPhotoOrVideo: 'Subir foto o vídeo',
  takePhotoOrVideo: 'Tomar una foto o un vídeo',
  video: 'Vídeo',
  editPhoto: 'Editar foto',
  postdata: 'Publicar',
  newPost: 'Nueva publicación',
  selectSubSpace: 'Seleccionar Subespacio',
  PleaseSelectAtleastOneSubSpace: "Seleccione al menos un espacio secundario",
  writeACaption: "Escribe una descripción",
  switchSpace: "¿Cambiar de espacio?",
  ifYouChangeTheSpace: "Si cambia el espacio, el Safely Pass generado será reemplazado. ¿Seguro que quieres hacer esto?",
  tooltipMessageToTrySwitchSpace: "Para cambiar a este espacio, primero debe desactivar el Safely Pass activado",
  undo: 'Deshacer',
  redo: 'Rehacer',
  reset: 'Reiniciar',
  delete: 'Eliminar',
  deleteAll: 'Eliminar todo',
  crop: 'Cortar',
  rotate: 'Girar',
  draw: 'Dibujar',
  color: 'Color',
  straight: 'Recta',
  free: 'Libre',
  shape: 'Forma',
  range: 'Rango',
  center: 'Centrar',
  left: 'Izquierda',
  underline: 'Subrayar',
  italic: 'Cursiva',
  bold: 'Negrita',
  textsize: 'Tamaño del texto',
  custom: 'Personalizado',
  square: 'Cuadrado',
  apply: 'Aplicar',
  right: 'Derecha',
  stopRecoding: 'Detener grabación',
  startRecoding: 'Iniciar grabación',
  renameSubspace: "Renombrar Subespacio",
  youhaveanewPostpleaserefresh: "Tienes una nueva publicación",
  checkInNewUser: 'Registrar nuevo usuario',
  errorYouNeedToAddAnImage: "Error: Es necesario añadir una imagen",
  errorNameMustNotBeEmpty: "Error: El nombre no debe estar vacío",
  errorLastNameMustNotBeEmpty: "Error: El apellido no debe estar vacío",
  theSubspaceNameAlreadyExists: "El nombre del subespacio ya existe",
  theSubspaceNameIsInvalid: "El nombre del subespacio es invalido",
  allSubSpacesareselected: 'Todos los subespacios están seleccionados',
  wanttocheckintoaSpace: '¿Quieres ingresar a un espacio?',
  spaceInfo: "Información del Espacio",
  EditPost: "Editar Publicación",
  DeletePost: "Eliminar Publicación",
  viewSpace: "Ver Subespacios",
  spaceQRCode: "Código QR del espacio",
  selfDeclarationFrom: 'Formulario de Autodeclaración',
  saveChanges: 'Guardar cambios',
  searchSpace: "Buscar Espacio",
  enableSelfDeclaration: 'Habilite la Autodeclaración para obtener acceso al formulario para generar el Safely Pass',
  verificationPointSwitchText: 'Solo se requiere si el administrador o monitor tiene que activar el Safely Pass de los usuarios',
  changeshasbeenupdatedsuccessfully: 'Los cambios se han actualizado correctamente',
  createBy: 'Creado por',
  deletePostQuestion: '¿Eliminar publicación?',
  youAreAboutToDeletePost: 'Estás a punto de eliminar esta publicación. ¿Está seguro de que desea continuar?',
  checkpoint: 'Punto de control',
  marketplace: 'Marketplace',
  noSpacesFound: 'No se encontraron espacios',
  errorGenerateASafelyPass: 'Para generar un Safely Pass de este espacio, primero necesitas registrar tu salida en el Safely Pass activado del espacio',
  errorViewSpace: 'Para ver estos subespacios, primero debe verificar el Pase con seguridad activado',
  activateSafelyPass: 'Activar Safely Pass',
  buyNow: 'Comprar ahora',
  checkOutQuestion: "¿Registrar salida de",
  seeMore: "Ver más",
  checkedIn: 'Dentro del espacio',
  notCheckedIn: 'Entrada no registrada',
  workspaceSettingsUpdateMessage: "Éxito",
  deleteSubspace: "Eliminar Subespacio",
  deleteSysbol: "¿Eliminar",
  deleteSubSpaceText1: "Estás a punto de eliminar el subespacio",
  areyousureyouwanttocontinue: "¿Estás seguro de que quieres continuar?",
  deletedSubspace: "Eliminada Subespacio",
  YoudonothaveaccesstoanySpace: '¡No tienes acceso a ningún espacio!',
  JoinanexistingSpace: 'Únete a un espacio existente',
  deleteSpaceImage: '¿Eliminar imagen del Espacio?',
  youAreAboutToDeleteSpaceImage: 'Estás a punto de eliminar la imagen del espacio. ¿Estás seguro de que quieres continuar?',
  deleteSpace: "Eliminar Espacio",
  deleteSpaceText1: "Estás a punto de eliminar el Espacio",
  deleteSpaceText2: "Este Espacio tiene datos. ¿Estás seguro de que quieres eliminarlo? Si la repuesta es Sí, envía un correo electrónico a ",
  deletedspace: "Eliminada Espacio",
  subSpaceDeleted: 'Subespacio eliminado',
  editMaximumOccupancy: 'Editar capacidad máxima',
  updateMaximumOccupancy: 'Actualizar',
  theMaximumOccupancyIsInvalid: 'La capacidad máxima es inválida',
  maximumOccupancyMustBeEqualOrGreaterthan1: "La capacidad máxima debe de ser igual o mayor a 1",
  maximumOcuppancy: 'Capacidad máxima',
  subspaceName: 'Nombre del subespacio',
  spaceDeleted: 'Espacio eliminado',
  Available: 'Disponible',
  AlmostFull: 'Casi lleno',
  Full: 'Lleno',
  subspaceActivityText1: 'Actividad en todos los espacios y subespacios de ',
  subspaceActivityText2: ' ',
  SubspacesActivity: 'Actividad en Subespacios',
  SubspacesHistory: 'Historial de Subespacios',
  UserSubspacesHistory: 'Subespacios',
  timeSpentInSubspace: 'Tiempo en el subespacio',
  oldest: 'Más antiguos',
  theSubspace: 'El subespacio ',
  maxCapacityOrNotAuthorized: ' esta a su máxima capacidad o no estas autorizado para ingresar.',
  mySubspaces: 'Mis subespacios',
  membersInSubspace: 'Miembros',
  occupancy: 'Capacidad',
  youAreCheckedIntoTheSpace: 'Estas registrado dentro del espacio',
  checkIntoQuestion: '¿Entrar al subespacio',
  areYouSureYouWantToCheckInto: '¿Estás seguro de que deseas entrar al subespacio',
  checkOutSubspace: 'Salir',
  checkOutSubspaceQuestion: '¿Salir del subespacio',
  areYouSureYouWantToCheckOut: '¿Estas seguro de que deseas salir del subespacio',
  checkInSubspace: 'Entrar',
  checkOutOfSubspace: 'Salir del subespacio',
  checkIntoSubspace: 'Ingresar a un subespacio',
  checkinCheckoutSubspace: 'Entrar / Salir',
  maximumOccupancy: 'Capacidad máxima',
  availableOccupancy: 'Capacidad disponible',
  users: 'Usuarios',
  youhavenopostsyet: 'Aún no tienes publicaciones',
  photo: 'foto',
  inputLabel: 'Etiqueta del campo de captura',
  ButtonsAndActions: 'Botones y Acciones',
  AddAnotherButton: 'Agregar otro Botón',
  GrantSafelyPass: 'Generar Safely Pass',
  Thiswordalreadyexists: 'Éste botón ya existe',
  buttonValidation: 'Debe tener al menos un botón con que genere el Safely Pass',
  emptyDescription: 'Necesita agregar un nombre al botón',
  sameDescription: 'El nombre de los botones no debe repetirse',
  minimumButtons: 'Debe tener al menos dos botones',
  requiredValidation: 'Debe completar todos los campos obligatorios',
  addInput: 'Agregar campo de captura',
  addPhotoOrVideo: 'Agregar foto o vídeo',
  userDeleted: 'Usuario removido exitosamente',
  youAreAboutToRemove: 'Estás a punto de eliminar a',
  fromSpace: 'del espacio',
  fromSpaces: 'de los espacios',
  fromSelectedSpaces: 'de los espacios seleccionados.',
  areYouSureWantToContinue: '¿Estás seguro de que quieres continuar?',
  loadmore: 'Ver más',
  spaceHasBeenDeleted: 'El espacio ha sido eliminado',
  messageUserHasActivedPass: "tiene un Safely Pass activado de este espacio y no se ha eliminado. Si eliminas al usuario se registrará su salida del espacio",
  thatsAll: "¡Eso es todo!",
  leaveSpace: "Dejar el Espacio",
  leaveSpaceQus: "¿Dejar el Espacio",
  leave: "Dejar",
  leaveSpaceText1: "Estás a punto de dejar el espacio ",
  leavespaceUpdated: 'Dejó el espacio exitosamente',
  AgreeToThe: "Acepto los",
  and: 'y ',
  showMoreSpaces: 'Ver más',
  thatsAllMessage: '¡Eso es todo!',
  minimumValue: "Valor Mínimo",
  maximumValue: "Valor Máximo ",
  inputRangeMessage: "El Safely Pass será denegado si el usuario ingresa un valor fuera del rango",
  inputLabelValidation: "Los etiquetas de los campos de captura no deben estar vacías",
  rangeEmptyValue: "Los valores máximo y mínimo no deben estar vacíos",
  rangeValidation: "El valor mínimo no puede ser mayor o igual al valor máximo",
  noSubspacesFound: 'No se encontraron subespacios',
  noUsersFound: 'No se encontraron usuarios',
  searchSubspace: 'Buscar subespacio',
  searchMember: 'Buscar Miembro',
  and_The: 'y la',
  ofSafelyPass: 'de Safely Pass',
  skip: 'Omitir',
  subspaceCreated: '¡Subespacio Creado!',
  actions: 'Acciones',
  editUser: 'Editar Usuario',
  editUserUserName: "Nombre del usuario",
  removeUser: 'Eliminar Usuario',
  pleaseEnterTheValue: 'Por favor, ingresa un valor',
  AssignSubspaces: 'Asignar subespacios',
  resendPinIn: 'Reenviar Pin en',
  seconds: 'segundos',
  thePinHasExpired: 'El pin ha expirado.',
  visitors: 'Visitantes',
  lastCheckinTime: 'Última hora de entrada',
  date: 'fecha',
  activity: 'actividad',
  safelyPassDenied: 'Safely Pass Denegado',
  safelyPassHasBeenActivated: 'El Safely Pass de ',
  forTheSpace: 'para el espacio ',
  safelyPassHasBeenDenied: 'El Safely Pass de ',
  hasBeenDenied: 'ha sido denegado ',
  hasBeenActivated: 'ha sido activado ',
  SafelyPassesDisabled: 'Safely Passes Desactivados',
  disabledBy: 'Desactivado por',
  disabledDate: 'Fecha de desactivación',
  disabledPeriod: 'Periodo desactivado',
  enabledDate: 'Fecha de activación',
  workspaceDisabledMessage: 'Safely Pass ha sido desactivado para el espacio',
  from: 'del',
  to: 'al',
  DisableUserSafelyPass: 'Desactivar el Safely Pass del usuario',
  Disable: 'Desactivar',
  DisableSafelyPass: 'Desactivar Safely Pass',
  AllSpaces: 'Todos los espacios',
  SpecificSpaces: 'Espacios específicos',
  StartDate: 'Fecha de inicio',
  EndDate: 'Fecha de termino',
  DisabledUserSafelyPassSuccessfully: ' ¡Safely Pass del usuario desactivado exitosamente!',
  SearchSpaces: 'Buscar espacios',
  DisableSafelyPassOfUserName: 'Desactivar el Safely Pass de',
  Dateplaceholder: 'Fecha',
  EnableUserSafelyPass: 'Activar el Safely Pass del usuario',
  Enable: 'Activar',
  EnabledUserSafelyPassSuccessfully: '¡Safely Pass del usuario activado exitosamente!',
  EnableSafelyPassOfUserName: '¿ Activar el Safely Pass de',
  enableUserSafelyPassSuffix: 'El Safely Pass de',
  areyousureyouwanttoenabletheuser: "¿Estás seguro que quieres activar el usuario?",
  areyousureyouwanttodisabletheuser: "¿Estás seguro de que quieres desactivar al usuario?",
  safelyPassIsDisabledForAllTheSpaces: 'esta desactivado para todos los espacios',
  safelyPassIsDisabledForTheSpaces: 'esta desactivado para los espacios',
  safelyPassIsDisabledForTheSpace: 'esta desactivado para el espacio',
  AreYouSureYouWantToRemove: '¿Estás seguro que quieres eliminar el usuario?',
  safelyPassIsCurrentlyActivatedForTheSpace: 'está actualmente activado para el espacio',
  errorEncounteredPleaseTryAfterSometime: "Se encontró un error, por favor, inténtalo nuevamente después de algún tiempo",
  your: "Tu",
  didNotReceiveTheVerificationPin: "¿No recibió el pin de verificación?",
  multiple: 'Opción Múltiple',
  optionsAndActions: 'Opciones y Acciones',
  addAnotherOption: 'Agregar otra opción',
  thisOptionLabelAlreadyExists: 'Ésta opción ya existe',
  addOptionLabel: 'Necesita agregar una descripción a cada opción',
  sameLabelDescription: 'La descripción de la opción no debe repetirse',
  minimumOptionValidation: 'Debe tener al menos dos opciones',
  transactionButton: 'Completar una transacción',
  transactionsTitle: 'Transacciones',
  transactionName: 'Nombre de la Transacción',
  lastExecution: 'Ultima ejecución',
  createTransaction: 'Crear Transacción',
  RenameTransaction: 'Renombrar Transacción',
  EditTransactionForm: 'Editar Formulario de Transacción',
  AssignTransaction: 'Asingar Transacción',
  TransactionQRCode: 'Código QR de Transacción',
  DeleteTransaction: 'Eliminar Transacción',
  onlyAdmin: 'Sólo Administrador',
  allMembers: 'Todos los miembros',
  specificMembers: 'Miembros específicos',
  selectMembers: 'Seleccionar miembros',
  allMembersAreSelected: 'Todos los miembros están seleccionados',
  assignTo: 'Asignar A',
  assignTransactionTo: 'Asignar transacción a',
  transactionsHistory: 'Historial de transacciones',
  transactions: 'Transacciones',
  transactionName: 'Nombre de transacción',
  renameTransactionError: 'Esta transacción ya existe en el espacio. Ingrese un nombre único.',
  UploadPDFFiles: '*Cargar solo archivos pdf',
  UploadingFile: 'Subiendo Archivo',
  assign: 'Asignar',
  deleteTransactionQuestion: '¿Eliminar transacción?',
  deleteTransactionText1: 'Estás a punto de eliminar la transacción ',
  deleteTransactionText2: ' y sus registros en los reportes. ¿Estás seguro de que quieres eliminarlo?',
  deleteTransactionSuccessfully: '¡Transacción eliminada correctamente!',
  assignTransactionSuccessfuly: '¡Transacción asignada correctamente!',
  workSpaceDetailsLabelOptions: '¿Que te gustaría hacer?',
  inputFields: 'Campos de Captura',
  signature: 'Firma',
  textEditInput: 'Editar Campo de Texto',
  fontSize: 'Tamaño de Fuente',
  requiredField: 'Campo Requerido',
  signatureEditInput: 'Editar Campo de Firma',
  removeInput: 'Remover',
  transactions: 'Transacciones',
  addInputFields: 'Agregar Campos de Captura',
  saveAndSubmit: 'Guardar y Enviar',
  page: 'Página',
  addSignature: 'Agregar Firma',
  clear: 'Limpiar',
  requiredText: 'Texto Requerido',
  requiredSignature: 'Firma Requerida',
  inputDeletedSuccesfully: 'Campo removido',
  transactionSavedSuccesfully: 'Transacción Guardada con Éxito',
  changesSavedSuccesfully: 'Cambios Guardados con Éxito',
  dropdownlist: 'Lista desplegable',
  GrantSafelyPassError: 'Al menos una opción debe tener la acción “Otorgar aprobación segura”',
};