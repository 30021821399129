import styled from "styled-components";

const Sidebar = styled.div`
  background-color: ${props => props.backgroundColor};
`;

Sidebar.defaultProps = {
  backgroundColor: "#13988A",
  width: "260px"
};

export default Sidebar;
