import React, { useEffect, useState } from "react";
import {
  checkDeleteSpace,
  deleteSpace,
  updatedeleteSpace
} from "../../redux/actions/workspaceActions";
import { connect } from "react-redux";
import { general } from "../../general/global";
import { translate } from "../../redux/reducers/languageReducer";
import Modal from "../commons/wrapped-components/Modal";
import { toastSuccess, toastDismissAll } from "../commons/Toast";
import { withRouter } from "react-router-dom";

const DeleteSpace = props => {
  const [clickSaveButton, setClickSaveButton] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [showBody, setShowBody] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [typeValue, setTypeValue] = useState(null);

  useEffect(() => {
    if (props.showDeleteSpace) {
      props
        .onCheckDeleteSpace(props.workspaceFocused._id, props.accesstokenidp)
        .then(getResponce => {
          setShowTitle(true);
          setShowBody(true);
          setShowSave(true);
          setTypeValue(getResponce.data.type);
          if (getResponce.data.type === "Delete") {
            setShowCancel(true);
          }
        })
        .catch(error => {
          setShowTitle(true);
          setShowBody(true);
          setShowSave(true);
          setTypeValue("Email");
        });
    }
  }, [props.showDeleteSpace]);

  const resetValue = () => {
    setShowTitle(false);
    setShowBody(false);
    setShowCancel(false);
    setTypeValue(null);
    setShowSave(false);
    props.closeDeleteSpace();
  };

  const spinnerdiv = () => (
    <div className="add-new-post-col">
      <div className="loader-container-spinner">
        <div className="spinner-loader">
          <div className="spinner-border" role="status"></div>
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      title={
        showTitle
          ? props.dictionary.s("deleteSysbol") +
            " " +
            props.workspaceFocused.name +
            "?"
          : null
      }
      dialogClassName="safely-modal renderModalDeleteSpace"
      showModal={props.showDeleteSpace}
      onHide={() => {
        resetValue();
      }}
      onSave={
        showSave
          ? async () => {
              if (typeValue === "Delete") {
                toastDismissAll();
                if (!clickSaveButton) {
                  setClickSaveButton(true);
                  await props
                    .ondeleteSpace(
                      props.workspaceFocused._id,
                      props.accesstokenidp
                    )
                    .then(responceData => {
                      props.onUpdatedeleteSpace(props.workSpacesProps, props.workspaceFocused._id);
                      resetValue();
                      setClickSaveButton(false);
                      props.history.push("/workspacelist");
                      toastSuccess(responceData.sucessMessage);
                    });
                    
                }
              } else {
                resetValue();
              }
            }
          : null
      }
      onClose={
        showCancel
          ? () => {
              resetValue();
            }
          : null
      }
      textButtonSave={
        clickSaveButton
          ? props.dictionary.s("loading") + " ..."
          : typeValue === "Delete"
          ? props.dictionary.s("delete")
          : props.dictionary.s("close")
      }
      textButtonClose={props.dictionary.s("cancel")}
      propertyButtonSave={{
        block: false,
        style: {
          backgroundColor:
            typeValue === "Delete" ? "#E67070" : general.theme.whiteColor,
          color:
            typeValue === "Delete"
              ? general.theme.whiteFontColor
              : general.theme.primaryColor,
          border:
            typeValue === "Delete"
              ? `1px solid #E67070`
              : `1px solid #665DFF`
        }
      }}
      bsStyleButtonSave="secondary"
      buttonCloseProperties={{
        block: false,
        style: {
          backgroundColor: general.theme.whiteColor,
          border: `1px solid ${general.theme.primaryColor}`,
          color: general.theme.primaryColor
        }
      }}
      properties={{
        centered: true
      }}
      body={
        <>
          {showBody ? (
            <>
              {typeValue === "Delete" ? (
                <div className="deleteSubSpaceText">
                  <div>
                    {props.dictionary.s("deleteSpaceText1")}
                    <b> '{props.workspaceFocused.name}'</b>.
                  </div>
                  <div>{props.dictionary.s("areyousureyouwanttocontinue")}</div>
                </div>
              ) : <div className="deleteSubSpaceText">
              <div>
                {props.dictionary.s("deleteSpaceText2") + ' '}
               <span className="deleteSpaceEmail">support@andonix.com</span>
              </div>
            </div>}
            </>
          ) : (
            spinnerdiv()
          )}
        </>
      }
    />
  );
};

const mapStateToProps = state => {
  const user = state.user;
  return {
    userId: user.id,
    accesstokenidp: state.user.accesstokenidp,
    workSpacesProps:state.workspace,
    lang: state.language.lang,
    workspaceFocused: state.workspace.spaceFocused,
    workspaceSubFocused: state.workspace.workspaceSubFocused,
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    }
  };
};

const mapDispatchToProps = dispatch => ({
  ondeleteSpace: (workSpaceId, accesstokenidp) =>
    dispatch(
      deleteSpace(workSpaceId, accesstokenidp)
    ),
    onUpdatedeleteSpace: (workSpacesProps, workspaceId) =>
    dispatch(updatedeleteSpace(workSpacesProps, workspaceId)),
  onCheckDeleteSpace: (workSpaceId, accesstokenidp) =>
    dispatch(checkDeleteSpace(workSpaceId, accesstokenidp))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeleteSpace));
