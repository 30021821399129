import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Rnd } from 'react-rnd';
import * as roles from "../../constants/roles";
import * as toastType from "../../constants/toastTypes";
import SignatureInputIcon from '../../icons/SignatureInput.png';
import TextInputIcon from '../../icons/TextInput.png';
import { createTransaction, editTransaction } from '../../redux/actions/transactionsActions';
import { getUserListByWorkspaceId } from "../../redux/actions/workspaceActions";
import { translate } from "../../redux/reducers/languageReducer";
import Button from '../commons/styled-components/general/Button';
import { showToast } from "../commons/Toast";
import Checkbox from "../commons/wrapped-components/inputs/Checkbox";
import InputNumber from "../commons/wrapped-components/inputs/inputNumber";
import PDFVisualizer from '../commons/wrapped-components/PDFVisualizer';
import MainNavigation from "../Navigation/MainNavigation";
import AssignTo from "./AssignTo";



const Create = ({ userId, transaction, dictionary, accesstokenidp, spaceFocused, history, onCreateTransactions, onEditTransactions, onGetUserListByWorkspaceId, edit = false, currentTransaction }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState([0, 0]);
  const [canvasSize, setCanvasSize] = useState([0, 0]);
  const [focusedInputIndex, setFocusedInputIndex] = useState(null);
  const [inputs, setInputs] = useState(edit ? currentTransaction.inputs : []);

  const [selectedValue, setSelectedValue] = useState(edit ? currentTransaction.assignTo : "only_admin"); //Save radio button value
  const [userOptions, setUserOptions] = useState([]); //List of users to display in select component
  const [selectedUser, setSelectedUser] = useState(edit ? currentTransaction.assignee : []); //Selected users

  useEffect(() => {
    fetchSpaceUsers();
  }, []);

  const setOptions = (optionItems) => {
    let options = [];
    if (optionItems) {
      options = optionItems.map((option) => {
        if (option.roleId === roles.ADMIN) {
          return { label: option.name + ' ' + option.lastName, value: option.id, disabled: true }
        } else {
          return { label: option.name + ' ' + option.lastName, value: option.id }
        }
      })
    }

    return options;
  }

  const fetchSpaceUsers = async () => {
    const response = await onGetUserListByWorkspaceId(accesstokenidp, spaceFocused._id);
    setUserOptions(setOptions(response.data));
  }

  /*
  {
    pageNumber,
    inputType: 'text' || 'signature',
    fontSize: 0,
    required: true,
    positionX: 'n%',
    positionY: 'n%',
  }
  */

  const addInput = (type) => {
    const newInputs = [...inputs];
    newInputs.push({
      pageNumber,
      inputType: type,
      positionX: 45,
      positionY: 45,
      required: true,
      fontSize: type === 'text' ? 1 : undefined,
      width: type === 'text' ? 15 : 25,
      height: type === 'text' ? 4 : 15,
    });

    setInputs(newInputs);
    setFocusedInputIndex(newInputs.length - 1);
  };

  const onDragStop = (e, d) => {
    const { node, x, y } = d;
    const [canvasWidth, canvasHeight] = canvasSize;
    const newInputs = [...inputs];

    if (x >= canvasWidth || y >= canvasHeight || x < 0 || y < 0) return null;

    newInputs[node.id].positionX = (x * 100) / canvasWidth;
    newInputs[node.id].positionY = (y * 100) / canvasHeight;

    setInputs(newInputs);
  };

  const onResizeStop = (e, direction, ref, delta, position) => {
    const { id, offsetWidth, offsetHeight } = ref;
    const { x, y } = position;
    const [canvasWidth, canvasHeight] = canvasSize;
    const newInputs = [...inputs];

    newInputs[id].width = (offsetWidth * 100) / canvasWidth;
    newInputs[id].height = (offsetHeight * 100) / canvasHeight;


    newInputs[id].positionX = (x * 100) / canvasWidth;
    newInputs[id].positionY = (y * 100) / canvasHeight;

    setInputs(newInputs);
  }

  const getDnDComponent = (obj, index) => {
    const [canvasWidth, canvasHeight] = canvasSize;
    const { inputType, positionX, positionY, width, height, fontSize, required } = obj;

    const size = {
      width: (canvasWidth / 100) * width,
      height: (canvasHeight / 100) * height,
    };

    const position = {
      x: (canvasWidth / 100) * positionX,
      y: (canvasHeight / 100) * positionY,
    };

    if (obj.pageNumber === pageNumber) {
      return (
        <Rnd
          id={index}
          key={index}
          size={size}
          position={position}
          className={`pdf-${inputType}${index === focusedInputIndex ? ' resizable' : ''}${required ? ' input-required' : ''}`}
          onDragStop={onDragStop}
          onResizeStop={onResizeStop}
          bounds="parent"
          onClick={(e) => {
            e.stopPropagation();
            setFocusedInputIndex(index);
          }}
        >
          <div>
            <div className='resizers'>
              <div className='resizer top-left'></div>
              <div className='resizer top-right'></div>
              <div className='resizer bottom-left'></div>
              <div className='resizer bottom-right'></div>
            </div>
            <span style={{ fontFamily: 'Helvetica', fontSize: fontSize ? (canvasHeight / 100) * fontSize : '14px' }}>{`${dictionary.s(inputType)}${required ? '*' : ''}`}</span>
          </div>
        </Rnd>
      );
    }
  };

  const updateCanvasSize = () => {
    const canvas = document.getElementsByClassName('react-pdf__Page__canvas')[0];
    if (canvas) {
      setCanvasSize([canvas.getBoundingClientRect().width, canvas.getBoundingClientRect().height]);
    }
  }

  useLayoutEffect(() => {
    function updateSize() {
      setSize([document.documentElement.clientWidth, document.documentElement.clientHeight]);
      updateCanvasSize();
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setTimeout(updateCanvasSize, 300)
  }

  const editInput = () => {
    const { inputType, required, fontSize } = inputs[focusedInputIndex];

    return (
      <div className='assign-to-container'>
        <p>{dictionary.s(inputType + 'EditInput')}</p>
        {inputType === 'text' ?
          <InputNumber
            label={dictionary.s('fontSize')}
            value={fontSize}
            returnkeytype="next"
            onChangeText={(value) => {
              const newInputs = [...inputs];
              newInputs[focusedInputIndex].fontSize = value;
              setInputs(newInputs);
            }}
            onBlur={() => {
              if (fontSize === '') {
                const newInputs = [...inputs];
                newInputs[focusedInputIndex].fontSize = 3;
                setInputs(newInputs);
              }
            }}
            dictionary={dictionary}
          /> : null
        }
        <div style={{ margin: '10px 0' }}>
          <Checkbox
            value={required}
            className={'transaction-checkbox checkbox'}
            onChange={() => {
              const newInputs = [...inputs];
              newInputs[focusedInputIndex].required = !required;
              setInputs(newInputs);
            }}
          />
          <span style={{ display: 'inline-block', font: '15px Montserrat', verticalAlign: 'top' }}>{dictionary.s('requiredField')}</span>
          <Button
            style={{ margin: '0 auto', display: 'block', fontWeight: 'bold' }}
            variant="danger"
            onClick={() => {
              const newInputs = [...inputs];
              newInputs.splice(focusedInputIndex, 1);
              setFocusedInputIndex(null);
              setInputs(newInputs);
              showToast(toastType.ERROR, dictionary.s('inputDeletedSuccesfully'));
            }}
          >
            {dictionary.s('removeInput')}
          </Button>
        </div>
      </div>
    );
  }

  const pdfWidth = Math.min(Math.max(size[0] * 0.45, 320), 1200);

  // https://safely-media.s3-us-west-2.amazonaws.com/transactions/sample.pdf
  return (
    <MainNavigation title={dictionary.s('transactions')}>
      <span className="reports-header">{dictionary.s('addInputFields')}</span>
      <div className="transaction-container">
        <div className="transaction-header">
          <div className="transaction-filename">{decodeURIComponent((edit ? currentTransaction.url : transaction.PDFLocation).split('/').pop())}</div>
          <div className="transaction-pagination" style={{ width: pdfWidth }}>
            {pageNumber === 1 ? null : <i style={{ cursor: 'pointer' }} onClick={() => setPageNumber(pageNumber - 1)} className="material-icons plus-symbol">navigate_before</i>}
            {`${dictionary.s('page')} ${pageNumber}/${numPages}`}
            {pageNumber === numPages ? null : <i style={{ cursor: 'pointer' }} onClick={() => setPageNumber(pageNumber + 1)} className="material-icons plus-symbol">navigate_next</i>}
          </div>
          <div className="transaction-filename" />
        </div>
        <div className="transaction-row">
          <div className="input-select">
            {dictionary.s('inputFields')}
            <div>
              <div className="input-button" onClick={() => addInput('text')}>
                <img src={TextInputIcon} className="input-icon" />
                {dictionary.s('text')}
              </div>
              <div className="input-button" onClick={() => addInput('signature')}>
                <img src={SignatureInputIcon} className="input-icon" />
                {dictionary.s('signature')}
              </div>
            </div>
          </div>
          <div className="pdf-viewer" style={{ width: pdfWidth }} onClick={() => { setFocusedInputIndex(null) }}>
            <PDFVisualizer
              file={edit ? currentTransaction.url : transaction.PDFLocation}
              onLoadSuccess={onDocumentLoadSuccess}
              PageProperties={{
                pageNumber: pageNumber,
                width: pdfWidth
              }}
            />
            {inputs.map((input, index) => {
              return getDnDComponent(input, index)
            })}
          </div>
          <div className="create-transaction-menu">
            {focusedInputIndex !== null ? editInput() :
              <AssignTo
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                userOptions={userOptions}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              />
            }
          </div>
        </div>
        <div className="transaction-button-container" >
          <Button
            className="cancel-btn"
            onClick={() => history.push('/transactions')}
          >
            {dictionary.s("cancel")}
          </Button>
          <Button
            className="save-btn"
            onClick={() => {
              let userIds = undefined;
              if (selectedValue === 'specific_members') {
                userIds = selectedUser.length ? selectedUser.map(({ value }) => value) : [];
              }

              const body = {
                userId,
                inputs,
                assignTo: selectedValue !== 'specific_members' ? selectedValue : undefined,
                workspaceId: spaceFocused._id,
                name: edit ? currentTransaction.transactionName : transaction.newTransactionName,
                url: edit ? currentTransaction.url : transaction.PDFLocation,
                id: edit ? currentTransaction._id : undefined,
                assignee: userIds
              };

              edit ? onEditTransactions(body) : onCreateTransactions(body);
              history.push('/transactions');
              setTimeout(
                () => {
                  showToast(toastType.SUCCESS, dictionary.s('changesSavedSuccesfully'));
                },
                500
              )

            }}
          >
            {dictionary.s("save")}
          </Button>
        </div>
      </div>
    </MainNavigation>
  );
};

const mapStateToProps = state => ({
  userId: state.user.id,
  transaction: state.transactions,
  currentTransaction: state.transactions.currentTransaction,
  dictionary: {
    s: key => translate(state.language.dictionary, key)
  },
  accesstokenidp: state.user.accesstokenidp,
  spaceFocused: state.workspace.spaceFocused,
});

const mapDispatchToProps = dispatch => ({
  onCreateTransactions: (body) => dispatch(createTransaction(body)),
  onEditTransactions: (body) => dispatch(editTransaction(body)),
  onGetUserListByWorkspaceId: (accesstokenidp, workSpaceId) =>
    dispatch(getUserListByWorkspaceId(accesstokenidp, workSpaceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Create);