import React, { Component } from "react";
import MainNavigation from "../Navigation/MainNavigation";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "../../redux/reducers/languageReducer";

import Report from "./ReportGenerator"

class SubspaceReports extends Component {

  render() {
    return (
      <MainNavigation title={this.props.title}>
        <Report
          customColumns
          type='verificationFormsReport'
          searchBy={'userName'}
          fixedColumns={['id', 'userName', 'dateSubmitted', 'accessGranted']}
          insertIndex={1}
        />
      </MainNavigation>
    );
  }
}

const mapStateToProps = state => {
  return {
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    }
  }
};
export default connect(mapStateToProps, null)(withRouter(SubspaceReports));