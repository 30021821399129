import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { general } from '../../general/global';
import MyBarcodeScanner from '../commons/MyBarcodeScanner';
import { useDispatch, useSelector } from 'react-redux';
import { creatingNewWorkspace, resetWorkspaceCreated, setSpaceFocused, setWorkspaceFocused } from '../../redux/actions/workspaceActions';
import Modal from "../commons/wrapped-components/Modal";
import { ShowMoreButton } from "./ShowMoreButton";
import Space from '../commons/wrapped-components/Space';

const Spaces = ({
    dictionary,
    spaces,
    history,
    hasNextPage,
    handleShowMoreSpaces,
    searchValue,
    totalPages,
    workspacesOld
}) => {
    const [visibleScanner, setVisibleScanner] = useState(false);
    const [workspaceExistModal, setWorkspaceExistModal] = useState(false);
    const [erroMessageValue, setMessageValue] = useState("");

    const dispatch = useDispatch();
    const { loading, workspaceFocused } = useSelector(state => state.workspace);

    const renderQRWorkspaceExist = () => (
        <Modal
            title=" "
            className="animated fadeInDown qrScannerErrorPopup"
            showModal={workspaceExistModal}
            onHide={() => setWorkspaceExistModal(false)}
            onSave={() => {
                setWorkspaceExistModal(false);
            }}
            textButtonSave={dictionary.s("close")}
            bsStyleButtonSave="primary cancel-button-qrcode"
            propertyButtonSave={{
                className: "border-radius-none",
                block: true
            }}
            properties={{
                centered: true
            }}
            body={erroMessageValue}
        />
    );

    return (
        <>
            {renderQRWorkspaceExist()}
            {spaces.length > 0 ?
                spaces.map((item, index) => {
                    const selected = workspaceFocused._id === item._id;
                    return (
                        <Col
                            xs={12} sm={12} md={4} lg={3} key={index}>
                            <Space
                                className={`space-item ${selected ? 'active' : ''} ${general.isChromeAndroid ? 'isAndroid' : ''}`}
                                key={index}
                                onClick={() => {
                                    //--Iterate workpsceOld
                                    const selectSpaceOld = workspacesOld.find(workspaceOld => workspaceOld._id === item._id);
                                    dispatch(setWorkspaceFocused(selectSpaceOld));
                                    dispatch(setSpaceFocused(item));

                                    history.push("/workspaceDetails");
                                }}
                                data={item}
                                dictionary={dictionary}
                                showRole
                            />
                        </Col>
                    );
                })
                :
                <>
                    <Col xs={12}
                        className={"noSpacesFound " + (spaces.length === 0 && searchValue === '' && "thereAreNoSpaces")}
                    >
                        {spaces.length === 0 && loading ?
                            dictionary.s("loading") + '...'
                            : spaces.length === 0 && searchValue !== '' && !loading ?
                                dictionary.s("noSpacesFound")
                                : spaces.length === 0 && searchValue === '' && !loading ?
                                    <>
                                        <span>{dictionary.s('YoudonothaveaccesstoanySpace')}</span>
                                        <div>
                                            <MyBarcodeScanner
                                                visibleScanner={visibleScanner}
                                                closeQrScannerVisible={() => setVisibleScanner(false)}
                                                setMessageValue={setMessageValue}
                                                setWorkspaceExistModal={setWorkspaceExistModal}
                                            />
                                            <span
                                                className="link-item"
                                                onClick={() => {
                                                    dispatch(creatingNewWorkspace(false));
                                                    dispatch(resetWorkspaceCreated());
                                                    history.push("/create-workspace");
                                                }}
                                            >{dictionary.s('createANewWorkspace')}</span>
                                            {` ${dictionary.s('or')} `}
                                            <span
                                                className="link-item"
                                                onClick={() => setVisibleScanner(true)}
                                            >{dictionary.s('JoinanexistingSpace')}</span>
                                        </div>
                                    </> : null
                        }
                    </Col>

                </>
            }
            <ShowMoreButton
                handleShowMore={handleShowMoreSpaces}
                dictionary={dictionary}
                hasNextPage={hasNextPage}
                totalPages={totalPages}
            />
        </>
    )
}

export default Spaces;