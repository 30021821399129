import colorPalette from "./colorPalette";

export default {
  bgNavBar: colorPalette.whiteColor,
  bgMainMenu: colorPalette.aquaLightColor,
  bgActiveMenuItemSidebar: colorPalette.SIDEBAR_ITEM_ACTIVE,
  bgSubmenuSidebar: colorPalette.whiteColor,
  bgButtonSidebar: colorPalette.blueLighterColor,
  bgSidebar: colorPalette.whiteColor,
  bgHoverSidebar: colorPalette.SIDEBAR_ITEM_HOVER,
  fontColorMenuItemSidebar: colorPalette.SIDEBAR_ITEM_FONT,
  whiteFontColor: colorPalette.whiteColor,
  fontColorSubmenuItemSidebar: colorPalette.darkGrayColor,
  fontColorHoverSidebarMenuItems: colorPalette.SIDEBAR_ITEM_HOVER_FONT,
  fontColorActiveMenuItemSidebar: colorPalette.SIDEBAR_ITEM_ACTIVE_FONT,
  fontColorTitleCurrentPage: colorPalette.DARK,
  fontColorPrimary: colorPalette.PRIMARY_DARK,
  placeholderSearchBar: colorPalette.grayColor,
  arrowLeftIcon: colorPalette.aquaColor,
  bgButtonSidebarTop: colorPalette.blueLighterColor,
  bgAndonButton: colorPalette.aquaColor,
  fontColorSearchIcon: colorPalette.grayColor,
  fontColorClearIconSearchBar: colorPalette.grayColor,
  bgSearchBar: colorPalette.greenGrayLightColor,
  placeholderInputLogin: colorPalette.grayLightColor,
  eyeIconColor: colorPalette.grayLightColor,
  copyRightColor: colorPalette.darkGrayColor,
  fontColorUserLogged: colorPalette.aquaColor,
  bgColorDropDownActiveItem: colorPalette.aquaColor,
  bgButtonLogin: colorPalette.blueDarkColor,
  bgLineLeft: colorPalette.SIDEBAR_ITEM_ACTIVE_FONT,
  borderColorInputs: colorPalette.blueDarkColor,
  primaryColor: colorPalette.PRIMARY,
  secondaryColor: colorPalette.SECONDARY,
  dangerColor: colorPalette.DANGER,
  darkColor: colorPalette.DARK,
  grayColor: colorPalette.grayColor,
  gradDarkColor: colorPalette.GRAY_DARK,
  whiteColor: colorPalette.whiteColor,
  redColor: colorPalette.redColor,
  grayMedium: colorPalette.GRAY_MEDIUM,
  redCoral: colorPalette.RED_CORAL,
  grayLighter: colorPalette.GRAY_LIGHTER,
  fontColor: colorPalette.FONT_COLOR,
  borderColorInput: colorPalette.BORDER_COLOR_INPUT,
};
