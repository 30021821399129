import React from 'react';
import { connect } from 'react-redux';
import { translate } from '../../redux/reducers/languageReducer';
import MainNavigation from "../Navigation/MainNavigation";
import Report from "./ReportGenerator"

const TransactionsReport = ({ title, dictionary }) => {
    return (
        <MainNavigation title={title}>
            <Report
                type='transactionsHistory'
                searchBy={'userName'}
                insertIndex={1}
            />
        </MainNavigation>
    )
}

const mapStateToProps = (state) => ({
    dictionary: {
        s: key => translate(state.language.dictionary, key)
    }
});

export default connect(mapStateToProps, null)(TransactionsReport);
