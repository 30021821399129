import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import MainContainer from "../commons/styled-components/dashboard/MainContainer";
import SidebarButton from "./SidebarButton";
import { general } from "../../general/global";
import Backdrop from "../commons/styled-components/dashboard/Backdrop";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import Timer from "../safelypass/Timer";
import { connect } from "react-redux";
import {
  checkOut,
  getAccessAuthorizationActivesList,
  changeSafelyPassStatus,
  onRefreshCheckInRegisterScreen
} from "../../redux/actions/operationActions";
import { translate } from "../../redux/reducers/languageReducer";
import {
  getWorkspacesList,
  setWorkspaceFocused,
  checkIsDeleteSpace,
  checkIsDeletesubSpace,
  getActivesSubspaceCheckIn,
  getCheckIntoSusbpaceList,
  setWorkspaceDisabled,
} from "../../redux/actions/workspaceActions";
import {
  setUserData,
  getUserProfileById
} from "../../redux/actions/userActions";
import { toastSuccess } from "../../components/commons/Toast";
import { postPushData } from "../../redux/actions/postAction";
import Modal from "../commons/wrapped-components/Modal";
import Label from "../commons/styled-components/general/Label";
import { ReactComponent as IconClose } from "../../icons/close-icon-Circle.svg"
import moment from "moment";

const MainNavigation = props => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [collapseSidebar, setCollpaseSidebar] = useState(false);
  const [deviceType, setdeviceType] = useState(window.deviceType);

  const paddingTopMainContainer = "70.52px";
  const elementRef = useRef();
  const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      const updateSize = () => setSize([window.innerWidth, window.innerHeight]);
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  };
  const [width] = useWindowSize();
  const location = useLocation();
  let widthWindow = width;
  let backdrop;

  const onCheckOut = () => props.onCheckOut(props.accessAuthorizationId);

  const checkIsSafelyPassIsActivated = async () => {
    if (props.loading) return;
    const { status, data } = await props.onGetAccessAuthorizationActivesList(props.userId);
    if (status && data.length) {
      const space = props.workSpacesList.find(
        x => x._id === data[0].workSpaceId
      );
      if (window.location.hash !== "#/workspaceDetails") {
        activateSafelyPass(space, data[0]);
        await props.onGetCheckIntoSubspaceList(props.workSpaceId, props.userId, props.accesstokenidp);
        props.onGetActivesSubspaceCheckIn(props.userId, props.accesstokenidp);
      }
    } else if (props.isSafelyPassActivated && props.safelyPassGrantedDate) {
      props.onChangeSafelyPassStatus("inactive");
    }
  };

  const onFocus = () => {
    if (document.visibilityState === 'visible') {
      switch (window.location.hash) {
        case "#/safelypass":
        case "#/userVerification":
        case "#/subWorkspacelist":
        case "#/subWorkspaceListAddmember":
        case "#/workspaceDetails":
          showSpacedeleteMessage();
          syncData(window.location.hash);
          break;
        case "#/workspacelist":
          syncData(window.location.hash);
          break;
        case "#/users":
        case "#/reportlist":
        case "#/reportlist/active-pass":
        case "#/reportlist/passes-rejected":
        case "#/reportlist/check-ins":
        case "#/selfdeclarationReport":
        case "#/verificationReport":
          showSpacedeleteMessage();
          break;
        default:
          return;
      }
    }
  };

  const showSpacedeleteMessage = () => {
    props
      .oncheckIsDeleteSpace(props.workSpaceId, props.accesstokenidp)
      .then(responseWorkspace => {
        if (
          responseWorkspace && responseWorkspace.status === false &&
          responseWorkspace.httpCode === 404
        ) {
          props.history.push("/workspacelist");
          toastSuccess(responseWorkspace.axActions[0][0].message);
        }
      });
  };

  const syncData = async type => {
    if (type === "#/subWorkspaceListAddmember") {
      props
        .oncheckIsDeletesubSpace(
          props.workSpaceId,
          props.workspaceSubFocused._id,
          props.accesstokenidp
        )
        .then(responseWorkspace => {
          if (
            responseWorkspace && responseWorkspace.status === false &&
            responseWorkspace.httpCode === 404
          ) {
            props.history.push("/subWorkspacelist");
            toastSuccess(responseWorkspace.axActions[0][0].message);
          }
        });
    }
    await props.onGetWorkspacesList(props.userId, props.accesstokenidp);
    if (type === "#/workspaceDetails" && props.postDetails.length > 0) {
      await props.onpostPushData(
        props.workSpaceId,
        props.userId,
        props.accesstokenidp,
        1,
        props.postDetails.length > 10 ? (Math.ceil(props.postDetails.length / 10) * 10) : 10
      );
    }
    await checkIsSafelyPassIsActivated();
    await syncUserData();
    props.onRefreshCheckInRegisterScreen(general.guid());
  };

  const syncUserData = async () => {
    const { status, data } = await props.onGetUserProfileById(props.userId);
    if (status) {
      const {
        email,
        countryCode,
        mobilePhone,
        userName,
        name,
        lastName,
        picture,
        workSpaces,
        backgroundPicture
      } = data;
      props.onSetUserData({
        email,
        countryCode,
        mobilePhone,
        userName,
        name,
        lastName,
        picture,
        workSpaces,
        backgroundPicture
      });
    }
  };

  const activateSafelyPass = (space, { accessGranted, grantDate, ...rest }) => {
    const safelyPassStatus = accessGranted ? "granted" : "denied";
    if (space) props.onSetWorkspaceFocused(space);
    props.onChangeSafelyPassStatus(safelyPassStatus, {
      accessGranted,
      grantDate: new Date(grantDate),
      ...rest
    });
  };

  const addingColors = () => {
    const root = document.documentElement;
    root.style.setProperty("--bg-main-menu", general.theme.bgMainMenu);
    root.style.setProperty(
      "--bg-submenu-sidebar",
      general.theme.bgSubmenuSidebar
    );
    root.style.setProperty(
      "--bg-onhover-sidebar",
      general.theme.bgHoverSidebar
    );
    root.style.setProperty(
      "--bg-active-menu-item-sidebar",
      general.theme.bgActiveMenuItemSidebar
    );
    root.style.setProperty(
      "--font-color-menu-sidebar",
      general.theme.fontColorMenuItemSidebar
    );
    root.style.setProperty(
      "--font-color-onhover-sidebar",
      general.theme.fontColorHoverSidebarMenuItems
    );
    root.style.setProperty(
      "--font-color-submenu-sidebar",
      general.theme.fontColorSubmenuItemSidebar
    );
    root.style.setProperty(
      "--font-color-active-menu-item-sidebar",
      general.theme.fontColorActiveMenuItemSidebar
    );
    root.style.setProperty(
      "--bg-active-dropdown-item",
      general.theme.bgColorDropDownActiveItem
    );
    root.style.setProperty("--bg-line-selected-item", general.theme.bgLineLeft);
  };

  useEffect(() => {
    addingColors();
    elementRef.current.scrollTop = 0;
    const handleResize = () => setdeviceType(window.deviceType);
    window.addEventListener("resize", handleResize);
    window.addEventListener("visibilitychange", onFocus);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("visibilitychange", onFocus);
    };
  }, [props.workSpacesList, props.workSpaceId, props.postDetails]);

  const drawerToggleClickHandler = () => setSidebarOpen(!sidebarOpen);

  const backdropClickHandler = () => setSidebarOpen(false);

  const handleCollapseSidebar = () => setCollpaseSidebar(!collapseSidebar);

  if (sidebarOpen) {
    backdrop = <Backdrop onClick={backdropClickHandler} />;
  }

  const onSelectedMenuItem = () => {
    if (widthWindow <= 768) {
      backdropClickHandler();
    }
  };

  const closeModal = () => {
    props.onSetWorkspaceDisabled(false);
    if (props.userId === props.workspaceDisabled.data.userId) {
      props.history.push("/workspaceDetails");
    }
    else {
      if (props.workspaceDisabled.redirect)
        props.history.push(props.workspaceDisabled.redirect);
      else
        props.history.goBack();
    }
  }

  const DisableSafelyPassModal = () => {
    let _data = props.workspaceDisabled.data;
    return (
      <Modal
        title={" "}
        showModal={props.workspaceDisabled.showModal}
        onHide={() => closeModal()}
        onSave={() => closeModal()}
        textButtonSave={props.dictionary.s("close")}
        bsStyleButtonSave="outline-primary"
        propertyButtonSave={{ className: "border-radius-none", }}
        properties={{ centered: true, }}
        body={
          <div className="workspace-disable-content">
            <div className="workspace-disable-title">
              <IconClose width="40px" height="40px" />
              <Label className="heading_1">{props.dictionary.s("safelyPassDeactivated")}</Label>
            </div>
            <div className="workspace-disable-body">
              {_data &&
                <span>
                  {props.userId === _data.userId ?
                    props.dictionary.s('your') :
                    <span className="bold">{`${_data.userFirstName.trim()} ${_data.userLastName.trim()}'s`}</span>}
                  {` ${props.dictionary.s("workspaceDisabledMessage")}`}<span className="bold">{` '${_data.workspaces[0].name}' `}</span>
                  {`${props.dictionary.s("from")} ${moment(_data.startDate).format("MMM D, YYYY")} 
                ${props.dictionary.s("to")} ${moment(_data.endDate).format("MMM D, YYYY")}`}
                </span>
              }
            </div>
          </div>
        }
      />
    )
  };

  return (
    <>
      <Sidebar
        width={widthWindow}
        openSidebar={drawerToggleClickHandler}
        onselectedmenuitem={onSelectedMenuItem}
        show={sidebarOpen}
        handleCollapseSidebar={handleCollapseSidebar}
        collapseSidebar={collapseSidebar}
      />
      {widthWindow < 1200 && (
        <SidebarButton
          iconClassName="icon-angleLeft"
          sidebarBgColor={general.theme.bgButtonSidebarTop}
          iconColor={general.theme.arrowLeftIcon}
          position="fixed"
          left={0}
          marginLeft={"0px"}
          rotate180dg="rotate(180deg)"
          visibility={!sidebarOpen ? "visible" : "hidden"}
          transition="visibility 0.3s linear 0.6s, opacity 0.3s linear 0.6s"
          top="0px"
          onClick={drawerToggleClickHandler}
        />
      )}
      {DisableSafelyPassModal()}
      {backdrop}
      <MainContainer
        paddingleft={
          widthWindow > 1200 && (collapseSidebar ? "82.58px" : "260px")
        }
        paddingTop={paddingTopMainContainer}
        className="main-container"
        ref={elementRef}
      >
        <Header
          marginleft={collapseSidebar ? "82.58px" : "260px"}
          screenwidth={widthWindow}
          title={props.title}
        />
        <Timer
          isActive={props.isSafelyPassActivated}
          value={props.safelyPassGrantedDate}
          onFinish={() => onCheckOut()}
          isVisible={false}
        />
        <div
          className={
            location.pathname.replace("/", "") +
            " sub-container mb-3 " +
            deviceType
          }
        >
          <ToastContainer />
          {props.children}
        </div>
      </MainContainer>
    </>
  );
};

const mapStateToProps = state => ({
  isSafelyPassActivated: state.operation.safelyPassStatus === "granted",
  accessAuthorizationId: state.operation.accessAuthorizationId,
  dictionary: {
    s: key => translate(state.language.dictionary, key)
  },
  safelyPassGrantedDate: state.operation.safelyPassGrantedDate,
  userId: state.user.id,
  workSpaceId: state.workspace.workspaceFocused._id,
  workSpacesList: state.workspace.list,
  workspaceSubFocused: state.workspace.workspaceSubFocused,
  accesstokenidp: state.user.accesstokenidp,
  postDetails: state.posts.postData,
  checkIntoSubspaceList: state.workspace.checkIntoSubspaceList,
  activesSubspaceCheckIn: state.workspace.activesSubspaceCheckIn,
  workspaceDisabled: state.workspace.workspaceDisabled,
});

const mapDispatchToProps = dispatch => ({
  onCheckOut: id => dispatch(checkOut(id)),
  onGetAccessAuthorizationActivesList: userId =>
    dispatch(getAccessAuthorizationActivesList(userId)),
  onChangeSafelyPassStatus: (status, extraInfo) =>
    dispatch(changeSafelyPassStatus(status, extraInfo)),
  onGetWorkspacesList: (userId, accesstokenidp) =>
    dispatch(getWorkspacesList(userId, accesstokenidp)),
  onGetUserProfileById: userId => dispatch(getUserProfileById(userId)),
  onSetUserData: user => dispatch(setUserData(user)),
  onSetWorkspaceFocused: workspace => dispatch(setWorkspaceFocused(workspace)),
  onRefreshCheckInRegisterScreen: refresh =>
    dispatch(onRefreshCheckInRegisterScreen(refresh)),
  oncheckIsDeleteSpace: (workSpaceId, accesstokenidp) =>
    dispatch(checkIsDeleteSpace(workSpaceId, accesstokenidp)),
  onpostPushData: (workspaceId, userId, accesstokenidp, pageNo, limit) =>
    dispatch(postPushData(workspaceId, userId, accesstokenidp, pageNo, limit)),
  oncheckIsDeletesubSpace: (workspaceId, subspaceId, accesstokenidp) =>
    dispatch(checkIsDeletesubSpace(workspaceId, subspaceId, accesstokenidp)),
  onGetCheckIntoSubspaceList: (workSpaceId, userId, accesstokenidp) =>
    dispatch(getCheckIntoSusbpaceList(workSpaceId, userId, accesstokenidp)),
  onGetActivesSubspaceCheckIn: (userId, accesstokenidp) =>
    dispatch(getActivesSubspaceCheckIn(userId, accesstokenidp)),
  onSetWorkspaceDisabled: (showModal, data = null) =>
    dispatch(setWorkspaceDisabled(showModal, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MainNavigation));