export default {
  aquaColor: "#13988A",
  darkColor: "#000",
  whiteColor: "#FFFFFF",
  aquaActiveDark: "#00695E",
  aquaActiveLight: "#AAE8E1",
  aquaActiveLighter: "#E7F6F5",
  greenColor: "#69A142",
  purpleColor: "#66499E",
  orangeColor: "#FF9E1B",
  blueColor: "#102ed5",
  orangeRedColor: "#F1582C",
  aquaLightColor: "#F6FFFE",
  grayColor: "#A7A6A6",
  greenGrayLightColor: "#EEF5F5",
  grayLightColor: "#D5D3D3",
  darkGrayColor: "#756C61",
  blueDarkColor: "#1849E8",
  blueLightColor: "#007bff",
  blueLighterColor: "#9edbfc",
  blueSubLightColor: "#bce3fa",
  blueLightGreenColor: "#2f738e",
  PRIMARY: "#665EFF",
  PRIMARY_DARK: "#2B2B42",
  SECONDARY: "#707070",
  DANGER: "#FF0007",
  DARK: "#454F63",
  GRAY_DARK: "#7E7B7B",
  TRANSPARENT: 'transparent',
  BODY_BG: '#f1f5fd',
  redColor: "#FF0000",
  SIDEBAR_ITEM_FONT: '#3E3E3E',
  SIDEBAR_ITEM_HOVER: '#F4F4F4',
  SIDEBAR_ITEM_HOVER_FONT: '#5A5A5A',
  SIDEBAR_ITEM_ACTIVE: '#EDF3FF',
  SIDEBAR_ITEM_ACTIVE_FONT: '#665DFF',
  GRAY_MEDIUM: '#DDDDDD',
  RED_CORAL: '#E67070',
  GRAY_LIGHTER: '#F7F7F7',
  FONT_COLOR: '#6e7e88',
  BORDER_COLOR_INPUT: "#9BAAC7",
};
