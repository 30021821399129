import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { getReportData } from "../../redux/actions/reports";
import { translate } from "../../redux/reducers/languageReducer";
import { getColumns, getCustomColumns } from '../../constants/reportsColums';
import { exportReportTypes } from '../../constants/exportReportTypes';
import ReportFilters from "./ReportFilters";
import { datePeriodOptions } from "../../constants/datePeriods";
import ExportReportButton from "./ExportReportButton";
import Table from "../commons/wrapped-components/Table";

class ReportGenerator extends Component {
    constructor(props) {
        super(props);
        const todayDate = moment(new Date()).format("YYYY-MM-DD");
        this.state = {
            sizePerPage: 10,
            paginationPage: 1,
            showAll: false,
            deviceType: window.deviceType,
            showFilter: true
        }
        this.filterValues = {
            startDate: todayDate,
            endDate: todayDate,
            searchString: ''
        }
        this.paginationState = {
            startIndex: 0,
            endIndex: 10,
            page: 1,
            sizePerPage: 10,
        };
    }

    handleResize = () => {
        this.setState({ deviceType: window.deviceType });
    }

    componentDidMount() {
        let todayDate = moment(new Date()).format("YYYY-MM-DD");
        this.fetchReportData(todayDate, todayDate); // Default period= Today
        window.addEventListener("resize", this.handleResize);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.workSpaceFocused._id !== this.props.workSpaceFocused._id) {
            let todayDate = moment(new Date()).format("YYYY-MM-DD");
            this.fetchReportData(todayDate, todayDate);
        }
    }


    fetchReportData = (startDate = '', endDate = '', searchString = '') => {
        const { workSpaceFocused, type, getReportData, userDetails, accesstokenidp } = this.props;
        const { page, sizePerPage } = this.paginationState;
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const startDateUTC = startDate !== "" ? moment.tz(startDate + ' 00:00:00', timeZone).utc().format() : "";
        const endDateUTC = endDate !== "" ? moment.tz(endDate + ' 23:59:59', timeZone).utc().format() : "";
        getReportData(
            {
                searchString,
                startDate: startDateUTC,
                endDate: endDateUTC,
                timeZone,
                userId: userDetails.id,
                workSpaceId: workSpaceFocused._id
            },
            type,
            page,
            sizePerPage,
            accesstokenidp
        );
    }

    getPeriodOptions = (datePeriodOptions) => {
        const periods = [];
        datePeriodOptions.map((period, index) => {
            periods.push({ name: this.props.dictionary.s(period.value), value: period.value })
        })
        return periods;
    }

    /* Local dataset search
        getReportsData = () => {
            const { data, type, searchBy } = this.props;
            const { searchString } = this.state;
            if (data[type] === undefined) return undefined;
            if (searchString.trim() !== '') {
                if (Array.isArray(searchBy)) {
                    return data[type].reportData.filter(row => {
                        const searcheableArray = [];
                        searchBy.map(column => {
                            if (row[column] !== undefined) searcheableArray.push(row[column].toLowerCase());
                        });
                        return searcheableArray.join('|').indexOf(searchString.toLowerCase()) !== -1
                    });
                }
                return data[type].reportData.filter(row => row[searchBy].toLowerCase().indexOf(searchString.toLowerCase()) !== -1);
            } else {
                return data[type].reportData;
            }
        }
    */

    handleSearch = searchString => {
        const { startDate, endDate } = this.filterValues;
        this.filterValues = { ...this.filterValues, searchString };
        this.paginationState = { ...this.paginationState, page: 1, sizePerPage: 10 }
        this.fetchReportData(startDate, endDate, searchString);
    };

    handleOnChange = (workSpaceId, startDate, endDate) => {
        this.filterValues = {
            startDate,
            endDate,
            searchString: '',
        };
        this.fetchReportData(startDate, endDate, '');
    };

    onTableChange = (type, newState) => {
        const { page, sizePerPage } = newState;
        const { startDate, endDate, searchString } = this.filterValues;

        this.paginationState = {
            page,
            sizePerPage,
            startIndex: ((page - 1) * sizePerPage),
            endIndex: page * sizePerPage
        };

        this.fetchReportData(startDate, endDate, searchString)
    }

    getExportReportBody = () => {
        const { workSpaceFocused, type, userDetails } = this.props;
        const { startDate, endDate, searchString } = this.filterValues;
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const startDateUTC = startDate !== "" ? moment.tz(startDate + ' 00:00:00', timeZone).utc().format() : "";
        const endDateUTC = endDate !== "" ? moment.tz(endDate + ' 23:59:59', timeZone).utc().format() : "";


        return {
            timeZone,
            reportType: exportReportTypes[type],
            startDate: startDateUTC,
            endDate: endDateUTC,
            userId: userDetails.id,
            workSpaceId: workSpaceFocused._id
        }
    }

    getWorkaroundData = originalData => originalData.map(row => {
        const cleanRow = {};
        Object.keys(row).map(key => cleanRow[key.replace(/\./g, "_")] = row[key]);
        return cleanRow;
    });

    render() {
        const { loading, type, dictionary, data, exportButton, onlySearchBox, customColumns, fixedColumns, insertIndex } = this.props;
        const { deviceType, showFilter, showAll } = this.state;
        const dataLength = data[type] === undefined ? undefined : data[type].totalCount;
        const reportData = data[type] === undefined ? undefined : this.getWorkaroundData(data[type].reportData); // this.getReportsData();
        const columns = customColumns ? getCustomColumns(type, dictionary, reportData, fixedColumns, insertIndex) : getColumns(type, dictionary, data);
        return (
            <div className="report-container">
                <Row>
                    <Col className="report-title" md={12} xs={12} sm={12} xl={12} lg={12}>
                        <h3>{dictionary.s(type)}</h3>
                        {exportButton ? <ExportReportButton disabled={!dataLength} exportReportBody={this.getExportReportBody()} setFilterVisible={(show) => this.setState({ showFilter: show })} showFilter={showFilter} /> : null}
                    </Col>
                </Row>
                {showFilter ? (
                    <ReportFilters
                        onChange={this.handleOnChange}
                        options={this.getPeriodOptions(datePeriodOptions)}
                        handleSearch={this.handleSearch}
                        onlySearchBox={onlySearchBox}
                    />
                ) : null
                }
                <div className="report-table checkin-table">
                    {reportData && (
                        <Table
                            columns={columns}
                            data={reportData}
                            loading={loading}
                            customPagination={true}
                            noDataIndication={loading ? dictionary.s("loading") : dictionary.s("noResultFound")}
                            striped={false}
                            hover={false}
                            editable={true}
                            keyField={"id"}
                            condesed={true}
                            dictionary={dictionary}
                            paginationSize={10}
                            sizePerPageList={[{
                                id: '10', text: '10', value: 10
                            }, {
                                id: '50', text: '50', value: 50
                            }, {
                                id: 'all', text: dictionary.s("All"), value: dataLength
                            }]
                            }
                            paginationOptions={{
                                totalSize: dataLength,
                                ...this.paginationState
                            }}
                            remote={{
                                filter: false,
                                pagination: true,
                                sort: false,
                                cellEdit: false
                            }}
                            onTableChange={this.onTableChange}
                        />
                    )}
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }
}

ReportGenerator.defaultProps = {
    exportButton: true,
    searchBy: 'user_name',
    onlySearchBox: false,
    customColumns: false,
    fixedColumns: [],
    insertIndex: 0,
}

const mapStateToProps = state => ({
    accesstokenidp: state.user.accesstokenidp,
    data: state.reports.data,
    loading: state.reports.loading,
    message: state.reports.message,
    userDetails: state.user,
    workSpaceFocused: state.workspace.workspaceFocused,
    dictionary: {
        s: key => translate(state.language.dictionary, key)
    }
});
const mapDistchToProps = dispatch => ({
    getReportData: (requestBody, apiEndpointName, page, limit, accesstokenidp) => dispatch(getReportData(requestBody, apiEndpointName, page, limit, accesstokenidp))
});
export default connect(mapStateToProps, mapDistchToProps)(ReportGenerator);
