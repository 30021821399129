import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Table from "../commons/wrapped-components/Table";
import MainNavigation from "../Navigation/MainNavigation";
import { ReactComponent as EditIcon } from "../../icons/editIcon.svg";
import { connect } from "react-redux";
import {
  setMessage,
  editUserData,
  updateUserSubSpaces,
} from "../../redux/actions/userActions";
import { getRolesList } from "../../redux/actions/roleActions";
import {
  getWorkspacesList,
  removeMemberSubWorkspace,
  getPaginatedUser
} from "../../redux/actions/workspaceActions";
import { translate } from "../../redux/reducers/languageReducer";
import { toastSuccess, toastError } from "../commons/Toast.js";
import { ToastSuccess, ToastError } from "../../redux/actions/toastAction";
import { DesactiveToast } from "../../redux/actions/toastAction";
import DisableSafelyPassPopUp from "../commons/disableSafelyPassModal";
import EnableSafelyPassPopUp from "../commons/enableSafelyPassModal";
import DeleteUser from './deleteUser';
import InputSearch from "../commons/wrapped-components/inputs/InputSearch";

const ManageUser = (props) => {
  useEffect(() => {
    load();
    if (props.toastMessage) showToast(props.isToastSuccess);
  }, [props.refreshUserManagementScreen, props.toastMessage, props.workSpaceFocused._id]);

  const [searchVal, setSearchVal] = useState("");
  const [showDeleteMember, setShowDeleteMember] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [showEnableModal, setShowEnableModal] = useState(false);
  const [memberData, setMemberData] = useState(null);

  // Pagination state
  const [paginationState, setPaginationState] = useState({
    startIndex: 0,
    endIndex: 10,
    page: 1,
    sizePerPage: 10,
  })

  const load = async () => {
    const { page, sizePerPage } = paginationState;
    await props.onGetPaginatedUser({ workSpaceId: props.workSpaceFocused._id, searchString: searchVal, adminId: props.userId }, page, sizePerPage, props.accesstokenidp);
    await props.onGetRolesList();
    await props.onGetWorkspacesList(props.userId, props.accesstokenidp, true);

  };

  const getUsers = (page, sizePerPage, searchVal) => {
    props.onGetPaginatedUser({ workSpaceId: props.workSpaceFocused._id, searchString: searchVal, adminId: props.userId }, page, sizePerPage, props.accesstokenidp);
  }

  const showToast = isToastSuccess => {
    if (isToastSuccess) { toastSuccess(props.toastMessage); }
    else { toastError(props.toastMessage); }
    props.onDesactiveToast();
  };

  const onUserRowEditClick = (userData) => {
    props.onEditUserData(userData);
    props.history.push('/useredit');
  }

  const onUserRowDisableBtnClick = (userData) => {
    userData['userFullName'] = userData.name; // adding userFullName parameter for the enable & disable popups
    setMemberData(userData);
    userData.disabledSpaces.length === 0 ? setShowDisableModal(true) : setShowEnableModal(true);
  }

  const CloseDisableModal = () => {
    setMemberData(null);
    setShowDisableModal(false);
  }

  const CloseEnableModal = () => {
    setMemberData(null);
    setShowEnableModal(false);
  }

  const editFormatterName = (cell, row) => {
    const isUserDisabled = row.disabledSpaces.find((space) => space._id === props.workSpaceFocused._id);
    return <span>
      {row.picture ? <img src={row.picture} alt="user picture" className="usermgmt-user-pic rounded-circle mr-2" /> : null}
      <a
        className="nameHyperLink"
        href={"#/subspaceHistory?user_id=" + row.userId}
        >
          {cell}
        </a>
        {isUserDisabled && <i className="material-icons userDisabledIcon">block</i> }
      </span>;
  }
  
  const editFormatterAction = (cell, row) => {
    // logged-in user is an Owner
    if (row.userId === props.userId && row.spaceOwner) {
      return (
        <div className="actionIcons">
          <EditIcon onClick={() => onUserRowEditClick(row) } />
          <i className="material-icons" onClick={() => onUserRowDisableBtnClick(row) }>block</i> 
        </div> 
      );
    } // logged-in user is an admin but not owner
    else if (row.userId !== props.userId && row.spaceOwner) {
      return (
        <div className="actionIcons">
          <i className="material-icons only-disable" onClick={() => onUserRowDisableBtnClick(row) }>block</i> 
        </div> 
      );
    } 
    // other roles
    else if (row.userId !== props.workSpaceFocused.spaceOwner) {
      return (
        <div className="actionIcons">
          <EditIcon onClick={() => onUserRowEditClick(row) } />
          <i className="material-icons" onClick={() => onUserRowDisableBtnClick(row) }>block</i> 
          <i className="material-icons" onClick={() => onClickOfDeleteMember(row) }>highlight_off</i> 
        </div> 
      );
    }
  }

  const rowClasses = (row, rowIndex) => {
    const isUserDisabled = row.disabledSpaces.find((space) => space._id === props.workSpaceFocused._id);
    if (isUserDisabled) {
      return 'userDisabledRow';
    }
  }
  const onClickOfDeleteMember = (data) => {
    setMemberData(data);
    setShowDeleteMember(true);
  }

  const CloseDeleteMemberModal = () => {
    setMemberData(null);
    setShowDeleteMember(false);
  }

  const builColumns = () => {
    const newColumns = [
      { dataField: "userId", text: "user_id", hidden: true },
      { dataField: "name", text: props.dictionary.s('name').toUpperCase(), editable: false, formatter: editFormatterName },
      { dataField: "userName", text: props.dictionary.s('editUserUserName').toUpperCase(), editable: false },
      { dataField: "role", text: props.dictionary.s('role').toUpperCase(), editable: false },
      {
        dataField: "action",
        text: props.dictionary.s('actions').toUpperCase(),
        editable: false,
        formatter: editFormatterAction,
      }
    ];
    return newColumns;
  };

  const buildRows = (users) => {
    const rows = [];
    if (users) {
      users.map((user) => {
        const attributes = {};
        let rolefilter = props.rolesList.filter(roleItem => user.roleId === roleItem.id);
        let role = rolefilter && rolefilter[0] ? rolefilter[0].visibleName : null;

        rows.push({
          ...attributes,
          userId: user.id,
          picture: user.picture,
          name: user.name + " " + user.lastName,
          firstName: user.name,
          lastName: user.lastName,
          spaceOwner: user.spaceOwner,
          userName: user.userName && user.userName.length > 0 ? user.userName : "-",
          role: role,
          disabledSpaces: user.disabledSpaces,
          workspaceBelong: user.workspaceBelong,
          active: user.active,
          checkedInWorkSpaceId: user.checkedInWorkSpaceId,
        });
      });
    }
    return rows
  };

  const columns = builColumns();
  const data = buildRows(
    props.paginatedUsers,
    props.workSpaceFocused.subspaces,
  );

  const onTableChange = (type, newState) => {
    const { page, sizePerPage } = newState;
    setPaginationState({
      page,
      sizePerPage,
      startIndex: ((page - 1) * sizePerPage),
      endIndex: page * sizePerPage
    });
    getUsers(page, sizePerPage, searchVal);
  }

  /*
  const filteredData = (input) => {
    let SortData = data.sort(function (a, b) {
      var nameA = a.userName.toUpperCase();
      var nameB = b.userName.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    let filteredInput = SortData;
    if (input && input.length > 0) {
      const newInput = replaceDiacritics(input.toLowerCase());
      filteredInput = SortData.filter(
        d => (
          replaceDiacritics(d.userName.toLowerCase()).includes(newInput) ||
          replaceDiacritics(d.email.toLowerCase()).includes(newInput) ||
          d.phone.includes(newInput) ||
          replaceDiacritics(d.role.toString().toLowerCase()).includes(newInput) ||
          replaceDiacritics(d.subSpace.toString().toLowerCase()).includes(newInput)
        )
      );
    }
    return filteredInput;
  }
  */

  return (
    <MainNavigation title={props.title}>
      <Container fluid className="userManagement-table">
        <DeleteUser
          data={memberData}
          load={load}
          showDeleteMember={showDeleteMember}
          closeDeleteModal={CloseDeleteMemberModal}
        />
        <DisableSafelyPassPopUp 
          load={load}
          userData={memberData}
          showDisableModal={showDisableModal} 
          closeDisableModal={CloseDisableModal}
        />
        <EnableSafelyPassPopUp 
          load={load}
          userData={memberData} 
          showEnableModal={showEnableModal}
          closeEnableModal={CloseEnableModal}
        />
        <Row className="user-controls">
          <Col className="workspace-selector" xs={12} sm={12} lg={4} md={4}>
          </Col>
          <Col xs={12} sm={12} lg={3} md={3}>
            <InputSearch
              searchValue={searchVal}
              onChangeText={setSearchVal}
              handleSearch={(value) => {
                const page = 1;
                const sizePerPage = paginationState.sizePerPage;
                getUsers(page, sizePerPage, value);
                setPaginationState({
                  page,
                  sizePerPage,
                  startIndex: ((page - 1) * sizePerPage),
                  endIndex: page * sizePerPage
                });
                if (value === '') setSearchVal('');
              }}
              className='input-search-container'
            />
          </Col>
        </Row>
        <Row>
          <Col className="users-table">
            <Table
              columns={columns}
              data={data}
              dictionary={props.dictionary}
              customPagination={true}
              loading={props.rolesLoading || props.workSpacesLoading}
              noDataIndication={props.rolesLoading || props.workSpacesLoading ? props.dictionary.s("loading") : props.dictionary.s("noResultFound")}
              striped={false}
              hover={false}
              editable={true}
              cellEdit={{ mode: 'click' }}
              condesed={true}
              sizePerPageList={[
                {
                  id: '10', text: '10', value: 10
                }, {
                  id: '20', text: '20', value: 20
                }, {
                  id: 'all', text: props.dictionary.s('All'), value: props.totalPaginatedUsers
                }
              ]}
              paginationOptions={{
                totalSize: props.totalPaginatedUsers,
                ...paginationState
              }}
              remote={{
                filter: false,
                pagination: true,
                sort: false,
                cellEdit: false
              }}
              onTableChange={onTableChange}
              rowClasses={rowClasses}
            />
          </Col>
        </Row>
      </Container>
    </MainNavigation>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.user.id,
    paginatedUsers: state.workspace.paginatedUsers,
    totalPaginatedUsers: state.workspace.totalUsers,
    rolesList: state.role.list,
    rolesLoading: state.role.loading,
    workSpaceFocused: state.workspace.workspaceFocused,
    workSpacesList: state.workspace.list,
    workSpacesLoading: state.workspace.loading,
    message: state.user.message,
    subSpaces: state.workspace.workspaceFocused.subspaces,
    accesstokenidp: state.user.accesstokenidp,
    dictionary: {
      s: (key) => translate(state.language.dictionary, key),
    },
    isToastSuccess: state.toast.isSuccess,
    toastMessage: state.toast.message,
    refreshUserManagementScreen: state.workspace.refreshUserManagementScreen,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetWorkspacesList: (userId, accesstokenidp, isUserRegistered) =>
    dispatch(getWorkspacesList(userId, accesstokenidp, isUserRegistered)),
  onGetRolesList: () => dispatch(getRolesList()),
  onSetMessage: (message) => dispatch(setMessage(message)),
  updateUserSubSpaces: (workSpaceId, userId, subspaceIds) =>
    dispatch(updateUserSubSpaces(workSpaceId, userId, subspaceIds)),
  removeMemberSubWorkspace: (userId, workSpaceId, subspaceId) =>
    dispatch(removeMemberSubWorkspace(userId, workSpaceId, subspaceId)),
  onToastSuccess: (message) => dispatch(ToastSuccess(message)),
  onToastError: (message) => dispatch(ToastError(message)),
  onDesactiveToast: () => dispatch(DesactiveToast()),
  onEditUserData: (userData) => dispatch(editUserData(userData)),
  onGetPaginatedUser: (body, page, limit, accesstokenidp) =>
    dispatch(getPaginatedUser(body, page, limit, accesstokenidp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageUser);