import React, { useState } from "react";
import { connect } from "react-redux";
import { translate } from "../../redux/reducers/languageReducer";
import Modal from "../commons/wrapped-components/Modal";
import TakePhotoOrVideo from "./TakePhotoOrVideo";
import EditImage from "./EditImage";
import Addpost from "./Addpost";
import { ReactComponent as CameraIcon } from "../../icons/camera.svg";
import { ReactComponent as UploadIcon } from "../../icons/icon_upload.svg";
import { 
  spinnerPost,
  changedCaption,
  changedSubspace,
} from "../../redux/actions/postAction";

const PhotoOrVedioPopup = props => {
  const [showTakePhotoOrVedio, setShowTakePhotoOrVedio] = useState(false);
  const [showModalAddpost, setShowModalAddpost] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [videoData, setVideoData] = useState(null);
  const [showEditImage, setShowEditImage] = useState(false);

  const closeTakePhotoOrVideo = () => {
    setShowTakePhotoOrVedio(false);
  };

  const closeAddpostsVideo = () => {
    setShowModalAddpost(false);
  };

  const fileReader = (file, { target }) => {
    let reader = new FileReader();
    if (file && file.type.split('/')[0] ==='image' ) {
      reader.onloadend = () => {
        setImageData(reader.result);
        props.onClosePhotoOrVedioPopUp();
        setShowEditImage(true);
      };
      reader.readAsDataURL(file);
    }
    else if (file && file.type.split('/')[0] ==='video') {
      props.onspinnerPost(true);
      props.onClosePhotoOrVedioPopUp();
      reader.onloadend = () => {
        setVideoData(reader.result);
        setShowModalAddpost(true);
        props.onspinnerPost(false);
      };
      reader.readAsDataURL(file);
    }
  };

  const galleryClick = () => {
    if(props.showModalPhotoOrVedio){
   document.querySelector('#contained-button-file').click();
    }
  }

  const handleFileChange = (event) => {
    fileReader(event.target.files[0], event);
  }

  const closeEditImage = () => {
    setShowEditImage(false);
  };

  const renderModalPhotoOrVedio = () => (
    <div>
      <TakePhotoOrVideo
        showModal={showTakePhotoOrVedio}
        onCloseTakePhotoOrVideo={closeTakePhotoOrVideo}
      />
      <EditImage showModalEditImage={showEditImage} image={imageData} onCloseEditImage={closeEditImage}/> 
       <Addpost showModalAddpost={showModalAddpost} data={videoData} type="video" onCloseAddpost={closeAddpostsVideo}/>
      <Modal
        dialogClassName="photoOrVedioPopup"
        showModal={props.showModalPhotoOrVedio}
        onHide={() => {
          props.onChangeOfCaption(null);
          props.onChangeOfSelectedSubspace([]);
          props.onClosePhotoOrVedioPopUp()
        }}
        title={' '}
        properties={{
          centered: true
        }}
        body={
          <div className="PhotoOrVideoContainer">
            <div 
              className="PhotoOrVideo"
              onClick={() => galleryClick() }
            >
            <div className="photo-icon-style">
                <UploadIcon />
                <input type="file" id="contained-button-file" accept="image/*, video/*" onChange={handleFileChange} style={{ display: "none" }} />
              </div>
              <div className="photo-icon-text">
                {props.dictionary.s("uploadPhotoOrVideo")}
              </div>
            </div>
            <div
              className="PhotoOrVideo"
              onClick={() => {
                props.onClosePhotoOrVedioPopUp();
                setShowTakePhotoOrVedio(true);
              }}
            >
              <div className="photo-icon-style">
                <CameraIcon />
              </div>
              <div className="photo-icon-text">
                {props.dictionary.s("takePhotoOrVideo")}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );

  return renderModalPhotoOrVedio();
};

const mapStateToProps = state => {
  return {
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    }
  };
};


const mapDispatchToProps = (dispatch) => ({
  onspinnerPost: (condition) => dispatch(spinnerPost(condition)),
  onChangeOfCaption: (caption) => dispatch(changedCaption(caption)),
  onChangeOfSelectedSubspace: (subspace) => dispatch(changedSubspace(subspace)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoOrVedioPopup);
