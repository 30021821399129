import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
import { general } from "../../../../general/global";
import { Button } from "react-bootstrap";
import SWSModal from "../Modal";
import ImageCropper from "./ImageCropper";
import ButtonMultiMediaOfAStep from "../ButtonMultiMediaOfAStep";
import { connect } from "react-redux";
import { translate } from "../../../../redux/reducers/languageReducer";

const InputPhoto = (props) => {
  const [showFloatingArea, setShowFloatingArea] = useState(false);
  const [wayAttachmentMedia, setWayAttachmentMedia] = useState("");
  const [deviceType, setdeviceType] = useState(window.deviceType);
  const [facingMode, setFacingMode] = useState(
    (general.isSafari && deviceType !== 'desktop') || (general.isChromeAndroid && deviceType !== 'desktop')
      ? { exact: "environment" }
      : "user"
  );
  const [resetCameraView, setResetCameraView] = useState(false);
  const [uploadPic, setUploadPic] = useState(false);
  const [gallery, setGallery] = useState(false);
  const [imageData, setImage] = useState(null);

  const webcamRef = React.useRef(null);

  useEffect(() => {
    function handleResize() {
      setdeviceType(window.deviceType);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const initialize = () => {
    setUploadPic(true);
    document.body.onfocus = checkIt;
  };

  const checkIt = () => {
    setTimeout(() => {
      setUploadPic(false);
    }, 100);
    document.body.onfocus = null;
  };

  const onClickMenuItemFromWebCam = () => {
    setShowFloatingArea(true);
    setWayAttachmentMedia("webcam");
  };

  const captureGalleryImage = async (e) => {
    e.persist();
    setUploadPic(true);
    let imageSrc = e.target.files[0];
    general.getBase64Png(imageSrc, (result) => {
      setImage(result);
      setUploadPic(false);
      setGallery(true);
    });
  };

  const capture = React.useCallback(() => {
    let imageSrc = null;
    imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    setGallery(true);
  }, [webcamRef]);

  const onCaptureSend = async (res) => {
    const imageSrc = res;
    const file = await general.dataURLtoFile(
      imageSrc,
      `${new Date().getTime()}.jpg`,
      "image/jpeg"
    );
    general.getBase64(file, (result) => {
      props.onScreenShot(file, result);
      setShowFloatingArea(false);
      setGallery(false);
    });
  };

  const cancelAttachmentMedia = () => {
    setShowFloatingArea(false);
    setGallery(false);
  };

  const renderDropDownToggle = () => (
    <ButtonMultiMediaOfAStep
      className="fa fa-camera"
      onClick={onClickMenuItemFromWebCam}
      style={{
        position: "absolute",
        left: 50,
        bottom: 15,
        zIndex: 10,
        padding: 0,
        backgroundColor: general.theme.primaryColor,
        ...props.styleIcon,
      }}
    />
  );

  const getFacingMode = () =>
    facingMode === "user" ? { exact: "environment" } : "user";

  const onChangeCamera = () => {
    if (general.isSafari) setFacingMode(getFacingMode());
    else if (general.isChromeAndroid) {
      setFacingMode(getFacingMode());
      setResetCameraView(true);
      setTimeout(() => {
        setResetCameraView(false);
      }, 100);
    } else {
      setFacingMode('user');
    }
  };

  const body = gallery ? (
    imageData ?
      <div className="vertical-center image-crop-modal text-center" style={{ minHeight: 280 }}>
        <h3>{props.dictionary.s("cropPhoto")}</h3>
        <ImageCropper
          image={imageData && {
            path: imageData,
            name: "image",
          }}
          onSave={onCaptureSend}
          onCancel={cancelAttachmentMedia}
          textButtonSave={props.dictionary.s('save')}
          textButtonCancel={props.dictionary.s('cancel')}

        />
      </div> : <p className="text-danger">Please Select Image First</p>
  ) : (
      <div className="vertical-center capture-container text-center" style={{ minHeight: 280 }}>
        {wayAttachmentMedia === "webcam" && (
          <div className="sws-row">
            {(general.isSafari) || general.isChromeAndroid ? (
              <button
                onClick={onChangeCamera}
                className="change-camera-btn"
                style={{
                  position: "absolute",
                  right: 20,
                  top: 34,
                  zIndex: 10,
                  padding: 0,
                  ...props.styleIcon,
                  width: 35,
                  height: 35,
                }}
              >
                <span
                  className="material-icons"
                  style={{ lineHeight: 1.4, color: general.theme.primaryColor }}
                >
                  flip_camera_ios
              </span>
              </button>
            ) : null}
            {resetCameraView && general.isChromeAndroid ? (
              <div className="text-center">{`${props.dictionary.s(
                "loading"
              )}...`}</div>
            ) : !uploadPic ?(
                <Webcam
                  audio={false}
                  height={350}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width="100%"
                  videoConstraints={{
                    facingMode: facingMode,
                  }}
                />
              ) : (
                <div
                  className="text-center"
                  style={{
                    height: 350
                  }}
                ></div>
              )}

            <Button variant="link" onClick={cancelAttachmentMedia}>
              {props.dictionary.s("cancel")}

            </Button>
            <Button
              variant="info"
              className="border-radius-none"
              onClick={capture}
            >
              {props.dictionary.s("takePhoto")}
            </Button>

            <>
              <input
                onChange={(e) => captureGalleryImage(e)}
                onClick={() => initialize()}
                type="file"
                id="file"
                accept="image/png, image/jpeg"
              />
              <label htmlFor="file">{props.dictionary.s("choosefile")}</label>
            </>
          </div>
        )}
      </div>
    );

  const renderModal = () => (
    <SWSModal
      title=""
      className={!uploadPic ? "animated rubberBand" : "animated rubberBand copperpoupHide"}
      showModal={showFloatingArea}
      dialogClassName="screen-center"
      body={showFloatingArea ? body : null}
    />
  );

  return (
    props.mode === "onlyIcon" && (
      <div
        style={{ position: 'relative', width: '100%' }}
        className={props.className}
      >
        {renderDropDownToggle()}
        {renderModal()}
      </div>
    )
  );
};

InputPhoto.defaultProps = {
  id: general.guid(),
  name: "photo...",
  label: "photo",
  tabIndex: 1,
  imagePreviewURL: "",
  onScreenShot: () => { },
  className: "",
  audio: false,
  height: 350,
  width: 640,
  style: {
    border: "2px solid #13988A",
    minHeight: 40,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 14,
    paddingRight: 14,
    position: "relative",
  },
  screenshotFormat: "image/jpeg",
  onUserMedia: () => { },
  onUserMediaError: () => { },
  audioSource: "",
  videoSource: "",
  // onlyIcon: false,
  bsStyle: "link",
  styleIconContainer: {
    borderRadius: "50%",
    width: 45,
    height: 45,
    borderColor: "#665EFF",
  },
  styleIcon: {
    border: "1px solid #ccc",
    borderRadius: "50%",
    width: 40,
    height: 40,
    color: general.theme.primaryColor,
  },
  mode: "normal", //operation, onlyIcon
  action: "read",
};

const mapStateToProps = (state) => ({
  dictionary: {
    s: (key) => translate(state.language.dictionary, key),
  },
});

export default connect(mapStateToProps, null)(InputPhoto);
