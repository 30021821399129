import styled from "styled-components";
import InputBootstrap from "react-bootstrap/FormControl";

const StyledInput = styled(InputBootstrap)`
  border: ${(props) => props.border} !important;
  padding-left: ${(props) => props.paddingleft};
  padding-right: ${(props) => props.paddingright};
  border-radius: ${(props) => props.borderradius};
  background-color: ${(props) => props.backgroundcolor} !important;
  color: ${(props) => props.color};
  font-family: ${(props) => props.fontFamily};
  font-size: ${(props) => props.fontSize};
  height: ${({ height, theme }) => height ? height : theme.input_height};
  padding: 0.375rem 0.75rem;
  border-radius: ${({ borderRadius }) => borderRadius};

  &::placeholder {
    color: ${(props) => props.placeholdercolor};
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) => props.placeholdercolor};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => props.placeholdercolor};
  }
`;

export default StyledInput;