import { es, en } from "./local";

let dictionary = es;
let lang = "es";

const _set = (language) => (lang = language);

export const setLang = (language) => {
  switch (language) {
    case "en":
      dictionary = en;
      break;
    case "es":
      dictionary = es;
      break;
    default:
      dictionary = es;
      break;
  }
  _set(language);
};

export const s = (key, params) =>
  !dictionary[key]
    ? `-${key}-`
    : !params
    ? dictionary[key]
    : dictionary[key].replace(
        /\{\d\}/g,
        (val) => params[parseFloat(val.match(/\d/g)[0])]
      );
