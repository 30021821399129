import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import workspaceReducer from "./workspaceReducer";
import userReducer from "./userReducer";
import onboardingReducer from "./onboardingReducer";
import magicLinkReducer from "./magicLinkReducer";
import role from "./roleReducer";
import operationReducer from "./operationReducer";
import languageReducer from "./languageReducer";
import reports from "./reports";
import toastReducer from "./toastReducer";
import editForm from "./editFormReducer";
import postReducer from './postReducer';
import marketPlaceReducer from './marketPlaceReducer';
import disableEnableReducer from './disableOrEnableUserSafelyPassReducer';
import transactionsReducer from './transactionsReducer';

export default combineReducers({
  login: loginReducer,
  user: userReducer,
  workspace: workspaceReducer,
  onboarding: onboardingReducer,
  magicLink: magicLinkReducer,
  role,
  reports,
  operation: operationReducer,
  language: languageReducer,
  toast: toastReducer,
  editForm: editForm,
  posts: postReducer,
  marketPlace: marketPlaceReducer,
  disableOrEnable: disableEnableReducer,
  transactions: transactionsReducer,
});
