import React from 'react';
import { Radio, RadioGroup } from "react-radio-group";
import MultiSelect from "react-multi-select-component";
import { connect } from 'react-redux';
import { translate } from '../../redux/reducers/languageReducer';

const AssignToOptions = ({
    selectedValue,
    setSelectedValue,
    userOptions,
    selectedUser,
    setSelectedUser,
    dictionary
}) => {

    return (
        <>
            <RadioGroup
                name="space-selection"
                selectedValue={selectedValue}
                onChange={(e) => setSelectedValue(e)}
            >
                <div className="radio-button-background">
                    <Radio value="only_admin" className="radio-button" />
                    <label>{dictionary.s('onlyAdmin')}</label>
                </div>
                <div className="radio-button-background">
                    <Radio value="all_members" className="radio-button" />
                    <label>{dictionary.s('allMembers')}</label>
                </div>
                <div className="radio-button-background">
                    <Radio value="specific_members" className="radio-button" />
                    <label>{dictionary.s('specificMembers')}</label>
                </div>
            </RadioGroup>

            {selectedValue === "specific_members" && (
                <MultiSelect
                    className="assign-to-multiselect"
                    labelledBy={dictionary.s("selectSubSpace")}
                    options={userOptions}
                    value={selectedUser}
                    overrideStrings={{
                        allItemsAreSelected: dictionary.s("allMembersAreSelected"),
                        selectSomeItems: dictionary.s("selectMembers") + ' *',
                        search: dictionary.s("search"),
                    }}
                    onChange={(e) => setSelectedUser(e)}
                />)}
        </>
    )
}

const mapStateToProps = (state) => ({
    dictionary: {
        s: key => translate(state.language.dictionary, key)
    },
});

export default connect(mapStateToProps, null)(AssignToOptions);
