export default {
  getStarted: "Get started",
  signin: "Sign in",
  poweredBy: "Powered by",
  cellPhoneNumer: "Mobile Number",
  emailOrPhoneNumber: "Email ID or Mobile Number",
  emailIDOrPhoneNumber: "Email ID or Mobile Number",
  getASafelyLink: "Get a Safely link",
  or: "or",
  orSigninWith: "or sign in with",
  dontHaveAnAccount: "Don't have an account?",
  termAndConditions: "Terms & Conditions",
  companys: "Company's",
  email: "Email ID",
  emailExample: "youremail@mail.com",
  password: "Password",
  rememberMe: "Remember me?",
  welcome: "Welcome",
  verifiedAccount:
    "Your account has already been verified. Set up a new Safely space.",
  createANewSafelyWorkspace: "Create a new Safely space",
  lookingForAnExistingSafelyWorksapce:
    "Looking for an existing Safely space?",
  askForASafelyLink: "Ask for a Safely link",
  askForASafelyPass: "Ask for Safely Pass",
  tryAnotherAccount: "Try another account",
  whatIsTheNameOfYourSafelyWorkspace:
    "Give a name to your new Safely space",
  workspacesName: "Safely space name",
  workspaceNameHelp: "It can be the name of your Company or Team.",
  prev: "Previous",
  next: "Next",
  byContinuingYouAgreeToOur: " By continuing you're agreeing to our",
  termsAndConditions: "Customer Terms and Conditions of Service",
  andThe: ",",
  PrivacyPolicy: "Privacy Policy",
  wouldYouLikeToAddSomeoneElseFromYourSafelyWorkspace:
    "Invite others to your space",
  shareYourSafelyLink: "Share your Safely link",
  shareaspaceinvitationlink: "Share a space invitation link",
  inviteYourContacts: "Invite your contacts",
  omit: "Omit",
  addAnother: "Add Another",
  setYourProfile: "Set your Profile",
  fullName: "Full name",
  yourFullName: "Your full name",
  setYourSafelyWorkplace: "Set your Safely space",
  geolocationPermissionsForYourCompanysMembers:
    "Geolocation permissions for your company's members",
  requestTheUsersCurrentLocationOnlyWhenATransactionIsMade:
    "Request the user's current location only when a transaction is made.",
  requestTheUsersRealTimeLocation: "Request the user's real-time location.",
  activeteGeolocationMandatory: "Active geolocation mandatory",
  finishSigningUp: "Finish Signing Up",
  register: "Register",
  signup: "Sign Up",
  loading: "Loading",
  user: "User",
  finish: "Finish",
  names: "Name(s)",
  lastName: "Last Name",
  cellPhoneNumberValidation: "Mobile number should have 10 digits.",
  completeAllFields: "Complete all fields",
  share: "Share",
  cancel: "Cancel",
  copy: "Copy",
  join: "Join",
  mySafelyPass: "My Safely Pass",
  completeAccessForm: "Complete access form",
  geolocation: "Geolocation",
  code: "Code",
  form: "Form",
  comments: "Comments",
  send: "Send",
  checkPoint: "Verification Point",
  raiseAlert: "",
  safelyPass: "Safely Pass",
  confirm: "Confirm",
  accept: "Accept",
  required: "Required",
  areYouSureToSendTheInformation: "Are you sure to send the information?",
  error: "Error",
  anErrorHasOccurredDoYouWantToTryAgain:
    "An error has occurred, do you want to try again?",
  yes: "Yes",
  no: "No",
  youHasNotAcceptedToEnableTheLocationServices:
    "You has not accepted to enable the location services or something went wrong during the process.",
  workspaces: "Spaces",
  thingsToVerifyMayInclude: "Things to verify may include",
  generateASafelyPass: "Generate Safely Pass",
  generatingASafelyPassYouConfirmThatTheInformationProvidedIsTrue:
    "By generating a Safely Pass, you confirm that the information provided is true and correct to the best of your knowledge.",
  Verify: "Verify",
  safelyPassActivated: "Safely Pass Activated",
  safelyPassDeactivated: "Safely Pass Deactivated",
  activatedSafelyPass: "Activated Safely Pass",
  tryAgain: "Try again",
  yourSafelyPassHavenotBeenGenerated:
    "Sorry, Your Safely Pass was not generated due to the information that you disclosed in the Self Declaration form. Please reach out to your designated contact within your organization for further instructions",
  yourSafelyPassVaveNotBeenActived:
    "Sorry, Your Safely Pass was not activated due to the information that you disclosed in the Verification form. Please reach out to your designated contact within your organization for further instructions",
  yourSafelyPassVaveNotBeenActived1:
    "Sorry, Your Safely Pass was not activated due to the information that you disclosed in the Verification form.",
  yourSafelyPassVaveNotBeenActived2:
    "Please reach out to your designated contact within your organization for further instructions",
  yourSafelyPassHavenotBeenGenerated1: "Sorry, Your Safely Pass was not generated due to the information that you disclosed in the Self Declaration form.",
  yourSafelyPassHavenotBeenGenerated2: "Please reach out to your designated contact within your organization for further instructions",
  manageUser: "Manage User",
  userName: "User name",
  workSpace: "space",
  continueAndAgree: "Continue & Agree",
  createANewWorkspace: "Create a new space",
  scanAWorkspaceQRCode: "Scan a space QR code",
  shareMyWorkspaceLink: "Share my space link",
  back: "Back",
  settings: "Settings",
  locationIsRequired: "Location is required",
  selfDeclaration: "Self Declaration",
  editSelfDeclaration: "Edit Self Declaration",
  TheWorkspaceYouWantToAccessMightHaveRequirementsThatNeedToBeMet:
    "The space you want to access might have requirements that need to be met.",
  addWorkspace: "Add space",
  save: "Save",
  // checkOut: "Check out",
  pleaseSelectAnyThatApplyToYou: "Please select any that apply",
  thoseMayInclude: "Those may include",
  verify: "Verify",
  close: "Close",
  verificationPoint: "Verification Point",
  editVerificationPoint: "Edit Verification Form",
  areAllTheConditionsToGrantThisUserAccessToTheWorkspaceMet:
    "Are ALL the conditions to grant this user access to the space met?",
  preferences: "Preferences",
  inviteAUserToMyWorkspace: "Invite a User to my space",
  myWorkspaceQRCode: "My Space QR code",
  renameMySpace: "Rename my Space",
  spaceSettings: "Space Settings",
  theWorkspaceNameIsInvalid: "The space name is invalid",
  download: "Download",
  emailOrPhoneNumberIsInvalid: "Email ID or Mobile Number is invalid.",
  hasInviteYouToJoinTheSafely: "Has invited you to join the Safely",
  reject: "Reject",
  joinNow: "Join Now",
  youAlreadyBelongToTheWorkspace: "You already belong to the space",
  theWorkspaceNameAlreadyExists: "The space name already exists",
  notEmailOrNumberValid:
    "Username entered does not appear to be a valid Email ID or Mobile Number",
  isSeekingAccessTo: "is seeking access to",
  language: 'Language',
  english: 'English',
  spanish: 'Spanish',
  logginIn: "Logging In",
  takePhoto: "Take photo",
  useWebCam: "User webcam",
  userVerification: "User Verification",
  Dashbaord: "Dashbaord",
  userManagement: "User Management",
  noResultFound: "No result found",
  logout: "Log out",
  justOneMoreStepBeforeYouActivateYourSafelyPass: 'Just one more step before you activate your Safely Pass.',
  pleaseProceedToTheAssignedVerificationPointToActivateYourSafelyPass: "Please proceed to the assigned verification point to activate your Safely Pass.",
  refresh: 'Refresh',
  createAccount: 'Create Account',
  firstName: 'First Name',
  reports: 'Reports',
  safelyPassesHistoryReports: 'Safely Pass History',
  activationDate: 'Activation date',
  monitorName: 'Monitor name',
  PREV: 'PREV',
  NEXT: 'NEXT',
  All: 'All',
  showing: 'Showing',
  Admin: "ADMIN",
  Monitor: "MONITOR",
  User: "USER",
  Visitor: "VISITOR",
  browse: 'Browse',
  choosefile: "Choose a File",
  agreeToSafely: "I agree to Safely’s",
  termsOfService: "terms of service",
  orSignUpWith: "or sign up with",
  welcomeBack: "Welcome Back!",
  createSpace: "Create Space",
  CreatenewSafelyspace: "Create new Safely space",
  invitationLink: "Send invitation link to their mobile number or email id",
  haveAccount: "Already have an account?",
  hey: "Hey",
  hasbeencreated: "has been created!",
  theUserDoesNotBelongToAnyOfYourWorkspaces: "The user does not belong to any of your spaces",
  scanQRCode: "Scan QR code",
  signInToSafely: "Sign in to Safely",
  forgotPassword: "Forgot Password?",
  helloFriend: "Hello Friend!",
  pleaseSigninWithPersonalInfo: "To keep connected with us please sign in with your personal info",
  enterPersonalDetailsAndStartJourney: "Enter your personal details and start the journey with Safely Pass",
  mySpace: "My Spaces",
  groups: "Groups",
  group: "Group",
  contactIndex: "CONTACT INDEX",
  global: "Global",
  today: "Today",
  createSafelySpace: "Create Safely Space",
  confirmAccount: "You have confirmed your account!",
  newSafelyspacefororganization: "You’re all set to start a new Safely space for your organization.",
  lookingForExistingSpace: "Looking for an existing space?",
  updateYourSettings: "Update Your Settings",
  almostDone: "Almost done!",
  pleaseUpdateYourSettings: "Please update your settings",
  spaceColor: "Space Color",
  renameSpace: "Rename Space",
  rename: "Rename",
  Import: "Import",
  ImportContacts: "Import Contacts",
  DescriptionImportContacts: "To import contacts, upload a CSV",
  DownloadCSVTemplate: "Download CSV template",
  UploadFile: "Upload File",
  ImportedContacts: "Imported Contacts",
  InvitesSent: "Invites sent!",
  createQrCode: "Create QR Code",
  inviteMyContacts: "Invite My Contacts",
  information: "Information",
  toSwitchToThisWorkSpaceYouFirstNeedToCheckoutTheActivatedSafelyPass: 'To switch to this space, you first need to check out the activated Safely Pass.',
  MessageErrorDefault: "An error occurred try again later",
  home: 'Home',
  statistics: 'Statistics',
  location: 'Location',
  createSubSpace: 'Create Subspace',
  subspaces: 'Subspaces',
  subspace: 'Subspace',
  done: 'Done',
  addMembers: 'Add Members',
  members: 'Members',
  member: 'Member',
  searchbyname: 'Search by name',
  forgotYourPassword: 'Forgot Your Password?',
  backToSignIn: 'Back to Sign In',
  sendUsEmailorMobileNumber: 'Send us the Email ID or Mobile Number associated with your account and we’ll send you a link to reset it!',
  verifyYourName: 'Verify your Name',
  fetchedYourDetailsFrom: 'We have fetched your details from ',
  pleaseVerifyNameandupdate: 'Please verify your name and update it if it’s incorrect.',
  verifyMobileNumber: 'Verify Mobile Number',
  resendPin: 'Resend Pin',
  verifyEmailId: 'Verify Email ID',
  resendPinIn30sec: 'Resend Pin in 30 seconds',
  sentPinToEmailandEnterPin: 'We have sent a pin to your email id. Please enter the pin below to continue.',
  ThisQRCodenolongerexists: "This QR Code no longer exists",
  FlipCamera: "FLIP CAMERA",
  AligntheQRCodewiththeframetoscan: "Align the QR Code with the frame to scan",
  Edit: "Edit",
  sentPinToMobileandEnterPin: 'We have sent a pin to your mobile number. Please enter the pin below to continue.',
  theWorksubspaceNameAlreadyExists: "Already subspace exist in space",
  remove: 'Remove',
  areyousureyouneedremove: 'Are you sure you need remove',
  fromSubspace: 'from subspace',
  removebutton: 'Remove',
  Istheuserawareoftheconditionsestablishedbythecompanytoaccesstothisspace:
    "Is the user aware of the conditions established by the company to access to this space?",
  DidthisusermeetALLtheconditionstoaccessthisspace: "Did this user meet ALL the conditions to access this space?",
  SafelyPassisRejected: 'Safely Pass is Rejected',
  scanQRCodeToActivateSafelySpace: "Scan QR to activate Safely Pass",
  checkinCheckoutRegister: 'Check In / Check Out in Register',
  checkInRegister: 'Check In Register',
  search: "Search",
  name: "Name",
  checkInTime: "Check-in Time",
  checkOutTime: 'Check-out Time',
  space: 'Space',
  checkinCheckout: "Check In / Check Out",
  checkIn: "Check In",
  checkOut: "Check Out",
  userCheckOut: 'Check Out ',
  areYouSureYouNeedToCheckOutTheUser: 'Are you sure you need to check out the user ',
  userNotRegistered: 'User is not Registered!',
  invalidpin: 'Invalid Pin',
  pinverified: 'Pin verified',
  verificationForm: 'Verification Form',
  editVerificationForm: "Edit Verification Form",
  content: "Content",
  emailId: "Email ID",
  mobileNumber: "Mobile Number",
  subSpaces: 'SubSpaces',
  role: 'Role',
  selectSpace: "Select Space",
  cropPhoto: "Crop Photo",
  sendLinkToResetPinToMobile: "We have just sent you a SMS to reset your password.",
  sendLinkToResetPinToEmail: "We have just sent you an email to reset your password.",
  safelyPassAlreadyActivated: "This Safely Pass is already activated",
  ActivatedSafelyPasses: "Activated Safely Passes",
  SafelyPassesNotGenerated: "Safely Passes Not Generated",
  SafelyPassesRejected: "Rejected Safely Passes",
  CheckedInUsingRegister: "Checkpoint records",
  passRequestedDate: "Pass requested date",
  passRejectedDate: "Pass rejected date",
  editSelfDeclarationForm: "Edit Self Declaration Form",
  selfDeclarationForm: "Self Declaration Form",
  selectInput: "Select Input",
  label: "Label",
  number: "Numerical Value",
  text: "Textbox",
  boolean: "Yes/No",
  buttongroup: "Button",
  denySafelyPass: 'Deny Safely Pass',
  addAnotherInput: "Add Another Input",
  of: 'of',
  show: 'Show',
  perPage: 'per page',
  subSpaceQrcode: 'Subspace QR Code',
  qrCode: 'QR Code',
  accessGranted: 'Access Granted',
  passGranted: 'Pass Granted?',
  youhavecheckedintosubspaceInnovation: 'You have gotten into subspace ',
  accessDenied: 'Access Denied',
  youhavebeendeniedaccesstosubspaceInnovation: 'You have been denied entry to subspace',
  youhavecomeoutsubspace: 'You have come out of subspace ',
  setUpYourSpace: 'Set up your Space',
  yourLocationwillBeTracked: 'Your location will be tracked for ensuring your safety inside the space',
  chooseUniqueColor: 'Choose a unique color for easily identifying the space',
  mobileNumberOrEmailId: 'Mobile Number or Email ID',
  verificationFormsReport: 'Verification Forms Report',
  selfDeclarationReport: 'Self Declarations Report',
  export: 'Export',
  yesterday: "Yesterday",
  currentWeek: "Current Week",
  lastWeek: "Last Week",
  currentMonth: "Current Month",
  lastMonth: "Last Month",
  currentYear: "Current Year",
  lastYear: "Last Year",
  all: "All",
  accessGrantedQustion: 'Access granted?',
  dateSubmitted: 'Date Submitted',
  safelyPassHistory: "Safely Pass History",
  selfDeclarations: "Self Declarations",
  verificationForms: "Verification Forms",
  searchMembers: 'Search Members',
  permissionsareneededtoscanauserSafelyPass: "Permissions are needed to scan a user's Safely Pass",
  more: "more",
  less: "less",
  post: "Posts",
  sharephotosandvideoswithyourcolleagues: 'Share photos and videos with your colleagues',
  addNewPost: 'Add New Post',
  uploadPhotoOrVideo: 'Upload Photo or Video',
  takePhotoOrVideo: 'Take Photo or Video',
  video: 'Video',
  editPhoto: 'Edit Photo',
  postdata: 'Post',
  newPost: 'New Post',
  selectSubSpace: 'Select Subspace',
  PleaseSelectAtleastOneSubSpace: "Please Select Atleast one Sub Space",
  writeACaption: "Write a caption",
  switchSpace: "Switch Space?",
  ifYouChangeTheSpace: "If you change the space, the safely pass generated will be replaced. Are you sure you want to do this?",
  tooltipMessageToTrySwitchSpace: "To switch to this space, you first need to check out the activated Safely Pass",
  undo: 'Undo',
  redo: 'Redo',
  reset: 'Reset',
  delete: 'Delete',
  deleteAll: 'DeleteAll',
  crop: 'Crop',
  rotate: 'Rotate',
  draw: 'Draw',
  color: 'Color',
  straight: 'Straight',
  free: 'Free',
  shape: 'Shape',
  range: 'Range',
  center: 'Center',
  left: 'Left',
  underline: 'Underline',
  italic: 'Italic',
  bold: 'Bold',
  textsize: 'Text size',
  custom: 'Custom',
  square: 'Square',
  apply: 'Apply',
  right: 'Right',
  stopRecoding: 'Stop Recoding',
  startRecoding: 'Start Recoding',
  renameSubspace: "Rename Subspace",
  youhaveanewPostpleaserefresh: "You have a new Post",
  checkInNewUser: 'Check In New User',
  errorYouNeedToAddAnImage: "Error: You need to add an image",
  errorNameMustNotBeEmpty: "Error: Name must not be empty",
  errorLastNameMustNotBeEmpty: "Error: Last name must not be empty",
  theSubspaceNameAlreadyExists: "Subspace name already exists!",
  theSubspaceNameIsInvalid: "The Subspace name is invalid",
  allSubSpacesareselected: 'All subspaces are selected',
  wanttocheckintoaSpace: 'Want to check into a Space?',
  spaceInfo: "Space Info",
  EditPost: "Edit Post",
  DeletePost: "Delete Post",
  viewSpace: "View Subspaces",
  spaceQRCode: "Space QR Code",
  selfDeclarationFrom: 'Self Declaration Form',
  saveChanges: 'Save Changes',
  searchSpace: "Search Space",
  enableSelfDeclaration: 'Enable Self Declaration to get access to the form to generate Safely Pass',
  verificationPointSwitchText: 'Only required if the Admin or Monitor has to activate the Safely Pass of users',
  changeshasbeenupdatedsuccessfully: 'Changes have been updated successfully',
  createBy: 'Created by',
  deletePostQuestion: 'Delete Post ?',
  youAreAboutToDeletePost: 'You are about to delete this post. Are you sure you want to continue?',
  checkpoint: 'Checkpoint',
  marketplace: 'Marketplace',
  noSpacesFound: 'No Spaces Found',
  errorGenerateASafelyPass: 'To Generate a Safely Pass in this space, you first need to check out the activated Safely Pass of space',
  errorViewSpace: 'To view to this subspaces, you first need to check out the activated Safely Pass',
  activateSafelyPass: 'Activate Safely Pass',
  buyNow: 'Buy Now',
  checkOutQuestion: "Check Out",
  seeMore: 'See More',
  checkedIn: 'Checked In',
  notCheckedIn: 'Not checked in',
  workspaceSettingsUpdateMessage: "success",
  deleteSubspace: "Delete Subspace",
  deleteSysbol: "Delete",
  deleteSubSpaceText1: "You are about to delete the subspace",
  areyousureyouwanttocontinue: "Are you sure you want to continue?",
  deletedSubspace: "Deleted Subspace",
  YoudonothaveaccesstoanySpace: 'You do not have access to any Space!',
  JoinanexistingSpace: 'Join an existing Space',
  deleteSpaceImage: 'Delete Space Image?',
  youAreAboutToDeleteSpaceImage: 'You are about to delete your Space Image. Are you sure you want to continue?',
  deleteSpace: "Delete Space",
  deleteSpaceText1: "You are about to delete the space",
  deleteSpaceText2: "This Space has data. Are you sure you want to delete it? If Yes, please send an email to",
  deletedspace: "Deleted Space",
  subSpaceDeleted: 'Subspace Deleted',
  editMaximumOccupancy: 'Edit Maximum Occupancy',
  updateMaximumOccupancy: 'Update',
  theMaximumOccupancyIsInvalid: 'The maximum occupancy is invalid',
  maximumOccupancyMustBeEqualOrGreaterthan1: "The maximum occupancy must be equal or greater than 1",
  maximumOcuppancy: 'Maximum Occupancy',
  subspaceName: 'Subspace Name',
  spaceDeleted: 'Space Deleted',
  Available: 'Available',
  AlmostFull: 'Almost Full',
  Full: 'Full',
  subspaceActivityText1: ' ',
  subspaceActivityText2: "’s activity in all spaces and subspaces",
  SubspacesActivity: 'Subspaces Activity',
  SubspacesHistory: 'Subspaces History',
  UserSubspacesHistory: 'Subspaces',
  timeSpentInSubspace: 'Time Spent in Subspace',
  oldest: 'Oldest',
  theSubspace: 'The subspace ',
  maxCapacityOrNotAuthorized: ' is at its maximum capacity or you are not authorized to access.',
  mySubspaces: 'My subspaces',
  membersInSubspace: 'members',
  occupancy: 'occupancy',
  youAreCheckedIntoTheSpace: 'You are checked into the space',
  checkIntoQuestion: 'Check into',
  areYouSureYouWantToCheckInto: 'Are you sure you want to check into',
  checkOutSubspace: 'Check Out',
  checkOutSubspaceQuestion: 'Check out of',
  areYouSureYouWantToCheckOut: 'Are you sure you want to check out of',
  checkInSubspace: 'Check in',
  checkOutOfSubspace: 'Check out of Subspace',
  checkIntoSubspace: 'Check into Subspace',
  checkinCheckoutSubspace: 'Check In / Check Out',
  maximumOccupancy: 'Maximum occupancy',
  availableOccupancy: 'Available occupancy',
  users: 'Users',
  youhavenopostsyet: 'You have no posts yet',
  photo: 'photo',
  inputLabel: 'Input Label',
  ButtonsAndActions: 'Buttons and Actions',
  AddAnotherButton: 'Add Another Button',
  GrantSafelyPass: 'Grant Safely Pass',
  Thiswordalreadyexists: 'This button label already exists',
  buttonValidation: 'You must have at least one button with Grant Access',
  emptyDescription: 'You need to add a button label',
  sameDescription: 'The buttons label should not repeated',
  minimumButtons: 'You must have at least two buttons',
  requiredValidation: 'You must complete all required fields',
  addInput: 'Add Input',
  addPhotoOrVideo: 'Add Photo or Video',
  userDeleted: 'User removed successfully',
  youAreAboutToRemove: 'You are about to remove',
  fromSpace: 'from the space',
  fromSpaces: 'from Spaces',
  fromSelectedSpaces: 'from the selected spaces.',
  areYouSureWantToContinue: 'Are you sure want to continue?',
  loadmore: 'Show More',
  spaceHasBeenDeleted: "The space has been deleted",
  messageUserHasActivedPass: "has an activated Safely Pass of this space and hasn't been removed. If you remove the user, the user will be checked out of the space",
  thatsAll: "That’s All!",
  leaveSpace: "Leave Space",
  leaveSpaceQus: "Leave Space",
  leave: "Leave",
  leaveSpaceText1: "You are about to leave the Space",
  leavespaceUpdated: 'Leave Space successfully',
  AgreeToThe: "I agree to the",
  and: 'and ',
  showMoreSpaces: 'Show more',
  thatsAllMessage: 'That’s All!',
  minimumValue: "Minimum Value",
  maximumValue: "Maximum Value",
  inputRangeMessage: "Safely Pass will be denied if the user enters a value outside the range",
  inputLabelValidation: "The labels of the inputs must not be empty",
  rangeEmptyValue: "The maximum and minimum values ​​must not be empty",
  rangeValidation: "The minimum value cannot be greater than or equal to the maximum value",
  noSubspacesFound: 'No suspaces found',
  noUsersFound: 'No users found',
  searchSubspace: 'Search subspace',
  searchMember: 'Search member',
  and_The: 'and the',
  ofSafelyPass: 'of Safely Pass',
  skip: 'Skip',
  subspaceCreated: 'Subspace Created!',
  actions: 'Actions',
  editUser: 'Edit User',
  editUserUserName: "Username",
  removeUser: 'Remove User',
  pleaseEnterTheValue: 'Please enter a value',
  AssignSubspaces: 'Assign Subspaces',
  resendPinIn: 'Resend Pin in',
  seconds: 'seconds',
  thePinHasExpired: 'The pin has expired.',
  visitors: 'Visitors',
  lastCheckinTime: 'Last check in time',
  date: 'date',
  activity: 'activity',
  safelyPassHasBeenActivated: 'Safely Pass has been activated for ',
  forTheSpace: 'for the space ',
  safelyPassDenied: 'Safely Pass Denied',
  safelyPassHasBeenDenied: 'Safely Pass has been denied to ',
  SafelyPassesDisabled: 'Disabled Safely Passes',
  disabledBy: 'Disabled by',
  disabledDate: 'Disabled date',
  disabledPeriod: 'Disabled period',
  enabledDate: 'Enabled date',
  workspaceDisabledMessage: 'safely pass has been disabled for the space',
  from: 'from',
  to: 'to',
  DisableUserSafelyPass: 'Disable User Safely Pass',
  Disable: 'Disable',
  DisableSafelyPass: 'Disable Safely Pass',
  AllSpaces: 'All Spaces',
  SpecificSpaces: 'Specific Spaces',
  StartDate: 'Start Date',
  EndDate: 'End Date',
  DisabledUserSafelyPassSuccessfully: 'Disabled User Safely Pass Successfully!',
  SearchSpaces: 'Search Spaces',
  Dateplaceholder: 'Date',
  EnableUserSafelyPass: 'Enable User Safely Pass',
  Enable: 'Enable',
  EnabledUserSafelyPassSuccessfully: 'Enabled User Safely Pass Successfully!',
  areyousureyouwanttoenabletheuser: 'Are you sure you want to enable the user?',
  areyousureyouwanttodisabletheuser: 'Are you sure you want to disable the user?',
  safelyPassIsDisabledForAllTheSpaces: 'safely pass is disabled for all the spaces',
  safelyPassIsDisabledForTheSpaces: 'safely pass is disabled for the spaces',
  safelyPassIsDisabledForTheSpace: 'safely pass is disabled for the space',
  AreYouSureYouWantToRemove: 'Are you sure you want to remove?',
  safelyPassIsCurrentlyActivatedForTheSpace: 'safely pass is currently activated for the space',
  errorEncounteredPleaseTryAfterSometime: "Error encountered, please try again after sometime",
  your: "Your",
  didNotReceiveTheVerificationPin: "Did not receive the verification pin?",
  multiple: 'Multiple Choice',
  optionsAndActions: 'Options and Actions',
  addAnotherOption: 'Add Another Option',
  thisOptionLabelAlreadyExists: 'This option label already exists',
  addOptionLabel: 'You need to add a option label',
  sameLabelDescription: 'The option label should not repeated',
  minimumOptionValidation: 'You must have at least two options',
  transactionButton: 'Fill out a Transaction',
  transactionsTitle: 'Transactions',
  transactionName: 'Transaction Name',
  lastExecution: 'Last Execution',
  createTransaction: 'Create Transaction',
  RenameTransaction: 'Rename Transaction',
  EditTransactionForm: 'Edit Transaction Form',
  AssignTransaction: 'Assign Transaction',
  TransactionQRCode: 'Transaction QR Code',
  DeleteTransaction: 'Delete Transaction',
  onlyAdmin: 'Only Admin',
  allMembers: 'All members',
  specificMembers: 'Specific members',
  selectMembers: 'Select Members',
  allMembersAreSelected: 'All members are selected',
  assignTo: 'Assign To',
  assignTransactionTo: 'Assign transaction to',
  transactionsHistory: 'Transactions History',
  transactions: 'Transactions',
  transactionName: 'Transaction name',
  renameTransactionError: 'This transaction already exists in the space. Please enter a unique name.',
  UploadPDFFiles: '*Upload only pdf files',
  UploadingFile: 'Uploading File',
  assign: 'Assign',
  deleteTransactionQuestion: 'Delete Transaction?',
  deleteTransactionText1: 'You are about to delete the transaction ',
  deleteTransactionText2: ' and its records in the reports. Are you sure you want to delete it?',
  deleteTransactionSuccessfully: 'Transaction Deleted Successfully!',
  assignTransactionSuccessfuly: 'Transaction Assigned Successfully!',
  workSpaceDetailsLabelOptions: 'What would you like to do?',
  inputFields: 'Input Fields',
  signature: 'Signature',
  textEditInput: 'Edit Text Input',
  fontSize: 'Font Size',
  requiredField: 'Required field',
  signatureEditInput: 'Edit Signature Input',
  removeInput: 'Remove',
  transactions: 'Transactions',
  addInputFields: 'Add Input Fields',
  saveAndSubmit: 'Save and Submit',
  page: 'Page',
  addSignature: 'Add Signature',
  clear: 'Clear',
  requiredText: 'Required Text',
  requiredSignature: 'Required Signature',
  inputDeletedSuccesfully: 'Input Deleted Successfully',
  transactionSavedSuccesfully: 'Transaction Saved Successfully',
  changesSavedSuccesfully: 'Changes Saved Successfully',
  dropdownlist: 'Drop-down List',
  GrantSafelyPassError: 'At least one option should have the action “Grant Safely Pass”',
};