import React, { useState } from "react";
import { connect } from "react-redux";
import { Col } from "react-bootstrap";
import Avatar from "../commons/wrapped-components/Avatar";
import { translate } from "../../redux/reducers/languageReducer";
import InputPhoto from "../commons/wrapped-components/inputs/InputPhoto";
import { updateProfilePhoto, updateUsername } from "../../redux/actions/userActions";
import { str_truncate } from "../../general/general";
import { ReactComponent as Pen } from "../../icons/pen.svg";
import { ReactComponent as IndexIcon } from "../../icons/comparison.svg";
import { ReactComponent as SaveIcon } from "../../icons/tick.svg";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import ImageLoader from "../commons/wrapped-components/ImageLoader";
import InputText from "../commons/wrapped-components/inputs/InputText";
import {
  updateEditedUser
} from "../../redux/actions/workspaceActions";

const UserProfile = (props) => {
  const [userEditable, setUserEditable] = useState(false);
  const [firstName, setUserFirstname] = useState(props.userName);
  const [lastName, setUserLastname] = useState(props.lastName);
  const [loading, setLoading] = useState(false);
  const userName = `${props.userName} ${props.lastName}`;

  //updating username
  const loadupdateUsername = async (id, getfirstName, getlastName) => {
    await props.onupdateUsername(id, getfirstName, getlastName).then((data) => {
      props.onupdateEditedUser(data.data,props.workSpacesProps);
      setUserEditable(false);
    });
  }

  const loadupdateProfilePhoto = async (file, imagePreviewUrl) => {
    setLoading(true)
    await props.onUpdateProfilePhoto(
      imagePreviewUrl,
      file,
      props.userId
    ).then(responceData => {
      props.onupdateEditedUser(responceData.data,props.workSpacesProps);
      setLoading(false)
    });
  }

  return (
    <>
      <Col
        xs={12}
        style={{ height: '100px', display: 'flex', justifyContent: 'center' }}
      >
        <div
          className="container-avatar"
          style={{ position: 'absolute', top: 'calc(-160px / 2)' }}
        >
          {loading ? <ImageLoader /> :
            <Avatar
              src={props.picture}
              className="rounded-circle avatar"
              styles={{ boxShadow: '0px 0px 0px 5px #fff' }}
            />
          }
          <div style={{position: 'absolute', right: -25}}>
            <InputPhoto
              imagePreviewURL=""
              mode="onlyIcon"
              styleIcon={{
                borderRadius: "50%",
                width: 40,
                height: 40,
                border: "1px solid #ccc",
                right: 20,
                left: null
              }}
              onScreenShot={async (file, imagePreviewUrl) => {
                await loadupdateProfilePhoto(file, imagePreviewUrl);
              }}
            />
          </div>
        </div>
      </Col>
      <Col xs={12} className="d-flex justify-content-center">
        <div className="user-info">
          {userEditable ? (
            <div className="edit-user">
              <InputText autoCorrect="false" inputStyle={{ color: '#3E3E3E', fontSize: '24px', border: 'none', borderBottom: '1px solid #9BAAC7', width: "50%" }} onChange={(e) => setUserFirstname(e.target.value)} value={firstName} />
              <InputText autoCorrect="false" inputStyle={{ color: '#3E3E3E', fontSize: '24px', border: 'none', borderBottom: '1px solid #9BAAC7', width: "50%", marginLeft: '5px' }} onChange={(e) => setUserLastname(e.target.value)} value={lastName} />
              <button className="close-btn-round" onClick={() => setUserEditable(false)}><CloseIcon /></button>
              <button className="save-btn-round" onClick={() => loadupdateUsername(props.userId, firstName, lastName)}><SaveIcon /></button>
            </div>
          ) : (
              <>
                <h3 title={userName}>
                  {userName.length > 23 ? str_truncate(userName, 23, "...") : userName}
                  <button className="edit-user-btn" onClick={() => setUserEditable(true)}><Pen /></button>
                </h3>
              </>
            )}
        </div>
      </Col>
      <Col
        className="user-stats d-flex align-items-center"
        sm={12}
        lg={7}
        md={7}
        xl={7}
        xs={12}
      >
        <div className="contact-index">
          <IndexIcon />
          <p>{props.dictionary.s("contactIndex")}</p>
        </div>
        <div className="stats">
          <ul>
            <li>
              <h2>0</h2>
              <p>{props.dictionary.s("today")}</p>
            </li>
            <li>
              <h2>0</h2>
              <p>{props.dictionary.s("global")}</p>
            </li>
          </ul>
        </div>
      </Col>
    </>
  );
};

const mapStateToProps = (state) => {
  const user = state.user;
  return {
    picture: user.picture,
    userName: user.name,
    lastName: user.lastName,
    userId: user.id,
    workSpacesProps: state.workspace,
    uploadingPhoto: user.uploadingPhoto,
    workspaceFocused: state.workspace.spaceFocused,
    loading: state.magicLink.loading,
    workSpaceName: state.workspace.spaceFocused.name,
    accesstokenidp: state.user.accesstokenidp,
    dictionary: {
      s: (key) => translate(state.language.dictionary, key),
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdateProfilePhoto: (uri, file, userId) =>
    dispatch(updateProfilePhoto(uri, file, userId)),
  onupdateUsername: (id, userName, lastName) => dispatch(updateUsername(id, userName, lastName)),
  onupdateEditedUser: (data, workSpacesProps) =>
    dispatch(updateEditedUser(data, workSpacesProps))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);