import React, { useEffect } from "react";
import MetisMenu from "react-metismenu";
import { getMenu } from "../../constants/menuItems";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from 'moment';
import {
  postMarketplaceStatistics,
} from '../../redux/actions/marketPlaceAction';

const SidebarWrapper = ({
  match,
  location,
  collapsesidebar,
  onselectedmenuitem,
  MarketplaceStatistics,
  accesstokenidp,
  userId
}) => {
  let metisMenu = null;
  useEffect(() => {
    if (metisMenu) {
      switch (match.path) {
        case '/subWorkspaceListAddmember':
        case '/subWorkspacelist':
        case '/workspacelist':
        case '/workspaceDetails':
        case '/editVerification':
        case '/userVerification':
        case '/safelypass':
        case '/editcheckpoint':
        case '/checkIntoSubspace':
          metisMenu.changeActiveLinkTo('#/workspacelist');
          break;
        case '/users':
        case '/useredit':
        case '/subspaceHistory':
          metisMenu.changeActiveLinkTo('#/users');
          break;
        case '/checkInRegister/:activeKey?':
        case '/verificationPoint':
          metisMenu.changeActiveLinkTo('#/checkInRegister');
          break;
        case '/reportlist/:reportKey?':
          metisMenu.changeActiveLinkTo('#/reportlist');
          break;
        case '/selfdeclarationReport':
          metisMenu.changeActiveLinkTo('#/selfdeclarationReport');
          break;
        case '/verificationReport':
          metisMenu.changeActiveLinkTo('#/verificationReport');
          break;
        case '/subspacesReport/:reportKey?':
          metisMenu.changeActiveLinkTo('#/subspacesReport');
          break;
      }
    }
  });

  return (
    <div className={collapsesidebar ? "sidebar-collapsed" : ""}>
      <MetisMenu
        ref={ref => metisMenu = ref}
        content={getMenu()}
        iconNamePrefix=""
        iconNameStateHidden=""
        iconNameStateVisible="rotate-minus-90"
        classNameStateIcon="icon-angleRight"
        onSelected={(e) => {
          let url = e.target.href.split("#");
          if (url[1] === '/marketPlace' && location.pathname !== '/marketPlace') {
            MarketplaceStatistics(accesstokenidp, userId, "marketplace", moment().toISOString());
          }
          onselectedmenuitem()
        }}
        activeLinkTo={"#" + location.pathname}
      />
    </div>
  );
};

const mapStateToProps = state => {
  const user = state.user;
  return {
    accesstokenidp: state.user.accesstokenidp,
    userId: user.id,
  };
};

const mapDispatchToProps = dispatch => ({
  MarketplaceStatistics: (accesstokenidp, userId, type, datetime) => dispatch(postMarketplaceStatistics(accesstokenidp, userId, type, datetime)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SidebarWrapper));
