import * as roles from "./roles";

let menuActive = [];

export const SidebarItemsForAdmin = (roleId, dictionary, settings) => {
  const items = [
    {
      icon: "icon-home",
      label: dictionary.home,
      to: "#/workspacelist"
    },
    /* {
      icon: "icon-location",
      label: dictionary.location,
      to: "#/location",
    }*/
  ];

  // const marketplace = {
  //   icon: "icon-market",
  //   label: dictionary.marketplace,
  //   to: "#/marketPlace"
  // };

  const checkpoint = {
    icon: "icon-cheeckout_new",
    label: dictionary.checkpoint,
    to: "#/checkInRegister",
  };

  const reports = {
    icon: "icon-reports",
    label: dictionary.reports,
    content: [
      {
        label: dictionary.safelyPassHistory,
        to: '#/reportlist',
      },
      {
        label: dictionary.selfDeclarations,
        to: '#/selfdeclarationReport',
      },
      {
        label: dictionary.verificationForms,
        to: '#/verificationReport',
      },
      {
        label: dictionary.subspaces,
        to: '#/subspacesReport',
      },
      {
        label: dictionary.transactions,
        to: '#/transactionsHistory',
      }
    ],
  };

  const userReports = {
    icon: "icon-reports",
    label: dictionary.reports,
    content: [
      {
        label: dictionary.safelyPassHistory,
        to: '#/reportlist',
      },
      {
        label: dictionary.subspaces,
        to: '#/subspacesReport',
      },
      {
        label: dictionary.transactions,
        to: '#/transactionsHistory',
      }
    ],
  };

  const userManagement = {
    icon: "icon-users",
    label: dictionary.userManagement,
    to: "#/users"
  };

  switch (roleId) {
    case roles.MONITOR:
      items.push(checkpoint);
      items.push(reports);
      // items.push(marketplace);
      break;
    case roles.USER:
      items.push(userReports);
      // items.push(marketplace);
      break;
    case roles.ADMIN:
      //items.splice(2,0, statistics)
      items.push(checkpoint);
      items.push(userManagement);
      items.push(reports);
      // items.push(marketplace);
      break;
    default:
      break;
  }

  return items;
};

export const SidebarItems = (roleId, dictionary, settings) => {
  menuActive = SidebarItemsForAdmin(roleId, dictionary, settings);
};

export const getMenu = () => menuActive;
