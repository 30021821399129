import React, { useState, useEffect } from "react";
import Button from "../commons/styled-components/general/Button";
import Input from "../commons/styled-components/general/Input";
import { connect } from "react-redux";
import {
  saveUserWorkspace,
  resetworkspaceFocused,
  creatingNewWorkspace,
} from "../../redux/actions/workspaceActions";
import {
  changeWorkspaceName,
  resetOnboarding,
} from "../../redux/actions/onboardingActions";
import { general } from "../../general/global";
import { withRouter } from "react-router-dom";
import "../../styles/workspace.css";
import { translate } from "../../redux/reducers/languageReducer";
import ProgessSteps from "./progessSteps";
import Header from "./header";

const CreateWorkspace = (props) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [deviceType, setdeviceType] = useState(window.deviceType);
  const [buttonCreate, setbuttonCreate] = useState(false);

  useEffect(() => {
    if (!props.creatingNewWorkspace) {
      setErrorMessage(null);
      props.onChangeWorkspaceName("");
    }
    const handleResize = () => {
      setdeviceType(window.deviceType);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [props.creatingNewWorkspace]);
  
  const saveWorkspace = async () => {
    if (props.workspacename !== "") {
      setbuttonCreate(true);
      const workspace = props.workspacename.trim();
      if (!workspace.replace(/\s/g, "").length) {
        setErrorMessage(props.dictionary.s("theWorkspaceNameIsInvalid"));
        setbuttonCreate(false);
      } else {
        if (!props.creatingNewWorkspace) {
            const response = await props.onSaveworkspace(
              props.accesstokenidp,
              workspace,
              props.userId
            );
            if (response.status) {
              setbuttonCreate(false);
              props.onCreatingNewWorkspace(true);
              props.history.push("/set-workspace");
            } else {
              setbuttonCreate(false);
              setErrorMessage(response.axActions[0][0].message);
            }
        } else {
          setbuttonCreate(false);
          props.history.push("/set-workspace");
        }
      }
    } else setErrorMessage(props.dictionary.s("theWorkspaceNameIsInvalid"));
  };

  return (
    <div className={deviceType + ' limiter create-workspace'}>
      <Header />
      <div className="container-login100">
        <div className="createnewSafelyspace">
          {props.dictionary.s("CreatenewSafelyspace")}
        </div>
        <div className="wrap-login100">
          <div className="login100-form validate-form create-workspace-form">
            <ProgessSteps step="1" />
            <div className="login100-form-title-paddingTop">
              <span className="login100-form-title">
                {props.dictionary.s("whatIsTheNameOfYourSafelyWorkspace")}
              </span>
            </div>
            <div className="workspaceNameHelp">
              <p>{props.dictionary.s("workspaceNameHelp")}</p>
              <div className="input-div">
                {(props.workspacecreated) ?
                  <Input
                    readOnly={true}
                    type="text"
                    name="email"
                    className="workspacesName"
                    placeholder={props.dictionary.s("workspacesName")}
                    borderradius="8px"
                    border={`1px solid ${general.theme.borderColorInputs}`}
                    onChange={(e) => {
                      props.onChangeWorkspaceName(e.target.value);
                      setErrorMessage(null);
                    }}
                    value={props.workspacecreated.name}
                    onKeyPress={(event) => {
                      let regex = new RegExp("[0-9A-Za-z\u00C0-\u017F ]+$");
                      let key = String.fromCharCode(
                        !event.charCode ? event.which : event.charCode
                      );
                      if (!regex.test(key)) {
                        event.preventDefault();
                        return false;
                      }
                    }}
                  /> :
                  <Input
                    type="text"
                    name="email"
                    maxLength="30"
                    className="workspacesName"
                    placeholder={props.dictionary.s("workspacesName")}
                    borderradius="8px"
                    border={`1px solid ${general.theme.borderColorInputs}`}
                    onChange={(e) => {
                      props.onChangeWorkspaceName(e.target.value);
                      setErrorMessage(null);
                    }}
                    value={props.workspacename}
                    onKeyPress={(event) => {
                      let regex = new RegExp("[0-9A-Za-z\u00C0-\u017F ]+$");
                      let key = String.fromCharCode(
                        !event.charCode ? event.which : event.charCode
                      );
                      if (!regex.test(key)) {
                        event.preventDefault();
                        return false;
                      }
                    }}
                  />
                }
              </div>
              {(props.message || errorMessage) && (
                <div
                  className="label-div label-error-message"
                  style={{
                    marginHorizontal: 20,
                    marginVertical: 10
                  }}
                >
                  <label>
                    {props.message || errorMessage}
                  </label>
                </div>
              )}
              {(props.workspaceFocused._id && !props.workspacecreated) && (
                <Button
                  className="backButton"
                  width="133px"
                  bgcolor="#1849E8"
                  position="absolute"
                  right="0"
                  borderradius="16px"
                  onClick={async () => {
                    props.history.push("/workspacelist");
                  }}
                >
                  {props.dictionary.s("back")}
                </Button>
              )}
              <Button
                className="nextButton"
                width="133px"
                bgcolor="#1849E8"
                position="absolute"
                right="0"
                borderradius="16px"
                onClick={() => saveWorkspace()}
                disabled={buttonCreate}
              >
                {props.dictionary.s(
                  (props.workspacecreated)
                    ? "next"
                    : "createSpace"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.workspace.loading,
  workspacename: state.onboarding.workspacename,
  userId: state.user.id,
  message: state.workspace.message,
  workspacecreated: state.workspace.workspaceCreated,
  creatingNewWorkspace: state.workspace.creatingNewWorkspace,
  workspacesList: state.workspace.list,
  workspaceFocused: state.workspace.workspaceFocused,
  accesstokenidp: state.user.accesstokenidp,
  dictionary: {
    s: key => translate(state.language.dictionary, key)
  }
});

const mapDispatchToProps = (dispatch) => ({
  onChangeWorkspaceName: (workspaceName) =>
    dispatch(changeWorkspaceName(workspaceName)),
  onSaveworkspace: (accesstokenidp, name, userId, settings) =>
    dispatch(saveUserWorkspace(accesstokenidp, name, userId, settings)),
  onResetOnboarding: () => dispatch(resetOnboarding()),
  onResetworkspaceFocused: () => dispatch(resetworkspaceFocused()),
  onCreatingNewWorkspace: creating => dispatch(creatingNewWorkspace(creating)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateWorkspace));