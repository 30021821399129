import React from "react";
import {
  deletePostHandler,
} from "../../redux/actions/postAction";
import { connect } from "react-redux";
import { general } from "../../general/global";
import { translate } from "../../redux/reducers/languageReducer";
import Modal from "../commons/wrapped-components/Modal";

const DeletePost = (props) => {
  return (
    <Modal
      title={props.dictionary.s("deletePostQuestion")}
      dialogClassName="safely-modal renderModalCreatSubSpace"
      showModal={props.showDeletePost}
      onHide={() => {
        props.closeDeletePost();
      }}
      onSave={async () => {
        if (props.selectedData) {
          if(props.selectedData.media) {
            props
            .onClickOfDeletePost(
              props.selectedData._id,
              props.accesstokenidp,
              props.selectedData.media[0].url.split("/")
            )
            .then(() => {
              props.closeDeletePost();
              props.PostViewData();
            })
            .catch((error) => {
              props.closeDeletePost();
            });
          }
          else {
            props
            .onClickOfDeletePost(
              props.selectedData._id,
              props.accesstokenidp,
            )
            .then(() => {
              props.closeDeletePost();
              props.PostViewData();
            })
            .catch((error) => {
              props.closeDeletePost();
            });
          }
        }
      }}
      onClose={() => {
        props.closeDeletePost();
      }}
      textButtonSave={props.loading ? "..." : props.dictionary.s("delete")}
      textButtonClose={props.dictionary.s("cancel")}
      propertyButtonSave={{
        block: false,
        style: {
          backgroundColor: "#E67070",
          color: general.theme.whiteFontColor,
          border: `1px solid #E67070`,
        },
      }}
      bsStyleButtonSave="secondary"
      buttonCloseProperties={{
        block: false,
        style: {
          backgroundColor: general.theme.whiteColor,
          border: `1px solid ${general.theme.primaryColor}`,
          color: general.theme.primaryColor,
        },
      }}
      properties={{
        centered: true,
      }}
      body={
        <>
          <div className="deletePostText">
            {props.dictionary.s("youAreAboutToDeletePost")}
          </div>
        </>
      }
      isLoadingButton={props.loading}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    accesstokenidp: state.user.accesstokenidp,
    loading: state.posts.loading,
    lang: state.language.lang,
    dictionary: {
      s: (key) => translate(state.language.dictionary, key),
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClickOfDeletePost: (postId, accesstokenidp, filePath) =>
    dispatch(deletePostHandler(postId, accesstokenidp, filePath)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DeletePost);
