import React from "react";

const TermsAndConditions = () => {
  return (
    <>
      <div className="container mt-5">
        <div className="text-bod text-center">
          <h3>TERMS AND CONDITIONS</h3>
        </div>
        <div className="bs-callout">
          <div className="text-bold">Last Updated: May 10, 2020</div>
          <div>
            This Application is operated by Tripi Connnected Tech, Inc. d/b/a
            Andonix, a Delaware Corporation, (hereinafter <b>“Andonix”</b>).
            These terms and conditions (<b>“Terms”</b>) govern your access to
            the Andonix Safely website <a href="https://safely.andonix.com">https://safely.andonix.com</a> and mobile
            application (collectively, the <b>“Application”</b>).
            <br />
            <br />
            The term <b>“you”</b> or <b>“User”</b> shall refer to any person or
            entity who views, uses, accesses, browses or submits any content or
            material to the Application (regardless of the type of account
            created on the Application, as described in detail below). The terms{" "}
            <b>“we,”</b> <b>“our”</b> or <b>“us”</b> shall refer to Andonix.
            <br />
            <br />
            <div>
              The Application is offered to you conditioned on your acceptance
              without modification of Terms contained herein. Your use of the
              Application constitutes your agreement to all such Terms. Please
              read these terms carefully, and keep a copy of them for your
              reference.
            </div>
            <div>
              These Terms are important and affect your legal rights, so please
              read them carefully. Note that these Terms contain a{" "}
              <em>mandatory arbitration provision</em> that requires the use of
              arbitration on an individual basis and limits the remedies
              available to you in the event of certain disputes.
            </div>
          </div>
        </div>
        <div className="bs-callout">
          <div className="text-bold">
            YOU ACKNOWLEDGE AND AGREE THAT, BY ACCESSING, USING AND/OR CREATING
            AN ACCOUNT, COMMUNICATING WITH USERS THROUGH THE APPLICATION, YOU
            ARE AFFIRMING THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND
            BY THESE TERMS, WHETHER OR NOT YOU HAVE REGISTERED WITH THE
            APPLICATION. IF YOU DO NOT AGREE TO THESE TERMS, THEN YOU HAVE NO
            RIGHT TO ACCESS OR USE THE APPLICATION.
          </div>
          <br />
          <div className="text-bold">PRIVACY</div>
          <div>
            Your use of the Application is subject to the Andonix Privacy
            Policy. Please review our{" "}
            <a href="https://safely.andonix.com/privacypolicy.html">
              Privacy Policy
            </a>
            , which also governs the Application and informs Users of our data
            collection practices.&nbsp;
          </div>
          <br />
          <div className="text-bold">USE OF OUR APPLICATION</div>
          <div>
            <p>
              The Application connects workers, employees and visitors with
              their organizations to enhance and improve safety, access
              compliance and monitoring.
            </p>
            <p>
              Subject to your compliance with its obligations under these Terms,
              we will provide you with access to the Services. Access to the
              Services is permitted on a temporary basis, and we reserve the
              right to withdraw or amend the service we provide without notice.
              We will not be liable if for any reason our Services are
              unavailable at any time or for any period. We may make some parts
              of our Application available only to Users who have registered an
              account with us.{" "}
              <b>
                You may be required to register an account with us and submit
                certain personal data to access certain parts of our
                Application.
              </b>
            </p>
            <p>
              You must be eighteen (18) or over in order to use the Application
              and its services. Andonix does not knowingly collect, either
              online or offline, personal information from persons under the age
              of 13.
            </p>
          </div>
          <br />
          <div>
            <div className="text-bold">
              REGISTRATION, ACCOUNT AND COMMUNICATION PREFERENCES
            </div>
            <div>
              <p>
                In order to access and use certain areas or features of the
                Application, you will need to have an account with us (the{" "}
                <b>“Account”</b>). By creating an Account, you agree to (i)
                provide accurate, current and complete account information about
                yourself as necessary, (ii) maintain and promptly update from
                time to time as necessary your Account information, (iii)
                maintain the security of your password and accept all risks of
                unauthorized access to your Account and the information you
                provide to us, and (iv) immediately notify us if you discover or
                otherwise suspect any security breaches related to your Account.
                In creating an Account, you represent that you are of legal age
                to form a binding contract and are not a person barred from
                receiving services under the laws of the United States or any
                other applicable jurisdiction. We reserve the right to suspend
                or terminate your Account if any information provided during the
                registration process or thereafter proves to be inaccurate,
                false or misleading.
              </p>
            </div>
            <div>
              <ol type="a">
                <li>
                  <div className="text-bold">
                    <em>Accounts</em>
                  </div>
                  <div>
                    Users of the Application may create different types of
                    accounts as different types of users:{" "}
                    <b>Administrators, Employees and Visitors</b>. Each type of
                    account may provide different types of access and tools. An{" "}
                    <b>“Administrator”</b> Account shall refer to such accounts
                    reserved for companies or entities managing and monitoring
                    employees and visitors to their workplace and facilities (a{" "}
                    <b>“Worksite”</b>) through the Application. An
                    <b>“Employee”</b> Account shall refer to an account linked
                    to an Administrator and for individuals employed by such
                    Administrator. A <b>“Visitor”</b> Account shall refer to an
                    account by an individual who is temporarily accessing an
                    Administrator Worksite, but is acting as an independent
                    contractor and is not employed by the Administrator.
                  </div>
                </li>
                <li>
                  <div className="text-bold">
                    <em>Communication Preferences</em>
                  </div>
                  <div>
                    By creating an Account, you also consent to receive
                    electronic communications from us (e.g., via email or by
                    posting notices to the Application). These communications
                    may include notices about your Account (e.g., password
                    changes, updates and other transactional information) and
                    are part of your relationship with us. You agree that any
                    notices, agreements, disclosures or other communications
                    that we send to you electronically will satisfy any legal
                    communication requirements, including, but not limited to,
                    that such communications be in writing.
                  </div>
                </li>
                <li>
                  <div className="text-bold">
                    <em>Account Information</em>
                  </div>
                  <div>
                    <p>
                      Upon your acceptance of these Terms, Andonix grants you a
                      non-exclusive, revocable, nontransferable limited license
                      to access and use the Andonix Application solely for your
                      personal use (and not for any commercial use or purpose on
                      your part) in the United States of America for purposes of
                      monitoring and submitting information and data voluntarily
                      provided by you and Andonix’s use of such information and
                      data in accordance with our Privacy Policy. It is a
                      condition of your use of the Application that all
                      information you provide to Andonix or input into the
                      Application is correct, current, and complete. You agree
                      that Andonix may collect, maintain, organize, modify, use,
                      publish, and disseminate any data or information you
                      submit and any other data or information about you that
                      Andonix collects or receives, either through or in
                      connection with the Application or from any other person
                      or entity, in accordance with our Privacy Policy.
                    </p>
                    <p>
                      You acknowledge and agree that, while Andonix may collect,
                      maintain, organize, modify, use, publish, and disseminate
                      information that is submitted to it, Andonix is not
                      responsible for the accuracy, completeness, timeliness,
                      quality, or any other aspect of such information. Any
                      reliance you place on such information is strictly at your
                      own risk.
                    </p>
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="bs-callout">
          <div className="text-bold">
            ANY AND ALL ACTIONS TAKEN BY ANY COMPANY, EMPLOYER, WORKSITE, OR ANY
            THIRD-PARTIES ARE MADE SOLELY BY SUCH ENTITIES AND PURSUANT TO THEIR
            RESPECTIVE INTERNAL POLICIES. ANDONIX DOES NOT ASSUME, AND WILL NOT
            HAVE, ANY LIABILITY OR RESPONSIBILITY TO ANY USER OR ANY OTHER
            PERSON OR ENTITY FOR ANY ACTION TAKEN BY ANY COMPANY, EMPLOYER,
            WORKSITE OR ANY THIRD-PARTY IN CONNECTION WITH ANY INFORMATION
            PROVIDED THROUGH THE APPLICATION OR USE OF THE APPLICATION. TO
            REVIEW HOW AN ADMINISTRATOR ACCOUNT MAY USE YOUR INFORMATION
            PROVIDED THROUGH THE APPLICATION, PLEASE REVIEW OUR PRIVACY POLICY.
          </div>
          <div>
            <ol type="a" start={4}>
              <li>
                <div className="text-bold">
                  <em>Account Access and Security</em>
                </div>
                <div>
                  <div>
                    You agree that you will treat your username, password, or
                    other security credentials or identifying information you
                    choose or are provided (collectively, the “Credentials”) as
                    confidential and that you will not disclose such Credentials
                    to any other person or entity unless you are required to do
                    so by law, in which case you will inform Andonix prior to
                    making the disclosure and will only disclose that portion of
                    the Credentials to the extent necessary to comply with legal
                    requirements.
                  </div>
                  <div>
                    You also acknowledge that your account is personal to you
                    and agree not to provide any other person with access to the
                    Andonix Application or portions of it using your
                    Credentials. You agree to notify Andonix immediately of any
                    unauthorized access to, or use of, your Credentials or any
                    other breach of security and to assist Andonix in mitigating
                    any inappropriate use, or disclosure of the information as
                    may be requested by Andonix. You also agree to protect any
                    physical devices you own or control which you use to access
                    the Application and to ensure that you click the “Log Out”
                    link and complete the log out function at the end of each
                    session. If you access your account from a public or shared
                    computer, you should use particular caution when doing so to
                    prevent others from viewing or recording your Credentials or
                    any other protected information.
                  </div>
                  <br />
                  <div>
                    Andonix reserves the right to monitor and audit all use of
                    the Andonix Application and to disable any user name,
                    password or other identifier at any time in its sole
                    discretion, without notice and without cause.
                  </div>
                </div>
              </li>
              <li>
                <div className="text-bold">
                  <em>Third-Party Account Login</em>
                </div>
                <div>
                  We may provide tools through the Application that enable you
                  to create an account and login through a third party service,
                  including through features that allow you to link your account
                  on the Application with an account from a third party service
                  (such as Google, Facebook, LinkedIn), and import and export
                  information from such third party services. By using one of
                  these tools, you represent, warrant, and agree that such
                  transfers are permitted under applicable law, and that you are
                  authorized to, and that we may on your behalf, transfer that
                  information to or from the applicable third-party service, as
                  applicable. Third party services are not under our control,
                  and we are not responsible for any third party service’s use
                  of exported information. When using or accessing services
                  offered through third party service providers, you will be
                  subject to terms and privacy policies posted by those service
                  providers.
                </div>
              </li>
            </ol>
          </div>
          <div className="text-bold">ACCESS TO THE APPLICATION; UPDATES</div>
          <div>
            <p>
              We shall use commercially reasonable efforts to ensure the
              availability of the Application, except that we shall not be
              liable for: (a) scheduled downtime; or (b) any unavailability
              caused directly or indirectly by circumstances beyond our
              reasonable control, including without limitation, (i) a force
              majeure event; (ii) Internet service provider, webhosting, cloud
              computing platform, or public telecommunications network failures
              or delays, or denial of service attacks; (iii) a fault or failure
              of your computer systems or networks; or (iv) any breach by of
              these Terms by you. We may provide periodical updates to the
              Application which may include upgrades, bug fixes, patches and
              other error corrections, or new features (collectively, including
              related documentation,
              <b>"Updates"</b>). Updates may also modify or delete in their
              entirety certain features and functionality. You agree that,
              Andonix has no obligation to provide any Updates or to continue to
              provide or enable any particular features or functionality.
            </p>
            <p>
              If the Application is provided to you in the form of an
              application downloaded to a mobile device, depending upon the
              settings of that mobile device, when the mobile device is
              connected to the internet: (a) Updates to the Application may
              automatically download and install; or (b) you may receive notice
              of, or be prompted to download and install, available Updates. You
              may receive no notice of a particular Update if your device is
              configured to automatically download and install Updates. Andonix
              recommends that you promptly download and install all Updates. You
              acknowledge and agree that the Application or portions thereof may
              not properly operate if you do not download and install Updates.
              You further agree that all Updates will be deemed part of the
              Application and be subject to all terms and conditions set forth
              in these Terms of Service.
            </p>
            <p>
              Andonix reserves the right to alter, modify, or terminate the
              Application and any service or material Andonix provides in
              connection with the Application without notice at any time in
              Andonix’s sole discretion. From time to time, Andonix may restrict
              access to some portions of the Application or the entire
              Application.{" "}
              <b>
                ANDONIX WILL NOT BE LIABLE IF, FOR ANY REASON, ALL OR ANY PART
                OF THE APPLICATION IS UNAVAILABLE AT ANY TIME OR FOR ANY PERIOD.
              </b>
            </p>
          </div>
          <div className="text-bold">PROHIBITED USES</div>
          <div>
            <div>
              The use of the Application is limited to the permitted uses
              expressly authorized by us. You shall not use the Application or
              any part thereof:
            </div>
            <div>
              <ol type="a">
                <li>To copy the Application or any part thereof;</li>
                <li>
                  To modify, translate, adapt, or otherwise create derivative
                  works or improvements, whether or not copyrightable or
                  patentable, of the Application or any part thereof;
                </li>
                <li>
                  To reverse engineer, disassemble, decompile, decode, or
                  otherwise attempt to derive or gain access to the source code
                  of the Application or any part thereof;
                </li>
                <li>
                  In any way that violates any applicable federal, state, local,
                  or international law or regulations;
                </li>
                <li>
                  For the purpose of exploiting or harming, or attempting to
                  exploit or harm, any other person in any way;
                </li>
                <li>
                  To transmit, or procure the sending of, any advertising or
                  promotion material, including, but not limited to, “spam” or
                  similar solicitation;
                </li>
                <li>
                  To impersonate Andonix, an Andonix employee, another user, or
                  any other person or entity, including, but not limited to, by
                  using an e-mail address associated with any of the foregoing;
                </li>
                <li>
                  To restrict or inhibit any other person or entity’s use or
                  enjoyment of the Application or any portion thereof or which
                  could expose any other person or entity to liability;
                </li>
                <li>
                  In a manner that could disable, overburden, damage, or impair
                  the Application or interfere with any other person or entity’s
                  use of the Application;
                </li>
                <li>
                  use a robot, spider, or other automatic device, process, or
                  means to access the Application for any purpose, including,
                  but not limited to, monitoring or copying any of the material
                  collected, maintained, organized, modified, used, published,
                  or disseminated by Andonix or through the Application without
                  Andonix’s prior written consent;
                </li>
                <li>
                  use any manual process to monitor or copy any of the material
                  collected, maintained, organized, modified, used, published,
                  or disseminated by Andonix or through the Application without
                  Andonix’s prior written consent;
                </li>
                <li>
                  use any device, software, or routine that interferes with the
                  proper working of the Application or any part thereof;
                </li>
                <li>
                  To attempt to gain unauthorized access to, interfere with,
                  damage, or disrupt any part of the Application, the server on
                  which the Application is stored, or any server, computer, or
                  database connected to the Application;
                </li>
                <li>
                  To introduce any virus, Trojan horse, worm, logic bomb, or
                  other material which is malicious or technologically harmful
                  to the Application or any part thereof;
                </li>
                <li>
                  To remove, delete, alter, or obscure any trademarks or any
                  copyright, trademark, patent or other intellectual property or
                  proprietary rights notice from the Application or any part
                  thereof; or
                </li>
                <li>
                  To rent, lease, lend, sell, sublicense, assign, distribute,
                  publish, transfer, or otherwise make available the Application
                  or any of its features or functionality or any part thereof to
                  any third party for any reason, including, but not limited to,
                  by making the Application available on a network where it is
                  capable of being accessed by more than one device at any time.
                </li>
              </ol>
            </div>
          </div>
          <div className="text-bold">INTELLECTUAL PROPERTY RIGHTS</div>
          <div>
            <p>
              You are granted a non-exclusive, non-transferable, revocable
              license to access and use the Application strictly in accordance
              with these Terms. As a condition of your use of the Application,
              you warrant to us that you will not use the Application for any
              purpose that is unlawful or prohibited by these Terms. You may not
              use the Application in any manner which could damage, disable,
              overburden, or impair the Application or interfere with any other
              party's use and enjoyment of the Application. You may not obtain
              or attempt to obtain any materials or information through any
              means not intentionally made available or provided for through the
              Application.&nbsp;
            </p>
            <p>
              All content included as part of the Application, such as text,
              graphics, logos, images, as well as the compilation thereof, and
              any software used on the Application, is the property of Andonix
              or its suppliers and protected by copyright and other laws that
              protect intellectual property and proprietary rights. You agree to
              observe and abide by all copyright and other proprietary notices,
              legends or other restrictions contained in any such content and
              will not make any changes thereto.&nbsp;
            </p>
            <p>
              You will not modify, publish, transmit, reverse engineer,
              participate in the transfer or sale, create derivative works, or
              in any way exploit any of the content, in whole or in part, found
              on the Application. Our content is not for resale. Your use of the
              Application does not entitle you to make any unauthorized use of
              any protected content, and in particular you will not delete or
              alter any proprietary rights or attribution notices in any
              content. You will use protected content solely for your personal
              use, and will make no other use of the content without the express
              written permission of Andonix and the copyright owner. You agree
              that you do not acquire any ownership rights in any protected
              content. We do not grant you any licenses, express or implied, to
              the intellectual property of Andonix or our licensors except as
              expressly authorized by these Terms.&nbsp;
            </p>
          </div>
          <div className="text-bold">FEEDBACK</div>
          <div>
            <p>
              You can submit questions, comments, suggestions, ideas, original
              or creative materials or other information about Andonix, or the
              Application or the services provided through the Application
              (collectively, <b>“Feedback”</b>). Feedback is non-confidential
              and shall become the sole property of Andonix. We shall own
              exclusive rights, including, without limitation, all intellectual
              property rights, in and to such Feedback and shall be entitled to
              the unrestricted use and dissemination of this Feedback for any
              purpose, commercial or otherwise, without acknowledgment or
              compensation to you.
            </p>
          </div>
          <div className="text-bold">
            LINKS TO THIRD PARTY WEBSITES/THIRD-PARTY SERVICES
          </div>
          <div>
            <p>
              The Website may contain links to other brands or services ("Linked
              Website"). The Linked Websites are not under the control of
              Andonix and we are not responsible for the contents of any Linked
              Websites, including without limitation any link contained in a
              Linked Website, products or merchandise sold through the Website,
              or any changes or updates to a Linked Website. We are providing
              these links to you only as a convenience, and the inclusion of any
              link does not imply endorsement by Andonix of the services or any
              association with its operators. Certain services made available
              through the Website are delivered by third parties and
              organizations. By using any product, service or functionality
              originating from the Website, you hereby acknowledge and consent
              that we may share such information and data with any third party
              with whom we have a contractual relationship to provide the
              requested product, service or functionality on behalf of Andonix
              and customers.
            </p>
          </div>
          <div className="text-bold">USE OF COMMUNICATION SERVICES</div>
          <div>
            The Services may contain comment sections, chat areas, forums,
            reviews, and/or other message or communication facilities designed
            to enable you to communicate with other Users (collectively,
            <b>"Communication Services"</b>). You agree to use the Communication
            Services only to post, send and receive messages and material that
            are proper and related to the particular Communication
            Service.&nbsp;By way of example, and not as a limitation, you agree
            that when using a Communication Service, you will not:
          </div>
          <div>
            <ol type="a">
              <li>
                defame, abuse, harass, stalk, threaten or otherwise violate the
                legal rights (such as rights of privacy and publicity) of
                others;
              </li>
              <li>
                publish, post, upload, distribute or disseminate any
                inappropriate, profane, defamatory, infringing, obscene,
                indecent or unlawful topic, name, material or information;
              </li>
              <li>
                upload files that contain software or other material protected
                by intellectual property laws (or by rights of privacy of
                publicity) unless you own or control the rights thereto or have
                received all necessary consents;
              </li>
              <li>
                upload files that contain viruses, corrupted files, or any other
                similar software or programs that may damage the operation of
                another's computer;
              </li>
              <li>
                advertise or offer to sell or buy any goods or services for any
                business purpose, unless such Communication Service specifically
                allows such messages;
              </li>
              <li>
                conduct or forward surveys, contests, pyramid schemes or chain
                letters;
              </li>
              <li>
                download any file posted by another user of a Communication
                Service that you know, or reasonably should know, cannot be
                legally distributed in such manner;
              </li>
              <li>
                falsify or delete any author attributions, legal or other proper
                notices or proprietary designations or labels of the origin or
                source of software or other material contained in a file that is
                uploaded,
              </li>
              <li>
                restrict or inhibit any other user from using and enjoying the
                Communication Services;
              </li>
              <li>
                violate any code of conduct or other guidelines which may be
                applicable for any particular Communication Service;
              </li>
              <li>
                harvest or otherwise collect information about others, including
                e-mail addresses, without their consent;
              </li>
              <li>violate any applicable laws or regulations.&nbsp;</li>
            </ol>
          </div>
          <div>
            We have no obligation to monitor the Communication Services.
            However, we reserve the right to review materials posted to a
            Communication Service and to remove any materials in its sole
            discretion. We reserve the right to terminate your access to any or
            all of the Communication Services at any time without notice for any
            reason whatsoever.&nbsp;
          </div>
          <br />
          <div className="text-bold">LIMITATIONS OF LIABILITY</div>
          <div>
            By using the Application, you agree that Andonix and each of their
            respective parents, affiliates, subsidiaries, franchisees,
            representatives, consultants, contractors, legal counsel,
            advertising, public relations, promotional, fulfillment and
            marketing agencies, Application providers, web masters, and their
            respective officers, directors, employees, representatives and
            agents (the <b>"Released Parties"</b>) will not be responsible for
            any incorrect or inaccurate information, whether caused by you or by
            any of the equipment or programming associated with or utilized to
            access the Application; technical failures of any kind, including,
            but not limited to malfunctions, interruptions, or disconnections in
            network hardware or software or technical or human error.
          </div>
          <div>
            <p>
              TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
              SHALL ANDONIX OR ANY OF THE RELEASED PARTIES BE LIABLE FOR ANY
              INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR
              PUNITIVE DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, LOSS
              OF REVENUE, INCOME OR PROFITS) ARISING OUT OF OR IN ANY WAY
              RELATED TO THE ACCESS OR USE OF THE APPLICATION OR THE USE OF ANY
              SERVICES PROVIDED BY ANDONIX OR OTHERWISE RELATED TO THESE TERMS,
              REGARDLESS OF THE FORM OF ACTION, WHETHER BASED IN CONTRACT, TORT
              (INCLUDING, BUT NOT LIMITED TO, SIMPLE NEGLIGENCE, WHETHER ACTIVE,
              PASSIVE OR IMPUTED), STRICT PRODUCT LIABILITY OR ANY OTHER LEGAL
              OR EQUITABLE THEORY (EVEN IF THE PARTY HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF WHETHER SUCH DAMAGES
              WERE FORESEEABLE). TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
              LAW, IN NO EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF ANDONIX
              ARISING OUT OF OR IN ANY WAY RELATED TO THESE TERMS, THE ACCESS TO
              AND USE OF THE APPLICATION EXCEED THE AMOUNT PAID BY YOU FOR THE
              SERVICES PROVIDED BY ANDONIX, IF ANY, IN THE SIX (6) MONTHS PRIOR
              TO THE DATE WHEN SUCH CLAIM AROSE.
            </p>
            <p>
              ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF, OR
              RELATING TO, THESE TERMS OF SERVICE OR THE APPLICATION MUST BE
              COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION OR CLAIM
              ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY
              BARRED.
            </p>
          </div>
          <br />
          <div className="text-bold">INDEMINIFICATION</div>
          <div>
            You agree to indemnify, defend and hold harmless ANDONIX, its
            EMPLOYEES, AGENTS, OFFICERS, DIRECTORS, SHAREHOLDERS, LICENSORS, AND
            SERVICE PROVIDERS, for any losses, costs, liabilities and expenses
            (including reasonable attorney's fees AND COSTS) relating to or
            arising out of your use of or inability to use the APPLICATION, your
            violation of these Terms or your violation of any rights of a third
            party, or your violation of any applicable laws, rules or
            regulations. We reserve the right to assume the exclusive defense
            and control of any matter otherwise subject to indemnification by
            you, in which event you will fully cooperate with us in asserting
            any available defenses.&nbsp;
          </div>
          <br />
          <div className="text-bold">HARM FROM COMMERCIAL USE</div>
          <div>
            You agree that the consequences of commercial use or re-publication
            of content or information from the Application may be so serious and
            incalculable, that monetary compensation may not be a sufficient or
            appropriate remedy and that we will be entitled to temporary and
            permanent injunctive relief to prohibit such use.
          </div>
          <br />
          <div className="text-bold">DISCLAIMER OF WARRANTIES</div>
          <div>
            <p>
              THE APPLICATION AND ALL INFORMATION OBTAINED USING THE APPLICATION
              IS PROVIDED "AS IS" AND WITH ALL FAULTS AND DEFECTS WITHOUT
              WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER
              APPLICABLE LAW, ANDONIX, ON ITS OWN BEHALF AND ON BEHALF OF ITS
              AFFILIATES AND ITS AND THEIR RESPECTIVE EMPLOYEES, AGENTS,
              OFFICERS, DIRECTORS, SHAREHOLDERS, LICENSORS, AND SERVICE
              PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS,
              IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE APPLICATION,
              INCLUDING, BUT NOT LIMITED TO, ALL IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
              NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF
              DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE. WITHOUT
              LIMITATION TO THE FOREGOING, ANDONIX PROVIDES NO WARRANTY OR
              UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND, THAT THE
              APPLICATION OR ANY DATA OBTAINED USING THE APPLICATION WILL MEET
              YOUR REQUIREMENTS; ACHIEVE ANY INTENDED RESULTS; BE COMPATIBLE OR
              WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS OR SERVICES;
              OPERATE WITHOUT INTERRUPTION; MEET ANY PERFORMANCE OR RELIABILITY
              STANDARDS; OR BE ERROR FREE OR THAT ANY ERRORS OR DEFECTS CAN OR
              WILL BE CORRECTED.
            </p>
            <p>
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF, OR LIMITATIONS
              ON, IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE
              STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE
              EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU, AND THE FOREGOING
              DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED
              UNDER APPLICABLE LAW.
            </p>
          </div>
          <div className="text-bold">RELIANCE ON INFORMATION POSTED</div>
          <div>
            <p>
              Commentary and other materials posted on the Application are not
              intended to amount to advice on which reliance should be placed.
              We therefore disclaim all liability and responsibility arising
              from any reliance placed on such materials by any visitor of the
              Application, or by anyone who may be informed of any of its
              contents.
            </p>
            <p className="text-bold">
              YOU UNDERSTAND AND AGREE THAT THE INFORMATION PROVIDED THROUGH THE
              APPLICATION IS FOR INFORMATIONAL PURPOSES ONLY. THE CONTENT IS NOT
              INTENDED TO BE A SUBSTITUTE FOR OTHER TOOLS OR SERVICES. NEVER
              DISREGARD OTHER ESSENTIAL INFORMATION BECAUSE OF SOMETHING YOU
              HAVE READ ON THIS APPLICATION.
            </p>
            <p className="text-bold">
              IF YOU THINK YOU MAY HAVE A MEDICAL EMERGENCY, CALL YOUR DOCTOR,
              GO TO A HOSPITAL OR CALL 911 IMMEDIATELY. ANDONIX DOES NOT
              RECOMMEND OR ENDORSE ANY SPECIFIC, TESTS, PHYSICIANS, PRODUCTS,
              PROCEDURES, OPINIONS, OR OTHER INFORMATION THAT MAY BE MENTIONED
              ON OUR APPLICATION. RELIANCE ON ANY INFORMATION PROVIDED ON THE
              APPLICATION BY ANDONIX AND ANY OF ITS EMPLOYEES, OFFICERS,
              CONTRACTORS IS SOLELY AT YOUR OWN RISK.
            </p>
            <p className="text-bold">
              ALL USERS OF THE APPLICATION SHALL AT ALL TIMES FOLLOW AND COMPLY
              WITH ANY AND ALL LOCAL, MUNICIPAL, STATE OR FEDERAL REQUIREMENTS,
              MANDATES, EXECUTIVE ORDERS OR APPLICABLE LAWS, INCLUDING BUT NOT
              LIMITED TO, CURFEWS, STAY-AT-HOME REQUIREMENTS, SHELTER-IN-PLACE
              ORDERS, MANDATORY QUARANTINES AND THE LIKE (COLLECTIVELY,
              “APPLICABLE LAWS”). ANDONIX SHALL NOT BE LIABLE FOR ANY VIOLATIONS
              AND/OR FINES IMPOSED TO A PARTICULAR USER FOR NON COMPLIANCE WITH
              ANY SUCH APPLICABLE LAWS.
            </p>
          </div>
          <div className="text-bold">
            TERMINATION OR MODIFICATION OF SERVICE
          </div>
          <div>
            <p>
              We reserve the right to refuse registration, to suspend, block,
              prevent access to, cancel, or otherwise terminate your right to
              use the Application at any time, with or without cause, in our
              sole discretion and without prior notice to you. We may refuse to
              accept or may cancel any registration, whether or not the
              registration has been confirmed, for any or no reason, and without
              liability to you or anyone else. We also reserve the right to
              limit or prohibit all activity, including Accounts that, in our
              sole judgment, appear to be malicious or unlawful. All provisions
              of these Terms, which by their nature should survive termination,
              shall survive termination, including, without limitation,
              ownership provisions, warranty disclaimers, indemnity, and
              limitations of liability.
            </p>
          </div>
          <div className="text-bold">ERRORS, INACCURACIES AND OMISSIONS</div>
          <div>
            <p>
              Occasionally there may be information on the Application that
              contains typographical errors, inaccuracies or omissions that may
              relate to product or service descriptions, pricing, promotions,
              offers, charges and availability. We reserve the right to correct
              any errors, inaccuracies or omissions, and to change or update
              information or cancel transactions if any information in the
              Application is inaccurate at any time without prior notice. We
              undertake no obligation to update, amend or clarify information on
              the Application, except as required by law. No specified update or
              refresh date applied in the Application, should be taken to
              indicate that all information in the Application has been modified
              or updated.
            </p>
            <p>
              ANDONIX DOES NOT REPRESENT, WARRANT OR OTHERWISE ENDORSE THAT THE
              APPLICATION OR ANY CONTENT OR INFORMATION THAT IS AVAILABLE,
              ADVERTISED OR SOLD THROUGH THE APPLICATION ARE ACCURATE, COMPLETE,
              AVAILABLE, CURRENT, OR THAT THE RESULTS OF USING THE APPLICATION
              WILL MEET YOUR REQUIREMENTS. WE RESERVE THE RIGHT TO CORRECT ANY
              ERRORS OR OMISSIONS IN THE APPLICATION.
            </p>
          </div>
          <div className="text-bold">ELECTRONIC COMMUNICATIONS</div>
          <div>
            <p>
              Using the Application or sending emails to us constitutes
              electronic communications. You consent to receive electronic
              communications and you agree that all agreements, notices,
              disclosures and other communications that we provide to you
              electronically, via email and on the Application, satisfy any
              legal requirement that such communications be in writing.
            </p>
            <p className="text-bold"></p>
            <div>ARBITRATION</div>
            By agreeing to the Terms, you agree that you are required to resolve
            any claim that you may have against ANDONIX on an individual basis
            in arbitration, as set forth in this Arbitration SECTION. This will
            preclude you from bringing any class, collective, or representative
            action against Andonix, and also preclude you from participating in
            or recovering relief under any current or future class, collective,
            consolidated, or representative action brought against Andonix
            someone else.
            <p />
            <p>
              You and Andonix agree that any dispute, claim or controversy
              arising out of or relating to (a) these Terms or the existence,
              breach, termination, enforcement, interpretation or validity
              thereof, or (b) your access to or use of the Application at any
              time, whether before or after the date you agreed to the Terms,
              will be settled by binding arbitration between you and Andonix,
              and not in a court of law.
            </p>
            <p>
              The arbitration shall be administered by the American Arbitration
              Association (<b>"AAA"</b>) in accordance with the AAA’s Consumer
              Arbitration Rules and the Supplementary Procedures for Consumer
              Related Disputes (the <b>"AAA Rules"</b>) then in effect, except
              as modified by this Arbitration Agreement. The parties agree that
              the arbitrator (<b>“Arbitrator”</b>), and not any federal, state,
              or local court or agency, shall have exclusive authority to
              resolve any disputes relating to the interpretation,
              applicability, enforceability or formation of this Arbitration
              Agreement, including any claim that all or any part of this
              Arbitration Agreement is void or voidable. Notwithstanding any
              choice of law or other provision in the Terms, the parties agree
              and acknowledge that this Arbitration Agreement evidences a
              transaction involving interstate commerce and that the Federal
              Arbitration Act, (<b>“FAA”</b>), will govern its interpretation
              and enforcement and proceedings pursuant thereto. It is the intent
              of the parties that the FAA and AAA Rules shall pre-empt all state
              laws to the fullest extent permitted by law. If the FAA and AAA
              Rules are found to not apply to any issue that arises under this
              Arbitration Agreement or the enforcement thereof, then that issue
              shall be resolved under the laws of the state of Michigan.
            </p>
            <p>
              The Arbitrator’s award shall be final, and judgment may be entered
              upon it in any court having jurisdiction. In the event that any
              legal or equitable action, proceeding or arbitration arises out of
              or concerns these Terms, the prevailing party shall be entitled to
              recover its costs and reasonable attorney's fees. The parties
              agree to arbitrate all disputes and claims in regards to these
              Terms or any disputes arising as a result of these Terms, whether
              directly or indirectly, including tort claims that are a result of
              these Terms. The entire dispute, including the scope and
              enforceability of this arbitration provision shall be determined
              by the Arbitrator. YOU UNDERSTAND AND AGREE THAT YOU ARE GIVING UP
              THE RIGHT TO GO TO COURT AND HAVE A DISPUTE HEARD BY A JUDGE OR
              JURY. This Arbitration Agreement shall survive the termination of
              these Terms.
            </p>
          </div>
          <div className="text-bold">CLASS ACTION WAIVER</div>
          <div>
            <p>
              Any arbitration or action under these Terms will take place on an
              individual basis; class arbitrations and
              class/representative/collective actions are not permitted. THE
              PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY
              IN THEIR INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
              MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE
              PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL
              ACTION AGAINST THE OTHER. Further, the arbitrator or judge may not
              consolidate more than one person's claims, and may not otherwise
              preside over any form of a representative or class
              proceeding.&nbsp;
            </p>
          </div>
          <div className="text-bold">JURISDICTION AND APPLICABLE LAW</div>
          <div>
            <p>
              If for any reason, a dispute proceeds in court, you irrevocably
              consent to the exclusive jurisdiction of the state and/or federal
              courts in the State of Michigan for purposes of any legal action
              arising out of or related to the use of the Application or these
              Terms. To the maximum extent permitted by law, this agreement is
              governed by the laws of the State of Michigan, United States of
              America, without regard to Michigan conflict of laws rules. Use of
              the Application is unauthorized in any jurisdiction that does not
              give effect to all provisions of these Terms, including, without
              limitation, this section.&nbsp;
            </p>
          </div>
          <div className="text-bold">ENTIRE AGREEMENT</div>
          <div>
            <p>
              Unless otherwise specified herein, this agreement constitutes the
              entire agreement between you and us with respect to the
              Application and it supersedes all prior or contemporaneous
              communications and proposals, whether electronic, oral or written,
              between you and us.
            </p>
          </div>
          <div className="text-bold">CHANGES TO TERMS</div>
          <div>
            <p>
              We reserve the right, in our sole discretion, to change the Terms
              under which the Application is offered. The most current version
              of the Terms will supersede all previous versions. We encourage
              you to periodically review the Terms to stay informed of our
              updates. We may alter or amend our Terms by giving you reasonable
              notice. By continuing to use the Application after expiry of the
              notice period, or accepting the amended Terms (as we may decide at
              our sole discretion), you will be deemed to have accepted any
              amendment to these Terms.
            </p>
          </div>
          <div className="text-bold">RELATIONSHIP BETWEEN THE PARTIES</div>
          <div>
            <p>
              The Parties are independent contractors and nothing in these Terms
              shall be construed as making either party the partner, joint
              venturer, agent, legal representative, employer, contractor, or
              employee of the other. Each Party has sole responsibility for its
              activities and its personnel, and shall have no authority and
              shall not represent to any third party that it has the authority
              to bind or otherwise obligate the other Party in any manner.
            </p>
          </div>
          <div className="text-bold">SEVERABILITY</div>
          <div>
            <p>
              If any term, clause or provision of these Terms is held invalid or
              unenforceable, then that term, clause or provision will be
              severable from these Terms and will not affect the validity or
              enforceability of any remaining part of that term, clause or
              provision, or any other term, clause or provision of these Terms.
            </p>
          </div>
          <div className="text-bold">FORCE MAJEURE</div>
          <div>
            <p>
              We shall be excused from performance under these Terms, to the
              extent we are prevented or delayed from performing, in whole or in
              part, as a result of an event or series of events caused by or
              resulting from: (a) weather conditions or other elements of nature
              or acts of God; (b) acts of war, acts of terrorism, insurrection,
              riots, civil disorders, or rebellion; (c) quarantines or
              embargoes; (d) labor strikes; (e) error or disruption to major
              computer hardware or networks or software failures; or (g) other
              causes beyond the reasonable control of Andonix.
            </p>
          </div>
          <div className="text-bold">MISCELLANEOUS</div>
          <div>
            <p>
              These Terms constitute the entire agreement between you and us
              relating to your access to and use of the Application. The
              Application is controlled and operated from within the United
              States. Without limiting anything else, we make no representation
              that the Application, information or other materials available on,
              in, or through the Application are appropriate or available for
              use in other locations, and access to them from territories where
              they are illegal is prohibited. Those who choose to access our
              Application from other locations do so on their own volition and
              are responsible for compliance with applicable laws. These Terms,
              and any rights and licenses granted hereunder, may not be
              transferred or assigned by you without our prior written consent.
              The waiver or failure of Andonix to exercise in any respect any
              right provided hereunder shall not be deemed a waiver of such
              right in the future or a waiver of any of other rights established
              under these Terms. Headings used in these Terms are for reference
              only and shall not affect the interpretation of these Terms. No
              person or entity not party to this agreement will be deemed to be
              a third-party beneficiary of these Terms or any provision hereof.
              When used herein, the words “includes” and “including” and their
              syntactical variations shall be deemed followed by the words
              “without limitation.”
            </p>
          </div>
          <div className="text-bold">
            <p>
              The Application is not in any way associated with or endorsed by
              the Centers for Disease Control and Prevention (CDC), World Health
              Organization (WHO) or any governmental entity.
            </p>
          </div>
          <div className="text-bold">CONTACT US</div>
          <div>
            We welcome your questions or comments regarding these Terms:&nbsp;
          </div>
          <br />
          <div className="text-bold">
            TRIPI CONNECTED TECH, INC. d/b/a Andonix
          </div>
          <br />
          <div>
            <div className="text-bold">By Mail:</div>
            <div>18615 Sherwood Ave</div>
            <div>Detroit, MI 34786</div>
            <br />
            <div className="text-bold">By Phone:</div>
            <div>1 (888) 479-7239</div>
            <br />
            <div className="text-bold">By E-mail:</div>
            <div>
              <a href="mailto:info@andonix.com">info@andonix.com</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
