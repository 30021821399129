import React, { useState } from 'react';
import { connect } from 'react-redux'
import { updateProfileBackgroundPhoto } from "../../redux/actions/userActions";
import ImageLoader from "../commons/wrapped-components/ImageLoader";
import InputPhoto from "../commons/wrapped-components/inputs/InputPhoto";

const UserProfileBackground = (props) => {
    const [loading, setLoading] = useState(false);

    const loadupdateProfileBackgroundPhoto = async (imagePreviewUrl, file) => {
        setLoading(true)
        const { status } = await props.onUpdateProfileBackgroundPhoto(
            imagePreviewUrl,
            file,
            props.userId
        );
        if (status) setLoading(false)
    }

    const backgroundStyle = {
        width: '100%',
        height: '100%',
        borderRadius: '20px 20px 0 0',
    };

    return (
        <div style={{ width: '100%' }}>
            {
                loading ? <ImageLoader styles={backgroundStyle} /> :
                    <div style={{
                        backgroundImage: `url(${props.backgroundPicture})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        ...backgroundStyle
                    }}></div>
            }
            <InputPhoto
                imagePreviewURL=""
                mode="onlyIcon"
                styleIcon={{
                    borderRadius: "50%",
                    width: 40,
                    height: 40,
                    border: "1px solid #ccc",
                    right: 20,
                    bottom: 21,
                    left: null
                }}
                onScreenShot={async (file, imagePreviewUrl) => {
                    await loadupdateProfileBackgroundPhoto(imagePreviewUrl, file);
                }}
            />
        </div>
    );
}

const mapStateProps = state => {
    const user = state.user;
    return {
        userId: user.id,
        backgroundPicture: user.backgroundPicture,
    };
}

const mapDispatchToProps = dispatch => ({
    onUpdateProfileBackgroundPhoto: (uri, file, userId) => dispatch(updateProfileBackgroundPhoto(uri, file, userId)),
});

export default connect(mapStateProps, mapDispatchToProps)(UserProfileBackground);