//import S3 from 'aws-sdk/clients/s3';
import credentials from './amazon.s3.credentials';
import {
    SPINNER_PROGRESS
} from "./redux/actions/types";
const AWS = require('aws-sdk');

const _config = {
    bucket: 'safely-media',
    keyPrefix: 'image-postfeed/',
    region: 'us-west-2',
    accessKey: credentials.accessKeyId,
    secretKey: credentials.secretAccessKey
}

export default class AmazonStorage {
    constructor(config = {}) {
        this.config = { ..._config, ...config };

        AWS.config.update({
            accessKeyId: this.config.accessKey,
            secretAccessKey: this.config.secretKey,
            region: this.config.region
        });
    }
    async uploadImage(base64, userid, dispatch) {
        try {
            const s3 = new AWS.S3();
            const base64Data = new Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64');
            const type = base64.split(';')[0].split('/')[1];
            const params = {
                ACL: 'public-read',
                Body: base64Data,
                Bucket: this.config.bucket,
                ContentEncoding: 'base64',
                ContentType: `image/${type}`,
                Key: `${this.config.keyPrefix}${new Date().getTime()}${userid}.jpeg`
            };
            const response = await s3.upload(params).on('httpUploadProgress', function (evt) {
                let number = parseInt(((evt.loaded * 100) / evt.total) - 3);
                dispatch({ type: SPINNER_PROGRESS, payload: number <= 0 ? 1 : number });
            }).promise();
            return response;
        } catch (e) {
            console.log("Error", e);
        }
    }
    async uploadVedio(base64, userid, dispatch) {
        try {
            const s3 = new AWS.S3();
            const base64Data = new Buffer.from(base64.replace(/^data:video\/\w+;base64,/, ""), 'base64');
            const type = base64.split(';')[0].split('/')[1];
            const params = {
                ACL: 'public-read',
                Body: base64Data,
                Bucket: this.config.bucket,
                ContentEncoding: 'base64',
                ContentType: `video/${type}`,
                Key: `${this.config.keyPrefix}${new Date().getTime()}${userid}.mp4`
            };
            const response = await s3.upload(params).on('httpUploadProgress', function (evt) {
                let number = parseInt(((evt.loaded * 100) / evt.total) - 3);
                dispatch({ type: SPINNER_PROGRESS, payload: number <= 0 ? 1 : number });
            }).promise();
            return response;
        } catch (e) {
            console.log("Error", e);
        }
    }

    async uploadImageKeyPrefix(base64, userid, id, dispatch, keyPrefix) {
        try {
            this.config.keyPrefix = keyPrefix;
            const s3 = new AWS.S3();
            const base64Data = new Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64');
            const type = base64.split(';')[0].split('/')[1];
            const params = {
                ACL: 'public-read',
                Body: base64Data,
                Bucket: this.config.bucket,
                ContentEncoding: 'base64',
                ContentType: `image/${type}`,
                Key: `${this.config.keyPrefix}${id}${userid}${new Date().getTime()}.jpeg`
            };
            const response = await s3.upload(params).on('httpUploadProgress', function (evt) {
                let number = parseInt(((evt.loaded * 100) / evt.total) - 3);
                dispatch({ type: SPINNER_PROGRESS, payload: number <= 0 ? 1 : number });
            }).promise();
            return response;
        } catch (e) {
            console.log("Error", e);
        }
    }

    async put(uri, file, keyPrefix) {
        try {
            this.config.keyPrefix = keyPrefix;
            const s3 = new AWS.S3();

            var buf = Buffer.from(uri.replace('data:image/jpeg;base64,', ''), 'base64');
            const params = {
                ACL: 'public-read',
                Body: buf,
                Bucket: this.config.bucket,
                ContentType: file.type,
                Key: `${this.config.keyPrefix}${file.name}`
            };
            const response = await s3.upload(params).promise();
            return response;
        } catch (e) {
            console.log("Error", e);
        }
    }

    async deleteFileS3(keyPrefix) {
        try {
            const s3 = new AWS.S3();
            const params = {
                Bucket: this.config.bucket,
                Key: `${keyPrefix}`
            };
            const response = await s3.deleteObject(params).promise();
            return response;
        } catch (e) {
            console.log("Error", e);
        }
    }

    async uploadFile(uri, file, keyPrefix, isBase64 = true) {
        try {
            this.config.keyPrefix = keyPrefix;
            const s3 = new AWS.S3();

            const body = isBase64 ? new Buffer.from(uri.replace(/^data:application\/\w+;base64,/, ""), 'base64') : uri;
            const params = {
                ACL: 'public-read',
                Body: body,
                Bucket: this.config.bucket,
                ContentType: file.type,
                Key: `${this.config.keyPrefix}${file.name}`
            };
            const response = await s3.upload(params).promise();
            return response;
        } catch (e) {
            console.log("Error", e);
        }
    }
}
