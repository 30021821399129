import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { general } from "../../general/global";
import { Container, Row, Col, Button } from "react-bootstrap";
import MainNavigation from "../Navigation/MainNavigation";
import Modal from "../commons/wrapped-components/Modal";
import { ReactComponent as CloseIcon } from "../../icons/closeIcon.svg";
import Table from "../commons/wrapped-components/Table";
import CheckInUser from "./CheckInUser";
import AssignSubspaces from "./AssignSubspaces";
import RemoveFromSpace from "./RemoveFromSpace";
import CheckInNewUser from "./CheckInNewUser";
import {
  unregisteredAccessAuthorization,
  getAccessAuthorizationActivesListByWorkSpaceIds,
  checkOut,
  getCheckoutTimeByWorkSpaceIds,
  getPaginatedUser,
  getUsersForCheckpoint,
  setCheckPoint,
  setCheckinByCheckpoint
} from "../../redux/actions/operationActions";
import * as userActions from "../../redux/actions/userActions";
import {
  getWorkspacesList,
  removeMemberMultipleSpaces,
} from "../../redux/actions/workspaceActions";
import { translate } from "../../redux/reducers/languageReducer";
import moment from "moment";
import Timer from "../commons/wrapped-components/inputs/Timer";
import * as roles from "../../constants/roles";
import InputText from "../commons/wrapped-components/inputs/InputText";
import { replaceDiacritics } from "../../general/general";
import ToggleMenu from "../Workspace/ToggleMenu";
import Avatar from "../commons/wrapped-components/Avatar";
import InputSearch from "../commons/wrapped-components/inputs/InputSearch";
import { showToast } from "../commons/Toast";
import * as toastType from "../../constants/toastTypes";
import DisableSafelyPassPopUp from "../commons/disableSafelyPassModal";
import EnableSafelyPassPopup from "../commons/enableSafelyPassModal";

const workSpacesMonitorBelongs = (userId, spaces) => {
  const monitorWorkspace = spaces
    .filter((workspace) => {
      const findWorkSpace = workspace.users.find((user) => user.id === userId);
      return !findWorkSpace
        ? false
        : findWorkSpace.roleId === roles.ADMIN ||
        findWorkSpace.roleId === roles.MONITOR;
    })
    .map((x) => ({
      _id: x._id,
      name: x.name,
      users: x.users.filter((y) => y.id !== userId), //Diferent users than logged
    }));

  return monitorWorkspace;
};

const getSubSpaceNameByWorkSpaceId = (workSpaces) => {
  const subSpaces = [];
  workSpaces.map((workspace) => {
    subSpaces.push({
      _id: workspace._id,
      subSpaces: workspace.defaultSubspaceId
        ? workspace.subspaces.filter((person) => {
          return person._id !== workspace.defaultSubspaceId;
        })
        : [],
    });
  });
  return subSpaces;
};

const getUsersList = (workSpaces, paginatedUsers, activesList, checkoutTime) => {
  let users = [];
  workSpaces.map((workSpace) => {
    const workSpaceId = workSpace._id;
    const workSpaceName = workSpace.name;
    workSpace.users.map((user) => {
      const exists = users.find((x) => x.id === user.id);
      const safelyPass = activesList.find(
        (x) => x.workSpaceId === workSpaceId && x.userId === user.id
      );

      const checkOutTime = checkoutTime.find(
        (x) => x.workSpaceId === workSpaceId && x.userId === user.id
      );
      if (safelyPass) {
        const { _id, monitorId, accessGranted, grantDate } = safelyPass;
        if (!exists) {
          users.push({
            ...user,
            workSpaceId,
            workSpaceName,
            safelyPassId: _id,
            monitorId,
            accessGranted,
            grantDate,
          });
        }
      } else if (checkOutTime) {
        if (!exists) {
          users.push({
            ...user,
            workSpaceId,
            workSpaceName,
            checkOutTime: checkOutTime && checkOutTime.checkoutDate,
          });
        } else {
          let _user = users.find((item) => item.id === user.id);
          _user.subspaces = [
            ..._user.subspaces.filter((item) => item !== null),
            ...user.subspaces.filter((item) => item !== null),
          ];
        }
      } else {
        if (!exists) {
          users.push({
            ...user,
            workSpaceId,
            workSpaceName,
            checkOutTime: "-",
          });
        }
      }
    });
  });

  const pUsers = [];
  for (let pUser of paginatedUsers) {
    const userToAdd = users.find(user => user.id === pUser.userId);
    if (userToAdd) pUsers.push(userToAdd);
  }
  return pUsers;
  /*
  return users.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );
  */
};

const CheckInRegister = (props) => {
  const [checkedOutUser, setCheckoutUser] = useState({});
  const [checkedInUser, setCheckinUser] = useState({});
  const [activeRowUser, setActiveRowUser] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleCheckOut, setVisibleModalCheckOut] = useState(false);
  const [modalVisibleCheckInNewUser, setVisibleModalCheckInNewUser] = useState(false);
  const [modalVisibleAssignSubspaces, setVisibleModalAssignSubspaces] = useState(false);
  const [modalVisibleRemoveFromSpace, setVisibleModalRemoveFromSpace] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [spacesToRemove, updateSpacesToRemove] = useState({});
  const [stepRemoveSpace, updateStepRemoveSpace] = useState(1);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [showEnableModal, setShowEnableModal] = useState(false);

  const activeKey = props.match.params.activeKey || 'members';

  // Pagination state
  const [paginationState, setPaginationState] = useState({
    startIndex: 0,
    endIndex: 10,
    page: 1,
    sizePerPage: 10,
  });

  useEffect(() => {
    loadData();
  }, [props.refreshCheckInRegisterScreen, activeKey]);

  const loadData = async (page=paginationState.page) => {
    const sizePerPage = paginationState.sizePerPage;

    await getUsers(page, sizePerPage, '');
    setPaginationState({
      page,
      sizePerPage,
      startIndex: ((page - 1) * sizePerPage),
      endIndex: page * sizePerPage
    });

    setSearchString('');

    await props.onGetAccessAuthorizationActivesListByWorkSpaceIds(
      props.workSpaces.map((x) => x._id)
    );
  };

  const { users } = props.checkpointUsers;

  const getUsers = async (page, sizePerPage, name) => {
    await props.onGetUsersForCheckpoint({ monitorId: props.userId, registered: activeKey === 'members' ? true : false, name: name, pageNo: page, limit: sizePerPage });
  }

  const checkInUser = async (userId, workSpaceId, monitorId, userProfile) => {
    setModalVisible(false);
    const selectedWorkspace = props.workspaces.find(workspace => workspace._id === workSpaceId);
    if (selectedWorkspace && selectedWorkspace.settings.verificationPoint) {
      props.onSetCheckPoint({ userId, workSpaceId, inputs: [], userProfile });
      props.onSetCheckinByCheckpoint(true);
      props.history.push(`/checkpoint`);
    } else {
      const { status } = await props.onUnRegisteredAccessAuthorization(
        userId,
        workSpaceId,
        monitorId
      );
      if (status) {
        await loadData(1);
      }
    }
  };

  const closeDisableModal = () => {
    setActiveRowUser({});
    setShowDisableModal(false);
  };

  const closeEnableModal = () => {
    setActiveRowUser({});
    setShowEnableModal(false);
  };

  const rowClasses = (row, rowIndex) => {
    if (row.disabledAllSpaces) {
      return 'userDisabledRow';
    }
  }


  const dropdownMenu = (user) => {
    const { userId, workspaceFocused } = props;
    const userRole = workspaceFocused.users.find(user => userId === user.id).roleName;

    if (props.type === 'visitors' && userRole === 'Monitor') return null;

    let dropDownList = [];
    switch (true) {
      case props.type === 'visitors' && userRole === 'Admin':
        dropDownList = [
          {
            name: user.disabledSpaces.length > 0 ? "EnableUserSafelyPass" : "DisableUserSafelyPass",
            onDropDownItemClick: () => {
              if (user.disabledSpaces.length > 0) {
                setActiveRowUser(user);
                setShowEnableModal(true);
              } else {
                setActiveRowUser(user);
                setShowDisableModal(true);
              }
            },
          },
          {
            name: "removeUser",
            onDropDownItemClick: () => {
              setActiveRowUser(user);
              setVisibleModalRemoveFromSpace(true);
            }
          }
        ];
        break;
      case userRole === 'Monitor':
      //case user.userId == workspaceFocused.spaceOwner: 
      //for space owner assignsubspace and disable enable feature is available hence adding seperate case
        dropDownList = [
          {
            name: "AssignSubspaces",
            onDropDownItemClick: () => {
              setActiveRowUser(user);
              setVisibleModalAssignSubspaces(true);
            }
          },
        ];
        break;
      case user.userId == workspaceFocused.spaceOwner:
        dropDownList = [
          {
            name: "AssignSubspaces",
            onDropDownItemClick: () => {
              setActiveRowUser(user);
              setVisibleModalAssignSubspaces(true);
            }
          },
          {
            name: user.disabledSpaces.length > 0 ? "EnableUserSafelyPass" : "DisableUserSafelyPass",
            onDropDownItemClick: () => {
              if (user.disabledSpaces.length > 0) {
                setActiveRowUser(user);
                setShowEnableModal(true);
              } else {
                setActiveRowUser(user);
                setShowDisableModal(true);
              }
            },
          }
        ];
        break;
      case userRole === 'Admin':
        dropDownList = [
          {
            name: "AssignSubspaces",
            onDropDownItemClick: () => {
              setActiveRowUser(user);
              setVisibleModalAssignSubspaces(true);
            }
          },
          {
            name: user.disabledSpaces.length > 0 ? "EnableUserSafelyPass" : "DisableUserSafelyPass",
            onDropDownItemClick: () => {
              if (user.disabledSpaces.length > 0) {
                setActiveRowUser(user);
                setShowEnableModal(true);
              } else {
                setActiveRowUser(user);
                setShowDisableModal(true);
              }
            },
          },
          {
            name: "removeUser",
            onDropDownItemClick: () => {
              setActiveRowUser(user);
              setVisibleModalRemoveFromSpace(true);
            }
          }
        ];
    }

    return (
      <div className={'checkpoint-threedots'}>
        <ToggleMenu
          onDropDownClick={() => { }}
          dropDownList={dropDownList}
        />
      </div>
    );
  };

  const checkOutUser = async (user) => {
    setCheckoutUser(user);
    setVisibleModalCheckOut(true);
  };

  const buildColumns = () => {
    const newColumns = [
      { dataField: "userId", text: "user_id", hidden: true },
      { dataField: "userName", text: props.dictionary.s("name").toUpperCase() },
      {
        dataField: "lastCheckinTime",
        text: props.dictionary.s("lastCheckinTime").toUpperCase(),
        searchable: false,
      },
      {
        dataField: "workspace",
        text: props.dictionary.s("space").toUpperCase(),
        searchable: false,
      },
      {
        dataField: "checkinCheckout",
        text: props.dictionary.s("checkinCheckout").toUpperCase(),
        headerAlign: (column, colIndex) => "right",
        searchable: false,
      },
    ];
    return newColumns;
  };

  const buildRows = (users) => {
    const rows = [];
    if (users === null) return rows;
    users.map((user) => {
      const attributes = {};
      let checkedInWorkSpace = {};
      if (user.checkedInWorkSpaceId !== null) {
        checkedInWorkSpace = user.workspaceBelong.find(workspace => workspace._id === user.checkedInWorkSpaceId);
      }

      rows.push({
        ...attributes,
        userId: user.userId,
        disabledAllSpaces: user.disabledAllSpaces,
        userName: (
          <>
            <Avatar
              src={user.picture}
              className="rounded-circle"
              styles={{ boxShadow: '0px 0px 0px 5px #fff', margin: '8px' }}
              width={32}
              height={32}
            />
            <span>{user.userFirstName + " " + user.userLastName}</span>
            {user.disabledAllSpaces && <i className="material-icons userDisabledIcon">block</i>}
          </>
        ),
        lastCheckinTime: user.lastCheckinTime ? (
          moment(user.lastCheckinTime).format(
            (props.lang === "en" ? "MMM DD YYYY" : "DD MMM YYYY") +
            ", hh:mm:ss a"
          )
        ) : (
            <span className="text-danger">
              {props.dictionary.s("notCheckedIn")}
            </span>
          ),
        workspace: user.lastCheckinTime
          ? user.checkedInWorkSpaceId && checkedInWorkSpace
            ? checkedInWorkSpace.name
            : "-"
          : "",
        checkinCheckout: user.grantDate ? (
          <div style={{ minWidth: 280 }} className="text-right">
            <Timer
              value={user.grantDate}
              contentStyle={{ marginBottom: 5 }}
              textStyle={{ fontSize: 13, color: "#E67070", marginRight: 10 }}
            />
            <Button
              onClick={() => checkOutUser(user)}
              className="btn-checkout-outline"
            >
              {props.dictionary.s("checkOut").toUpperCase()}
            </Button>
            {dropdownMenu(user)}
          </div>
        ) : (
            <div style={{ minWidth: 280 }} className="text-right">
              <Button
                onClick={() => {
                  setModalVisible(true);
                  setCheckinUser(user);
                }}
                disabled={user.disabledAllSpaces}
                className="btn-checkin-outline"
              >
                {props.dictionary.s("checkIn").toUpperCase()}
              </Button>
              {dropdownMenu(user)}
            </div>
          ),
      });
    });
    return rows;
  };

  const subSpaces = getSubSpaceNameByWorkSpaceId(props.workspaces);
  const columns = buildColumns();
  const data = buildRows(users);

  const handleSearchUsers = async (value) => {
    setSearchString(value);
    const page = 1;
    const sizePerPage = paginationState.sizePerPage;
    await getUsers(page, sizePerPage, value);
    setPaginationState({
      page,
      sizePerPage,
      startIndex: ((page - 1) * sizePerPage),
      endIndex: page * sizePerPage
    });
  }
  // New user button enviar boton a la derecha

  const renderSearch = () => {
    return (
      <Row>
        <Col md={12}>
          <div className='search-and-button-container'>
            {props.type === 'visitors' && (
              <Button
                className="check-in-new-user mr-2"
                style={{ backgroundColor: "#665EFF" }}
                onClick={() => {
                  props.resetUserMessage();
                  setVisibleModalCheckInNewUser(true);
                }}
              >
                {props.dictionary.s("checkInNewUser")}
              </Button>
            )}
            <div
              className="input-search-container"
            >
              <InputSearch
                placeholder={props.dictionary.s("search")}
                searchValue={searchString}
                onChangeText={setSearchString}
                handleSearch={handleSearchUsers}
                className='search-checkpoint'
              />
            </div>
          </div>
        </Col>
      </Row>
    );
  };


  const onTableChange = async (type, newState) => {
    const { page, sizePerPage } = newState;
    setPaginationState({
      page,
      sizePerPage,
      startIndex: ((page - 1) * sizePerPage),
      endIndex: page * sizePerPage
    });
    await getUsers(page, sizePerPage, searchString);
  }

  const saveDisabled = !(Object.keys(spacesToRemove).length && Object.keys(spacesToRemove).filter(spaceId => spacesToRemove[spaceId]).length);

  const removeFromSpaceContent = () => {

    switch (stepRemoveSpace) {
      case 1:
        return (
          <RemoveFromSpace
            setModalVisible={setVisibleModalRemoveFromSpace}
            user={activeRowUser}
            workspaces={props.workspaces}
            updateSpacesToRemove={updateSpacesToRemove}
          />
        );
        break;
      case 2:
        return (
          <>
            <h3>
              {props.dictionary.s("remove") + ' ' + activeRowUser.userFullName + '?'}
            </h3>
            {props.dictionary.s("youAreAboutToRemove")}
            <strong>{` '${activeRowUser.userFullName}' `}</strong>
            {props.dictionary.s("fromSelectedSpaces")}
            {' ' + props.dictionary.s("areYouSureWantToContinue")}
          </>
        );

        break;
      case 3:
        return (
          <>
            <h3>
              {props.dictionary.s("remove") + ' ' + activeRowUser.userFullName + '?'}
            </h3>
            <span>{` '${activeRowUser.userFullName}' `}</span>
            {props.dictionary.s("messageUserHasActivedPass")}.
            {props.dictionary.s("areYouSureWantToContinue")}
          </>
        );

        break;
    }
  };
  // const tableData = filteredData(searchString);

  return (
    <>
      <Container fluid>
        {renderSearch()}
        <Row>
          <Col
            style={{ marginTop: 30, marginBottom: 40 }}
            className="checkin-table"
          >
            <Table
              columns={columns}
              data={data}
              loading={props.loading}
              noDataIndication={props.loading ? props.dictionary.s("loading") : props.dictionary.s("noResultFound")}
              striped={false}
              hover={false}
              condesed={true}
              customPagination={true}
              dictionary={props.dictionary}
              sizePerPageList={[
                {
                  id: '10', text: '10', value: 10
                }, {
                  id: '20', text: '20', value: 20
                }, {
                  id: 'all', text: props.dictionary.s('All'), value: props.totalPaginatedUsers
                }
              ]}
              paginationOptions={{
                totalSize: props.totalPaginatedUsers,
                ...paginationState
              }}
              remote={{
                filter: false,
                pagination: true,
                sort: false,
                cellEdit: false
              }}
              onTableChange={onTableChange}
              rowClasses={rowClasses}
            />
          </Col>
        </Row>
      </Container>
      {/*checkout modal*/}
      <Modal
        showModal={modalVisibleCheckOut}
        dismissible={true}
        properties={{
          centered: true,
        }}
        textButtonSave={props.dictionary.s("close")}
        className="animated fadeInDown checkout-modal"
        closeButton
        body={
          <>
            <div>
              <button
                onClick={() => setVisibleModalCheckOut(false)}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <CloseIcon />
              </button>
              <h3>
                {props.dictionary.s("checkOutQuestion") +
                  " " +
                  checkedOutUser.userFirstName +
                  " " +
                  checkedOutUser.userLastName +
                  "?"}
              </h3>
              <div style={{ marginVertical: 20 }}>
                <p
                  style={{ fontSize: 18, color: "#334856", textAlign: "left" }}
                >
                  {props.dictionary.s("areYouSureYouNeedToCheckOutTheUser") +
                    checkedOutUser.userFirstName +
                    " " +
                    checkedOutUser.userLastName +
                    "?"}
                </p>
              </div>
            </div>
            <div className="btn-container">
              <Button
                theme="light"
                className="btn btn-outline-secondary"
                style={{
                  width: 120,
                  height: 44,
                }}
                onClick={() => setVisibleModalCheckOut(false)}
              >
                {props.dictionary.s("cancel")}
              </Button>
              <Button
                theme="light"
                className="btn-danger"
                style={{
                  width: 120,
                  height: 44,
                }}
                onClick={async () => {
                  setVisibleModalCheckOut(false);
                  const { _id: safelyPassId } = props.activesList.find(active => active.userId === checkedOutUser.userId && active.workSpaceId === checkedOutUser.checkedInWorkSpaceId);
                  const { status } = await props.onCheckOut(safelyPassId);
                  if (status) await loadData();
                }}
              >
                {props.dictionary.s("checkOut")}
              </Button>
            </div>
          </>
        }
      />
      {/*checkout modal*/}
      {/*checkin modal*/}
      <Modal
        showModal={modalVisible}
        onHide={() => setModalVisible(false)}
        dismissible={true}
        properties={{
          centered: true,
        }}
        textButtonSave={props.dictionary.s("close")}
        className="animated fadeInDown checkin-modal"
        closeButton
        body={
          checkedInUser?.notDisabledSpaces && (
            <CheckInUser
              lang={props.lang}
              setModalVisible={setModalVisible}
              monitorId={props.userId}
              checkInUser={checkInUser}
              workSpaces={checkedInUser?.notDisabledSpaces.map((x) => ({
                value: x._id,
                label: x.name,
              }))}
              user={checkedInUser}
              dictionary={props.dictionary}
            />
          )
        }
      />
      {/*checkin modal*/}
      {/*checkin new user modal*/}
      <Modal
        showModal={modalVisibleCheckInNewUser}
        onHide={() => setVisibleModalCheckInNewUser(false)}
        dismissible={true}
        properties={{
          centered: true,
        }}
        textButtonSave={props.dictionary.s("close")}
        className="animated fadeInDown checkin-modal"
        closeButton
        body={
          <CheckInNewUser
            lang={props.lang}
            setModalVisible={setVisibleModalCheckInNewUser}
            monitorId={props.userId}
            defaultWorkspace={props.workspaceFocused}
            workSpaces={props.workSpaces}
            checkInUser={checkInUser}
          />
        }
      />
      {/*checkin new user modal*/}
      {/*Assign subspaces modal*/}
      <Modal
        dismissible
        closeButton
        showModal={modalVisibleAssignSubspaces}
        onHide={() => setVisibleModalAssignSubspaces(false)}
        properties={{
          centered: true,
        }}
        textButtonSave={props.dictionary.s("close")}
        className="animated fadeInDown checkin-modal"
        body={
          <AssignSubspaces
            setModalVisible={setVisibleModalAssignSubspaces}
            user={activeRowUser}
            subSpaces={subSpaces}
            workSpaces={activeRowUser.workspaceBelong ? activeRowUser.workspaceBelong.map((x) => ({
              value: x._id,
              label: x.name
            })) : []}
            reloadData={loadData}
          />
        }
      />
      {/*Assign subspaces modal*/}
      {/*Remove from space modal*/}
      <Modal
        dismissible
        closeButton
        showModal={modalVisibleRemoveFromSpace}
        onHide={() => {
          updateSpacesToRemove({});
          updateStepRemoveSpace(1);
          setVisibleModalRemoveFromSpace(false);
        }}
        properties={{
          centered: true,
        }}
        className="animated fadeInDown checkin-modal"
        dialogClassName={stepRemoveSpace === 1 ? "big-modal" : ''}
        body={removeFromSpaceContent()}
        textButtonSave={
          props.dictionary.s("removebutton")
        }
        textButtonClose={props.dictionary.s("cancel")}
        propertyButtonSave={{
          block: false,
          disabled: saveDisabled,
          style: {
            backgroundColor: "#E67070",
            color: general.theme.whiteFontColor,
            border: `1px solid #E67070`,
          },
        }}
        bsStyleButtonSave="secondary"
        buttonCloseProperties={{
          block: false,
          style: {
            backgroundColor: general.theme.whiteColor,
            border: `1px solid ${general.theme.primaryColor}`,
            color: general.theme.primaryColor,
          },
        }}
        onClose={() => {
          updateSpacesToRemove({});
          updateStepRemoveSpace(1);
          setVisibleModalRemoveFromSpace(false);
        }}
        onSave={async () => {
          switch (stepRemoveSpace) {
            case 1: {
              updateStepRemoveSpace(2);
              break;
            }
            case 2: {
              const spaceIdsToRemove = Object.keys(spacesToRemove).filter(spaceId => spacesToRemove[spaceId]);
              const response = await props.onRemoveMemberMultipleSpaces(activeRowUser.userId, spaceIdsToRemove, false);
              if (response.data.failedRemoveUser.length) {
                const spacesFailedToRemove = {};
                response.data.failedRemoveUser.map(spaceFailed => spacesFailedToRemove[spaceFailed.workSpaceId] = true);
                updateSpacesToRemove(spacesFailedToRemove);
                updateStepRemoveSpace(3);
              } else {
                showToast(toastType.SUCCESS, props.dictionary.s("userDeleted"));
                updateSpacesToRemove({});
                updateStepRemoveSpace(1);
                setVisibleModalRemoveFromSpace(false);
                loadData();
              }
              break;
            }
            case 3: {
              const spaceIdsToRemove = Object.keys(spacesToRemove).filter(spaceId => spacesToRemove[spaceId]);
              const response = await props.onRemoveMemberMultipleSpaces(activeRowUser.userId, spaceIdsToRemove, true);
              showToast(toastType.SUCCESS, props.dictionary.s("userDeleted"));
              updateSpacesToRemove({});
              updateStepRemoveSpace(1);
              setVisibleModalRemoveFromSpace(false);
              loadData();
              break;
            }
          }
        }}
      />
      {/*Remove from space modal*/}
      {/* Disable user's safely pass modal */}
      <DisableSafelyPassPopUp
        showDisableModal={showDisableModal}
        closeDisableModal={closeDisableModal}
        userData={activeRowUser}
        load={loadData}
      />
      {/* Disable user's safely pass modal */}
      {/* Enable user's safely pass modal */}
      <EnableSafelyPassPopup
        userData={activeRowUser}
        showEnableModal={showEnableModal}
        closeEnableModal={closeEnableModal}
        load={loadData}
      />
      {/* Enable user's safely pass modal */}
    </>

  );
};

const mapStateToProps = (state) => {
  const activesList = state.operation.activesList;
  const workSpaceId = state.workspace.workspaceFocused._id;
  const checkPoint = state.workspace.workspaceFocused.checkPointChecklist[0];
  const userId = state.user.id;
  const checkoutTime = state.operation.checkoutTime;
  const workSpaces = workSpacesMonitorBelongs(userId, state.workspace.list);
  const paginatedUsers = state.operation.paginatedUsers;
  const totalPaginatedUsers = state.operation.totalUsers;
  const loading = state.operation.loading;
  let users = null;
  if (activesList !== undefined && paginatedUsers.length && !loading) {
    users = getUsersList(workSpaces, paginatedUsers, activesList, checkoutTime);
  }

  return {
    workSpaceId,
    userId,
    lang: state.language.lang,
    users,
    totalPaginatedUsers,
    loading,
    accesstokenidp: state.user.accesstokenidp,
    workSpaces,
    workspaces: state.workspace.list,
    activesList,
    workspaceFocused: state.workspace.workspaceFocused,
    checkoutTime: state.operation.checkoutTime,
    checkPointInputs: checkPoint.inputs,
    workSpacesLoading: state.workspace.workSpacesLoading,
    message: state.operation.message,
    dictionary: {
      s: (key) => translate(state.language.dictionary, key),
    },
    refreshCheckInRegisterScreen: state.operation.refreshCheckInRegisterScreen,
    checkpointUsers: state.operation.checkpointUsers,
    activeKey: state.operation.activeKey,
    spaceFocused: state.workspace.spaceFocused,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUnRegisteredAccessAuthorization: (userId, workSpaceId, monitorId) =>
    dispatch(unregisteredAccessAuthorization(userId, workSpaceId, monitorId)),
  onGetAccessAuthorizationActivesListByWorkSpaceIds: (workSpacesIds) =>
    dispatch(getAccessAuthorizationActivesListByWorkSpaceIds(workSpacesIds)),
  onCheckOut: (id) => dispatch(checkOut(id)),
  onGetCheckoutTimeByWorkSpaceIds: (workSpacesIds) =>
    dispatch(getCheckoutTimeByWorkSpaceIds(workSpacesIds)),
  resetUserMessage: () => dispatch(userActions.setMessage(null)),
  onGetPaginatedUser: (body, page, limit, accesstokenidp) => dispatch(getPaginatedUser(body, page, limit, accesstokenidp)),
  onGetUsersForCheckpoint: (body) => dispatch(getUsersForCheckpoint(body)),
  onSetCheckPoint: checkPoint => dispatch(setCheckPoint(checkPoint)),
  onRemoveMemberMultipleSpaces: (userId, workSpaceIds, checkout) => dispatch(removeMemberMultipleSpaces(userId, workSpaceIds, checkout)),
  onSetCheckinByCheckpoint: (value) => dispatch(setCheckinByCheckpoint(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckInRegister);
