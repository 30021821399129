import {
    SET_CURRENT_TRANSACTION,
    TRANSACTIONS_START_LOADING,
    TRANSACTIONS_END_LOADING,
    TRANSACTIONS_FAIL,
    CREATE_TRANSACTION_SUCCESS,
    GET_TRANSACTIONS_SUCCESS,
    SECONDARY_LOAD_START,
    SECONDARY_LOAD_END,
    PDF_LOCATION,
    ASSIGN_TRANSACTION,
    ASSIGN_TRANSACTION_SUCCESS,
    ASSIGN_TRANSACTION_FAIL,
    DELETE_TRANSACTION,
    DELETE_TRANSACTION_SUCCESS,
    DELETE_TRANSACTION_FAIL,
    EXECUTE_TRANSACTION_SUCCESS,
    GET_TRANSACTION,
    GET_TRANSACTION_SUCCESS,
    GET_TRANSACTION_FAIL,
} from "./types";
import AmazonStorage from "../../amazonStorage";
import { safelyApi } from "../../api/safely";
import ApiEndPoint from "../../constants/apiEndPoint";

export const getTransactions = (body, save = true) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: save ? TRANSACTIONS_START_LOADING : SECONDARY_LOAD_START, payload: {} });
            const { accesstokenidp } = getState().user;
            const response = await safelyApi(ApiEndPoint.getTransactions, {
                method: 'post',
                body,
                headers: { accesstokenidp: accesstokenidp }
            });

            if (response.status) {
                dispatch({ type: save ? GET_TRANSACTIONS_SUCCESS : SECONDARY_LOAD_END, payload: response });
            } else {
                dispatch({ type: TRANSACTIONS_FAIL, payload: { message: response.error_description || response.systemError, ...response } });
                if (!save) dispatch({ type: SECONDARY_LOAD_END });
            }
            return response;
        } catch (e) {
            dispatch({ type: TRANSACTIONS_FAIL, payload: { message: e.message } });
            if (!save) dispatch({ type: SECONDARY_LOAD_END });
            return { e, status: false };
        }
    }
}

export const renameTransaction = (body) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: SECONDARY_LOAD_START, payload: {} });
            const { accesstokenidp } = getState().user;
            const response = await safelyApi(ApiEndPoint.renameTransaction, {
                method: 'put',
                body,
                headers: { accesstokenidp: accesstokenidp }
            });
            if (!response.status)
                dispatch({ type: TRANSACTIONS_FAIL, payload: { message: response.error_description || response.systemError, ...response } })
            dispatch({ type: SECONDARY_LOAD_END, payload: response });

            return response;
        } catch (e) {
            dispatch({ type: TRANSACTIONS_FAIL, payload: { message: e.message } });
            return { e, status: false };
        }
    }
}

export const exists = (workspaceId, transactionName) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: SECONDARY_LOAD_START, payload: {} });
            const { accesstokenidp } = getState().user;
            ///:workspaceId/:transactionName
            const response = await safelyApi(`${ApiEndPoint.transactionExists}/${workspaceId}/${transactionName}`, {
                method: 'get',
                headers: { accesstokenidp: accesstokenidp }
            });
            if (!response.status)
                dispatch({ type: TRANSACTIONS_FAIL, payload: { message: response.error_description || response.systemError, ...response } })
            else
                dispatch({ type: SECONDARY_LOAD_END, payload: response });

            return response;
        } catch (e) {
            dispatch({ type: TRANSACTIONS_FAIL, payload: { message: e.message } })
            return { e, status: false };
        }
    }
}

export const createTransaction = (body) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: TRANSACTIONS_START_LOADING, payload: {} });
            const { accesstokenidp } = getState().user;
            const response = await safelyApi(ApiEndPoint.createTransaction, {
                method: 'post',
                body,
                headers: { accesstokenidp: accesstokenidp }
            });

            if (response.status)
                dispatch({ type: CREATE_TRANSACTION_SUCCESS, payload: response });
            else
                dispatch({ type: TRANSACTIONS_FAIL, payload: { message: response.error_description || response.systemError, ...response } });
            return response;
        } catch (e) {
            dispatch({ type: TRANSACTIONS_FAIL, payload: { message: e.message } })
        }
    }
}


export const editTransaction = (body) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: TRANSACTIONS_START_LOADING, payload: {} });
            const { accesstokenidp } = getState().user;
            const response = await safelyApi(ApiEndPoint.createTransaction, {
                method: 'put',
                body,
                headers: { accesstokenidp: accesstokenidp }
            });

            if (response.status)
                dispatch({ type: CREATE_TRANSACTION_SUCCESS, payload: response });
            else
                dispatch({ type: TRANSACTIONS_FAIL, payload: { message: response.error_description || response.systemError, ...response } });
            return response;
        } catch (e) {
            dispatch({ type: TRANSACTIONS_FAIL, payload: { message: e.message } })
        }
    }
}

export const executeTransaction = (body) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: TRANSACTIONS_START_LOADING });
            const { accesstokenidp } = getState().user;
            const response = await safelyApi(ApiEndPoint.executeTransaction, {
                method: 'post',
                body,
                headers: { accesstokenidp: accesstokenidp }
            });

            if (response.status)
                dispatch({ type: EXECUTE_TRANSACTION_SUCCESS, payload: response });
            else
                dispatch({ type: TRANSACTIONS_FAIL, payload: { message: response.error_description || response.systemError, ...response } });
            return response;
        } catch (e) {
            dispatch({ type: TRANSACTIONS_FAIL, payload: { message: e.message } })
        }
    }
}

export const assignTransaction = (accesstokenidp, body) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ASSIGN_TRANSACTION, payload: {} });
            const response = await safelyApi(ApiEndPoint.assignTransaction, {
                method: 'PUT',
                body,
                headers: { accesstokenidp: accesstokenidp }

            });

            if (response.status) {
                dispatch({ type: ASSIGN_TRANSACTION_SUCCESS, payload: response });
            } else {
                dispatch({
                    type: ASSIGN_TRANSACTION_FAIL,
                    payload: {
                        message: response.error_description || response.systemError,
                        ...response
                    }
                })
            }
            return response;
        } catch (e) {
            dispatch({ type: ASSIGN_TRANSACTION_FAIL, payload: { message: e.message } })
            return { e, status: false };
        }
    }
}

export const PDFLocation = (payload) => {
    return async (dispatch) => {
        dispatch({ type: PDF_LOCATION, payload });
    }
}

export const setCurrentTransaction = (payload) => {
    return async (dispatch) => {
        dispatch({ type: SET_CURRENT_TRANSACTION, payload });
        return;
    }
}

export const deleteTransaction = (transactionId, filePath) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: DELETE_TRANSACTION, payload: {} });
            const { accesstokenidp } = getState().user;
            const response = await safelyApi(ApiEndPoint.deleteTransaction + "/" + transactionId, {
                method: 'DELETE',
                headers: { accesstokenidp: accesstokenidp }
            });

            if (response.status) {
                dispatch({ type: DELETE_TRANSACTION_SUCCESS, payload: response });
                await new AmazonStorage().deleteFileS3('transactions/' + filePath[4] + '/transactionForms/' + filePath[6]);
            } else {
                dispatch({
                    type: DELETE_TRANSACTION_FAIL,
                    payload: {
                        message: response.error_description || response.systemError,
                        ...response
                    }
                })
            }
            return response;
        } catch (e) {
            dispatch({ type: DELETE_TRANSACTION_FAIL, payload: { message: e.message } })
            return { e, status: false };
        }

    }
}

export const getTransaction = (transactionId) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: GET_TRANSACTION, payload: {} });
            const { accesstokenidp } = getState().user;
            const response = await safelyApi(ApiEndPoint.getTransaction + "/" + transactionId, {
                method: 'GET',
                headers: { accesstokenidp: accesstokenidp }
            });

            if (response.status) {
                dispatch({ type: GET_TRANSACTION_SUCCESS, payload: response.data });
            } else {
                dispatch({
                    type: GET_TRANSACTION_FAIL,
                    payload: {
                        message: response.error_description || response.systemError,
                        ...response
                    }
                })
            }
            return response;
        } catch (e) {
            dispatch({ type: GET_TRANSACTION_FAIL, payload: { message: e.message } })
            return { e, status: false };
        }
    }
}
