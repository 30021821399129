import React from "react";
import InputText from "./wrapped-components/inputs/InputText";
import InputNumber from "./wrapped-components/inputs/inputNumber";
import InputBoolean from "./wrapped-components/inputs/inputBoolean";
import InputButtonGroup from "./wrapped-components/inputs/InputButtonGroup";
import InputRange from "./wrapped-components/inputs/InputRange";
import MultipleChoice from './wrapped-components/inputs/MultipleChoice';
import Label from "./styled-components/general/Label";
import DropDownList from "./wrapped-components/inputs/DropDownList";
import * as inputTypes from "../../constants/inputTypes";
import { ListGroup } from "react-bootstrap";

const TimeLine = (props) => {
  const getInputByType = ({ _id, label, value, required, type, enabled, ...rest }) => {

    switch (type) {
      case inputTypes.TEXT:
        return (
          <InputText
            id={"text_" + _id}
            label={label}
            required={required}
            value={value}
            onChangeText={(value) => props.onValueChange(_id, value)}
            editable={props.editable.toString()}
            dictionary={props.dictionary}
            returnkeytype="next"
            dictionary={props.dictionary}
          />
        );
      case inputTypes.NUMBER:
        return (
          <InputNumber
            id={"number_" + _id}
            label={label}
            required={required}
            value={value}
            onChangeText={(value) => props.onValueChange(_id, value)}
            editable={props.editable.toString()}
            dictionary={props.dictionary}
            returnkeytype="next"
            dictionary={props.dictionary}
          />
        );
      case inputTypes.BOOLEAN:
        return (
          <InputBoolean
            id={"boolean_" + _id}
            label={label}
            required={required}
            value={value}
            onValueChange={(value) => props.onValueChange(_id, value)}
            disabled={!props.editable}
            dictionary={props.dictionary}
          />
        )
      case inputTypes.LABEL:
        return <Label id={"label_" + _id}>{label}</Label>
      case inputTypes.BUTTONGROUP:
        return (
          <InputButtonGroup
            id={_id}
            label={label}
            value={value}
            showInput={true}
            required={required}
            buttonList={rest.content.buttonList}
            dictionary={props.dictionary}
            onValueChange={props.onValueChange}
          />
        );
      case inputTypes.RANGE:
        return (
          <InputRange
            id={_id}
            label={label}
            value={value}
            showInput={true}
            required={required}
            editable={props.editable.toString()}
            dictionary={props.dictionary}
            onValueChange={(value) => props.onValueChange(_id, value)}
          />
        );
      case inputTypes.MULTIPLE:
        return (
          <MultipleChoice
            id={_id}
            label={label}
            value={value}
            options={rest.content.options}
            onValueChange={props.onValueChange}
            required={required}
            requiredText={props.dictionary.s('required')}
          />
        );
      case inputTypes.DROPDOWN:
        return (
          <DropDownList
            id={_id}
            label={label}
            value={value}
            options={rest.content.options}
            onValueChange={props.onValueChange}
            required={required}
            requiredText={props.dictionary.s('required')}
            isExecution={true}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ListGroup className="timeLine">
      {props.items.map((item, index) => (
        <ListGroup.Item key={"input_" + item._id} style={{ marginVertical: 6 }}>
          {getInputByType(item)}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

TimeLine.defaultProps = {
  items: [],
  onValueChange: () => { },
  ListHeaderComponent: null,
  ListFooterComponent: null,
  editable: true,
  refreshing: false,
  styles: {},
};
export default TimeLine;