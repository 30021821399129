import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MultiSelect from "react-multi-select-component";
import InputSelect from "../commons/wrapped-components/inputs/InputSelect";
import { Button } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "../../icons/closeIcon.svg";
import { translate } from "../../redux/reducers/languageReducer";
import { addUserTomulitSubSpace, removeUserFromMulitSubSpaces } from "../../redux/actions/operationActions";
import { getSpacesAndUserSubSpaces } from "../../redux/actions/workspaceActions";

const AssignSubspaces = (props) => {
  const { userId } = props.user;

  const setOptions = (optionItems) => {
    const options = [];
    optionItems.map((option) => {
      if (!option.name.includes('(Default)')) options.push({ label: option.name, value: option._id });
    });
    return options;
  };

  const setSelected = (subSpacesList) => {
    const selected = [];
    subSpacesList.map((option) => {
      if (!option.name.includes('(Default)')) selected.push({ label: option.name, value: option._id });
    });
    return selected;
  };

  const [workSpaceId, setWorkSpaceId] = useState(props.user.checkedInWorkSpaceId);
  const [subspaceOptions, setSubspaceOptions] = useState([]);
  const [selectedSubSpace, setSelectedSubSpace] = useState([]);
  const [originalSelectedSubSpace, setOriginalSelectedSubSpace] = useState([]);

  const fetchUserSubspaces = async () => {
    const object = {
      workSpaceId,
      userId,
    };
    const response = await props.onGetSpacesAndUserSubSpaces(object);
    setSubspaceOptions(setOptions(response.data.spaceSubspaces));
    setSelectedSubSpace(setSelected(response.data.userSubspaces));
    setOriginalSelectedSubSpace(setSelected(response.data.userSubspaces));
  };

  useEffect(() => {
    fetchUserSubspaces();
  }, [workSpaceId])

  return (
    <>
      <button
        onClick={() => props.setModalVisible(false)}
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <CloseIcon />
      </button>
      <h3>
        {props.dictionary.s("AssignSubspaces")}
      </h3>
      <div
        className="row justify-content-center align-items-center"
        style={{ flexDirection: "column" }}
      >
        <div className="checkin-input-container">
          <InputSelect
            value={props.workSpaces.find(x => x.value === workSpaceId)}
            searchable={false}
            options={props.workSpaces}
            onChange={(space) => {
              if (!space) return;
              // props.onChangeWorkSpaceFocused(workspace)
              setWorkSpaceId(space.value);
            }}
            valueKey="value"
            labelKey="label"
            clearable={false}
            disabled={false}
            label={props.dictionary.s('space')}
          />
          <label>{props.dictionary.s('subspaces')}</label>
          <MultiSelect
            className="custome-multiselect"
            options={subspaceOptions}
            value={selectedSubSpace}
            overrideStrings={{ allItemsAreSelected: "All Sub Spaces are selected", search: "Search Subspaces" }}
            hasSelectAll={false}
            onChange={(subSpacesSelected) => setSelectedSubSpace(subSpacesSelected)}
            labelledBy={"Select"}
            label={props.dictionary.s('space')}
          />
          <div style={{ marginBottom: 10 }}>
            {selectedSubSpace.map(subspace => (
              <div key={subspace.value} className={'subspace-oval'}>
                {subspace.label}
              </div>
            ))}
          </div>
          <Button
            className="popup-checkin"
            onClick={async () => {
              props.setModalVisible(false);
              const removedSubspaces = originalSelectedSubSpace.filter(originalSub => !(selectedSubSpace.some(selectedSub => originalSub.value === selectedSub.value)));
              const addedSubspaces = selectedSubSpace.filter(selectedSub => !(originalSelectedSubSpace.some(originalSub => originalSub.value === selectedSub.value)));
              if (addedSubspaces.length) {
                const arraySubSpacesId = addedSubspaces.map(i => i.value);
                await props.onaddUserTomulitSubSpace(userId, workSpaceId, arraySubSpacesId);
              }

              if (removedSubspaces.length) {
                const arraySubSpacesId = removedSubspaces.map(i => i.value);
                await props.onRemoveUserFromMulitSubSpaces(userId, workSpaceId, arraySubSpacesId);
              }
              props.reloadData();
            }}
          >
            {props.dictionary.s("AssignSubspaces")}
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const user = state.user;
  return {
    dictionary: {
      s: (key) => translate(state.language.dictionary, key),
    },
    userId: user.id,
    workspaceFocused: state.workspace.workspaceFocused,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onaddUserTomulitSubSpace: (userId, workSpaceId, subSpaceId) => dispatch(addUserTomulitSubSpace(userId, workSpaceId, subSpaceId)),
  onRemoveUserFromMulitSubSpaces: (userId, workSpaceId, subSpaceId) => dispatch(removeUserFromMulitSubSpaces(userId, workSpaceId, subSpaceId)),
  onGetSpacesAndUserSubSpaces: (data) => dispatch(getSpacesAndUserSubSpaces(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignSubspaces);