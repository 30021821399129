import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/lab/Slider";
import { Row, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import getCroppedImg from "./getCroppedImg";
import { ReactComponent as ZoomOut } from "../../../../icons/image-zoom-out.svg";
import { ReactComponent as ZoomIn } from "../../../../icons/image-zoom-in.svg";
import { styles } from "../../../../styles/imageCroperStyles";

const ImageCropper = ({ classes, image, onSave, onCancel, textButtonSave, textButtonCancel }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, getcroppedAreaPixels) => {
    setCroppedAreaPixels(getcroppedAreaPixels);
  }, []);

  const onClose = useCallback(() => {
    onCancel()
  }, []);

  const getCroppedImage = useCallback(async () => {
    try {
      const getcroppedImagedata = await getCroppedImg(
        image.path,
        croppedAreaPixels,
        rotation
      );
      onSave(getcroppedImagedata);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  return (
    <div>
      <div className={classes.cropContainer}>
        <Cropper
          image={image.path}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={4 / 3}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className={`row ${classes.controls}`}>
        <div
          className={`col-md-4 xs-12 sm-12 ${classes.sliderContainer} lg-4 xl-4 offset-md-4 offset-lg-4 offset-xl-4`}
        >
          <ZoomOut />
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            classes={{ container: classes.slider }}
            onChange={(e, getzoom) => setZoom(getzoom)}
          />
          <ZoomIn />
        </div>
      </div>
      <Row className="text-right cropped-image-btn-container">
        <Col xs={12} sm={12} lg={12} md={12} xl={12}>
        <Button
        onClick={onClose}
        variant="outlined" color="primary"
        classes={{ root: classes.cropButton }}
      >
        {textButtonCancel}
      </Button>
          <Button
            onClick={getCroppedImage}
            variant="contained"
            color="primary"
            classes={{ root: classes.cropButton }}
          >
            {textButtonSave}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default withStyles(styles)(ImageCropper);
