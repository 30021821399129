import React from 'react';

const Checkbox = ({ value, disabled, onChange, className, iconClassName }) => (
    <div className={className}>
        <label>
            <input
                type='checkbox'
                {...value ? { checked: value } : {}}
                onChange={onChange}
                disabled={disabled}
            />
            <span className='cr'>
                <i className={`cr-icon ${iconClassName}`} aria-hidden='true' />
            </span>
        </label>
    </div>
);

Checkbox.defaultProps = {
    value: false,
    onChange: () => { },
    className: 'checkbox',
    iconClassName: 'fa fa-check'
}

export default Checkbox;