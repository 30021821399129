import React from "react";
import { connect } from "react-redux";
import { general } from "../../general/global";
import { translate } from "../../redux/reducers/languageReducer";
import Modal from "../commons/wrapped-components/Modal";
import { ToastSuccess, ToastError } from "../../redux/actions/toastAction";
import { enableUserSafelyPass } from "../../redux/actions/disableOrEnableUserSafelyPassAction";

const EnableSafelyPassPopUp = (props) => {

    const displayModalContent = (data) => {
        if (data) {
            const replaceChar = (origString, replaceChar, index) => {
                let firstPart = origString.substr(0, index);
                let lastPart = origString.substr(index + 1);
                let newString = firstPart + replaceChar + lastPart;
                return newString;
            }
            let spacesList = '';
            if (data.disabledSpaces && data.disabledSpaces.length > 0) {
              if (data.disabledSpaces.length === 1) {
                spacesList = data.disabledSpaces[0].name;
              } else if (data.disabledSpaces.length === 2) {
                spacesList = data.disabledSpaces[0].name+' & '+data.disabledSpaces[1].name;
              } else {
                let spaceNames = data.disabledSpaces.map((space) => space.name);
                // spaceNames = ['aefebb', "efefsf", 'ervvebverv', "efefsf", 'ervvebverv'];
                spacesList = spaceNames.toString().split(',').join(', '); 
                spacesList = replaceChar(spacesList, ' &', spacesList.lastIndexOf(', '));
              }
            }
    
            if (props.lang === 'en') {
              return (
                <>
                  <span>{`${data.userFullName}'s `}</span>
                  {data.disabledSpaces && (data.disabledSpaces.length === data.workspaceBelong.length) ? 
                      props.dictionary.s("safelyPassIsDisabledForAllTheSpaces") : 
                      (data.disabledSpaces && data.disabledSpaces.length === 1 ?
                        props.dictionary.s("safelyPassIsDisabledForTheSpace") :
                        props.dictionary.s("safelyPassIsDisabledForTheSpaces")
                      ) + " - "
                  }
                  {data.disabledSpaces && (data.disabledSpaces.length === data.workspaceBelong.length) ? null :
                    spacesList.length > 0 ? <span>{spacesList}</span> : null
                  }
                  {`. ${props.dictionary.s("areyousureyouwanttoenabletheuser")}`}
                </>
              );
            } else {
              return (
                <>
                  {props.dictionary.s("enableUserSafelyPassSuffix")}
                  <span>{` ${data.userFullName} `}</span>
                  {data.disabledSpaces && (data.disabledSpaces.length === data.workspaceBelong.length) ? 
                      props.dictionary.s("safelyPassIsDisabledForAllTheSpaces") : 
                      (data.disabledSpaces && data.disabledSpaces.length === 1 ?
                        props.dictionary.s("safelyPassIsDisabledForTheSpace") :
                        props.dictionary.s("safelyPassIsDisabledForTheSpaces")
                      ) + " - "
                  }
                  {data.disabledSpaces && (data.disabledSpaces.length === data.workspaceBelong.length) ? null :
                    spacesList.length > 0 ? <span>{spacesList}</span> : null
                  }
                  {`. ${props.dictionary.s("areyousureyouwanttoenabletheuser")}`}
                </>
              );
            }
        }
    };

  return (
    <Modal
      title={props.lang === "en" ? 
        <>
        {props.dictionary.s("Enable")} {props.userData && (props.userData.userFullName)}'s{" "}
        {props.dictionary.s("safelyPass")}?
        </> : <>{props.dictionary.s("EnableSafelyPassOfUserName")}{" "}{props.userData && (props.userData.userFullName)}?</>
        }
      dialogClassName="safely-modal renderModalCreatSubSpace checkpoint-modal"
      showModal={props.showEnableModal}
      onHide={() => {
        props.closeEnableModal();
      }}
      onSave={async () => {
        if(props.userId && props.userData ) {
          const workspaceId = props.userData.disabledSpaces.map(i => i._id);
          if(workspaceId) {
            const response = await props.onEnableUserSafelyPass(props.userData.userId, props.userId, workspaceId);
            if(response.status) {
                props.onToastSuccess(props.dictionary.s("EnabledUserSafelyPassSuccessfully"));
            } else {
              props.onToastError(props.dictionary.s("errorEncounteredPleaseTryAfterSometime"));
            }
            props.load();
            props.closeEnableModal();
          }
        } else {
          props.onToastError(props.dictionary.s("errorEncounteredPleaseTryAfterSometime"));
        }
      }}
      onClose={() => {
        props.closeEnableModal();
      }}
      textButtonSave={props.dictionary.s("Enable")}
      textButtonClose={props.dictionary.s("cancel")}
      propertyButtonSave={{
        block: false,
        style: {
          backgroundColor: "#665DFF",
          color: general.theme.whiteFontColor,
          border: `1px solid #665DFF`,
        },
      }}
      bsStyleButtonSave="primary"
      buttonCloseProperties={{
        block: false,
        style: {
          backgroundColor: general.theme.whiteColor,
          border: `1px solid ${general.theme.primaryColor}`,
          color: general.theme.primaryColor,
        },
      }}
      properties={{
        centered: true,
      }}
      body={<div className="removeText">{props.userData && displayModalContent(props.userData)}</div>}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.language.lang,
    userId: state.user.id,
    dictionary: {
      s: (key) => translate(state.language.dictionary, key),
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  onEnableUserSafelyPass: (userId, enabledById, workspaces) =>
    dispatch(enableUserSafelyPass(userId, enabledById, workspaces)),
  onToastSuccess: (message) => dispatch(ToastSuccess(message)),
  onToastError: (message) => dispatch(ToastError(message)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EnableSafelyPassPopUp);
