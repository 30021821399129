import {
  CHANGED_NAME,
  CHANGED_LAST_NAME,
  CHANGED_WORKSPACE,
  CHANGED_MEMBER,
  ADD_MEMBER_TO_WORKSPACE,
  CHANGED_LOCATION_SERVICE_WHILE_USING,
  // CHANGED_LOCATION_SERVICE_REAL_TIME,
  CHANGED_EMAIL_NUMBER_ONBOARDING,
  CHANGED_PASSWORD_ONBOARDING,
  ONBOARDING_RESET,
  CHANGED_MAGIC_LINK_CODE_ONBOARDING,
  GET_COUNTRY_CODE_LIST_REQUEST,
  GET_COUNTRY_CODE_LIST_REQUEST_SUCCESS,
  GET_COUNTRY_CODE_LIST_REQUEST_FAIL,
  CHANGED_COUNTRYCODE
} from "../actions/types";

export const initialState = {
  emailNumber: "",
  name: "",
  lastName: "",
  password: "",
  magicLinkCode: null,
  workspacename: "",
  members: [
    {
      index: 0,
      member: ""
    },
    {
      index: 1,
      member: ""
    },
    {
      index: 2,
      member: ""
    }
  ],
  locationServiceWhileUsing: false,
  locationServiceRealTime: false,
  loading: false,
  countryCode: "+52",
  phoneCodeList: {}
};

export default (state, action) => {
  const { type, payload } = action;
  if (typeof state === "undefined") {
    return initialState;
  }
  switch (type) {
    case CHANGED_EMAIL_NUMBER_ONBOARDING:
      return { ...state, emailNumber: payload };
    case CHANGED_NAME:
      return { ...state, name: payload };
    case CHANGED_LAST_NAME:
      return { ...state, lastName: payload };
    case CHANGED_PASSWORD_ONBOARDING:
      return { ...state, password: payload };
    case CHANGED_WORKSPACE:
      return { ...state, workspacename: payload };
    case CHANGED_MEMBER:
      const members = [...state.members];
      const findMember = members.find(x => x.index === payload.index);
      findMember.member = payload.member;
      return { ...state, members: members };
    case ADD_MEMBER_TO_WORKSPACE:
      return {
        ...state,
        members: [
          ...state.members,
          { index: state.members.length, member: payload }
        ]
      };
    case CHANGED_LOCATION_SERVICE_WHILE_USING:
      return { ...state, locationServiceWhileUsing: payload };
    // case CHANGED_LOCATION_SERVICE_REAL_TIME:
    //   return { ...state, locationServiceRealTime: payload };
    case CHANGED_MAGIC_LINK_CODE_ONBOARDING:
      return { ...state, magicLinkCode: payload };
    case ONBOARDING_RESET:
      return { ...state, ...initialState };
    case CHANGED_COUNTRYCODE:
      return { ...state, countryCode: payload };
    case GET_COUNTRY_CODE_LIST_REQUEST:
      return { ...state, loading: true };
    case GET_COUNTRY_CODE_LIST_REQUEST_SUCCESS:
      return { ...state, loading: false, phoneCodeList: payload.data };
    case GET_COUNTRY_CODE_LIST_REQUEST_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
};
