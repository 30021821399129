import {
  USER_POST,
  USER_POST_SUCCESS,
  USER_POST_ERROR,
  USER_SOCIAL_POST,
  USER_SOCIAL_POST_SUCCESS,
  USER_SOCIAL_POST_ERROR,
  USER_CONTACT_UPDATE,
  USER_CONTACT_UPDATE_SUCCESS,
  USER_CONTACT_UPDATE_FAIL,
  EDIT_USER,
  USER_DETAILS_POST,
  USER_DETAILS_POST_SUCCESS,
  USER_DETAILS_POST_ERROR,
  SET_USER,
  SET_MESSAGE,
  USER_UPDATE_ROLE,
  USER_UPDATE_ROLE_SUCESS,
  USER_UPDATE_ROLE_FAIL,
  USER_GET_PROFILE_BY_ID,
  USER_GET_PROFILE_BY_ID_SUCCESS,
  USER_GET_PROFILE_BY_ID_FAIL,
  USER_RESET,
  USER_UPDATE_PROFILE_PHOTO,
  USER_UPDATE_PROFILE_PHOTO_FAIL,
  USER_UPDATE_PROFILE_PHOTO_SUCCESS,
  AUTHORIZATION_SET_USER_DATA,
  USER_UPDATE_USERNAME,
  USER_UPDATE_USERNAME_SUCCESS,
  USER_UPDATE_USERNAME_FAILURE,
  SEND_VERIFY_PIN_SUCCESS,
  SEND_VERIFY_PIN_ERROR,
  VERIFY_PIN_SUCCESS,
  VERIFY_PIN_ERROR,
  USER_UPDATE_SUBSPACES,
  USER_UPDATE_SUBSPACES_FAILURE,
  CHECK_IN_NEW_USER,
  CHECK_IN_NEW_USER_SUCCESS,
  CHECK_IN_NEW_USER_FAILURE,
  USER_UPDATE_BACKGROUND_PROFILE_PHOTO,
  USER_UPDATE_BACKGROUND_PROFILE_PHOTO_SUCCESS,
  USER_UPDATE_BACKGROUND_PROFILE_PHOTO_FAIL,
  CLICKED_USER,
} from "./types";

import { safelyApi } from "../../api/safely";
import AmazonStorage from "../../amazonStorage";
import ApiEndPoint from "../../constants/apiEndPoint";

export const createUser = (
  emailNumber,
  name,
  lastName,
  password,
  countryCode,
  magicLinkCode = ""
) => async (dispatch) => {
  try {
    dispatch({ type: USER_POST, payload: {} });
    const response = await safelyApi("/user", {
      method: "post",
      body: { emailNumber, name, lastName, password, countryCode, magicLinkCode },
    });
    if (response.status) {
      dispatch({ type: USER_POST_SUCCESS, payload: response });
    } else {
      dispatch({
        type: USER_POST_ERROR,
        payload: {
          message:
            response.error_description ||
            response.systemError ||
            response.description ||
            response.error,
          ...response,
        },
      });
    }
    return response;
  } catch (e) {
    dispatch({ type: USER_POST_ERROR, payload: e });
    return { e, status: false };
  }
};

export const createUserSocialMedia = (accesstoken, magicLinkCode = "") => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_SOCIAL_POST, payload: {} });
      const response = await safelyApi("/user/social", {
        method: "post",
        body: { accesstoken, magicLinkCode },
      });
      if (response.status) {
        dispatch({ type: USER_SOCIAL_POST_SUCCESS, payload: response });
      } else {
        dispatch({
          type: USER_SOCIAL_POST_ERROR,
          payload: { message: response.systemError, ...response },
        });
      }
      return response;
    } catch (e) {
      dispatch({ type: USER_SOCIAL_POST_ERROR, payload: e });
      return { e, status: false };
    }
  };
};

export const updateUserContact = (id, email, mobilePhone, name, lastName) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_CONTACT_UPDATE, payload: {} });
      const response = await safelyApi("/user/contact", {
        method: "put",
        body: { id, email, mobilePhone, name, lastName },
      });
      if (response.status) {
        dispatch({ type: USER_CONTACT_UPDATE_SUCCESS, payload: response });
      } else {
        dispatch({
          type: USER_CONTACT_UPDATE_FAIL,
          payload: {
            message: response.error_description || response.systemError,
            ...response,
          },
        });
      }
      return response;
    } catch (e) {
      dispatch({ type: USER_CONTACT_UPDATE_FAIL, payload: e });
      return { e, status: false };
    }
  };
};

export const updateRole = (id, workSpaceId, roleId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_UPDATE_ROLE, payload: {} });
      const response = await safelyApi("/user/role", {
        method: "put",
        body: { id, workSpaceId, roleId },
      });
      if (response.status) {
        dispatch({ type: USER_UPDATE_ROLE_SUCESS, payload: response.data });
      }
      else {
        dispatch({
          type: USER_UPDATE_ROLE_FAIL,
          payload: {
            message: response.error_description || response.systemError,
            ...response,
          },
        });
      }
      return response;
    } catch (e) {
      dispatch({ type: USER_UPDATE_ROLE_FAIL, payload: e });
      return { e, status: false };
    }
  };
};

//updating username
export const updateUsername = (id, name, lastName) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_UPDATE_USERNAME, payload: {} });
      const response = await safelyApi("/user/contact", {
        method: "put",
        body: { id, name, lastName },
      });
      if (response.status) {
        dispatch({ type: USER_UPDATE_USERNAME_SUCCESS, payload: response.data });
      }
      else {
        dispatch({
          type: USER_UPDATE_USERNAME_FAILURE,
          payload: {
            message: response.error_description || response.systemError,
            ...response,
          },
        });
      }
      return response;
    } catch (e) {
      dispatch({ type: USER_UPDATE_USERNAME_FAILURE, payload: e });
      return { e, status: false };
    }
  };
};

//updating username for users table
export const updateUsernameInTable = (id, name, lastName, spaceOwner) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_UPDATE_USERNAME, payload: {} });
      const response = await safelyApi("/user/contact", {
        method: "put",
        body: { id, name, lastName },
      });
      if (response.status) {
        if (id === spaceOwner) {
          dispatch({ type: USER_UPDATE_USERNAME_SUCCESS, payload: response.data });
        }
        return response;
      }

      return response;
    } catch (e) {
      dispatch({ type: USER_UPDATE_USERNAME_FAILURE, payload: e });
      return { e, status: false };
    }
  };
};

export const getUserProfileById = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_GET_PROFILE_BY_ID, payload: {} });
      const response = await safelyApi("/user/profile/" + id, {
        method: "get",
      });
      if (response.status) {
        dispatch({ type: USER_GET_PROFILE_BY_ID_SUCCESS, payload: response });
      }
      else {
        dispatch({
          type: USER_GET_PROFILE_BY_ID_FAIL,
          payload: {
            message: response.error_description || response.systemError,
            ...response,
          },
        });
      }
      return response;
    } catch (e) {
      dispatch({ type: USER_GET_PROFILE_BY_ID_FAIL, payload: e });
      return { e, status: false };
    }
  };
};

export const updateProfilePhoto = (uri, file, id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_UPDATE_PROFILE_PHOTO, payload: {} });
      //console.log(file)
      const response = await new AmazonStorage().put(uri, file, 'image-profiles/');
      var status = false;
      if (response) {
        const picture = response.Location;
        const res = await safelyApi('/user/picture', { method: 'put', body: { id, picture } });
        dispatch({ type: USER_UPDATE_PROFILE_PHOTO_SUCCESS, payload: response });
        dispatch({ type: AUTHORIZATION_SET_USER_DATA, payload: { picture } });
        if (res.status) {
          status = true;
        }
        return res;
      } else {
        dispatch({ type: USER_UPDATE_PROFILE_PHOTO_FAIL, payload: response })
        status = false;
      }
      return { response, status };

    } catch (e) {
      dispatch({ type: USER_UPDATE_PROFILE_PHOTO_FAIL, payload: e });
      return { e, status: false };
    }
  }
}

export const setMessage = (message) => (dispatch) =>
  dispatch({ type: SET_MESSAGE, payload: message });

export const resetUser = () => (dispatch) =>
  dispatch({ type: USER_RESET, payload: {} });

//verify user account email or mobile
export const sendVerifyEmailPin = (email, language) => async (dispatch) => {
  try {
    const response = await safelyApi("/login/email/verify", {
      method: "post",
      body: { email, language },
    });
    if (response.status) {
      dispatch({ type: SEND_VERIFY_PIN_SUCCESS, payload: response });
    } else {
      dispatch({
        type: SEND_VERIFY_PIN_ERROR,
        payload: response
      });
    }
    return response;
  } catch (e) {
    dispatch({ type: SEND_VERIFY_PIN_ERROR, payload: e });
    return { e, status: false };
  }
}

export const verifyValidEmailPin = (email, verifySid, code, language) => async (dispatch) => {
  try {
    const response = await safelyApi("/login/email/verifyChecks", {
      method: "post",
      body: { email, verifySid, code, language },
    });
    if (response.status) {
      dispatch({ type: VERIFY_PIN_SUCCESS, payload: response });
    } else {
      dispatch({
        type: VERIFY_PIN_ERROR,
        payload: response
      });
    }
    return response;
  } catch (e) {
    dispatch({ type: VERIFY_PIN_ERROR, payload: e });
    return { e, status: false };
  }
}

export const sendVerifyMobilePin = (mobileNumber, countryCode, language) => async (dispatch) => {
  try {
    const response = await safelyApi("/login/mobile/verify", {
      method: "post",
      body: { mobileNumber, countryCode, language },
    });
    if (response.status) {
      dispatch({ type: SEND_VERIFY_PIN_SUCCESS, payload: response });
    } else {
      dispatch({
        type: SEND_VERIFY_PIN_ERROR,
        payload: response
      });
    }
    return response;
  } catch (e) {
    dispatch({ type: SEND_VERIFY_PIN_ERROR, payload: e });
    return { e, status: false };
  }
}

export const verifyValidMobilePin = (verifySid, code, language) => async (dispatch) => {
  try {
    const response = await safelyApi("/login/mobile/verifyChecks", {
      method: "post",
      body: { verifySid, code, language },
    });
    if (response.status) {
      dispatch({ type: VERIFY_PIN_SUCCESS, payload: response });
    } else {
      dispatch({
        type: VERIFY_PIN_ERROR,
        payload: response
      });
    }
    return response;
  } catch (e) {
    dispatch({ type: VERIFY_PIN_ERROR, payload: e });
    return { e, status: false };
  }
}
export const updateUserSubSpaces = (workSpaceId, userId, subspaceIds) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_UPDATE_SUBSPACES, payload: {} });
      const response = await safelyApi(ApiEndPoint.AdduserToMUltiSubWorkspace, {
        method: "post",
        body: { workSpaceId, userId, subspaceIds },
      });
      if (response.status) {
        // dispatch({ type: WORKSPACE_SET_FOCUSED, payload: response.data });
        return response;
      } else {
        dispatch({
          type: USER_UPDATE_SUBSPACES_FAILURE,
          payload: { message: response.systemError, ...response },
        });
      }
      return response;
    } catch (e) {
      dispatch({ type: USER_UPDATE_SUBSPACES_FAILURE, payload: e });
      return { e, status: false };
    }
  }
}

export const setUserData = userData => dispatch => dispatch({ type: SET_USER, payload: userData });

export const checkInNewUser = (name, lastName, picture, workSpaceId, monitorId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CHECK_IN_NEW_USER, payload: {} });
      const { Location } = await new AmazonStorage().put(picture.preview, picture.file, 'image-profiles/');
      const { data } = await safelyApi(ApiEndPoint.RegisterUser, {
        method: 'post',
        body: { name, lastName, picture: Location, workSpaceId }
      });
      const response = await safelyApi(ApiEndPoint.CheckInUser,
        {
          method: 'post',
          body: { userId: data.id, workSpaceId, monitorId }
        }
      );
      dispatch({ type: CHECK_IN_NEW_USER_SUCCESS, payload: {} });
      return { ...response, picture: Location };
    }
    catch (e) {
      dispatch({ type: CHECK_IN_NEW_USER_FAILURE, payload: e });
      return { e, status: false };
    }
  }
}

export const updateProfileBackgroundPhoto = (uri, file, id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_UPDATE_BACKGROUND_PROFILE_PHOTO, payload: {} });
      const response = await new AmazonStorage().put(uri, file, 'image-profiles-background/');
      var status = false;
      if (response) {
        const backgroundPicture = response.Location;
        const res = await safelyApi('/user/contact', { method: 'put', body: { id, backgroundPicture } });
        dispatch({ type: USER_UPDATE_BACKGROUND_PROFILE_PHOTO_SUCCESS, payload: response });
        if (res.status) status = true;
      } else {
        dispatch({ type: USER_UPDATE_BACKGROUND_PROFILE_PHOTO_FAIL, payload: response })
        status = false;
      }
      return { response, status };
    } catch (e) {
      dispatch({ type: USER_UPDATE_BACKGROUND_PROFILE_PHOTO_FAIL, payload: e });
      return { e, status: false };
    }
  }
}

export const editUserData = userData => dispatch => dispatch({ type: EDIT_USER, payload: userData });

export const ClickedUserOfUserManagement = name => dispatch => dispatch({ type: CLICKED_USER, payload: name });

export const updateUserDetails = (userInfo) => {
  return async (dispatch, getState) => {
    try {
      const { user: { accesstokenidp } } = getState();
      dispatch({ type: USER_DETAILS_POST, payload: {} });
      const response = await safelyApi(
        ApiEndPoint.updateUserDataFromUserMgmt,
        {
          method: "put",
          headers: { accesstokenidp: accesstokenidp },
          body: userInfo
        }
      );
      if (response.status) {
        dispatch({ type: USER_DETAILS_POST_SUCCESS, payload: response });
      } else {
        dispatch({
          type: USER_DETAILS_POST_ERROR,
          payload: { message: response.systemError, ...response },
        });
      }
      return response;
    } catch (e) {
      dispatch({ type: USER_DETAILS_POST_ERROR, payload: e });
      return { e, status: false };
    }
  };
}