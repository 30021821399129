import {
  POST_LOGIN,
  USER_RESET,
  LOG_OUT_SUCCESS,
  LOGIN_USER_SOCIAL_MEDIA,
  LOGIN_USER_SOCIAL_MEDIA_SUCCESS,
  LOGIN_USER_SOCIAL_MEDIA_FAIL,
  SET_USER,
  SET_LOADING,
  LOGIN_USER_EXISTS,
  LOGIN_USER_EXISTS_SUCCESS,
  LOGIN_USER_EXISTS_FAIL,
  CHANGE_EMAIL_NUMBER,
  CHANGE_PASSWORD,
  LOGIN_USER_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_FAIL,
  LOG_OUT_ERROR,
  ONBOARDING_RESET,
  OPERATION_RESET,
  WORKSPACE_RESET,
  LANGUAGE_RESET,
  CHANGE_FORGOT_EMAIL_NUMBER,
  RESET_FORGOT_EMAIL_NUMBER,
  RESET_LOGIN
} from "./types";
import { safelyApi } from "../../api/safely";
import uuid from 'react-uuid'

export const changeEmailNumber = (emailNumber) => (dispatch) =>
  dispatch({ type: CHANGE_EMAIL_NUMBER, payload: emailNumber });
export const changePassword = (password) => (dispatch) =>
  dispatch({ type: CHANGE_PASSWORD, payload: password });

export const loginWithEmailAndPassword = (emailNumber, password, language, countryCode, firebaseToken) => async (
  dispatch
) => {
  try {
    dispatch({ type: LOGIN_USER, payload: {} });
    const response = await safelyApi("/login", {
      method: "post",
      body: { emailNumber, password, language, countryCode, firebaseToken, deviceId : firebaseToken != '' ? firebaseToken : uuid() },
    });

    if (response.status) {
      dispatch({ type: LOGIN_USER_SUCCESS, payload: response });
      dispatch({ type: SET_USER, payload: response.data });
      dispatch({ type: POST_LOGIN, payload: response.status });
    } else {
      dispatch({
        type: LOGIN_USER_FAIL,
        payload: {
          message: response.systemError || response.error_description,
        },
      });
    }
    return response;
  } catch (error) {
    //console.log("Enter to catch on login...");
    dispatch({ type: LOGIN_USER_FAIL, payload: error });
  }
};

export const logout = () => (dispatch) => {
  try {
    dispatch({ type: USER_RESET, payload: {} });
    dispatch({ type: LOG_OUT_SUCCESS, payload: {} });
    dispatch({ type: ONBOARDING_RESET, payload: {} });
    dispatch({ type: OPERATION_RESET, payload: {} });
    dispatch({ type: WORKSPACE_RESET, payload: {} });
    dispatch({
      type: POST_LOGIN,
      payload: false,
    });
    dispatch({ type: LANGUAGE_RESET, payload: {} });
  } catch (e) {
    dispatch({ type: LOG_OUT_ERROR, payload: {} });
  }
};

export const loginWithSocialMedia = (accesstoken, language, firebaseToken) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_USER_SOCIAL_MEDIA, payload: {} });
    const response = await safelyApi("/login/social", {
      method: "post",
      body: { accesstoken, language, firebaseToken, deviceId : firebaseToken != '' ? firebaseToken : uuid() },
    });
    if (response.status) {
      dispatch({ type: LOGIN_USER_SOCIAL_MEDIA_SUCCESS, payload: response });
      dispatch({ type: POST_LOGIN, payload: true });
      dispatch({ type: SET_USER, payload: response.data });
      //console.log("All actions in login social were executed!");
    } else {
      dispatch({
        type: LOGIN_USER_SOCIAL_MEDIA_FAIL,
        payload: { message: response.systemError, ...response },
      });
    }
    return response;
  } catch (error) {
    dispatch({ type: LOGIN_USER_SOCIAL_MEDIA_FAIL, payload: error });
  }
};

export const loginUserExists = (accesstoken) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_USER_EXISTS, payload: {} });
    const response = await safelyApi("/login/userexists", {
      method: "post",
      body: { accesstoken },
    });
    if (response.status){
      dispatch({ type: LOGIN_USER_EXISTS_SUCCESS, payload: response });
    } 
    else{
      dispatch({
        type: LOGIN_USER_EXISTS_FAIL,
        payload: { message: response.systemError, ...response },
      });
    }
    return response;
  } catch (e) {
    await dispatch({ type: LOGIN_USER_EXISTS_FAIL, payload: e });
    return { e, status: false };
  }
};

export const reset = (loading = false) => (dispatch) =>
  dispatch({ type: SET_LOADING, payload: loading });

export const SendEmailOrMobile = (emailNumber, language) => async (dispatch) => {
  try {
    const response = await safelyApi("/user/forgotPassword", {
      method: "put",
      body: { emailNumber, language },
    });
    return response;
  } catch (e) {
    console.log(e);
  }
};

export const ChangeForgotEmailNumber = (emailOrMobile) => (dispatch) =>
  dispatch({ type: CHANGE_FORGOT_EMAIL_NUMBER, payload: emailOrMobile  });

export const ResetForgotEmailNumber = () => (dispatch) =>
  dispatch({ type: RESET_FORGOT_EMAIL_NUMBER });

export const resetLogin = () => (dispatch) =>  
  dispatch({ type: RESET_LOGIN });