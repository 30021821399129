import React, { useState } from "react";
import { Button } from "react-bootstrap";
import InputSelect from "../commons/wrapped-components/inputs/InputSelect";
import { connect } from "react-redux";
import Avatar from "../commons/wrapped-components/Avatar";
import InputPhoto from "../commons/wrapped-components/inputs/InputPhoto";
import InputText from "../commons/wrapped-components/inputs/InputText";
import { ReactComponent as CloseIcon } from "../../icons/closeIcon.svg";
import { translate } from "../../redux/reducers/languageReducer";
import { checkInNewUser, setMessage } from "../../redux/actions/userActions";
import moment from "moment";


const CheckInUser = (props) => {
    const format = props.lang === "es" ? "ddd, DD MMM YYYY" : "ddd, MMM DD YYYY";
    const localeDate = moment().locale(props.lang);
    const { monitorId } = props;
    const [profliePic, changeProfilePic] = useState({
        file: 'default',
        preview: null
    });
    const [name, changeName] = useState('');
    const [lastName, changeLastName] = useState('');
    const [selectedWorkSpace, changeSelectedWorkSpace] = useState(props.defaultWorkspace);

    return (
        <>
            <button
                onClick={() => props.setModalVisible(false)}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <CloseIcon />
            </button>
            <h3>
                {props.dictionary.s("checkInNewUser")}
            </h3>
            <div
                className="row justify-content-center align-items-center"
                style={{ flexDirection: "column" }}
            >
                <div style={{ width: "160px", position: "relative" }} className="avtar">
                    <Avatar src={profliePic.preview} className="rounded-circle mr-2" />
                    <InputPhoto
                        imagePreviewURL=""
                        mode="onlyIcon"
                        styleIcon={{
                            borderRadius: "50%",
                            width: 40,
                            height: 40,
                            border: "1px solid #ccc",
                            right: '0px',
                            bottom: '5px',
                            left: null
                        }}
                        onScreenShot={async (file, preview) => {
                            changeProfilePic({ file, preview });
                        }}
                    />
                </div>
                <div className="checkin-input-container">
                    <InputText
                        maxlength="30"
                        className="form-control"
                        value={name}
                        placeholder={props.dictionary.s('name')}
                        onChangeText={changeName}
                    />
                    <InputText
                        maxlength="30"
                        className="form-control"
                        value={lastName}
                        placeholder={props.dictionary.s('lastName')}
                        onChangeText={changeLastName}
                    />
                    <InputSelect
                        className="checkin-select"
                        label={selectedWorkSpace.name}
                        value={selectedWorkSpace._id}
                        searchable={true}
                        options={props.workSpaces}
                        onChange={(workspace) => changeSelectedWorkSpace(workspace)}
                        valueKey="_id"
                        clearable={false}
                    />
                    <p
                        className="popup-datetime"
                    >
                        {localeDate.format(format).split(".").join("")},{" "}
                        {localeDate.format("hh:mm:ss a")}
                    </p>
                    <Button
                        className="popup-checkin"
                        disabled={props.loading}
                        onClick={async () => {
                            if (name.trim() !== '' && lastName.trim() !== '' && profliePic.file !== 'default') {
                                props.onSetMessage(null);
                                const { status, data, message, picture } = await props.onCheckInNewUser(name.trim(), lastName.trim(), profliePic, selectedWorkSpace._id, monitorId);
                                props.setModalVisible(false);
                                if (status) {
                                    props.checkInUser(data.userId, data.workSpaceId, data.monitorId, { id: data.userId, name: name.trim(), lastName: lastName.trim(), picture: picture });
                                } else props.onSetMessage(message);
                            } else if (profliePic.file === 'default') {
                                props.onSetMessage(props.dictionary.s('errorYouNeedToAddAnImage'));
                            } else if (name.trim() === '') {
                                props.onSetMessage(props.dictionary.s('errorNameMustNotBeEmpty'));
                            } else if (lastName.trim() === '') {
                                props.onSetMessage(props.dictionary.s('errorLastNameMustNotBeEmpty'));
                            }
                        }}
                    >
                        {props.loading ? props.dictionary.s('loading') + '...' : props.dictionary.s("checkIn")}
                    </Button>
                    {props.message ?
                        <div
                            style={{
                                marginHorizontal: 20,
                                marginVertical: 10,
                                textAlign: "center",
                                color: 'red'
                            }}
                        >
                            <label>{props.message}</label>
                        </div>
                        : null
                    }
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    const user = state.user;
    return {
        dictionary: {
            s: (key) => translate(state.language.dictionary, key),
        },
        userId: user.id,
        workspaceFocused: state.workspace.workspaceFocused,
        loading: state.user.loading,
        message: state.user.message,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onCheckInNewUser: (name, lastName, picture, workSpaceId, monitorId) => dispatch(checkInNewUser(name, lastName, picture, workSpaceId, monitorId)),
    onSetMessage: (message) => dispatch(setMessage(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckInUser);
