import styled from "styled-components";
import { Navbar } from "react-bootstrap";

const HeaderNavbar = styled(Navbar)`
  height: 70.52px;
  background-color: ${props => props.backgroundcolor};
  margin-left: ${props =>
    props.screenwidth < 1200 ? "0px" : props.marginleft};
  transition: all 0.5s;
  box-shadow: ${props => props.boxshadow};
`;
export default HeaderNavbar;
