import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../commons/wrapped-components/Modal';
import { general } from '../../general/global';
import { ReactComponent as CloseIcon } from "../../icons/closeIcon.svg";


const CheckOutSubspaceModal = ({ dictionary, showModalCheckOutSubspace, closeCheckOutSubspaceModal, onCheckOutSubspace, selectedSubspace }) => {
    return (
        <Modal
            showModal={showModalCheckOutSubspace}
            onHide={closeCheckOutSubspaceModal}
            dismissible={true}
            properties={{
                centered: true,
            }}
            textButtonSave={dictionary.s('checkOutSubspace')}
            textButtonClose={dictionary.s('cancel')}
            onClose={closeCheckOutSubspaceModal}
            onSave={onCheckOutSubspace}
            dialogClassName="safely-modal"
            className="animated fadeInDown checkout-modal"
            propertyButtonSave={{
                block: false,
                style: {
                    backgroundColor: general.theme.redCoral,
                    color: general.theme.whiteFontColor,
                    border: `1px solid ${general.theme.dangerColor}`,
                    width: '100px',
                    height: '40px',
                    fontWeight: '500',
                    padding: '0px',
                    lineHeight: '1.2rem'
                }
            }}
            bsStyleButtonSave="secondary"
            buttonCloseProperties={{
                block: false,
                style: {
                    backgroundColor: general.theme.grayLighter,
                    border: `0px`,
                    color: general.theme.secondaryColor,
                    width: '100px',
                    height: '40px',
                    fontWeight: '500'
                }
            }}
            closeButton
            body={
                <>
                    <button
                        onClick={closeCheckOutSubspaceModal}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <CloseIcon />
                    </button>
                    <h3>
                        <b>{`${dictionary.s("checkOutSubspaceQuestion")} ${selectedSubspace.subspaceName}?`}</b>
                    </h3>
                    <span className="checkout-subspace question-text">
                        {dictionary.s('areYouSureYouWantToCheckOut') + ' '}
                    </span>
                    <span className="checkout-subspace selected-subspace">
                        {selectedSubspace.subspaceName + '?'}
                    </span>
                </>
            }
        />
    )
}

CheckOutSubspaceModal.propTypes = {
    dictionary: PropTypes.object.isRequired,
    showModalCheckOutSubspace: PropTypes.bool.isRequired,
    closeCheckOutSubspaceModal: PropTypes.func.isRequired,
    onCheckOutSubspace: PropTypes.func
}

export default CheckOutSubspaceModal;
