import React from 'react';
import "../../../../styles/switch.css";
import PropTypes from "prop-types";

const Switch = ({ isOn, onChange, onColor, offColor, width, height, handleDiameter, name, disabled, id }) => {
    return (
        <>
            <input
                type="checkbox"
                checked={isOn}
                onChange={onChange}
                className="react-switch-checkbox"
                name={name ? name : id}
                id={id}
                disabled={disabled}
            />
            <label
                style={{ background: isOn ? onColor : offColor, width: width, height: height }}
                className="react-switch-label"
                htmlFor={name ? name : id}
            >
                <span className={`react-switch-button`} style={{ width: handleDiameter, height: handleDiameter }} />
            </label>
        </>
    )
}

Switch.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    isOn: PropTypes.bool.isRequired,
    onColor: PropTypes.string,
    offColor: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    handleDiameter: PropTypes.number
}

Switch.defaultProps = {
    width: 40,
    height: 25,
    handleDiameter: 20,
    onColor: "#665DFF",
    offColor: "#808080",
    isOn: false,
    onChange: () => { },
    disabled: false,
}

export default Switch;