import React, { memo, useState } from 'react';
import { Row, Col, DropdownButton, Dropdown } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Input from "../../commons/styled-components/general/Input";
import Label from "../../commons/styled-components/general/Label";
import Button from "../../commons/styled-components/general/Button";

const Table = memo((
    {
        containerStyle,
        contentStyle,
        keyField,
        loading,
        columns,
        data,
        overlayOptions,
        pagination,
        editable,
        cellEdit,
        search,
        customPagination,
        tableTitle,
        placeholderSearch,
        additionalItems,
        dictionary,
        paginationSize,
        sizePerPageList,
        paginationOptions,
        ...rest
    }
) => {
    const renderTitle = title => {
        return (
            <Col xs={12} sm={6} className="contactsTable-title">
                <span>{title}</span>
            </Col>
        );
    };

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            console.log(`e ${e} - row ${row} - rowIndex: ${rowIndex}`);
        },
        // onMouseEnter: (e, row, rowIndex) => {
        //     console.log(`enter on row with index: ${rowIndex}`);
        // }
    };
    const renderTable = (toolkitProps = [], paginationProps = []) => {
        return (
            <BootstrapTable
                keyField={keyField}
                loading={loading}
                columns={
                    !columns.length ? [{ dataField: " ", text: " " }] : columns
                }
                data={data}
                overlay={overlayFactory(overlayOptions)}
                pagination={pagination ? paginationFactory() : null}
                cellEdit={editable ? cellEditFactory(cellEdit) : null}
                rowEvents={rowEvents}
                {...toolkitProps.baseProps}
                {...paginationProps}
                {...rest}
            />
        );
    };

    const renderSearchTable = (paginationProps = []) => {
        return (
            <ToolkitProvider
                keyField={keyField}
                data={data}
                columns={columns}
                search
            >
                {
                    toolkitProps => (
                        <>
                            <Row className="pb-4">
                                {tableTitle !== '' && renderTitle(tableTitle)}
                                <Col xs={12} sm={tableTitle !== '' ? 6 : 12} className="contactsTable-search">
                                    <div>
                                        <Label><i className="fa fa-search" /></Label>
                                        <Input
                                            className="form-control contactsTable-search"
                                            type="text"
                                            onKeyUp={(e) => toolkitProps.searchProps.onSearch(e.target.value)}
                                            placeholder={placeholderSearch}
                                        />
                                        {additionalItems}
                                    </div>
                                </Col>
                            </Row>
                            {renderTable(toolkitProps, paginationProps)}
                        </>
                    )
                }
            </ToolkitProvider>
        )
    }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            {`${dictionary ? dictionary.s('showing') : 'Showing'}: ${from} - ${to}`}
            {` ${dictionary ? dictionary.s('of') : 'of'}  ${size}`}
        </span>
    );


    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <>
            <span>{`${dictionary ? dictionary.s("show") : 'Show'}:`}</span>
            <DropdownButton
                variant="outline-secondary reports-buttons"
                title={options.find(o => o.page === parseInt(currSizePerPage))?.text}
            >
                {
                    options.map(option =>
                        <Dropdown.Item
                            key={option.text}
                            eventKey={option.page}
                            active={currSizePerPage === `${option.page}`}
                            onClick={() => {
                                onSizePerPageChange(option.page)
                            }}
                        >
                            {option.text}
                        </Dropdown.Item>
                    )
                }
            </DropdownButton>
            <span>{dictionary ? dictionary.s('perPage') : `per page`}</span>
        </>
    );

    const options = {
        custom: true,
        totalSize: data.length,
        paginationSize: paginationSize,
        sizePerPageList: sizePerPageList ? sizePerPageList : [{
            id: '10', text: '10', value: 10
        }, {
            id: '20', text: '20', value: 20
        }, {
            id: 'all', text: dictionary ? dictionary.s('All') : 'All', value: data.length
        }], // A numeric array is also available. the purpose of above example is custom the text
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageRenderer,
        ...paginationOptions
    };
    const renderPaginationTable = (option) => {
        return (
            <PaginationProvider
                pagination={paginationFactory(option)}
            >
                {({ paginationProps, paginationTableProps }) => (
                    <>
                        {
                            search ?
                                renderSearchTable(paginationTableProps) :
                                <>
                                    <Row className="pb-4">
                                        {tableTitle !== '' && renderTitle(tableTitle)}
                                        <Col></Col>
                                    </Row>
                                    {renderTable([], paginationTableProps)}
                                </>
                        }
                        {data.length > 0 ?
                            <Row className="contactsTable-paginationFooter">
                                <Col xs={12} md={12} lg={4} className="contactsTable-paginationFooter-Showing">
                                    <PaginationTotalStandalone
                                        {...paginationProps}
                                    />
                                </Col>
                                <Col xs={12} md={6} lg={4} className="contactsTable-paginationFooter-Navigation">
                                    <div>
                                        <Button
                                            disabled={
                                                (paginationProps.page === undefined || paginationProps.page === 1) ? true : false
                                            }
                                            // ImportContacts-Buttons
                                            variant="outline-secondary reports-buttons"
                                            onClick={() => paginationProps.onPageChange(paginationProps.page - 1)}
                                        >{dictionary ? dictionary.s('PREV') : 'Prev'}</Button>
                                        <span>
                                            {`${paginationProps.page} ${dictionary ? dictionary.s('of') : 'of'} ${Math.ceil(paginationProps.dataSize / paginationProps.sizePerPage)}`}
                                        </span>
                                        <Button
                                            disabled={paginationProps.dataSize !== 0 ? (
                                                paginationProps.page === Math.ceil(paginationProps.dataSize / paginationProps.sizePerPage) ?
                                                    true : false
                                            ) : true}
                                            // ImportContacts-Buttons
                                            variant="outline-secondary reports-buttons"
                                            onClick={() => paginationProps.onPageChange(paginationProps.page + 1)}
                                        >{dictionary ? dictionary.s('NEXT') : 'Next'}</Button>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={4} className="contactsTable-paginationFooter-perPage">
                                    {paginationProps.dataSize !== 0 && <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                    />}
                                </Col>
                            </Row> : null
                        }
                    </>
                )
                }
            </PaginationProvider >
        );
    }

    return (
        <Row style={containerStyle}>
            <Col style={{ margin: '0px 15px', ...contentStyle }}>
                {customPagination ?
                    renderPaginationTable(options)
                    :
                    search ? renderSearchTable([]) :
                        renderTable([], [])
                }
            </Col>
        </Row>
    );
});

Table.defaultProps = {
    containerStyle: {
    },
    contentStyle: {
    },
    loading: false,
    keyField: 'id',
    data: [],
    columns: [],
    overlayOptions: {
        spinner: true,
        background: 'rgba(192,192,192,0.3)'
    },
    classes: 'white-bg',
    wrapperClasses: 'white-bg',
    bordered: false,
    noDataIndication: '',
    rowEvents: null,
    pagination: true,
    editable: true,
    cellEdit: {},
    search: false,
    paginationTable: false,
    tableTitle: "",
    additionalItems: null,
    placeholderSearch: "",
    paginationSize: 10,
    paginationOptions: {}
}

export default Table;