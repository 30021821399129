import React from "react";
import { deleteSpaceImageHandler } from "../../redux/actions/workspaceActions";
import { connect } from "react-redux";
import { general } from "../../general/global";
import { translate } from "../../redux/reducers/languageReducer";
import Modal from "../commons/wrapped-components/Modal";

const DeleteSpaceImage = (props) => {
  return (
    <Modal
      title={props.dictionary.s("deleteSpaceImage")}
      dialogClassName="safely-modal renderModalCreatSubSpace"
      showModal={props.showDeleteModal}
      onHide={() => {
        props.closeDeleteModal();
      }}
      onSave={() => {
        props
          .onClickOfDeleteSpaceImage(
            props.accesstokenidp,
            props.workspaceFocused._id,
            props.workspaceFocused.picture,
          )
          .then(() => {
            props.closeDeleteModal();
          })
          .catch((error) => {
            props.closeDeleteModal();
          });
      }}
      onClose={() => {
        props.closeDeleteModal();
      }}
      textButtonSave={props.dictionary.s("delete")}
      textButtonClose={props.dictionary.s("cancel")}
      propertyButtonSave={{
        block: false,
        style: {
          backgroundColor: "#E67070",
          color: general.theme.whiteFontColor,
          border: `1px solid #E67070`,
        },
      }}
      bsStyleButtonSave="secondary"
      buttonCloseProperties={{
        block: false,
        style: {
          backgroundColor: general.theme.whiteColor,
          border: `1px solid ${general.theme.primaryColor}`,
          color: general.theme.primaryColor,
        },
      }}
      properties={{
        centered: true,
      }}
      body={
        <>
          <div className="deletePostText">
            {props.dictionary.s("youAreAboutToDeleteSpaceImage")}
          </div>
        </>
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    accesstokenidp: state.user.accesstokenidp,
    lang: state.language.lang,
    workspaceFocused: state.workspace.spaceFocused,
    dictionary: {
      s: (key) => translate(state.language.dictionary, key),
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClickOfDeleteSpaceImage: (accesstokenidp, workSpaceId, oldPic) =>
    dispatch(deleteSpaceImageHandler(accesstokenidp, workSpaceId, oldPic)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSpaceImage);
