import React, { useState } from "react";
import ZXing from "../../ZXing";
import Modal from "../Modal";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "../../../../redux/reducers/languageReducer";

const BarcodeScanner = (props) => {
  const [barCodeReader, setBarCodeReader] = useState(null);
  //const barCodeReader = useRef(null);

  const onDecodeFromInputVideo = ({ text }) => {
    barCodeReader.stop();
    props.onBarcodeRead(text);
  };

  return (
    <Modal
      title={props.title ? props.dictionary.s(props.title) : props.dictionary.s("scanQRCode")}
      headerStyle={{ borderBottom: "0", paddingLeft: "2rem" }}
      titleStyle={{ fontWeight: "bold" }}
      bodyStyle={{ padding: "0 2rem 0 2rem" }}
      className={props.className ? "animated fadeInDown qrcodeScanner" : "animated fadeInDown"}
      showModal={props.visible}
      onHide={() => {
        props.onCancel();
        barCodeReader.stop();
      }}
      onSave={() => {
        props.onCancel();
        barCodeReader.stop();
      }}
      textButtonSave={props.dictionary.s("cancel")}
      bsStyleButtonSave="outline-primary ImportContacts-Buttons"
      propertyButtonSave={{
        className: "border-radius-none",
      }}
      properties={{
        centered: true,
      }}
      body={
        <ZXing
          ref={ref => setBarCodeReader(ref)}
          onDecodeFromInputVideo={(result) => onDecodeFromInputVideo(result)}
          dictionary={props.dictionary}
        />
      }
      bsStyleFooter="modalScannerFooter"
    />
  );
};

BarcodeScanner.defaultProps = {
  visible: true,
  onBarcodeRead: () => { },
};
const mapStateToProps = (state) => ({
  dictionary: {
    s: key => translate(state.language.dictionary, key)
  }
});
export default connect(
  mapStateToProps,
  null
)(withRouter(BarcodeScanner));
