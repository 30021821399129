import {
    EDIT_FORM_GET_REQUEST,
    EDIT_FORM_GET_SUCCESS,
    EDIT_FORM_GET_FAIL,
    UPDATE_INPUT,
    UPDATE_INPUT_SUCCESS,
    UPDATE_INPUT_FAIL,
    UPDATE_CONTENT,
    UPDATE_CONTENT_SUCCESS,
    UPDATE_CONTENT_FAIL,
} from './types';
import { safelyApi } from '../../api/safely';
import ApiEndPoint from '../../constants/apiEndPoint';

export const getCheckList = (workspaceId, accesstokenidp) => async (dispatch) => {
    try {
        dispatch({ type: EDIT_FORM_GET_REQUEST, payload: {} });
        const response = await safelyApi(ApiEndPoint.GetChecklistList + workspaceId, {
            method: 'GET',
            headers: { accesstokenidp: accesstokenidp },
        });
        if (response.status) {
            dispatch({ type: EDIT_FORM_GET_SUCCESS, payload: response.data })
        } else {
            dispatch({ type: EDIT_FORM_GET_FAIL, payload: response })
        }
        return response;
    } catch (e) {
        dispatch({ type: EDIT_FORM_GET_FAIL, payload: e });
        return { e, status: false };
    }
};

export const updateInputs = (id, inputs) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_INPUT, payload: {} });
        const response = await safelyApi(ApiEndPoint.UpdateCheckListInput, {
            method: 'PUT',
            body: { id: id, inputs: inputs },
        });
        if (response.status) {
            dispatch({ type: UPDATE_INPUT_SUCCESS, payload: response })
        } else {
            dispatch({ type: UPDATE_INPUT_FAIL, payload: response })
        }
        return response;
    } catch (e) {
        dispatch({ type: UPDATE_INPUT_FAIL, payload: e });
        return { e, status: false };
    }
};

export const updateContent = (id, contentMarkdown) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_CONTENT, payload: {} });
        const response = await safelyApi(ApiEndPoint.UpdateCheckListContent, {
            method: 'PUT',
            body: { id, contentMarkdown },
        });
        if (response.status) {
            dispatch({ type: UPDATE_CONTENT_SUCCESS, payload: response })
        } else {
            dispatch({ type: UPDATE_CONTENT_FAIL, payload: response })
        }
        return response;
    } catch (e) {
        dispatch({ type: UPDATE_CONTENT_FAIL, payload: e });
        return { e, status: false };
    }
};