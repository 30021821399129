import {
  GET_MARKETPLACE_PRODUCT_DATA,
  GET_MARKETPLACE_PRODUCT_DATA_FAIL,
  GET_MARKETPLACE_PRODUCT_DATA_SUCCESS
} from "../actions/types";

export const initialState = {
  loading: false,
  marketPlaceData: [],
  apiFail: false
};

export default (state, action) => {
  const { type, payload } = action;
  if (typeof state === "undefined") {
    return initialState;
  }
  switch (type) {
    case GET_MARKETPLACE_PRODUCT_DATA:
      return { ...state, loading: true };
    case GET_MARKETPLACE_PRODUCT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        marketPlaceData: payload,
        apiFail: false
      };
    case GET_MARKETPLACE_PRODUCT_DATA_FAIL:
      return {
        ...state,
        loading: false,
        marketPlaceData: payload,
        apiFail: true
      };
    default:
      return state;
  }
};
