import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import MultiSelect from "react-multi-select-component";
import InputSelect from "../commons/wrapped-components/inputs/InputSelect";
import MainNavigation from "../Navigation/MainNavigation";
import { toastSuccess, toastError } from "../commons/Toast.js";
import { translate } from "../../redux/reducers/languageReducer";
import { getSpacesAndUserSubSpaces } from "../../redux/actions/workspaceActions";
import { editUserData, updateUserDetails } from "../../redux/actions/userActions";
import { ToastSuccess, ToastError, DesactiveToast } from "../../redux/actions/toastAction";
import DeleteUser from './deleteUser';

const EditUser = (props) => {
    const {
        title,
        rolesList,
        dictionary,
        spaceFocused,
        spaceFocused: {_id: workSpaceId},
        selectedUserData,
        selectedUserData: {
            userId,
            userName,
            picture: userPhoto
        },
        onUpdateUserDetails,
        onGetSpacesAndUserSubSpaces, 
    } = props;
    const [firstName, setFirstName] = useState(selectedUserData.firstName || "");
    const [lastName, setLastName] = useState(selectedUserData.lastName || "");
    const [role, setRole] = useState({});
    const [subSpaceOption, setSubSpaceOption] = useState([]);
    const [subSpaceSelected, setSubSpaceSelected] = useState([]);
    const [subSpacePrevSelection, setSubSpacePrevSelection] = useState([]);
    const [clickSaveButton, setClickSaveButton] = useState(false);
    const [showDeleteMember, setShowDeleteMember] = useState(false);
    const [isInitLoad, setIsInitLoad] = useState(true);
    const roleList = rolesList.filter(role => role.id !== '3'); // excluding the visitor role

    const setOptions = (oldList) => {
        if(oldList && oldList.length > 0) {
            const options = [];
            oldList.map((option) => {
                if (!option.name.toUpperCase().includes("(DEFAULT)")) {
                    options.push({ label: option.name, value: option._id });
                }
            });
            return options;
        }
        else{
            return [];
        }
    };
        
    //  componentDidMount Hack,
    useEffect(() => {
        async function fetchSpaces() {
            const object = {
                workSpaceId: spaceFocused._id,
                userId: selectedUserData.userId,
            }
            const response = await onGetSpacesAndUserSubSpaces(object);
            if (response && response.data) {
                setSubSpaceOption(setOptions(response.data.spaceSubspaces));
                setSubSpaceSelected(setOptions(response.data.userSubspaces));
                setSubSpacePrevSelection(setOptions(response.data.userSubspaces));
            }
        }
        fetchSpaces();
        // fetching roles list & subspaces list on DidMount
        let selectedRole = roleList.find(roleItem => selectedUserData.role === roleItem.visibleName );
        setRole(selectedRole);
        
        //  componentWillUnmount Hack,
        // If you want to clear anything prior to unmounting,
        // pass it inside this return function and React will call it prior to unmounting this component
        return () => props.onEditUserData({});
      }, []);

    // effect to show Toast Notification
    useEffect(() => {
        if (props.toastMessage) showToastNotification();
    }, [props.toastMessage]);
    
    const showToastNotification = () => {
        if (props.isToastSuccess) { toastSuccess(props.toastMessage); }
        else if (props.isToastError) { toastError(props.toastMessage); }
        props.onDeactivateToast();
    };

    const CloseDeleteMemberModal = () => {
        setShowDeleteMember(false);
    }

    const saveUserBtnClick = async () => {
        setClickSaveButton(true);
        let addedSubspaces = subSpaceSelected.filter(
            item => !subSpacePrevSelection.find(a => item.value === a.value)
          ).map(space => space.value);
        let removedSubspaces = subSpacePrevSelection.filter(
          item => !subSpaceSelected.find(a => item.value === a.value)
        ).map(space => space.value);
        const userDataToSave = {
            "id": userId,
            "workSpaceId": workSpaceId,
            "firstName": firstName.trim(),
            "lastName": lastName.trim(),
            "roleId": role.id,
            "addUserToSubspaces": addedSubspaces,
            "removeUserFromSubspaces": removedSubspaces,
        }
        const response = await onUpdateUserDetails(userDataToSave);
        setFirstName(firstName.trim());
        setLastName(lastName.trim());
        setSubSpacePrevSelection(subSpaceSelected);
        if (response.status) {
            setClickSaveButton(false);
            props.onToastSuccess(dictionary.s("changeshasbeenupdatedsuccessfully"));
        } else {
            setClickSaveButton(false);
            props.onToastError(dictionary.s("MessageErrorDefault"));
        }
      };
    
    return (
        <MainNavigation title={title}>
          <Container fluid className="userManagement-table container-fluid">
            <DeleteUser 
                data={selectedUserData}
                load={() => props.history.push('/users')}
                showDeleteMember={showDeleteMember} 
                closeDeleteModal={CloseDeleteMemberModal}
            />
          {selectedUserData ? (
            <Row className="user-edit-controls">
                <Col xs={12} sm={12} md={12} lg={2} className="user-pic-container">
                    <img
                        src={userPhoto}
                        aria-hidden
                        alt="picture"
                        className="edit-user-pic"
                    />
                    {!selectedUserData.spaceOwner ? <Button
                        className="btn-checkout-outline"
                        disabled={props.loading}
                        onClick={() => setShowDeleteMember(true)}
                    >
                        {props.loading ? props.dictionary.s('loading') + '...' : props.dictionary.s("removeUser")}
                    </Button> : null}
                </Col>
                <Col className="user-edit-form-container" xs={12} sm={12} md={12} lg={8}>  
                    <Row>
                        <Col xs={12} sm={12} lg={6} md={6}>
                            <div className="user-edit-form-label">
                                {props.dictionary.s("firstName")}
                            </div>
                            <input
                                type="text"
                                className={`input-box ${!firstName && 'input-field-error'}`}
                                placeholder={props.dictionary.s("firstName")}
                                name="firstName"
                                value={firstName}
                                onChange={e => {
                                    setFirstName(e.target.value);
                                    setIsInitLoad(false);
                                }}
                            />
                            {!firstName.trim() && (
                                <div
                                    className="label-div label-error-message"
                                    style={{
                                        marginHorizontal: 20,
                                        marginVertical: 10
                                    }}
                                >
                                <label>
                                    {props.dictionary.s("pleaseEnterTheValue")}
                                </label>
                                </div>
                            )}
                        </Col>
                        <Col xs={12} sm={12} lg={6} md={6}>
                            <div className="user-edit-form-label">
                                {props.dictionary.s("lastName")}
                            </div>
                            <input
                                type="text"
                                className={`input-box ${!lastName && 'input-field-error'}`}
                                placeholder={props.dictionary.s("lastName")}
                                name="lastName"
                                value={lastName}
                                onChange={e => {
                                    setLastName(e.target.value);
                                    setIsInitLoad(false);
                                }}
                            />
                            {!lastName.trim() && (
                                <div
                                className="label-div label-error-message"
                                style={{
                                    marginHorizontal: 20,
                                    marginVertical: 10
                                }}
                                >
                                <label>
                                    {props.dictionary.s("pleaseEnterTheValue")}
                                </label>
                                </div>
                            )}
                        </Col>
                        <Col xs={12} sm={12} lg={6} md={6}>
                            <div className="user-edit-form-label">
                                {props.dictionary.s("editUserUserName")}
                            </div>
                            <input
                                type="text"
                                className="input-box"
                                name="userName"
                                value={userName}
                                disabled={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} lg={6} md={6}>
                            <div className="user-edit-form-label">
                                {props.dictionary.s("role")}
                            </div>
                            <InputSelect
                                value={role}
                                disabled={selectedUserData.spaceOwner}
                                label="Select a Role"
                                displayLabel={false}
                                valueKey="name"
                                labelKey="visibleName"
                                options={roleList}
                                clearable={false}
                                onChange={item => {
                                    setRole(item);
                                    setIsInitLoad(false);
                                }}
                            />
                        </Col>
                        <Col xs={12} sm={12} lg={6} md={6}>
                            <div className="user-edit-form-label">
                                {props.dictionary.s("subspaces")}
                            </div>
                            <MultiSelect
                                options={subSpaceOption}
                                value={subSpaceSelected}
                                overrideStrings={{
                                    allItemsAreSelected: props.dictionary.s(
                                    "allSubSpacesareselected"
                                    ),
                                    search: props.dictionary.s("search")
                                }}
                                filterOptions={(options, filter) => {
                                    if (!filter) {
                                        return options;
                                    }
                                    const exp = new RegExp(filter, "i");
                                    return options.filter(({ label }) => label && label.match(exp));
                                }}
                                hasSelectAll={false}
                                onChange={e => {
                                    setSubSpaceSelected(e);
                                    setIsInitLoad(false);
                                }}
                                labelledBy={props.dictionary.s("select")}
                            />
                            <div className="display-subspace-div">
                                {subSpaceSelected.map(space => {
                                    return (
                                    <div
                                        className="display-subspace-style"
                                        key={space.label}
                                    >
                                        {space.label}
                                    </div>
                                    );
                                })}
                            </div>
                        </Col>
                    </Row>
                    <Button
                        className="usermgmt-save-btn primary-cta"
                        disabled={ isInitLoad || !firstName.trim() || !lastName.trim() }
                        onClick={() => {
                            if(!clickSaveButton) {
                                saveUserBtnClick();
                            }
                        }}
                    >
                        {!clickSaveButton ? props.dictionary.s("save") : props.dictionary.s("loading") + '...'}
                    </Button>
                </Col>
            </Row>
          ) : null }
          </Container>
        </MainNavigation>
    );
};

const mapStateToProps = (state) => {
    return {
        rolesList: state.role.list,
        selectedUserData: state.user.userMgmtEditUserData,
        dictionary: {
            s: (key) => translate(state.language.dictionary, key),
        },
        isToastSuccess: state.toast.isSuccess,
        isToastError: state.toast.isError,
        toastMessage: state.toast.message,
        spaceFocused: state.workspace.spaceFocused,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onUpdateUserDetails: (userInfo) => dispatch(updateUserDetails(userInfo)),
        onEditUserData: (data) => dispatch(editUserData(data)),
        onToastSuccess: (message) => dispatch(ToastSuccess(message)),
        onToastError: (message) => dispatch(ToastError(message)),
        onDeactivateToast: () => dispatch(DesactiveToast()),
        onGetSpacesAndUserSubSpaces: (data) => dispatch(getSpacesAndUserSubSpaces(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);