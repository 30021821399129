import React, { useEffect, useState } from "react";
import Button from "../commons/styled-components/general/Button";
import BarcodeScanner from "../commons/wrapped-components/inputs/BarcodeScanner";
import { connect } from "react-redux";
import {logout} from "../../redux/actions/loginActions";
import { addUserToWorkspace } from "../../redux/actions/workspaceActions";
import { withRouter } from "react-router-dom";
import { translate } from "../../redux/reducers/languageReducer";
import Header from './header';
import Modal from "../commons/wrapped-components/Modal";

const Welcome = (props) => {
  const [openBarcode, setOpenBarcode] = useState(false);
  const [deviceType, setdeviceType] = useState(window.deviceType);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { fullName } = props;
  const getFullName = () => {
    let firstName = (fullName.name).charAt(0).toUpperCase() + (fullName.name).slice(1);
    let lastName = (fullName.lastName).charAt(0).toUpperCase() + (fullName.lastName).slice(1);
    return firstName + ' ' + lastName;
  }

  useEffect(() => {
    function handleResize() {
      setdeviceType(window.deviceType);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLogout = () => {
    props.onLogout();
    props.history.push("/");
  }

  const renderMessage = () => (
    <Modal
      title=" "
      className="animated fadeInDown qrScannerErrorPopup"
      showModal={showMessage}
      onHide={() => setShowMessage(false)}
      onSave={() => {
        setShowMessage(false);
      }}
      textButtonSave={props.dictionary.s("close")}
      bsStyleButtonSave="primary cancel-button-qrcode"
      propertyButtonSave={{
        className: "border-radius-none",
        block: true,
      }}
      properties={{
        centered: true,
      }}
      body={errorMessage}
    />
  );

  const spaceDeleted = (message) => (
    <>
      <div>
        <div className="error-cricle">
          <i className="material-icons subspace-close-icon">close</i>
        </div>
        <span className="error-message-title-text">
          {props.dictionary.s("spaceDeleted")}
        </span>
      </div>
      <div className="error-message-deletespace-text">
        {message}
      </div>
    </>
  );

  return (
    <div className={deviceType + " limiter welcomePage"}>
      {renderMessage()}
      {openBarcode && (
        <BarcodeScanner
          title="scanQRCode"
          className="qrcodeScanner"
          visible={openBarcode}
          onBarcodeRead={async (code) => {
            const qrCode = code.split('||');
            if(qrCode[0] === 'space'){
            const response = await props.onAddUserToWorkspace(
              props.userId,
              qrCode[1],
              props.accesstokenidp,
              props.name
            );
            if (response.status) {
              props.history.push("/workspacelist");
              //redirect to dashbaord
            } else if(response.status === false && response.httpCode === 404) {
              let errorMessage = spaceDeleted(response.axActions[0][0].message);
              setOpenBarcode(false);
              setErrorMessage(errorMessage);
              setShowMessage(true);
            } else {
              setOpenBarcode(false);
              setErrorMessage(props.dictionary.s("ThisQRCodenolongerexists"));
              setShowMessage(true);
            }
          } else {
            setOpenBarcode(false);
            setErrorMessage(props.dictionary.s("ThisQRCodenolongerexists"));
            setShowMessage(true);
          }
          }}
          onCancel={() => {
            setOpenBarcode(false);
          }}
        />
      )}
      <div className="container-login100 welcome-container">
        <form className="login100-form validate-form form-style">
          <div className="createSafelySpace-div">
            <div className="header-row-div">
              <Header />
            </div>
            <div className="welcomePage-body1">
              <div className="welcome-text-div">
                <div className="text-style">{props.dictionary.s("welcome")} <span className="user-name-style"> {getFullName()} </span></div>
                <div className="para-text-style">{props.dictionary.s("confirmAccount")}</div>
                <div className="para-text-style">{props.dictionary.s("newSafelyspacefororganization")}</div>
              </div>
              <div className="new-workspace-btn">
                <Button
                  className="create-space-btn"
                  height="40px"
                  bgcolor="#665DFF"
                  position="relative"
                  display="block"
                  right="0"
                  borderradius="4px"
                  onClick={() => {
                    props.history.replace("/create-workspace");
                  }}
                >
                  {props.dictionary.s("createSafelySpace")}
                </Button>
              </div>
            </div>
          </div>
          <div className="existingSpace-div">
            <div className="existing-text-style">{props.dictionary.s("lookingForExistingSpace")}</div>
            <div className="btn-display scan-btn-div">
              <Button
                className="scan-btn-button"
                width="336px"
                height="40px"
                bgcolor="#EDF3FF"
                position="relative"
                display="block"
                right="0"
                borderradius="4px"
                onClick={() => {
                  setOpenBarcode(true);
                }}
              >
                {props.dictionary.s("scanAWorkspaceQRCode")}
              </Button>
            </div>
            <div>
              <button
                className="tryAnotherAccount-Btn"
                onClick={() => handleLogout()}
              >
                {props.dictionary.s("tryAnotherAccount")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};



const mapStateToProps = (state) => ({
  userId: state.user.id,
  fullName: state.user,
  name: state.user.name,
  userName: state.user.userName,
  password: state.onboarding.password,
  accessToken: state.user.accesstoken,
  accesstokenidp: state.user.accesstokenidp,
  connectionId: state.user.connectionId,
  message: state.workspace.message,
  lang: state.language.lang,
  dictionary: {
    s: key => translate(state.language.dictionary, key)
  }
});

const mapDispatchToProps = (dispatch) => ({
  onAddUserToWorkspace: (userId, workSpaceId, accesstokenidp, name) =>
    dispatch(addUserToWorkspace(userId, workSpaceId, accesstokenidp, name)),
  // onLoginWithEmailAndPassword: (emailNumber, password, lang) =>
  //   dispatch(loginWithEmailAndPassword(emailNumber, password, lang)),
  // onLoginWithSocialMedia: (accessToken, lang) =>
  //   dispatch(loginWithSocialMedia(accessToken, lang)),
  onLogout: () => dispatch(logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Welcome));
