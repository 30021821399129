import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "../../redux/reducers/languageReducer";
import BarcodeScanner from "./wrapped-components/inputs/BarcodeScanner";
import * as roles from "../../constants/roles";
import {
  getLastAccessAuthorization,
  setCheckPoint,
  setMessage,
  getAccessAuthorizationActivesList,
  requestAccessAuthorization,
  setCheckinByCheckpoint
} from "../../redux/actions/operationActions";
import { getUserProfileById } from "../../redux/actions/userActions";
import {
  addUserToWorkspace,
  addWorkspaceToList,
  checkWorkspaceIsDisabled,
  postSubspaceGrantaccess,
  setWorkspaceFocused
} from "../../redux/actions/workspaceActions";
import { getTransaction } from "../../redux/actions/transactionsActions";

const MyBarcodeScanner = props => {
  const getRoleById = (userId, workSpace) => {
    let roleId = roles.USER;
    if (workSpace.users) {
      const user = workSpace.users.find(x => x.id === userId);
      if (user) roleId = user.roleId;
    }
    return roleId;
  };
  const showErrorMessage = () => {
    props.closeQrScannerVisible();
    const errorMessage = props.dictionary.s(
      "anErrorHasOccurredDoYouWantToTryAgain"
    );
    props.setMessageValue(errorMessage);
    props.setWorkspaceExistModal(true);
    props.history.push("/workspacelist");
  };
  const ReadQRCodeUserFlow = async response => {
    const { userId, _id, workSpaceId } = response.data;
    if (props.workspaces.find(x => x._id === workSpaceId)) {
      let seletedWorkspace = props.workspaces.filter(
        x => x._id === workSpaceId
      );
      if (seletedWorkspace.length > 0) {
        props.onSetWorkspaceFocused(seletedWorkspace[0]);
      }
      const responseUser = await props.onGetUserProfileById(userId);
      props.onSetCheckPoint({
        userId,
        workSpaceId,
        inputs: seletedWorkspace[0].checkPointChecklist[0].inputs,
        userProfile: responseUser.data,
        userVerificationId: _id
      });
      props.closeQrScannerVisible();
      props.history.push("/checkpoint");
    } else {
      props.closeQrScannerVisible();
      const errorMessage = props.dictionary.s(
        "theUserDoesNotBelongToAnyOfYourWorkspaces"
      );
      props.setMessageValue(errorMessage);
      props.setWorkspaceExistModal(true);
      props.history.push("/workspacelist");
    }
  };

  const safelyPassIsActivated = async userId => {
    const { status, data } = await props.onGetAccessAuthorizationActiveByUserId(
      userId
    );
    if (status && data.length) {
      return true;
    }
    return false;
  };

  const readQRWorkspace = async workspaceId => {
    if (!props.workspaces.find(x => x._id === workspaceId)) {
      const responseWorkspace = await props.onAddUserToWorkspace(
        props.userId,
        workspaceId,
        props.accesstokenidp
      );
      let errorMessage = "";
      if (responseWorkspace.status) {
        await props.onAddWorkSpaceToList(responseWorkspace.data);
        props.history.push("/workpacelist");
      } else if (responseWorkspace.status === false && responseWorkspace.httpCode === 404) {
        errorMessage = showMessage(
          responseWorkspace.axActions[0][0].message,
          "spaceDeleted"
        );
      } else {
        errorMessage = responseWorkspace.systemError || props.dictionary.s("anErrorHasOccurredDoYouWantToTryAgain");
      }
      props.setMessageValue(errorMessage);
      props.setWorkspaceExistModal(true);
    } else {
      props.setMessageValue(props.dictionary.s("youAlreadyBelongToTheWorkspace"));
      props.setWorkspaceExistModal(true);
    }
  };

  const showMessage = (text, type) => (
    <>
      <div>
        <div
          className={
            type === "subSpaceGranted"
              ? "sucess-cricle"
              : type === "subSpaceDenied" || type === "subSpaceDeleted" || type === "spaceDeleted"
                ? "error-cricle"
                : null
          }
        >
          <i
            className={
              type === "subSpaceGranted"
                ? "fa fa-check"
                : type === "subSpaceDenied"
                  ? "fa fa-times"
                  : type === "subSpaceDeleted" || type === "spaceDeleted"
                    ? "material-icons subspace-close-icon"
                    : null
            }
            aria-hidden="true"
          >{type === "subSpaceDeleted" || type === "spaceDeleted" ? "close" : null}</i>
        </div>
        <span className="error-message-title-text">
          {type === "subSpaceGranted"
            ? props.dictionary.s("accessGranted")
            : type === "subSpaceDenied"
              ? props.dictionary.s("accessDenied")
              : type === "subSpaceDeleted"
                ? props.dictionary.s("subSpaceDeleted")
                : type === "spaceDeleted"
                  ? props.dictionary.s("spaceDeleted")
                  : null}
        </span>
      </div>
      {type !== "subSpaceDeleted" || type !== "spaceDeleted" ? (
        <div className="error-message-des-text">
          {type === "subSpaceGranted"
            ? (<div className="subspace-style-text">
              {props.dictionary.s("youhavecheckedintosubspaceInnovation")}
              <span className="subspace-name-bold">'{text}'.</span>
            </div>)
            : type === "subSpaceDenied"
              ? (<div className="subspace-style-text">
                {props.dictionary.s('theSubspace')}
                <span className="subspace-name-bold">'{text}'</span>
                {props.dictionary.s('maxCapacityOrNotAuthorized')}
              </div>)
              : null}
        </div>
      ) : null}

      {type === "subSpaceDeleted" || type === "spaceDeleted" ?
        <div className="error-message-deletespace-text">
          {text}
        </div>
        : null}
    </>
  );

  const subSpaceCheckout = text => (
    <>
      <div className="error-message-des-text">
        {props.dictionary.s("youhavecomeoutsubspace")}
        <span className="subspace-name-bold">'{text}'</span>
      </div>
    </>
  );

  const getScannerData = async code => {
    if (props.checkInByCheckpoint) {
      props.onSetCheckinByCheckpoint(false);
    }
    const qrCode = code.split("||");
    if (qrCode[0]) {
      switch (qrCode[0]) {
        case "safelyPass": {
          const userId = qrCode[1];
          const workSpaceId = qrCode[2] || props.workSpaceId;
          const workSpace = props.workspaces.find(x => x._id === workSpaceId);
          const { selfDeclaration, verificationPoint } = workSpace.settings;
          if (workSpace) {
            const roleId = getRoleById(props.userId, workSpace);
            if (roleId === roles.USER || roleId === roles.VISITOR) {
              props.closeQrScannerVisible();
              let errorMessage = props.dictionary.s(
                "permissionsareneededtoscanauserSafelyPass"
              );
              props.setMessageValue(errorMessage);
              props.setWorkspaceExistModal(true);
            } else {
              let response;
              props.closeQrScannerVisible();
              const { data, status } = await props.onCheckWorkspaceIsDisabled(userId, workSpaceId, '/workspacelist');
              const userIsDisabled = (status && data) ? data.disabled : false;
              if (!userIsDisabled) {
                switch (true) {
                  case !selfDeclaration && verificationPoint:
                    response = await props.onRequestAccessAuthorization(
                      workSpaceId,
                      userId
                    );
                    break;
                  default:
                    response = await props.onGetLastAccessAuthorization(
                      workSpaceId,
                      userId
                    );
                    break;
                }
                if (response.status) {
                  await ReadQRCodeUserFlow(response);
                } else {
                  const responseData = await safelyPassIsActivated(userId);
                  if (responseData) {
                    let errorMessage = props.dictionary.s(
                      "safelyPassAlreadyActivated"
                    );
                    props.setMessageValue(errorMessage);
                    props.setWorkspaceExistModal(true);
                  } else {
                    let errorMessage = props.dictionary.s(
                      "permissionsareneededtoscanauserSafelyPass"
                    );
                    props.setMessageValue(errorMessage);
                    props.setWorkspaceExistModal(true);
                  }
                }
              }
            }
          } else {
            props.closeQrScannerVisible();
            let errorMessage = props.dictionary.s(
              "permissionsareneededtoscanauserSafelyPass"
            );
            props.setMessageValue(errorMessage);
            props.setWorkspaceExistModal(true);
          }
          break;
        }
        case "space":
          await readQRWorkspace(qrCode[1]);
          props.closeQrScannerVisible();
          break;
        case "subSpace":
          props
            .onpostSubspaceGrantaccess(
              qrCode[1],
              props.userId,
              qrCode[2],
              props.accesstokenidp
            )
            .then(responce => {
              props.closeQrScannerVisible();
              let errorMessage = "";
              if (responce.status) {
                if (
                  responce &&
                  responce.data &&
                  responce.data.granted === true
                ) {
                  errorMessage = showMessage(qrCode[3], "subSpaceGranted");
                } else if (
                  responce &&
                  responce.data &&
                  responce.data.checkout === true
                ) {
                  errorMessage = subSpaceCheckout(qrCode[3]);
                } else {
                  errorMessage = showMessage(qrCode[3], "subSpaceDenied");
                }
              } else if (
                responce.status === false &&
                responce.httpCode === 404
              ) {
                errorMessage = showMessage(
                  responce.axActions[0][0].message,
                  "subSpaceDeleted"
                );
              }
              props.setMessageValue(errorMessage);
              props.setWorkspaceExistModal(true);
            })
            .catch(error => {
              props.closeQrScannerVisible();
              const errorMessage = showMessage(qrCode[3], "subSpaceDenied");
              props.setMessageValue(errorMessage);
              props.setWorkspaceExistModal(true);
            });
          break;
        case "transactionform":
          const transactionId = qrCode[1];
          const response = await props.onGetTransaction(transactionId);
          props.closeQrScannerVisible();
          if (response.status) {
            props.history.push("/executeTransaction");
          }
          break;
        default:
          showErrorMessage();
          break;
      }
    }
  };

  const renderQRModal = () => (
    <BarcodeScanner
      visible={props.visibleScanner}
      onBarcodeRead={async code => {
        await getScannerData(code);
      }}
      onCancel={() => {
        props.closeQrScannerVisible();
      }}
    />
  );
  return (
    <>
      {renderQRModal()}
    </>
  );
};

const mapStateToProps = state => {
  const workSpaceId = state.workspace.workspaceFocused._id;
  const checkPoint = state.workspace.workspaceFocused.length > 0 ? state.workspace.workspaceFocused.checkPointChecklist[0] : null;
  return {
    workSpaceId,
    workspaces: state.workspace.list,
    userId: state.user.id,
    checkPointInputs: checkPoint ? checkPoint.inputs : null,
    loading: state.operation.loading,
    message: state.operation.message,
    accesstokenidp: state.user.accesstokenidp,
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    },
    checkInByCheckpoint: state.operation.checkInByCheckpoint
  };
};

const mapDispatchToProps = dispatch => ({
  onGetLastAccessAuthorization: (workSpaceId, userId) =>
    dispatch(getLastAccessAuthorization(workSpaceId, userId)),
  onGetUserProfileById: userId => dispatch(getUserProfileById(userId)),
  onSetCheckPoint: checkPoint => dispatch(setCheckPoint(checkPoint)),
  onSetMessage: message => dispatch(setMessage(message)),
  onGetAccessAuthorizationActiveByUserId: userId =>
    dispatch(getAccessAuthorizationActivesList(userId)),
  onAddUserToWorkspace: (userId, workSpaceId, accesstokenidp) =>
    dispatch(addUserToWorkspace(userId, workSpaceId, accesstokenidp)),
  onAddWorkSpaceToList: workspace => dispatch(addWorkspaceToList(workspace)),
  onpostSubspaceGrantaccess: (
    workSpaceId,
    userId,
    subspaceId,
    accesstokenidp
  ) =>
    dispatch(
      postSubspaceGrantaccess(workSpaceId, userId, subspaceId, accesstokenidp)
    ),
  onSetWorkspaceFocused: workspace => dispatch(setWorkspaceFocused(workspace)),
  onRequestAccessAuthorization: (
    workSpaceId,
    userId,
    userVerificationId,
    deviceId
  ) =>
    dispatch(
      requestAccessAuthorization(
        workSpaceId,
        userId,
        userVerificationId,
        deviceId
      )
    ),
  onSetCheckinByCheckpoint: (value) => dispatch(setCheckinByCheckpoint(value)),
  onCheckWorkspaceIsDisabled: (userId, workspaceId, redirect) => dispatch(checkWorkspaceIsDisabled(userId, workspaceId, redirect)),
  onGetTransaction: (transactionId) => dispatch(getTransaction(transactionId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyBarcodeScanner));