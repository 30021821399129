import {
  OPERATION_USER_VERIFICATION_POST,
  OPERATION_USER_VERIFICATION_POST_SUCCESS,
  OPERATION_USER_VERIFICATION_POST_FAIL,
  OPERATION_CHECK_POINT_POST,
  OPERATION_CHECK_POINT_POST_SUCCESS,
  OPERATION_CHECK_POINT_POST_FAIL,
  OPERATION_LAST_VERIFIED_GET,
  OPERATION_LAST_VERIFIED_GET_SUCCESS,
  OPERATION_LAST_VERIFIED_GET_FAIL,
  OPERATION_USER_VERIFICATION_CHANGE_INPUT,
  OPERATION_CHECK_POINT_CHANGE_INPUT,
  OPERATION_SET_USER_VERIFICATION,
  OPERATION_SET_CHECK_POINT,
  OPERATION_SET_MESSAGE,
  OPERATION_CHECK_OUT,
  OPERATION_CHANGE_SAFELY_PASS_STATUS,
  OPERATION_ACCESS_AUTHORIZATION_ACTIVES_LIST_REQUEST,
  OPERATION_ACCESS_AUTHORIZATION_ACTIVES_LIST_SUCCESS,
  OPERATION_ACCESS_AUTHORIZATION_ACTIVES_LIST_FAIL,
  OPERATION_ACCESS_AUTHORIZATION_REQUEST,
  OPERATION_ACCESS_AUTHORIZATION_DENIED_REQUEST,
  OPERATION_ACCESS_AUTHORIZATION_GRANT_REQUEST,
  OPERATION_ACCESS_AUTHORIZATION_GRANT_SUCCESS,
  OPERATION_ACCESS_AUTHORIZATION_GRANT_FAIL,
  OPERATION_ACCESS_AUTHORIZATION_DENIED_SUCCESS,
  OPERATION_ACCESS_AUTHORIZATION_DENIED_FAIL,
  OPERATION_ACCESS_AUTHORIZATION_REQUEST_SUCCESS,
  OPERATION_ACCESS_AUTHORIZATION_REQUEST_FAIL,
  OPERATION_CHECK_OUT_FAIL,
  OPERATION_ACCESS_AUTHORIZATION_UNREGISTERED_REQUEST,
  OPERATION_GET_CHECKOUT_TIME_REQUEST,
  OPERATION_GET_CHECKOUT_TIME_SUCCESS,
  OPERATION_GET_CHECKOUT_TIME_FAIL,
  OPERATION_REFRESH_CHECK_IN_REGISTER_SCREEN,
  OPERATION_ACCESS_AUTHORIZATION_ACTIVE_LIST_REQUEST,
  OPERATION_GET_PAGINATED_USERS_SUCCESS,
  OPERATION_GET_PAGINATED_USERS_FAILURE,
  OPERATION_GET_PAGINATED_USERS_REQUEST,
  OPERATION_GET_USERS_FOR_CHECKPOINT,
  OPERATION_GET_USERS_FOR_CHECKPOINT_SUCCESS,
  OPERATION_GET_USERS_FOR_CHECKPOINT_FAIL,
  OPERATION_SET_ACTIVE_KEY,
  OPERATION_SET_CHECK_IN_BY_CHECKPOINT
} from "./types";
import { dictionary } from "../../general/global";
import { safelyApi } from "../../api/safely";
import ApiEndPoint from "../../constants/apiEndPoint";

export const setUserVerification = (userVerification) => (dispatch) =>
  dispatch({
    type: OPERATION_SET_USER_VERIFICATION,
    payload: userVerification,
  });

export const addUserVerification = (userId, workSpaceId, inputValues, passGenerated) => async (
  dispatch
) => {
  try {
    dispatch({ type: OPERATION_USER_VERIFICATION_POST, payload: {} });
    if (inputValues.find((x) => x.required === true && x.inputValues === "")) {
      dispatch({
        type: OPERATION_USER_VERIFICATION_POST_FAIL,
        payload: { message: dictionary.s("completeAllFields") },
      });
      return null;
    }
    const response = await safelyApi("/operation", {
      method: "post",
      body: { userId, workSpaceId, inputValues, type: "userverification", passGenerated }
    });
    if (response.status) {
      dispatch({ type: OPERATION_USER_VERIFICATION_POST_SUCCESS, payload: response });
    }
    else {
      dispatch({
        type: OPERATION_USER_VERIFICATION_POST_FAIL, payload: {
          message: response.error_description || response.systemError,
          ...response,
        },
      });
    }
    return response;
  } catch (e) {
    dispatch({ type: OPERATION_USER_VERIFICATION_POST_FAIL, payload: e });
    return { e, status: false };
  }
};

export const addCheckPoint = (userId, workSpaceId, inputValues, passGenerated) => {
  return async (dispatch) => {
    try {
      dispatch({ type: OPERATION_CHECK_POINT_POST, payload: {} });
      if (
        inputValues.find((x) => x.required === true && x.inputValues === "")
      ) {
        dispatch({
          type: OPERATION_CHECK_POINT_POST_FAIL,
          payload: { message: dictionary.s("completeAllFields") },
        });
        return null;
      }

      const response = await safelyApi("/operation", {
        method: "post",
        body: { userId, workSpaceId, inputValues, type: "checkpoint", passGenerated },
      });
      if (response.status) {
        dispatch({
          type: OPERATION_CHECK_POINT_POST_SUCCESS,
          payload: response,
        });
      }
      else {
        dispatch({
          type: OPERATION_CHECK_POINT_POST_FAIL,
          payload: {
            message: response.error_description || response.systemError,
            ...response,
          },
        });
      }
      return response;
    } catch (e) {
      dispatch({ type: OPERATION_CHECK_POINT_POST_FAIL, payload: e });
      return { e, status: false };
    }
  };
};

export const grantAccessAuthorization = (id, checkPointId, monitorId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: OPERATION_ACCESS_AUTHORIZATION_GRANT_REQUEST, payload: {} });
      const response = await safelyApi('/accessauthorization/grant',
        {
          method: 'post',
          body: {
            id, checkPointId, monitorId
          }
        });
      if (response.status) {
        dispatch({ type: OPERATION_ACCESS_AUTHORIZATION_GRANT_SUCCESS, payload: { response } });
      } else {
        dispatch({ type: OPERATION_ACCESS_AUTHORIZATION_GRANT_FAIL, payload: { message: response.description || response.error_description || response.systemError } });
      }
      return response;
    } catch (error) {
      dispatch({ type: OPERATION_ACCESS_AUTHORIZATION_GRANT_FAIL, payload: { message: error } });
    }
  }
}

export const deniedAccessAuthorization = (id, checkPointId, monitorId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: OPERATION_ACCESS_AUTHORIZATION_DENIED_REQUEST, payload: {} });
      const response = await safelyApi('/accessauthorization/denied',
        {
          method: 'post',
          body: {
            id, checkPointId, monitorId
          }
        });
      if (response.status) {
        dispatch({ type: OPERATION_ACCESS_AUTHORIZATION_DENIED_SUCCESS, payload: {} });
      } else {
        dispatch({ type: OPERATION_ACCESS_AUTHORIZATION_DENIED_FAIL, payload: { message: response.description || response.error_description || response.systemError } });
      }
      return response;
    } catch (error) {
      //console.log(error);
      dispatch({ type: OPERATION_ACCESS_AUTHORIZATION_DENIED_FAIL, payload: { error } });
      return error;
    }
  }
}

export const valueChangeInputUserVerification = (id, value) => (dispatch) =>
  dispatch({
    type: OPERATION_USER_VERIFICATION_CHANGE_INPUT,
    payload: { id, value },
  });

export const changeSafelyPassStatus = (status, extraInfo = {}) => (dispatch) => {
  dispatch({ type: OPERATION_CHANGE_SAFELY_PASS_STATUS, payload: { status, extraInfo } });
}
export const setCheckPoint = (checkPoint) => (dispatch) =>
  dispatch({ type: OPERATION_SET_CHECK_POINT, payload: checkPoint });

export const setMessage = (message) => (dispatch) =>
  dispatch({ type: OPERATION_SET_MESSAGE, payload: message });

export const valueChangeInputCheckPoint = (id, value) => (dispatch) =>
  dispatch({
    type: OPERATION_CHECK_POINT_CHANGE_INPUT,
    payload: { id, value },
  });

export const checkOut = (id) => async (dispatch) => {
  try {
    if (id !== '') {
      const response = await safelyApi('/accessauthorization/checkout',
        {
          method: 'post',
          body: { id }
        });
      if (response.status) dispatch({ type: OPERATION_CHECK_OUT, payload: {} });
      else dispatch({ type: OPERATION_CHECK_OUT_FAIL, payload: {} });
      return response;
    } else dispatch({ type: OPERATION_CHECK_OUT_FAIL, payload: {} });
  } catch (error) {
    return error;
  }
}



export const getAccessAuthorizationActivesList = userId => {
  return async (dispatch) => {
    try {
      dispatch({ type: OPERATION_ACCESS_AUTHORIZATION_ACTIVE_LIST_REQUEST, payload: {} });
      const response = await safelyApi('/accessauthorization/list/actives/' + userId,
        { method: 'get' });
      return response;
    } catch (error) {
      return error;
    }
  }
}

export const requestAccessAuthorization = (workSpaceId, userId, userVerificationId, deviceId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: OPERATION_ACCESS_AUTHORIZATION_REQUEST, payload: {} });
      const requestAccessResponse = await safelyApi('/accessauthorization/request',
        {
          method: 'post',
          body: {
            workSpaceId, userId, userVerificationId, deviceId
          }
        }
      )
      const response = { ...requestAccessResponse, data: Array.isArray(requestAccessResponse.data) ? requestAccessResponse.data[0] : requestAccessResponse.data };
      if (response) dispatch({ type: OPERATION_ACCESS_AUTHORIZATION_REQUEST_SUCCESS, payload: response });
      else dispatch({ type: OPERATION_ACCESS_AUTHORIZATION_REQUEST_FAIL, payload: { message: response.description || response.error_description || response.systemError } });

      return response;
    } catch (e) {
      return e;
    }
  }
}

export const getLastAccessAuthorization = (workSpaceId, userId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: OPERATION_LAST_VERIFIED_GET, payload: {} });
      const response = await safelyApi('/accessauthorization/last',
        {
          method: 'post', body: { workSpaceId, userId }
        }
      )
      if (response.status) {
        dispatch({ type: OPERATION_LAST_VERIFIED_GET_SUCCESS, payload: response });
      } else {
        dispatch({ type: OPERATION_LAST_VERIFIED_GET_FAIL, payload: { message: response.description || response.error_description || response.systemError } });
      }

      return response;
    } catch (e) {
      //console.log(e);
      return e
    }
  }
}

export const getAccessAuthorizationActivesListByWorkSpaceIds = workSpacesIds => {
  return async (dispatch) => {
    try {
      dispatch({ type: OPERATION_ACCESS_AUTHORIZATION_ACTIVES_LIST_REQUEST, payload: {} });
      const response = await safelyApi('/accessauthorization/list/actives/byworkspacesids/?workSpaceId=' + workSpacesIds.join('&workSpaceId='),
        { method: 'get' }
      )
      if (response.status) { dispatch({ type: OPERATION_ACCESS_AUTHORIZATION_ACTIVES_LIST_SUCCESS, payload: response }); }
      else { dispatch({ type: OPERATION_ACCESS_AUTHORIZATION_ACTIVES_LIST_FAIL, payload: { message: response.description || response.error_description || response.systemError } }); }
    } catch (e) {
      return e
    }
  }
}

export const unregisteredAccessAuthorization = (userId, workSpaceId, monitorId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: OPERATION_ACCESS_AUTHORIZATION_UNREGISTERED_REQUEST, payload: {} });
      const response = await safelyApi('/accessauthorization/unregistered',
        {
          method: 'post',
          body: { userId, workSpaceId, monitorId }
        })
      return response;
    } catch (e) {
      return e;
    }
  }
};


export const addUserTomulitSubSpace = (userId, workSpaceId, subspaceIds) => {
  return async (dispatch) => {
    try {
      const response = await safelyApi(ApiEndPoint.AdduserToMUltiSubWorkspace,
        {
          method: 'post',
          body: { userId, workSpaceId, subspaceIds }
        })
      return response;

    } catch (e) {
      return e;
    }
  }
};

export const removeUserFromMulitSubSpaces = (userId, workSpaceId, subspaceIds) => {
  return async (dispatch) => {
    try {
      const response = await safelyApi(ApiEndPoint.RemoveUserFromMultiSubSpaces,
        {
          method: 'post',
          body: { userId, workSpaceId, subspaceIds }
        })
      return response;

    } catch (e) {
      return e;
    }
  }
};

export const getCheckoutTimeByWorkSpaceIds = (workSpacesIds) => {
  return async (dispatch) => {
    try {
      dispatch({ type: OPERATION_GET_CHECKOUT_TIME_REQUEST, payload: {} });
      const response = await safelyApi('/accessauthorization/list/lastCheckOut/byworkspacesids/?workSpaceId=' + workSpacesIds.join('&workSpaceId='),
        {
          method: 'get'
        })
      if (response) { dispatch({ type: OPERATION_GET_CHECKOUT_TIME_SUCCESS, payload: response }); }
      else { dispatch({ type: OPERATION_GET_CHECKOUT_TIME_FAIL, payload: { message: response.description || response.error_description || response.systemError } }); }
    } catch (e) {
      return e;
    }
  }
};

export const onRefreshCheckInRegisterScreen = (refresh) => dispatch => dispatch({ type: OPERATION_REFRESH_CHECK_IN_REGISTER_SCREEN, payload: { refresh } })

export const getPaginatedUser = (body, page, limit, accesstokenidp) => {
  return async (dispatch) => {
    try {
      dispatch({ type: OPERATION_GET_PAGINATED_USERS_REQUEST });
      const response = await safelyApi('/workspace/userlist/byworkspacesids/' + page + '/' + limit, { body, method: 'post', headers: { accesstokenidp: accesstokenidp } });
      if (response.status) { dispatch({ type: OPERATION_GET_PAGINATED_USERS_SUCCESS, payload: { paginatedUsers: response.data, totalCount: response.totalCount } }); }
      else { dispatch({ type: OPERATION_GET_PAGINATED_USERS_FAILURE, payload: { message: response.error_description || response.systemError, ...response } }); }
      return response;
    } catch (e) {
      dispatch({ type: OPERATION_GET_PAGINATED_USERS_FAILURE, payload: e });
      return { e, status: false };
    }
  }
}

export const getUsersForCheckpoint = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: OPERATION_GET_USERS_FOR_CHECKPOINT, payload: {} });
      const { accesstokenidp } = getState().user;
      const response = await safelyApi(ApiEndPoint.getUsersForCheckpoint, {
        method: 'post',
        body,
        headers: { accesstokenidp: accesstokenidp }
      });

      if (response.status) {
        dispatch({ type: OPERATION_GET_USERS_FOR_CHECKPOINT_SUCCESS, payload: response });
      } else {
        dispatch({ type: OPERATION_GET_USERS_FOR_CHECKPOINT_FAIL, payload: { message: response.error_description || response.systemError, ...response } })
      }
      return response;
    } catch (e) {
      dispatch({ type: OPERATION_GET_USERS_FOR_CHECKPOINT_FAIL, payload: { message: e.message } })
      return { e, status: false };
    }
  }
}

export const setActiveKey = (key) => dispatch =>
  dispatch({ type: OPERATION_SET_ACTIVE_KEY, payload: key });

export const setCheckinByCheckpoint = (value) => dispatch =>
  dispatch({ type: OPERATION_SET_CHECK_IN_BY_CHECKPOINT, payload: value });