/*  Libraries */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";

/*  Configurations, Variables  */

/*  Components */
import MainNavigation from "../Navigation/MainNavigation";
import Post from "../Post/post";
import WorkspaceInfo from "./WorkspaceInfo";
import CheckOutActivePass from '../Post/CheckOutActivePass';
import { ReactComponent as SafelypassIcon } from "../../icons/safelypass.svg";
import { ReactComponent as TransactionIcon } from "../../icons/transaction_white.svg";
import { ReactComponent as InfoIcon } from "../../icons/Icon_Info.svg";

/*  Reducers */
import { translate } from "../../redux/reducers/languageReducer";

/*  Actions */
import { postViewData } from "../../redux/actions/postAction";
import { changeWorkspaceFocusedForSafelyPass, checkWorkspaceIsDisabled } from "../../redux/actions/workspaceActions";
import { changeSafelyPassStatus } from "../../redux/actions/operationActions";

const WorkspaceDetails = props => {
  const [showWorkInfo, setShowWorkInfo] = useState(false);
  const [showCheckOut, setshowCheckOut] = useState(false);
  const [deviceType, setdeviceType] = useState(window.deviceType);
  const [showPost, setShowPost] = useState(true);
  const [currentheight, setCurrentheight] = useState(window.innerHeight);

  useEffect(() => {
    function handleResize() {
      setdeviceType(window.deviceType);
      setCurrentheight(window.innerHeight);
      if (window.deviceType !== "desktop") {
        setShowWorkInfo(false);
      }
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const workspaceIsDisabled = async () => {
    const { data, status } = await props.onCheckWorkspaceIsDisabled(props.userId, props.workspaceFocused._id);
    if (status && data) return data.disabled;
    return false;
  }

  const PostViewData = async () => {
    await props.getPostViewData(
      props.workspaceFocused._id,
      props.userId,
      props.accesstokenidp
    );
  };

  const closeCheckOut = () => {
    setshowCheckOut(false);
  };

  const checkScroll = () => {
    let videos = document.getElementsByTagName("video");
    for (let i = 0; i < videos.length; i++) {
      var video = videos[i];
      video.pause();
    }
  };

  return (
    <MainNavigation title={props.title}>
      <Container fluid>
        <Row>
          <Col>
            <div className="safty-pass-block">
              <div className="safty-pass-text">
                {props.dictionary.s("workSpaceDetailsLabelOptions")}
              </div>
              <Button
                className="safty-pass-button"
                onClick={async () => {
                  if (!await workspaceIsDisabled()) {
                    if (!props.isSafelyPassActivated) {
                      if (
                        props.workspaceFocusedForSafelyPass._id !==
                        props.workspaceFocused._id
                      ) {
                        props.onChangeSafelyPassStatus("inactive");
                      }
                      const selectSpaceOld = props.workspacesOld.find(workspaceOld => workspaceOld._id === props.workspaceFocused._id);
                      props.onChangeWorkspaceFocusedForSafelyPass(selectSpaceOld);
                      props.history.replace("/safelypass");
                    }
                    if (props.isSafelyPassActivated) {
                      if (
                        props.workspaceFocusedForSafelyPass._id !==
                        props.workspaceFocused._id
                      ) {
                        setshowCheckOut(true);
                      } else {
                        props.history.replace("/safelypass");
                      }
                    }
                  }
                }}
              >
                <SafelypassIcon />
                {props.dictionary.s("generateASafelyPass")}
              </Button>
              <Button
                className="safty-pass-button"
                onClick={() => props.history.replace("/transactions")}
              >
                <TransactionIcon />
                {props.dictionary.s('transactionButton')}
              </Button>
              <CheckOutActivePass showCheckOutModel={showCheckOut} closeCheckOutModel={closeCheckOut} />
            </div>
          </Col>
        </Row>
        <InfiniteScroll
          className="infiniteScrollpost"
          dataLength={props.postData.length}
          next={props.postData}
          height={currentheight - 220}
          endMessage={null}
          refreshFunction={PostViewData}
          pullDownToRefresh
          onScroll={() => {
            checkScroll();
          }}
        >
          <Row>
            {deviceType === "desktop" || showPost ? (
              <Col
                xs={12}
                sm={12}
                lg={7}
                md={12}
                xl={7}
              >
                <div className="safty-pass-text-title">
                  {props.dictionary.s("post")}
                  {deviceType !== "desktop" && showPost ? (
                    <span className="space-navegation-tap">
                      <InfoIcon
                        onClick={() => {
                          setShowPost(false);
                        }}
                      />
                    </span>
                  ) : null}
                </div>
                <Post />
              </Col>
            ) : null}
            {deviceType === "desktop" || !showPost ? (
              <Col
                xs={12}
                sm={12}
                lg={5}
                md={12}
                xl={5}
              >
                <div className="space-info-continer">
                  <div
                    className={
                      showWorkInfo
                        ? "space-info-text space-info-open"
                        : "space-info-text "
                    }
                  >
                    {deviceType !== "desktop" ? (
                      <i
                        className="fa fa-arrow-left header-back-button spaceinfoback"
                        onClick={() => {
                          setShowPost(true);
                        }}
                      ></i>
                    ) : null}
                    {props.dictionary.s("spaceInfo")}
                    {deviceType === "desktop" ? (
                      <span
                        className={
                          showWorkInfo ? "arrowIcondown" : "arrowIconup"
                        }
                        onClick={() => {
                          setShowWorkInfo(!showWorkInfo);
                        }}
                      ></span>
                    ) : null}
                  </div>
                  <div className="space-info-block">
                    {showWorkInfo ? null : <WorkspaceInfo />}
                  </div>
                </div>
              </Col>
            ) : null}
          </Row>
        </InfiniteScroll>
      </Container>
    </MainNavigation >
  );
};

const mapStateToProps = state => {
  const user = state.user;
  return {
    userId: user.id,
    accesstokenidp: state.user.accesstokenidp,
    workspaceFocused: state.workspace.spaceFocused,
    workspacesOld: state.workspace.list,
    workspaceFocusedForSafelyPass:
      state.workspace.workspaceFocusedForSafelyPass,
    postData: state.posts.postData,
    operation: state.operation,
    isSafelyPassActivated: state.operation.safelyPassStatus === "granted",
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    }
  };
};

const mapDispatchToProps = dispatch => ({
  getPostViewData: (workspaceId, userId, accesstokenidp) =>
    dispatch(postViewData(workspaceId, userId, accesstokenidp)),
  onChangeWorkspaceFocusedForSafelyPass: workspace =>
    dispatch(changeWorkspaceFocusedForSafelyPass(workspace)),
  onChangeSafelyPassStatus: status => dispatch(changeSafelyPassStatus(status)),
  onCheckWorkspaceIsDisabled: (userId, workspaceId) =>
    dispatch(checkWorkspaceIsDisabled(userId, workspaceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceDetails);