export const exportReportTypes = {
    ActivatedSafelyPasses: 'ACTIVATED_SAFELY_PASS',
    SafelyPassesRejected: 'REJECTED_SAFELY_PASS',
    CheckedInUsingRegister: 'CHECKIN_USING_REGISTER',
    selfDeclarationReport: 'DECLARATION_FORM',
    verificationFormsReport: 'VERIFICATION_FORM',
    SubspacesHistory: 'SUBSPACE_HISTORY',
    UserSubspacesHistory: 'SUBSPACE_HISTORY',
    SubspaceActivity: 'SUBSPACE_ACTIVITY',
    SafelyPassesDisabled: 'DISABLED_SAFELY_PASS',
    transactionsHistory: 'WORKSPACE_TRANSACTION',
};