import React, { useState } from "react";
import { connect } from "react-redux";
import { translate } from "../../redux/reducers/languageReducer";
import Modal from "../commons/wrapped-components/Modal";
import InputImageEditor from "../commons/wrapped-components/inputs/InputImageEditor";
import Addpost from "./Addpost";

const EditImage = props => {
  const [showModalAddpost, setShowModalAddpost] = useState(false);
  const [dataImage, setDataImage] = useState(null);
  const closeAddpostseEditImage = () => {
    setShowModalAddpost(false);
  };
  const renderModalEditImage = () => (
      <Modal
        dialogClassName="photoOrVedioPopup editimagepopup"
        showModal={props.showModalEditImage}
        title={props.dictionary.s("editPhoto")}
        onHide={() => props.onCloseEditImage()}
        properties={{
          centered: true,
          keyboard: false,
          autoFocus: false,
            enforceFocus: false,
            size: 'lg'
        }}
        body={
          <div>
          <InputImageEditor
            loadImage={
              props.image && {
                path: props.image,
                name: "image"
              }
            }
            crossOrigin={true}
            menu={["crop",'rotate','text','draw']}
            menuBarPosition="bottom"
            theme="whiteTheme"
            onSave={res => {
              setDataImage(res);
              props.onCloseEditImage();
              setShowModalAddpost(true);
            }}
            onCancel={props.onCloseEditImage}
            textButtonSave={props.dictionary.s("next")}
            textButtonCancel={props.dictionary.s("cancel")}
          />
          </div>
        }
      />
  );
 
  return (
    <div>
   <Addpost showModalAddpost={showModalAddpost} data={dataImage} type="image" onCloseAddpost={closeAddpostseEditImage}/>
    {renderModalEditImage()}
    </div>
    );
};

const mapStateToProps = state => {
  return {
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    }
  };
};

export default connect(mapStateToProps, null)(EditImage);
