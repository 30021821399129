import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { translate } from "../../../redux/reducers/languageReducer";
import Modal from "./Modal";
import QRCode from "./QRCode";
import ReactToPdf from "react-to-pdf";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";

const ref = React.createRef();

const DownloadQRCode = ({
    showQRModal,
    closeModalQR,
    pdfTitle,
    dictionary,
    containerStyle,
    fileName,
    value
}) => {
    const options = {
        orientation: "landscape",
        format: [375, 375]
    };

    const [deviceType, setdeviceType] = useState(window.deviceType);
    useEffect(() => {
        function handleResize() {
            setdeviceType(window.deviceType);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const dataURLToBlob = dataURL => {
        let BASE64_MARKER = ";base64,";
        let parts;
        let contentType;
        let raw;

        if (dataURL.indexOf(BASE64_MARKER) === -1) {
            parts = dataURL.split(",");
            contentType = parts[0].split(":")[1];
            raw = decodeURIComponent(parts[1]);

            return new Blob([raw], { type: contentType });
        }

        parts = dataURL.split(BASE64_MARKER);
        contentType = parts[0].split(":")[1];
        raw = window.atob(parts[1]);
        var rawLength = raw.length;
        var uInt8Array = new Uint8Array(rawLength);

        for (var i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], { type: contentType });
    };

    return (
        <Modal
            className="animated fadeInDown qrCodePopUP"
            showModal={showQRModal}
            onSave={() => {
                if (deviceType === "desktop") {
                    document.getElementById("downLoadAsPdf").click();
                } else {
                    html2canvas(document.querySelector("#qrCodeGenarator"), {
                        onclone: function (document) {
                            document.querySelector("#qrCodeGenarator").style.marginTop =
                                "120px";
                        },
                        height: 750
                    }).then(function (canvas) {
                        if (!navigator.userAgent.match("CriOS")) {
                            const fileBlob = dataURLToBlob(canvas.toDataURL("image/jpeg"));
                            saveAs(fileBlob, fileName + "QRcode.jpg");
                        } else {
                            canvas.toBlob(function (blob) {
                                let url = window.URL.createObjectURL(blob);
                                window.open(url, "_blank");
                            });
                        }
                    });
                }
            }}
            onHide={() => closeModalQR()}
            onClose={() => {
                closeModalQR();
            }}
            textButtonSave={dictionary.s("download")}
            textButtonClose={dictionary.s("close")}
            bsStyleButtonSave="primary download"
            propertyButtonSave={{
                className: "border-radius-none"
            }}
            buttonCloseProperties={{
                variant: "light"
            }}
            properties={{
                centered: true
            }}
            body={
                <div>
                    <button
                        type="button"
                        className="close"
                        onClick={() => closeModalQR()}
                    >
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                    <div className="generateQrCode" ref={ref} id="qrCodeGenarator">
                        <div className="modal-header">
                            <div className="modal-title h4">{pdfTitle}</div>
                        </div>
                        <div
                            className="Box-containerQR"
                            style={{ ...containerStyle }}
                        >
                            <div
                                className="containerQR"
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center"
                                }}
                            >
                                <em></em>
                                <QRCode
                                    value={value}
                                    styles={{ borderRadius: "10px", margin: "20px", opacity: 1 }}
                                />
                            </div>
                        </div>
                    </div>
                    {deviceType === "desktop" ? (
                        <ReactToPdf
                            targetRef={ref}
                            filename={fileName + "QRcode.pdf"}
                            options={options}
                        >
                            {({ toPdf }) => (
                                <div
                                    className="downLoadAsPdf"
                                    onClick={toPdf}
                                    id="downLoadAsPdf"
                                ></div>
                            )}
                        </ReactToPdf>
                    ) : null}
                </div>
            }
        />
    )
}

const mapStateToProps = state => {
    return {
        dictionary: {
            s: key => translate(state.language.dictionary, key)
        }
    };
};

export default connect(mapStateToProps, null)(DownloadQRCode);
