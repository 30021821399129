export default {
    apiKey: "AIzaSyDhPBSiNOix64MHdGy0Jo-m3IIZdVczpiE",
    authDomain: "safelyappdevelop.firebaseapp.com",
    databaseURL: "https://safelyappdevelop.firebaseio.com",
    projectId: "safelyappdevelop",
    storageBucket: "safelyappdevelop.appspot.com",
    messagingSenderId: "562163401857",
    appId: "1:562163401857:web:3140612550b9d021883980",
    measurementId: "G-2F85TY9SCG",
    pairKey: "BDx0Hf_NHnOMQimXKCpj3aoDSvevoyje5O7lEQOlz6r2RKPpOxsYrI-9mFhbsIbqSTTTL94tUD6AgCEGF_6ftsY"
};