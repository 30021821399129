import colorPalette from "../constants/colorPalette";

export const Principal = {
    label_font_size: "17px",
    label_font_family: "Roboto, Regular",
    label_color: "#6E7E88",
    input_height: "36px",
    heading_1: {
        color: colorPalette.PRIMARY_DARK,
        font_size: "22px",
        font_family: "Montserrat, bold",
        font_weight: "bold"
    },
    colorPalette: { ...colorPalette },
};