import {
    ROLE_GET_LIST,
    ROLE_GET_LIST_SUCESS,
    ROLE_GET_LIST_FAIL
} from './types';
import { safelyApi } from "../../api/safely";

export const getRolesList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: ROLE_GET_LIST, payload: {} });
            const response = await safelyApi('/role/list', { method: 'get' });
            if (response.status) { dispatch({ type: ROLE_GET_LIST_SUCESS, payload: response });}
            else { dispatch({ type: ROLE_GET_LIST_FAIL, payload: { message: response.error_description || response.systemError, ...response } }); }
            return response;
        } catch (e) {
            dispatch({ type: ROLE_GET_LIST_FAIL, payload: e });
            return { e, status: false };
        }
    }
}