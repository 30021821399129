import React, { Component } from "react";
import MainNavigation from "../Navigation/MainNavigation";
import { withRouter } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { translate } from "../../redux/reducers/languageReducer";
import { general } from "../../general/global";

import Report from "./ReportGenerator"

class SubspaceReports extends Component {
    constructor(props) {
        super(props);
        const activeKey = props.match.params.reportKey || 'activity';
        this.state = {
            activeKey
        }
    }

    render() {
        const { isAdminOrMonitor } = this.props;
        const { activeKey } = this.state;
        if (isAdminOrMonitor) {
            return (
                <MainNavigation title={this.props.title}>
                    <span className="reports-header">{this.props.dictionary.s('subspaces')}</span>
                    <Tabs id="reports-tabs" activeKey={activeKey} variant="pills" className="tabs-container"
                        onSelect={eventKey => {
                            this.props.history.push(`/subspacesReport/${eventKey}`);
                            this.setState({ activeKey: eventKey });
                        }}
                    >
                        <Tab tabClassName="tab-reports" eventKey="activity" title={this.props.dictionary.s('SubspacesActivity')} >
                            <Report type='SubspacesActivity' onlySearchBox exportButton={false} searchBy={'subspaceName'} />
                        </Tab>
                        <Tab tabClassName="tab-reports" eventKey="history" title={this.props.dictionary.s('SubspacesHistory')} >
                            <Report type='SubspacesHistory' searchBy={['user_name', 'user_lastName', 'subspace_name', 'accessGranted']} />
                        </Tab>
                    </Tabs>
                </MainNavigation>
            );
        }
        return (
            <MainNavigation title={this.props.title}>
                <Report type='UserSubspacesHistory' searchBy={['user_name', 'user_lastName', 'subspace_name', 'accessGranted']} />
            </MainNavigation>
        );
    }
}

const mapStateToProps = state => {
    const { user } = state;
    const { workspaceFocused } = state.workspace;
    const isAdminOrMonitor = general.isAdminOrMonitor(user.id, workspaceFocused);
    return {
        isAdminOrMonitor,
        dictionary: {
            s: key => translate(state.language.dictionary, key)
        }
    }
};
export default connect(mapStateToProps, null)(withRouter(SubspaceReports));