/*  Libraries */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";

/*  Configurations, Variables  */
import { general } from "../../general/global";
import { str_truncate } from "../../general/general";
import * as toastType from "../../constants/toastTypes";

/*  Components */
import MainNavigation from "../Navigation/MainNavigation";
import Label from "../commons/styled-components/general/Label";
import Modal from "../commons/wrapped-components/Modal";
import Avatar from "../commons/wrapped-components/Avatar";
import UserProfile from "./userProfile";
import InputSearch from "../commons/wrapped-components/inputs/InputSearch";
import UserProfileBackground from './userProfileBarckground';
import MyBarcodeScanner from "../commons/MyBarcodeScanner";
import SpaceDeletedPopup from "./spaceDeletedErrorPopup";
import { showToast } from "../commons/Toast.js";
import RenderSpaces from "./RenderSpaces";

/*  Reducers */
import { translate } from "../../redux/reducers/languageReducer";

/*  Actions */
import {
  getWorkspacesList,
  addUserToWorkspace,
  changeWorkspaceFocusedForSafelyPass,
  getCheckIntoSusbpaceList,
  getActivesSubspaceCheckIn,
  getWorkspaceListWithPagination
} from "../../redux/actions/workspaceActions";
import {
  getAccessAuthorizationActivesList,
  checkOut,
  changeSafelyPassStatus,
} from "../../redux/actions/operationActions";
import { setMessage } from "../../redux/actions/magicLinkAction";
import { changedMagicLinkCode } from "../../redux/actions/onboardingActions";
import { getMagicLinkInfoByCode } from "../../redux/actions/magicLinkAction";
import { DesactiveToast } from "../../redux/actions/toastAction";

const WorkspaceList = props => {
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showDeletedPopup, setShowDeletedPopup] = useState(false);

  if (props.spacesListWithPagination === undefined) {
    props.history.push('/exit');
  }

  const handleOpenUrl = url => {
    if (!url) return;
    const params = general.hashUrl(url);
    joinToAWorkSpace(params["?i"]);
  };

  const loadData = async () => {
    await props.onGetWorkspaceListWithPagination();
  }

  useEffect(() => {
    checkIfThereAnySafelyPassActivated();
    if (props.isToastSuccess) showToast(toastType.SUCCESS, props.toastMessage);
    else if (props.isToastError) showToast(toastType.ERROR, props.toastMessage);
    props.onDesactiveToast();
    loadData();
  }, []);

  const checkIfThereAnySafelyPassActivated = async () => {
    props.onSetMessage(null);
    const { data } = await props.onGetWorkspacesList(
      props.userId,
      props.accesstokenidp
    );
    const workspaces = data;
    await joinToAWorkSpace(props.magicLinkCode);
    handleOpenUrl(window.location.search);
    const activeList = await props.onGetAccessAuthorizationActivesList(props.userId);
    isThereAnySafelyPassActivated(activeList, workspaces);
  };

  const isThereAnySafelyPassActivated = (activeList, workspaces) => {
    const { status, data } = activeList;
    if (status && data.length > 0) {
      const workspace = workspaces.find(x => x._id === data[0].workSpaceId);
      if (workspace) {
        const { accessGranted, grantDate, ...rest } = data[0];
        const safelyPassStatus = accessGranted ? "granted" : "inactive";
        props.onChangeSafelyPassStatus(safelyPassStatus, {
          accessGranted,
          grantDate: new Date(grantDate),
          ...rest
        });
        props.onChangeWorkspaceFocusedForSafelyPass(workspace);
        props.onGetCheckIntoSubspaceList(workspace._id, props.userId, props.accesstokenidp).then(response => { });
        props.onGetActivesSubspaceCheckIn(props.userId, props.accesstokenidp).then(response => { });
      }
    } else if (status && props.accessAuthorizationId && !data.length) {
      props.onCheckOut(props.accessAuthorizationId);
    }
  };

  const joinToAWorkSpace = async code => {
    if (!code) return;
    const response = await props.onGetMagicLinkInfoByCode(code);
    if (response.status) {
      setShowInviteModal(true);
    } else if (!response.status && response.systemError === "Magic link(s) not found") {
      setShowDeletedPopup(true);
    }
  };

  const isValid = !props.workspaces.find(
    x => x._id === props.invitation.workSpaceId
  );

  const onReject = () => {
    setShowInviteModal(false);
    props.onChangeMagicLinkCode(null);
    setTimeout(() => {
      window.location = window.location.origin;
    }, 1000);
  };

  const renderInvitationModal = () => (
    <Modal
      title={""}
      {...props.dictionary}
      className="animated fadeInDown renderInvitationModal"
      showModal={true}
      {...(isValid
        ? {
          onClose: () => onReject(),
          textButtonClose: props.dictionary.s("reject"),
          onSave: async () => {
            const { status } = await props.onAddUserToWorkspace(
              props.userId,
              props.invitation.workSpaceId,
              props.accesstokenidp
            );
            if (status) onReject();
            else setShowInviteModal(false);
          },
          textButtonSave: props.dictionary.s("joinNow"),
          bsStyleButtonSave: "primary",
          buttonCloseProperties: { variant: "light" },
          propertyButtonSave: { className: "border-radius-none" }
        }
        : {
          textButtonSave: props.dictionary.s("close"),
          propertyButtonSave: {
            className: "border-radius-none",
            variant: "light"
          },
          onSave: () => onReject()
        })}
      properties={{ centered: true }}
      body={
        <Row>
          <Col>
            <Col md={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
              <Avatar
                src={props.invitation.pictureURL}
                className="rounded-circle"
              />
            </Col>
            <Col className="text-center">
              <Label style={{ fontSize: 20, fontWeight: "bold" }}>
                {props.invitation.addedByFullName}
              </Label>
            </Col>
            <Col className="text-center">
              <Label>
                {props.dictionary.s(
                  isValid
                    ? "hasInviteYouToJoinTheSafely"
                    : "youAlreadyBelongToTheWorkspace"
                )}
              </Label>
            </Col>
            <Col className="text-center">
              <Label
                style={{ fontWeight: "bold" }}
                className="workSpaceName-label-renderInvitationModal"
              >
                {props.invitation.workSpaceName}
              </Label>
            </Col>
          </Col>
        </Row>
      }
    />
  );

  return (
    <MainNavigation title={props.title}>
      <Container fluid>
        <Row className="user-profile">
          <UserProfileBackground />
        </Row>
        <hr style={{ margin: 0 }} />
        <Row>
          <UserProfile />
        </Row>
        <RenderSpaces history={props.history} />
      </Container>
      {showInviteModal && renderInvitationModal()}
      <SpaceDeletedPopup
        showPopup={showDeletedPopup}
        closePopup={() => {
          setShowDeletedPopup(false);
          props.onChangeMagicLinkCode(null);
          setTimeout(() => {
            window.location = window.location.origin;
          }, 1000);
        }}
      />
    </MainNavigation >
  );
};

const mapStateToProps = state => {
  const user = state.user;
  return {
    userId: user.id,
    workspaces: state.workspace.list, //Change this in next prints
    magicLinkCode: state.onboarding.magicLinkCode,
    invitation: state.magicLink.invitation,
    accesstokenidp: state.user.accesstokenidp,
    accessAuthorizationId: state.operation.accessAuthorizationId,
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    },
    toastMessage: state.toast.message,
    isToastSuccess: state.toast.isSuccess,
    isToastError: state.toast.isError,
    spacesListWithPagination: state.workspace.spacesListWithPagination,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetWorkspacesList: (id, accesstokenidp) =>
    dispatch(getWorkspacesList(id, accesstokenidp)),
  onSetMessage: message => dispatch(setMessage(message)),
  onChangeMagicLinkCode: code => dispatch(changedMagicLinkCode(code)),
  onGetMagicLinkInfoByCode: code => dispatch(getMagicLinkInfoByCode(code)),
  onAddUserToWorkspace: (userId, workSpaceId, accesstokenidp) =>
    dispatch(addUserToWorkspace(userId, workSpaceId, accesstokenidp)),
  onGetAccessAuthorizationActivesList: userId =>
    dispatch(getAccessAuthorizationActivesList(userId)),
  onChangeSafelyPassStatus: (status, extraInfo) =>
    dispatch(changeSafelyPassStatus(status, extraInfo)),
  onCheckOut: id => dispatch(checkOut(id)),
  onDesactiveToast: () => dispatch(DesactiveToast()),
  onChangeWorkspaceFocusedForSafelyPass: workspace =>
    dispatch(changeWorkspaceFocusedForSafelyPass(workspace)),
  onGetCheckIntoSubspaceList: (workSpaceId, userId, accesstokenidp) =>
    dispatch(getCheckIntoSusbpaceList(workSpaceId, userId, accesstokenidp)),
  onGetActivesSubspaceCheckIn: (userId, accesstokenidp) =>
    dispatch(getActivesSubspaceCheckIn(userId, accesstokenidp)),
  onGetWorkspaceListWithPagination: () => dispatch(getWorkspaceListWithPagination()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceList);