import React, { useState, useEffect } from 'react';
import { general } from "../../general/global";

const Timer = ({ isActive, value, onFinish, timeout, timeLimit, isVisible }) => {
    const seconds = isActive ? general.diffSeconds(new Date(value), new Date()) : null;
    const [remainingSecs, setRemainingSecs] = useState(seconds);
    const { hours, mins, secs } = general.getRemaining(remainingSecs);

    useEffect(() => {
        const unsubscriberTime = subscribeTimer(isActive, remainingSecs);

        return () => unsubscriberTime();
    }, [isActive, remainingSecs]);

    const subscribeTimer = (getisActive, getremainingSecs) => {
        let interval = null;
        if (getisActive) {
            interval = setInterval(() => {
                if (getremainingSecs >= timeLimit) onFinish();
                else updateRemainingSecs();
            }, timeout);
        } else if (!getisActive && getremainingSecs !== 0) clearInterval(interval);

        return () => clearInterval(interval);
    }

    const updateRemainingSecs = () => setRemainingSecs(general.diffSeconds(value, new Date()));

    const styles = isVisible ? {} : { display: 'none' };

    return (
        <label
            className="timer-text"
            style={{ ...styles }}
        >{`${hours !== '00' ? hours + ':' : '00:'}${mins}:${secs}`}{' Hrs'}</label>
    )
}

Timer.defaultProps = {
    isActive: true,
    timeout: 1000,
    timeLimit: 43200, //12 hours
    onFinish: () => { },
    isVisible: true,
}

export default Timer;