import React from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";

const { Item } = Dropdown;

export const UserProfile = (props) => (
  <DropdownButton
    title={props.userImage}
    className="dropdown-user mr-2"
    drop="down"
    alignRight
  >
    {props.items
      ? props.items.map((item, index) => renderItem(item, index))
      : null}
    <Item href="#/exit">
      <i className="fa fa-sign-out"></i>
      {props.logout}
    </Item>
  </DropdownButton>
);

const renderItem = (item, index) => (
  <Item key={index} href={item.url}>
    <i className={item.iconClassName}></i> {item.text}
  </Item>
);

export const UserItem = Item;
