import React from 'react';
import { connect } from 'react-redux';
import { translate } from '../../../redux/reducers/languageReducer';

const MeasuringCircle = (props) => {
    const { width, height, total, used, dictionary } = props;
    const remaining = total - used;
    const percent = 100 / total * used;
    let availability = { color: '#0EA61D', text: dictionary.s('Available') };

    if (percent > 85) availability = { color: '#E67070', text: dictionary.s('Full') };
    else if (percent > 65) availability = { color: '#F19307', text: dictionary.s('AlmostFull') };
    // else if (percent > 25) color = 'success';

    return (
        <div className="measuringCircle">
            <div className="circleContainer" style={{ width: width, height: height }}>
                <span className="availability" style={{ color: availability.color }}>{remaining}</span>
                <div className="circle" style={{ color: availability.color }}></div>
            </div>
            <span className="text" style={{ color: availability.color }}>{availability.text}</span>
        </div >
    );
};

MeasuringCircle.defaultProps = {
    width: '44px',
    height: '44px',
    total: 100,
    used: 50
}

const mapStateToProps = (state) => {
    return {
        dictionary: {
            s: key => translate(state.language.dictionary, key)
        }
    }
}

export default connect(mapStateToProps, null)(MeasuringCircle);