import React from 'react';
import { connect } from "react-redux";
import Modal from "../commons/wrapped-components/Modal";
import { translate } from "../../redux/reducers/languageReducer";

const SpaceDeletedPopup = (props) => {
    const spaceDeleted = () => (
        <>
          <div>
            <div className="error-cricle">
              <i className="material-icons subspace-close-icon">close</i>
            </div>
            <span className="error-message-title-text">
              {props.dictionary.s("spaceDeleted")}
            </span>
          </div>
          <div className="error-message-deletespace-text">
            {props.dictionary.s("spaceHasBeenDeleted")}
          </div>
        </>
    );
    return (
        <Modal
        title=" "
        className="animated fadeInDown qrScannerErrorPopup"
        showModal={props.showPopup}
        onHide={props.closePopup}
        onSave={props.closePopup}
        textButtonSave={props.dictionary.s("close")}
        bsStyleButtonSave="primary cancel-button-qrcode"
        propertyButtonSave={{
            className: "border-radius-none",
            block: true
        }}
        properties={{
            centered: true
        }}
        body={spaceDeleted()}
        />
    );
}

const mapStateToProps = state => {
    return {
      dictionary: {
        s: key => translate(state.language.dictionary, key)
      },
    };
};

export default connect(mapStateToProps)(SpaceDeletedPopup);