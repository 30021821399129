import React, { useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import MainNavigation from "../Navigation/MainNavigation";
import { ReactComponent as SafelyLogo } from "../../icons/Safely-logo-Design-03.svg";
import { translate } from "../../redux/reducers/languageReducer";
import ScanUserID from "../checkpoint/ScanUserID";

const VerificationPoint = (props) => {
  const [showQrScanner, setQrScannerVisible] = useState(false);

  const renderHomeScreen = () => {
    return (
      <MainNavigation title={props.title}>
        <Container fluid>
          <Row className="text-center">
            <Col
              className="d-flex justify-content-center"
              xs={12}
              lg={12}
              sm={12}
              md={12}
              xl={12}
            >
              <div className="logo-container">
                <div className="outer-circle">
                  <div className="inner-circle">
                    <SafelyLogo />
                  </div>
                </div>
              </div>
            </Col>
            <Col
              xs={12}
              lg={{ span: 6, offset: 3 }}
              sm={{ span: 6, offset: 3 }}
              md={{ span: 6, offset: 3 }}
              xl={{ span: 6, offset: 3 }}
            >
              <div className="cta-container">
              <div>
                <Button
                  onClick={() => setQrScannerVisible(true)}
                  className="btn-primary-filled"
                >
                  {props.dictionary.s("scanQRCodeToActivateSafelySpace")}
                </Button>
                </div>
                <div>
                <Button
                  onClick={() => props.history.push("/checkInRegister")}
                  className="btn-primary-outline"
                >
                  {props.dictionary.s("checkinCheckoutRegister")}
                </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </MainNavigation>
    );
  };

  if(showQrScanner) {
    return <ScanUserID setQrScannerVisible={setQrScannerVisible}  history={props.history}/>
  } else {
    return renderHomeScreen();
  }

}
const mapStateToProps = (state) => {
  const workSpaceId = state.workspace.workspaceFocused._id;
  const checkPoint = state.workspace.workspaceFocused.checkPointChecklist[0];
  return {
    workSpaceId,
    workspaces: state.workspace.list,
    userId: state.user.id,
    checkPointInputs: checkPoint.inputs,
    loading: state.operation.loading,
    message: state.operation.message,
    dictionary: {
      s: (key) => translate(state.language.dictionary, key),
    },
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VerificationPoint);
