import React, { useEffect } from "react";
import { logout } from "../../redux/actions/loginActions";
import { connect } from "react-redux";
import { persistor } from "../../redux/store";
import { checkOut } from "../../redux/actions/operationActions"

const Logout = (props) => {

  const onLogout = async () => {
    if (props.accessAuthorizationId) await props.onCheckOut(props.accessAuthorizationId);
    persistor.purge();
    localStorage.removeItem("lang");
    props.logout();
    localStorage.clear();
    props.history.push("/");
  }

  onLogout();

  return <div>Logout...</div>;
};

const mapStateToProps = (state) => ({
  accessAuthorizationId: state.operation.accessAuthorizationId
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  onCheckOut: (id) => dispatch(checkOut(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
