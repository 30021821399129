import React from 'react';
import InputButtonGroup from './InputButtonGroup';
import * as inputTypes from "../../../../constants/inputTypes";
import Label from '../../styled-components/general/Label';

export const validate = (inputList) => {
    const inputs = inputList.filter(i => i.type === inputTypes.MULTIPLE);

    if (inputs.find(x => x.content?.options?.find(y => y.value === ''))) return 'addOptionLabel';
    else if (inputs.find(x => x.content?.options?.find(y => y.isInvalidWord === true))) return 'sameLabelDescription';

    return '';
};

export const GeneratorMultipleChoice = ({ id, label, options, onChangeOptions, onChangeText, dictionary }) => (
    <InputButtonGroup
        id={id}
        inputLabel={label}
        buttonList={options}
        setButtonList={onChangeOptions}
        onChangeText={onChangeText}
        dictionary={dictionary}
        minimumItemsTextError='minimumOptionValidation'
        optionsTitleText='optionsAndActions'
        addAnotherItemText='addAnotherOption'
        itemLabelExistsTextError='thisOptionLabelAlreadyExists'
        addItemLabelText='addOptionLabel'
        characterLimit={false}
    />
);

const MultipleChoice = ({ id, label, value, required, requiredText, options, onValueChange }) => {
    const renderItem = (item) => (
        <div key={item.id} style={{ marginBottom: 10 }}>
            <div className="option-container">
                <div
                    className="option-radio"
                    onClick={() => onValueChange(id, item.value)}
                >
                    {item.value === value && (<div className="option-radio-dot" />)}
                </div>
                <div style={{ flex: 1 }}>
                    <Label fontSize="15px">{item.value}</Label>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <div style={{ marginBottom: 10 }}>
                <Label>{label}</Label>
            </div>
            <div>
                {options.map(x => renderItem(x))}
            </div>
            {required && (
                <div style={{ marginTop: 5 }}>
                    <Label className="required">* {requiredText}</Label>
                </div>
            )}
        </div>
    );
};

export default MultipleChoice;