import React, { useState } from 'react';
import Button from '../../styled-components/general/Button';
import Label from '../../styled-components/general/Label';
import PDFVisualizer from '../PDFVisualizer';

const InputFile = ({
    icon,
    iconClassName,
    buttonLabel,
    loadingLabel,
    onLoadend,
    showPreview,
    captionText,
}) => {
    const [showLoading, setShowLoading] = useState(false);
    const [visualizer, setVisualizer] = useState(null);

    const fileReader = (file, { target }) => {
        if (file) {
            let reader = new FileReader();
            setShowLoading(true);

            reader.onloadend = () => {
                onLoadend(file, reader.result);
                setShowLoading(false);
                setVisualizer(file);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleFileChange = (event) => fileReader(event.target.files[0], event)

    const showVisualizer = (visualizer && showPreview);

    const cleanVisualizer = () => {
        onLoadend(null, null);
        setVisualizer(null);
    }

    return (
        <>
            <div className="content-upload">
                {
                    showVisualizer ? <>
                        <i
                            className="fa fa-times-circle-o close-visualizer"
                            aria-hidden="true"
                            onClick={cleanVisualizer}
                        ></i>
                        <div className="content-visualizer">
                            <PDFVisualizer
                                file={visualizer}
                                PageProperties={{
                                    pageNumber: 1,
                                    scale: 0.3
                                }}
                            />
                        </div>
                    </> : <>
                            {icon && <div className={iconClassName}>{icon}</div>}
                            <Button
                                className="primary-cta"
                                onClick={() => document.querySelector('#upload-pdf-file').click()}
                            >{buttonLabel}</Button>
                            <input
                                type="file"
                                id="upload-pdf-file"
                                accept=".pdf"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                            />
                            {
                                showLoading && (
                                    <div className="uploading-pdf">
                                        <div className="uploading-pdf-items">
                                            <i
                                                className="fa fa-spinner fa-spin"
                                                style={{ animation: "fa-spin 1s infinite linear" }}
                                                aria-hidden="true"
                                            ></i>
                                            <Label>{loadingLabel}</Label>
                                        </div>
                                    </div>
                                )
                            }
                        </>
                }
                {
                    showVisualizer &&
                    <div className="content-file-name">
                        <Label color="#494949" fontSize="11px" >{visualizer.name}</Label>
                    </div>
                }
            </div>
            {captionText && !showVisualizer && <Label fontSize="11px">{captionText}</Label>}
        </>
    );
};

InputFile.defaultProps = {
    icon: null,
    iconClassName: 'upload-icon',
    buttonLabel: 'Upload',
    loadingLabel: 'Uploading...',
    showPreview: true,
    captionText: '',
}

export default InputFile;