import React, { useLayoutEffect, useRef, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import signatureIcon from '../../../../icons/signatureIcon.png';
import Modal from "../Modal";


const InputSignature = ({
    inputStyle,
    title = 'Add Signature',
    textButtonClose = 'Clear',
    textButtonSave = 'Save',
    onChange = () => { },
    value = null,
}) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [signature, setSignature] = useState(value);
    const [size, setSize] = useState([0, 0]);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const signatureRef = useRef(null);

    useLayoutEffect(() => {
        function updateSize() {
            setSize([document.documentElement.clientWidth, document.documentElement.clientHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return (
        <>
            <div style={inputStyle} onClick={() => { setModalVisible(true) }}>
                <img src={signatureIcon} style={{ width: 20, height: 20, position: 'absolute', top: 5, right: 5 }} />
                {
                    signature ?
                        <img src={signature} style={{ width: inputStyle.width, height: inputStyle.height }} />
                        : null
                }
            </div>
            <Modal
                className="add-signature"
                title={title}
                showModal={modalVisible}
                onHide={() => {
                    setModalVisible(false);
                }}
                onClose={() => {
                    signatureRef.current.clear();
                    setSaveDisabled(true);
                    setSignature(null);
                    onChange(null);
                }}
                onSave={() => {
                    if (!signatureRef.current.isEmpty()) {
                        const signatureBase64 = signatureRef.current.toDataURL();
                        setSignature(signatureBase64);
                        onChange(signatureBase64);
                    }
                    setModalVisible(false);
                }}
                textButtonSave={textButtonSave}
                textButtonClose={textButtonClose}
                disabledButtonSave={saveDisabled}
                body={
                    <SignaturePad
                        ref={signatureRef}
                        penColor='black'
                        onEnd={() => setSaveDisabled(false)}
                        canvasProps={{ width: Math.min(size[0] * 0.70, 470), height: size[1] * 0.3, className: 'signature-pad' }}
                    />
                }
            />
        </>

    );
};

export default InputSignature;