import React from 'react';
import { Col, Button } from 'react-bootstrap';
import Label from '../commons/styled-components/general/Label';

export const ShowMoreButton = ({
    hasNextPage,
    totalPages,
    dictionary,
    handleShowMore,
}) => {
    return (
        <>
            {hasNextPage && (
                <div className="postloadmore pr-3">
                    <Button className="add-new-post-button loadmore" onClick={handleShowMore}>
                        {dictionary.s("loadmore")}
                    </Button>
                </div>
            )}
            {
                !hasNextPage && totalPages > 0 && (
                    <Col sm={12} className='d-flex flex-row justify-content-center pb-4 pt-3'>
                        <Label>
                            {dictionary.s('thatsAllMessage')}
                        </Label>
                    </Col>
                )
            }
        </>
    )
};
