import React, { useRef } from "react";
import Label from "../../styled-components/general/Label";
import Input from "../../styled-components/general/Input";

const InputText = ({
  labelStyle,
  label,
  searchable,
  onSearch,
  inputWrapper,
  inputStyle,
  value,
  placeholder,
  required,
  onChangeText,
  icon,
  id,
  labelClassName,
  inputClassName,
  dictionary,
  ...rest
}) => {

  const input_Text = useRef();

  return (
    <div className="input-container">
      <Input
        type={searchable ? 'search' : 'text'}
        ref={input_Text}
        id={id}
        value={value}
        placeholder={placeholder}
        autoCorrect="false"
        className={inputClassName}
        style={inputStyle}
        onChange={(e) => {
          const _value = e.target.value;
          if (searchable && _value.length === 0) onSearch('');
          onChangeText(_value)
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') onSearch(event.currentTarget.value);
        }}
        onBlur={() => onChangeText(value.trim())}
        {...rest}
      />
      {label && <Label
        onClick={() => input_Text.current.focus()}
        style={labelStyle}
        className={`input-label dotted-text ${value && "top"} ${labelClassName}`}
      >{label}</Label>}
      {icon}
      {required && <Label className="required">* {dictionary && dictionary.s("required")}</Label>}
    </div>
  )
};

InputText.defaultProps = {
  label: "",
  value: "",
  placeholder: "",
  inputClassName: "default-input",
  labelClassName: "",
  labelStyle: {},
  inputStyle: {},
  onSearch: () => { },
  onChangeText: () => { },
  searchable: false,
  required: false,
  icon: null,
};

export default InputText;