import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { translate } from "../../redux/reducers/languageReducer";
import { Button } from "react-bootstrap";
import Modal from "../commons/wrapped-components/Modal";
import Webcam from "react-webcam";
import Addpost from "./Addpost";
import { spinnerPost } from "../../redux/actions/postAction";
import moment from "moment";
import VideoRecorder from "react-video-recorder";

var add;
const VedioRecoding = props => {
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);
  const [showModalAddpost, setShowModalAddpost] = useState(false);
  const [videoData, setVideoData] = useState(null);
  const [shownextbutton, setShownextbutton] = useState(false);

  useEffect(() => {}, []);

  const handleStartCaptureClick = React.useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm"
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
    startTimer();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    setShownextbutton(true);
    clearInterval(add);
  }, [mediaRecorderRef, webcamRef, setCapturing]);
 
  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks(prev => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleDownload = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm"
      });
      props.onspinnerPost(true);
      props.onCloseVedioRecoding();
      setShownextbutton(false);
      var url = URL.createObjectURL(blob);
      var request = new XMLHttpRequest();
      request.open("GET", url, true);
      request.responseType = "blob";
      request.onload = function() {
        var reader = new FileReader();
        reader.readAsDataURL(request.response);
        reader.onload = function(e) {
          setVideoData(e.target.result);
          setShowModalAddpost(true);
          props.onspinnerPost(false);
          setCapturing(false);
        };
      };
      request.send();
      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  const closeAddpostsVideo = () => {
    setShowModalAddpost(false);
  };

  const startTimer = () => {
    var startTimestamp = moment().startOf("day");
    add = setInterval(function() {
        startTimestamp.add(1, 'second');
        document.getElementById('timer').innerHTML = 
            startTimestamp.format('HH:mm:ss');
            if(startTimestamp.format('HH:mm:ss') === "00:03:00"){
              handleStopCaptureClick();
            }
    }, 1000);
}

const IsSafari = () => {
  const ua = navigator.userAgent;
  if (/iPhone|iPad|iPod /i.test(ua) || (!(/Chrome/i.test(ua)) && (/Safari/i.test(ua)) && (/webOS|Mac OS/i.test(ua))))
    return true;

  return false;
};

  const renderModalVedioRecoding = () => (
    <div>
      <Addpost
        showModalAddpost={showModalAddpost}
        data={videoData}
        type="video"
        onCloseAddpost={closeAddpostsVideo}
      />
      <Modal
        dialogClassName="photoOrVedioPopup editimagepopup"
        showModal={props.showModalVedioRecoding}
        title={props.dictionary.s("video")}
        onHide={() => {props.onCloseVedioRecoding();
          setCapturing(false);
          setRecordedChunks([]);
          setVideoData(null);
          setShownextbutton(false);
          clearInterval(add);
        }}
        properties={{
          centered: true,
          keyboard: false,
          autoFocus: false,
          enforceFocus: false,
          size: "lg"
        }}
        body={
         <>
         {IsSafari() ?
          <div className="videoRecoderDiv">
          <VideoRecorder
            onRecordingComplete={videoBlob => {
              props.onspinnerPost(true);
              props.onCloseVedioRecoding();
              var url = URL.createObjectURL(videoBlob);
              var request = new XMLHttpRequest();
              request.open("GET", url, true);
              request.responseType = "blob";
              request.onload = function() {
                var reader = new FileReader();
                reader.readAsDataURL(request.response);
                reader.onload = function(e) {
                  setVideoData(e.target.result);
                  setShowModalAddpost(true);
                  props.onspinnerPost(false);
                };
              };
              request.send();
            }}
            isFlipped={false}
            timeLimit={180000}
            isOnInitially
          />
          </div> :
          <div>
            {props.showModalVedioRecoding ? (
              <Webcam
                className="web-camera"
                audio={true}
                ref={webcamRef}
                width="100%"
                height="100%"
                videoConstraints={{
                  facingMode: props.facingMode
                }}
              />
            ) : null}
            <div id="timer"></div>
            {!shownextbutton ? (
              <div className="camera-buttons-div">
                <div>
                  <div className="photo-icon-style camera-style recodingdiv">
                    {capturing ? (
                      <i
                        className="fa fa-square stoprecord"
                        aria-hidden="true"
                        onClick={handleStopCaptureClick}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-square-o startrecord"
                        aria-hidden="true"
                        onClick={handleStartCaptureClick}
                      ></i>
                    )}
                  </div>
                  <div className="recoding-vedio">
                    {capturing
                      ? props.dictionary.s("stopRecoding")
                      : props.dictionary.s("startRecoding")}
                  </div>
                </div>
              </div>
            ) : null}
            <div className="recoding-buttons-next">
              {shownextbutton ? (
                <Button onClick={handleDownload}>
                  {props.dictionary.s("next")}
                </Button>
              ) : null}
            </div>
          </div>
        }
          </>
        }
      />
    </div>
  );

  return renderModalVedioRecoding();
};

const mapStateToProps = state => {
  return {
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    }
  };
};

const mapDispatchToProps = dispatch => ({
  onspinnerPost: condition => dispatch(spinnerPost(condition))
});

export default connect(mapStateToProps, mapDispatchToProps)(VedioRecoding);
