import React, { useState, useEffect } from 'react';
import { getRemaining, diffSeconds } from '../../../../general/general';

const Timer = ({ value, isActive, contentStyle, textStyle }) => {
    const seconds = diffSeconds(new Date(value), new Date());
    const [remainingSecs, setRemainingSecs] = useState(seconds);
    const { hours, mins, secs } = getRemaining(remainingSecs);

    useEffect(() => {
        const unsubscribeTimer = subscribeTimer(isActive, remainingSecs);
        return () => {
            unsubscribeTimer();
        }
    }, [isActive, remainingSecs]);


    const subscribeTimer = (getisActive, getremainingSecs) => {
        let interval = null;
        if (getisActive) {
            interval = setInterval(() => {
                updateRemainingSecs()
            }, 1000);
        } else if (!getisActive && getremainingSecs !== 0) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }

    const updateRemainingSecs = () => setRemainingSecs(diffSeconds(value, new Date()))

    return (
            <span style={textStyle}>{`${hours !== '00' ? hours + ':' : ''}${mins}:${secs}`}</span>
    )
};

Timer.defaultProps = {
    isActive: true,
    contentStyle: {},
    textStyle: {}
}

export default Timer;