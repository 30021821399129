import {
  SPINNER_POST,
  GET_POST_VIEW_DATA,
  GET_POST_VIEW_DATA_SUCCESS,
  GET_POST_VIEW_DATA_FAIL,
  SPINNER_PROGRESS,
  GET_LIKE_DISLIKE_DATA,
  GET_POST_VIEW_LOAD_MORE,
  CHANGED_CAPTION,
  CHANGED_SUBSPACE,
} from "../actions/types";

export const initialState = {
  postSpinner: false,
  loading: false,
  loadingmore: false,
  postData: [],
  progressSpinner: 0,
  apiFail: false,
  hasNextPage: false,
  caption: null,
  subspace: [],
};

export default (state, action) => {
  const { type, payload } = action;
  if (typeof state === "undefined") {
    return initialState;
  }
  switch (type) {
    case SPINNER_POST:
      return { ...state, postSpinner: payload };
    case GET_POST_VIEW_DATA:
      return { ...state, loading: true }; 
    case GET_POST_VIEW_LOAD_MORE:
          return { ...state, loadingmore: true };
    case GET_POST_VIEW_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        postData: payload,
        hasNextPage: action.hasNextPage,
        apiFail: false,
        loadingmore:false
      };
      case GET_LIKE_DISLIKE_DATA:
      return {
        ...state,
        postData: payload
      };
    case GET_POST_VIEW_DATA_FAIL:
      return {
        ...state,
        loading: false,
        postData: [],
        apiFail: true
      };
    case SPINNER_PROGRESS:
      return { ...state, progressSpinner: payload };
    case CHANGED_CAPTION:
      return { ...state, caption: payload };
    case CHANGED_SUBSPACE:
      return { ...state, subspace: payload };
    default:
      return state;
  }
};
