import React from 'react';
import { general } from '../../general/global';
import Modal from "../commons/wrapped-components/Modal";
import { ReactComponent as Check } from "../../icons/check.svg";
import { ReactComponent as Close } from "../../icons/closeWhite.svg";

export const MessagePopup = ({ showModal, closeModal, dictionary, messageType = '', userProfile = {}, workSpace = {}, lang = '' }) => {
    const Icon = () => {
        return (
            <div
                className='popup-icon-container'
                style={{
                    backgroundColor: messageType === 'granted' ? '#39B97E' : '#E67070',
                }}>
                {messageType === 'granted' ? <Check /> : <Close />}
            </div>
        )
    }

    return (
        <Modal
            headerStyle={{ paddingTop: '40px' }}
            title={
                <div className='popup-title-container'>
                    <Icon />
                    {messageType === 'granted' ? dictionary.s('safelyPassActivated') : dictionary.s('safelyPassDenied')}
                </div>
            }
            dialogClassName="safely-modal renderModalCreatSubSpace"
            showModal={showModal}
            onHide={() => {
                closeModal();
            }}
            onSave={async () => {
                closeModal();
            }}
            textButtonSave={dictionary.s("close")}
            propertyButtonSave={{
                block: false,
                style: {
                    backgroundColor: general.theme.whiteColor,
                    border: `1px solid ${general.theme.primaryColor}`,
                    color: general.theme.primaryColor
                }
            }}
            bsStyleButtonSave="secondary"
            properties={{
                centered: true
            }}
            body={
                <div style={{ marginLeft: '60px' }}>
                    <span className='popup-text'>{messageType === 'granted' ? dictionary.s('safelyPassHasBeenActivated') : dictionary.s('safelyPassHasBeenDenied')}</span>
                    <span className='popup-text-bold'>{'‘' + userProfile.name + ' ' + userProfile.lastName + '’'}</span>
                    {
                        lang === 'es' &&
                        <span className='popup-text'>
                            {messageType === 'granted' ? ' ' + dictionary.s('hasBeenActivated') : ' ' + dictionary.s('hasBeenDenied')}
                        </span>
                    }
                    <span className='popup-text'>{' ' + dictionary.s('forTheSpace')}</span>
                    <span className='popup-text-bold'>{'’' + workSpace.name + '’'}</span>

                </div>
            }
            bodyStyle={{ paddingTop: '0px' }}
            footerStyle={{ paddingBottom: '15px' }}
        />
    )
}
