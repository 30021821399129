import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as toastType from "../../constants/toastTypes";

/* ==Create your toast here== */
export const toastSuccess = (message, position) =>
    toast.success(
        <span style={{ display: "flex", justifyContent: "center" }}>
            <i className="fa fa-check pr-3" aria-hidden="true"></i>
            {message}
        </span>,
        { position: position ? position : toast.POSITION.TOP_CENTER }
    );

export const toastError = (message, position) =>
    toast.error(
        <span style={{ display: "flex", justifyContent: "center" }}>
            <i className="fa fa-close pr-3" aria-hidden="true"></i>
            {message}
        </span>,
        { position: position ? position : toast.POSITION.TOP_CENTER }
    );

export const toastDismissAll = () => toast.dismiss();

/* ===Use this function when you need to show a toast in your component, you can add new type of toast=== */
// You can also add more parameters to this function like position, autoClose, etc. 
export const showToast = (type, message, props = {}) => {
    switch (type) {
        case toastType.SUCCESS:
            return toastSuccess(message, props.position);
        case toastType.ERROR:
            return toastError(message, props.position);
        default:
            return;
    }
}

