/*  Libraries */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom";

/*  Configurations, Variables  */
import { general } from "../../general/global";

/*  Components */
import HeaderNavbar from "../commons/styled-components/dashboard/HeaderNavbar";
import { UserProfile } from "../commons/wrapped-components/UserProfile";
import TitlePage from "../commons/styled-components/dashboard/TitlePage";
import SpaceDropdown from "./SpaceDropdown";
import CheckOutSubspaceModal from "../Workspace/CheckOutSubspaceModal";
import BtnCheckIntoSusbapace from "../safelypass/BtnCheckIntoSusbapace";
import { ReactComponent as QrCodeScanner } from "../../icons/noun_QR Code.svg";
import MyBarcodeScanner from "../commons/MyBarcodeScanner";
import Modal from "../commons/wrapped-components/Modal";

/*  Reducers */
import { translate } from "../../redux/reducers/languageReducer";
import { checkOutIntoSubspace } from "../../redux/actions/workspaceActions";

const Header = props => {
  const [visibleScanner, setVisibleScanner] = useState(false);
  const [deviceType, setdeviceType] = useState(window.deviceType);
  const [showModalCheckOutSubspace, setShowModalCheckOutSubspace] = useState(false);
  const [workspaceExistModal, setWorkspaceExistModal] = useState(false);
  const [erroMessageValue, setMessageValue] = useState("");
  const { screenwidth, user } = props;
  useEffect(() => {
    const handleResize = () => setdeviceType(window.deviceType);
    if (props.activesSubspaceCheckIn === undefined)
      props.history.push('/exit');

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [props.activesSubspaceCheckIn]);

  const closeQrScannerVisible = () => setVisibleScanner(false);
  const location = useLocation();
  const getUserData = () => {
    let userName = user.name;
    let userLastName = user.lastName;
    let charArray = Array.from(userName);

    let hasSpace = charArray.includes(" ");
    let indexOfSpaceChar = charArray.indexOf(" ");

    if (hasSpace) userName = userName.substring(0, indexOfSpaceChar);
    return `${userName} ${userLastName && (userLastName[0].toUpperCase() + '.')} `;
  };
  const backButtonHTML = url => {
    return (
      <i
        className="fa fa-arrow-left header-back-button"
        aria-hidden="true"
        onClick={() => backButtonAction(url)}
      >
        &nbsp;
      </i>
    );
  };

  const backButton = url => {
    const splitUrl = url.split('/');
    if (splitUrl.length > 0) {
      switch ('/' + splitUrl[1]) {
        case "/workspaceDetails":
        case "/editVerification":
        case "/editcheckpoint":
        case "/userVerification":
        case "/safelypass":
        case "/editUserVerification":
        case "/editverificationform":
        case "/subspaceHistory":
        case "/checkIntoSubspace":
        case "/subWorkspacelist":
        case "/subWorkspaceListAddmember":
        case "/useredit":
        case "/transactions":
        case "/createTransaction":
        case "/editTransaction":
        case "/executeTransaction":
        case "/uploadTransaction":
        case "/transactionPdfViewer":
          return backButtonHTML(url);
        default:
          return null;
      }
    }
  };

  const backButtonAction = url => {
    const splitUrl = url.split('/');
    if (splitUrl.length > 0) {
      switch ('/' + splitUrl[1]) {
        case "/workspaceDetails":
          props.history.push("/workspacelist");
          break;
        case "/editUserVerification":
          props.history.push("/editVerification");
          break;
        case "/editverificationform":
          props.history.push("/editcheckpoint");
          break;
        case "/safelypass":
          props.history.push("/workspaceDetails");
          break;
        case "/editVerification":
        case "/editcheckpoint":
        case "/userVerification":
        case "/transactions":
          props.history.push("/workspaceDetails");
          break;
        case "/checkIntoSubspace":
          props.history.goBack();
          break;
        case "/subWorkspacelist":
          props.history.push("/workspaceDetails");
          break;
        case "/subWorkspaceListAddmember":
          props.history.push("/subWorkspacelist");
          break;
        case "/useredit":
        case "/subspaceHistory":
          props.history.push("/users");
          break;
        case "/uploadTransaction":
        case "/createTransaction":
        case "/editTransaction":
        case "/executeTransaction":
          props.history.push("/transactions");
          break;
        case "/transactionPdfViewer":
          props.history.push("/transactionsHistory");
          break;
        default:
          return null;
      }
    }
  };

  const spaceDropDownRender = url => {
    switch (url) {
      case "/selfdeclarationReport":
      case "/reportlist":
      case "/reportlist/passes-rejected":
      case "/reportlist/active-pass":
      case "/reportlist/check-ins":
      case "/reportlist/passes-disabled":
      case "/verificationReport":
      case "/subspacesReport":
      case "/subspacesReport/history":
      case "/subspacesReport/activity":
      case "/users":
      case "/transactionsHistory":
        return <SpaceDropdown className={deviceType} />;
      default:
        return null;
    }
  };

  const closeCheckOutSubspaceModal = () => setShowModalCheckOutSubspace(false);
  const showCheckOutSubspaceModal = () => setShowModalCheckOutSubspace(true);
  const onCheckOutSubspace = () => {
    const activeSubspace = props.activesSubspaceCheckIn[0];
    const { _id: accessAuthorizationId } = activeSubspace;
    props.onCheckOutIntoSubspace(accessAuthorizationId, props.accesstokenidp).then(response => {
      closeCheckOutSubspaceModal();
    });
  }

  const renderCheckIntoButton = () => {
    if (props.activesSubspaceCheckIn !== undefined && props.workSpaceIdSafelyPassActive !== undefined) {
      let showCheckIntoSubspaceButton = true;
      let isSpaceSelectedWhereSafelyPassActivated = false;
      if (props.isSafelyPassActivated)
        isSpaceSelectedWhereSafelyPassActivated = props.workSpaceId === props.workSpaceIdSafelyPassActive

      if (props.activesSubspaceCheckIn.length > 0)
        showCheckIntoSubspaceButton = false;

      const showButton = props.isSafelyPassActivated && location.pathname === "/workspacelist" && isSpaceSelectedWhereSafelyPassActivated;

      return (showButton && (
        <div className="pr-2">
          <BtnCheckIntoSusbapace
            type={showCheckIntoSubspaceButton ? 'into' : 'out'}
            dictionary={props.dictionary}
            history={props.history}
            deviceType={deviceType}
            showCheckOutSubspaceModal={showCheckOutSubspaceModal}
          />
        </div>
      ))
    }
  }

  const renderCheckOutSubspaceModal = () => {
    if (props.activesSubspaceCheckIn !== undefined && props.activesSubspaceCheckIn.length) {
      const activeSubspace = props.activesSubspaceCheckIn[0];
      return (
        <CheckOutSubspaceModal
          showModalCheckOutSubspace={showModalCheckOutSubspace}
          closeCheckOutSubspaceModal={closeCheckOutSubspaceModal}
          dictionary={props.dictionary}
          selectedSubspace={activeSubspace}
          onCheckOutSubspace={onCheckOutSubspace}
        />
      )
    }
  }

  const renderQRWorkspaceExist = () => (
    <Modal
      title=" "
      className="animated fadeInDown qrScannerErrorPopup"
      showModal={workspaceExistModal}
      onHide={() => setWorkspaceExistModal(false)}
      onSave={() => {
        setWorkspaceExistModal(false);
      }}
      textButtonSave={props.dictionary.s("close")}
      bsStyleButtonSave="primary cancel-button-qrcode"
      propertyButtonSave={{
        className: "border-radius-none",
        block: true
      }}
      properties={{
        centered: true
      }}
      body={erroMessageValue}
    />
  );

  return (
    <HeaderNavbar
      fixed="top"
      className="header-nav"
      expand="lg"
      backgroundcolor={general.theme.BODY_BG}
      marginleft={props.marginleft}
      screenwidth={screenwidth}
    // boxshadow={"0 2px 2px -1px rgba(0,0,0,.07)"}
    >
      {deviceType !== "phone" ? (
        <nav className="mr-auto">
          <TitlePage
            className={"main-container-header-tittle " + deviceType}
            color={general.theme.fontColorPrimary}
            paddingleft="5px"
            fontWeight="800"
            fontSize="22px"
          >
            {backButton(location.pathname)}
            {props.title}
          </TitlePage>
        </nav>
      ) : (
          <div className="mr-auto phonenave">
            {backButton(location.pathname)}
            {props.title}{" "}
          </div>
        )}
      {renderCheckOutSubspaceModal()}
      {renderCheckIntoButton()}
      <MyBarcodeScanner
        visibleScanner={visibleScanner}
        closeQrScannerVisible={closeQrScannerVisible}
        setMessageValue={setMessageValue}
        setWorkspaceExistModal={setWorkspaceExistModal}
      />
      <button
        className={"primary-cta scanQRCodeButton " + deviceType}
        onClick={() => {
          setVisibleScanner(true);
        }}
      >
        <QrCodeScanner />{" "}
        {deviceType !== "phone" ? props.dictionary.s("scanQRCode") : null}
      </button>
      {spaceDropDownRender(location.pathname)}
      <UserProfile
        userImage={
          <div style={{ display: "inline-block" }}>
            <div style={{ maxWidth: '200px', display: 'flex' }}>
              <img
                className="rounded-circle mr-2"
                width={35.8}
                height={35.8}
                src={user.picture}
                alt="user pic"
              />
              {screenwidth >= 600 ? (
                <p
                  style={{
                    fontSize: 15,
                    color: general.theme.fontColorUserLogged,
                    fontWeight: "bold",
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    margin: 0,
                    padding: '7px 10px 0 10px'
                  }}>{getUserData()}</p>
              ) : null}
            </div>
          </div>
        }
        id="basic-nav-dropdown"
        items={[]}
        logout={props.dictionary.s("logout")}
      />
      {renderQRWorkspaceExist()}
    </HeaderNavbar >
  );
};
const mapStateToProps = state => ({
  isSafelyPassActivated: state.operation.safelyPassStatus === "granted",
  user: state.user,
  dictionary: {
    s: key => translate(state.language.dictionary, key)
  },
  accesstokenidp: state.user.accesstokenidp,
  checkIntoSubspaceList: state.workspace.checkIntoSubspaceList,
  activesSubspaceCheckIn: state.workspace.activesSubspaceCheckIn,
  refreshCheckIntoSubspaceScreen: state.workspace.refreshCheckIntoSubspaceScreen,
  workSpaceId: state.workspace.workspaceFocused._id,
  workSpaceIdSafelyPassActive: state.operation.workSpaceIdSafelyPassActive,
});

const mapDispatchToProps = (dispatch) => ({
  onCheckOutIntoSubspace: (accessAuthorizationId, accesstokenidp) =>
    dispatch(checkOutIntoSubspace(accessAuthorizationId, accesstokenidp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));