import React from "react";
import { Col } from "react-bootstrap";

const ImageLoader = (props) => {
  return (
    <div
      className="loader-container"
      style={{ width: props.width, height: props.height, borderRadius: props.borderRadius, ...props.styles }}
    >
      <Col className="spinner-loader">
        <div className="spinner-border" role="status"></div>
      </Col>
    </div>
  );
};

ImageLoader.defaultProps = {
  height: '160px',
  width: '160px',
  borderRadius: '50%',
  styles: {},
};

export default ImageLoader;