import { 
    DISABLE_USER_SAFELY_PASS,
    DISABLE_USER_SAFELY_PASS_SUCCESS,
    DISABLE_USER_SAFELY_PASS_FAIL,
    ENABLE_USER_SAFELY_PASS,
    ENABLE_USER_SAFELY_PASS_SUCCESS,
    ENABLE_USER_SAFELY_PASS_FAIL,
} from "./types";
import { safelyApi } from "../../api/safely";
import ApiEndPoint from "../../constants/apiEndPoint";

export const disableUserSafelyPass = (userId, workspaces, disabledById, startDate, endDate) => async (dispatch) => {
    try {
        dispatch({ type: DISABLE_USER_SAFELY_PASS });
        const response = await safelyApi(ApiEndPoint.disableUserSafelyPass, {
            method: 'POST',
            body: {
                userId,
                workspaces,
                disabledById,
                startDate,
                endDate,
            }
        });
        if (response.status) {
            dispatch({type: DISABLE_USER_SAFELY_PASS_SUCCESS, payload: response.data})
        } else {
            dispatch({type: DISABLE_USER_SAFELY_PASS_FAIL, payload: response.data});
        }
        return response;
    } catch (e) {
        dispatch({type: DISABLE_USER_SAFELY_PASS_FAIL, payload: e});
        return { e, status: false };
    }
}

export const enableUserSafelyPass = (userId, enabledById, workspaces) => async (dispatch) => {
    try {
        dispatch({ type: ENABLE_USER_SAFELY_PASS });
        const response = await safelyApi(ApiEndPoint.enableUserSafelyPass, {
            method: 'PUT',
            body: {
                userId,
                enabledById,
                workspaces
            }
        });
        if (response.status) {
            dispatch({type: ENABLE_USER_SAFELY_PASS_SUCCESS, payload: response.data})
        } else {
            dispatch({type: ENABLE_USER_SAFELY_PASS_FAIL, payload: response.data });
        }
        return response;
    } catch (e) {
        dispatch({type: ENABLE_USER_SAFELY_PASS_FAIL, payload: e });
        return { e, status: false };
    }
}
