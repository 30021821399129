import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as SafelyLogo } from '../../icons/safely-logo.svg';
import { languageChange } from "../../redux/actions/languageActions";
import { translate } from "../../redux/reducers/languageReducer";
import InputSelect from "../commons/wrapped-components/inputs/InputSelect";
import moment from 'moment';

const LoginHeader = (props) => {
   
  useEffect(() => {    
  props.onLanguageChange(props.lang);
   }, []);
    const options = [
        {
          value: "en",
          label: props.dictionary.s('english'),
        },
        {
          value: "es",
          label: props.dictionary.s('spanish'),
        }
      ];

    return (
        <div id="loginBorder" className="login-header row">
            <div className="safely-logo col-6">
              <SafelyLogo />
            </div>
            <div className="language-select signin-select col-6">
              <InputSelect
                label={props.dictionary.s("language")}
                className="sws-select language-dropdown"
                value={options.find(x => x.value === props.lang)}
                options={options}
                onChange={lang => {
                  moment.locale(lang.value);
                  props.onLanguageChange(lang.value);
                }}
                sort={false}
                displayLabel={false}
                menuContainerStyle={{}}
                clearable={false}
                searchable={false}
                styles={{
                  control: {
                    borderRadius: "4px",
                    width: "98px",
                    cursor: "pointer",
                    ':hover': {
                      border: "1px solid #4FA3FF",
                    }
                  }
                }}
                labelKey="label"
                valueKey="value"
              />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    lang: state.language.lang,
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    }
});

const mapDispatchToProps = (dispatch) => ({
    onLanguageChange: lang => dispatch(languageChange(lang))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginHeader);