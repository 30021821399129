import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { translate } from "../../redux/reducers/languageReducer";
import Modal from "../commons/wrapped-components/Modal";
import MultiSelect from "react-multi-select-component";
import { ReactComponent as CameraIcon } from "../../icons/addPhotoOrVideo.svg";
import PhotoOrVedioPopup from "./photoOrVedioPopup";
import { getsubSpaceUnderSpace } from "../../redux/actions/workspaceActions";
import { 
  postData, 
  spinnerPost, 
  resetProgress, 
  PostEditedData,
  changedCaption,
  changedSubspace,
} from "../../redux/actions/postAction";

const AddText = (props) => {
  const [showPhotoOrVedio, setShowPhotoOrVedio] = useState(false);
  const [getOptions, setGetOptions] = useState([]);
  const setOptions = (optionItems) => {
    if(optionItems && optionItems.length > 0){
    const options = [];
    optionItems.map((option) => {
      options.push({ label: option.name, value: option._id });
    });
    return options;
  }
  else{
    return [];
  }
  };

  useEffect(() => {
    if(props.showModalAddText){
      props.onGetsubSpaceUnderSpace(props.workspaceFocused._id,props.userId).then(res=>{
        setGetOptions(setOptions(res.data));
      });
    }
    if(props.editData && props.showModalAddText) {
      let subspaces=props.editData.subspaces.map(function(obj) { 
        obj['value'] = obj['_id'];  
        obj['label'] = obj['name'];
        delete obj['_id']; 
        delete obj['name']; 
        return obj; 
        });
         props.onChangeOfCaption(props.editData.caption);
         props.onChangeOfSelectedSubspace(subspaces);
        }  
      },[props.editData,props.showModalAddText]);

  const closePhotoOrVedioPopUp = () => {
    setShowPhotoOrVedio(false);
  };

  const renderModalAddText = () => (
    <Modal
      dialogClassName="addPopup postModal titleAlign"
      showModal={props.showModalAddText}
      title={props.editData? props.dictionary.s("EditPost") : props.dictionary.s("newPost")}
      onHide={() => {
        props.onChangeOfCaption(null);
        props.onChangeOfSelectedSubspace([]);
        props.onCloseAddText();
      }}
      properties={{
        centered: true,
        keyboard: false,
        autoFocus: false,
        enforceFocus: false,
        size: "lg",
      }}
      onSave={() => {
        if(props.selectedSubSpace.length > 0 && props.caption.trim() !== ''){
          let subspaceList = props.selectedSubSpace.map(option => option.value);
          if(props.editData) {
            document.getElementsByClassName("infinite-scroll-component")[0].scrollTop=0;
            props.onClickOfPostEditData(
              props.accesstokenidp,
              props.userId,
              props.editData._id,
              props.caption.trim(),
              subspaceList
            ).then(result => {
              props.onChangeOfCaption(null);
              props.onChangeOfSelectedSubspace([]);
              props.PostViewData();
            });
            props.onCloseAddText(); 
          } else {
            props.onresetProgress(1);
            props.onspinnerPost(true);
            props.onClickOfPost(
              props.type,
              props.accesstokenidp,
              props.userId,
              props.workspaceFocused._id,
              props.caption.trim(),
              subspaceList
            ).then(result => {
              props.onresetProgress(0);
              props.onChangeOfCaption(null);
              props.onChangeOfSelectedSubspace([]);
              props.PostViewData();
            });
            props.onCloseAddText(); 
          }
        }    
      }}
      onClose={() => {
        props.onChangeOfCaption(null);
        props.onChangeOfSelectedSubspace([]);
        props.onCloseAddText();
      }}
      textButtonSave={props.dictionary.s("postdata")}
      textButtonClose={props.dictionary.s("cancel")}
      bsStyleButtonSave={ props.selectedSubSpace.length > 0 && props.caption && props.caption.trim() !== '' && props.data !=='' ? "primary post-button" : "primary post-button-disable"}
      propertyButtonSave={{
        className: "border-radius-none",
      }}
      buttonCloseProperties={{
        variant: "light",
      }}
      body={
          <div className="add-post-text">
            <div>
              <textarea
                className="form-control form-control-textAera"
                rows="5"
                defaultValue={props.caption}
                placeholder={props.dictionary.s("addNewPost") + ' *'}
                onChange={(e) =>props.onChangeOfCaption(e.target.value)}
              />
            </div>
            { !props.editData ? <div 
                className="add-photo-or-video-link" 
                onClick={() => {
                    setShowPhotoOrVedio(true);
                    props.onCloseAddText();
                }}>
               <CameraIcon className="camera-post-icon" />{props.dictionary.s('addPhotoOrVideo')}
            </div> : null }
            <div>
              <MultiSelect
                className="custome-multiselect"
                labelledBy={props.dictionary.s("selectSubSpace")}
                options={getOptions}
                value={props.selectedSubSpace}
                overrideStrings={{
                  allItemsAreSelected: props.dictionary.s("allSubSpacesareselected"),
                  selectSomeItems: props.dictionary.s("selectSubSpace") + ' *',
                  search: props.dictionary.s("search"),
                }}
                hasSelectAll={false}
                onChange={(subSpacesSelected) => {
                  props.onChangeOfSelectedSubspace(subSpacesSelected);
                }}
              />
            </div>
            <div className="display-subspace-div">
              {props.selectedSubSpace.map((space) => {
                return (
                  <div className="display-subspace-style" key={space.label}>{space.label}</div>
                );
              })}
            </div>
          </div>
      }
    />
  );

  return (
      <div>
        <PhotoOrVedioPopup
            showModalPhotoOrVedio={showPhotoOrVedio}
            onClosePhotoOrVedioPopUp={closePhotoOrVedioPopUp}
        />
        {renderModalAddText()}
      </div>
  );
};

const mapStateToProps = (state) => {
  const user = state.user;
  return {
    userId: user.id,
    caption: state.posts.caption,
    selectedSubSpace: state.posts.subspace,
    dictionary: {
      s: (key) => translate(state.language.dictionary, key),
    },
    workspaceFocused: state.workspace.spaceFocused,
    accesstokenidp: state.user.accesstokenidp,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClickOfPost: (
    type,
    accesstokenidp,
    userId,
    workspaceId,
    caption,
    subspaces
  ) =>
    dispatch(
      postData(
        type,
        accesstokenidp,
        userId,
        workspaceId,
        caption,
        subspaces
      )
    ),
    onspinnerPost: (condition) => dispatch(spinnerPost(condition)),
    onresetProgress: (condition) => dispatch(resetProgress(condition)),
    onClickOfPostEditData: (accesstokenidp, userId, postId, caption, subspaceList) => dispatch(PostEditedData(accesstokenidp, userId, postId, caption, subspaceList)),
    onGetsubSpaceUnderSpace: (workSpaceId,userId) => dispatch(getsubSpaceUnderSpace(workSpaceId,userId)),
    onChangeOfCaption: (caption) => dispatch(changedCaption(caption)),
    onChangeOfSelectedSubspace: (subspace) => dispatch(changedSubspace(subspace)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddText);
