import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { translate } from "../../redux/reducers/languageReducer";
import Modal from "../commons/wrapped-components/Modal";
import MultiSelect from "react-multi-select-component";
import { getsubSpaceUnderSpace } from "../../redux/actions/workspaceActions";
import { 
  postData, 
  spinnerPost, 
  resetProgress, 
  PostEditedData, 
  postViewData,
  changedCaption,
  changedSubspace,
} from "../../redux/actions/postAction";

const Addpost = (props) => {
  const [getOptions, setGetOptions] = useState([]);

  const setOptions = (optionItems) => {
    if(optionItems && optionItems.length > 0){
    const options = [];
    optionItems.map((option) => {
      options.push({ label: option.name, value: option._id });
    });
    return options;
  }
  else{
    return [];
  }
  };

   useEffect(() => {
    if(props.showModalAddpost){
      props.onGetsubSpaceUnderSpace(props.workspaceFocused._id,props.userId).then(res=>{
        setGetOptions(setOptions(res.data));
      });
    }
    if(props.editData && props.showModalAddpost) {
      let subspaces=props.editData.subspaces.map(function(obj) { 
        obj['value'] = obj['_id'];  
        obj['label'] = obj['name'];
        delete obj['_id']; 
        delete obj['name']; 
        return obj; 
         });
         props.onChangeOfCaption(props.editData.caption);
         props.onChangeOfSelectedSubspace(subspaces);
        }  
      },[props.editData,props.showModalAddpost]);

  const renderModalAddpost = () => (
    <Modal
      dialogClassName="addPopup postModal titleAlign postContentModal"
      showModal={props.showModalAddpost}
      title={props.editData? props.dictionary.s("EditPost") : props.dictionary.s("newPost")}
      onHide={() => {
        props.onChangeOfCaption(null);
        props.onChangeOfSelectedSubspace([]);
        props.onCloseAddpost();
      }}
      properties={{
        centered: true,
        keyboard: false,
        autoFocus: false,
        enforceFocus: false,
        size: "lg",
      }}
      onSave={() => {
        if(props.selectedSubSpace.length > 0 && props.caption.trim() !== '' && props.data !==''){
          let subspaceList = props.selectedSubSpace.map(option => option.value);
          if(props.editData) {
            document.getElementsByClassName("infinite-scroll-component")[0].scrollTop=0;
            props.onClickOfPostEditData(
              props.accesstokenidp,
              props.userId,
              props.editData._id,
              props.caption.trim(),
              subspaceList
            ).then(result => {
              props.onChangeOfCaption(null);
              props.onChangeOfSelectedSubspace([]);
              props.getPostViewData(props.workspaceFocused._id, props.userId, props.accesstokenidp,1,props.postDetails.length > 10 ? (Math.ceil(props.postDetails.length / 10) * 10) : 10);
            });
            props.onCloseAddpost(); 
          } else {
            props.onresetProgress(1);
            props.onspinnerPost(true);
            props.onClickOfPost(
              props.type,
              props.accesstokenidp,
              props.userId,
              props.workspaceFocused._id,
              props.caption.trim(),
              subspaceList,
              props.data,
            ).then(result => {
              props.onresetProgress(0);
              props.onChangeOfCaption(null);
              props.onChangeOfSelectedSubspace([]);
              props.getPostViewData(props.workspaceFocused._id, props.userId, props.accesstokenidp,1,props.postDetails.length > 10 ? (Math.ceil(props.postDetails.length / 10) * 10) : 10);
            });
            props.onCloseAddpost(); 
          }
        }    
      }}
      onClose={() => {
        props.onChangeOfCaption(null);
        props.onChangeOfSelectedSubspace([]);
        props.onCloseAddpost();
      }}
      textButtonSave={props.dictionary.s("postdata")}
      textButtonClose={props.dictionary.s("cancel")}
      bsStyleButtonSave={ props.selectedSubSpace.length > 0 && props.caption && props.caption.trim() !== '' && props.data !=='' ? "primary post-button" : "primary post-button-disable"}
      propertyButtonSave={{
        className: "border-radius-none",
      }}
      buttonCloseProperties={{
        variant: "light",
      }}
      body={
        <div className="new-post-container">
          {props.type === "image" ? (
            <div className="new-post-image">
              <img src={props.data} />
            </div>
          ) : null}
          {props.type === "video" ? (
            <div className="new-post-image">
              <video controls>
                <source src={props.data} />
              </video>
            </div>
          ) : null}
          <div className="new-post-text-div">
            <div>
              <textarea
                className="form-control form-control-textAera"
                rows="5"
                defaultValue={props.caption}
                placeholder={props.dictionary.s("addNewPost") + ' *'}
                onChange={(e) => props.onChangeOfCaption(e.target.value)}
              />
            </div>
            <div>
              <MultiSelect
                className="custome-multiselect"
                labelledBy={props.dictionary.s("selectSubSpace")}
                options={getOptions}
                value={props.selectedSubSpace}
                overrideStrings={{
                  allItemsAreSelected: props.dictionary.s("allSubSpacesareselected"),
                  selectSomeItems: props.dictionary.s("selectSubSpace") + ' *',
                  search: props.dictionary.s("search"),
                }}
                hasSelectAll={false}
                onChange={(subSpacesSelected) => {
                  props.onChangeOfSelectedSubspace(subSpacesSelected);
                }}
              />
            </div>
            <div className="display-subspace-div">
              {props.selectedSubSpace.map((space) => {
                return (
                  <div className="display-subspace-style" key={space.label}>{space.label}</div>
                );
              })}
            </div>
          </div>
        </div>
      }
    />
  );

  return renderModalAddpost();
};

const mapStateToProps = (state) => {
  const user = state.user;
  return {
    userId: user.id,
    dictionary: {
      s: (key) => translate(state.language.dictionary, key),
    },
    workspaceFocused: state.workspace.spaceFocused,
    accesstokenidp: state.user.accesstokenidp,
    postDetails: state.posts.postData,
    caption: state.posts.caption,
    selectedSubSpace: state.posts.subspace,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPostViewData: (workspaceId, userId, accesstokenidp, pageNo, limit) =>
    dispatch(postViewData(workspaceId, userId, accesstokenidp, pageNo, limit)),
  onClickOfPost: (
    type,
    accesstokenidp,
    userId,
    workspaceId,
    caption,
    subspaces,
    base64
  ) =>
    dispatch(
      postData(
        type,
        accesstokenidp,
        userId,
        workspaceId,
        caption,
        subspaces,
        base64
      )
    ),
    onspinnerPost: (condition) => dispatch(spinnerPost(condition)),
    onresetProgress: (condition) => dispatch(resetProgress(condition)),
    onClickOfPostEditData: (accesstokenidp, userId, postId, caption, subspaceList) => dispatch(PostEditedData(accesstokenidp, userId, postId, caption, subspaceList)),
    onGetsubSpaceUnderSpace: (workSpaceId,userId) => dispatch(getsubSpaceUnderSpace(workSpaceId,userId)),
    onChangeOfCaption: (caption) => dispatch(changedCaption(caption)),
    onChangeOfSelectedSubspace: (subspace) => dispatch(changedSubspace(subspace)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Addpost);
