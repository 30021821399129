import firebase from "firebase";
import fcmConfig from "./firebase.fcm.credentials";

export const initializedFirebase = firebase.initializeApp({
    apiKey: fcmConfig.apiKey,
    authDomain: fcmConfig.authDomain,
    databaseURL: fcmConfig.databaseURL,
    projectId: fcmConfig.projectId,
    storageBucket: fcmConfig.storageBucket,
    messagingSenderId: fcmConfig.messagingSenderId,
    appId: fcmConfig.appId,
    measurementId: fcmConfig.measurementId
});