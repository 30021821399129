import moment from 'moment';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import AmazonStorage from '../../amazonStorage';
import * as toastType from "../../constants/toastTypes";
import PDF from '../../general/pdf';
import { executeTransaction } from '../../redux/actions/transactionsActions';
import { translate } from "../../redux/reducers/languageReducer";
import Button from '../commons/styled-components/general/Button';
import { showToast } from "../commons/Toast";
import InputSignature from '../commons/wrapped-components/inputs/InputSignature';
import PDFVisualizer from '../commons/wrapped-components/PDFVisualizer';
import MainNavigation from "../Navigation/MainNavigation";



const Execute = ({ currentTransaction, spaceFocused, userId, dictionary, history, onExecuteTransaction }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [size, setSize] = useState([0, 0]);
    const [canvasSize, setCanvasSize] = useState([0, 0]);
    const [geolocation, setGeolcation] = useState(null);
    const [inputs, setInputs] = useState(currentTransaction.inputs);
    const [saveDisabled, setSaveDisabled] = useState(false);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(setGeolcation);
    }, [])

    const updateCanvasSize = () => {
        const canvas = document.getElementsByClassName('react-pdf__Page__canvas')[0];
        if (canvas) {
            setCanvasSize([canvas.getBoundingClientRect().width, canvas.getBoundingClientRect().height]);
        }
    }

    useLayoutEffect(() => {
        function updateSize() {
            setSize([document.documentElement.clientWidth, document.documentElement.clientHeight]);
            updateCanvasSize();
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setTimeout(updateCanvasSize, 300)
    }

    const updateInputValues = (index, newValue) => {
        const newInputs = [...inputs];
        if (newInputs[index].type === 'text') {
            newInputs[index].value = newValue.trim() === '' ? undefined : newValue;
        } else {
            newInputs[index].value = newValue;
        }
        setInputs(newInputs);
    }

    const getInputComponent = (obj, index) => {

        const [canvasWidth, canvasHeight] = canvasSize;
        const { inputType, required, positionX, positionY, width, height, fontSize, value } = obj;

        const style = {
            position: 'absolute',
            width: (canvasWidth / 100) * width,
            height: (canvasHeight / 100) * height,
            left: (canvasWidth / 100) * positionX,
            top: (canvasHeight / 100) * positionY,
            fontFamily: 'Helvetica',
            fontSize: (canvasHeight / 100) * fontSize,
            border: '1px solid black',
        };

        const notRequiredStyle = {
            position: 'absolute',
            left: (canvasWidth / 100) * positionX,
            top: ((canvasHeight / 100) * positionY) - 20,
            fontSize: 12,
            background: '#FFFFC4',
            border: '1px solid #BABABA'
        };

        const requiredStyle = {
            ...notRequiredStyle,
            color: '#E67070',
        };

        if (obj.pageNumber === pageNumber) {
            switch (inputType) {
                case 'text':
                    return (
                        <div key={'text-' + index} style={{ lineHeight: 1 }}>
                            {!value ? <div style={required ? requiredStyle : notRequiredStyle} >{dictionary.s(required ? 'requiredText' : 'text')}</div> : null}
                            <input
                                id={index}
                                key={index}
                                className="pdf-input-text"
                                type='text'
                                style={style}
                                value={value}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    updateInputValues(index, value);
                                }}
                            />
                        </div>
                    );
                    break;
                case 'signature':
                    return (
                        <div key={'signature-' + index}>
                            { !value ? <div style={required ? requiredStyle : notRequiredStyle} >{dictionary.s(required ? 'requiredSignature' : 'signature')}</div> : null}
                            <InputSignature value={value} inputStyle={style} title={dictionary.s('addSignature')} textButtonClose={dictionary.s('clear')} textButtonSave={dictionary.s('save')} onChange={(value) => { updateInputValues(index, value) }} />
                        </div>
                    );
                default:
                    break;
            }

        }
    };

    const pdfWidth = Math.min(Math.max(size[0] * 0.45, 320), 1200);
    const hasPrev = !(pageNumber === 1);
    const hasNext = !(pageNumber === numPages);

    return (
        <MainNavigation title={dictionary.s('transactions')}>
            <span className="reports-header">{currentTransaction.transactionName}</span>
            <div className="transaction-container execute">
                <div className="transaction-header">
                    <div className="transaction-filename">{decodeURIComponent(currentTransaction.url.split('/').pop())}</div>
                    <div className="transaction-pagination execute" style={{ width: pdfWidth - 215 }}>
                        {`${dictionary.s('page')} ${pageNumber}/${numPages}`}
                    </div>
                </div>
                <div className="transaction-row">
                    <div className="pdf-viewer execute" style={{ width: pdfWidth }}>
                        <PDFVisualizer
                            file={currentTransaction.url}
                            onLoadSuccess={onDocumentLoadSuccess}
                            PageProperties={{
                                pageNumber: pageNumber,
                                width: pdfWidth
                            }}
                        />
                        {inputs.map((input, index) => {
                            return getInputComponent(input, index)
                        })}
                    </div>
                </div>
                <div className="transaction-button-container" >
                    <Button
                        className="cancel-btn"
                        onClick={() => {
                            if (hasPrev) {
                                setPageNumber(pageNumber - 1)
                            } else {
                                history.push('/transactions');
                            }
                        }}
                    >
                        {hasPrev ? dictionary.s('prev') : dictionary.s("cancel")}
                    </Button>
                    <Button
                        className="save-btn"
                        disabled={hasNext || saveDisabled ? !hasNext : inputs.find(i => i.required && !i.value)}
                        onClick={async () => {
                            if (hasNext) {
                                setPageNumber(pageNumber + 1)
                            } else {
                                setSaveDisabled(true);
                                const { _id, transactionName } = currentTransaction;
                                const workspaceId = spaceFocused._id || currentTransaction.workspaceId;
                                const res = await fetch(currentTransaction.url);
                                const pdf = await res.arrayBuffer();
                                const pdfBytes = await PDF.modifyPDF(pdf, inputs);
                                const location = geolocation ? {
                                    "type": "Point",
                                    "coordinates": [geolocation.coords.latitude, geolocation.coords.longitude]
                                } : undefined;

                                const execDate = moment().format('YYYYMMDD-HHmmss');
                                debugger

                                const { Location } = await new AmazonStorage().uploadFile(
                                    pdfBytes,
                                    {
                                        name: `${workspaceId}-${currentTransaction.transactionName}-${userId}-${execDate}.pdf`,
                                        type: 'application/pdf'
                                    },
                                    `transactions/${workspaceId}/transactionSubmissionForms/`,
                                    false
                                );
                                // console.log(Location);

                                const body = {
                                    userId,
                                    location,
                                    transactionFormId: _id,
                                    transactionFormName: transactionName,
                                    workspaceId,
                                    url: Location,
                                }

                                onExecuteTransaction(body);
                                if (window.ReactNativeWebView) {
                                    window.ReactNativeWebView.postMessage('params as string')
                                } else {
                                    history.push('/transactions');
                                    showToast(toastType.SUCCESS, dictionary.s('transactionSavedSuccesfully'));
                                }
                            }
                        }}
                    >
                        {hasNext ? dictionary.s("next") : dictionary.s("saveAndSubmit")}
                    </Button>
                </div>
            </div>
        </MainNavigation>
    );
};

const mapStateToProps = state => ({
    userId: state.user.id,
    currentTransaction: state.transactions.currentTransaction,
    loading: state.transactions.loading,
    dictionary: {
        s: key => translate(state.language.dictionary, key)
    },
    accesstokenidp: state.user.accesstokenidp,
    spaceFocused: state.workspace.spaceFocused,
});

const mapDispatchToProps = dispatch => ({
    onExecuteTransaction: body => dispatch(executeTransaction(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Execute);