import {
  POST_LOGIN,
  LOG_OUT_SUCCESS,
  LOG_OUT_ERROR,
  LOGIN_USER_SOCIAL_MEDIA_SUCCESS,
  LOGIN_USER_SOCIAL_MEDIA_FAIL,
  CHANGE_EMAIL_NUMBER,
  CHANGE_PASSWORD,
  SET_LOADING,
  SET_ACCESS_TOKEN,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGIN_USER_EXISTS,
  CHANGE_FORGOT_EMAIL_NUMBER,
  RESET_FORGOT_EMAIL_NUMBER,
  RESET_LOGIN,
  USER_JWT_TOKEN_UPADATE
  // LOGIN_USER_EXISTS_SUCCESS,
} from "../actions/types";

export const initialState = {
  isLoggingIn: false,
  accessToken: null,
  user: {
    id: null,
    picture: null,
    workSpaces: [
      {
        _id: null,
      },
    ],
    roles: [{}],
  },
  message: null,
  loading: false,
  //emailNumber: getUserInfo.userName || "",
  emailNumber: "",
  password: "",
  forgotEmailNumber: null,
  error: "",
  jwtAccessToken: null,
  jwtRefreshToken: null
};

export default (state, action) => {
  const { type, payload } = action;
  if (typeof state === "undefined") {
    return initialState;
  }
  switch (type) {
    case POST_LOGIN:
      return { ...state, isLoggingIn: payload };
    case CHANGE_EMAIL_NUMBER:
      return { ...state, emailNumber: payload };
    case CHANGE_PASSWORD:
      return { ...state, password: payload };
    case LOGIN_USER_EXISTS:
      return { ...state, loading: true };
    case LOGIN_USER_SUCCESS:
    case LOGIN_USER_SOCIAL_MEDIA_SUCCESS:
      const { accesstokenidp, ...rest } = payload.data;
      return {
        ...state,
        ...initialState,
        accessToken: accesstokenidp,
        jwtAccessToken:payload.data.accessToken,
        jwtRefreshToken:payload.data.refreshToken,
        user: rest,
      };
    case LOGIN_USER_FAIL:
    case LOGIN_USER_SOCIAL_MEDIA_FAIL:
      return { ...state, message: payload.message, loading: false };
    case LOG_OUT_SUCCESS:
      return { ...state, ...initialState };
    case LOG_OUT_ERROR:
      return { ...state, ...payload };
    case RESET_LOGIN:
      return { ...state, ...initialState };
    case SET_LOADING:
      return { ...state, loading: false, message: null };
    case CHANGE_FORGOT_EMAIL_NUMBER: 
     return { ...state, forgotEmailNumber: payload }
    case RESET_FORGOT_EMAIL_NUMBER:
      return { ...state, forgotEmailNumber: null };
    case USER_JWT_TOKEN_UPADATE:
      return { ...state, jwtAccessToken: payload };  
    default:
      return state;
  }
}
