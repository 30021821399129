import React from 'react'

export const CreateButton = ({ onClick, dictionary, text }) => {
    return (
        <button
            className="primary-cta"
            onClick={onClick}>
            {dictionary.s(text)}
        </button>
    )
};
