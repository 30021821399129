import {
    GET_REPORT_DATA,
    GET_REPORT_DATA_SUCCESS,
    GET_REPORT_DATA_FAIL,
    GET_VERIFICATION_FORM_INPUTS_REQUEST,
    GET_VERIFICATION_FORM_INPUTS_SUCCESS,
    GET_VERIFICATION_FORM_INPUTS_FAIL,
    GET_DECLARATION_FORM_INPUTS_REQUEST,
    GET_DECLARATION_FORM_INPUTS_SUCCESS,
    GET_DECLARATION_FORM_INPUTS_FAIL,

} from './types';
import { safelyApi } from "../../api/safely";
import ApiEndPoint from "../../constants/apiEndPoint";

export const getReportData = (body, apiEndpointName, page, limit, accesstokenidp) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_REPORT_DATA, payload: { reportType: apiEndpointName } });
            const response = await safelyApi(ApiEndPoint[apiEndpointName] + '/' + page + '/' + limit, { body, method: 'post', headers: { accesstokenidp: accesstokenidp } });
            if (response.status) { dispatch({ type: GET_REPORT_DATA_SUCCESS, payload: { reportType: apiEndpointName, data: response.data, totalCount: response.totalCount, totalPages: response.totalPages } }); }
            else { dispatch({ type: GET_REPORT_DATA_FAIL, payload: { message: response.error_description || response.systemError, ...response } }); }
            return response;
        } catch (e) {
            dispatch({ type: GET_REPORT_DATA_FAIL, payload: e });
            return { e, status: false };
        }
    }
}

export const getSubspaceHistory = (body, page, limit, accesstokenidp) => {
    const apiEndpointName = 'SubspaceActivity';
    return async (dispatch) => {
        try {
            dispatch({ type: GET_REPORT_DATA, payload: { reportType: apiEndpointName } });
            const response = await safelyApi(ApiEndPoint[apiEndpointName]  + page + '/' + limit, {body, method: 'post', headers: { accesstokenidp: accesstokenidp } });
            if (response.status) { dispatch({ type: GET_REPORT_DATA_SUCCESS, payload: { reportType: apiEndpointName, data: response.data, totalCount: response.totalCount, totalPages: response.totalPages } }); }
            else { dispatch({ type: GET_REPORT_DATA_FAIL, payload: { message: response.error_description || response.systemError, ...response } }); }
            return response;
        } catch (e) {
            dispatch({ type: GET_REPORT_DATA_FAIL, payload: e });
            return { e, status: false };
        }
    }
}

export const getVerificationFormInputs = (workSpaceId, startDate, endDate, accesstokenidp, timeZone) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_VERIFICATION_FORM_INPUTS_REQUEST, payload: {} });
            const response = await safelyApi(ApiEndPoint.GetVerificationFormInputs, {
                method: 'POST',
                headers: { accesstokenidp: accesstokenidp },
                body: {
                    workSpaceId: workSpaceId,
                    startDate: startDate,
                    endDate: endDate,
                    timeZone: timeZone
                }
            });
            if (response.status) { dispatch({ type: GET_VERIFICATION_FORM_INPUTS_SUCCESS, payload: { data: response.data, exportReportId: response.exportReportId } }); }
            else { dispatch({ type: GET_VERIFICATION_FORM_INPUTS_FAIL, payload: { message: response.error_description || response.systemError, ...response } }); }
            return response;
        } catch (e) {
            dispatch({ type: GET_VERIFICATION_FORM_INPUTS_FAIL, payload: { message: e } });
            return { e, status: false };
        }
    }
}

export const getDeclarationFormInputs = (workSpaceId, startDate, endDate, accesstokenidp, timeZone) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_DECLARATION_FORM_INPUTS_REQUEST, payload: {} });
            const response = await safelyApi(ApiEndPoint.GetDeclarationFormInputs, {
                method: 'POST',
                headers: { accesstokenidp: accesstokenidp },
                body: {
                    workSpaceId: workSpaceId,
                    startDate: startDate,
                    endDate: endDate,
                    timeZone: timeZone
                }
            });
            if (response.status) { dispatch({ type: GET_DECLARATION_FORM_INPUTS_SUCCESS, payload: { data: response.data, exportReportId: response.exportReportId } }); }
            else { dispatch({ type: GET_DECLARATION_FORM_INPUTS_FAIL, payload: { message: response.error_description || response.systemError, ...response } }); }
            return response;
        } catch (e) {
            dispatch({ type: GET_DECLARATION_FORM_INPUTS_FAIL, payload: { message: e } });
            return { e, status: false };
        }
    }
}