import React, { Component } from 'react'
import PropTypes from 'prop-types';
import '../../styles/multiselect.css';
import { replaceDiacritics } from "../../general/general";

export class Multiselect extends Component {
    constructor(props) {
        super(props);
        this.state ={
            checked: [],
            dropDownValue: [],
            searchValue:''
          }
          this.checkBox = this.checkBox.bind(this);
    }
    UNSAFE_componentWillMount() {
        this.setState({
            dropDownValue: this.props.options
        });
    }
    removeChip(value) {
        let checkedValue = this.state.checked;
        let index = checkedValue.indexOf(value);
        checkedValue.splice(index, 1);
        this.setState({
            checked: checkedValue
        }, () => {
            this.props.onSelectOptions(this.state.checked); 
        });
        this.setState({searchValue: ''});
        let optionsValues = this.props.options.filter((person) => !this.state.checked.includes(person.id));
        this.setState({dropDownValue: optionsValues});
    }
    checkBox(value, condition) {
        let checkedValue = this.state.checked;
        if(condition) {
            checkedValue.push(value);
        } else {
            //let index = checkedValue.indexOf(value);
            //checkedValue.splice(index, 1);
        }
        this.setState({
            checked: checkedValue
        }, () => {
            this.props.onSelectOptions(this.state.checked); 
        });
        this.setState({searchValue: ''});
        this.setState({dropDownValue: this.props.options});
    }
    searchFun(e) {    
        this.setState({searchValue: e.target.value.trim()});
        if(e.target.value.length !== 0) {
            let enteredValue = replaceDiacritics(e.target.value.trim().toLowerCase());
            let self = this;
            let optionsValues = this.state.dropDownValue.filter((person) => !this.state.checked.includes(person.id));
            let presentValue = optionsValues.filter(function(data) {
                if(self.props){
                let nameValue = replaceDiacritics(data[self.props.name].toLowerCase());
                let lastName = replaceDiacritics(data[self.props.lastName].toLowerCase());
                return nameValue.indexOf(enteredValue) > -1 || lastName.indexOf(enteredValue) > -1;
            }})
            this.setState({dropDownValue: presentValue});
        } else {
            this.setState({dropDownValue: this.props.options});
        }
    }
    getUserData(data, self){
        let userData = this.props.options.filter(function(result) {
            return result[self.props.value] === data;
        });
        if(userData.length > 0)
        {
        return userData[0][self.props.name] + ' ' + userData[0][self.props.lastName];
        }
        else{
            return  null;
        }
    }
    returnChip() {
        let self = this;
        const chip = this.state.checked.length > 0 ? this.state.checked.map((data, index) =>
            <div className="chip-body" key={index}>
            <p className="chip-text">{this.getUserData(data, self)}</p>
                <button className="chip-close" onClick={e => this.removeChip(data)}>&times;</button>
            </div>
        ) : []
        return chip;
    }
    returnList() {
        let optionsValues = this.state.dropDownValue.filter((person) => !this.state.checked.includes(person.id));
        const list = optionsValues.length > 0 ? optionsValues.map((data, index) =>
        <label className="container-dropdown" key={index}>{data[this.props.name] + ' ' + data[this.props.lastName]}
        <input type="checkbox" value={data[this.props.value]} onChange={e => this.checkBox(e.target.value, e.target.checked)} checked = {this.state.checked.includes(data[this.props.value]) ? true : false} autocomplete={this.props.autocomplete} />
        <span className="checkmark"></span>
    </label>
        ) : null;
        return list;
    }
    render() {
        return (
            <div className="multiSelect">
                <i className="fa fa-search" aria-hidden="true"></i>
                <input type="text" name="Search" placeholder={this.props.placeholder} className="input-box" onChange={e => this.searchFun(e)} autocomplete={this.props.autocomplete} value={this.state.searchValue} />
                <div className="search-result">
                    <div className="list-result">
                        {this.returnList()}
                    </div>
                </div>
                <div className="chip">
                    {this.returnChip()}
                </div>
            </div>
        )
    }
}

Multiselect.defaultProps = {
    options: []
}

/** define proptypes including fields which is required */
Multiselect.prototypes = {
    options: PropTypes.array.isRequired,
    onSelectOptions: PropTypes.func
}

export default Multiselect;