import React, { useEffect } from "react";
import { connect } from "react-redux";
import { general } from "../../general/global";
import Modal from "../commons/wrapped-components/Modal";
import Input from "../commons/styled-components/general/Input";
import { translate } from "../../redux/reducers/languageReducer";

const Rename = props => {
  useEffect(() => {
  }, []);

  const renderModalRenameSpace = () => (
    <Modal
      title={props.title}
      dialogClassName="safely-modal rename-space-modal-width"
      showModal={props.showModalRename}
      onHide={() => { props.onHideRename(); }}
      onSave={async () => { props.onSaveRename(); }}
      onClose={() => { props.onCloseRename(); }}
      textButtonSave={props.loading ? "..." : props.dictionary.s("rename")}
      textButtonClose={props.dictionary.s("cancel")}
      propertyButtonSave={{
        block: false,
        style: {
          backgroundColor: general.theme.primaryColor,
          color: general.theme.whiteFontColor,
          border: `1px solid ${general.theme.primaryColor}`
        }
      }}
      bsStyleButtonSave="secondary"
      buttonCloseProperties={{
        block: false,
        style: {
          backgroundColor: general.theme.whiteColor,
          border: `1px solid ${general.theme.primaryColor}`,
          color: general.theme.primaryColor
        }
      }}
      properties={{
        centered: true
      }}
      body={
        <>
          <Input
            type="text"
            maxLength="30"
            value={props.changedWorkspaceName}
            onChange={e => { props.renameChange(e.target.value); }}
            onKeyPress={event => {
              let regex = new RegExp("[0-9A-Za-z\u00C0-\u017F ]+$");
              let key = String.fromCharCode(
                !event.charCode ? event.which : event.charCode
              );
              if (!regex.test(key)) {
                event.preventDefault();
                return false;
              }
            }}
          />
          {(props.erroMessage) && (
            <div
              className="label-div"
              style={{
                marginHorizontal: 20,
                marginVertical: 10,
                textAlign: "center",
                color: general.theme.redColor
              }}
            >
              <label>{props.erroMessage}</label>
            </div>
          )}
        </>
      }
      isLoadingButton={props.loading}
    />
  );

  return renderModalRenameSpace();
};

const mapStateToProps = state => {
  return {
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    }
  };
};


export default connect(mapStateToProps, null)(Rename);
