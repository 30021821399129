import React, { useEffect } from "react";
import {
  //BrowserRouter as Router,
  HashRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Signup from "./components/onboarding/Signup";
import Welcome from "./components/onboarding/Welcome";
import CreateWorkspace from "./components/onboarding/CreateWorkspace";
import WorkspaceConfiguration from "./components/onboarding/WorkspaceConfiguration";
import SetWorkspace from "./components/onboarding/SetWorkspace";
import AcceptTerms from "./components/onboarding/AcceptTerms";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import PrivateRoute from "./PrivateRoute";
import Logout from "./components/Login/Logout";
import Callback from "./components/Login/CallbackSocial";
import ManageUser from "./components/User/Manage";
import EditUser from "./components/User/EditUser";
import WorkspaceList from "./components/Workspace/WorkspaceList";
import SubWorkspaceList from "./components/Workspace/SubWorkspaceList";
import SubWorkspaceListAddmember from "./components/Workspace/SubWorkspaceListAddmember";
import MainReports from "./components/reports/MainReports";
import VerificationReport from "./components/reports/VerificationReport";
import DeclarationReport from "./components/reports/DeclarationReport";
import UserVerification from "./components/safelypass/UserVerification";
import CheckpointExecute from "./components/checkpoint/CheckpointExecute";
import ScanUserID from "./components/checkpoint/ScanUserID";
import ForgotPassword from "./components/Login/ForgotPassword";
import MySafelyPass from "./components/safelypass/MySafelyPass";
import VerificationPoint from "./components/Verification/VerificationPoint";
import CheckInRegister from "./components/Verification/CheckInRegister";
import { SidebarItems } from "./constants/menuItems";
import { connect } from "react-redux";
import VerifyMobileNumber from "./components/onboarding/verifyMobileNumber";
import VerifyEmail from "./components/onboarding/verifyEmail";
import EditVerificationForm from "./components/checkpoint/EditVerificationForm";
import EditUserVerification from "./components/safelypass/EditUserVerification";
import Create from "./components/Transactions/Create";
import Execute from "./components/Transactions/Execute";
// import { general } from "./general/global";
import Notifications from "./notifications";
import {
  changeSafelyPassStatus,
  onRefreshCheckInRegisterScreen,
  getUsersForCheckpoint
} from "./redux/actions/operationActions";
import {
  getPaginatedUser,
  getWorkspacesList,
  changeWorkspaceFocusedForSafelyPass,
  pushUpdatedeleteSpace,
  pushUpdatedeleteSubspace,
  checkIntoSubspacePushNotification,
  checkOutIntoSubspacePushNotification,
  refreshCheckIntoSubspaceScreen,
  getCheckIntoSusbpaceList,
  checkOutIntoSubspace,
  getActivesSubspaceCheckIn,
  removeMemberWorkspace,
  updateSpaceSettingNotifation,
  getWorkspaceListWithPagination,
  getSubspacesByUserIdWorkSpaceId,
  refreshUserManagementScreen,
} from "./redux/actions/workspaceActions";
import * as notificationTypes from "./constants/notificationsTypes";
import { getUserProfileById, setUserData } from "./redux/actions/userActions";
import { general } from "./general/global";
import WorkspaceDetails from "./components/Workspace/WorkspaceDetails";
import { postPushData } from "./redux/actions/postAction";
import { toastSuccess, toastDismissAll } from "./components/commons/Toast";
import Marketplace from "./components/Marketplace/Marketplace";
import { languageChange } from "./redux/actions/languageActions";
import { getRolesList } from "./redux/actions/roleActions";
import { getReportData } from "./redux/actions/reports";
import subspaceReports from "./components/reports/subspaceReports";
import subspaceHistory from "./components/reports/subspaceHistory";
import CheckIntoSubspace from "./components/Workspace/CheckIntoSubspace";
import moment from 'moment';
import MainCheckPoint from "./components/Verification/MainCheckPoint";
import TransactionsPage from './components/Transactions/TransactionsPage';
import UploadTransaction from './components/Transactions/UploadTransaction';

import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./components/commons/styled-components/GlobalStyles";
import { Principal } from "./themes/themes";
import TransactionsReport from "./components/reports/TransactionsReport";
import TransactionPdfViewer from "./components/reports/TransactionPdfViewer";

const lang = localStorage.getItem("lang") || 'en';
moment.locale(lang);

const notifications = new Notifications();

function App(props) {
  SidebarItems(props.roleId, props.dictionary, props.settings);
  const onCheckOutSubspace = async () => {
    if (props.activesSubspaceCheckIn !== undefined && props.activesSubspaceCheckIn.length) {
      const activeSubspace = props.activesSubspaceCheckIn[0];
      const { _id: accessAuthorizationId } = activeSubspace;
      await props.onCheckOutIntoSubspace(accessAuthorizationId, props.accesstokenidp);
    }
  }

  useEffect(() => {
    props.onLanguageChange(props.lang);
    let unsubscribe = () => { };
    if (notifications.isSupported()) {
      notifications.requestPermission();
      unsubscribe = notifications.createNotificationListeners(
        ({ data, notification }) => {
          if (props.accessToken) {
            const { type, payload } = data;
            if (type) {
              refreshScreen(type, JSON.parse(payload));
            }
          }
        }
      );
    }

    const refreshScreen = async (
      type,
      { accessGranted, grantDate, ...rest }
    ) => {
      const space = props.workspacesList.find(x => x._id === rest.workSpaceId);
      switch (type) {
        case notificationTypes.USER_CHECKIN:
          if (props.userId === rest.userId) {
            activateSafelyPass(space, { accessGranted, grantDate, ...rest });
            await props.onGetCheckIntoSubspaceList(props.workSpaceId, props.userId, props.accesstokenidp);
            await props.onGetActivesSubspaceCheckIn(props.userId, props.accesstokenidp);
          } else if (general.isAdminOrMonitor(props.userId, space)) {
            props.onRefreshCheckInRegisterScreen(general.guid());
          }
          break;
        case notificationTypes.USER_CHECKOUT:
          if (props.userId === rest.userId) {
            props.onChangeSafelyPassStatus("inactive");
            await onCheckOutSubspace();
          } else if (general.isAdminOrMonitor(props.userId, space)) {
            props.onRefreshCheckInRegisterScreen(general.guid());
          }
          break;
        case notificationTypes.SUBSPACE_CHECKOUT:
          toastSuccess(props.lang === "en" ? rest.message_en : rest.message_es);
          break;
        case notificationTypes.WORKSPACE:
        case notificationTypes.CHECKLIST:
          props.onRefreshCheckIntoSubspaceScreen(general.guid());
          props.onGetReportData({ workSpaceId: props.workspaceFocused._id }, 'SubspacesActivity', props.accesstokenidp);
          await props.onGetWorkspacesList(props.userId, props.accesstokenidp);
          break;
        case notificationTypes.DISABLED_SAFELY_PASS:
        case notificationTypes.ENABLED_SAFELY_PASS:
          props.onRefreshUserManagementScreen(general.guid());
          props.onRefreshCheckInRegisterScreen(general.guid());
          break;
        case notificationTypes.USER:
          await syncUserData();
          await props.onGetWorkspaceListWithPagination();
          await props.onGetSubspacesByUserIdWorkSpaceId('');
          await props.onGetCheckIntoSubspaceList(props.workSpaceId, props.userId, props.accesstokenidp);
          await props.onGetPaginatedUser({ workSpaceId: props.workspaceFocused._id, searchString: "", adminId: props.userId }, 1, 10, props.accesstokenidp);
          await props.onGetWorkspacesList(props.userId, props.accesstokenidp);
          await props.onGetWorkspaceListWithPagination();
          await props.onGetSubspacesByUserIdWorkSpaceId();
          break;
        case notificationTypes.ADD_POST:
          if (
            rest.workspaceId === props.workspaceFocused._id &&
            window.location.href.split("#/")[1] === "workspaceDetails"
          ) {
            toastDismissAll();
            await props
              .onpostPushData(
                props.workspaceFocused._id,
                props.userId,
                props.accesstokenidp,
                1,
                props.postDetails.length > 10 ? (Math.ceil(props.postDetails.length / 10) * 10) : 10
              )
              .then(responce => {
                toastSuccess(props.dictionary.youhaveanewPostpleaserefresh);
              });
          }
          break;
        case notificationTypes.POST_LIKE:
        case notificationTypes.EDIT_POST:
        case notificationTypes.DELETE_POST:
          if (
            rest.workspaceId === props.workspaceFocused._id &&
            window.location.href.split("#/")[1] === "workspaceDetails"
          ) {
            const focusedPost = props.postDetails.findIndex(x => rest._id === x._id);
            if (focusedPost >= 0) {
              await props.onpostPushData(
                props.workspaceFocused._id,
                props.userId,
                props.accesstokenidp,
                1,
                props.postDetails.length > 10 ? (Math.ceil(props.postDetails.length / 10) * 10) : 10
              );
            }
          }
          break;
        case notificationTypes.DELETE_WORKSPACE:
        case notificationTypes.DELETE_SUBSPACE:
          props.onGetReportData({ workSpaceId: props.workspaceFocused._id }, 'SubspacesActivity', props.accesstokenidp);
          if (
            props.workspaceSubFocused._id === rest.deletedSubspaceId &&
            window.location.href.split("#/")[1] ===
            "subWorkspaceListAddmember" &&
            type === notificationTypes.DELETE_SUBSPACE
          ) {
            toastSuccess(props.dictionary.deletedSubspace);
            setTimeout(function () {
              window.location.hash = "#/subWorkspacelist";
            }, 1500);
          }
          if (
            rest._id === props.workspaceFocused._id &&
            type === notificationTypes.DELETE_WORKSPACE
          ) {
            toastSuccess(props.dictionary.deletedspace);
            setTimeout(function () {
              window.location.hash = "#/workspacelist";
            }, 1500);
          }
          await props.onGetRolesList();
          if (type === notificationTypes.DELETE_WORKSPACE) {
            props.onPushUpdatedeleteSpace(props.workSpacesProps, rest._id);
            await props.onGetWorkspaceListWithPagination();
          }
          if (type === notificationTypes.DELETE_SUBSPACE) {
            props.onPushUpdatedeleteSubspace(props.workSpacesProps, rest._id, rest.deletedSubspaceId);
            await props.onGetSubspacesByUserIdWorkSpaceId('');
          }
          if (
            rest._id === props.workspaceFocused._id &&
            window.location.href.split("#/")[1] === "workspaceDetails" &&
            type === notificationTypes.DELETE_SUBSPACE
          ) {
            await props.onpostPushData(
              props.workspaceFocused._id,
              props.userId,
              props.accesstokenidp,
              1,
              props.postDetails.length > 10 ? (Math.ceil(props.postDetails.length / 10) * 10) : 10
            );
          }
          props.onRefreshCheckInRegisterScreen(general.guid());
          break;
        case notificationTypes.USER_SUBSPACE_CHECKIN:
          props.onGetReportData({ workSpaceId: props.workspaceFocused._id }, 'SubspacesActivity', props.accesstokenidp);
          props.onCheckIntoSubspacePushNotification({ ...rest, grantDate, userIdSelected: props.userId });
          break;
        case notificationTypes.USER_SUBSPACE_CHECKOUT:
          props.onGetReportData({ workSpaceId: props.workspaceFocused._id }, 'SubspacesActivity', props.accesstokenidp);
          props.onCheckOutIntoSubspacePushNotification({ ...rest, userIdSelected: props.userId });
          break;
        case notificationTypes.ACCESSAUTHORIZATION:
          break;
        case notificationTypes.REMOVE_USER_FROM_SPACE:
          props.onRefreshCheckInRegisterScreen(general.guid());
          if (props.workspaceFocused._id === rest.workSpaceId &&
            props.userId === rest.userId &&
            window.location.hash !== "#/workspacelist")
            window.location.hash = "#/workspacelist";
          else
            if (props.userId === rest.userId) {
              await props.onGetWorkspaceListWithPagination();
              await props.onRemoveMemberWorkspace(props.workSpacesProps, rest.userId, rest.workSpaceId);
            }
          break;
        case notificationTypes.EDIT_SPACE_SETTINGS:
          await props.onUpdateSpaceSettingNotifation(rest, props.workSpacesProps)
          if (
            props.workspaceFocusedForSafelyPass._id ===
            rest._id
          ) {
            if (!props.isSafelyPassActivated) {
              props.onChangeSafelyPassStatus("inactive");
              if (window.location.href.split("#/")[1] === "userVerification" || window.location.href.split("#/")[1] === "safelypass") {
                toastDismissAll();
                toastSuccess(props.dictionary.updateYourSettings);
                setTimeout(function () {
                  window.location.hash = "#/workspaceDetails";
                }, 1500);
              }
            }
          }
          break;
        default:
          break;
      }
    };

    return () => {
      unsubscribe();
    };
  }, [
    props.accessToken,
    props.userId,
    props.workspacesList,
    props.workspaceFocused,
    props.activesSubspaceCheckIn,
    props.postDetails,
    props.workspaceFocusedForSafelyPass
  ]);

  const activateSafelyPass = (space, { accessGranted, grantDate, ...rest }) => {
    const safelyPassStatus = accessGranted ? "granted" : "denied";
    // if (space) props.onSetWorkspaceFocused(space);
    if (space) props.onChangeWorkspaceFocusedForSafelyPass(space);
    props.onChangeSafelyPassStatus(safelyPassStatus, {
      accessGranted,
      grantDate: new Date(grantDate),
      ...rest
    });
  };

  const syncUserData = async () => {
    const { status, data } = await props.onGetUserProfileById(props.userId);
    if (status) {
      const {
        email,
        countryCode,
        mobilePhone,
        userName,
        name,
        lastName,
        picture,
        workSpaces,
        backgroundPicture
      } = data;
      props.onSetUserData({
        email,
        countryCode,
        mobilePhone,
        userName,
        name,
        lastName,
        picture,
        workSpaces,
        backgroundPicture
      });
    }
  };

  return (
    <ThemeProvider theme={Principal}>
      <GlobalStyles />
      <Router hashType="slash">
        <Switch>
          <PrivateRoute path="/" type="login" component={Login} exact
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path="/dashboard"
            type="bypass"
            title={props.dictionary.Dashboard}
            component={Dashboard}
            exact
            role={["0", "1", "2", "3"]}
          />
          <Route
            path="/forgotpassword"
            type="forgotpassword"
            component={ForgotPassword}
            exact
          />
          <PrivateRoute
            path="/userVerification"
            type="userverification"
            title={props.dictionary.safelyPass}
            component={UserVerification}
            isEditView={false}
            exact
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path="/editVerification"
            type="userverification"
            title={props.dictionary.safelyPass}
            component={UserVerification}
            isEditView={true}
            exact
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path="/editUserVerification"
            type="userverification"
            title={props.dictionary.safelyPass}
            component={EditUserVerification}
            isEditView={true}
            exact
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path="/users"
            type="users"
            title={props.dictionary.userManagement}
            component={ManageUser}
            exact
            role={["0"]}
          />
          <PrivateRoute
            path="/useredit"
            type="useredit"
            title={props.dictionary.editUser}
            component={EditUser}
            exact
            role={["0"]}
          />

          <PrivateRoute
            path="/scanUserID"
            type="scanUserID"
            title={props.dictionary.checkpoint}
            component={ScanUserID}
            exact
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path="/verificationPoint"
            type="verificationPoint"
            title={props.dictionary.verificationPoint}
            component={VerificationPoint}
            exact
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path="/checkInRegister/:activeKey?"
            type="checkInRegister"
            title={props.dictionary.checkpoint}
            component={MainCheckPoint}
            exact
            role={["0", "1"]}
          />
          <PrivateRoute
            path="/checkpoint/:checkInByMonitor?"
            type="checkpoint"
            title={props.dictionary.verificationPoint}
            component={CheckpointExecute}
            isEditView={false}
            exact
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path="/editcheckpoint"
            type="checkpoint"
            title={props.dictionary.verificationPoint}
            component={CheckpointExecute}
            isEditView={true}
            exact
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path="/editverificationform"
            type="editverificationform"
            title={props.dictionary.verificationPoint}
            component={EditVerificationForm}
            exact
            role={["0", "1", "2", "3"]}
          />
          <Route path="/exit" type="logout" component={Logout} exact />
          <PrivateRoute exact path="/signup" type="signup" component={Signup}
            role={["0", "1", "2", "3"]}
          />
          <Route
            exact
            path="/verifyMobileNumber"
            type="verifyMobileNumber"
            component={VerifyMobileNumber}
          />
          <Route
            path="/verifyemail"
            type="verifyemail"
            component={VerifyEmail}
            exact
          />
          <PrivateRoute
            path="/access_token=:access_token&expires_in=:expires_in&token_type=:token_type"
            type="callback"
            component={Callback}
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute // Callback with report redirection
            path="/access_token=:access_token&report_key=:reportKey&user_id=:userId&space_id=:workSpaceId?"
            type="callback"
            component={Callback}
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute // Callback with report redirection
            path="/access_token=:access_token&report_type=:reportType&user_id=:userId&space_id=:workSpaceId?"
            type="callback"
            component={Callback}
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute path="/welcome" type="bypass" component={Welcome}
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path="/create-workspace"
            type="bypass"
            component={CreateWorkspace}
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path="/workspace-configuration"
            type="bypass"
            component={WorkspaceConfiguration}
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path="/set-workspace"
            type="bypass"
            component={SetWorkspace}
            role={["0", "1", "2", "3"]}
          />
          <Route
            path="/termsandconditions"
            type="termsandconditions"
            component={AcceptTerms}
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path="/workspacelist"
            type="bypass"
            title={props.dictionary.home}
            component={WorkspaceList}
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path="/subWorkspacelist"
            type="/subWorkspacelist"
            title={props.workspaceFocused.name}
            component={SubWorkspaceList}
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path="/subWorkspaceListAddmember"
            type="/subWorkspaceListAddmember"
            title={props.workspaceSubFocused.name}
            component={SubWorkspaceListAddmember}
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path={"/reportlist/:reportKey?"}
            type="report"
            title={props.dictionary.reports}
            component={MainReports}
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path="/verificationReport"
            type="report"
            title={props.dictionary.reports}
            component={VerificationReport}
            role={["0", "1"]}
          />
          <PrivateRoute
            path="/selfdeclarationReport"
            type="report"
            title={props.dictionary.reports}
            component={DeclarationReport}
            role={["0", "1"]}
          />
          <PrivateRoute
            path={"/subspacesReport/:reportKey?"}
            type="report"
            title={props.dictionary.reports}
            component={subspaceReports}
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path={"/subspaceHistory"}
            type="/subspaceHistory"
            title={props.userMgmtName}
            component={subspaceHistory}
            role={["0"]}
          />
          <PrivateRoute
            path="/transactionsHistory"
            type="/transactionsHistory"
            title={props.dictionary.reports}
            component={TransactionsReport}
            role={["0", "1", "2"]}
          />
          <PrivateRoute
            path="/transactionPdfViewer/:fileName?"
            type="transactionPdfViewer"
            title={props.dictionary.transactions}
            component={TransactionPdfViewer}
            role={["0", "1", "2"]}
          />
          <PrivateRoute
            path="/safelypass"
            type="/safelypass"
            title={props.dictionary.safelyPass}
            component={MySafelyPass}
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path="/workspaceDetails"
            type="/workspaceDetails"
            title={props.workspaceFocused.name}
            component={WorkspaceDetails}
            role={["0", "1", "2", "3"]}
          />
          {/* <PrivateRoute
            path="/marketPlace"
            type="/marketPlace"
            title={props.dictionary.marketplace}
            component={Marketplace}
            role={["0", "1", "2", "3"]}
          /> */}
          <PrivateRoute
            path="/checkIntoSubspace"
            type="/checkIntoSubspace"
            title={props.dictionary.mySubspaces}
            component={CheckIntoSubspace}
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path="/transactions"
            type="/transactions"
            title={props.dictionary.transactionsTitle}
            component={TransactionsPage}
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path="/uploadTransaction"
            type="/uploadTransaction"
            title={props.dictionary.transactionsTitle}
            component={UploadTransaction}
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path="/createTransaction"
            type="/createTransaction"
            component={Create}
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            edit
            path="/editTransaction"
            type="/editTransaction"
            component={Create}
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute
            path="/executeTransaction"
            type="executeTransaction"
            component={Execute}
            role={["0", "1", "2", "3"]}
          />
          <PrivateRoute // Callback with report redirection
            path="/access_token=:access_token&user_id=:userId&space_id=:workSpaceId&view=:view&transactionFormId=:transactionId&transactionFormName=:transactionName"
            type="callback"
            component={Callback}
            role={["0", "1", "2", "3"]}
          />
          <Redirect to="/" />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

const mapStateToProps = state => {
  return {
    accessToken: state.login.accessToken,
    roleId: state.workspace.spaceFocused.roleId,
    settings: state.workspace.workspaceFocused.settings,
    VerificationSended: state.operation.VerificationSended,
    dictionary: state.language.dictionary,
    workspacesList: state.workspace.list,
    userId: state.user.id,
    accesstokenidp: state.user.accesstokenidp,
    workspaceFocused: state.workspace.workspaceFocused,
    lang: state.language.lang,
    workSpacesProps: state.workspace,
    workSpaceId: state.workspace.workspaceFocused._id,
    activesSubspaceCheckIn: state.workspace.activesSubspaceCheckIn,
    postDetails: state.posts.postData,
    workspaceSubFocused: state.workspace.workspaceSubFocused,
    workspaceFocusedForSafelyPass:
      state.workspace.workspaceFocusedForSafelyPass,
    isSafelyPassActivated: state.operation.safelyPassStatus === "granted",
    userMgmtName: state.user.userMgmtClickedName,
    // subspaceFocused: state.workspace.subspaceFocused,
  };
};

const mapDispatchToProps = dispatch => ({
  onChangeSafelyPassStatus: (status, extraInfo) =>
    dispatch(changeSafelyPassStatus(status, extraInfo)),
  // onSetWorkspaceFocused: workspace => dispatch(setWorkspaceFocused(workspace)),
  onGetPaginatedUser: (body, page, limit, accesstokenid) =>
    dispatch(getPaginatedUser(body, page, limit, accesstokenid)),
  onGetWorkspacesList: (userId, accesstokenidp) =>
    dispatch(getWorkspacesList(userId, accesstokenidp)),
  onGetUserProfileById: userId => dispatch(getUserProfileById(userId)),
  onSetUserData: user => dispatch(setUserData(user)),
  onRefreshCheckInRegisterScreen: refresh =>
    dispatch(onRefreshCheckInRegisterScreen(refresh)),
  onpostPushData: (workspaceId, userId, accesstokenidp, pageNo, limit) =>
    dispatch(postPushData(workspaceId, userId, accesstokenidp, pageNo, limit)),
  onChangeWorkspaceFocusedForSafelyPass: workspace =>
    dispatch(changeWorkspaceFocusedForSafelyPass(workspace)),
  onLanguageChange: (language) => dispatch(languageChange(language)),
  onGetRolesList: () => dispatch(getRolesList()),
  onPushUpdatedeleteSpace: (workSpacesProps, workspaceId) =>
    dispatch(pushUpdatedeleteSpace(workSpacesProps, workspaceId)),
  onPushUpdatedeleteSubspace: (workSpacesProps, workspaceId, subSpaceId) =>
    dispatch(pushUpdatedeleteSubspace(workSpacesProps, workspaceId, subSpaceId)),
  onCheckIntoSubspacePushNotification: (data) =>
    dispatch(checkIntoSubspacePushNotification(data)),
  onCheckOutIntoSubspacePushNotification: (data) =>
    dispatch(checkOutIntoSubspacePushNotification(data)),
  onGetReportData: (requestBody, apiEndpointName, accesstokenidp) => dispatch(getReportData(requestBody, apiEndpointName, accesstokenidp)),
  onRefreshCheckIntoSubspaceScreen: (uid) => dispatch(refreshCheckIntoSubspaceScreen(uid)),
  onGetCheckIntoSubspaceList: (workSpaceId, userId, accesstokenidp) =>
    dispatch(getCheckIntoSusbpaceList(workSpaceId, userId, accesstokenidp)),
  onGetActivesSubspaceCheckIn: (userId, accesstokenidp) =>
    dispatch(getActivesSubspaceCheckIn(userId, accesstokenidp)),
  onCheckOutIntoSubspace: (accessAuthorizationId, accesstokenidp) =>
    dispatch(checkOutIntoSubspace(accessAuthorizationId, accesstokenidp)),
  onRemoveMemberWorkspace: (workSpacesProps, userId, workSpaceId) =>
    dispatch(removeMemberWorkspace(workSpacesProps, userId, workSpaceId)),
  onUpdateSpaceSettingNotifation: (dataResponce, workSpacesProps) =>
    dispatch(updateSpaceSettingNotifation(dataResponce, workSpacesProps)),
  onGetWorkspaceListWithPagination: () => dispatch(getWorkspaceListWithPagination()),
  onGetSubspacesByUserIdWorkSpaceId: (search) => dispatch(getSubspacesByUserIdWorkSpaceId(search)),
  onGetUsersForCheckpoint: (body) => dispatch(getUsersForCheckpoint(body)),
  onRefreshUserManagementScreen: refresh => dispatch(refreshUserManagementScreen(refresh)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);