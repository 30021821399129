/*  Libraries */
import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

/*  Configurations, Variables  */
import { general } from "../../general/global";
import Notifications from "../../notifications";
import * as toastType from "../../constants/toastTypes";
import * as inputTypes from "../../constants/inputTypes";

/*  Components */
import MainNavigation from "../Navigation/MainNavigation";
import Modal from "../commons/wrapped-components/Modal";
import Avatar from "../commons/wrapped-components/Avatar";
import TimeLine from "../commons/Timeline";
import { ReactComponent as EditingIcon } from "../../icons/pen.svg";
import { showToast } from "../commons/Toast";
import ImageLoader from "../commons/wrapped-components/ImageLoader";

/*  Reducers */
import { translate } from "../../redux/reducers/languageReducer";

/*  Actions */
import {
  setUserVerification,
  addUserVerification,
  valueChangeInputUserVerification,
  changeSafelyPassStatus,
  requestAccessAuthorization,
  getAccessAuthorizationActivesList,
  checkOut
} from "../../redux/actions/operationActions";
import { getCheckList, } from "../../redux/actions/editFormActions";
import { checkWorkspaceIsDisabled } from "../../redux/actions/workspaceActions";

const notifications = new Notifications();
const UserVerification = props => {
  const [showModal, setShowModal] = useState(false);
  const [spaceActivated, setSpaceActivated] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [contentMarkdown, setContentMarkdown] = useState(null);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    init();
  }, [props.workspaceFocusedForSafelyPass, props.safelyPassStatus]);

  const init = async () => {
    let _contentMarkdown = "";
    let _workSpaceId = "";
    let _inputs = [];

    if (props.isEditView) {
      const { status, data } = await props.onGetCheckList(props.workSpaceId, props.accesstokenidp);
      if (status) {
        _contentMarkdown = data.userVerificationChecklist[0].contentMarkdown;
        _inputs = data.userVerificationChecklist[0].inputs;
        _workSpaceId = props.workSpaceId;
      }
    }
    else {
      _contentMarkdown = props.workspaceFocusedForSafelyPass.userVerificationChecklist[0].contentMarkdown;
      _inputs = props.workspaceFocusedForSafelyPass.userVerificationChecklist[0].inputs;
      _workSpaceId = props.workspaceFocusedForSafelyPass._id;
      const { status, data } = await props.onGetAccessAuthorizationActiveByUserId(props.userId);
      if (status && data.length && data[0].accessGranted) {
        setSpaceActivated(true);
        props.history.push('/safelypass')
      }
    }
    setContentMarkdown(_contentMarkdown);
    await props.onSetUserVerification({
      userId: props.userId,
      workSpaceId: _workSpaceId,
      inputs: _inputs
    });
    setShowLoader(false);
  }

  const validateRequired = () => props.inputs.find(i => i.required === true && !i.value) ? false : true;

  const requestSafelyPass = async () => {
    if (validateRequired()) {
      /*  validations */
      const validations = props.inputs.find(x =>
        x.type === inputTypes.BOOLEAN && x.value && (x.denyPass === true || x.denyPass === undefined) ||
        x.type === inputTypes.BUTTONGROUP && x.content.buttonList.find(i => i.value === x.value).denyAccess === true ||
        x.type === inputTypes.MULTIPLE && x.content.options.find(i => i.value === x.value)?.denyAccess === true ||
        x.type === inputTypes.RANGE && (Number(x.value) < Number(x.content.min) || Number(x.value) > Number(x.content.max)) ||
        x.type === inputTypes.DROPDOWN && x.content.options.find(i => i.value === x.value)?.denyAccess === true
      );

      if (validations) {
        // props.onChangeSafelyPassStatus('canceled');
        setShowModal(true);
        await props.onAddUserVerification(
          props.userId,
          props.workSpaceId,
          props.inputs,
          "No"
        );
      } else {
        props.onChangeSafelyPassStatus('pending');
        const response = await props.onAddUserVerification(
          props.userId,
          props.workSpaceId,
          props.inputs,
          null
        );
        if (response.status) {
          let fcmToken = ''
          if (notifications.isSupported()) {
            fcmToken = await notifications.getToken();
          }
          await props.onRequestAccessAuthorization(
            props.workSpaceId, props.userId, response.data[0]._id, fcmToken
          );
        }
        props.history.push("/safelypass");
      }
    }
    else
      showToast(toastType.ERROR, props.dictionary.s('requiredValidation'));
  }

  const getSpacePicture = () => {
    if (props.workspaceFocused.picture) {
      return (
        <Avatar
          src={props.workspaceFocused.picture}
          className="avatar-circle-small"
          styles={{ boxShadow: '0px 0px 0px 5px #fff' }}
        />
      );
    } else {
      return (
        <div
          className="avatar-circle-small"
          style={{
            backgroundColor: props.workspaceFocused.settings.backgroundColor,
            padding: '10px 8px',
          }}
        >
          {props.workspaceFocused.name.substring(0, 2)}
        </div>
      )
    }
  };

  const workspaceIsDisabled = async () => {
    const { data, status } = await props.onCheckWorkspaceIsDisabled(props.userId, props.workspaceFocused._id);
    if (status && data) return data.disabled;
    return false;
  }

  return (
    <MainNavigation title={props.title}>
      <Container fluid className="d-flex flex-column">
        {
          showLoader ? <ImageLoader height='82vh' width='100%' borderRadius='' /> :
            <>
              <Row style={{ padding: "30px 25px 0" }}>
                <Col md={12} className='mt-2 d-flex justify-content-between'>
                  <label style={styles.title}>{props.dictionary.s("selfDeclaration")}</label>
                  {props.isEditView && props.workspaceFocused.users.find(
                    user => user.id === props.userId
                  ).roleId === "0" &&
                    <div onClick={() => props.history.push("/editUserVerification")} className="EditionDiv">
                      <EditingIcon />
                      <span className="pl-2 font-weight-bold">
                        {props.dictionary.s("Edit")}
                      </span>
                    </div>
                  }
                </Col>
              </Row>
              <Row style={{ padding: "15px 25px 0" }}>
                <Col className="d-flex align-items-end">
                  {getSpacePicture()}
                  <label
                    title={props.isEditView ? props.workspaceFocused.name : props.workSpaceName}
                    style={{
                      fontSize: 18,
                      fontWeight: "600",
                      color: general.theme.fontColorPrimary,
                      marginLeft: '15px',
                    }}
                  >
                    {props.isEditView ? props.workspaceFocused.name : props.workSpaceName}
                  </label>
                </Col>
              </Row>
              <Row style={{ padding: "20px 25px 0" }}>
                <Col>
                  {contentMarkdown !== null &&
                    <pre className="text-content text-content-pre"
                      style={{
                        fontFamily: "Roboto, Regular",
                        fontSize: 17,
                        color: "#6E7E88"
                      }}>
                      {contentMarkdown}
                    </pre>
                  }
                  <TimeLine
                    items={props.inputs}
                    onValueChange={(id, value) => props.onValueChange(id, value)}
                    dictionary={props.dictionary}
                  />
                </Col>
              </Row>
              <Row style={{ padding: "20px 25px 0" }}>
                <Col style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                  <div style={{ paddingTop: "20px" }}>
                    {props.message && (
                      <div style={{ marginTop: 10 }}>
                        <label
                          style={{
                            textAlign: "center",
                            color: general.theme.dangerColor,
                          }}
                        >
                          {props.message}
                        </label>
                      </div>
                    )}
                    {!props.isEditView &&
                      <Button
                        style={{
                          backgroundColor: (spaceActivated || !validateRequired() ? general.theme.grayColor : general.theme.primaryColor),
                          borderColor: (spaceActivated || !validateRequired() ? general.theme.grayColor : general.theme.primaryColor),
                          fontWeight: 600,
                        }}
                        onClick={async () => {
                          if (!await workspaceIsDisabled()) {
                            setButtonDisabled(true);
                            const { status, data } = await props.onGetAccessAuthorizationActiveByUserId(props.userId);
                            if (status && data.length) {
                              const workSpace = props.workSpacesList.find(x => x._id === data[0].workSpaceId);
                              if (workSpace) {
                                const { accessGranted, grantDate, ...rest } = data[0];
                                if (accessGranted) {
                                  props.onChangeSafelyPassStatus('granted', { accessGranted, grantDate: new Date(grantDate), ...rest });
                                } else requestSafelyPass();
                              }
                            } else requestSafelyPass();
                            setButtonDisabled(false);
                          }
                        }}
                        disabled={props.loading || props.loadingWorkspace || spaceActivated || buttonDisabled || !validateRequired()}
                      >
                        {props.dictionary.s(
                          props.buttonText
                        )}
                      </Button>
                    }
                  </div>
                </Col>
              </Row>
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Col sm={12}>
                  <Modal
                    title={props.dictionary.s("safelyPass")}
                    className="animated fadeInDown safelyPass-popup"
                    showModal={showModal}
                    onHide={() => {
                      setShowModal(false);
                      props.onCheckOut('');
                      props.history.push("/workspaceDetails");
                    }}
                    onSave={() => {
                      setShowModal(false);
                      props.onCheckOut('');
                      props.history.push("/workspaceDetails");
                    }}
                    textButtonSave={props.dictionary.s("close")}
                    bsStyleButtonSave="secondary safelyPass-popup-button"
                    propertyButtonSave={{
                      className: "border-radius-none",
                      block: true
                    }}
                    properties={{ centered: true }}
                    body={
                      <>
                        <div className="safelyPass-popup-text1">
                          {props.dictionary.s("yourSafelyPassHavenotBeenGenerated1")}
                        </div>
                        <div className="safelyPass-popup-text2">
                          {props.dictionary.s("yourSafelyPassHavenotBeenGenerated2")}
                        </div>
                      </>
                    }
                  />
                </Col>
              </Row>
            </>
        }
      </Container>
    </MainNavigation >
  );
};

const styles = {
  content: {
    flexGrow: 1,
    backgroundColor: "#F9FBFE",
    alignItems: "center"
  },
  innerContent: {
    flex: 1,
    width: 310,
    paddingTop: 20
  },
  titleContent: {
    paddingVertical: 15,
    marginBottom: 10
    //borderBottomWidth: 1,
    //borderBottomColor: colorsPalette.PRIMARY
  },
  title: {
    fontWeight: "bold",
    color: general.theme.fontColorTitleCurrentPage,
    fontSize: 22,
    marginBottom: 0
  }
};

const mapStateToProps = state => {
  const sortedList = state.workspace.list.sort((a, b) => a.name.localeCompare(b.name));
  const workspaceSettings = state.workspace.workspaceFocusedForSafelyPass.settings;
  const { selfDeclaration, verificationPoint } = workspaceSettings;
  let buttonText = 'generateASafelyPass';
  switch (true) {
    case state.operation.loading:
      buttonText = 'loading';
      break;
    case (selfDeclaration && !verificationPoint):
      buttonText = 'activateSafelyPass';
      break;
  }

  return {
    buttonText,
    workspaceSettings,
    inputs: state.operation.userVerificationInputs,
    // workSpaceId: state.operation.userVerification.workSpaceId,
    workSpaceId: state.workspace.spaceFocused._id,
    accesstokenidp: state.user.accesstokenidp,
    userId: state.user.id,
    message: state.operation.message,
    loading: state.operation.loading,
    safelyPassStatus: state.operation.safelyPassStatus,
    loadingWorkspace: state.workspace.loading,
    workSpaceName: state.workspace.workspaceFocusedForSafelyPass.name,
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    },
    workSpacesList: sortedList,
    workspaceFocusedForSafelyPass: state.workspace.workspaceFocusedForSafelyPass,
    workspaceFocused: state.workspace.workspaceFocused,
    spaceFocused: state.workspace.spaceFocused,
  }
};

const mapDispatchToProps = dispatch => ({
  onAddUserVerification: (userId, workSpaceId, inputValues, passGenerated) =>
    dispatch(addUserVerification(userId, workSpaceId, inputValues, passGenerated)),
  onValueChange: (id, value) =>
    dispatch(valueChangeInputUserVerification(id, value)),
  onSetUserVerification: userVerification =>
    dispatch(setUserVerification(userVerification)),
  onChangeSafelyPassStatus: status => dispatch(changeSafelyPassStatus(status)),
  onRequestAccessAuthorization: (workSpaceId, userId, userVerificationId, deviceId) =>
    dispatch(requestAccessAuthorization(workSpaceId, userId, userVerificationId, deviceId)),
  onCheckOut: (id) => dispatch(checkOut(id)),
  onGetAccessAuthorizationActiveByUserId: userId => (dispatch(getAccessAuthorizationActivesList(userId))),
  onGetCheckList: (userId, accesstokenidp) => dispatch(getCheckList(userId, accesstokenidp)),
  onCheckWorkspaceIsDisabled: (userId, workspaceId) =>
    dispatch(checkWorkspaceIsDisabled(userId, workspaceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserVerification);