import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import MainNavigation from '../Navigation/MainNavigation';
import CheckInRegister from './CheckInRegister';
import { connect } from 'react-redux';
import { translate } from '../../redux/reducers/languageReducer';
import { setActiveKey } from '../../redux/actions/operationActions';
import { showToast } from '../commons/Toast';
import * as toastType from "../../constants/toastTypes";
import { DesactiveToast } from '../../redux/actions/toastAction';
import { toastSuccess, toastError } from "../commons/Toast.js";
import { ToastSuccess, ToastError } from "../../redux/actions/toastAction";

const MainCheckPoint = ({ title, dictionary, history, onSetActiveKey, ...props }) => {
    const key = props.match.params.activeKey || 'members';

    useEffect(() => {
        if (props.isToastSuccess) showToast(toastType.SUCCESS, props.toastMessage);
        else if (props.isToastError) showToast(toastType.ERROR, props.toastMessage);
        props.onDeactivateToast();
        onSetActiveKey(key);
    }, []);

    // effect to show Toast Notification
    useEffect(() => {
        if (props.toastMessage) showToastNotification();
    }, [props.toastMessage]);

    const showToastNotification = () => {
        if (props.isToastSuccess) { toastSuccess(props.toastMessage); }
        else if (props.isToastError) { toastError(props.toastMessage); }
        props.onDeactivateToast();
    };

    return (
        <MainNavigation title={title}>
            <Tabs id="checkpoint-tab"
                activeKey={key}
                variant="pills" className="tabs-container"
                onSelect={(eventKey) => {
                    history.push(`/checkInRegister/${eventKey}`);
                    onSetActiveKey(eventKey);
                }}
            >
                <Tab tabClassName="tab-checkpoint" eventKey="members" title={dictionary.s('members')} >
                    {props.activeKey && props.activeKey === 'members' ? <CheckInRegister type='members' history={history} match={props.match} /> : null}
                </Tab>
                <Tab tabClassName="tab-checkpoint" eventKey="visitors" title={dictionary.s('visitors')} >
                    {props.activeKey && props.activeKey === 'visitors' ? <CheckInRegister type='visitors' history={history} match={props.match} /> : null}
                </Tab>
            </Tabs>
        </MainNavigation>
    )
}

const mapStateToProps = (state) => ({
    dictionary: {
        s: (key) => translate(state.language.dictionary, key),
    },
    activeKey: state.operation.activeKey,
    toastMessage: state.toast.message,
    isToastSuccess: state.toast.isSuccess,
    isToastError: state.toast.isError,
});

const mapDispatchToProps = (dispatch) => ({
    onSetActiveKey: (key) => dispatch(setActiveKey(key)),
    onToastSuccess: (message) => dispatch(ToastSuccess(message)),
    onToastError: (message) => dispatch(ToastError(message)),
    onDeactivateToast: () => dispatch(DesactiveToast()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainCheckPoint);
