import {
    EDIT_FORM_GET_REQUEST,
    EDIT_FORM_GET_SUCCESS,
    EDIT_FORM_GET_FAIL,
    UPDATE_CONTENT,
    UPDATE_CONTENT_SUCCESS,
    UPDATE_CONTENT_FAIL,
    UPDATE_INPUT,
    UPDATE_INPUT_SUCCESS,
    UPDATE_INPUT_FAIL
  } from "../actions/types";
  
  export const initialState = {
    loading: true,
    data: {},
    content: "",
    inputs: null
  };
  
  export default (state, action) => {
    const { type, payload } = action;
    if (typeof state === "undefined") {
      return initialState;
    }
    switch (type) {
      case EDIT_FORM_GET_REQUEST:
        return { ...state, loading: true };
      case EDIT_FORM_GET_SUCCESS:
        return {
          ...state,
          loading: false,
          data: payload
        };
      case EDIT_FORM_GET_FAIL:
        return { ...state, loading: false, data: payload };
      case UPDATE_INPUT:
        return { ...state, loading: true };
      case UPDATE_INPUT_SUCCESS:
        return { ...state, loading: false, data: payload };
      case UPDATE_INPUT_FAIL:
        return { ...state, loading: false, data: payload };
      case UPDATE_CONTENT:
        return { ...state, loading: true };
      case UPDATE_CONTENT_SUCCESS:
        return { ...state, loading: false, data: payload };
      case UPDATE_CONTENT_FAIL:
        return { ...state, loading: false, data: payload };
      default:
        return state;
    }
  };  