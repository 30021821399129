/*  Libraries */
import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';

/*  Configurations, Variables  */
import * as roles from "../../constants/roles";
import * as toastType from "../../constants/toastTypes";

/*  Components */
import MainNavigation from "../Navigation/MainNavigation";
import Space from '../commons/wrapped-components/Space';
import Table from '../commons/wrapped-components/Table';
import Label from '../commons/styled-components/general/Label';
import InputSearch from '../commons/wrapped-components/inputs/InputSearch';
import { CreateButton } from '../Workspace/CreateButton';
import { ReactComponent as PlayBlue } from "../../icons/play_blue.svg";
import ToggleMenu from '../Workspace/ToggleMenu';
import Modal from '../commons/wrapped-components/Modal';
import InputText from '../commons/wrapped-components/inputs/InputText';
import { showToast } from "../commons/Toast";
import DownloadQRCode from "../commons/wrapped-components/DownloadQRCode";

/*  Reducers */
import { translate } from '../../redux/reducers/languageReducer';

/*  Actions */
import { getTransactions, renameTransaction, exists, setCurrentTransaction } from '../../redux/actions/transactionsActions';
import { general } from '../../general/global';
import { getUserListByWorkspaceId } from "../../redux/actions/workspaceActions";
import AssignTransactionModal from './AssignTransactionModal';
import DeleteTransactionModal from './DeleteTransactionModal';

const TransactionsPage = ({
    lang,
    dictionary,
    title,
    workspaceFocused,
    getTransactions,
    loading,
    secondaryLoading,
    data,
    totalPagination,
    renameTransaction,
    history,
    transactionExists,
    onGetUserListByWorkspaceId,
    onSetCurrentTransaction,
    accesstokenidp,
    spaceFocused,
}) => {
    const [search, setSearch] = useState('');
    const initialPagination = {
        startIndex: 0,
        endIndex: 10,
        page: 1,
        sizePerPage: 10,
    };
    const [paginationState, setPaginationState] = useState(initialPagination);
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [transaction, setTransaction] = useState({});
    const [error, setError] = useState('');
    const [userOptions, setUserOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [showAssignToModal, setShowAssignToModal] = useState(false);
    const [showDeleteTransactionModal, setShowDeleteTransactionModal] = useState(false);
    const [showQRModal, setShowQRModal] = useState(false);


    useEffect(() => {
        loadData();
    }, []);

    const getData = async (page, sizePerPage, name) => {
        await getTransactions({
            workspaceId: workspaceFocused._id,
            userId: workspaceFocused.userId,
            name: name,
            pageNo: page,
            limit: sizePerPage
        });
    }
    const loadData = async (page = paginationState.page) => {
        const sizePerPage = paginationState.sizePerPage;

        await getData(page, sizePerPage, '');
        setPaginationState({
            page,
            sizePerPage,
            startIndex: ((page - 1) * sizePerPage),
            endIndex: page * sizePerPage
        });
        await fetchSpaceUsers();
        setSearch('');
    };

    const getOptions = (transaction) => {
        switch (workspaceFocused.roleId) {
            case roles.ADMIN:
                return [
                    {
                        name: "RenameTransaction",
                        onDropDownItemClick: () => {
                            setTransaction(transaction);
                            setShowRenameModal(true);
                        }
                    },
                    {
                        name: "EditTransactionForm",
                        onDropDownItemClick: () => {
                            onSetCurrentTransaction(transaction);
                            history.push('/editTransaction');
                        }
                    },
                    {
                        name: "AssignTransaction",
                        onDropDownItemClick: () => {
                            setTransaction(transaction);
                            setSelectedUser(selectUsersActiveTransaction(transaction.assignee))
                            setShowAssignToModal(true);
                        }
                    },
                    {
                        name: "TransactionQRCode",
                        onDropDownItemClick: () => {
                            setTransaction(transaction);
                            setShowQRModal(true);
                        }
                    },
                    {
                        name: "DeleteTransaction",
                        onDropDownItemClick: () => {
                            setTransaction(transaction);
                            setShowDeleteTransactionModal(true)
                        }
                    }
                ];
            case roles.MONITOR:
                return [
                    {
                        name: "AssignTransaction",
                        onDropDownItemClick: () => {
                            setTransaction(transaction);
                            setSelectedUser(selectUsersActiveTransaction(transaction.assignee))
                            setShowAssignToModal(true);
                        }
                    },
                    {
                        name: "TransactionQRCode",
                        onDropDownItemClick: () => {
                            setTransaction(transaction);
                            setShowQRModal(true);
                        }
                    }
                ];
            default:
                return []
        }
    }
    const setOptions = (optionItems) => {
        let options = [];
        if (optionItems) {
            options = optionItems.map((option) => {

                if (option.roleId === roles.ADMIN) {
                    return { label: option.name + ' ' + option.lastName, value: option.id, disabled: true }
                } else {
                    return { label: option.name + ' ' + option.lastName, value: option.id }
                }

            })
        }

        return options;
    }

    const fetchSpaceUsers = async () => {
        const response = await onGetUserListByWorkspaceId(accesstokenidp, spaceFocused._id)
        setUserOptions(setOptions(response.data));
    }

    const selectUsersActiveTransaction = (assignedUsers = []) => {
        let seletedUsers = [];
        assignedUsers.map((userId) => {
            const user = userOptions.find(({ value }) => value === userId);
            if (user) {
                seletedUsers.push({ label: user.label, value: user.value });
            }
        });
        return seletedUsers;
    }

    const buildColumns = () => {
        const newColumns = [
            { dataField: "_id", text: "transactionId", hidden: true },
            { dataField: "transactionName", text: dictionary.s("transactionName").toUpperCase() },
            {
                dataField: "lastExecution",
                text: dictionary.s("lastExecution").toUpperCase(),
                searchable: false,
            },
            {
                dataField: "actions",
                text: dictionary.s("actions").toUpperCase(),
                headerAlign: (column, colIndex) => "right",
                searchable: false,
            },
        ];
        return newColumns;
    };

    const buildRows = (data) => {
        const rows = [];
        if (data === null) return rows;
        data.map((transaction) => {
            const attributes = {};

            rows.push({
                ...attributes,
                _id: transaction._id,
                transactionName: (
                    <div className="transaction-item">
                        <div className="transaction-viewer"></div>
                        <div className="transaction-details">
                            <Label className="transaction-name" >{transaction.transactionName}</Label>
                            <Label fontSize="10px">
                                {`${dictionary.s('createBy')} 
                                ${transaction.createdByFullName}, 
                                ${moment(transaction.createdAt).format(`${lang === 'en' ? 'MMM DD YYYY' : 'DD MMM YYYY'}`)}`}
                            </Label>
                        </div>
                    </div>
                ),
                lastExecution: (
                    <div>
                        <Label fontSize="14px">
                            {moment(transaction.lastExecution).isValid() &&
                                moment(transaction.lastExecution).format(`${lang === 'en' ? 'MMM DD YYYY' : 'DD MMM YYYY'} , hh:mm:ss a`)
                            }
                        </Label>
                    </div>
                ),
                actions: (
                    <div className="transaction-actions">
                        <PlayBlue
                            onClick={async () => {
                                await onSetCurrentTransaction(transaction);
                                history.push('/executeTransaction');
                            }}
                            width={28}
                            className="cursor-pointer"
                        />
                        {
                            (workspaceFocused.roleId === roles.ADMIN || workspaceFocused.roleId === roles.MONITOR) &&
                            <ToggleMenu
                                dropDownList={getOptions(transaction)}
                                onDropDownClick={() => { }}
                            />
                        }
                    </div>
                ),
            });
        });
        return rows;
    };

    const onTableChange = async (type, newState) => {
        const { page, sizePerPage } = newState;
        setPaginationState({
            page,
            sizePerPage,
            startIndex: ((page - 1) * sizePerPage),
            endIndex: page * sizePerPage
        });
        await getData(page, sizePerPage, search);
    }

    const rowClasses = (row, rowIndex) => {
        if (row.disabledAllSpaces) {
            return 'userDisabledRow';
        }
    }

    const handleSearch = async (value) => {
        setSearch(value);
        const page = 1;
        const sizePerPage = value === '' ? initialPagination.sizePerPage : paginationState.sizePerPage;
        setPaginationState({
            page,
            sizePerPage,
            startIndex: ((page - 1) * sizePerPage),
            endIndex: page * sizePerPage
        });
        await getData(page, sizePerPage, value);
    }

    const renderSearch = () => {
        return (
            <Row className="transactions-options">
                <Col xs={12} sm={6}>
                    <InputSearch
                        placeholder={`${dictionary.s("search")} ${dictionary.s("transactionsTitle")}`}
                        searchValue={search}
                        onChangeText={setSearch}
                        handleSearch={handleSearch}
                        className='input-search-container'
                    />
                </Col>
                {workspaceFocused.roleId === roles.ADMIN &&
                    <Col xs={12} sm={6} className="flex-end">
                        <CreateButton
                            dictionary={dictionary}
                            onClick={() => history.push("/uploadTransaction")}
                            text='createTransaction'
                            style={{}}
                        />
                    </Col>
                }
            </Row>
        );
    };

    const onChangeTransactionName = (value) => {
        setTransaction({
            ...transaction,
            transactionName: value
        });
        setError('');
    }

    const closeRenameTransaction = () => {
        setShowRenameModal(false);
        setError('');
    }

    const RenameTransaction = () => (
        <Modal
            title={dictionary.s('RenameTransaction')}
            dialogClassName="safely-modal"
            showModal={showRenameModal}
            onHide={closeRenameTransaction}
            onClose={closeRenameTransaction}
            onSave={async () => {
                if (transaction.transactionName.length) {
                    let _transactions = await transactionExists(workspaceFocused._id, transaction.transactionName);
                    if (!_transactions.data.length) {
                        await renameTransaction({
                            id: transaction._id,
                            workspaceId: workspaceFocused._id,
                            name: transaction.transactionName
                        });
                        loadData();
                        showToast(toastType.SUCCESS, dictionary.s('changeshasbeenupdatedsuccessfully'));
                        setShowRenameModal(false);
                    }
                    else {
                        setError(dictionary.s('renameTransactionError'));
                    }
                }
            }}
            textButtonSave={secondaryLoading ? '...' : dictionary.s("rename")}
            buttonCloseProperties={{
                style: {
                    height: '40px'
                }
            }}
            propertyButtonSave={{
                style: {
                    backgroundColor: general.theme.primaryColor,
                    color: general.theme.whiteFontColor,
                    border: `1px solid ${general.theme.primaryColor}`,
                    // width: '100px',
                    height: '40px',
                    fontWeight: '500',
                    padding: '0px',
                    lineHeight: '1.2rem'
                }
            }}
            body={
                <div>
                    <InputText
                        maxLength="50"
                        className="form-control"
                        value={transaction.transactionName}
                        onChangeText={onChangeTransactionName}
                    />
                    {error && <Label className="required">{error}</Label>}
                </div>
            }
            properties={{ centered: true, size: "md" }}
        />
    );

    return (
        <MainNavigation title={title}>
            <Container fluid className="safely-views">
                <DownloadQRCode
                    showQRModal={showQRModal}
                    closeModalQR={() => setShowQRModal(false)}
                    pdfTitle={transaction.transactionName}
                    fileName={transaction.transactionName}
                    value={`transactionform||${transaction._id}`}
                    containerStyle={{ border: "1px solid #BBB8B8", borderRadius: "16px" }}
                />
                <AssignTransactionModal
                    userOptions={userOptions}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    showModal={showAssignToModal}
                    closeModal={() => { setShowAssignToModal(false) }}
                    activeTransaction={transaction}
                    setShowAssignToModal={setShowAssignToModal}
                    loadData={loadData}
                />
                <DeleteTransactionModal
                    showDeleteTransactionModal={showDeleteTransactionModal}
                    setShowDeleteTransactionModal={setShowDeleteTransactionModal}
                    activeTransaction={transaction}
                    setShowDeleteTransactionModal={setShowDeleteTransactionModal}
                    loadData={loadData}
                />
                <Row>
                    <Col><Space data={workspaceFocused} /></Col>
                </Row>
                {renderSearch()}
                <Table
                    columns={buildColumns()}
                    data={buildRows(data)}
                    loading={loading}
                    noDataIndication={dictionary.s(loading ? "loading" : "noResultFound")}
                    striped={false}
                    hover={false}
                    condesed={true}
                    customPagination={true}
                    dictionary={dictionary}
                    sizePerPageList={[
                        { id: '10', text: '10', value: 10 },
                        { id: '20', text: '20', value: 20 },
                        { id: 'all', text: dictionary.s('All'), value: totalPagination }
                    ]}
                    paginationOptions={{
                        totalSize: totalPagination,
                        ...paginationState
                    }}
                    remote={{
                        filter: false,
                        pagination: true,
                        sort: false,
                        cellEdit: false
                    }}
                    onTableChange={onTableChange}
                    rowClasses={rowClasses}
                    contentStyle={{ margin: '0' }}
                />
                {RenameTransaction()}
            </Container>
        </MainNavigation>
    );
};

const mapStateToProps = state => ({
    lang: state.language.lang,
    dictionary: {
        s: key => translate(state.language.dictionary, key)
    },
    workspaceFocused: state.workspace.spaceFocused,
    loading: state.transactions.loading,
    secondaryLoading: state.transactions.secondaryLoading,
    totalPagination: state.transactions.transactionPaging.totalCount,
    data: state.transactions.transactionPaging.transactions,
    spaceFocused: state.workspace.spaceFocused,
    accesstokenidp: state.user.accesstokenidp,
});

const mapDispatchToProps = dispatch => ({
    getTransactions: (body, save) => dispatch(getTransactions(body, save)),
    renameTransaction: (body) => dispatch(renameTransaction(body)),
    transactionExists: (workspaceId, transactionName) => dispatch(exists(workspaceId, transactionName)),
    onSetCurrentTransaction: (transaction) => dispatch(setCurrentTransaction(transaction)),
    onGetUserListByWorkspaceId: (accesstokenidp, workSpaceId) =>
        dispatch(getUserListByWorkspaceId(accesstokenidp, workSpaceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPage);