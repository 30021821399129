import React from "react";
import { connect } from "react-redux";
import { Col } from "react-bootstrap";
import Modal from "../commons/wrapped-components/Modal";
import { CircularProgressbar , buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { translate } from "../../redux/reducers/languageReducer";

const Addpost = (props) => {
  const renderModalSpinner = () => (
    <Modal
      dialogClassName="addPopup spinnerpopup"
      showModal={props.postSpinner}
      properties={{
        centered: true,
        size: "sm",
      }}
      body={
        <div className="loader-container-spinner">
      {props.progressSpinner !== 0 ?
      <>
      <CircularProgressbar value={props.progressSpinner} text={`${props.progressSpinner}%`} 
      styles={buildStyles({
        pathColor: '#665EFF',
        textColor: '#6E6E7C'
      })}
      />
      <div className="loading-cal-spinner">
      {props.dictionary.s("loading")}
      </div>
      </>
      :<Col className="spinner-loader">
        <div className="spinner-border" role="status"></div>
      </Col>}
    </div>
      }
    />
  );

  return renderModalSpinner();
};

const mapStateToProps = (state) => {
  return {
    postSpinner: state.posts.postSpinner,
    progressSpinner: state.posts.progressSpinner,
    dictionary: {
      s: (key) => translate(state.language.dictionary, key),
    }
  };
};


export default connect(mapStateToProps, null)(Addpost);
