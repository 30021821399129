export const POST_LOGIN = "POST_LOGIN";
// export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_ERROR = "LOAD_USER_ERROR";

export const SAVE_USER_WORKSPACE = "SAVE_USER_WORKSPACE";
export const SAVE_WORKSPACE_ERROR = "SAVE_WORKSPACE_ERROR";

export const USER_POST = "USER_POST";
export const USER_POST_SUCCESS = "USER_POST_SUCCESS";
export const USER_POST_ERROR = "USER_POST_ERROR";

export const USER_SOCIAL_POST = "USER_SOCIAL_POST";
export const USER_SOCIAL_POST_SUCCESS = "USER_SOCIAL_POST_SUCCESS";
export const USER_SOCIAL_POST_ERROR = "USER_SOCIAL_POST_ERROR";

export const EDIT_USER = "EDIT_USER";
export const CLICKED_USER = "CLICKED_USER";
export const SET_USER = "SET_USER";
export const SET_MESSAGE = "SET_MESSAGE";

export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";

export const USER_CONTACT_UPDATE = "USER_CONTACT_UPDATE";
export const USER_CONTACT_UPDATE_SUCCESS = "USER_CONTACT_UPDATE_SUCCESS";
export const USER_CONTACT_UPDATE_FAIL = "USER_CONTACT_UPDATE_FAIL";
export const USER_RESET = "USER_RESET";
export const USER_UPDATE_ROLE = "USER_UPDATE_ROLE";
export const USER_UPDATE_ROLE_SUCESS = "USER_UPDATE_ROLE_SUCESS";
export const USER_UPDATE_ROLE_FAIL = "USER_UPDATE_ROLE_FAIL";
export const USER_UPDATE_PROFILE_PHOTO = 'USER_UPDATE_PROFILE_PHOTO';
export const USER_UPDATE_PROFILE_PHOTO_SUCCESS = 'USER_UPDATE_PROFILE_PHOTO_SUCCESS';
export const USER_UPDATE_PROFILE_PHOTO_FAIL = 'USER_UPDATE_PROFILE_PHOTO_FAIL';
export const USER_UPDATE_BACKGROUND_PROFILE_PHOTO = 'USER_UPDATE_BACKGROUND_PROFILE_PHOTO';
export const USER_UPDATE_BACKGROUND_PROFILE_PHOTO_SUCCESS = 'USER_UPDATE_BACKGROUND_PROFILE_PHOTO_SUCCESS';
export const USER_UPDATE_BACKGROUND_PROFILE_PHOTO_FAIL = 'USER_UPDATE_BACKGROUND_PROFILE_PHOTO_FAIL';

export const LOG_OUT = "LOG_OUT";
export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS";
export const LOG_OUT_ERROR = "LOG_OUT_ERROR";

export const CHANGE_EMAIL_NUMBER = "CHANGE_EMAIL_NUMBER";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
//New
export const LOGIN_USER_SOCIAL_MEDIA = "LOGIN_USER_SOCIAL_MEDIA";
export const LOGIN_USER_SOCIAL_MEDIA_SUCCESS =
  "LOGIN_USER_SOCIAL_MEDIA_SUCCESS";
export const LOGIN_USER_SOCIAL_MEDIA_FAIL = "LOGIN_USER_SOCIAL_MEDIA_FAIL";
export const LOGIN_USER_EXISTS = "LOGIN_USER_EXISTS";
export const LOGIN_USER_EXISTS_SUCCESS = "LOGIN_USER_EXISTS_SUCCESS";
export const LOGIN_USER_EXISTS_FAIL = "LOGIN_USER_EXISTS_FAIL";

export const SET_LOADING = "SET_LOADING";

//export const WORKSPACE_CREATE = "WORKSPACE_CREATE";
export const WORKSPACE_GET_LIST = "WORKSPACE_GET_LIST";
export const WORKSPACE_GET_LIST_SUCCESS = "WORKSPACE_GET_LIST_SUCCESS";
export const WORKSPACE_ADD_USER = "WORKSPACE_ADD_USER";
export const WORKSPACE_CREATE_SUCCESS = "WORKSPACE_CREATE_SUCCESS";
export const WORKSPACE_CREATE_FAIL = "WORKSPACE_CREATE_FAIL";
export const WORKSPACE_GET_LIST_FAIL = "WORKSPACE_GET_LIST_FAIL";
export const WORKSPACE_SET_FOCUSED = "WORKSPACE_SET_FOCUSED";
export const CREATING_NEW_WORKPACE = "CREATING_NEW_WORKPACE";
export const WORKSPACE_RESET = "WORKSPACE_RESET";
export const WORKSPACE_ADD_USER_SUCCESS = "WORKSPACE_ADD_USER_SUCCESS";
export const WORKSPACE_ADD_USER_FAIL = "WORKSPACE_ADD_USER_FAIL";
export const RESET_WORKSPACE_CREATED = "RESET_WORKSPACE_CREATED";
export const WORKSPACE_ADD_ITEM_TO_LIST = "WORKSPACE_ADD_ITEM_TO_LIST";
export const WORKSPACE_ADD_SETTINGS = "WORKSPACE_ADD_SETTINGS";
export const WORKSPACE_ADD_SETTINGS_SUCCESS = "WORKSPACE_ADD_SETTINGS_SUCCESS";
export const WORKSPACE_ADD_SETTINGS_FAIL = "WORKSPACE_ADD_SETTINGS_FAIL";
export const WORKSPACE_RENAME = "WORKSPACE_ADD_SETTINGS_FAIL";
export const WORKSPACE_RENAME_SUCCESS = "WORKSPACE_RENAME_SUCCESS";
export const WORKSPACE_RENAME_FAIL = "WORKSPACE_RENAME_FAIL";
export const WORKSPACE_IMPORT_CONTACTS = "WORKSPACE_IMPORT_CONTACTS";
export const WORKSPACE_IMPORT_CONTACTS_SUCCESS = "WORKSPACE_IMPORT_CONTACTS_SUCCESS";
export const WORKSPACE_IMPORT_CONTACTS_FAIL = "WORKSPACE_IMPORT_CONTACTS_FAIL";
export const WORKSPACE_UPDATE_SETTINGS = "WORKSPACE_UPDATE_SETTINGS";
export const SET_WORKSPACE_AT_LOGIN = "SET_WORKSPACE_AT_LOGIN";
export const RESET_WORKSPACE_FOCUSED = "RESET_WORKSPACE_FOCUSED";
export const WORKSPACE_SAFELY_PASS_SET_FOCUSED = "WORKSPACE_SAFELY_PASS_SET_FOCUSED";

export const CHANGED_NAME = "CHANGED_NAME";
export const CHANGED_LAST_NAME = "CHANGED_LAST_NAME";
export const CHANGED_WORKSPACE = "CHANGED_WORKSPACE";
export const CHANGED_MEMBER = "CHANGED_MEMBER";
export const ADD_MEMBER_TO_WORKSPACE = "ADD_MEMBER_TO_WORKSPACE";
export const CHANGED_LOCATION_SERVICE_WHILE_USING =
  "CHANGED_LOCATION_SERVICE_WHILE_USING";
export const CHANGED_LOCATION_SERVICE_REAL_TIME =
  "CHANGED_LOCATION_SERVICE_REAL_TIME";
export const CHANGED_EMAIL_NUMBER_ONBOARDING =
  "CHANGED_EMAIL_NUMBER_ONBOARDING";
export const CHANGED_PASSWORD_ONBOARDING = "CHANGED_PASSWORD_ONBOARDING";
export const ONBOARDING_RESET = "ONBOARDING_RESET";
export const CHANGED_MAGIC_LINK_CODE_ONBOARDING =
  "CHANGED_MAGIC_LINK_CODE_ONBOARDING";

export const MAGIC_LINK_CREATE = "MAGIC_LINK_CREATE";
export const MAGIC_LINK_CREATE_SUCCESS = "MAGIC_LINK_CREATE_SUCCESS";
export const MAGIC_LINK_CREATE_FAIL = "MAGIC_LINK_CREATE_FAIL";

export const MAGIC_LINK_SET_MESSAGE = "MAGIC_LINK_SET_MESSAGE";
export const MAGIC_LINK_SET_CODE = "MAGIC_LINK_SET_CODE";
export const MAGIC_LINK_GET_INFO_BY_CODE = "MAGIC_LINK_GET_INFO_BY_CODE";
export const MAGIC_LINK_GET_INFO_BY_CODE_SUCCESS =
  "MAGIC_LINK_GET_INFO_BY_CODE_SUCCESS";
export const MAGIC_LINK_GET_INFO_BY_CODE_FAIL =
  "MAGIC_LINK_GET_INFO_BY_CODE_FAIL";

export const ROLE_GET_LIST = "ROLE_GET_LIST";
export const ROLE_GET_LIST_SUCESS = "ROLE_GET_LIST_SUCESS";
export const ROLE_GET_LIST_FAIL = "ROLE_GET_LIST_FAIL";

export const OPERATION_CHECK_POINT_POST = "OPERATION_CHECK_POINT_POST";
export const OPERATION_CHECK_POINT_POST_SUCCESS =
  "OPERATION_CHECK_POINT_POST_SUCCESS";
export const OPERATION_CHECK_POINT_POST_FAIL =
  "OPERATION_CHECK_POINT_POST_FAIL";
export const OPERATION_USER_VERIFICATION_POST =
  "OPERATION_USER_VERIFICATION_POST";
export const OPERATION_USER_VERIFICATION_POST_SUCCESS =
  "OPERATION_USER_VERIFICATION_POST_SUCCESS";
export const OPERATION_USER_VERIFICATION_POST_FAIL =
  "OPERATION_USER_VERIFICATION_POST_FAIL";
export const OPERATION_LAST_VERIFIED_GET = "OPERATION_LAST_VERIFIED_GET";
export const OPERATION_LAST_VERIFIED_GET_SUCCESS =
  "OPERATION_LAST_VERIFIED_GET_SUCCESS";
export const OPERATION_LAST_VERIFIED_GET_FAIL =
  "OPERATION_LAST_VERIFIED_GET_FAIL";
export const OPERATION_CHECK_POINT_CHANGE_INPUT =
  "OPERATION_CHECK_POINT_CHANGE_INPUT";
export const OPERATION_CHECK_POINT_CHANGE_COMMENTS =
  "OPERATION_CHECK_POINT_CHANGE_COMMENTS";
export const OPERATION_USER_VERIFICATION_CHANGE_INPUT =
  "OPERATION_USER_VERIFICATION_CHANGE_INPUT";
export const OPERATION_USER_VERIFICATION_CHANGE_COMMENTS =
  "OPERATION_USER_VERIFICATION_CHANGE_COMMENTS";
export const OPERATION_SET_USER_VERIFICATION =
  "OPERATION_SET_USER_VERIFICATION";
export const OPERATION_SET_CHECK_POINT = "OPERATION_SET_CHECK_POINT";
export const OPERATION_SET_MESSAGE = "OPERATION_SET_MESSAGE";
export const OPERATION_RESET = "OPERATION_RESET";
export const OPERATION_CHANGE_SAFELY_PASS_STATUS =
  "OPERATION_CHANGE_SAFELY_PASS_STATUS";
export const OPERATION_ACCESS_AUTHORIZATION = "OPERATION_ACCESS_AUTHORIZATION";
export const OPERATION_ACCESS_AUTHORIZATION_SUCCESS =
  "OPERATION_ACCESS_AUTHORIZATION_SUCCESS";
export const OPERATION_ACCESS_AUTHORIZATION_FAIL =
  "OPERATION_ACCESS_AUTHORIZATION_FAIL";
export const OPERATION_CHECK_OUT = "OPERATION_CHECK_OUT";

export const USER_GET_PROFILE_BY_ID = "USER_GET_PROFILE_BY_ID";
export const USER_GET_PROFILE_BY_ID_SUCCESS = "USER_GET_PROFILE_BY_ID_SUCCESS";
export const USER_GET_PROFILE_BY_ID_FAIL = "USER_GET_PROFILE_BY_ID_FAIL";

export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE';
export const LANGUAGE_RESET = "LANGUAGE_RESET";

export const AUTHORIZATION_SET_USER_DATA = 'AUTHORIZATION_SET_USER_DATA';

export const OPERATION_ACCESS_AUTHORIZATION_ACTIVES_LIST_REQUEST = "OPERATION_ACCESS_AUTHORIZATION_ACTIVES_LIST_REQUEST";
export const OPERATION_ACCESS_AUTHORIZATION_ACTIVES_LIST_SUCCESS = 'OPERATION_ACCESS_AUTHORIZATION_ACTIVES_LIST_SUCCESS';
export const OPERATION_ACCESS_AUTHORIZATION_ACTIVES_LIST_FAIL = 'OPERATION_ACCESS_AUTHORIZATION_ACTIVES_LIST_FAIL';
export const OPERATION_ACCESS_AUTHORIZATION_ACTIVE_LIST_REQUEST = 'OPERATION_ACCESS_AUTHORIZATION_ACTIVE_LIST_REQUEST';

export const OPERATION_ACCESS_AUTHORIZATION_DENIED_REQUEST = "OPERATION_ACCESS_AUTHORIZATION_DENIED_REQUEST";
export const OPERATION_ACCESS_AUTHORIZATION_DENIED_SUCCESS = "OPERATION_ACCESS_AUTHORIZATION_DENIED_SUCCESS"
export const OPERATION_ACCESS_AUTHORIZATION_DENIED_FAIL = "OPERATION_ACCESS_AUTHORIZATION_DENIED_FAIL";
export const OPERATION_ACCESS_AUTHORIZATION_REQUEST = "OPERATION_ACCESS_AUTHORIZATION_REQUEST";
export const OPERATION_ACCESS_AUTHORIZATION_GRANT_REQUEST = "OPERATION_ACCESS_AUTHORIZATION_GRANT_REQUEST";
export const OPERATION_ACCESS_AUTHORIZATION_GRANT_FAIL = "OPERATION_ACCESS_AUTHORIZATION_GRANT_FAIL";
export const OPERATION_ACCESS_AUTHORIZATION_GRANT_SUCCESS = "OPERATION_ACCESS_AUTHORIZATION_GRANT_SUCCESS";

export const OPERATION_ACCESS_AUTHORIZATION_REQUEST_SUCCESS = "OPERATION_ACCESS_AUTHORIZATION_REQUEST_SUCCESS";
export const OPERATION_ACCESS_AUTHORIZATION_REQUEST_FAIL = "OPERATION_ACCESS_AUTHORIZATION_REQUEST_FAIL";
export const OPERATION_CHECK_OUT_FAIL = "OPERATION_CHECK_OUT_FAIL";

export const OPERATION_GET_CHECKOUT_TIME_REQUEST = "OPERATION_GET_CHECKOUT_TIME";
export const OPERATION_GET_CHECKOUT_TIME_SUCCESS = "OPERATION_GET_CHECKOUT_TIME_SUCCESS";
export const OPERATION_GET_CHECKOUT_TIME_FAIL = "OPERATION_GET_CHECKOUT_TIME_FAIL";

export const OPERATION_ACCESS_AUTHORIZATION_UNREGISTERED_REQUEST = 'OPERATION_ACCESS_AUTHORIZATION_UNREGISTERED_REQUEST';
export const OPERATION_ACCESS_AUTHORIZATION_UNREGISTERED_SUCCESS = 'OPERATION_ACCESS_AUTHORIZATION_UNREGISTERED_SUCCESS';
export const OPERATION_ACCESS_AUTHORIZATION_UNREGISTERED_FAIL = 'OPERATION_ACCESS_AUTHORIZATION_UNREGISTERED_FAIL';
export const OPERATION_REFRESH_CHECK_IN_REGISTER_SCREEN = 'OPERATION_REFRESH_CHECK_IN_REGISTER_SCREEN';

export const WORKSPACE_REFRESH_USER_MANAGEMENT_SCREEN = 'WORKSPACE_REFRESH_USER_MANAGEMENT_SCREEN';

export const GET_REPORT_DATA = "GET_REPORT_DATA";
export const GET_REPORT_DATA_SUCCESS = "GET_REPORT_DATA_SUCCESS";
export const GET_REPORT_DATA_FAIL = "GET_REPORT_DATA_FAIL";

export const USER_UPDATE_USERNAME = "USER_UPDATE_USERNAME";
export const USER_UPDATE_USERNAME_SUCCESS = "USER_UPDATE_USERNAME_SUCCESS";
export const USER_UPDATE_USERNAME_FAILURE = "USER_UPDATE_USERNAME_FAILURE";
export const CHECK_IN_NEW_USER = 'CHECK_IN_NEW_USER';
export const CHECK_IN_NEW_USER_SUCCESS = 'CHECK_IN_NEW_USER_SUCCESS';
export const CHECK_IN_NEW_USER_FAILURE = 'CHECK_IN_NEW_USER_FAILURE';
// export const OPERATION_ACCESS_AUTHORIZATION_REQUEST_SUCCESS = "OPERATION_ACCESS_AUTHORIZATION_REQUEST_SUCCESS";
// export const OPERATION_ACCESS_AUTHORIZATION_REQUEST_FAIL = "OPERATION_ACCESS_AUTHORIZATION_REQUEST_FAIL";
// export const OPERATION_CHECK_OUT_FAIL = "OPERATION_CHECK_OUT_FAIL";

export const WORKSPACE_SUB_SET_FOCUSED = "WORKSPACE_SUB_SET_FOCUSED";
export const SEND_VERIFY_PIN_SUCCESS = "SEND_VERIFY_PIN_SUCCESS";
export const SEND_VERIFY_PIN_ERROR = "SEND_VERIFY_PIN_ERROR";
export const VERIFY_PIN_SUCCESS = "VERIFY_PIN_SUCCESS";
export const VERIFY_PIN_ERROR = "VERIFY_PIN_ERROR";


export const SHOW_TOAST_SUCCESS_MESSAGE = "SHOW_TOAST_SUCCESS_MESSAGE";
export const SHOW_TOAST_ERROR_MESSAGE = "SHOW_TOAST_ERROR_MESSAGE";
export const DESACTIVE_TOAST = "DESACTIVE_TOAST";

// export const OPERATION_CHECK_OUT_REQUEST = 'OPERATION_CHECK_OUT_REQUEST';
// export const OPERATION_CHECK_OUT_SUCCESS = 'OPERATION_CHECK_OUT_SUCCESS';


export const USER_UPDATE_SUBSPACES = "USER_UPDATE_SUBSPACES";
export const USER_UPDATE_SUBSPACES_SUCCESS = "USER_UPDATE_SUBSPACES_SUCCESS";
export const USER_UPDATE_SUBSPACES_FAILURE = "USER_UPDATE_SUBSPACES_FAILURE";

export const CHANGED_COUNTRYCODE = "CHANGED_COUNTRYCODE";
export const GET_COUNTRY_CODE_LIST_REQUEST = "GET_COUNTRY_CODE_LIST_REQUEST";
export const GET_COUNTRY_CODE_LIST_REQUEST_SUCCESS = "GET_COUNTRY_CODE_LIST_REQUEST_SUCCESS";
export const GET_COUNTRY_CODE_LIST_REQUEST_FAIL = "GET_COUNTRY_CODE_LIST_REQUEST_FAIL";

export const EDIT_FORM_GET_REQUEST = "EDIT_FORM_GET_REQUEST";
export const EDIT_FORM_GET_SUCCESS = "EDIT_FORM_GET_SUCCESS";
export const EDIT_FORM_GET_FAIL = "EDIT_FORM_GET_FAIL";
export const UPDATE_INPUT = "UPDATE_INPUT";
export const UPDATE_INPUT_FAIL = "UPDATE_INPUT_FAIL";
export const UPDATE_CONTENT = "UPDATE_CONTENT";
export const UPDATE_CONTENT_FAIL = "UPDATE_CONTENT_FAIL";
export const UPDATE_INPUT_SUCCESS = "UPDATE_INPUT_SUCCESS";
export const UPDATE_CONTENT_SUCCESS = "UPDATE_CONTENT_SUCCESS";

export const EDIT_USER_VERIFICATION_FORM_CONTENT_UPDATE = 'EDIT_USER_VERIFICATION_FORM_CONTENT_UPDATE';

export const CHANGE_FORGOT_EMAIL_NUMBER = "CHANGE_FORGOT_EMAIL_NUMBER";
export const RESET_FORGOT_EMAIL_NUMBER = "RESET_FORGOT_EMAIL_NUMBER";

export const GET_VERIFICATION_FORM_INPUTS_REQUEST = "GET_VERIFICATION_FORM_INPUTS_REQUEST";
export const GET_VERIFICATION_FORM_INPUTS_SUCCESS = "GET_VERIFICATION_FORM_INPUTS_SUCCESS";
export const GET_VERIFICATION_FORM_INPUTS_FAIL = "GET_VERIFICATION_FORM_INPUTS_FAIL";

export const GET_DECLARATION_FORM_INPUTS_REQUEST = "GET_DECLARATION_FORM_INPUTS_REQUEST";
export const GET_DECLARATION_FORM_INPUTS_SUCCESS = "GET_DECLARATION_FORM_INPUTS_SUCCESS";
export const GET_DECLARATION_FORM_INPUTS_FAIL = "GET_DECLARATION_FORM_INPUTS_FAIL";

export const RESET_LOGIN = "RESET_LOGIN";

export const SPINNER_POST = "SPINNER_POST";
export const GET_POST_VIEW_DATA_SUCCESS = "GET_POST_VIEW_DATA_SUCCESS";
export const GET_POST_VIEW_DATA_FAIL = "GET_POST_VIEW_DATA_FAIL";
export const GET_POST_VIEW_DATA = "GET_POST_VIEW_DATA";
export const SPINNER_PROGRESS = "SPINNER_PROGRESS";

export const GET_MARKETPLACE_PRODUCT_DATA = "GET_MARKETPLACE_PRODUCT_DATA";
export const GET_MARKETPLACE_PRODUCT_DATA_SUCCESS = "GET_MARKETPLACE_PRODUCT_DATA_SUCCESS";
export const GET_MARKETPLACE_PRODUCT_DATA_FAIL = "GET_MARKETPLACE_PRODUCT_DATA_FAIL";

export const SUBSPACE_UPDATE_MAX_OCCUPANCY = 'SUBSPACE_UPDATE_MAX_OCCUPANCY';
export const SUBSPACE_UPDATE_MAX_OCCUPANCY_SUCCESS = 'SUBSPACE_UPDATE_MAX_OCCUPANCY_SUCCESS';
export const SUBSPACE_UPDATE_MAX_OCCUPANCY_FAIL = 'SUBSPACE_UPDATE_MAX_OCCUPANCY_FAIL';

export const SUBSPACE_GET_CHECK_INTO_SUBSPACE_LIST = 'GET_CHECK_INTO_SUBSPACE_LIST';
export const SUBSPACE_GET_CHECK_INTO_SUBSPACE_LIST_SUCCESS = 'GET_CHECK_INTO_SUBSPACE_LIST_SUCCESS';
export const SUBSPACE_GET_CHECK_INTO_SUBSPACE_LIST_FAIL = 'GET_CHECK_INTO_SUBSPACE_LIST_FAIL';
export const SUBSPACE_CHECK_INTO_SUBSPACE = 'CHECK_IN_SUBSPACE';
export const SUBSPACE_CHECK_INTO_SUBSPACE_SUCCESS = 'CHECK_IN_SUBSPACE_SUCCESS';
export const SUBSPACE_CHECK_INTO_SUBSPACE_FAIL = 'CHECK_IN_SUBSPACE_FAIL';
export const SUBSPACE_CHECK_OUT_INTO_SUBSPACE = 'CHECK_OUT_SUBSPACE';
export const SUBSPACE_CHECK_OUT_INTO_SUBSPACE_SUCCESS = 'CHECK_OUT_SUBSPACE_SUCCESS';
export const SUBSPACE_CHECK_OUT_INTO_SUBSPACE_FAIL = 'CHECK_OUT_SUBSPACE_FAIL';
export const SUBSPACE_GET_ACTIVES_SUBSPACE_CHECK_IN = 'GET_ACTIVES_SUBSPACE_CHECK_IN';
export const SUBSPACE_GET_ACTIVES_SUBSPACE_CHECK_IN_SUCCESS = 'GET_ACTIVES_SUBSPACE_CHECK_IN_SUCCESS';
export const SUBSPACE_GET_ACTIVES_SUBSPACE_CHECK_IN_FAIL = 'GET_ACTIVES_SUBSPACE_CHECK_IN_FAIL';
export const SUBSPACE_REST_CHECK_INTO_SUBSPACE_LIST = 'SUBSPACE_REST_CHECK_INTO_SUBSPACE_LIST';
export const SUBSPACE_CHECK_INTO_SUBSPACE_PUSH_NOTIFICATION = 'SUBSPACE_CHECK_INTO_SUBSPACE_PUSH_NOTIFICATION';
export const SUBSPACE_CHECK_OUT_PUSH_NOTIFICATION = 'SUBSPACE_CHECK_OUT_PUSH_NOTIFICATION';
export const REFRESH_CHECK_INTO_SUBSPACE_SCREEEN = 'REFRESH_CHECK_INTO_SUBSPACE_SCREEEN';
export const WORKSPACE_UPDATE_USER = 'WORKSPACE_UPDATE_USER';

export const GET_LIKE_DISLIKE_DATA = 'GET_LIKE_DISLIKE_DATA';
export const GET_POST_VIEW_LOAD_MORE = 'GET_POST_VIEW_LOAD_MORE';

export const GET_SPACES_AND_SUBSPACES_BY_USERID_AND_WORKSPACEID = 'GET_SPACES_AND_SUBSPACES_BY_USERID_AND_WORKSPACEID';
export const GET_SPACES_AND_SUBSPACES_BY_USERID_AND_WORKSPACEID_SUCCESS = 'GET_SPACES_AND_SUBSPACES_BY_USERID_AND_WORKSPACEID_SUCCESS';
export const GET_SPACES_AND_SUBSPACES_BY_USERID_AND_WORKSPACEID_FAIL = 'GET_SPACES_AND_SUBSPACES_BY_USERID_AND_WORKSPACEID_FAIL';

export const USER_DETAILS_POST = "USER_DETAILS_POST";
export const USER_DETAILS_POST_SUCCESS = "USER_DETAILS_POST_SUCCESS";
export const USER_DETAILS_POST_ERROR = "USER_DETAILS_POST_ERROR";

export const OPERATION_GET_PAGINATED_USERS_SUCCESS = 'OPERATION_GET_PAGINATED_USERS_SUCCESS';
export const OPERATION_GET_PAGINATED_USERS_FAILURE = 'OPERATION_GET_PAGINATED_USERS_FAILURE';
export const OPERATION_GET_PAGINATED_USERS_REQUEST = 'OPERATION_GET_PAGINATED_USERS_REQUEST';

export const SPACE_GET_PAGINATED_USERS_SUCCESS = 'SPACE_GET_PAGINATED_USERS_SUCCESS';
export const SPACE_GET_PAGINATED_USERS_FAILURE = 'SPACE_GET_PAGINATED_USERS_FAILURE';
export const SPACE_GET_PAGINATED_USERS_REQUEST = 'SPACE_GET_PAGINATED_USERS_REQUEST';

export const SPACE_GET_LIST_OF_SPACES_WITH_PAGINATION = 'SPACE_GET_SPACE_LIST_WITH_PAGINATION';
export const SPACE_GET_LIST_OF_SPACES_WITH_PAGINATION_SUCCESS = 'SPACE_GET_SPACE_LIST_WITH_PAGINATION_SUCCESS';
export const SPACE_GET_LIST_OF_SPACES_WITH_PAGINATION_FAIL = 'SPACE_GET_SPACE_LIST_WITH_PAGINATION_FAIL';
export const SPACE_GET_SUBSPACES_BY_USER_ID_WORKSPACE_ID = 'GET_SUBSPACES_BY_USER_ID_WORKSPACE_ID';
export const SPACE_GET_SUBSPACES_BY_USER_ID_WORKSPACE_ID_SUCCESS = 'GET_SUBSPACES_BY_USER_ID_WORKSPACE_ID_SUCCESS';
export const SPACE_GET_SUBSPACES_BY_USER_ID_WORKSPACE_ID_FAIL = 'GET_SUBSPACES_BY_USER_ID_WORKSPACE_ID_FAIL';
export const SPACE_GET_USER_LIST_BY_SUBSPACE_ID = 'GET_USERLIST_BY_SUBSPACE_ID';
export const SPACE_GET_USER_LIST_BY_SUBSPACE_ID_SUCCESS = 'GET_USERLIST_BY_SUBSPACE_ID_SUCCESS';
export const SPACE_GET_USER_LIST_BY_SUBSPACE_ID_FAIL = 'GET_USERLIST_BY_SUBSPACE_ID_FAIL';
export const SPACE_SHOW_MORE_SPACES = 'SPACE_SHOW_MORE_SPACES';
export const SPACE_SHOW_MORE_SPACES_SUCCESS = 'SPACE_SHOW_MORE_SPACES_SUCCESS';
export const SPACE_SHOW_MORE_SPACES_FAIL = 'SPACE_SHOW_MORE_SPACES_FAIL';
export const SPACE_SEARCH_SPACE = 'SPACE_SEARCH_SPACE';
export const SPACE_SEARCH_SPACE_SUCCESS = 'SPACE_SEARCH_SPACE_SUCCESS';
export const SPACE_SEARCH_SPACE_FAIL = 'SPACE_SEARCH_SPACE_FAIL';
export const SPACE_RESET_PAGE_NUMBER = 'SPACE_RESET_PAGE_NUMBER';
export const SPACE_INCREMENT_PAGE_NUMBER = 'SPACE_INCREMENT_PAGE_NUMBER';
export const SPACE_SET_SPACE_FOCUSED = 'SPACE_SET_SPACE_FOCUSED';
export const SPACE_SET_SUBSPACE_FOCUSED = 'SPACE_SET_SUBSPACE_FOCUSED';
export const SPACE_GET_USERS_BY_WORKSPACE_ID = 'SPACE_GET_USERS_BY_WORKSPACE_ID';
export const SPACE_GET_USERS_BY_WORKSPACE_ID_SUCCESS = 'SPACE_GET_USERS_BY_WORKSPACE_ID_SUCCESS';
export const SPACE_GET_USERS_BY_WORKSPACE_ID_FAIL = 'SPACE_GET_USERS_BY_WORKSPACE_ID_FAIL';
export const SPACE_DELETE_SPACE_IMAGE = 'SPACE_DELETE_SPACE_IMAGE';

export const SUBSPACE_REMOVE_MEMBER_SUBSPACE = 'SUBSPACE_REMOVE_MEMBER_SUBSPACE';
export const SUBSPACE_REMOVE_MEMBER_SUBSPACE_SUCCESS = 'SUBSPACE_REMOVE_MEMBER_SUBSPACE_SUCCESS';
export const SUBSPACE_REMOVE_MEMBER_SUBSPACE_FAIL = 'SUBSPACE_REMOVE_MEMBER_SUBSPACE_FAIL';
export const SUBSPACE_REFRESH_SCREEN_SUBWORKSPACE_LIST_ADD_MEMBER = 'SUBSPACE_REFRESH_SCREEN_SUBWORKSPACE_LIST_ADD_MEMBER';

export const SUBSPACE_ADD_SUBSPACE_TO_SUBSPACE_LIST = 'SUBSPACE_ADD_SUBSPACE_TO_SUBSPACE_LIST';

export const SUBSPACE_REMOVE_SUBSPACE_FROM_SUBSPACE_LIST = 'SUBSPACE_REMOVE_SUBSPACE_FROM_SUBSPACE_LIST';
export const SUBSPACE_RENAME_SUBSPACE = 'SUBSPACE_RENAME_SUBSPACE';

export const SUBSPACE_SHOW_MORE_SUBSPACES = 'SUBSPACE_SHOW_MORE_SUBSPACES';
export const SUBSPACE_SHOW_MORE_SUBSPACES_SUCCESS = 'SUBSPACE_SHOW_MORE_SUBSPACES_SUCCESS';
export const SUBSPACE_SHOW_MORE_SUBSPACES_FAIL = 'SUBSPACE_SHOW_MORE_SUBSPACES_FAIL';

export const SUBSPACE_SHOW_MORE_SUBSPACE_USERS = 'SUBSPACE_SHOW_MORE_SUBSPACE_USERS';
export const SUBSPACE_SHOW_MORE_SUBSPACE_USERS_SUCCESS = 'SUBSPACE_SHOW_MORE_SUBSPACE_USERS_SUCCESS';
export const SUBSPACE_SHOW_MORE_SUBSPACE_USERS_FAIL = 'SUBSPACE_SHOW_MORE_SUBSPACE_USERS_FAIL';
export const SUBSPACE_RESET_SUBSPACE_USER_LIST = 'SUBSPACE_RESET_SUBSPACE_USER_LIST';
export const SUBSPACE_RESET_SUBSPACE_LIST = 'SUBSPACE_RESET_SUBSPACE_LIST';

export const USER_JWT_TOKEN_UPADATE = 'USER_JWT_TOKEN_UPADATE';
export const WORKSPACE_UPDATE_USER_SETTINGS = "WORKSPACE_UPDATE_USER_SETTINGS";

//post
export const CHANGED_CAPTION = "CHANGED_CAPTION";
export const CHANGED_SUBSPACE = "CHANGED_SUBSPACE";

export const OPERATION_GET_USERS_FOR_CHECKPOINT = 'OPERATION_GET_USERS_FOR_CHECKPOINT';
export const OPERATION_GET_USERS_FOR_CHECKPOINT_SUCCESS = 'OPERATION_GET_USERS_FOR_CHECKPOINT_SUCCESS';
export const OPERATION_GET_USERS_FOR_CHECKPOINT_FAIL = 'OPERATION_GET_USERS_FOR_CHECKPOINT_FAIL';
export const OPERATION_SET_ACTIVE_KEY = 'OPERATION_SET_ACTIVE_KEY';
export const OPERATION_SET_CHECK_IN_BY_CHECKPOINT = 'OPERATION_SET_CHECK_IN_BY_CHECKPOINT';

//disable & enable user safely pass in usermanagement and checkpoint
export const DISABLE_USER_SAFELY_PASS = 'DISABLE_USER_SAFELY_PASS';
export const DISABLE_USER_SAFELY_PASS_SUCCESS = 'DISABLE_USER_SAFELY_PASS_SUCCESS';
export const DISABLE_USER_SAFELY_PASS_FAIL = 'DISABLE_USER_SAFELY_PASS_FAIL';
export const ENABLE_USER_SAFELY_PASS = 'ENABLE_USER_SAFELY_PASS';
export const ENABLE_USER_SAFELY_PASS_SUCCESS = 'ENABLE_USER_SAFELY_PASS_SUCCESS';
export const ENABLE_USER_SAFELY_PASS_FAIL = 'ENABLE_USER_SAFELY_PASS_FAIL';

/*  disable Safely Pass */
export const SET_WORKSPACE_DISABLED = "SET_WORKSPACE_DISABLED";


// transactions
export const CREATE_TRANSACTION = " CREATE_TRANSACTION";
export const TRANSACTIONS_START_LOADING = 'TRANSACTIONS_START_LOADING';
export const TRANSACTIONS_END_LOADING = 'TRANSACTIONS_END_LOADING';
export const TRANSACTIONS_FAIL = 'TRANSACTIONS_FAIL';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const RENAME_TRANSACTIONS_SUCCESS = 'RENAME_TRANSACTIONS_SUCCESS';
export const SECONDARY_LOAD_START = 'SECONDARY_LOAD_START';
export const SECONDARY_LOAD_END = 'SECONDARY_LOAD_END';
export const PDF_LOCATION = 'PDF_LOCATION';
export const ASSIGN_TRANSACTION = 'ASSIGN_TRANSACTION';
export const ASSIGN_TRANSACTION_SUCCESS = 'ASSIGN_TRANSACTION_SUCCESS';
export const ASSIGN_TRANSACTION_FAIL = 'ASSIGN_TRANSACTION_FAIL';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS';
export const DELETE_TRANSACTION_FAIL = 'DELETE_TRANSACTION_FAIL';
export const SET_CURRENT_TRANSACTION = 'SET_CURRENT_TRANSACTION';
export const EXECUTE_TRANSACTION_SUCCESS = 'EXECUTE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS';
export const GET_TRANSACTION = 'GET_TRANSACTION';
export const GET_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS';
export const GET_TRANSACTION_FAIL = 'GET_TRANSACTION_FAIL';