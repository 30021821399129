import React, { useEffect, useState, Suspense, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { translate } from '../../redux/reducers/languageReducer';
import MainNavigation from '../Navigation/MainNavigation';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { getCheckIntoSusbpaceList, checkIntoSubspace, checkOutIntoSubspace, getActivesSubspaceCheckIn, resetCheckIntoSubspaceList } from '../../redux/actions/workspaceActions';
import Timer from '../commons/wrapped-components/inputs/Timer';
import Table from '../commons/wrapped-components/Table';
import Modal from '../commons/wrapped-components/Modal';
import { general } from '../../general/global';
import CheckOutSubspaceModal from './CheckOutSubspaceModal';
import { ReactComponent as CloseIcon } from "../../icons/closeIcon.svg";
import InputText from '../commons/wrapped-components/inputs/InputText';
import { filteredData } from '../../general/general';
import InputSearch from '../commons/wrapped-components/inputs/InputSearch';

const CheckIntoSubspace = ({
    userId,
    workSpaceId,
    accesstokenidp,
    dictionary,
    title,
    workSpaceName,
    loading,
    onGetCheckIntoSubspaceList,
    onCheckIntoSubspace,
    onCheckOutIntoSubspace,
    onGetActivesSubspaceCheckIn,
    checkIntoSubspaceList,
    activesSubspaceCheckIn,
    onResetCheckIntoSubspaceList,
    isSafelyPassActivated,
    history,
    refreshCheckIntoSubspaceScreen
}) => {
    const [showModalCheckIn, setShowModalCheckIn] = useState(false);
    const [showModalCheckOutSubspace, setShowModalCheckOutSubspace] = useState(false);
    const [selectedSubspace, setSelectedSubspace] = useState({});
    const [searchValue, setSearchValue] = useState("");
    useEffect(() => {
        if (isSafelyPassActivated) {
            onGetCheckIntoSubspaceList(workSpaceId, userId, accesstokenidp).then(response => { });
            onGetActivesSubspaceCheckIn(userId, accesstokenidp).then(response => { });
        } else {
            history.push('/safelypass');
        }
    }, [isSafelyPassActivated, refreshCheckIntoSubspaceScreen]);

    const buildColumns = () => {
        const newColumns = [
            {
                dataField: 'subspaceName', text: dictionary.s('subspace').toUpperCase(),
            },
            {
                dataField: 'occupancy', text: dictionary.s('occupancy').toUpperCase(),
                headerClasses: 'text-center',
                align: 'center',
                searchable: false
            },
            {
                dataField: 'checkinCheckout', text: dictionary.s('checkinCheckoutSubspace').toUpperCase(),
                headerAlign: (column, colIndex) => "right",
                searchable: false
            }
        ]

        return newColumns;
    }

    const buildRows = () => {
        const rows = [];
        if (!checkIntoSubspaceList.length) return rows;
        let isThereAnactiveSubpace = false;
        let fullCapacity = false;
        if (activesSubspaceCheckIn.length) {
            isThereAnactiveSubpace = true;
        }

        checkIntoSubspaceList.map((subspace) => {
            fullCapacity = subspace.alreadyOccupied === subspace.maxOccupancy;
            rows.push({
                subspaceName: subspace.subspaceName,
                occupancy: (
                    <>
                        <span className={`${fullCapacity && 'text-danger'}`}>{subspace.alreadyOccupied}</span>
                        <span className='span-check-into max-occupancy'>{' / ' + subspace.maxOccupancy + ' ' + dictionary.s('membersInSubspace')}</span>
                    </>
                ),
                checkinCheckout: subspace.checkInStatus ? (
                    <div className="text-right">
                        <Timer
                            value={subspace.checkInDateTime}
                            contentStyle={{ marginBottom: 5 }}
                            textStyle={{ fontSize: 13, color: "#E67070", marginRight: 10 }}
                        />
                        <Button
                            onClick={() => {
                                setShowModalCheckOutSubspace(true);
                                setSelectedSubspace(subspace);
                            }}
                            className="btn-checkout-outline"
                        >
                            {dictionary.s('checkOutSubspace').toUpperCase()}
                        </Button>
                    </div>
                ) : (
                        <div className="text-right">
                            <Button
                                onClick={() => {
                                    setShowModalCheckIn(true);
                                    setSelectedSubspace(subspace);
                                }}
                                className={`btn-checkin-outline btn-checkin-subspace ${(isThereAnactiveSubpace || fullCapacity) ? 'disabled-checkin' : 'btn-checkin-outline'}`}
                                disabled={(isThereAnactiveSubpace || fullCapacity) ? true : false}
                            >
                                {dictionary.s('checkInSubspace').toUpperCase()}
                            </Button>
                        </div>
                    )
            });
        });
        return rows;
    }

    const closeCheckInModal = () => setShowModalCheckIn(false);
    //Checkin from subspace
    const onCheckInSubspace = async () => {
        closeCheckInModal();
        await onCheckIntoSubspace(workSpaceId, userId, selectedSubspace.subspaceId, accesstokenidp);
        await onGetActivesSubspaceCheckIn(userId, accesstokenidp);
    }

    const RenderCheckInModal = () => (
        <Modal
            showModal={showModalCheckIn}
            onHide={closeCheckInModal}
            dismissible={true}
            properties={{
                centered: true,
            }}
            textButtonSave={dictionary.s('checkInSubspace')}
            textButtonClose={dictionary.s('cancel')}
            onClose={closeCheckInModal}
            onSave={onCheckInSubspace}
            dialogClassName="safely-modal"
            className="animated fadeInDown checkout-modal"
            propertyButtonSave={{
                block: false,
                style: {
                    backgroundColor: general.theme.primaryColor,
                    color: general.theme.whiteFontColor,
                    border: `1px solid ${general.theme.primaryColor}`,
                    width: '100px',
                    height: '40px',
                    fontWeight: '500',
                    padding: '0px',
                    lineHeight: '1.2rem'
                }
            }}
            bsStyleButtonSave="secondary"
            buttonCloseProperties={{
                block: false,
                style: {
                    backgroundColor: general.theme.grayLighter,
                    border: `0px`,
                    color: general.theme.secondaryColor,
                    width: '100px',
                    height: '40px',
                    fontWeight: '500'
                }
            }}
            body={
                <>
                    <button
                        onClick={closeCheckInModal}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <CloseIcon />
                    </button>
                    <h3>
                        {`${dictionary.s("checkIntoQuestion")} ${selectedSubspace.subspaceName}?`}
                    </h3>
                    <span className="checkout-subspace question-text">
                        {dictionary.s('areYouSureYouWantToCheckInto') + ' '}
                    </span>
                    <span className="checkout-subspace selected-subspace">
                        {selectedSubspace.subspaceName + '?'}
                    </span>
                </>
            }
        />
    )

    const closeCheckOutSubspaceModal = () => setShowModalCheckOutSubspace(false);
    //Checkout from subspace
    const onCheckOutSubspace = async () => {
        closeCheckOutSubspaceModal();
        const { _id: accessAuthorizationId } = activesSubspaceCheckIn.find(subspace => subspace.subspaceId === selectedSubspace.subspaceId);
        await onCheckOutIntoSubspace(accessAuthorizationId, accesstokenidp);
    }

    const renderCheckOutSubspaceModal = () => {
        if (activesSubspaceCheckIn.length) {

            return (
                <CheckOutSubspaceModal
                    showModalCheckOutSubspace={showModalCheckOutSubspace}
                    closeCheckOutSubspaceModal={closeCheckOutSubspaceModal}
                    dictionary={dictionary}
                    selectedSubspace={selectedSubspace}
                    onCheckOutSubspace={onCheckOutSubspace}
                />
            )
        }
    }

    const renderSearch = () => {
        return (
            <Row>
                <Col xs={12} sm={6}
                    className="input-search-container searchSpace"
                >
                    <InputSearch
                        placeholder={dictionary.s("search")}
                        searchValue={searchValue}
                        onChangeText={setSearchValue}
                        handleSearch={() => { }}
                    />
                </Col>
            </Row>
        );
    };

    const columns = useMemo(() => buildColumns(), [dictionary]);
    const data = useMemo(() => buildRows(), [checkIntoSubspaceList, activesSubspaceCheckIn]);

    const tableData = filteredData(searchValue, data, 'subspaceName');

    return (
        <MainNavigation title={title}>
            <Container fluid style={{ paddingRight: '45px', paddingLeft: '45px' }}>
                <RenderCheckInModal />
                {renderCheckOutSubspaceModal()}
                <Row>
                    <Col sm={12} className="pt-5 pb-4">
                        <h5>{`${dictionary.s('youAreCheckedIntoTheSpace')} `}<b>{workSpaceName}</b> </h5>
                    </Col>
                    <Col style={{ marginBottom: 45 }} className="checkin-table">
                        {renderSearch()}
                        <Table
                            columns={columns}
                            data={tableData}
                            noDataIndication={loading ? dictionary.s("loading") : dictionary.s("noResultFound")}
                            striped={false}
                            placeholderSearch={dictionary.s('search')}
                            hover={false}
                            condesed={true}
                            customPagination={true}
                            dataSize={10}
                            sizePerPage={10}
                            dictionary={dictionary}
                        />
                    </Col>
                </Row>
            </Container>
        </MainNavigation>
    )
}

CheckIntoSubspace.propTypes = {
    dictionary: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    userId: state.user.id,
    workSpaceId: state.workspace.spaceFocused._id,
    accesstokenidp: state.user.accesstokenidp,
    dictionary: {
        s: key => translate(state.language.dictionary, key)
    },
    workSpaceName: state.workspace.spaceFocused.name,
    loading: state.workspace.loading,
    checkIntoSubspaceList: state.workspace.checkIntoSubspaceList,
    activesSubspaceCheckIn: state.workspace.activesSubspaceCheckIn,
    isSafelyPassActivated: state.operation.safelyPassStatus === "granted",
    refreshCheckIntoSubspaceScreen: state.workspace.refreshCheckIntoSubspaceScreen,
});

const mapDispatchToprops = (dispatch) => ({
    onGetCheckIntoSubspaceList: (workSpaceId, userId, accesstokenidp) =>
        dispatch(getCheckIntoSusbpaceList(workSpaceId, userId, accesstokenidp)),
    onCheckIntoSubspace: (workSpaceId, userId, subspaceId, accesstokenidp) =>
        dispatch(checkIntoSubspace(workSpaceId, userId, subspaceId, accesstokenidp)),
    onCheckOutIntoSubspace: (accessAuthorizationId, accesstokenidp) =>
        dispatch(checkOutIntoSubspace(accessAuthorizationId, accesstokenidp)),
    onGetActivesSubspaceCheckIn: (userId, accesstokenidp) =>
        dispatch(getActivesSubspaceCheckIn(userId, accesstokenidp))
});

export default connect(mapStateToProps, mapDispatchToprops)(CheckIntoSubspace);
