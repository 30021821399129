import React, { useEffect, useState } from "react";
import MainNavigation from "../Navigation/MainNavigation";
import {
  Container,
  Row,
  Col,
  Button
} from "react-bootstrap";
import QRCode from "../commons/wrapped-components/QRCode";
import { general } from "../../general/global";
import { connect } from "react-redux";
import Avatar from "../commons/wrapped-components/Avatar";
import {
  checkOut,
  getAccessAuthorizationActivesList,
  requestAccessAuthorization,
  changeSafelyPassStatus
} from "../../redux/actions/operationActions";
import { translate } from "../../redux/reducers/languageReducer";
import Modal from "../commons/wrapped-components/Modal";
import { getRoleById } from "../../general/general";
import { ReactComponent as PassIcon } from "../../icons/pass_green.svg";
import { ToastSuccess } from "../../redux/actions/toastAction";
import Timer from "./Timer";
import {
  setWorkspaceFocused,
  changeWorkspaceFocusedForSafelyPass,
  checkOutIntoSubspace,
  resetCheckIntoSubspaceList,
  getCheckIntoSusbpaceList,
  getActivesSubspaceCheckIn,
  checkWorkspaceIsDisabled
} from "../../redux/actions/workspaceActions";
import BtnCheckIntoSusbapace from "./BtnCheckIntoSusbapace";
import moment from 'moment';

const MySafelyPass = props => {
  const [isActive, setIsActive] = useState(props.isSafelyPassActivated);
  if (!isActive && props.isSafelyPassActivated) setIsActive(true);
  const [showModal, setShowModal] = useState(true);
  const [showModalChangeSpace, setShowModalChangeSpace] = useState(false);
  const selectedSpace = props.workspaceFocusedForSafelyPass;
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const checkIsSafelyPassIsActivated = async () => {
    if (props.loading) return;
    const { status, data } = await props.onGetAccessAuthorizationActivesList(
      props.userId
    );
    if (status && data.length) {
      const workspace = props.workSpacesList.find(
        x => x._id === data[0].workSpaceId
      );
      if (workspace) {
        props.onChangeWorkspaceFocusedForSafelyPass(workspace);
        const { accessGranted, grantDate, ...rest } = data[0];
        const safelyPassStatus = accessGranted ? "granted" : "denied";
        props.onChangeSafelyPassStatus(safelyPassStatus, {
          accessGranted,
          grantDate: new Date(grantDate),
          ...rest
        });
      }
    }
  };

  const onCheckOutSubspace = async () => {
    if (props.activesSubspaceCheckIn !== undefined && props.activesSubspaceCheckIn.length) {
      const activeSubspace = props.activesSubspaceCheckIn[0];
      const { _id: accessAuthorizationId } = activeSubspace;
      await props.onCheckOutIntoSubspace(accessAuthorizationId, props.accesstokenidp);
    }
  }

  const onCheckOut = async () => {
    setIsActive(false);
    await props.onCheckOut(props.accessAuthorizationId);
    await onCheckOutSubspace();
    props.onResetCheckIntoSubspaceList();
    props.onToastSuccess(props.dictionary.s("safelyPassDeactivated"));
    props.history.push("/workspaceDetails");
  };

  const checkIsUserDisabled = async () => {
    if (await workspaceIsDisabled()) {
      props.history.push("/workspaceDetails");
    }
  }

  useEffect(() => {
    moment.locale(props.lang);
    checkIsSafelyPassIsActivated();
    if (props.safelyPassStatus === 'inactive') {
      isActive ? props.history.push("/workspaceDetails") : props.history.push("/userVerification");
    }
    
    //Get checkInto subspace list
    if (isActive || props.isSafelyPassActivated) {
      if (props.checkIntoSubspaceList?.length === 0) {
        props.onGetCheckIntoSubspaceList(props.workSpaceId, props.userId, props.accesstokenidp).then(response => { });
        props.onGetActivesSubspaceCheckIn(props.userId, props.accesstokenidp).then(response => { });
      }
    }

    checkIsUserDisabled();
  }, [isActive, props.isSafelyPassActivated]);

  const onHideModalChangeSpace = () => {
    setShowModalChangeSpace(false);
  };

  const onSaveModalChangeSpace = () => {
    setShowModalChangeSpace(false);
    props.onChangeWorkspaceFocusedForSafelyPass(selectedSpace);
    props.history.push("/userVerification");
  };

  const onCloseModalChangeSpace = () => {
    setShowModalChangeSpace(false);
  };

  const bodyModalChangeSpace = () => (
    <>
      <div className="safelyPass-popup-text2">
        {props.dictionary.s("ifYouChangeTheSpace")}
      </div>
    </>
  );

  const reusableModal = (
    title,
    getshowModal,
    onHide,
    onSave,
    onClose,
    textButtonSave,
    textButtonClose,
    body,
    type
  ) => (
      <Modal
        title={title}
        className="animated fadeInDown safelyPass-popup"
        showModal={getshowModal}
        onHide={onHide}
        onSave={onSave}
        onClose={onClose}
        textButtonSave={textButtonSave}
        textButtonClose={textButtonClose}
        bsStyleButtonSave={
          type === "denied" ? "secondary safelyPass-popup-button" : ""
        }
        propertyButtonSave={{
          className:
            type === "denied"
              ? "border-radius-none"
              : "safelyPass-popup-button-yes",
          block: true
        }}
        buttonCloseProperties={{
          className: type === "denied" ? "" : "safelyPass-popup-button-no"
        }}
        properties={{
          centered: true
        }}
        body={body()}
      />
    );

  const getSpacePicture = () => {
    if (props.workspaceFocusedForSafelyPass.picture) {
      return (
        <Avatar
          src={props.workspaceFocusedForSafelyPass.picture}
          className="rounded-circle-small avatar"
          styles={{ boxShadow: '0px 0px 0px 5px #fff' }}
        />
      );
    } else {
      return (
        <div
          className="rounded-circle-small noSpaceImage-worksapce"
          style={{
            backgroundColor: props.workspaceSettings.backgroundColor,
            padding: '10px 8px',
          }}
        >
          {props.workspaceFocusedForSafelyPass.name.substring(0, 2)}
        </div>
      )
    }
  };

  const renderCheckIntoSubspaceButton = () => {
    let showCheckIntoSubspaceButton = true;
    if (props.activesSubspaceCheckIn.length) {
      showCheckIntoSubspaceButton = false;
    }
    return (
      props.isSafelyPassActivated && (
        <BtnCheckIntoSusbapace
          type={showCheckIntoSubspaceButton ? 'into' : 'out'}
          dictionary={props.dictionary}
          history={props.history}
        />
      )
    )
  }

  const workspaceIsDisabled = async () => {
    const { data, status } = await props.onCheckWorkspaceIsDisabled(props.userId, props.workspaceFocused._id);
    if (status && data) return data.disabled;
    return false;
  }

  const renderViewBySafelyPassStatus = () => {
    switch (props.safelyPassStatus) {
      case "inactive":
        return null;
      // case 'canceled':
      case "denied":
        const onHide = () => {
          setShowModal(false);
          props.onCheckOut("");
          props.onChangeSafelyPassStatus("inactive");
          props.history.push("/workspaceDetails");
        };

        const onSave = () => {
          setShowModal(false);
          props.onCheckOut("");
          props.onChangeSafelyPassStatus("inactive");
          props.history.push("/workspaceDetails");
        };

        const deniedModalbody = () => (
          <>
            <div className="safelyPass-popup-text1">
              {props.safelyPassStatus === "canceled"
                ? props.dictionary.s("yourSafelyPassHavenotBeenGenerated1")
                : props.dictionary.s("yourSafelyPassVaveNotBeenActived1")}
            </div>
            <div className="safelyPass-popup-text2">
              {props.safelyPassStatus === "canceled"
                ? props.dictionary.s("yourSafelyPassHavenotBeenGenerated2")
                : props.dictionary.s("yourSafelyPassVaveNotBeenActived2")}
            </div>
          </>
        );

        return reusableModal(
          props.dictionary.s("safelyPass"),
          showModal,
          onHide,
          onSave,
          null,
          props.dictionary.s("close"),
          null,
          deniedModalbody,
          "denied"
        );
      case "pending":
        return (
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
              padding: "0px 30px 0 30px"
            }}
          >
            <Col md={12}>
              <div>
                {getSpacePicture()}
                <label
                  style={{
                    fontSize: 26,
                    fontWeight: "600",
                    color: general.theme.darkColor,
                    marginLeft: '45px',
                  }}
                  className="safypasstext"
                >
                  {props.workSpaceName}
                </label>
              </div>
            </Col>
            <Col
              md={5}
              xs={11}
              style={{ ...styles.safelyPassContent, backgroundColor: "#fff" }}
            >
              <div style={{ padding: "10px 20px" }}>
                <label
                  style={{
                    fontSize: 24,
                    textAlign: "center",
                    fontWeight: "bold",
                    color: general.theme.darkColor,
                    width: "100%"
                  }}
                >
                  {props.dictionary.s("mySafelyPass")}
                </label>
              </div>
              <div style={{ padding: "5px 20px" }}>
                <label
                  style={{
                    fontSize: 14,
                    textAlign: "center",
                    // color: general.theme.primaryColor,
                    width: "100%"
                  }}
                >
                  {props.dictionary.s(
                    "justOneMoreStepBeforeYouActivateYourSafelyPass"
                  )}
                </label>
              </div>
              <div className="Box-containerQR">
                <div
                  className="containerQR"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center"
                  }}
                >
                  <em></em>
                  <QRCode
                    value={
                      "safelyPass||" + props.userId + "||" + props.workSpaceId
                    }
                    size={200}
                    styles={{
                      borderRadius: "10px",
                      margin: "20px",
                      opacity: 1,
                      ...styles.safelyPassInnerContent
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  margin: "10px 30px"
                }}
              >
                <label
                  style={{
                    textAlign: "center",
                    color: general.theme.darkColor
                  }}
                >
                  {props.buttonAction === 'activateSafelyPass' ?
                    '' : props.dictionary.s("pleaseProceedToTheAssignedVerificationPointToActivateYourSafelyPass")
                  }
                </label>
                <Button
                  disabled={buttonDisabled}
                  onClick={async () => {
                    if (!await workspaceIsDisabled()) {
                      setButtonDisabled(true);
                      if (props.buttonAction === 'activateSafelyPass') {
                        await props.onRequestAccessAuthorization(
                          props.workSpaceId, props.userId
                        );
                        await checkIsSafelyPassIsActivated();
                      }
                      else {
                        await checkIsSafelyPassIsActivated();
                      }
                      setButtonDisabled(false);
                    }
                  }}
                >
                  {props.dictionary.s(props.buttonAction)}
                </Button>
              </div>
            </Col>
          </Row>
        );
      case "granted":
        return (
          <>
            <Row className="user-profile">
              <Col
                sm={12}
                lg={4}
                md={3}
                xl={4}
                xs={12}
                className="user-details d-flex align-items-center"
              >
                <div style={{ position: "relative" }}>
                  <Avatar src={props.picture} className="rounded-circle mr-2" />
                </div>
                <div
                  className="user-info"
                >
                  <div className="user-info-text-name">
                    {props.userName} {props.lastName}
                  </div>
                  <p className="user-info-text-role">
                    {props.dictionary.s(
                      getRoleById(
                        props.workspaceFocusedForSafelyPass.users
                          .filter(({ id }) => id === props.userId)
                          .map(({ roleId }) => roleId)
                          .toString()
                      )
                    )}
                  </p>
                </div>
              </Col>
              <Col
                sm={12}
                lg={4}
                md={4}
                xl={4}
                className="safelypass-text-container"
              >
                <div className="d-flex safelypass-activated-div">
                  <PassIcon width={25} height={25} />
                  <label className="safelypass-activated-text">
                    {props.dictionary.s("safelyPassActivated")}
                    <div className="safelypass-activated-date">
                      {moment(props.safelyPassGrantedDate).format("ddd, DD MMM YYYY").replace('.', '')}</div>
                  </label>
                </div>
              </Col>
              <Col
                sm={12}
                lg={4}
                md={5}
                xl={4}
                xs={12}
                className="timer-section"
              >
                <div className="timer-check-out-contier">
                  <div className="workspaceSafelyPasscontier">
                    {props.workspaceFocusedForSafelyPass.picture ? <Avatar
                      src={props.workspaceFocusedForSafelyPass.picture}
                      className="rounded-circle"
                      styles={{ boxShadow: '0px 0px 0px 5px #fff' }}
                      width={40}
                      height={40}
                    /> : <div
                      className="noSpaceImage-worksapce"
                      style={{
                        backgroundColor: props.workspaceFocusedForSafelyPass.settings.backgroundColor,
                      }}
                    >
                        <div>
                          {props.workspaceFocusedForSafelyPass.name.substring(0, 2)}
                        </div>
                      </div>}
                    <div className="safely-workspace-name">
                      {props.workspaceFocusedForSafelyPass.name}
                    </div>
                  </div>

                  <div className="timer-container">
                    <Timer
                      isActive={isActive}
                      value={props.safelyPassGrantedDate}
                      onFinish={() => onCheckOut()}
                    />
                    <Button
                      className="btn-checkout"
                      variant="danger"
                      onClick={() => {
                        onCheckOut();
                      }}
                    >
                      {props.dictionary.s("checkOut")}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  className="qr-code-section">
                  <QRCode
                    value={props.userId}
                    size={200}
                    border={`4px solid ${props.workspaceSettings.backgroundColor}`}
                    styles={{
                      borderRadius: "10px",
                      margin: "20px",
                      opacity: 1,
                      ...styles.safelyPassInnerContent
                    }}
                  />
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center">
                  {renderCheckIntoSubspaceButton()}
                </div>
              </Col>
            </Row>
          </>
        );
      default:
        return (
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
              height: "50vh"
            }}
          >
            <Col
              md={5}
              xs={11}
              style={{ ...styles.safelyPassContent, border: "1px solid #ccc" }}
            >
              <div>
                <div>
                  <label style={{ textAlign: "center" }}>
                    {props.dictionary.s("yourSafelyPassHavenotBeenGenerated")}
                  </label>
                </div>
              </div>
            </Col>
          </Row>
        );
    }
  };

  return (
    <MainNavigation title={props.title}>
      <Container fluid className="safelypass-coniter">
        {reusableModal(
          props.dictionary.s("switchSpace"),
          showModalChangeSpace,
          onHideModalChangeSpace,
          onSaveModalChangeSpace,
          onCloseModalChangeSpace,
          props.dictionary.s("yes"),
          props.dictionary.s("no"),
          bodyModalChangeSpace,
          "changeSapace"
        )}
        {
          props.safelyPassStatus !== 'inactive' ? renderViewBySafelyPassStatus() : null
        }
      </Container>
    </MainNavigation>
  );
};

const styles = {
  content: {
    flexGrow: 1
  },
  safelyPassContent: {
    display: "flex",
    flexDirection: "column",
    flex: 2,
    marginTop: 0,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F9FBFE"
  },
  safelyPassInnerContent: {
    backgroundColor: "#fff",
    // padding: 15,
    borderRadius: 20,
    marginVertical: 35,
    shadowColor: "#455B6326",
    shadowRadius: 20,
    shadowOpacity: 0.7
  }
};

const mapStateToProps = state => {
  const user = state.user;
  const sortedList = state.workspace.list.sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const workspaceSettings = state.workspace.workspaceFocusedForSafelyPass.settings;
  const { selfDeclaration, verificationPoint } = workspaceSettings;

  let safelyPassStatus = state.operation.safelyPassStatus;
  let buttonAction = 'refresh';
  switch (true) {
    case ((!selfDeclaration && verificationPoint) && safelyPassStatus === 'inactive'):
      safelyPassStatus = 'pending';
      break;
    case ((!selfDeclaration && !verificationPoint) && safelyPassStatus === 'inactive'):
      safelyPassStatus = 'pending';
      buttonAction = 'activateSafelyPass';
      break;
  }

  return {
    workspaceSettings,
    safelyPassStatus,
    buttonAction,
    picture: user.picture,
    userName: user.name + " " + user.lastName,
    userId: user.id,
    workSpaceId: state.workspace.workspaceFocusedForSafelyPass._id,
    workSpaceName: state.workspace.workspaceFocusedForSafelyPass.name,
    uploadingPhoto: state.user.uploadingPhoto,
    safelyPassGrantedDate: state.operation.safelyPassGrantedDate,
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    },
    loading: state.operation.loading,
    isSafelyPassActivated: state.operation.safelyPassStatus === "granted",
    accessAuthorizationId: state.operation.accessAuthorizationId,
    workSpacesList: sortedList,
    workSpacesLoading: state.workspace.loading,
    workspaceFocusedForSafelyPass: state.workspace.workspaceFocusedForSafelyPass,
    activesSubspaceCheckIn: state.workspace.activesSubspaceCheckIn,
    checkIntoSubspaceList: state.workspace.checkIntoSubspaceList,
    lang: state.language.lang,
    accesstokenidp: state.user.accesstokenidp,
  };
};

const mapDispatchToProps = dispatch => ({
  onCheckOut: id => dispatch(checkOut(id)),
  onGetAccessAuthorizationActivesList: userId =>
    dispatch(getAccessAuthorizationActivesList(userId)),
  onChangeSafelyPassStatus: (status, extraInfo) =>
    dispatch(changeSafelyPassStatus(status, extraInfo)),
  onToastSuccess: message => dispatch(ToastSuccess(message)),
  onSetWorkspaceFocused: workspace => dispatch(setWorkspaceFocused(workspace)),
  onChangeWorkspaceFocusedForSafelyPass: workspace => dispatch(changeWorkspaceFocusedForSafelyPass(workspace)),
  onRequestAccessAuthorization: (workSpaceId, userId, userVerificationId, deviceId) => dispatch(requestAccessAuthorization(workSpaceId, userId, userVerificationId, deviceId)),
  onCheckOutIntoSubspace: (accessAuthorizationId, accesstokenidp) =>
    dispatch(checkOutIntoSubspace(accessAuthorizationId, accesstokenidp)),
  onResetCheckIntoSubspaceList: () =>
    dispatch(resetCheckIntoSubspaceList()),
  onGetCheckIntoSubspaceList: (workSpaceId, userId, accesstokenidp) =>
    dispatch(getCheckIntoSusbpaceList(workSpaceId, userId, accesstokenidp)),
  onGetActivesSubspaceCheckIn: (userId, accesstokenidp) =>
    dispatch(getActivesSubspaceCheckIn(userId, accesstokenidp)),
  onCheckWorkspaceIsDisabled: (userId, workspaceId) =>
    dispatch(checkWorkspaceIsDisabled(userId, workspaceId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MySafelyPass);