import styled from 'styled-components';

const Icon = styled.i`
font-size: ${props => props.fontsize};
color: ${props => props.color};
font-style: ${props => props.fontstyle};
position: ${props => props.position};
z-index: ${props => props.zIndex};
right: ${props => props.right};
line-height: ${props => props.lineHeight};
padding: ${props => props.padding};
padding-left: ${props => props.paddingleft};
left:${props => props.left};
margin-left: ${props => props.marginleft};
margin-left: ${props => props.marginLeft};


&::before{
    color:${props => props.color} !important;
}
`
export default Icon;