import {
  GET_REPORT_DATA,
  GET_REPORT_DATA_SUCCESS,
  GET_REPORT_DATA_FAIL,
  GET_VERIFICATION_FORM_INPUTS_REQUEST,
  GET_VERIFICATION_FORM_INPUTS_SUCCESS,
  GET_VERIFICATION_FORM_INPUTS_FAIL,
  GET_DECLARATION_FORM_INPUTS_REQUEST,
  GET_DECLARATION_FORM_INPUTS_SUCCESS,
  GET_DECLARATION_FORM_INPUTS_FAIL
} from "../actions/types";

export const initialState = {
  safelyPassesHistoryReports: [],
  data: {},
  verificationForm: [],
  declarationForm: [],
  verificationReportId: null,
  declarationReportId: null,
  message: null,
  loading: false
};

export default (state, action) => {
  const { type, payload } = action;
  if (typeof state === "undefined") {
    return initialState;
  }
  const newData = { ...state.data };
  switch (type) {
    case GET_REPORT_DATA:
      newData[payload.reportType] = { reportData: [], exportReportId: "" };
      return { ...state, loading: true, data: newData };
    case GET_REPORT_DATA_SUCCESS:
      newData[payload.reportType] = {
        reportData: payload.data,
        exportReportId: payload.exportReportId,
        totalCount: payload.totalCount,
        totalPages: payload.totalPages
      };
      return { ...state, loading: false, data: newData };
    case GET_REPORT_DATA_FAIL:
      return { ...state, loading: false, message: payload.message };
    case GET_VERIFICATION_FORM_INPUTS_REQUEST:
      return { ...state, loading: true };
    case GET_VERIFICATION_FORM_INPUTS_SUCCESS:
      return {
        ...state,
        loading: false,
        verificationForm: payload.data,
        verificationReportId: payload.exportReportId
      };
    case GET_VERIFICATION_FORM_INPUTS_FAIL:
      return { ...state, loading: false, message: payload.message };
    case GET_DECLARATION_FORM_INPUTS_REQUEST:
      return { ...state, loading: true };
    case GET_DECLARATION_FORM_INPUTS_SUCCESS:
      return {
        ...state,
        loading: false,
        declarationForm: payload.data,
        declarationReportId: payload.exportReportId
      };
    case GET_DECLARATION_FORM_INPUTS_FAIL:
      return { ...state, loading: false, message: payload.message };
    default:
      return state;
  }
};
