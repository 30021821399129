import React, { Component } from 'react'
import { connect } from "react-redux";
import { Button, Row, Col } from 'react-bootstrap';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';
import { translate } from "../../../../redux/reducers/languageReducer";


const icona = require('tui-image-editor/dist/svg/icon-a.svg');
const iconb = require('tui-image-editor/dist/svg/icon-b.svg');
const iconc = require('tui-image-editor/dist/svg/icon-c.svg');
const icond = require('tui-image-editor/dist/svg/icon-d.svg');

const whiteTheme = {
    'common.bi.image': '',
    // 'common.bisize.width': '0px',
    // 'common.bisize.height': '0px',
    'common.backgroundImage': 'none',
    'common.backgroundColor': '#fff',
    'common.border': '0px',

    // header
    'header.display': 'none',
    // 'header.backgroundImage': 'none',
    // 'header.backgroundColor': 'transparent',
    // 'header.border': '0px',

    // load button
    // 'loadButton.backgroundColor': '#fff',
    // 'loadButton.border': '1px solid #ddd',
    // 'loadButton.borderRadius': '0px',
    // 'loadButton.color': '#222',
    // 'loadButton.fontFamily': '\'Noto Sans\', sans-serif',
    // 'loadButton.fontSize': '12px',
    // 'loadButton.height': '25px',
    // 'loadButton.lineHeight': '25px',

    // download button
    // 'downloadButton.display': 'none',
    // 'downloadButton.backgroundColor': '#fdba3b',
    // 'downloadButton.border': '1px solid #fdba3b',
    // 'downloadButton.color': '#fff',
    // 'downloadButton.fontFamily': '\'Noto Sans\', sans-serif',
    // 'downloadButton.fontSize': '12px',
    // 'downloadButton.borderRadius': '0px',
    // 'downloadButton.height': '25px',
    // 'downloadButton.lineHeight': '25px',

    // main icons
    'menu.normalIcon.path': icond,
    'menu.activeIcon.path': iconb,
    'menu.disabledIcon.path': icona,
    'menu.hoverIcon.path': iconc,
    'menu.iconSize.width': '17px',
    'menu.iconSize.height': '17px',

    // submenu primary color
    'submenu.backgroundColor': 'transparent',
    'submenu.partition.color': '#e5e5e5',

    // submenu icons
    'submenu.normalIcon.path': icond,
    'submenu.activeIcon.path': iconc,
    'submenu.disabledIcon.path': icona,
    'submenu.hoverIcon.path': iconc,
    'submenu.iconSize.width': '20px',
    'submenu.iconSize.height': '20px',

    // submenu labels
    'submenu.normalLabel.color': '#858585',
    'submenu.normalLabel.fontWeight': 'normal',
    'submenu.activeLabel.color': '#000',
    'submenu.activeLabel.fontWeight': 'normal',

    // checkbox style
    'checkbox.border': '1px solid #ccc',
    'checkbox.backgroundColor': '#fff',

    // rango style
    'range.pointer.color': '#333',
    'range.bar.color': '#ccc',
    'range.subbar.color': '#606060',

    'range.disabledPointer.color': '#d3d3d3',
    'range.disabledBar.color': 'rgba(85,85,85,0.06)',
    'range.disabledSubbar.color': 'rgba(51,51,51,0.2)',

    'range.value.color': '#000',
    'range.value.fontWeight': 'normal',
    'range.value.fontSize': '11px',
    'range.value.border': '0',
    'range.value.backgroundColor': '#f5f5f5',
    'range.title.color': '#000',
    'range.title.fontWeight': 'lighter',

    // colorpicker style
    'colorpicker.button.border': '0px',
    'colorpicker.title.color': '#000'
};

const darkTheme = {
    'common.bi.image': '',
    'common.backgroundImage': 'none',
    'common.backgroundColor': '#fff',
    'common.border': '0px',

    'header.display': 'true',

     // main icons
     'menu.normalIcon.path': icond,
     'menu.activeIcon.path': iconb,
     'menu.disabledIcon.path': icona,
     'menu.hoverIcon.path': iconc,
     'menu.iconSize.width': '17px',
     'menu.iconSize.height': '17px',

    // submenu icons
    'submenu.normalIcon.path': icond,
    'submenu.activeIcon.path': iconc,
    'submenu.disabledIcon.path': icona,
    'submenu.hoverIcon.path': iconc,
    'submenu.iconSize.width': '20px',
    'submenu.iconSize.height': '20px',
};

export  class InputImageEditor extends Component {
    constructor(props) {
        super(props);
        this.editorRef = React.createRef();
    }

    render() {
        const {dictionary} = this.props;
        const locale = {
            Text: dictionary.s('text'),
             Draw: dictionary.s('draw'),
             Shape: dictionary.s('shape'),
             Color: dictionary.s('color'),
             Straight: dictionary.s('straight'),
             Free:dictionary.s('free'),
             Range:dictionary.s('range'),
             Right:dictionary.s('right'),
             Center:dictionary.s('center'),
             Left:dictionary.s('left'),
             Underline:dictionary.s('underline'),
             Italic:dictionary.s('italic'),
             Bold:dictionary.s('bold'),
             'Text size':dictionary.s('textsize'),
             Custom:dictionary.s('custom'),
             Square :dictionary.s('square'),
             Apply :dictionary.s('apply'),
             Cancel :dictionary.s('cancel'),
             Crop :dictionary.s('crop'),
             Undo :dictionary.s('undo'),
             Redo :dictionary.s('redo'),
             Reset :dictionary.s('reset'),
             Delete :dictionary.s('delete'),
             DeleteAll :dictionary.s('deleteAll'),
             Rotate :dictionary.s('rotate'),
         }
        return(
            <React.Fragment>
    
            <Row>
            <ImageEditor
            ref={this.editorRef}
            includeUI={{
                loadImage: this.props.loadImage,
                theme: this.props.theme === 'darkTheme' ? darkTheme : whiteTheme,
                menu: this.props.menu,
                locale:locale,
                uiSize: {
                    width: '100%'
                },
                menuBarPosition: this.props.menuBarPosition
            }}
            cssMaxWidth={700}
            cssMaxHeight='auto'
            // selectionStyle={{
            //     cornerSize: 20,
            //     rotatingPointOffset: 70
            // }}
            usageStatistics={true}
        />
            </Row>
            <Row className="mt-3">
            <Col xs={12} md={12} sm={12} lg={12} className="text-right">
            <Button variant='outline-secondary outline-secondary-cancel' className='border-radius-none' onClick={this.cancel} style={{ width: 100, }}>
            { this.props.textButtonCancel === null? dictionary.s('cancel') : this.props.textButtonCancel }
            </Button>
            <Button variant='outline-success outline-secondary-save' className='border-radius-none' onClick={this.save} style={{ marginLeft: 15, width: 100, }}>
               { this.props.textButtonSave === null? dictionary.s('save') : this.props.textButtonSave }
            </Button>
            </Col>
            </Row>
            </React.Fragment>
        )
        
    }
    cancel = () => {
        this.props.onCancel();
    }

    save = () => {
        const editorInstance = this.editorRef.current.getInstance();
        // this.editorRef.current.getRootElement().classList.add('image-editor-root');
        this.props.onSave(editorInstance.toDataURL());
    }

    loadImageFromFile = (file) => {
        const editorInstance = this.editorRef.current.getInstance();
        editorInstance.loadImageFromFile(file).then(result => {
        });
    }

    loadImageFromURL = (url, name) => {
        const editorInstance = this.editorRef.current.getInstance();
        editorInstance.loadImageFromURL(url, name).then(result => {
        });
    }
    
}
InputImageEditor.defaultProps = {
    loadImage: {
        path: '',//base64
        name: '',
    },
    theme: 'darkTheme',
    menu: ['crop', 'flip', 'rotate'/*, 'draw'*/, 'shape', 'icon', 'text', 'mask', 'filter'],
    initMenu: 'shape',
    menuBarPosition: 'bottom',
    properties: {
        // addText: (pos) => { },
        // onMousedown: (event, originPointer) => { },
    },
    locale: {
        //     Text: dictionary.s('text'),
        //     Draw: dictionary.s('draw'),
        //     Shape: dictionary.s('shape'),
    },
    cssMaxHeight: 1200,
    cssMaxWidth: '100%',
    textButtonSave: null,
    onSave: () => { },
    onCancel: () => { },
}


const mapStateToProps = state => {
    return {
      dictionary: {
        s: key => translate(state.language.dictionary, key)
      }
    };
  };

export default connect(mapStateToProps, null)(InputImageEditor) ;