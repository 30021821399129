import styled from "styled-components";

const SidebarButtonContainer = styled.div`
  background-color: ${props => props.backgroundColor};
  height: ${props => props.height};
  width: ${props => props.width};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: ${props => props.position};
  z-index: 1032;
  right: ${props => props.right};
  top: ${props => props.top};
  margin-top: 70px;
  border-radius: 4px 0px 0px 4px;
  bottom: ${props => props.bottom};
  left: ${props => props.left};
  margin-left: ${props => props.marginLeft};
  transform: ${props => props.rotate180dg};
  box-shadow: ${props => props.boxShadow};
  visibility: ${props => props.visibility};
  transition: ${props => props.transition};

  &:hover {
    transition: opacity 0.5s;
    opacity: 0.8;
  }

  /* @media(max-width: 700px){
        margin-left: 60.58px;
} */
`;

SidebarButtonContainer.defaultProps = {
  backgroundColor: "#AAE8E1",
  position: "absolute",
  bottom: "auto",
  right: 0,
  height: "42.87px",
  width: "32.31px"
};

export default SidebarButtonContainer;
