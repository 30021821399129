import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getPhoneCodeList } from "../../../../redux/actions/onboardingActions";
import Modal from "../Modal";
import { ListGroup } from 'react-bootstrap';
import Input from "../../styled-components/general/Input";
import InputSearch from "../inputs/InputSearch";

const EmailNumberPhone = ({ value, autoCorrect, autoCapitalize, onChangeText, onSelectionOfCountryCode, onChangedCountryCode, selectedCountryCode, selectedLanguage, ...props }) => {
    const [showCode, setShowCode] = useState(
        props.countryCode.length > 0 ? true : false
    );
    const [countryCodeList, setCountryCodeList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [filtedCountryCodeList, setFiltedCountryCodeList] = useState(null);

    const filterByLanguage = (phoneCodeList) => {
        const codeFiltered = phoneCodeList.map((item, index) => {
            if (selectedLanguage === 'en') {
                return { country: item.name_en, dial_code: item.dial_code }
            } else if (selectedLanguage === 'es') {
                return { country: item.name_es, dial_code: item.dial_code }
            }
        });

        return codeFiltered;
    }
    useEffect(() => {
        props.onGetPhoneCodeList().then((response) => {
            const { status, data } = response;
            if (status && data.length) {
                const filteredList = filterByLanguage(data);
                setCountryCodeList(filteredList);
                setFiltedCountryCodeList(null);
            }
        });
    }, [selectedLanguage])

    const validatePhoneNumber = getvalue => {
        setShowCode(/^\d+$/.test(getvalue));
        return getvalue;
    }

    const onFilterCountryCodeList = (text) => {
        const filter = text.toLowerCase();
        let filteredList = [];
        countryCodeList.forEach((item, i) => {
            if ((item.dial_code.toLowerCase().indexOf(filter) > -1) || (item.country.toLowerCase(filter).indexOf(filter) > -1)) {
                filteredList.push(item);
            }
        });
        if (filter === "") {
            filteredList = countryCodeList;
        }

        setFiltedCountryCodeList(filteredList);
    }

    const renderModalCoutryCodeList = () => (
        <Modal
            dialogClassName="safely-modal"
            showModal={showModal}
            onHide={() => {
                setFilterText('');
                setFiltedCountryCodeList(countryCodeList);
                setShowModal(false);
            }}
            bsStyleButtonSave="secondary"
            propertyButtonSave={{
                className: "border-radius-none",
                block: true,
            }}
            properties={{
                centered: true,
            }}
            body={
                <>
                    <div className="mb-3">
                        <InputSearch
                            placeholder={props.placeholderSearchBox}
                            searchValue={filterText}
                            onChangeText={gettext => {
                                setFilterText(gettext);
                                onFilterCountryCodeList(gettext);
                            }}
                            handleSearch={() => { }}
                            className="input-search-container"
                        />
                    </div>
                    <ListGroup style={{ overflow: "auto", height: 300 }}>
                        {filtedCountryCodeList ? filtedCountryCodeList.map((item, index) => (
                            <ListGroup.Item key={index} style={{ width: '100%', float: 'left', cursor: "pointer" }} onClick={() => {
                                onChangedCountryCode(item.dial_code);
                                setFilterText('');
                                setFiltedCountryCodeList(countryCodeList);
                                setShowModal(false);
                            }} active={selectedCountryCode === item.dial_code}>
                                <div style={{ width: "20%", float: 'left' }}>{item.dial_code}</div>
                                <div style={{ width: "80%", float: 'left' }}>{item.country}</div>
                            </ListGroup.Item>
                        ))
                            : countryCodeList.map((item, index) => (
                                <ListGroup.Item key={index} style={{ width: '100%', float: 'left', cursor: "pointer" }} onClick={() => {
                                    onChangedCountryCode(item.dial_code);
                                    setShowModal(false);
                                }} active={selectedCountryCode === item.dial_code}>
                                    <div style={{ width: "20%", float: 'left' }}>{item.dial_code}</div>
                                    <div style={{ width: "80%", float: 'left' }}>{item.country}</div>
                                </ListGroup.Item>
                            ))}
                    </ListGroup>
                </>
            }
        />
    )

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            {countryCodeList && renderModalCoutryCodeList()}
            {
                showCode === true && (
                    <button
                        style={{
                            width: 95,
                            marginRight: 5,
                        }}
                        className="input-style"
                        onClick={() => {
                            setShowModal(true);
                        }}
                    >{selectedCountryCode.length > 0 ? selectedCountryCode : '+52'}</button>
                )
            }
            <Input
                type="text"
                inputMode="text"
                autoCorrect={false}
                placeholder={props.placeholderPhoneNumberText}
                className='input-style form-control'
                value={value}
                onChange={e => onChangeText(validatePhoneNumber(e.target.value))}
            />
        </div>
    )
    // const a ="md";
    // a.length
}

EmailNumberPhone.defaultProps = {
    showCountryCode: false,
    label: '',
    countryCode: '',
    value: '',
    placeholder: '',
    onChangeText: () => { },
}

const mapStateToProps = state => ({
    phoneCodeList: state.onboarding.phoneCodeList,
    selectedLanguage: state.language.lang,
});

const mapDispatchToProps = (dispatch) => ({
    onGetPhoneCodeList: () => dispatch(getPhoneCodeList()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailNumberPhone);