import { safelyApi } from "../../api/safely";
import ApiEndPoint from "../../constants/apiEndPoint";
import {
    GET_MARKETPLACE_PRODUCT_DATA,
    GET_MARKETPLACE_PRODUCT_DATA_FAIL,
    GET_MARKETPLACE_PRODUCT_DATA_SUCCESS
} from './types';

export const getMarketplaceProductList = (accesstokenidp) => async (dispatch) => {
    try {
        dispatch({ type: GET_MARKETPLACE_PRODUCT_DATA, payload: {} });
        const response = await safelyApi(ApiEndPoint.MarketPlace , {
            method: "get",
            headers: { accesstokenidp: accesstokenidp },
        });
        if(response.status) {
            dispatch({ type: GET_MARKETPLACE_PRODUCT_DATA_SUCCESS, payload: response.data });
        } else {
            dispatch({ type: GET_MARKETPLACE_PRODUCT_DATA_FAIL, payload: response});
        }
        return response;
    } catch (error) {
        dispatch({ type: GET_MARKETPLACE_PRODUCT_DATA_FAIL, payload: error });
        return { error, status: false };
    }
}

export const postMarketplaceStatistics = (accesstokenidp, userId, type, datetime) => async (dispatch) => {
    try {
        const response = await safelyApi(ApiEndPoint.MarketPlaceStatistics , {
            method: "post",
            headers: { accesstokenidp: accesstokenidp },
            body: {
                userId,
                type,
                datetime
            },
        });
        return response;
    } catch (error) {
        return { error, status: false };
    }
}

export const clickOfProductButton = (accesstokenidp, userId, productId, type, datetime) => async (dispatch) => {
    try {
        const response = await safelyApi(ApiEndPoint.MarketPlaceStatistics , {
            method: "post",
            headers: { accesstokenidp: accesstokenidp },
            body: {
                userId,
                productId,
                type,
                datetime
            },
        });
        return response;
    } catch (error) {
        return { error, status: false };
    }
}