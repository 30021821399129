import styled from "styled-components";
import ButtonBootstrap from "react-bootstrap/Button";

//const primaryColor = "#13988A";
const Button = styled(ButtonBootstrap)`
  width: ${(props) => props.width};
  background-color: ${(props) => props.bgcolor};
  font-weight: ${(props) => props.fontWeight || 600};
  border-radius: ${(props) => props.borderradius};
  height: ${(props) => props.height};
  border-color: ${(props) => props.bgcolor};
  font-size: ${(props) => props.fontSize};
  position: ${(props) => props.position};
  right: ${(props) => props.right};
  display: ${(props) => props.display};
  margin: ${(props) => props.margin};
  color: ${(props) => props.color};
  line-height: ${(props) => props.lineheight};
  &:hover {
    background-color: ${(props) => props.bgcolor} !important;
    border-color: ${(props) => props.bgcolor} !important;
    color: ${(props) => props.color} !important;
  }
  &:focus {
    border: 1px solid ${(props) => props.bgcolor} !important;
  }
  &:active {
    background-color: ${(props) => props.bgcolor} !important;
  }
  &:disabled {
    background-color: ${(props) => props.bgcolor} !important;
  }
`;

export default Button;
