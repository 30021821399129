export default class ApiEndPoint {
    static ActivatedSafelyPasses = '/accessauthorization/dt/byworkspaceid/activated';
    static SafelyPassesNotGenerated = '/accessauthorization/dt/byworkspaceid/pendingforgrant';
    static SafelyPassesRejected = '/accessauthorization/dt/byworkspaceid/rejected';
    static CheckedInUsingRegister = '/accessauthorization/dt/byworkspaceid/unregisteredusers';
    static SafelyPassesDisabled = '/disabledsafelypass/history/report';
    static RemoveUserFromASpace = '/workspace/removeuser';
    static RemoveUserFromMultipleSpaces = '/workspace/multiple/removeuser';
    static CreateSubWorkspace = "/workspace/subspace";
    static AdduserToSubWorkspace = "/workspace/subspace/adduser";
    static RemoveuserToSubWorkspace = "/workspace/subspace/removeuser";
    static AdduserToMUltiSubWorkspace = "/workspace/subspace/multiple/adduser";
    static RemoveUserFromMultiSubSpaces = "/workspace/subspace/multiple/removeuser";
    static GetChecklistList = '/workspace/';
    static UpdateCheckListInput = '/checklist/inputs';
    static UpdateCheckListContent = "/checklist/content";
    static SubspaceGrantaccess = "/accessauthorization/subspace/grantaccess";
    static verificationFormsReport = "/accessauthorization/dt/byworkspaceid/verificationform";
    static selfDeclarationReport = "/accessauthorization/dt/byworkspaceid/declarationform";
    static ExportReport = "/report/download/";
    static Post = "/posts";
    static RegisterUser = "/user/unregistered";
    static CheckInUser = "/accessauthorization/unregistered";
    static WorkspacePicture = "/workspace/picture";
    static MarketPlace = "/marketplace";
    static MarketPlaceStatistics = "/marketplace/statistics";
    static checkDeleteStatus = "/workspace/check/deletestatus";
    static workSpaceAddUser = "/workspace/adduser";
    static SubspacesHistory = '/accessauthorization/reports/subspace/checkinout';
    static UserSubspacesHistory = '/accessauthorization/reports/subspace/checkinout';
    static SubspaceActivity = '/workspace/reports/subspaceHistory/';
    static getCheckIntoSubspaceList = "/workspace/subspace/checkinout/list";
    static checkIntoSubspace = '/accessauthorization/subspace/checkIn';
    static checkOutIntoSubspace = '/accessauthorization/subspace/checkout';
    static getActiveSubspaceCheckIn = '/accessauthorization/subspace/list/actives/';
    static SubspacesActivity = '/workspace/reports/subspace/realtimeactivity';
    static LeaveSpace = '/workspace/leaveSpace';
    static GetWorkspaceListWithPagination = '/workspace/list2/';
    static GetSubspacesByUserIdWorkSpaceId = '/workspace/subspaces/byUserIdbyWorkSpaceId';
    static updateUserDataFromUserMgmt = '/user/updateUserManagement';
    static GetSubspacesByUserIdWorkSpaceIdWithPagination = '/workspace/subspace/list/byUserIdbyWorkSpaceId';
    static GetUserListBySubspaceId = '/workspace/subspace/userlist/bySubSpaceId';
    static searchSpace = '/workspace/search/list/';
    static getUsersByWorkspaceId = '/workspace/userlist/';
    static getsubSpaceUnderSpaceApi = '/workspace/subspace/list';
    static getUsersForCheckpoint = '/workspace/userlist/byMonitorId';
    static disableUserSafelyPass = '/disabledsafelypass/disable';
    static enableUserSafelyPass = '/disabledsafelypass/enable';
    static getTransactions = '/transaction/getForms';
    static transactionsHistory = '/transaction/report';
    static renameTransaction = '/transaction/rename';
    static transactionExists = '/transaction/exists';
    static createTransaction = '/transaction';
    static assignTransaction = '/transaction/assignTo';
    static executeTransaction = '/transaction/executeForm';
    static deleteTransaction = '/transaction';
    static getTransaction = '/transaction';
}
