import React, { useEffect, useState } from "react";
import "../../styles/workspace.css";
import { connect } from "react-redux";
import Button from "../commons/styled-components/general/Button";
import { translate } from "../../redux/reducers/languageReducer";
import Header from "./header";
import ProgessSteps from "./progessSteps";
import Switch from "../commons/wrapped-components/inputs/Switch";
import { general } from "../../general/global";
import ColorPicker from "../commons/wrapped-components/ColorPicker";
import { addWorkspaceSettings } from "../../redux/actions/workspaceActions";
import Label from "../commons/styled-components/general/Label";

const SetWorkspace = (props) => {
  const { workspaceCreated } = props;
  const [deviceType, setdeviceType] = useState(window.deviceType);
  const [locationValue, setLocationValue] = useState(true
    // workspaceCreated.settings.locationIsRequired !== undefined ?
    //   workspaceCreated.settings.locationIsRequired : true
  );
  const [selfDeclarationValue, setSelfDeclarationValue] = useState(true
    // workspaceCreated.settings.selfDeclaration !== undefined ?
    //   workspaceCreated.settings.selfDeclaration : true
  );
  const [verificationPointValue, setVerificationPointValue] = useState(true
    // workspaceCreated.settings.verificationPoint !== undefined ?
    //   workspaceCreated.settings.verificationPoint : true
  );
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState('#C3DF9A'
    // workspaceCreated.settings.backgroundColor ?
    //   workspaceCreated.settings.backgroundColor : '#C3DF9A'
  );

  useEffect(() => {
    const handleResize = () => {
      setdeviceType(window.deviceType);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = () => setDisplayColorPicker(!displayColorPicker);

  const handleClose = () => setDisplayColorPicker(false);

  const onChangeColor = (getcolor) => setColor(getcolor.hex);

  return (
    <div className={deviceType + ' limiter create-workspace'}>
      <Header />
      <div className="container-login100">
        <div className="workspace-created">
          <span>
            {props.dictionary.s("setUpYourSpace")}
          </span>
        </div>
        <div className="wrap-login100">
          <div className="login100-form validate-form workspace-configuration-form">
            <ProgessSteps step="2" />
            <span className="setworkspace update-settings-text">
              {props.dictionary.s("pleaseUpdateYourSettings")}
            </span>
            <div>
              <div className="set-workspace switch-control-container">
                {/* <div className="set-workspace options border-top border-bottom switch-option-padding">
                  <div className="set-workspace-text">
                    <Label className="mr-auto">{props.dictionary.s('locationIsRequired')}</Label>
                    <div className="switch-label-text">{props.dictionary.s('yourLocationwillBeTracked')}</div>
                  </div>
                  <Switch
                    id={`switch_location`}
                    isOn={locationValue}
                    onChange={() => setLocationValue(!locationValue)}
                    onColor={general.theme.primaryColor}
                    offColor="#808080"
                    width={38}
                    height={22}
                    handleDiameter={17}
                  />
                </div> */}
                <div className="set-workspace options border-top border-bottom switch-option-padding">
                  <div className="set-workspace-text">
                    <Label className="mr-auto">{props.dictionary.s('selfDeclaration')}</Label>
                    <div className="switch-label-text">{props.dictionary.s('enableSelfDeclaration')}</div>
                  </div>
                  <Switch
                    id={`switch_self_declaration`}
                    isOn={selfDeclarationValue}
                    onChange={() => setSelfDeclarationValue(!selfDeclarationValue)}
                    onColor={general.theme.primaryColor}
                    offColor="#808080"
                    width={38}
                    height={22}
                    handleDiameter={17}
                  />
                </div>
                <div className="set-workspace options border-bottom switch-option-padding">
                  <div className="set-workspace-text">
                    <Label className="mr-auto">{props.dictionary.s('verificationPoint')}</Label>
                    <div className="switch-label-text">{props.dictionary.s('verificationPointSwitchText')}</div>
                  </div>
                  <Switch
                    id={`verification_point`}
                    isOn={verificationPointValue}
                    onChange={() => setVerificationPointValue(!verificationPointValue)}
                    onColor={general.theme.primaryColor}
                    offColor="#808080"
                    width={38}
                    height={22}
                    handleDiameter={17}
                  />
                </div>
                <div className="set-workspace options border-bottom switch-option-padding">
                  <div className="set-workspace-text">
                    <Label className="mr-auto" style={{ width: "initial" }}>{props.dictionary.s('spaceColor')}</Label>
                    <div className="switch-label-text">{props.dictionary.s('chooseUniqueColor')}</div>
                  </div>
                  <ColorPicker
                    handleClick={handleClick}
                    handleClose={handleClose}
                    onChangeColor={onChangeColor}
                    displayColorPicker={displayColorPicker}
                    color={color}
                    width={25}
                    height={25}
                    borderRadius="5px"
                    marginRight="5px" />
                </div>
              </div>
              <div className="bottom-button padding-top">
                <Button
                  className="backButton"
                  width="100px"
                  bgcolor="#1849E8"
                  position="absolute"
                  right="0"
                  borderradius="4px"
                  onClick={async () => props.history.push("/create-workspace")}
                >
                  {props.dictionary.s("back")}
                </Button>
                <Button
                  className="nextButton"
                  width="100px"
                  bgcolor="#1849E8"
                  position="absolute"
                  right="0"
                  borderradius="4px"
                  onClick={async () => {
                    const workspaceId = props.workspaceCreated._id;
                    const selfDeclaration = selfDeclarationValue;
                    const verificationPoint = verificationPointValue;
                    const location = locationValue;
                    const colorHex = color;
                    const response = await props.onAddWorkspaceSettings(workspaceId, selfDeclaration, verificationPoint, location, colorHex, props.accesstokenidp);
                    if (response.status) props.history.push("/workspace-configuration")
                  }}
                >
                  {props.dictionary.s(props.loading ? "loading" : "next")}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    locationServiceWhileUsing: state.onboarding.locationServiceWhileUsing,
    user: state.user,
    password: state.onboarding.password,
    accessToken: state.user.accesstoken,
    connectionId: state.user.connectionId,
    accesstokenidp: state.user.accesstokenidp,
    workspaceCreated: state.workspace.workspaceCreated,
    lang: state.language.lang,
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    }
  }
};

const mapDispatchToProps = (dispatch) => ({
  onAddWorkspaceSettings: (workspaceId, selfDeclaration, verificationPoint, locationIsRequired, backgroundColor, accesstokenidp) =>
    dispatch(addWorkspaceSettings(workspaceId, selfDeclaration, verificationPoint, locationIsRequired, backgroundColor, accesstokenidp))
});

export default connect(mapStateToProps, mapDispatchToProps)(SetWorkspace);