import {
    SHOW_TOAST_SUCCESS_MESSAGE,
    SHOW_TOAST_ERROR_MESSAGE,
    DESACTIVE_TOAST
} from "./types";

export const ToastSuccess = (message) => {
    return async (dispatch) => {
        dispatch({ type: SHOW_TOAST_SUCCESS_MESSAGE, payload: { message } });
    };
}

export const ToastError = (message) => {
    return async (dispatch) => {
        dispatch({ type: SHOW_TOAST_ERROR_MESSAGE, payload: { message } });
    };
}

export const DesactiveToast = () => {
    return async (dispatch) => {
        dispatch({ type: DESACTIVE_TOAST });
    };
}