import {
  MAGIC_LINK_CREATE,
  MAGIC_LINK_CREATE_SUCCESS,
  MAGIC_LINK_CREATE_FAIL,
  MAGIC_LINK_SET_MESSAGE,
  MAGIC_LINK_SET_CODE,
  MAGIC_LINK_GET_INFO_BY_CODE,
  MAGIC_LINK_GET_INFO_BY_CODE_SUCCESS,
  MAGIC_LINK_GET_INFO_BY_CODE_FAIL
} from "../actions/types";

export const initialState = {
  workspaceId: "",
  receiverId: "",
  countryCode: "+52",
  name: "",
  addedBy: "",
  message: null,
  loading: false,
  invitation: {
    _id: null,
    used: false,
    workSpaceId: "",
    workSpaceName: "",
    code: "",
    addedByUserId: "",
    addedByFullName: "",
    pictureURL: ""
  }
};

export default (state, action) => {
  const { type, payload } = action;
  if (typeof state === "undefined") {
    return initialState;
  }
  switch (type) {
    case MAGIC_LINK_CREATE:
    case MAGIC_LINK_GET_INFO_BY_CODE:
      return { ...state, loading: true };
    case MAGIC_LINK_CREATE_SUCCESS:
      return { ...state, ...initialState, ...payload.data };
    case MAGIC_LINK_CREATE_FAIL:
      return { ...state, loading: false, message: payload.message };
    case MAGIC_LINK_SET_MESSAGE:
      return { ...state, message: payload };
    case MAGIC_LINK_SET_CODE:
      return { ...state, code: payload };
    case MAGIC_LINK_GET_INFO_BY_CODE_SUCCESS:
      return { ...state, loading: false, invitation: payload.data };
    case MAGIC_LINK_GET_INFO_BY_CODE_FAIL:
      return { ...state, loading: false, message: payload.message };
    default:
      return state;
  }
};
