import styled from "styled-components";
import LabelBootstrap from "react-bootstrap/FormLabel";

const Label = styled(LabelBootstrap)`
  color: ${({ color, theme }) => color ? color : theme.label_color};
  font-family: ${({ fontFamily, theme }) => fontFamily ? fontFamily : theme.label_font_family};
  font-size: ${({ fontSize, theme }) => fontSize ? fontSize : theme.label_font_size};
  margin-bottom: 0px;
`;

export default Label;