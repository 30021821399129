import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, DropdownButton, Dropdown } from 'react-bootstrap';
import moment from 'moment';
import MainNavigation from "../Navigation/MainNavigation";
import Avatar from "../commons/wrapped-components/Avatar";
import dateFormats from "../../constants/dateFormats";
import { translate } from "../../redux/reducers/languageReducer";
import { getSubspaceHistory } from "../../redux/actions/reports";
import { getUserProfileById, ClickedUserOfUserManagement } from "../../redux/actions/userActions";
import Button from "../commons/styled-components/general/Button";
import { exportReportTypes } from '../../constants/exportReportTypes';
import ReportFilters from "./ReportFilters";
import { datePeriodOptions } from "../../constants/datePeriods";
import ExportReportButton from "./ExportReportButton";


class SubspaceHistory extends Component {
    constructor(props) {
        super(props);
        const todayDate = moment(new Date()).format("YYYY-MM-DD");
        this.state = {
            searchString: '',
            sizePerPage: 10,
            paginationPage: 1,
            showAll: false,
            deviceType: window.deviceType,
            showFilter: true,
            user: null,
            startDate: todayDate,
            endDate: todayDate,
        };
    }

    handleResize = () => {
        this.setState({ deviceType: window.deviceType });
    }

    componentDidMount() {
        this.fetchReportData(true);
        this.props.clickedUser(null);
        window.addEventListener("resize", this.handleResize);
    }

    fetchReportData = async (reloadHeader = true) => {
        const { onGetSubspaceHistory, accesstokenidp } = this.props;
        const { user_id } = this.queryStringToJSON(this.props.location.search.slice(1));
        const { sizePerPage, paginationPage, searchString, startDate, endDate } = this.state;
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const startDateUTC = startDate !== "" ? moment.tz(startDate + ' 00:00:00', timeZone).utc().format() : "";
        const endDateUTC = endDate !== "" ? moment.tz(endDate + ' 23:59:59', timeZone).utc().format() : "";

        const body = {
            searchString,
            startDate: startDateUTC,
            endDate: endDateUTC,
            timeZone,
            userId: user_id
        }

        onGetSubspaceHistory(body, paginationPage, sizePerPage, accesstokenidp);
        if (reloadHeader) {
            const responseUser = await this.props.onGetUserProfileById(user_id);
            let name = responseUser.data.name + " " + responseUser.data.lastName;
            this.props.clickedUser(name);
            this.setState({ user: responseUser.data })
        }
    }

    queryStringToJSON = (qs) => {
        const pairs = qs.split('&');
        const result = {};
        pairs.forEach(function (p) {
            const pair = p.split('=');
            const key = pair[0];
            const value = decodeURIComponent(pair[1] || '');

            if (result[key]) {
                if (Object.prototype.toString.call(result[key]) === '[object Array]') {
                    result[key].push(value);
                } else {
                    result[key] = [result[key], value];
                }
            } else {
                result[key] = value;
            }
        });

        return JSON.parse(JSON.stringify(result));
    };

    reportRow = (rowData) => {
        const { date, text, granted, status } = rowData;
        const lang = localStorage.getItem('lang');
        const dateFormat = dateFormats[lang];
        const icons = {
            'Checked-Out-true': 'checkout.png',
            'Checked-In-true': 'checkin.png',
            'Self-Declaration-true': 'declaration.png',
            'Verification-true': 'verification.png',
            'Self-Declaration-false': 'declaration-false.png',
            'Verification-false': 'verification-false.png',
            'Safely-Pass-true': 'pass.png',
            'Space-SafelyPass-Enabled-undefined': 'PassEnabled.png',
            'Space-SafelyPass-Disabled-undefined': 'PassDisabled.png'
        };
        return (
            <div className={'activityRow'}>
                <div className={'activityDate'}>
                    {moment(date).format(dateFormat)}
                </div>
                <div className={'activityText'}>
                    <img className={'activityIcon'} src={require('../../icons/' + icons[status + '-' + granted])} />
                    <span dangerouslySetInnerHTML={{ __html: text }} />
                </div>
            </div>
        );
    };

    handleSearch = searchString => { this.setState({ searchString, paginationPage: 1 }); };

    handleOnChange = (workSpaceId, startDate, endDate) => { this.setState({ startDate, endDate, searchString: '', paginationPage: 1 }); };

    getPeriodOptions = (datePeriodOptions) => {
        const periods = [];
        datePeriodOptions.map((period, index) => {
            periods.push({ name: this.props.dictionary.s(period.value), value: period.value })
        })
        return periods;
    }


    getExportReportBody = () => {
        const { userDetails } = this.props;
        const { startDate, endDate } = this.state;
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const startDateUTC = startDate !== "" ? moment.tz(startDate + ' 00:00:00', timeZone).utc().format() : "";
        const endDateUTC = endDate !== "" ? moment.tz(endDate + ' 23:59:59', timeZone).utc().format() : "";

        return {
            timeZone,
            reportType: exportReportTypes['SubspaceActivity'],
            startDate: startDateUTC,
            endDate: endDateUTC,
            userId: userDetails.id,
        }
    }

    getHeader = (reportData) => {
        const { picture, name, lastName } = this.state.user;
        return (
            <div style={{ marginBottom: 15, display: 'inline-block', width: '100%' }}>
                <Avatar
                    src={picture}
                    className="rounded-circle-small"
                    styles={{ verticalAlign: 'top', position: 'relative' }}
                />
                <label
                    style={{
                        fontSize: 24,
                        fontWeight: "600",
                        margin: '5px 0px 0px 5px',
                        display: 'inline'
                    }}
                >
                    {`${this.props.dictionary.s('subspaceActivityText1')} ${name} ${lastName}${this.props.dictionary.s('subspaceActivityText2')}`}
                </label>
                <div style={{ display: 'inline', float: 'right' }}>
                    <ExportReportButton disabled={reportData.length === 0} exportReportBody={this.getExportReportBody()} setFilterVisible={(show) => this.setState({ showFilter: show })} showFilter={this.state.showFilter} />
                </div>
                {this.state.showFilter ? (
                    <ReportFilters
                        onChange={this.handleOnChange}
                        options={this.getPeriodOptions(datePeriodOptions)}
                        handleSearch={this.handleSearch}
                    />
                ) : null
                }
            </div>
        );
    };

    getReportContent = (reportData) => {

        if (reportData.length === 0) return this.props.loading ? this.props.dictionary.s('loading') : this.props.dictionary.s('noResultFound');
        if (this.props.loading) return this.props.dictionary.s('loading');
        const header = (
            <div className={'activityRow'}>
                <div className={'activityHeaderText'}>
                    {this.props.dictionary.s('date').toUpperCase()}
                </div>
                <div className={'activityHeaderText'}>
                    {this.props.dictionary.s('activity').toUpperCase()}
                </div>
            </div>
        )

        const rows = [header]

        reportData.map((rowData) => {
            rows.push(this.reportRow(rowData));
        });

        return rows;
    };

    onSizePerPageChange = (option) => {
        if (option.id === 'all') {
            this.setState({
                showAll: true,
                sizePerPage: option.value,
                paginationPage: 1,
            });
        } else {
            this.setState({
                showAll: false,
                sizePerPage: option.value,
                paginationPage: 1,
            });
        }
    }

    onPageChange = (page) => { this.setState({ paginationPage: page }); }

    getPagination = (reportData) => {
        const { paginationPage, sizePerPage, showAll } = this.state;
        const { totalCount } = this.props.data || {};
        const from = (paginationPage * sizePerPage) - (sizePerPage - 1);
        const to = paginationPage * sizePerPage > totalCount ? totalCount : paginationPage * sizePerPage;
        const options = [{
            id: '10', text: '10', value: 10
        }, {
            id: '50', text: '50', value: 50
        }, {
            id: 'all', text: this.props.dictionary.s("All"), value: totalCount
        }];

        if (totalCount > 0 && reportData.length > 0) {
            return (
                <Row className="contactsTable-paginationFooter">
                    <Col xs={12} md={12} lg={4} className="contactsTable-paginationFooter-Showing">
                        <span className="react-bootstrap-table-pagination-total">
                            {`${this.props.dictionary.s('showing')}: ${from} - ${to}`}
                            {` ${this.props.dictionary.s('of')}  ${totalCount}`}
                        </span>
                    </Col>
                    <Col xs={12} md={6} lg={4} className="contactsTable-paginationFooter-Navigation">
                        <div>
                            <Button
                                disabled={(paginationPage === 1)}
                                variant="outline-secondary reports-buttons"
                                onClick={() => this.onPageChange(paginationPage - 1)}
                            >{this.props.dictionary.s('PREV')}</Button>
                            <span>
                                {`${paginationPage} ${this.props.dictionary.s('of')} ${Math.ceil(totalCount / sizePerPage
                                )}`}
                            </span>
                            <Button
                                disabled={paginationPage === Math.ceil(totalCount / sizePerPage)}
                                variant="outline-secondary reports-buttons"
                                onClick={() => this.onPageChange(paginationPage + 1)}
                            >{this.props.dictionary.s('NEXT')}</Button>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={4} className="contactsTable-paginationFooter-perPage">
                        <span>{`${this.props.dictionary.s("show")}:`}</span>
                        <DropdownButton
                            variant="outline-secondary reports-buttons"
                            title={showAll ? this.props.dictionary.s('all') : `${sizePerPage}`}
                        >
                            {
                                options.map(option => {
                                    const active = showAll ? (option.id === 'all') : (sizePerPage === option.value);
                                    return (
                                        <Dropdown.Item
                                            key={option.text}
                                            eventKey={option.id}
                                            active={active}
                                            onClick={() => this.onSizePerPageChange(option)}
                                        >
                                            {option.text}
                                        </Dropdown.Item>
                                    )
                                })
                            }
                        </DropdownButton>
                        <span>{this.props.dictionary.s('perPage')}</span>

                    </Col>
                </Row>
            );
        }
        return null;
    }

    render() {
        const reportData = this.props.data ? this.props.data.reportData : [];
        return (
            <MainNavigation title={this.props.title} >
                <div className="report-container">
                    {
                        this.state.user ? this.getHeader(reportData) : null
                    }
                    <div style={{ overflowX: 'auto' }}>
                        <div style={{ minWidth: 'fit-content', width: '100%' }}>
                            {
                                this.getReportContent(reportData)
                            }
                        </div>
                    </div>
                    <div className={'subspaces-history'} style={{ marginTop: 50, height: 150 }}>
                        {
                            this.getPagination(reportData)
                        }
                    </div>
                </div>
            </MainNavigation>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        switch (true) {
            case prevState.paginationPage !== this.state.paginationPage:
            case prevState.sizePerPage !== this.state.sizePerPage:
            case prevState.searchString !== this.state.searchString:
            case prevState.startDate !== this.state.startDate:
            case prevState.endDate !== this.state.endDate:
                this.fetchReportData(false);
                break;
        }
    }
}

const mapStateToProps = state => ({
    accesstokenidp: state.user.accesstokenidp,
    userDetails: state.user,
    data: state.reports.data.SubspaceActivity,
    loading: state.reports.loading,
    dictionary: {
        s: key => translate(state.language.dictionary, key)
    }
});
const mapDistchToProps = dispatch => ({
    onGetUserProfileById: (userId) => dispatch(getUserProfileById(userId)),
    onGetSubspaceHistory: (requestBody, page, limit, accesstokenidp) => dispatch(getSubspaceHistory(requestBody, page, limit, accesstokenidp)),
    clickedUser: (name) => dispatch(ClickedUserOfUserManagement(name))
});
export default connect(mapStateToProps, mapDistchToProps)(withRouter(SubspaceHistory));