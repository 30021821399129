import React, { Component } from 'react';

class ProgessSteps extends Component {
    render() {
        const {step} = this.props;
        return (
            <div className= "create-workspace-progess-bar">
              <div className={step === "1" || step === "2" || step === "3" ? "cricle-progess selected" : "cricle-progess"}>
               <div className={step === "1" || step === "2" || step === "3" ? "cricle-progess-text selected" : "cricle-progess-text"}>1</div>
              </div>
              {step === "1" ? <div className="patch-div"></div> : null }
              <div className={step === "2" || step === "3" ? "cricle-progess-line selected" : "cricle-progess-line"}></div>
              <div className={step === "2" || step === "3" ? "cricle-progess selected" : "cricle-progess"}>
              <div className={step === "2" ? "cricle-progess-text selected" : "cricle-progess-text"}>2</div>
              </div>
              {step === "2" ? <div className="patch-div"></div> : null }
              <div className={step === "3" ? "cricle-progess-line selected" : "cricle-progess-line"}></div>
              <div className={step === "3" ? "cricle-progess selected" : "cricle-progess"}>
              <div className={step === "3" ? "cricle-progess-text selected" : "cricle-progess-text"}>3</div>
              </div>
              {step === "3" ? <div className="patch-div"></div> : null }
            </div>
        );
    }
}

export default ProgessSteps;