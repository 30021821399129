import React from 'react';
import { general } from '../../../general/global';
import Label from '../styled-components/general/Label';
import Avatar from './Avatar';

const Space = ({
    className,
    data,
    showRole,
    key,
    onClick,
    dictionary
}) => {
    return (
        <div
            className={className}
            key={key}
            onClick={onClick}
        >
            {data.picture ?
                <Avatar
                    src={data.picture}
                    className="rounded-circle"
                    styles={{ boxShadow: '0px 0px 0px 5px #fff' }}
                    width={40}
                    height={40}
                /> :
                <div
                    className="noSpaceImage-worksapce"
                    style={{
                        backgroundColor: data.settings?.backgroundColor,
                    }}
                >
                    <div>{data.name?.substring(0, 2)}</div>
                </div>
            }
            <div style={{ padding: '0 10px' }}>
                <Label
                    className="space-name"
                    title={data.name}
                    color={general.theme.fontColorPrimary}
                >
                    {data.name.length > 30
                        ? general.str_truncate(data.name, 30, "...")
                        : data.name}
                </Label>
                {showRole && dictionary && <p className="roleName">{dictionary.s(data.roleName)}</p>}
            </div>
        </div>
    )
}

Space.defaultProps = {
    className: 'render-space',
};

export default Space;