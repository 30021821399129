import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { translate } from '../../redux/reducers/languageReducer';
import { creatingNewWorkspace, resetWorkspaceCreated, showMoreSpaces, searchSpace } from '../../redux/actions/workspaceActions';
import { setUserVerification } from '../../redux/actions/operationActions';
import InputSearch from '../commons/wrapped-components/inputs/InputSearch';
import Spaces from './Spaces';
import { CreateButton } from './CreateButton';

const RenderSpaces = ({
    dictionary,
    onCreatingNewWorkspace,
    onResetWorkspaceCreated,
    onSearchSpace,
    onShowMoreSpaces,
    history,
    loading,
    spacesListWithPagination,
    workspacesOld
}) => {
    if (spacesListWithPagination === undefined) {
        history.push('/exit');
    }

    const [searchValue, setSearchValue] = useState('');
    const { spaces, hasNextPage, limit, totalCount, totalPages } = spacesListWithPagination;


    const handleShowMoreSpaces = async () => {
        await onShowMoreSpaces();
    }

    const handleSearchSpace = async (value) => {
        setSearchValue(value);
        await onSearchSpace(value);
    }

    const onCreateSpace = () => {
        onCreatingNewWorkspace(false);
        onResetWorkspaceCreated();
        history.push("/create-workspace");
    }

    return (
        <>
            <Row className="space-header">
                <Col xs={12} className="header-title">
                    <h4>{dictionary.s("mySpace")}</h4>
                </Col>
                <Col xs={12} sm={12} md={6} className="input-search-container searchSpace">
                    <InputSearch
                        placeholder={dictionary.s("searchSpace")}
                        searchValue={searchValue}
                        onChangeText={setSearchValue}
                        handleSearch={handleSearchSpace}
                    />
                </Col>
                <Col
                    xs={12}
                    md={6}
                    className="header-cta-wrapper d-flex justify-content-end"
                >
                    <CreateButton
                        dictionary={dictionary}
                        onClick={onCreateSpace}
                        text='createSpace'
                    />
                </Col>
            </Row>
            <Row className={"space-wrapper " + (spaces.length === 0 && "withoutSpaces")}>
                <Spaces
                    dictionary={dictionary}
                    spaces={spaces}
                    history={history}
                    hasNextPage={hasNextPage}
                    handleShowMoreSpaces={handleShowMoreSpaces}
                    searchValue={searchValue}
                    totalPages={totalPages}
                    workspacesOld={workspacesOld}
                />
            </Row>
        </>
    )
}

const mapStateToProps = state => ({
    loading: state.workspace.loading,
    dictionary: {
        s: key => translate(state.language.dictionary, key)
    },
    spacesListWithPagination: state.workspace.spacesListWithPagination,
    workspacesOld: state.workspace.list, //Change this in next prints
});

const mapDispatchToProps = dispatch => ({
    onCreatingNewWorkspace: creating => dispatch(creatingNewWorkspace(creating)),
    onResetWorkspaceCreated: () => dispatch(resetWorkspaceCreated()),
    onSetUserVerification: userVerification => dispatch(setUserVerification(userVerification)),
    onShowMoreSpaces: () => dispatch(showMoreSpaces()),
    onSearchSpace: (searchedSpace) => dispatch(searchSpace(searchedSpace)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RenderSpaces);
