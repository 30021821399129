import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row } from "react-bootstrap";
import { translate } from "../../redux/reducers/languageReducer";
import MainNavigation from "../Navigation/MainNavigation";
import { 
  getMarketplaceProductList,
  clickOfProductButton } from '../../redux/actions/marketPlaceAction';
import { ReactComponent as Safely_Logo} from "../../icons/Safely_Logo.svg";
import moment from 'moment';

const Marketplace = props => {
  const [deviceType, setdeviceType] = useState(window.deviceType);

  useEffect(() => {
    getMarketplaceProductDetails();
    function handleResize() {
      setdeviceType(window.deviceType);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getMarketplaceProductDetails = () => {
    props.MarketplaceProductList(props.accesstokenidp);
  }

  return (
    <MainNavigation title={props.title}>
      <Container fluid>
        <Row className="marketplace-container-row">
          {props.marketPlaceData && props.marketPlaceData.length > 0 && props.marketPlaceData.map(data => {
            return (
            <div className={deviceType+"Marketplace-data"} key={data._id}>
                <img src={data.productImage} alt="productImage" className="marketPlace-image" />
              <div className="market-product-div row">
                <div className="market-product-left col-8">
                  <div><Safely_Logo /></div>
                  <div>{data.productName}</div>
                </div>
                <div className="market-product-right col-4">
                  {data.price? 
                    <a href={data.link} target="_blank" onClick={() => props.onClickOfProductButton(props.accesstokenidp, props.userId, data._id, "product", moment().toISOString())} className="marketPlace-btn-text">
                      {props.dictionary.s("buyNow")}
                    </a> 
                    : <a href={data.link} target="_blank" onClick={() => props.onClickOfProductButton(props.accesstokenidp, props.userId, data._id, "product", moment().toISOString())} className="marketPlace-btn-text">
                        {props.dictionary.s("seeMore")}
                      </a>}
                </div>
              </div>
            </div>
            )
          })}
          
        </Row>
      </Container>
    </MainNavigation>
  );
};

const mapStateToProps = state => {
  const user = state.user;
  return {
    accesstokenidp: state.user.accesstokenidp,
    userId: user.id,
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    },
    marketPlaceData: state.marketPlace.marketPlaceData
  };
};

const mapDispatchToProps = dispatch => ({
  MarketplaceProductList: (accesstokenidp) => dispatch(getMarketplaceProductList(accesstokenidp)),
  onClickOfProductButton: (accesstokenidp, userId, productId, type, datetime) => dispatch(clickOfProductButton(accesstokenidp, userId, productId, type, datetime)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Marketplace);