import {
    SET_CURRENT_TRANSACTION,
    TRANSACTIONS_START_LOADING,
    TRANSACTIONS_END_LOADING,
    GET_TRANSACTIONS_SUCCESS,
    TRANSACTIONS_FAIL,
    RENAME_TRANSACTIONS_SUCCESS,
    SECONDARY_LOAD_START,
    SECONDARY_LOAD_END,
    PDF_LOCATION,
    ASSIGN_TRANSACTION,
    ASSIGN_TRANSACTION_SUCCESS,
    ASSIGN_TRANSACTION_FAIL,
    DELETE_TRANSACTION,
    DELETE_TRANSACTION_SUCCESS,
    DELETE_TRANSACTION_FAIL,
    EXECUTE_TRANSACTION_SUCCESS,
    GET_TRANSACTION,
    GET_TRANSACTION_SUCCESS,
    GET_TRANSACTION_FAIL,
} from '../actions/types';

export const initialState = {
    loading: false,
    message: null,
    transactionPaging: {
        transactions: [],
        hasNextPage: false,
        totalCount: 0,
        totalPages: 0,
        limit: 10,
        currentPage: 1
    },
    PDFLocation: '',
    newTransactionName: '',
    currentTransaction: null,
};

export default (state, action) => {
    const { type, payload } = action;

    if (typeof state === "undefined") {
        return initialState;
    }

    switch (type) {
        case TRANSACTIONS_START_LOADING:
        case ASSIGN_TRANSACTION:
        case DELETE_TRANSACTION:
        case GET_TRANSACTION:
            return {
                ...state,
                loading: true,
            };
        case TRANSACTIONS_END_LOADING:
            return {
                ...state,
                loading: false,
            };
        case SECONDARY_LOAD_START:
            return {
                ...state,
                secondaryLoading: true,
            };
        case SECONDARY_LOAD_END:
            return {
                ...state,
                secondaryLoading: false,
            };
        case TRANSACTIONS_FAIL:
            return {
                ...state,
                loading: false,
                message: payload.message,
            };
        case GET_TRANSACTIONS_SUCCESS:
            const { data: transactions, hasNextPage, limit, totalCount, totalPages } = payload;
            const newPagination = { transactions, hasNextPage, limit, totalCount, totalPages };
            return {
                ...state,
                loading: false,
                transactionPaging: newPagination
            };
        case RENAME_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                message: payload.message,
            };
        case PDF_LOCATION:
            return {
                ...state,
                PDFLocation: payload.location,
                newTransactionName: payload.name
            };
        case ASSIGN_TRANSACTION_SUCCESS:
        case DELETE_TRANSACTION_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case ASSIGN_TRANSACTION_FAIL:
        case DELETE_TRANSACTION_FAIL:
        case GET_TRANSACTION_FAIL:
            return {
                ...state,
                loading: false,
                message: payload.message,
            }
        case SET_CURRENT_TRANSACTION:
        case GET_TRANSACTION_SUCCESS:
            return {
                ...state,
                loading: false,
                currentTransaction: payload,
            }
        default:
            return state;
    }
};