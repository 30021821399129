import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import createEncryptor from 'redux-persist-transform-encrypt'
import rootReducer from "./reducers";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
const middleware = [thunk];
const encryptor = createEncryptor({
  secretKey: 'safely-app-84_web',
  onError: function (error) {
    // Handle the error.
    console.log(error);
  }
})
const persistConfig = {
  key: "root",
  storage,
  transforms: [encryptor],
  whitelist: [
    "login",
    "user",
    "role",
    "workspace",
    "operation",
    "onboarding",
    "language",
    "transactions",
  ],
  stateReconciler: autoMergeLevel2
};

const initialState = {};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,
  initialState,
  applyMiddleware(...middleware)
);
const persistor = persistStore(store);

export { store, persistor };
