import React from 'react';
import { ReactComponent as CancelIcon } from "../../../../icons/closeIcon.svg";
import TextInput from "./InputText";
import Label from "../../styled-components/general/Label";
import '../../../../styles/inputs/Inputs.css';
import { showToast } from "../../Toast";
import * as toastType from "../../../../constants/toastTypes";
import { general } from "../../../../general/global";
import * as inputTypes from "../../../../constants/inputTypes";
import Select, { components } from "react-select";

export const addDefaultButtons = (_input, _dictionary) => {
    _input.content = { buttonList: [] };
    _input.content.buttonList = [
        { id: general.guid(), value: _dictionary.s("yes"), denyAccess: false, isInvalidWord: false },
        { id: general.guid(), value: _dictionary.s("no"), denyAccess: true, isInvalidWord: false },
    ];
    return _input;
}
export const cleanPropertiesButton = (_inputList) => {
    _inputList.forEach(b => {
        if (b.type === inputTypes.BUTTONGROUP)
            b.content.buttonList.forEach(b => {
                delete b.isInvalidWord
            })
    });
    return _inputList;
}
export const buttonValidation = (_inputList) => {
    const _filterButtonList = _inputList.filter(i => i.type === inputTypes.BUTTONGROUP);
    let message = "";
    let valueList = []
    let bandList = [];
    let sameWords = [];
    _filterButtonList.forEach(buttons => {
        let bfalse = buttons.content.buttonList.find(i => i.denyAccess === false)
        bandList.push(bfalse !== undefined);
        valueList.push(buttons.content.buttonList.find(i => i.value === "") ? false : true);
        sameWords.push(buttons.content.buttonList.find(i => i.isInvalidWord === true) ? true : false)
    });

    if (bandList.find(i => i === false) === false)
        message = "buttonValidation";
    else if (valueList.find(i => i === false) === false)
        message = "emptyDescription";
    else if (sameWords.find(i => i === true))
        message = "sameDescription";
    return message;
}

const InputButtonGroup = ({
    /*  ShowMode    */
    showInput,
    id,
    label,
    value,
    onValueChange,
    required,
    /*  EditMode    */
    inputLabel,
    dictionary,
    buttonList,
    setButtonList,
    onChangeText,

    characterLimit,
    maximumCharacters,
    ...rest
}) => {

    const onAddButton = () => {
        let _buttonList = general.copyObject(buttonList);
        _buttonList.push({ id: general.guid(), value: "", denyAccess: false, isInvalidWord: true });
        setButtonList(id, _buttonList);
    }
    const onDelButton = (_id) => {
        if (buttonList.length > 2) {
            let _buttonList = general.copyObject(buttonList);
            _buttonList = _buttonList.filter(b => b.id !== _id);
            _buttonList = validateButtonValue(_buttonList);
            setButtonList(id, _buttonList);
        }
        else
            showToast(toastType.ERROR, dictionary.s(rest.minimumItemsTextError))
    }
    const validateButtonValue = (_buttonList) => {
        _buttonList.forEach(button => {
            const buttonsWithSameWord = _buttonList.filter(
                b => b.value.toUpperCase() === button.value.toUpperCase()
            );
            if (buttonsWithSameWord.length > 1) {
                buttonsWithSameWord.forEach(i => {
                    _buttonList.find(x => x.id === i.id).isInvalidWord = true;
                });
            }
            else button.isInvalidWord = button.value === "" ? true : false;
        });
        return _buttonList;
    }
    const onSetButton = (_id, _value = null, _denyAccess = null) => {
        let _buttonList = general.copyObject(buttonList);
        let _button = _buttonList.find(b => b.id === _id);
        if (_value !== null) {
            if (characterLimit) {
                if (_value.length <= maximumCharacters) _button.value = _value;
            }
            else _button.value = _value;
            _buttonList = validateButtonValue(_buttonList);
        }
        if (_denyAccess !== null) _button.denyAccess = _denyAccess === 'true' ? false : true;
        setButtonList(id, _buttonList);
    }
    const getOptions = () => {
        return [
            { label: dictionary.s('GrantSafelyPass'), value: true },
            { label: dictionary.s('denySafelyPass'), value: false }
        ];
    }

    if (showInput)
        return (
            <div className="inputButton-container-show">
                <Label>{label}</Label>
                <div className="inputButton-container-buttons">
                    {buttonList.length > 0 && buttonList.map(item => {
                        return (
                            <div
                                key={item.id}
                                className={`inputButton-button ${value === item.value && 'active'}`}
                                onClick={() => onValueChange(id, item.value)}
                            >
                                <Label
                                    fontSize="14px"
                                    color="#333"
                                    className={value === item.value && 'active'}
                                >{item.value}</Label>
                            </div>
                        )
                    })
                    }
                </div>
                <Label className="required">*{dictionary.s("required")}</Label>
            </div>
        )
    else
        return (
            <div className="inputButton-container">
                <TextInput
                    label={dictionary.s('inputLabel')}
                    value={inputLabel}
                    inputClassName="edition-input"
                    onChangeText={onChangeText}
                />
                <Label color={general.theme.fontColorPrimary}>{dictionary.s(rest.optionsTitleText)}</Label>
                <div>
                    {buttonList.length > 0 && buttonList.map((button, index) => {
                        return (
                            <div key={index} className="inputButton-options">
                                <div className="text-container">
                                    <TextInput
                                        label={dictionary.s('inputLabel')}
                                        value={button.value}
                                        inputClassName="edition-input"
                                        onChangeText={text => onSetButton(button.id, text)}
                                    />
                                    {button.isInvalidWord && <Label className="required">{dictionary.s(
                                        button.value.length > 0 ?
                                            rest.itemLabelExistsTextError :
                                            rest.addItemLabelText
                                    )}</Label>
                                    }
                                </div>
                                <div className="option-container">
                                    <Select
                                        styles={customStyles}
                                        value={getOptions().find(x => x.value === !button.denyAccess)}
                                        options={getOptions()}
                                        components={(propsValue) =>
                                            <components.DropdownIndicator {...propsValue}>
                                                <i className="material-icons CaretDown">{'arrow_drop_down'}</i>
                                            </components.DropdownIndicator>
                                        }
                                        onChange={(e) => onSetButton(button.id, null, e.value.toString())}
                                    />
                                    <div className="cancelIcon">
                                        <CancelIcon height='17px' width='17px' onClick={() => onDelButton(button.id)} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
                <div className="inputButton-addButton">
                    <span onClick={onAddButton}>
                        <i className="material-icons plus-symbol">add</i>{dictionary.s(rest.addAnotherItemText)}
                    </span>
                </div>
            </div>
        );
};

const customStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
        ...styles,
        color: '#6E6E7C',
        padding: 5,
        cursor: "pointer",
        backgroundColor: isDisabled
            ? null
            : isSelected
                ? "#F5F5F5"
                : isFocused
                    ? "#F1F5FD"
                    : null,
        ":active": {
            ...styles[":active"],
            backgroundColor: !isDisabled && (isSelected ? "#F5F5F5" : "#455B6326"),
        },
    }),
    control: (styles) => ({
        ...styles,
        cursor: "pointer",
        backgroundColor: 'transparent',
        border: 'none',
        borderColor: 'transparent',
        ':hover': {
            ...styles[":active"],
            borderColor: 'transparent',
            borderBottom: '1px solid #9a9b9e',
        },
        borderBottom: '1px solid #9a9b9e',
        borderRadius: 0,
        boxShadow: 'none !important'
    }),
    container: (styles) => ({
        ...styles,
        width: '100%'
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    singleValue: (base) => ({ ...base, color: "#5A5A5A" }),
}

InputButtonGroup.defaultProps = {
    minimumItemsTextError: 'minimumButtons',
    optionsTitleText: 'ButtonsAndActions',
    addAnotherItemText: 'AddAnotherButton',
    itemLabelExistsTextError: 'Thiswordalreadyexists',
    addItemLabelText: 'emptyDescription',
    characterLimit: true,
    maximumCharacters: 15,
};

export default InputButtonGroup;