import {
  ROLE_GET_LIST,
  ROLE_GET_LIST_SUCESS,
  ROLE_GET_LIST_FAIL
} from "../actions/types";

export const initialState = {
  list: [],
  message: null,
  loading: false
};

export default (state, action) => {
  const { type, payload } = action;
  if (typeof state === "undefined") {
    return initialState;
  }
  switch (type) {
    case ROLE_GET_LIST:
      return { ...state, loading: true };
    case ROLE_GET_LIST_SUCESS:
      return { ...state, loading: false, list: payload.data };
    case ROLE_GET_LIST_FAIL:
      return { ...state, loading: false, message: payload.message };
    default:
      return state;
  }
};
