import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import InputSelect from "../commons/wrapped-components/inputs/InputSelect";
import InputText from "../commons/wrapped-components/inputs/InputText";
import moment from "moment";
import momenttz from "moment-timezone";
import { getDatesByPeriod } from "../../general/general";
import { connect } from "react-redux";
import { translate } from "../../redux/reducers/languageReducer";
import DatePicker from "react-datepicker"; // this datepicker uses date-fns library for date formatting
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import enIN from 'date-fns/locale/en-IN';

const ReportFilters = (props) => {

  let day = new Date().getDate();
  let year = new Date().getFullYear();
  let month = new Date().getMonth();
  let startdate = new Date(year, month, day, 0, 0, 0);
  let enddate = new Date(year, month, day, 23, 59, 59);

  const [startDate, setStartDate] = useState(startdate);
  const [minDate, setMinDate] = useState(startdate);
  const [endDate, setEndDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());
  const [search, setSearch] = useState("");
  const [timeZone, setTimeZone] = useState(momenttz.tz.guess());

  useEffect(() => {
    setStartDate(new Date(year, month, day, 0, 0, 0));
    setEndDate(new Date());
    setMinDate(new Date(year, month, day, 0, 0, 0));
    setMaxDate(new Date());
    setSearch("");
    setPeriod("today");
  }, [props.workSpaceFocused._id]);

  //   moment(new Date()).format("MMM DD YYYY" + ", hh:mm:ss a")
  const [selectedPeriod, setPeriod] = useState("today");

  const handPeriodChange = (period) => {
    setPeriod(period)
    const date = getDatesByPeriod(period);
    setStartDate(date.startDate);
    setEndDate(date.endDate);
    setMinDate(date.startDate);
    setMaxDate(date.endDate);
    setSearch("");
    props.onChange(props.workSpaceFocused._id, period !== "all" ? moment(date.startDate).format("YYYY-MM-DD") : "", period !== "all" ? moment(date.endDate).format("YYYY-MM-DD") : "", props.accesstokenidp, timeZone)
  };

  const handleStartDateChange = (date) => {
    setSearch("");
    props.onChange(props.workSpaceFocused._id, moment(date).format("YYYY-MM-DD"), moment(date.endDate).format("YYYY-MM-DD"), props.accesstokenidp, timeZone);
    setStartDate(date)
  }

  const handleEndDateChange = (date) => {
    let dt = new Date(date)
    let day = dt.getDate();
    let year = dt.getFullYear();
    let month = dt.getMonth();
    let enddate = new Date(year, month, day, 23, 59, 59);

    setSearch("");
    props.onChange(props.workSpaceFocused._id, moment(startDate).format("YYYY-MM-DD"), moment(date).format("YYYY-MM-DD"), props.accesstokenidp, timeZone);
    setEndDate(enddate)
  }
  if (props.onlySearchBox) {
    return (
      <Row className="align-items-center filter-container">
        <Col
          xs={12}
          sm={{ span: 3, offset: 9 }}
          className="input-search-container pl-0"
        >
          <i className="fa fa-search" aria-hidden="true"></i>
          <InputText
            searchable
            onSearch={(searchValue) => { props.handleSearch(searchValue) }}
            inputStyle={{ marginTop: 0 }}
            required={false}
            className="form-control"
            value={search}
            onChangeText={(e) => setSearch(e)}
            placeholder={props.dictionary.s("search")}
          />
        </Col>
      </Row >
    )
  }

  return (
    <Row className="align-items-center filter-container">
      <Col xs={12} sm={6} lg={3} xl={3} md={6} className="pl-0">
        <InputSelect
          displayLabel={false}
          options={props.options}
          searchable={true}
          clearable={true}
          className="period-selector"
          onChange={(e) => handPeriodChange(e.value)}
          value={selectedPeriod}
          label={props.dictionary.s(selectedPeriod)}
        />
      </Col>
      <Col
        xs={12}
        sm={6}
        lg={3}
        xl={3}
        md={6}
        className="date-picker-container pl-0"
      >
        <i className="fa fa-calendar" aria-hidden="true"></i>
        <DatePicker
          dateFormat="EEE, MMM dd, yyyy hh:mm aa"
          className="form-control date-picker"
          onChange={(date) => handleStartDateChange(date)}
          disabled={selectedPeriod !== "custom"}
          minDate={minDate}
          maxDate={maxDate}
          selected={startDate}
          locale={props.lang === "en" ? enIN : es}
        />
      </Col>
      <Col
        xs={12}
        sm={6}
        lg={3}
        xl={3}
        md={6}
        className="date-picker-container pl-0"
      >
        <i className="fa fa-calendar" aria-hidden="true"></i>
        <DatePicker
          dateFormat="EEE, MMM dd, yyyy hh:mm aa"
          className="form-control date-picker"
          onChange={(date) => handleEndDateChange(date)}
          disabled={selectedPeriod !== "custom"}
          minDate={startDate}
          maxDate={maxDate}
          selected={endDate}
          locale={props.lang === "en" ? enIN : es}
        />
      </Col>
      <Col
        xs={12}
        sm={6}
        lg={3}
        xl={3}
        md={6}
        className="input-search-container pl-0"
      >
        <i className="fa fa-search" aria-hidden="true"></i>
        <InputText
          searchable
          onSearch={(searchValue) => { props.handleSearch(searchValue) }}
          inputStyle={{ marginTop: 0 }}
          required={false}
          className="form-control"
          onChangeText={(e) => setSearch(e)}
          value={search}
          placeholder={props.dictionary.s("search")}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  lang: state.language.lang,
  workSpaceFocused: state.workspace.workspaceFocused,
  accesstokenidp: state.user.accesstokenidp,
  dictionary: {
    s: (key) => translate(state.language.dictionary, key),
  }
});
const mapDistchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDistchToProps)(ReportFilters);
