import {
  WORKSPACE_GET_LIST,
  WORKSPACE_ADD_USER,
  WORKSPACE_CREATE_SUCCESS,
  WORKSPACE_CREATE_FAIL,
  WORKSPACE_GET_LIST_FAIL,
  WORKSPACE_GET_LIST_SUCCESS,
  WORKSPACE_SET_FOCUSED,
  CREATING_NEW_WORKPACE,
  WORKSPACE_RESET,
  RESET_WORKSPACE_CREATED,
  SET_WORKSPACE_AT_LOGIN,
  WORKSPACE_ADD_ITEM_TO_LIST,
  WORKSPACE_ADD_SETTINGS,
  WORKSPACE_ADD_SETTINGS_SUCCESS,
  WORKSPACE_ADD_SETTINGS_FAIL,
  WORKSPACE_UPDATE_SETTINGS,
  WORKSPACE_RENAME,
  WORKSPACE_RENAME_SUCCESS,
  WORKSPACE_RENAME_FAIL,
  WORKSPACE_IMPORT_CONTACTS,
  WORKSPACE_IMPORT_CONTACTS_SUCCESS,
  WORKSPACE_IMPORT_CONTACTS_FAIL,
  WORKSPACE_SUB_SET_FOCUSED,
  USER_UPDATE_SUBSPACES_SUCCESS,
  RESET_WORKSPACE_FOCUSED,
  WORKSPACE_SAFELY_PASS_SET_FOCUSED,
  SUBSPACE_UPDATE_MAX_OCCUPANCY,
  SUBSPACE_UPDATE_MAX_OCCUPANCY_FAIL,
  SUBSPACE_GET_CHECK_INTO_SUBSPACE_LIST,
  SUBSPACE_GET_CHECK_INTO_SUBSPACE_LIST_SUCCESS,
  SUBSPACE_CHECK_INTO_SUBSPACE_FAIL,
  SUBSPACE_GET_ACTIVES_SUBSPACE_CHECK_IN_SUCCESS,
  REFRESH_CHECK_INTO_SUBSPACE_SCREEEN,
  SUBSPACE_CHECK_OUT_INTO_SUBSPACE_SUCCESS,
  WORKSPACE_UPDATE_USER,
  SUBSPACE_UPDATE_MAX_OCCUPANCY_SUCCESS,
  SUBSPACE_CHECK_INTO_SUBSPACE_SUCCESS,
  SUBSPACE_GET_CHECK_INTO_SUBSPACE_LIST_FAIL,
  SUBSPACE_REST_CHECK_INTO_SUBSPACE_LIST,
  SUBSPACE_CHECK_OUT_INTO_SUBSPACE,
  SUBSPACE_CHECK_INTO_SUBSPACE,
  SUBSPACE_CHECK_OUT_INTO_SUBSPACE_FAIL,
  SUBSPACE_CHECK_INTO_SUBSPACE_PUSH_NOTIFICATION,
  SUBSPACE_CHECK_OUT_PUSH_NOTIFICATION,
  WORKSPACE_ADD_USER_FAIL,
  GET_SPACES_AND_SUBSPACES_BY_USERID_AND_WORKSPACEID,
  GET_SPACES_AND_SUBSPACES_BY_USERID_AND_WORKSPACEID_SUCCESS,
  GET_SPACES_AND_SUBSPACES_BY_USERID_AND_WORKSPACEID_FAIL,
  SPACE_GET_LIST_OF_SPACES_WITH_PAGINATION,
  SPACE_GET_LIST_OF_SPACES_WITH_PAGINATION_SUCCESS,
  SPACE_GET_LIST_OF_SPACES_WITH_PAGINATION_FAIL,
  SPACE_GET_SUBSPACES_BY_USER_ID_WORKSPACE_ID,
  SPACE_GET_SUBSPACES_BY_USER_ID_WORKSPACE_ID_FAIL,
  SPACE_GET_SUBSPACES_BY_USER_ID_WORKSPACE_ID_SUCCESS,
  SPACE_GET_USER_LIST_BY_SUBSPACE_ID,
  SPACE_GET_USER_LIST_BY_SUBSPACE_ID_FAIL,
  SPACE_GET_USER_LIST_BY_SUBSPACE_ID_SUCCESS,
  SPACE_INCREMENT_PAGE_NUMBER,
  SPACE_GET_PAGINATED_USERS_SUCCESS,
  SPACE_GET_PAGINATED_USERS_FAILURE,
  SPACE_GET_PAGINATED_USERS_REQUEST,
  SPACE_SHOW_MORE_SPACES,
  SPACE_SHOW_MORE_SPACES_SUCCESS,
  SPACE_SHOW_MORE_SPACES_FAIL,
  SPACE_SEARCH_SPACE,
  SPACE_SEARCH_SPACE_FAIL,
  SPACE_SEARCH_SPACE_SUCCESS,
  SPACE_RESET_SEARCH_SPACE_DATA,
  SPACE_RESET_PAGE_NUMBER,
  SPACE_SET_SPACE_FOCUSED,
  SPACE_SET_SUBSPACE_FOCUSED,
  SPACE_GET_USERS_BY_WORKSPACE_ID,
  SPACE_GET_USERS_BY_WORKSPACE_ID_FAIL,
  WORKSPACE_UPDATE_USER_SETTINGS,
  SPACE_GET_USERS_BY_WORKSPACE_ID_SUCCESS, SUBSPACE_RENAME_SUBSPACE, SUBSPACE_SHOW_MORE_SUBSPACES,
  SUBSPACE_SHOW_MORE_SUBSPACES_SUCCESS,
  SUBSPACE_SHOW_MORE_SUBSPACES_FAIL, SUBSPACE_SHOW_MORE_SUBSPACE_USERS_SUCCESS, SUBSPACE_SHOW_MORE_SUBSPACE_USERS, SUBSPACE_SHOW_MORE_SUBSPACE_USERS_FAIL,
  SPACE_DELETE_SPACE_IMAGE,
  SUBSPACE_RESET_SUBSPACE_USER_LIST,
  SUBSPACE_RESET_SUBSPACE_LIST,
  SET_LOADING,
  SET_WORKSPACE_DISABLED,
  WORKSPACE_REFRESH_USER_MANAGEMENT_SCREEN,
} from "../actions/types";

export const initialState = {
  workspaceFocused: { /**Deprecated**  Instead use 'spaceFocused'*/
    _id: null,
    users: [],
    paginatedUsers: [],
    totalUsers: 0,
    name: "",
    userVerificationChecklist: [
      {
        inputs: []
      }
    ],
    checkPointChecklist: [
      {
        inputs: []
      }
    ],
    settings: {
      locationFrequency: 30 //seg
    }
  },
  workspaceCreated: null,
  userAddedToWorkspace: null,
  list: [], /**Deprecated** Avoid use this state, instead use 'spacesListWithPagination.spaces' only if it's required*/
  loading: false,
  message: false,
  error: "",
  creatingNewWorkspace: false,
  updatingWorkspace: false,
  workspaceSubFocused: { /**Deprecated**  Instead use 'subspaceFocused'*/
    _id: null,
    users: [],
    usersCount: null,
    name: ""
  },
  workspaceFocusedForSafelyPass: {
    _id: null,
    users: [],
    name: "",
    userVerificationChecklist: [
      {
        inputs: []
      }
    ],
    checkPointChecklist: [
      {
        inputs: []
      }
    ],
    settings: {
      locationFrequency: 30 //seg
    }
  },
  checkIntoSubspaceList: [],
  activesSubspaceCheckIn: [],
  refreshCheckIntoSubspaceScreen: false,
  refreshUserManagementScreen: false,
  latestUserIdCheckinSubspace: null,
  latestUserIdCheckOutSubspace: null,
  spacesListWithPagination: {
    spaces: [], //Store workspaces or spaces
    hasNextPage: false,
    limit: 12,
    totalCount: 0,
    totalPages: 0,
  },
  pageNumber: 1,
  pageLimit: 12,
  spaceFocused: {
    checkPointId: "",
    createdDate: "",
    defaultSubspaceId: "",
    name: "",
    picture: "",
    roleId: "",
    roleName: "",
    safelyPassStatus: "",
    settings: {
      locationFrequency: 15,
      locationIsRequired: true,
      selfDeclaration: true,
      verificationPoint: true,
      backgroundColor: "#42CE37"
    },
    spaceOwner: "",
    userId: "",
    userVerificationId: "",
    _id: null
  },
  subspacesListWithPagination: {
    subspaces: [], //Store subspaces from a workspace or space
    hasNextPage: false,
    totalCount: 0,
    totalPages: 0,
    limit: 12,
    currentPage: 1
  },
  subspaceFocused: { //Selected subspace
    maxOccupancy: 50,
    name: "",
    subspaceOwner: "",
    usersCount: 0,
    _id: "",
  },
  subspaceUsers: {
    users: [], //Store users belongs to a subspace
    hasNextPage: false,
    totalCount: 0,
    totalPages: 0,
    limit: 12,
    currentPage: 1
  },
  workspaceUsers: [], //Store users belongs to a workspace or space
  workspaceDisabled: {
    showModal: false,
    redirect: "",
    data: null
  }
};

export default (state, action) => {
  const { type, payload } = action;
  if (typeof state === "undefined") {
    return initialState;
  }
  switch (type) {
    case WORKSPACE_GET_LIST:
    case WORKSPACE_ADD_USER:
    case WORKSPACE_RENAME:
    case SUBSPACE_UPDATE_MAX_OCCUPANCY:
    case SUBSPACE_GET_CHECK_INTO_SUBSPACE_LIST:
    case SUBSPACE_CHECK_INTO_SUBSPACE:
    case SUBSPACE_CHECK_OUT_INTO_SUBSPACE:
    case SPACE_GET_LIST_OF_SPACES_WITH_PAGINATION:
    case SPACE_GET_SUBSPACES_BY_USER_ID_WORKSPACE_ID:
    case SPACE_GET_USER_LIST_BY_SUBSPACE_ID:
    case SPACE_SHOW_MORE_SPACES:
    case SPACE_SEARCH_SPACE:
    case SPACE_GET_USERS_BY_WORKSPACE_ID:
    case SUBSPACE_SHOW_MORE_SUBSPACES:
    case SUBSPACE_SHOW_MORE_SUBSPACE_USERS:
    case GET_SPACES_AND_SUBSPACES_BY_USERID_AND_WORKSPACEID:
      return { ...state, loading: true };
    case SPACE_GET_PAGINATED_USERS_REQUEST:
      return { ...state, loading: true, paginatedUsers: [], totalUsers: 0 };
    case WORKSPACE_ADD_USER_FAIL:
      return { ...state, loading: false };
    case WORKSPACE_CREATE_SUCCESS:
      const newWorkspace = payload.data;
      return {
        ...state,
        loading: false,
        message: null,
        workspaceCreated: newWorkspace,
        list: [newWorkspace, ...state.list]
      };
    case WORKSPACE_CREATE_FAIL:
    case WORKSPACE_GET_LIST_FAIL:
    case WORKSPACE_ADD_SETTINGS_FAIL:
    case WORKSPACE_RENAME_FAIL:
    case SUBSPACE_UPDATE_MAX_OCCUPANCY_FAIL:
    case SUBSPACE_CHECK_INTO_SUBSPACE_FAIL:
    case SUBSPACE_GET_CHECK_INTO_SUBSPACE_LIST_FAIL:
    case SUBSPACE_CHECK_OUT_INTO_SUBSPACE_FAIL:
    case SPACE_GET_LIST_OF_SPACES_WITH_PAGINATION_FAIL:
    case SPACE_GET_SUBSPACES_BY_USER_ID_WORKSPACE_ID_FAIL:
    case SPACE_GET_USER_LIST_BY_SUBSPACE_ID_FAIL:
    case SPACE_SHOW_MORE_SPACES_FAIL:
    case SPACE_SEARCH_SPACE_FAIL:
    case SPACE_GET_USERS_BY_WORKSPACE_ID_FAIL:
    case SUBSPACE_SHOW_MORE_SUBSPACES_FAIL:
    case SUBSPACE_SHOW_MORE_SUBSPACE_USERS_FAIL:
    case SPACE_GET_PAGINATED_USERS_FAILURE:
    case GET_SPACES_AND_SUBSPACES_BY_USERID_AND_WORKSPACEID_FAIL:
      return {
        ...state,
        loading: false,
        message: payload.message
      };
    case WORKSPACE_GET_LIST_SUCCESS:
      const list = payload.data;
      let workspaceFocused = initialState.workspaceFocused;
      let workspaceSubFocused = initialState.workspaceSubFocused;
      let workspaceFocusedForSafelyPass = initialState.workspaceFocusedForSafelyPass;
      if (list.length > 0) {
        workspaceFocused = list[0] || state.workspaceFocused;
        const sortedList = sortFunction([...list]);
        workspaceFocusedForSafelyPass =
          state.workspaceFocusedForSafelyPass._id === null
            ? sortedList[0]
            : state.workspaceFocusedForSafelyPass;

        workspaceSubFocused = state.workspaceSubFocused;
        if (state.workspaceFocused._id) {
          workspaceFocused =
            list.find(x => x._id === state.workspaceFocused._id) || list[0];
        }
        if (state.workspaceSubFocused._id) {
          workspaceSubFocused =
            workspaceFocused.subspaces.find(
              x => x._id === state.workspaceSubFocused._id
            ) || workspaceFocused.subspaces[0];
        }
      }
      return {
        ...state,
        message: null,
        loading: false,
        list,
        workspaceFocused,
        workspaceSubFocused,
        workspaceFocusedForSafelyPass
      };
    case WORKSPACE_SAFELY_PASS_SET_FOCUSED:
      return {
        ...state,
        loading: false,
        workspaceFocusedForSafelyPass: payload
      };
    case WORKSPACE_SUB_SET_FOCUSED:
      return { ...state, workspaceSubFocused: payload };
    case WORKSPACE_SET_FOCUSED:
      return {
        ...state,
        workspaceFocused: payload,
        workspaceSubFocused: payload && payload.subspaces ? payload.subspaces[0] : {}
      };
    case CREATING_NEW_WORKPACE:
      return { ...state, creatingNewWorkspace: payload };
    case WORKSPACE_RESET:
      return { ...state, ...initialState };
    case RESET_WORKSPACE_CREATED:
      return { ...state, workspaceCreated: null };
    case WORKSPACE_ADD_ITEM_TO_LIST:
      const findItem = state.list.find(x => x._id === payload._id);
      const newList = findItem ? state.list : [payload, ...state.list];
      return { ...state, list: newList };
    case WORKSPACE_ADD_SETTINGS:
      return { ...state, loading: true };
    case WORKSPACE_ADD_SETTINGS_SUCCESS:
      const newWorkspaceSettings = payload.data;
      return {
        ...state,
        loading: false,
        workspaceCreated: newWorkspaceSettings
      };
    case WORKSPACE_RENAME_SUCCESS:
      const newLists = state.list.map(x =>
        x._id === payload._id ? { ...x, name: payload.name } : x
      );
      return { ...state, loading: false, list: newLists };
    case WORKSPACE_IMPORT_CONTACTS:
      return { ...state, loading: true };
    case WORKSPACE_IMPORT_CONTACTS_SUCCESS:
    case GET_SPACES_AND_SUBSPACES_BY_USERID_AND_WORKSPACEID_SUCCESS:
      return { ...state, loading: false };
    case WORKSPACE_IMPORT_CONTACTS_FAIL:
      return { ...state, loading: false, message: payload.message };
    case WORKSPACE_UPDATE_SETTINGS:
      return { ...state, updatingWorkspace: true, workspaceCreated: payload };
    case USER_UPDATE_SUBSPACES_SUCCESS:
      return { ...state, workspaceFocused: payload };
    case SET_WORKSPACE_AT_LOGIN:
      return {
        ...state,
        workspaceFocused: payload.focused,
        list: payload.list
      };
    case RESET_WORKSPACE_FOCUSED:
      return { ...state, workspaceFocused: initialState.workspaceFocused };
    case SUBSPACE_GET_CHECK_INTO_SUBSPACE_LIST_SUCCESS:
      return { ...state, loading: false, checkIntoSubspaceList: payload, latestUserIdCheckinSubspace: null, latestUserIdCheckOutSubspace: null }
    case SUBSPACE_GET_ACTIVES_SUBSPACE_CHECK_IN_SUCCESS:
      return { ...state, activesSubspaceCheckIn: payload, loading: false }
    case SUBSPACE_CHECK_INTO_SUBSPACE_SUCCESS: {
      const { _id, active, grantDate, subspaceId } = payload;

      const found = state.checkIntoSubspaceList.find(item => item.subspaceId === subspaceId);

      let listUpdated = state.checkIntoSubspaceList;
      if (found !== undefined) {
        listUpdated = state.checkIntoSubspaceList.map(item => {
          if (item.subspaceId === subspaceId && !item.checkInStatus) {
            return {
              ...item,
              accessAuhtorizationId: _id,
              alreadyOccupied: item.alreadyOccupied + 1,
              checkInDateTime: grantDate,
              checkInStatus: active,
            }
          }
          return item;
        });
      }
      return { ...state, checkIntoSubspaceList: listUpdated, loading: false, latestUserIdCheckinSubspace: null, latestUserIdCheckOutSubspace: null }
    }
    case SUBSPACE_CHECK_OUT_INTO_SUBSPACE_SUCCESS: {
      const { active, subspaceId } = payload;
      const found = state.checkIntoSubspaceList.find(item => item.subspaceId === subspaceId);
      let listUpdated = found !== undefined
        ? state.checkIntoSubspaceList.map(item => {
          if (item.subspaceId === subspaceId && item.checkInStatus) {
            return {
              ...item,
              accessAuhtorizationId: null,
              alreadyOccupied: item.alreadyOccupied > 0 ? item.alreadyOccupied - 1 : 0,
              checkInDateTime: null,
              checkInStatus: active,
            }
          }
          return item;
        }) : state.checkIntoSubspaceList;
      return { ...state, activesSubspaceCheckIn: [], checkIntoSubspaceList: listUpdated, loading: false, latestUserIdCheckinSubspace: null, latestUserIdCheckOutSubspace: null }
    }
    case REFRESH_CHECK_INTO_SUBSPACE_SCREEEN:
      return { ...state, refreshCheckIntoSubspaceScreen: payload.refresh }
    case SUBSPACE_REST_CHECK_INTO_SUBSPACE_LIST:
      return { ...state, checkIntoSubspaceList: [] }
    case SUBSPACE_CHECK_INTO_SUBSPACE_PUSH_NOTIFICATION: {
      const { _id, active, grantDate, subspaceId, userId, workSpaceId, userIdSelected } = payload;

      let activesSubspaceCheckIn = [...state.activesSubspaceCheckIn];
      let findIfthisUserCheckedIn = {};
      let latestUserIdCheckinSubspace = state.latestUserIdCheckinSubspace;

      let checkIntoSubspaceList = [...state.checkIntoSubspaceList];

      let updatedList = checkIntoSubspaceList;
      findIfthisUserCheckedIn = activesSubspaceCheckIn.find(item => item.userId === userId);
      if (activesSubspaceCheckIn !== undefined && activesSubspaceCheckIn.length === 0 && userIdSelected === userId) {
        updatedList = checkIntoSubspaceList.map(item => {
          if (item.subspaceId === subspaceId && !item.checkInStatus) {
            //Activate in this subspace
            const newItem = {
              ...item,
              accessAuhtorizationId: _id,
              alreadyOccupied: item.alreadyOccupied + 1,
              checkInDateTime: grantDate,
              checkInStatus: true,
            }
            //Update activesSubspaceCheckIn state:
            activesSubspaceCheckIn = [{
              active: true,
              subspaceId: item.subspaceId,
              subspaceName: item.subspaceName,
              grantDate: grantDate,
              userId: userId,
              workSpaceId: workSpaceId,
              _id: _id
            }]
            return newItem;
          }
          return item;
        });
      } else if (userId === activesSubspaceCheckIn[0]?.userId && subspaceId !== activesSubspaceCheckIn[0]?.subspaceId) {
        if (findIfthisUserCheckedIn !== undefined) {
          //checkout user from current subspace && anctivate to other subspace
          const { subspaceId: subspaceIdActive, subspaceName: subspaceNameActive } = activesSubspaceCheckIn[0];
          updatedList = checkIntoSubspaceList.map(item => {
            if (item.subspaceId === subspaceIdActive || item.subspaceName === subspaceNameActive) {
              //Deactivate currect subspace
              return {
                ...item,
                accessAuhtorizationId: null,
                alreadyOccupied: item.alreadyOccupied > 0 ? item.alreadyOccupied - 1 : 0,
                checkInDateTime: null,
                checkInStatus: false,
              }
            } else if (item.subspaceId === subspaceId && !item.checkInStatus) {
              //Activate in this subspace
              const newItem = {
                ...item,
                accessAuhtorizationId: _id,
                alreadyOccupied: item.alreadyOccupied + 1,
                checkInDateTime: grantDate,
                checkInStatus: true,
              }
              //Update activesSubspaceCheckIn state:
              activesSubspaceCheckIn = [{
                active: true,
                subspaceId: item.subspaceId,
                subspaceName: item.subspaceName,
                grantDate: grantDate,
                userId: userId,
                workSpaceId: workSpaceId,
                _id: _id
              }]
              return newItem;
            }
            return item;
          })
        }
      } else if (userId !== activesSubspaceCheckIn[0]?.userId && latestUserIdCheckinSubspace !== userId) {
        //Increment already occupancy in other subspace
        updatedList = checkIntoSubspaceList.map(item => {
          if (item.subspaceId === subspaceId) {
            //Save the user checked out
            latestUserIdCheckinSubspace = userId;
            return {
              ...item,
              alreadyOccupied: item.alreadyOccupied + 1,
            }
          }
          return item;
        });
      } else {
        latestUserIdCheckinSubspace = null;
      }
      return { ...state, checkIntoSubspaceList: updatedList, activesSubspaceCheckIn, latestUserIdCheckinSubspace, latestUserIdCheckOutSubspace: null }
    }

    case SUBSPACE_CHECK_OUT_PUSH_NOTIFICATION: {
      const { _id, userId, subspaceId, active, userIdSelected } = payload;
      let activesSubspaceCheckIn = [...state.activesSubspaceCheckIn];
      let findIfthisUserHasCheckInSubspace = {};
      let latestUserIdCheckOutSubspace = state.latestUserIdCheckOutSubspace;
      const checkIntoSubspaceList = [...state.checkIntoSubspaceList];
      let updatedList = checkIntoSubspaceList;
      //Check If this user has checkin in a subspace
      if (userId === userIdSelected) {
        if (activesSubspaceCheckIn !== undefined && activesSubspaceCheckIn.length) {
          findIfthisUserHasCheckInSubspace = activesSubspaceCheckIn.find(item => item.userId === userId && item.subspaceId === subspaceId);

          if (findIfthisUserHasCheckInSubspace !== undefined) {
            updatedList = checkIntoSubspaceList.map(item => {
              //Check Out user fron this subspace
              if (item.subspaceId === subspaceId && item.checkInStatus) {
                //Clean active subspace list
                activesSubspaceCheckIn = [];
                return {
                  ...item,
                  accessAuhtorizationId: null,
                  alreadyOccupied: item.alreadyOccupied > 0 ? item.alreadyOccupied - 1 : 0,
                  checkInDateTime: null,
                  checkInStatus: false,
                }
              }
              return item;
            })
          } else {
            updatedList = checkIntoSubspaceList.map(item => {
              //lower alreadyOccupied value from a subspace when any other user check outs.
              if (item.subspaceId === subspaceId) {
                return {
                  ...item,
                  alreadyOccupied: item.alreadyOccupied > 0 ? item.alreadyOccupied - 1 : 0,
                }
              }
              return item;
            })
          }
        }
      } else {
        if (latestUserIdCheckOutSubspace !== userId) {
          updatedList = checkIntoSubspaceList.map(item => {
            if (item.subspaceId === subspaceId) {
              latestUserIdCheckOutSubspace = userId;
              //lower alreadyOccupied value from a subspace when any other user check outs.
              return {
                ...item,
                alreadyOccupied: item.alreadyOccupied > 0 ? item.alreadyOccupied - 1 : 0,
              }
            }
            return item;
          });
        }
      }

      return { ...state, checkIntoSubspaceList: updatedList, activesSubspaceCheckIn, latestUserIdCheckinSubspace: null, latestUserIdCheckOutSubspace }
    }
    case WORKSPACE_UPDATE_USER:
      const _workspaceSubFocused = payload.workspaceSubFocused ? { workspaceSubFocused: payload.workspaceSubFocused } : {};
      return {
        ...state,
        workspaceCreated: payload.workspaceCreated,
        workspaceFocused: payload.workspaceFocused,
        ..._workspaceSubFocused,
        list: payload.list
      }
    case SUBSPACE_UPDATE_MAX_OCCUPANCY_SUCCESS: {
      const { _id: workSpaceId, subspaces } = payload.data;
      const { subspaceId } = payload;
      let workspaceFocusedModified = { ...state.workspaceFocused };
      if (workSpaceId !== undefined && subspaces !== undefined) {
        if (workspaceFocusedModified._id === workSpaceId) {
          workspaceFocusedModified.subspaces = subspaces;
        }
      }

      let updatedList = state.list;
      updatedList = workSpaceId !== undefined ? state.list.map(item => {
        if (item._id === workSpaceId) {
          return workspaceFocusedModified;
        }
        return item;
      }) : updatedList;
      let workspaceSubFocusedUpdated = state.workspaceSubFocused;
      workspaceSubFocusedUpdated = subspaceId !== undefined ?
        workspaceFocusedModified.subspaces.find(item => item._id === subspaceId)
        : workspaceSubFocusedUpdated;

      let subspaceFocused = { ...state.subspaceFocused }; //===*New to update subspaceFocusedw
      const updatedSubspaces = state.subspacesListWithPagination.subspaces.map(subspace => {
        let updateSubspace = {};
        if (subspace._id === subspaceId) {
          updateSubspace = {
            ...subspace,
            maxOccupancy: payload.maxOccupancy
          }
          subspaceFocused = updateSubspace;
          return updateSubspace;
        }
        return subspace;
      });

      const newList = {
        ...state.subspacesListWithPagination,
        subspaces: updatedSubspaces
      }

      return { ...state, list: updatedList, workspaceFocused: workspaceFocusedModified, workspaceSubFocused: workspaceSubFocusedUpdated, subspacesListWithPagination: newList, subspaceFocused, loading: false }
    }
    case SPACE_GET_LIST_OF_SPACES_WITH_PAGINATION_SUCCESS: {
      const { data: spaces, hasNextPage, limit, totalCount, totalPages } = payload;
      const newList = {
        spaces,
        hasNextPage,
        limit,
        totalCount,
        totalPages
      }

      let spaceFocused = initialState.spaceFocused;
      if (spaces.length) {
        spaceFocused = (state.spaceFocused._id === null || state.spaceFocused._id === '') ? spaces[0]
          : (spaces.find(x => x._id === state.spaceFocused._id));
        if (spaceFocused === undefined) spaceFocused = state.spaceFocused;
      }
      return { ...state, spacesListWithPagination: newList, loading: false, spaceFocused }
    }
    case SPACE_SHOW_MORE_SPACES_SUCCESS: {
      const { data: spaces, hasNextPage, limit, totalCount, totalPages } = payload;
      let newList = {
        spaces: [...state.spacesListWithPagination.spaces, ...spaces],
        hasNextPage,
        limit,
        totalCount,
        totalPages
      }
      return { ...state, spacesListWithPagination: newList, loading: false }
    }
    case SPACE_SEARCH_SPACE_SUCCESS: {
      const { data: spaces, hasNextPage, limit, totalCount, totalPages } = payload;
      const newList = {
        spaces,
        hasNextPage,
        limit,
        totalCount,
        totalPages
      }
      return { ...state, spacesListWithPagination: newList, loading: false };
    }
    case SPACE_INCREMENT_PAGE_NUMBER: {
      const pageNumber = state.pageNumber + 1;
      return { ...state, pageNumber }
    }
    case SPACE_RESET_PAGE_NUMBER:
      return { ...state, pageNumber: initialState.pageNumber }
    case SPACE_GET_SUBSPACES_BY_USER_ID_WORKSPACE_ID_SUCCESS: {

      const { data: subspaces, hasNextPage, totalCount, totalPages, limit, currentPage } = payload;

      const newList = {
        subspaces,
        hasNextPage,
        totalCount,
        totalPages,
        limit,
        currentPage
      }

      let subspaceFocused = { ...state.subspaceFocused };

      if (subspaces.length && subspaceFocused._id === "") {
        subspaceFocused = subspaces[0];
      }

      return { ...state, loading: false, subspacesListWithPagination: newList, subspaceFocused }
    }
    case SPACE_SET_SPACE_FOCUSED:
      return { ...state, spaceFocused: payload }
    case SPACE_SET_SUBSPACE_FOCUSED:
      return { ...state, subspaceFocused: payload }
    case SPACE_GET_USER_LIST_BY_SUBSPACE_ID_SUCCESS: {

      const { data: users, hasNextPage, totalCount, totalPages, limit, currentPage } = payload;

      const newList = {
        users,
        hasNextPage,
        totalCount,
        totalPages,
        limit,
        currentPage
      }

      return { ...state, subspaceUsers: newList, loading: false }
    }
    case SPACE_GET_USERS_BY_WORKSPACE_ID_SUCCESS:
      return { ...state, workspaceUsers: payload, loading: false }
    case SUBSPACE_RENAME_SUBSPACE: {
      let subspaceFocused = { ...state.subspaceFocused };
      const updatedSubspaces = state.subspacesListWithPagination.subspaces.map(subspace => {
        let updateSubspace = {};
        if (subspace._id === payload.subspaceId) {
          updateSubspace = {
            ...subspace,
            name: payload.subspaceName
          }
          subspaceFocused = updateSubspace;
          return updateSubspace;
        }
        return subspace;
      });

      const newList = {
        ...state.subspacesListWithPagination,
        subspaces: updatedSubspaces
      }

      return { ...state, subspacesListWithPagination: newList, subspaceFocused }
    }
    case SUBSPACE_SHOW_MORE_SUBSPACES_SUCCESS: {
      const { data: subspaces, hasNextPage, totalCount, totalPages, limit, currentPage } = payload;

      const newList = {
        subspaces: [...state.subspacesListWithPagination.subspaces, ...subspaces],
        hasNextPage,
        totalCount,
        totalPages,
        limit,
        currentPage
      }
      return { ...state, subspacesListWithPagination: newList, loading: false }
    }
    case SUBSPACE_SHOW_MORE_SUBSPACE_USERS_SUCCESS: {
      const { data: users, hasNextPage, totalCount, totalPages, limit, currentPage } = payload;

      const newList = {
        users: [...state.subspaceUsers.users, ...users],
        hasNextPage,
        totalCount,
        totalPages,
        limit,
        currentPage
      }

      return { ...state, subspaceUsers: newList, loading: false }
    }
    case SPACE_GET_PAGINATED_USERS_SUCCESS:
      return { ...state, loading: false, paginatedUsers: payload.paginatedUsers, totalUsers: payload.totalCount };
    case WORKSPACE_UPDATE_USER_SETTINGS:
      return { ...state, spaceFocused: payload.spaceFocused, workspaceFocusedForSafelyPass: payload.workspaceFocusedForSafelyPass, workspaceFocused: payload.workspaceFocused, spacesListWithPagination: payload.spacesListWithPagination, list: payload.list };
    case SPACE_DELETE_SPACE_IMAGE: {
      let spaceFocused = { ...state.spaceFocused };
      delete spaceFocused.picture;
      return { ...state, spaceFocused }
    }
    case SUBSPACE_RESET_SUBSPACE_USER_LIST:
      return { ...state, subspaceUsers: initialState.subspaceUsers };
    case SUBSPACE_RESET_SUBSPACE_LIST:
      return { ...state, subspacesListWithPagination: initialState.subspacesListWithPagination }
    case SET_LOADING:
      return { ...state, loading: payload };
    case SET_WORKSPACE_DISABLED:
      const { showModal, data, redirect } = payload;
      let _workspaceDisabled = {
        showModal: showModal || false,
        redirect: redirect || "",
        data: data || null
      }
      return { ...state, workspaceDisabled: _workspaceDisabled };

    case WORKSPACE_REFRESH_USER_MANAGEMENT_SCREEN:
      return { ...state, refreshUserManagementScreen: payload.refresh };
    default:
      return state;
  }

};
function sortFunction(cloneList) {
  return cloneList.sort((a, b) => a.name.localeCompare(b.name));
}