import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { translate } from "../../../redux/reducers/languageReducer";
// import { dictionary } from "../../../general/global";

class SWSModal extends Component {
  // constructor(props) {
  //     super(props);
  // }

  render() {
    return (
      <Modal
        className={this.props.className}
        backdrop='static'
        dialogClassName={this.props.dialogClassName}
        show={this.props.showModal}
        size={this.props.size}
        onHide={() => {
          if (this.props.onHide) this.props.onHide();
          else if (this.props.onClose) this.props.onClose();
        }}
        centered={this.props.center}
        {...this.props.properties}
      >
        {this.props.title ? (
          <Modal.Header closeButton style={this.props.headerStyle}>
            <Modal.Title style={this.props.titleStyle}>
              {this.props.title}
            </Modal.Title>
          </Modal.Header>
        ) : null}
        <Modal.Body style={this.props.bodyStyle || {}}>
          {this.props.body}
        </Modal.Body>
        {!this.props.footer &&
          !this.props.onClose &&
          !this.props.onSave ? null : (
            <Modal.Footer
              style={this.props.footerStyle}
              className={(this.props.footer ? "camera-modal-footer" : "") + this.props.bsStyleFooter}
            >
              {this.props.onClose ? (
                <Button
                  variant={this.props.bsStyleButtonClose}
                  className="border-radius-none"
                  onClick={() => {
                    this.props.onClose();
                  }}
                  {...this.props.buttonCloseProperties}
                >
                  {this.props.textButtonClose || this.props.dictionary.s("cancel")}
                </Button>
              ) : null}
              {this.props.footer ? (
                this.props.footer
              ) : (
                  <Button
                    className="border-radius-none"
                    variant={this.props.bsStyleButtonSave}
                    disabled={(
                      this.props.isLoadingButton ?
                        true :
                        this.props.disabledButtonSave
                    )}
                    onClick={() => {
                      if (!this.props.isLoadingButton) this.props.onSave();
                    }}
                    {...this.props.propertyButtonSave}
                  >
                    {this.props.isLoadingButton ? (
                      <span>
                        {this.props.dictionary.s("loading")}...{" "}
                        <i
                          className="fa fa-spinner fa-spin"
                          style={{ animation: "fa-spin 1s infinite linear" }}
                          aria-hidden="true"
                        ></i>
                      </span>
                    ) : (
                        this.props.textButtonSave
                      )}
                  </Button>
                )}
            </Modal.Footer>
          )}
      </Modal>
    );
  }
}



SWSModal.defaultProps = {
  showModal: false,
  center: false,
  isLoadingButton: false,
  className: "",
  headerStyle: {},
  title: "",
  titleStyle: {},
  bodyStyle: {},
  body: null,
  footerStyle: {},
  size: "",
  onClose: null,
  onSave: null,
  textButtonSave: "",
  bsStyleButtonSave: "info",
  dialogClassName: "",
  properties: {},
  onHide: null,
  footer: null,
  buttonCloseProperties: {

  },
  bsStyleFooter: "",
  bsStyleButtonClose: "secondary",
  disabledButtonSave: false
};
const mapStateToProps = (state) => {
  return {
    dictionary: {
      s: key => translate(state.language.dictionary, key)
      }
  }
}

export default connect(mapStateToProps)(SWSModal);