import React, { useEffect } from "react";
import { BrowserQRCodeSvgWriter } from "@zxing/library";

const QRCode = ({ value, size, styles, border }) => {
  useEffect(() => {
    document.getElementById("result").innerHTML = "";
    const codeWriter = new BrowserQRCodeSvgWriter();
    codeWriter.writeToDom("#result", value, size, size);
  }, [size, value]);

  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
      <div
        id="result"
        className="qrCodeSvg"
        style={{ display: "flex", justifyContent: "center", width: size, border: border, boxShadow: border && "0px 3px 12px #788076bf", ...styles }}
      ></div>
    </div>
  );
};

QRCode.defaultProps = {
  size: 200,
};

export default QRCode;
