import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import MainNavigation from "../Navigation/MainNavigation";
import Modal from "../commons/wrapped-components/Modal";
import { translate } from "../../redux/reducers/languageReducer"
import * as roles from "../../constants/roles";
import { general } from "../../general/global";
import AddMemberPopUP from './AddMemberPopUP';
import Label from "../commons/styled-components/general/Label";
import { ReactComponent as Usericon } from '../../icons/user-icon.svg';
import MyQRCode from "./MyQRCode";
import ToggleMenu from "./ToggleMenu";
import Rename from "./Rename";
import {
  setWorkspaceSubFocused,
  createSubWorkspace,
  addMemberSubWorkspace,
  renameWorkspaceSub,
  updateMaxOccupancy,
  updateCreateSubSpaceMember,
  getSubspacesByUserIdWorkSpaceId,
  setSubspaceFocused,
  renameSubspace,
  showMoreSubspaces,
  resetSubspaceList
} from "../../redux/actions/workspaceActions";
import ReactTooltip from "react-tooltip";
import DeleteSubSpace from './DeleteSubSpace';
import InputText from "../commons/wrapped-components/inputs/InputText";
import InputNumber from "../commons/wrapped-components/inputs/inputNumber";
import MaximumOccupancy from "./MaximumOccupancy";
import Avatar from "../commons/wrapped-components/Avatar";
import InputSearch from '../commons/wrapped-components/inputs/InputSearch';
import { ShowMoreButton } from "./ShowMoreButton";
import { CreateButton } from "./CreateButton";

const SubWorkspaceList = props => {
  if (props.subspacesListWithPagination === undefined || !props.subspacesListWithPagination?.subspaces) {
    props.history.push("/exit");
  }

  const [showSubCreate, setShowSubCreate] = useState(false);
  const [showAddMembers, setShowAddMembers] = useState(false);
  const [erroMessage, setMessage] = useState("");
  const [createSubSpaceName, setCreateSubSpaceName] = useState("");
  const [showModalQR, setShowModalQR] = useState(false);
  const [showModalRenameSubspace, setShowModalRenameSubspace] = useState(false);
  const [editedSubspaceName, setEditedSubspaceName] = useState(props.subspaceName);
  const [showModalDeleteSubspace, setShowModalDeleteSubspace] = useState(false);
  const [createMaxOccupancy, setCreateMaxOccupancy] = useState('50');
  const [editedMaxOccupancy, setEditedMaxOccupancy] = useState(props.maxOccupancy);
  const [showModalMaxOccupancy, setShowModalMaxOccupancy] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const { subspaces, hasNextPage, totalPages } = props.subspacesListWithPagination;

  const loadData = async () => {
    await props.onGetSubspacesByUserIdWorkSpaceId(searchValue);
  }

  useEffect(() => {
    closeModalQR();
    onCloseRename();
    closeDeleteSubSpace();
    onCloseMaxOccupancy();
    loadData();

    return () => {
      props.onResetSubspaceList();
    }
  }, []);

  const onCreate = (subspaceNameEntered, maxOccupancy) => {
    props.onCreateSubWorkspace(subspaceNameEntered, props.workSpaceId, props.userId, maxOccupancy).then((responce) => {
      if (responce.status) {
        const { name, subspaceOwner, usersCount, _id } = responce.data.subspaces[responce.data.subspaces.length - 1];
        props.onSetWorkspaceSubFocused(responce.data.subspaces[responce.data.subspaces.length - 1]); //Sould be deleted later releases      
        props.onSetSubspaceFocused({ name, subspaceOwner, usersCount, _id });

        let adminUser = props.workspaceUsers.filter((person) => person.roleId === roles.ADMIN);
        let adminid = adminUser.map(a => a.id);
        props.onAddMemberSubWorkspace(adminid, props.workSpaceId, responce.data.subspaces[responce.data.subspaces.length - 1]._id);
        setMessage("");
        setCreateSubSpaceName("");
        props.onUpdateCreateSubSpaceMember(responce.data, props.workSpacesProps, responce.data.subspaces[responce.data.subspaces.length - 1]._id);
        setShowSubCreate(false);
        setShowAddMembers(true);
        setCreateMaxOccupancy('50');
        props.onGetSubspacesByUserIdWorkSpaceId(searchValue);
      } else {
        setMessage(responce.systemError);
      }
    }).catch(error => {
      setMessage(error.message);
      setCreateSubSpaceName("");
    });
  }

  const onChangeCreateSubspaceName = (value) => {
    setCreateSubSpaceName(value);
    setMessage("");
  }

  const onChangeCreateMaxOccupancy = (value) => {
    setCreateMaxOccupancy(value);
    setMessage("");
  }

  const renderModalCreatSubSpace = () => (
    <Modal
      title={props.dictionary.s("createSubSpace")}
      dialogClassName="safely-modal renderModalCreatSubSpace"
      showModal={showSubCreate}
      onHide={() => {
        setCreateSubSpaceName("");
        setMessage("");
        setCreateMaxOccupancy('50');
        setShowSubCreate(false);
        setShowAddMembers(false);
      }}
      onSave={async () => {
        if (createSubSpaceName.trim() !== '' && createMaxOccupancy.trim() !== '') {
          let subspaceNameEntered = createSubSpaceName.trim();
          const maxOccupancy = parseInt(createMaxOccupancy);
          if (subspaces && subspaces.length > 0) {
            const found = subspaces.find(
              (x) => x.name.toLowerCase() === subspaceNameEntered.toLowerCase()
            );
            if (found || (subspaceNameEntered.toLowerCase() === props.spaceName.toLowerCase())) {
              setMessage(props.dictionary.s("theWorksubspaceNameAlreadyExists"));
            }
            else if (maxOccupancy) {
              onCreate(subspaceNameEntered, maxOccupancy);
            } else setMessage(props.dictionary.s("maximumOccupancyMustBeEqualOrGreaterthan1"));
          }
          else if (maxOccupancy) {
            onCreate(subspaceNameEntered, maxOccupancy);
          } else setMessage(props.dictionary.s("maximumOccupancyMustBeEqualOrGreaterthan1"));
        }
      }}
      onClose={() => {
        setCreateSubSpaceName("");
        setMessage("");
        setCreateMaxOccupancy('50');
        setShowSubCreate(false);
        setShowAddMembers(false);
      }}
      textButtonSave={props.dictionary.s("next")}
      textButtonClose={props.dictionary.s("cancel")}
      propertyButtonSave={{
        block: false,
        style: {
          backgroundColor: (createSubSpaceName.trim() !== '' && createMaxOccupancy.trim() !== '') ? general.theme.primaryColor : general.theme.grayMedium,
          color: general.theme.whiteFontColor,
          border: (createSubSpaceName.trim() !== '' && createMaxOccupancy.trim() !== '') ? `1px solid ${general.theme.primaryColor}` : `1px solid ${general.theme.grayMedium}`,
        }
      }}
      bsStyleButtonSave="secondary"
      buttonCloseProperties={{
        block: false,
        style: {
          backgroundColor: general.theme.whiteColor,
          border: `1px solid ${general.theme.primaryColor}`,
          color: general.theme.primaryColor
        }
      }}

      properties={{
        centered: true,
      }}
      body={
        <>
          <div className="pb-15">
            <InputText
              label={props.dictionary.s('subspaceName')}
              labelClassName="create-subspace-title mb-0"
              inputClassName="form-control"
              onChangeText={onChangeCreateSubspaceName}
              value={createSubSpaceName}
              onKeyPress={(event) => {
                let regex = new RegExp("[0-9A-Za-z\u00C0-\u017F ]+$");
                let key = String.fromCharCode(
                  !event.charCode ? event.which : event.charCode
                );
                if (!regex.test(key)) {
                  event.preventDefault();
                  return false;
                }
              }}
              maxLength="30"
            />
          </div>
          <div className="pb-15">
            <InputNumber
              label={props.dictionary.s('maximumOcuppancy')}
              labelClassName="create-subspace-title mb-0"
              inputClassName="form-control"
              onChangeText={onChangeCreateMaxOccupancy}
              value={createMaxOccupancy}
              min="0"
              onKeyPress={event => {
                event = (event) ? event : window.event;
                var charCode = (event.which) ? event.which : event.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                  event.preventDefault();
                  return false;
                }
                return true;
              }}
            />
          </div>
          {(props.workspaceMessage || erroMessage) && (
            <div
              className="label-div"
              style={{
                marginHorizontal: 20,
                marginVertical: 10,
                textAlign: "center",
                color: general.theme.redColor
              }}
            >
              <label>
                {props.message || erroMessage}
              </label>
            </div>
          )}
        </>
      }
    />
  );

  const showMyQRcodeModal = () => setShowModalQR(true);
  const showRenameSubspaceModal = () => setShowModalRenameSubspace(true);
  const showDeletedSubspaceModal = () => setShowModalDeleteSubspace(true);
  const closeModalQR = () => setShowModalQR(false);
  const closeAddmember = () => {
    setShowSubCreate(false);
    setShowAddMembers(false);
  };

  const onCloseRename = () => {
    setEditedSubspaceName(props.subspaceName);
    setShowModalRenameSubspace(false);
    setMessage("");
  }

  const closeDeleteSubSpace = () => {
    setShowModalDeleteSubspace(false);
  }

  const onSaveRename = async () => {
    if (editedSubspaceName !== "") {
      const subspaceName = editedSubspaceName.trim();
      if (subspaceName.length > 0) {
        const found = subspaces.find(
          x => x.name.toLowerCase() === subspaceName.toLowerCase()
        );
        if (found) {
          setMessage(props.dictionary.s("theSubspaceNameAlreadyExists"));
        } else {
          const response = await props.onRenameWorkspaceSub(
            {
              workSpaceId: props.spaceFocused._id,
              subspaceId: props.subspaceFocused._id,
              name: subspaceName
            },
            props.accesstokenidp
          );
          if (response.status) {
            setShowModalRenameSubspace(false);
            props.onUpdateCreateSubSpaceMember(response.data, props.workSpacesProps, props.subspaceFocused._id);
            props.onRenameSubspace(props.subspaceFocused._id, subspaceName); //*New action
          } else {
            setMessage(props.workspaceMessage);
          }
        }
      } else { setMessage(props.dictionary.s("theSubspaceNameIsInvalid")); }
    } else { setMessage(props.dictionary.s("theSubspaceNameIsInvalid")); }
  }

  const renameChange = (value) => {
    setEditedSubspaceName(value);
    setMessage("");
  }

  const onCloseMaxOccupancy = () => {
    setShowModalMaxOccupancy(false);
    setEditedMaxOccupancy(props.maxOccupancy);
    setMessage('');
  }

  const onchangeEditedMaxOccupancy = (value) => {
    setEditedMaxOccupancy(value);
    setMessage("");
  }

  const onSaveUpdateMaxOccupancy = () => {
    if (editedMaxOccupancy !== "") {
      const editMaxOccupancy = parseInt(editedMaxOccupancy);
      if (editMaxOccupancy) {
        props.onUpdateMaxOccupancy({
          workSpaceId: props.spaceFocused._id,
          subspaceId: props.subspaceFocused._id,
          maxOccupancy: editMaxOccupancy
        }, props.accesstokenidp).then(response => {
          if (response.status) {
            onCloseMaxOccupancy();
            props.onUpdateCreateSubSpaceMember(response.data, props.workSpacesProps, props.subspaceFocused._id);
          }
        });
      } else setMessage(props.dictionary.s("maximumOccupancyMustBeEqualOrGreaterthan1"));
    } else setMessage(props.dictionary.s("theMaximumOccupancyIsInvalid"));
  }

  const showEditMaximumOccupancyModal = () => setShowModalMaxOccupancy(true);

  const handleSearchSubspace = async (value) => {
    setSearchValue(value);
    await props.onGetSubspacesByUserIdWorkSpaceId(value);
  }

  const handleShowMoreSubspaces = async () => {
    await props.onShowMoreSubspaces();
  }

  const onCreateSubspace = () => {
    setShowSubCreate(true);
    if (searchValue !== '')
      handleSearchSubspace('');
  }

  return (
    <MainNavigation title={props.title}>
      <Container fluid>
        <Row>
          <ReactTooltip />
          <MyQRCode
            showModalQR={showModalQR}
            title={props.lan === 'en' ? props.dictionary.s("subspace") + " '" + props.subspaceFocused.name + "' " + props.dictionary.s("qrCode") : props.dictionary.s("subSpaceQrcode") + " '" + props.subspaceFocused.name + "' "}
            name={props.subspaceFocused.name}
            value={'subSpace||' + props.spaceFocused._id + '||' + props.subspaceFocused._id + '||' + props.subspaceFocused.name}
            closeModalQR={closeModalQR}
            colorCode={props.spaceFocused.settings.backgroundColor}
          />
          <Rename
            title={props.dictionary.s("renameSubspace")}
            showModalRename={showModalRenameSubspace}
            onHideRename={onCloseRename}
            onSaveRename={onSaveRename}
            onCloseRename={onCloseRename}
            loading={false}
            renameChange={renameChange}
            changedWorkspaceName={editedSubspaceName}
            erroMessage={props.workspaceMessage || erroMessage ? props.message || erroMessage : null}
          />
          <MaximumOccupancy
            title={props.dictionary.s('editMaximumOccupancy')}
            showModalMaxOccupancy={showModalMaxOccupancy}
            onHideMaxOccupancy={onCloseMaxOccupancy}
            onSaveMaxOccupancy={onSaveUpdateMaxOccupancy}
            onCloseMaxOccupancy={onCloseMaxOccupancy}
            loading={false}
            setMaximumOccupancy={onchangeEditedMaxOccupancy}
            maximumOccupancy={editedMaxOccupancy}
            dictionary={props.dictionary}
            errorMessage={props.workspaceMessage || erroMessage ? props.message || erroMessage : null}
          />
          {renderModalCreatSubSpace()}
          <AddMemberPopUP showAddMembers={showAddMembers} closeAddmember={closeAddmember} type="subWorkSpace" />
          <DeleteSubSpace showDeleteSubSpace={showModalDeleteSubspace} closeDeleteSubSpace={closeDeleteSubSpace} />
        </Row>
        <Row className="space-header subspace-header">
          <Col xs={12} className="header-title header-subtitle">
            <div className="subtitle-header subspace-list">
              <div className='d-flex flex-row align-items-center'>
                {props.spaceFocused.picture ?
                  <Avatar
                    src={props.spaceFocused.picture}
                    className="rounded-circle"
                    styles={{ boxShadow: '0px 0px 0px 5px #fff' }}
                    width={40}
                    height={40}
                  /> : <div
                    className="noSpaceImage-worksapce"
                    style={{
                      backgroundColor: props.spaceFocused.settings.backgroundColor,
                    }}
                  >
                    <div>
                      {props.spaceFocused.name.substring(0, 2)}
                    </div>
                  </div>}
                <h4 className='pb-0 pl-3'>{props.spaceName}</h4>
              </div>
              {
                (props.spaceFocused.roleId === roles.ADMIN) ?
                  <span>
                    {subspaces ? subspaces.length : '0'} {' '}
                    {subspaces ? `${subspaces.length <= 1 ? props.dictionary.s("subspace") : props.dictionary.s("subspaces")}` : props.dictionary.s("subspace")}
                  </span> :
                  <span>
                    {subspaces ? subspaces.length : '0'} {' '}
                    {subspaces ? `${subspaces.length <= 1 ? props.dictionary.s("subspace") : props.dictionary.s("subspaces")}` : props.dictionary.s("subspace")}
                  </span>
              }
            </div>
          </Col>
          <Col xs={12} className="header-title">
            <h4>{props.dictionary.s("subspaces")}</h4>
          </Col>
          <Col xs={12} sm={6}
            className="input-search-container searchSpace"
          >
            <InputSearch
              placeholder={props.dictionary.s("searchSubspace")}
              searchValue={searchValue}
              onChangeText={setSearchValue}
              handleSearch={handleSearchSubspace}
            />
          </Col>
          {props.spaceFocused.roleId === roles.ADMIN ? <Col
            xs={12}
            md={6}
            className="header-cta-wrapper d-flex justify-content-end"
          >
            <CreateButton
              dictionary={props.dictionary}
              onClick={onCreateSubspace}
              text='createSubSpace'
            />
          </Col> : null}
        </Row>
        <Row className="sub-space-wrapper">
          {subspaces.length > 0 ?
            <>
              {subspaces.map((item, index) => {
                const selected = props.subspaceFocused._id === item._id;
                return (
                  <Col
                    xs={12} md={3} sm={12} lg={3} key={index}>
                    <div
                      className={`${selected
                        ? "space-sub-item active"
                        : "space-sub-item"
                        }`}
                      onClick={() => {
                        const selectSubspaceOld = props.workspaceSubListOld.find(susbpaceOld => susbpaceOld._id === item._id);
                        props.onSetWorkspaceSubFocused(selectSubspaceOld);
                        props.onSetSubspaceFocused(item);
                        setEditedMaxOccupancy(item.maxOccupancy);
                      }}
                    >
                      <div className="user-icon-div">
                        <Usericon />
                      </div>
                      <div className={props.spaceFocused.roleId !== roles.USER ? "user-subspace" : "user-subspace continer-user-subspace"}
                        onClick={() => {
                          if (props.spaceFocused.roleId !== roles.USER) {
                            const selectSubspaceOld = props.workspaceSubListOld.find(susbpaceOld => susbpaceOld._id === item._id);
                            props.onSetWorkspaceSubFocused(selectSubspaceOld);
                            props.onSetSubspaceFocused(item);
                            setEditedMaxOccupancy(item.maxOccupancy);
                            props.history.push("/subWorkspaceListAddmember");
                          }
                        }}>
                        <Label
                          data-tip={item.name}
                        >
                          {item.name}
                        </Label>
                        {(props.spaceFocused.roleId !== roles.USER) ?
                          <p>
                            {item.usersCount}{" "}
                            {`${item.usersCount <= 1 ? props.dictionary.s("member") : props.dictionary.s("members")}`}
                          </p>
                          : null}

                      </div>
                      {props.spaceFocused.roleId === roles.ADMIN && (props.spaceFocused.defaultSubspaceId !== item._id) ? (
                        <div className="sub-spacetoggle">
                          <ToggleMenu
                            onDropDownClick={() => {
                              const selectSubspaceOld = props.workspaceSubListOld.find(susbpaceOld => susbpaceOld._id === item._id); //this should be removed in later releases
                              props.onSetWorkspaceSubFocused(selectSubspaceOld);
                              props.onSetSubspaceFocused(item);
                              setEditedSubspaceName(item.name);
                              setEditedMaxOccupancy(item.maxOccupancy);
                            }}
                            id={props.workSpaceId}
                            dropDownList={[
                              {
                                name: "subSpaceQrcode",
                                onDropDownItemClick: showMyQRcodeModal
                              },
                              {
                                name: "renameSubspace",
                                onDropDownItemClick: showRenameSubspaceModal
                              },
                              {
                                name: "deleteSubspace",
                                onDropDownItemClick: showDeletedSubspaceModal
                              },
                              {
                                name: "editMaximumOccupancy",
                                onDropDownItemClick: showEditMaximumOccupancyModal
                              }
                            ]}
                          />
                        </div>
                      ) : null}
                    </div>
                  </Col>
                );
              })}
            </>
            : <Col xs={12} className="noSubspacesFound text-center p-4">
              {
                subspaces.length === 0 && props.loading ?
                  props.dictionary.s("loading") + '...'
                  : subspaces.length === 0 && searchValue !== '' && !props.loading &&
                  props.dictionary.s("noSubspacesFound")
              }
            </Col>
          }
          <ShowMoreButton
            handleShowMore={handleShowMoreSubspaces}
            dictionary={props.dictionary}
            hasNextPage={hasNextPage}
            totalPages={totalPages}
          />
        </Row>
      </Container>
    </MainNavigation>
  );
};

const mapStateToProps = state => ({
  userId: state.user.id,
  accesstokenidp: state.user.accesstokenidp,
  workSpaceId: state.workspace.spaceFocused._id,
  spaceName: state.workspace.spaceFocused.name,
  subspaceName: state.workspace.subspaceFocused.name,
  workspaceSubListOld: state.workspace.workspaceFocused.subspaces,
  workspaceMessage: state.workspace.message,
  lan: state.language.lang,
  dictionary: {
    s: key => translate(state.language.dictionary, key)
  },
  maxOccupancy: state.workspace.subspaceFocused.maxOccupancy,
  workSpacesProps: state.workspace,
  subspaceFocused: state.workspace.subspaceFocused,
  spaceFocused: state.workspace.spaceFocused,
  subspacesListWithPagination: state.workspace.subspacesListWithPagination,
  workspaceUsers: state.workspace.workspaceUsers,
  loading: state.workspace.loading,
});

const mapDispatchToProps = dispatch => ({
  onSetWorkspaceSubFocused: (workspaceSub) =>
    dispatch(setWorkspaceSubFocused(workspaceSub)),
  onCreateSubWorkspace: (name, workSpaceId, userId, maxOccupancy) => dispatch(createSubWorkspace(name, workSpaceId, userId, maxOccupancy)),
  onAddMemberSubWorkspace: (userIds, workSpaceId, subspaceId) => dispatch(addMemberSubWorkspace(userIds, workSpaceId, subspaceId)),
  onRenameWorkspaceSub: (body, accesstokenidp) => dispatch(renameWorkspaceSub(body, accesstokenidp)),
  onUpdateMaxOccupancy: (body, accesstokenidp) => dispatch(updateMaxOccupancy(body, accesstokenidp)),
  onUpdateCreateSubSpaceMember: (data, workSpacesProps, subspaceId) => dispatch(updateCreateSubSpaceMember(data, workSpacesProps, subspaceId)),
  onGetSubspacesByUserIdWorkSpaceId: (search) => dispatch(getSubspacesByUserIdWorkSpaceId(search)),
  onSetSubspaceFocused: (subspace) => dispatch(setSubspaceFocused(subspace)),
  onRenameSubspace: (subspaceId, subspaceName) => dispatch(renameSubspace(subspaceId, subspaceName)),
  onShowMoreSubspaces: () => dispatch(showMoreSubspaces()),
  onResetSubspaceList: () => dispatch(resetSubspaceList())
});

export default connect(mapStateToProps, mapDispatchToProps)(SubWorkspaceList);
