import React, { useState } from "react";
import Modal from "../commons/wrapped-components/Modal";
import { connect } from "react-redux";
import { general } from "../../general/global";
import { translate } from "../../redux/reducers/languageReducer";
import { removeMemberWorkspaceAPI } from "../../redux/actions/workspaceActions";
import { showToast } from "../commons/Toast";
import * as toastType from "../../constants/toastTypes";

const DeleteUser = (props) => {
  const [existActivePass, setExistActivePass] = useState(false);

  const displayModalContent = (data) => {
    if (data) {
      const isUserDisabled = data.disabledSpaces && data.disabledSpaces.find((space) => space._id === props.workSpaceId);
      if (existActivePass) {
        return (
          <>
            <span>{` '${data.firstName+' '+data.lastName}' `}</span>
            {props.dictionary.s("messageUserHasActivedPass")}
            <span>{` '${props.spaceFocused.name}'. `}</span>
            {props.dictionary.s("areYouSureWantToContinue")}
          </>
        );
      } else if (isUserDisabled) {
        if(props.lang === "es") {
          //spanish text
          return (
            <>
              {props.dictionary.s('enableUserSafelyPassSuffix')}
              <span>{` ${data.firstName+' '+data.lastName} `}</span>
              {props.dictionary.s("safelyPassIsDisabledForTheSpace")}
              <span>{` '${props.spaceFocused.name}'. `}</span>
              {props.dictionary.s("AreYouSureYouWantToRemove")}
            </>
          );
        } else {
          return (
            <>
              <span>{`${data.firstName+' '+data.lastName}'s `}</span>
              {props.dictionary.s("safelyPassIsDisabledForTheSpace")}
              <span>{` '${props.spaceFocused.name}'. `}</span>
              {props.dictionary.s("AreYouSureYouWantToRemove")}
            </>
          );
        } 
      } 
      else {
        return (
          <>
            {props.dictionary.s("youAreAboutToRemove")}
            <span>{` '${data.firstName+' '+data.lastName}' `}</span>
            {props.dictionary.s("fromSpace")}
            <span>{` '${props.spaceFocused.name}'. `}</span>
            {props.dictionary.s("areYouSureWantToContinue")}
          </>
        );
      }
    }
  };

  return (
    <Modal
      title={`${props.dictionary.s("remove")} ${
        props.data ? props.data.firstName+' '+props.data.lastName : null
      }?`}
      dialogClassName="safely-modal renderModalCreatSubSpace"
      showModal={props.showDeleteMember}
      onHide={() => {
        props.closeDeleteModal();
        setExistActivePass(false);
      }}
      onSave={async () => {
        let response = {};
        if (props.data && props.workSpaceId) {
          response = await props.onRemoveMemberFromSpaceAPI(
            props.data.userId,
            props.workSpaceId,
            existActivePass
          );
          if (
            response.status === false &&
            response.systemError === "Safely pass is active"
          ) {
            setExistActivePass(true);
          } else if (response && response.status) {
            showToast(toastType.SUCCESS, props.dictionary.s("userDeleted"));
            setExistActivePass(false);
            props.closeDeleteModal();
            props.load();
          }
        }
      }}
      onClose={() => {
        props.closeDeleteModal();
        setExistActivePass(false);
      }}
      textButtonSave={
        props.loading ? "..." : props.dictionary.s("removebutton")
      }
      textButtonClose={props.dictionary.s("cancel")}
      propertyButtonSave={{
        block: false,
        style: {
          backgroundColor: "#E67070",
          color: general.theme.whiteFontColor,
          border: `1px solid #E67070`,
        },
      }}
      bsStyleButtonSave="secondary"
      buttonCloseProperties={{
        block: false,
        style: {
          backgroundColor: general.theme.whiteColor,
          border: `1px solid ${general.theme.primaryColor}`,
          color: general.theme.primaryColor,
        },
      }}
      properties={{ centered: true }}
      body={<div className="removeText">{displayModalContent(props.data)}</div>}
      isLoadingButton={props.loading}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    workSpaceId: state.workspace.spaceFocused._id,
    accesstokenidp: state.user.accesstokenidp,
    dictionary: {
      s: (key) => translate(state.language.dictionary, key),
    },
    loading: state.workspace.loading,
    spaceFocused: state.workspace.spaceFocused,
    lang: state.language.lang,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onRemoveMemberFromSpaceAPI: (userId, workSpaceId, checkout) =>
    dispatch(removeMemberWorkspaceAPI(userId, workSpaceId, checkout)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUser);
