import React, { useState, useEffect } from "react";
import LoginHeader from "./LoginHeader";
import LoginRightDesign from "./LoginRightDesign";
import Button from "../commons/styled-components/general/Button";
import InputStyled from "../commons/styled-components/general/Input";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Socialicons from "../onboarding/Socialicons";
import { connect } from "react-redux";
import { translate } from "../../redux/reducers/languageReducer";
import { SendEmailOrMobile, ChangeForgotEmailNumber, ResetForgotEmailNumber } from '../../redux/actions/loginActions';
import Alert from 'react-bootstrap/Alert';
import * as EmailValidator from 'email-validator';

const ForgotPassword = (props) => {
  const [deviceType, setdeviceType] = useState(window.deviceType);
  const [displayAlert , setdisplayAlert] = useState("");

  useEffect(() => {
    function handleResize() {
      setdeviceType(window.deviceType);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onClickOfSignUp = () => {
    props.history.push("/signup");
  };

  const validateCodeForm = (emailOrMobile) => {
    if (emailOrMobile && emailOrMobile.length > 0) {
        const validNumber = /^\d{10}$/.test(emailOrMobile);
        const validEmail = EmailValidator.validate(String(emailOrMobile).toLowerCase()); 
        if(validNumber || validEmail) {
            return true;
        }    
    }
  }

    const onClickofSend = async (e) => {
        e.preventDefault();
        const response = await props.onSendEmailOrMobile(props.emailOrMobile, props.lang);
        props.onResetForgotEmailNumber();
        document.querySelector("#emailOrMobile").value = "";
        if(response && response.status) {
          setdisplayAlert(<Alert variant="success" className="success-alert">{response.data.message}</Alert>);  
        } else {
          setdisplayAlert(<Alert variant="danger">{response.axActions[0][0].message}</Alert>);
        }
    }

    const displayAlertMessage = (paramsDisplayAlert) => {
      if(paramsDisplayAlert) {
        setTimeout(() => {
          setdisplayAlert('');
          props.history.push('/');
        }, 5000);
      }
      return paramsDisplayAlert;
    }

  return (
    <div className= { deviceType==="desktop" || deviceType==="tablet" ? "wrapper displayWrapper" : "wrapper" }>
      <div>{displayAlertMessage(displayAlert)}</div>
      <div className="login">
        <div
          className={
            deviceType !== "phone"
              ? "login-form-column col-7"
              : "login-form-column col-12"
          }
        >
          <div className="forgotPass-header"><LoginHeader /></div>
          <div className="safely-sigin-form row">
            <div>
                <span className="signUp-title">
                    {props.dictionary.s("forgotYourPassword")}
                </span>
                <p className="forgot-para-text">
                     {props.dictionary.s("sendUsEmailorMobileNumber")}
                </p>
            </div>
            <Form className="form-login forgotPass-form">
              <Form.Group>
                <InputStyled
                  id="emailOrMobile"
                  name="email"
                  required
                  type="text"
                  placeholder={props.dictionary.s("emailOrPhoneNumber")}
                  onChange={(e) => props.onChangeEmailNumber(e.target.value)}
                  className="border-bottom-input border-bottom-input-focus"
                  placeholdercolor="#6E6E7C"
                />
              </Form.Group>
              <div
                className="send-btn"
              >
                <Button
                  type="submit"
                  disabled={!validateCodeForm(props.emailOrMobile)}
                  block
                  className="btn-submit"
                  width="280px"
                  height="40px"
                  position="relative"
                  display="block"
                  right="0"
                  borderradius="4px"
                  onClick={onClickofSend}
                >
                  {props.dictionary.s("send")}
                </Button>
              </div>
              <div className="forgot-password">
                <Link to="/">{props.dictionary.s("backToSignIn")}</Link>
              </div>
            </Form>
            <div className="social-icon-div forgot-socialIcon col-12">
              <div className="signup-with-text">
                {props.dictionary.s("orSigninWith")}
              </div>
              <Socialicons />
            </div>
            {deviceType === "phone" ? (
              <div className="phoneSingin">
                <span>
                  {props.dictionary.s("dontHaveAnAccount")}
                  <Link to="/signup" className="phoneSigninLink">
                    {props.dictionary.s("signup")}
                  </Link>
                </span>
              </div>
            ) : null}
          </div>
        </div>
        {deviceType !== "phone" ? (
          <LoginRightDesign SignUpClicked={onClickOfSignUp} />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  emailOrMobile: state.login.forgotEmailNumber,
  lang: state.language.lang,
  dictionary: {
    s: (key) => translate(state.language.dictionary, key),
  },
});

const mapDispatchToProps = (dispatch) => ({
    onSendEmailOrMobile: (emailNumber, language) =>
      dispatch(SendEmailOrMobile(emailNumber, language)),
    onChangeEmailNumber: (emailOrMobile) => 
      dispatch(ChangeForgotEmailNumber(emailOrMobile)),
    onResetForgotEmailNumber: () => 
      dispatch(ResetForgotEmailNumber()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
