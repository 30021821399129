/*  Libraries */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Col, Row, Button } from "react-bootstrap";

/*  Configurations, Variables  */
import { general } from "../../general/global";
import * as toastType from "../../constants/toastTypes";
import * as inputTypes from "../../constants/inputTypes";
import * as roles from "../../constants/roles";

/*  Components */
import Avatar from "../commons/wrapped-components/Avatar";
import TimeLine from "../commons/Timeline";
import MainNavigation from "../Navigation/MainNavigation";
import { showToast } from "../commons/Toast";
import { ReactComponent as EditingIcon } from "../../icons/pen.svg";
import ImageLoader from "../commons/wrapped-components/ImageLoader";

/*  Reducers */
import { translate } from "../../redux/reducers/languageReducer";

/*  Actions */
import {
  valueChangeInputCheckPoint,
  addCheckPoint,
  grantAccessAuthorization,
  deniedAccessAuthorization,
  setCheckPoint,
  unregisteredAccessAuthorization,
  setCheckinByCheckpoint
} from "../../redux/actions/operationActions";
import { ToastSuccess, ToastError } from "../../redux/actions/toastAction";
import { getCheckList, } from "../../redux/actions/editFormActions";
import { getWorkspaceListWithPagination, checkWorkspaceIsDisabled } from "../../redux/actions/workspaceActions";
import { MessagePopup } from "./MessagePopup";

const CheckPointExecute = (props) => {
  const { pathname } = props.location;
  const [checkPointFlow, setCheckPointFlow] = useState(pathname === "/checkpoint");
  const [contentMarkdown, setContentMarkdown] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [showPopupMessage, setShowPopupMessage] = useState(false);
  const [messageType, setMessageType] = useState(null);

  const params = {
    checkInByCheckpoint: props.checkInByCheckpoint,
    activeKey: props.activeKey
  };

  const workspaceId = !props.isEditView ? props.workSpaceId : props.workspaceFocused._id;

  const getWorkSpace = workspaceId => props.workSpaces.find(x => x._id === workspaceId) || { settings: {}, name: '' };
  const workSpace = getWorkSpace(workspaceId);
  const init = async () => {
    let _contentMarkdown = "";
    let _inputs = [];

    await props.onGetWorkspaceListWithPagination(true);

    const { status, data } = await props.onGetCheckList(workspaceId, props.accesstokenidp);
    if (status) {
      _contentMarkdown = data.checkPointChecklist[0].contentMarkdown;
      _inputs = data.checkPointChecklist[0].inputs;
    }

    setContentMarkdown(_contentMarkdown);
    await props.onSetCheckPoint({
      userId: props.userId,
      workSpaceId: workspaceId,
      inputs: _inputs,
      userProfile: props.userProfile,
      userVerificationId: props.userVerificationId,
    });
    setShowLoader(false);
  }

  useEffect(() => {
    init();
  }, []);

  const onClickofEdit = (e) => {
    e.preventDefault();
    props.history.push('/editverificationform');
  }

  const getSpacePicture = () => {
    if (workSpace.picture) {
      return (
        <Avatar
          src={workSpace.picture}
          className="rounded-circle-small avatar"
          styles={{ boxShadow: '0px 0px 0px 5px #fff' }}
        />
      );
    } else {
      return (
        <div
          className="rounded-circle-small noSpaceImage-worksapce"
          style={{
            backgroundColor: workSpace.settings.backgroundColor,
            padding: '12px 8px',
          }}
        >
          {workSpace.name.substring(0, 2)}
        </div>
      )
    }
  };
  const validateRequired = () => props.inputs.find(i => i.required === true && !i.value) ? false : true;

  const requestSafelyPass = async () => {

    if (validateRequired()) {
      /*  validations */
      const validations = props.inputs.find(x =>
        x.type === inputTypes.BOOLEAN && x.value && (x.denyPass === true || x.denyPass === undefined) ||
        x.type === inputTypes.BUTTONGROUP && x.content.buttonList.find(i => i.value === x.value).denyAccess === true ||
        x.type === inputTypes.MULTIPLE && x.content.options.find(i => i.value === x.value)?.denyAccess === true ||
        x.type === inputTypes.RANGE && (Number(x.value) < Number(x.content.min) || Number(x.value) > Number(x.content.max)) ||
        x.type === inputTypes.DROPDOWN && x.content.options.find(i => i.value === x.value)?.denyAccess === true
      );

      const response = await props.onAddCheckPoint(
        props.userId,
        props.workSpaceId,
        props.inputs,
        validations ? 'no' : null
      );
      const { data, status } = response;
      const checkPoint = data[0];
      if (status) {
        let accessAuthorizationId = props.requestAccessAuthorizationId;
        if (params.checkInByCheckpoint) {
          const response = await props.onUnRegisteredAccessAuthorization(props.userProfile.id, props.workSpaceId, props.userId);
          if (response.status) accessAuthorizationId = response.data._id;
        }

        if (validations) {
          setMessageType('denied');
          await props.onDeniedAccessAuthorization(accessAuthorizationId, checkPoint._id, props.userId);
        } else {
          setMessageType('granted');
          await props.onGrantAccessAuthorization(accessAuthorizationId, checkPoint._id, props.userId);
        }
      }
      showModal();
    }
    else
      showToast(toastType.ERROR, props.dictionary.s('requiredValidation'));
  }

  const closeModal = () => {
    setShowPopupMessage(false);
  }

  const showModal = () => {
    setShowPopupMessage(true);
  }

  const workspaceIsDisabled = async () => {
    const { data, status } = await props.onCheckWorkspaceIsDisabled(props.userProfile.id, workspaceId);
    if (status && data) return data.disabled;
    return false;
  }

  return (
    <MainNavigation title={props.title}>
      <MessagePopup
        workSpace={workSpace}
        userProfile={props.userProfile}
        showModal={showPopupMessage}
        messageType={messageType}
        dictionary={props.dictionary}
        closeModal={() => {
          closeModal();
          if ((params.checkInByCheckpoint && params.activeKey === 'members') || (params.checkInByCheckpoint && params.activeKey === 'visitors')) {
            props.onSetCheckinByCheckpoint(false);
            props.history.push(`/checkInRegister/${params.activeKey}`);
          } else {
            props.history.push("/workspacelist")
          }
        }}
        lang={props.lang}
      />
      <Container>
        {
          showLoader ? <ImageLoader height='82vh' width='100%' borderRadius='' /> :
            <Row style={{ padding: "30px 25px" }}>
              <Col className="user-profile" md={12} style={{ height: "120px", borderBottom: "1px solid #edecff", textAlign: "center" }}>
                <div className="d-flex justify-content-end pt-4">
                  <div className="EditionDiv">
                    {
                      props.isEditView &&
                        workSpace.roleId === roles.ADMIN ?
                        <div onClick={onClickofEdit} className="verifyEditdiv">
                          <EditingIcon />
                          <span className="pl-2 font-weight-bold">{props.dictionary.s("Edit")}</span>
                        </div> : <div style={{ height: '25px' }}></div>
                    }
                  </div>
                </div>
                <Avatar
                  src={props.isEditView ? "" : props.userProfile.picture}
                  styles={{ alignItems: "center", width: "135px", height: '135px' }}
                  className="rounded-circle mr-2"
                />
              </Col>
              <Col>
                <div>
                  <label
                    style={{
                      ...styles.title,
                      textAlign: "center",
                      width: "100%",
                      paddingTop: "5rem",
                      fontSize: 24,
                      fontFamily: "Monserrat, Semibold",
                    }}
                  >
                    {props.isEditView ? props.dictionary.s("user") : props.userProfile.name}
                  </label>
                </div>
                <div>
                  <label
                    style={{
                      ...styles.title,
                      textAlign: "center",
                      fontSize: 17,
                      fontFamily: "Roboto, Medium",
                      // color: general.theme.gradDarkColor,
                      color: "#2B2B42",
                      width: "100%",
                    }}
                  >
                    {props.dictionary.s("isSeekingAccessTo")}
                  </label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 10
                  }}
                >
                  <div style={{ display: 'inline-block' }}>
                    {getSpacePicture()}
                    <label
                      style={{
                        fontSize: 22,
                        fontWeight: "600",
                        color: general.theme.darkColor,
                        marginLeft: '45px',
                        marginTop: '5px'
                      }}
                      className="safypasstext"
                    >
                      {workSpace.name}
                    </label>
                  </div>
                </div>
                <div>
                  {contentMarkdown !== null &&
                    <pre className="text-content text-content-pre"
                      style={{
                        fontFamily: "Roboto, Regular",
                        fontSize: 17,
                        color: "#6E7E88"
                      }}>
                      {contentMarkdown}
                    </pre>
                  }
                  <div className="d-flex flex-column">
                    <TimeLine
                      editable={false}
                      items={props.inputs}
                      onValueChange={(id, value) => props.onValueChangeInputCheckPoint(id, value)}
                      dictionary={props.dictionary}
                    />
                  </div>
                </div>
                {props.message && (
                  <div style={{ marginTop: 10, width: "100%" }}>
                    <label style={{ textAlign: "center", color: general.theme.DANGER }}>
                      {props.message}
                    </label>
                  </div>
                )}
                {
                  !props.isEditView &&
                  <div className="m-3 d-flex justify-content-center">
                    <Button
                      onClick={async () => {
                        if (!await workspaceIsDisabled()) {
                          requestSafelyPass();
                        }
                      }}
                      style={{
                        backgroundColor: (!validateRequired() ? general.theme.grayColor : general.theme.primaryColor),
                        borderColor: (!validateRequired() ? general.theme.grayColor : general.theme.primaryColor),
                        fontWeight: 600,
                      }}
                      disabled={props.loading || !validateRequired()}
                    >
                      {props.dictionary.s("verify")}
                    </Button>
                  </div>
                }
              </Col>
            </Row>
        }
      </Container>
    </MainNavigation >
  );
};

const styles = {
  content: {
    flexGrow: 1,
    backgroundColor: "#F9FBFE",
    alignItems: "center",
  },
  innerContent: {
    flex: 1,
    width: 310,
    paddingTop: 20,
  },
  titleContent: {
    paddingVertical: 15,
    //borderBottomWidth: 1,
    //borderBottomColor: colorsPalette.PRIMARY
  },
  title: {
    fontWeight: "bold",
    color: general.theme.darkColor,
    fontSize: 18,
  },
  button: {
    width: 110,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
  },
};

const mapStateToProps = (state) => ({
  requestAccessAuthorizationId: state.operation.requestAccessAuthorizationId,
  userProfile: state.operation.userProfile,
  userVerificationId: state.operation.userVerificationId,
  userId: state.user.id,
  workSpaceId: state.operation.workSpaceId,
  accesstokenidp: state.user.accesstokenidp,
  workSpaces: state.workspace.spacesListWithPagination.spaces,
  inputs: state.operation.checkPointInputs,
  comments: state.operation.checkPointComments,
  message: state.operation.message,
  loading: state.operation.loading,
  workspaceFocused: state.workspace.spaceFocused,
  dictionary: {
    s: key => translate(state.language.dictionary, key)
  },
  activeKey: state.operation.activeKey,
  checkInByCheckpoint: state.operation.checkInByCheckpoint,
  lang: state.language.lang,
});
const mapDispatchToProps = (dispatch) => ({
  onAddCheckPoint: (userId, workSpaceId, inputValues, passGenerated) =>
    dispatch(addCheckPoint(userId, workSpaceId, inputValues, passGenerated)),
  onValueChangeInputCheckPoint: (id, value) =>
    dispatch(valueChangeInputCheckPoint(id, value)),
  onGrantAccessAuthorization: (id, checkPointId, monitorId) => dispatch(grantAccessAuthorization(id, checkPointId, monitorId)),
  onDeniedAccessAuthorization: (id, checkPointId, monitorId) => dispatch(deniedAccessAuthorization(id, checkPointId, monitorId)),
  onToastSuccess: (message) => dispatch(ToastSuccess(message)),
  onToastError: (message) => dispatch(ToastError(message)),
  onSetCheckPoint: (_checkPoint) => dispatch(setCheckPoint(_checkPoint)),
  onGetCheckList: (userId, accesstokenidp) => dispatch(getCheckList(userId, accesstokenidp)),
  onUnRegisteredAccessAuthorization: (userId, workSpaceId, monitorId) => dispatch(unregisteredAccessAuthorization(userId, workSpaceId, monitorId)),
  onGetWorkspaceListWithPagination: (value) => dispatch(getWorkspaceListWithPagination(value)),
  onSetCheckinByCheckpoint: (value) => dispatch(setCheckinByCheckpoint(value)),
  onCheckWorkspaceIsDisabled: (userId, workspaceId) =>
    dispatch(checkWorkspaceIsDisabled(userId, workspaceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckPointExecute);