import {
  USER_POST,
  USER_POST_SUCCESS,
  USER_POST_ERROR,
  USER_SOCIAL_POST,
  USER_SOCIAL_POST_SUCCESS,
  USER_SOCIAL_POST_ERROR,
  USER_CONTACT_UPDATE,
  USER_CONTACT_UPDATE_SUCCESS,
  USER_CONTACT_UPDATE_FAIL,
  USER_DETAILS_POST,
  USER_DETAILS_POST_SUCCESS,
  USER_DETAILS_POST_ERROR,
  EDIT_USER,
  SET_USER,
  SET_MESSAGE,
  USER_RESET,
  USER_UPDATE_ROLE,
  USER_UPDATE_ROLE_SUCESS,
  USER_UPDATE_ROLE_FAIL,
  USER_UPDATE_PROFILE_PHOTO,
  USER_UPDATE_PROFILE_PHOTO_SUCCESS,
  USER_UPDATE_PROFILE_PHOTO_FAIL,
  USER_UPDATE_USERNAME_SUCCESS,
  USER_UPDATE_USERNAME_FAILURE,
  SEND_VERIFY_PIN_SUCCESS,
  SEND_VERIFY_PIN_ERROR,
  VERIFY_PIN_SUCCESS,
  VERIFY_PIN_ERROR,
  CHECK_IN_NEW_USER,
  CHECK_IN_NEW_USER_SUCCESS,
  CHECK_IN_NEW_USER_FAILURE,
  USER_UPDATE_BACKGROUND_PROFILE_PHOTO,
  USER_UPDATE_BACKGROUND_PROFILE_PHOTO_SUCCESS,
  USER_UPDATE_BACKGROUND_PROFILE_PHOTO_FAIL,
  CLICKED_USER,
} from "../actions/types";

export const initialState = {
  _id: null,
  id: null,
  active: false,
  connectionId: "",
  countryCode: "",
  createdBy: "",
  dateCreated: "",
  email: "",
  idaasId: "",
  identityProvider: "",
  magicLinkCode: "",
  mobilePhone: "",
  name: "",
  lastName: "",
  picture: '',
  userName: "",
  visible: false,
  workSpaces: [],
  accesstokenidp: "",
  roles: [{}],
  loading: false,
  message: null,
  uploadingPhoto: false,
  __v: null,
  expiresAt: null,
  accesstoken: "",
  pinSentSuccessData: {},
  pinSentErrorData: {},
  verifiedPinSuccess: {},
  verifiedPinFail: {},
  backgroundPicture: '',
  userMgmtEditUserData: {},
  userMgmtClickedName: '',
};

export default (state, action) => {
  const { type, payload } = action;
  if (typeof state === "undefined") {
    return initialState;
  }
  switch (type) {
    case USER_POST:
    case USER_SOCIAL_POST:
    case USER_CONTACT_UPDATE:
    case USER_UPDATE_ROLE:
    case CHECK_IN_NEW_USER:
    case USER_DETAILS_POST:
      return { ...state, loading: true };
    case USER_POST_SUCCESS:
    case USER_SOCIAL_POST_SUCCESS:
      return { ...state, ...initialState, ...payload.data };
    case USER_POST_ERROR:
    case USER_SOCIAL_POST_ERROR:
      return { ...state, ...initialState, message: payload.message };
    case USER_CONTACT_UPDATE_SUCCESS:
      return {
        ...state,
        ...initialState,
        ...payload.data,
        accesstoken: state.accesstoken,
      };
    case USER_CONTACT_UPDATE_FAIL:
      return { ...state, loading: false, message: payload.message };
    case SET_USER:
      return { ...state, ...payload };
    case EDIT_USER:
      return { ...state, userMgmtEditUserData: payload };
    case SET_MESSAGE:
      return { ...state, message: payload };
    case USER_RESET:
      return { ...state, ...initialState };
    case USER_UPDATE_ROLE_SUCESS:
    case CHECK_IN_NEW_USER_SUCCESS:
    case USER_DETAILS_POST_SUCCESS:
      return { ...state, loading: false };
    case USER_UPDATE_ROLE_FAIL:
    case USER_DETAILS_POST_ERROR:
    case CHECK_IN_NEW_USER_FAILURE:
      return { ...state, loading: false, message: payload.message };
    case USER_UPDATE_PROFILE_PHOTO:
      return { ...state, uploadingPhoto: true };
    case USER_UPDATE_PROFILE_PHOTO_SUCCESS:
      return { ...state, uploadingPhoto: false, picture: payload.Location };
    case USER_UPDATE_PROFILE_PHOTO_FAIL:
      return { ...state, uploadingPhoto: false, message: payload };
    case USER_UPDATE_USERNAME_SUCCESS:
      return { ...state, name: payload.name, lastName: payload.lastName, loading: false };
    case USER_UPDATE_USERNAME_FAILURE:
      return { ...state, loading: false, message: payload.message };
    case SEND_VERIFY_PIN_SUCCESS:
      return { ...state, pinSentSuccessData: payload };
    case SEND_VERIFY_PIN_ERROR:
      return { ...state, pinSentErrorData: payload };
    case VERIFY_PIN_SUCCESS:
      return { ...state, verifiedPinSuccess: payload };
    case VERIFY_PIN_ERROR:
      return { ...state, verifiedPinFail: payload }
    case USER_UPDATE_BACKGROUND_PROFILE_PHOTO:
      return { ...state, uploadingPhoto: true };
    case USER_UPDATE_BACKGROUND_PROFILE_PHOTO_SUCCESS:
      return { ...state, uploadingPhoto: false, backgroundPicture: payload.Location };
    case USER_UPDATE_BACKGROUND_PROFILE_PHOTO_FAIL:
      return { ...state, uploadingPhoto: false, message: payload };
    case CLICKED_USER:
      return { ... state, userMgmtClickedName: payload};
    default:
      return state;
  }
}