import React, { useState } from "react";
import { Button } from "react-bootstrap";
import InputSelect from "../commons/wrapped-components/inputs/InputSelect";
import { connect } from "react-redux";
import Avatar from "../commons/wrapped-components/Avatar";
import InputPhoto from "../commons/wrapped-components/inputs/InputPhoto";
import InputText from "../commons/wrapped-components/inputs/InputText";
import { ReactComponent as CloseIcon } from "../../icons/closeIcon.svg";
import {
  updateProfilePhoto,
} from "../../redux/actions/userActions";
import moment from "moment";

const CheckInUser = (props) => {
  const format = props.lang === "es" ? "ddd, DD MMM YYYY" : "ddd, MMM DD YYYY";
  const localeDate = moment().locale(props.lang);

  const { userFirstName, picture, userId, userLastName } = props.user;
  const [userpicture, setUserpicture] = useState(picture);
  const [workSpaceId, setWorkSpaceId] = useState(props.workSpaces[0].value);

  return (
    <>
      <button
        onClick={() => props.setModalVisible(false)}
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <CloseIcon />
      </button>
      <h3>
        {props.dictionary.s("checkIn")} {userFirstName} {userLastName}
      </h3>
      <div
        className="row justify-content-center align-items-center"
        style={{ flexDirection: "column" }}
      >
        <div style={{ width: "160px", position: "relative" }} className="avtar check-in-user-avtar">
          <Avatar src={userpicture} className="rounded-circle mr-2" />
          <InputPhoto
            imagePreviewURL=""
            mode="onlyIcon"
            styleIcon={{
              borderRadius: "50%",
              width: 40,
              height: 40,
              border: "1px solid #ccc",
              right: '0px',
              bottom: '5px',
              left: null
            }}
            onScreenShot={async (file, imagePreviewUrl) => {
              setUserpicture(imagePreviewUrl);
              await props.onUpdateProfilePhoto(
                imagePreviewUrl,
                file,
                userId
              );
            }}
          />
        </div>
        <div className="checkin-input-container">
          <InputText className="form-control disabled-input" value={userFirstName} disabled={true} />
        </div>
        <div className="checkin-input-container">
          <InputText className="form-control disabled-input" value={userLastName} disabled={true} />
        </div>
        <div className="checkin-input-container">
          <InputSelect
            className="checkin-select"
            value={props.workSpaces.find(x => x.value === workSpaceId)}
            searchable={false}
            options={props.workSpaces}
            onChange={(space) => {
              if (!space) return;
              setWorkSpaceId(space.value);
            }}
            valueKey="value"
            labelKey="label"
            clearable={false}
            disabled={false}
          />
          <p
            className="popup-datetime"
          >
            {localeDate.format(format).split(".").join("")},{" "}
            {localeDate.format("hh:mm:ss a")}
          </p>
          <Button
            className="popup-checkin"
            onClick={() => {
              props.checkInUser(userId, workSpaceId, props.monitorId, { id: userId, name: userFirstName, lastName: userLastName, picture: picture });
            }}
          >
            {props.dictionary.s("checkIn")}
          </Button>
        </div>
      </div>
    </>
  );
};


const mapDispatchToProps = (dispatch) => ({
  onUpdateProfilePhoto: (uri, file, userId) =>
    dispatch(updateProfilePhoto(uri, file, userId))
});

export default connect(null, mapDispatchToProps)(CheckInUser);