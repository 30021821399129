import React from 'react';
import { connect } from "react-redux";
import { translate } from "../../../../redux/reducers/languageReducer";
import InputText from "./InputText";

const InputSearch = ({
    searchValue,
    className,
    placeholder,
    onChangeText,
    dictionary,
    handleSearch
}) => {

    return (
        <div className={className} style={{ position: "relative" }}>
            <span className="icon-search-container">
                <i className="fa fa-search search-fa-icon"></i>
            </span>
            <InputText
                searchable
                placeholder={placeholder ? placeholder : dictionary.s("search")}
                value={searchValue}
                onSearch={(searchValue) => { handleSearch(searchValue) }}
                onChangeText={(e) => onChangeText(e)}
                inputStyle={{ marginTop: 0 }}
                required={false}
                className="form-control user-search"
            />
        </div>
    );
}

InputSearch.defaultProps = {
    value: '',
    className: '',
    placeholder: null,
    onChangeText: () => { },
}

const mapStateToProps = (state) => ({
    dictionary: {
        s: (key) => translate(state.language.dictionary, key),
    },
});

export default connect(mapStateToProps, null)(InputSearch);