import React from "react";
import { SketchPicker } from "react-color";
import PropTypes from "prop-types";

const ColorPicker = ({ width, height, handleClick, handleClose, onChangeColor, displayColorPicker, color, padding, borderRadius, innerBorderRadius, marginRight }) => {
    const styles = {
        main: {
            position: 'relative',
            width: !padding && width,
            height: !padding && height,
            marginRight: marginRight
        },
        color: {
            width: width,
            height: height,
            borderRadius: innerBorderRadius ? innerBorderRadius : borderRadius,
            background: `${color}`,
        },
        swatch: {
            padding: padding,
            background: '#fff',
            borderRadius: borderRadius,
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
        },
        popover: {
            position: 'absolute',
            zIndex: 2,
            bottom: 0,
            right: 0
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        },
    }

    return (
        <div style={styles.main} className="color-div">
            <div style={styles.swatch} onClick={handleClick} className="color-cricle">
                <div style={styles.color} />
            </div>
            {
                displayColorPicker ? <div style={styles.popover}>
                    <div style={styles.cover} onClick={handleClose} />
                    <SketchPicker color={color} onChange={onChangeColor}  onChangeComplete={handleClose}/>
                </div> : null
            }
        </div>
    )
}

ColorPicker.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    handleClick: PropTypes.func,
    handleClose: PropTypes.func,
    onChangeColor: PropTypes.func,
    displayColorPicker: PropTypes.bool,
    color: PropTypes.string,
    padding: PropTypes.string,
    borderRadius: PropTypes.string,
    innerBorderRadius: PropTypes.string,
    marginRight: PropTypes.string
}

ColorPicker.defaultProps = {
    width: 18,
    height: 18,
    handleClick: () => { },
    handleClose: () => { },
    onChangeColor: () => { },
    displayColorPicker: false,
    color: '#f17013',
    borderRadius: '0px',
}

export default ColorPicker;
