import React, { useEffect } from "react";
import MainNavigation from "../Navigation/MainNavigation";
import { connect } from "react-redux";

const Dashboard = (props) => {
  useEffect(() => {
    props.history.replace("/workspacelist");
  });
  return <MainNavigation title={props.title}>Dashboard</MainNavigation>;
};

const mapStateToProps = (state) => ({
  user: state.user,
  workspaceCreated: state.workspace.workspaceCreated,
});

export default connect(mapStateToProps, null)(Dashboard);
