import React from "react";
import ButtonSidebarContainer from "../commons/styled-components/dashboard/SidebarButtonContainer";
import Icon from "../commons/styled-components/general/Icon";

const SidebarButton = props => (
  <>
    <ButtonSidebarContainer
      className="sidebar-button"
      backgroundColor={props.sidebarBgColor}
      position={props.position}
      bottom={props.bottom}
      onClick={props.onClick}
      right={props.right}
      left={props.left}
      marginLeft={props.marginLeft}
      rotate180dg={props.rotate180dg}
      height={props.sidebarHeight}
      width={props.sidebarWidth}
      boxShadow={props.sidebarBoxShadow}
      top={props.top}
      visibility={props.visibility}
      transition={props.transition}
    >
      <Icon className={props.iconClassName} color={props.iconColor} />
    </ButtonSidebarContainer>
  </>
);

export default SidebarButton;
