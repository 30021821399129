import React, { useEffect, useState } from "react";
import Button from "../commons/styled-components/general/Button";
import Input from "../commons/styled-components/general/Input";
import { connect } from "react-redux";
import InputPassword from "../commons/wrapped-components/inputs/InputPassword";
import LabelStyled from "../commons/styled-components/general/Label";
import {
  setMessage,
  resetUser,
  updateUserContact,
  sendVerifyEmailPin,
  sendVerifyMobilePin,
} from "../../redux/actions/userActions";
import {
  changedEmailOnboarding,
  changedName,
  changedLastName,
  changedPasswordOnboarding,
  resetOnboarding,
  changedCountryCode,
  getPhoneCodeList,
} from "../../redux/actions/onboardingActions";
import { loginWithSocialMedia } from "../../redux/actions/loginActions";
import { translate } from "../../redux/reducers/languageReducer";
import InputSelect from "../commons/wrapped-components/inputs/InputSelect";
import { languageChange } from "../../redux/actions/languageActions";
import Socialicons from "./Socialicons";
import { Link } from "react-router-dom";
import SignupWelcome from "./SignupWelcome";
import EmailPhoneNumber from "../commons/wrapped-components/inputs/EmailPhoneNumber";
import Notifications from "../../notifications";
import * as EmailValidator from "email-validator";

const notifications = new Notifications();
const Signup = (props) => {
  const [checked, setChecked] = useState(true);
  const [deviceType, setdeviceType] = useState(window.deviceType);
  const options = [
    {
      value: "en",
      label: props.dictionary.s("english"),
    },
    {
      value: "es",
      label: props.dictionary.s("spanish"),
    },
  ];
  const onChangeCheck = () => {
    setChecked(!checked);
  };
  const handleSignIn = () => {
    props.history.push("/");
  };
  const getConnectId = (connectionId) => {
    if (connectionId === "facebook") {
      return "facebook.";
    } else if (connectionId === "google-oauth2") {
      return "google.";
    } else if (connectionId === "windowslive") {
      return "microsoft.";
    } else {
      return "";
    }
  };
  useEffect(() => {
    window.location.replace("/#/signup");
    function handleResize() {
      setdeviceType(window.deviceType);
    }
    if (!props.userId) {
      props.onChangedEmailNumber("");
      props.onChangedLastName("");
      props.onChangedPassword("");
      props.onChangedName("");
      props.setMessage("");
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={deviceType + " limiter"}>
      <div className="container-login100">
        <div
          className="wrap-signup"
          style={{
            height:
              props.magicLinkCode && deviceType === "desktop" ? "575px !important" : null,
          }}
        >
          {deviceType !== "phone" ? (
            <SignupWelcome SignIn={handleSignIn} />
          ) : null}
          <div
            className={
              deviceType !== "phone"
                ? "signup-form col-7"
                : "signup-form col-10"
            }
          >
            <div className="language-select col-12">
              <InputSelect
                label={props.dictionary.s("language")}
                className="sws-select language-dropdown"
                value={options.find((x) => x.value === props.lang)}
                options={options}
                onChange={(lang) => {
                  props.onLanguageChange(lang.value);
                  props.setMessage("");
                }}
                sort={false}
                displayLabel={false}
                menuContainerStyle={{}}
                clearable={false}
                searchable={false}
                styles={{
                  control: {
                    borderRadius: "4px",
                    width: "98px",
                    cursor: "pointer",
                    ":hover": {
                      border: "1px solid #4FA3FF",
                    },
                  },
                }}
                labelKey="label"
                valueKey="value"
              />
            </div>
            <div className="form-align-center">
              <form className="login100-form validate-form form-signup">
                {props.userId === null ? (
                  <span className="signUp-title">
                    {props.dictionary.s("createAccount")}
                  </span>
                ) : (
                  <div>
                    <span className="signUp-title">
                      {props.dictionary.s("verifyYourName")}
                    </span>
                    <p className="paragraph-text-style">
                      {props.dictionary.s("fetchedYourDetailsFrom")}{" "}
                      {getConnectId(props.connectionId)}
                      {props.dictionary.s("pleaseVerifyNameandupdate")}
                    </p>
                  </div>
                )}
                {props.userId === null && (
                  <div className="wrap-input100 validate-input m-b-16">
                    <EmailPhoneNumber
                      selectedCountryCode={props.countryCode}
                      // phoneCodeList={phoneCodeList}
                      value={props.emailNumber}
                      autoCapitalize="none"
                      autoCorrect={false}
                      onChangeText={(text) => props.onChangedEmailNumber(text)}
                      onChangedCountryCode={(phoneCode) => {
                        props.onChangedCountryCode(phoneCode);
                      }}
                      placeholderPhoneNumberText={props.dictionary.s(
                        "emailOrPhoneNumber"
                      )}
                      placeholderSearchBox={
                        props.dictionary.s("search") + "..."
                      }
                    />
                  </div>
                )}
                <div className="wrap-input100 validate-input m-b-16">
                  <Input
                    type="text"
                    inputMode="text"
                    autocomplete="off"
                    name="first_name"
                    placeholder={props.dictionary.s("firstName")}
                    className="input-style"
                    value={props.name}
                    onChange={(e) => {
                      props.onChangedName(e.target.value);
                      props.setMessage(null);
                    }}
                    onKeyDown={(e) => {
                      let keyCode = e.keyCode ? e.keyCode : e.which;
                      if (keyCode > 47 && keyCode < 58) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className="wrap-input100 validate-input m-b-16">
                  <Input
                    type="text"
                    name="last_name"
                    autocomplete="off"
                    placeholder={props.dictionary.s("lastName")}
                    inputMode="text"
                    className="input-style"
                    // key={index}
                    value={props.lastName}
                    onChange={(e) => {
                      props.onChangedLastName(e.target.value);
                      props.setMessage(null);
                    }}
                    onKeyDown={(e) => {
                      let keyCode = e.keyCode ? e.keyCode : e.which;
                      if (keyCode > 47 && keyCode < 58) {
                        e.preventDefault();
                      }
                    }}
                    required
                  />
                </div>
                {props.userId === null && (
                  <div className="wrap-input100 validate-input m-b-16">
                    <InputPassword
                      id="passwordLogin"
                      className="input-style"
                      autocomplete="new-password"
                      iconstyle={{ lineHeight: "30px" }}
                      required
                      type="password"
                      name="password"
                      placeholder={props.dictionary.s("password")}
                      value={props.password}
                      onChange={(e) => {
                        props.onChangedPassword(e.target.value);
                        props.setMessage(null);
                      }}
                      placeholdercolor="#6E6E7C"
                      eyeiconcolor="1px solid #000000"
                    />
                  </div>
                )}

                {props.magicLinkCode !== null && (
                  <div className="wrap-input100 validate-input m-b-16">
                    <LabelStyled>{props.dictionary.s("code")}</LabelStyled>
                    <Input
                      type="text"
                      name="magic_link"
                      placeholder=""
                      className="input-style"
                      enabled={false}
                      value={props.magicLinkCode}
                    />
                  </div>
                )}

                {props.message && (
                  <div
                    style={{ marginTop: "10" }}
                    className="signup-error-message"
                  >
                    <LabelStyled
                      style={{
                        display: "block",
                        textAlign: "center",
                        fontSize: "12px",
                        color: "#cc5555",
                      }}
                    >
                      {props.message}
                    </LabelStyled>
                  </div>
                )}
                <label className="checkbox-div wrap-input100 validate-input m-b-16">
                  <input
                    type="checkbox"
                    value={checked}
                    onClick={onChangeCheck}
                    required
                  />
                  <span className="checkmark-tick"></span>
                  <span className="terms-text-style">
                    {props.dictionary.s('AgreeToThe')}{" "}
                    <a
                      href={
                        props.lang === "en"
                          ? "https://safely.andonix.com/termsandconditions.html"
                          : "https://safely.andonix.com/termsandconditions_es.html"
                      }
                      target="_blank"
                    >
                      {props.dictionary.s('termAndConditions')}
                    </a>{" "}
                    {props.dictionary.s('and_The')}{" "}
                    <a
                      href={
                        props.lang === "en"
                          ? "https://safely.andonix.com/privacypolicy.html"
                          : "https://safely.andonix.com/privacypolicy_es.html"
                      }
                      target="_blank"
                    >
                     {props.dictionary.s('PrivacyPolicy')}
                    </a>{" "}
                    {props.dictionary.s('ofSafelyPass')}
                  </span>
                </label>

                <div className="signup-div">
                  <Button
                    className={checked ? "singupButton" : "signupBtn"}
                    disabled={checked}
                    width="280px"
                    height="40px"
                    position="relative"
                    display="block"
                    right="0"
                    borderradius="4px"
                    onClick={async () => {
                      const emailNumber = props.emailNumber.trim();
                      const name = props.name.trim();
                      const lastName = props.lastName.trim();
                      const countryCode = props.countryCode;
                      if (
                        emailNumber !== "" &&
                        name !== "" &&
                        lastName !== ""
                      ) {
                        const validNumber = /^\d{10}$/.test(emailNumber);
                        const validEmail = EmailValidator.validate(
                          String(emailNumber).toLowerCase()
                        );
                        if (validNumber || validEmail) {
                          let response = null;
                          if (props.userId === null) {
                            //Is a new user
                            if (props.password) {
                              if (validEmail) {
                                response = await props.onClickSendVerifyEmailPin(
                                  emailNumber,
                                  props.lang
                                );
                                if (response.status) {
                                  props.history.push("/verifyemail");
                                } else {
                                  props.setMessage(
                                    response.axActions[0][0].message
                                  );
                                }
                              } else if (validNumber) {
                                response = await props.onClickSendVerifyMobilePin(
                                  emailNumber,
                                  countryCode,
                                  props.lang
                                );
                                if (response.status) {
                                  props.history.push("/verifyMobileNumber");
                                } else {
                                  props.setMessage(
                                    response.axActions[0][0].message
                                  );
                                }
                              }
                            } else {
                              props.setMessage(
                                props.dictionary.s("completeAllFields")
                              );
                            }
                          } else {
                            response = await props.onUpdateUserContact(
                              props.userId,
                              props.emailNumber,
                              props.mobilePhone,
                              props.name,
                              props.lastName
                            );
                            if (response.status) {
                              let fcmToken = "";
                              if (notifications.isSupported()) {
                                fcmToken = await notifications.getToken();
                              }
                              const result = await props.onLoginWithSocialMedia(
                                props.accessToken,
                                props.lang,
                                fcmToken
                              );
                              if (result.status) {
                                if (props.magicLinkCode === null) {
                                  props.history.push("/Welcome");
                                }
                                if (props.magicLinkCode !== null) {
                                  props.history.push("/workspacelist");
                                }
                              }
                            }
                          }
                        } else {
                          props.setMessage(
                            props.dictionary.s("notEmailOrNumberValid")
                          );
                        }
                      } else {
                        props.setMessage(
                          props.dictionary.s("completeAllFields")
                        );
                      }
                    }}
                  >
                    {props.dictionary.s("signup")}
                  </Button>
                </div>
                <div className="socialIcon-signUp">
                  <div className="signup-with-text">
                    {props.dictionary.s("orSignUpWith")}
                  </div>
                  <Socialicons />
                </div>
                {deviceType === "phone" ? (
                  <div className="phoneSingin">
                    <span>
                      {props.dictionary.s("haveAccount")}
                      <Link to="/" className="phoneSigninLink">
                        {props.dictionary.s("signin")}
                      </Link>
                    </span>
                  </div>
                ) : null}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: state.user.id,
  connectionId: state.user.connectionId,
  emailNumber: state.onboarding.emailNumber,
  name: state.onboarding.name,
  lastName: state.onboarding.lastName,
  password: state.onboarding.password,
  checkValue: state.onboarding.checkValue,
  magicLinkCode: state.onboarding.magicLinkCode,
  message: state.user.message,
  lang: state.language.lang,
  accessToken: state.user.accesstoken,
  dictionary: {
    s: (key) => translate(state.language.dictionary, key),
  },
  countryCode: state.onboarding.countryCode,
  phoneCodeList: state.onboarding.phoneCodeList,
});

const mapDispatchToProps = (dispatch) => ({
  onChangedEmailNumber: (emailNumber) =>
    dispatch(changedEmailOnboarding(emailNumber)),
  onChangedName: (name) => dispatch(changedName(name)),
  onChangedLastName: (lastName) => dispatch(changedLastName(lastName)),
  onChangedPassword: (password) =>
    dispatch(changedPasswordOnboarding(password)),
  onUpdateUserContact: (id, email, mobilePhone, name, lastName) =>
    dispatch(updateUserContact(id, email, mobilePhone, name, lastName)),
  setMessage: (message) => dispatch(setMessage(message)),
  onLoginWithSocialMedia: (accessToken, lang, fcmToken) =>
    dispatch(loginWithSocialMedia(accessToken, lang, fcmToken)),
  onResetUser: () => dispatch(resetUser()),
  onResetOnboarding: () => dispatch(resetOnboarding()),
  onLanguageChange: (lang) => dispatch(languageChange(lang)),
  onClickSendVerifyEmailPin: (email, language) =>
    dispatch(sendVerifyEmailPin(email, language)),
  onClickSendVerifyMobilePin: (mobile, countryCode, language) =>
    dispatch(sendVerifyMobilePin(mobile, countryCode, language)),
  onChangedCountryCode: (countryCode) =>
    dispatch(changedCountryCode(countryCode)),
  onGetPhoneCodeList: () => dispatch(getPhoneCodeList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
