import styled from "styled-components";
import { FormLabel } from "react-bootstrap";

const TitlePage = styled(FormLabel)`
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  line-height: 27px;
  font-weight: ${(props) => props.fontWeight};
  padding-left: ${(props) => props.paddingleft};
  margin-bottom: 0;

  /* margin-left: 18.21px; */
  // margin-right: 20px;
  // top: 19.81px;

  @media only screen and (max-width: 480px) {
    font-size: 15px;
    margin-left: 0px;
    padding-left: 23px;
  }
`;

export default TitlePage;
