import { store } from "../redux/store";
import {
  USER_JWT_TOKEN_UPADATE,
} from "../redux/actions/types";
import {
  logout,
} from "../redux/actions/loginActions";
export const baseURL = window.location.host.includes('localhost') ? "https://dev-api-safely.andonix.com/api/v1" : "https://api-safely.andonix.com/api/v1";
const defaultConfig = {
  url: "",
  method: "get",
  baseURL: baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  body: null,
};

export const safelyApi = async (url, config = defaultConfig) => {
  const _baseURL = config.baseURL || defaultConfig.baseURL;
  let jwt = {};
  if (store.getState().login.isLoggingIn && !store.getState().login.jwtAccessToken) {
    store.dispatch(logout());
  }
  if (store.getState().login.jwtAccessToken) {
    jwt = {
      "Authorization": 'Bearer ' + store.getState().login.jwtAccessToken,
      "accesstokenidp": store.getState().login.accessToken
    }
  }
  const initRequest = {
    method: config.method || defaultConfig.method,
    headers: { ...defaultConfig.headers, ...config.headers, ...jwt },
    ...(config.body ? { body: JSON.stringify(config.body) } : {}),
  };
  try {
    const response = await fetch(_baseURL + url, initRequest);
    if (response.status === 401 && !store.getState().login.jwtRefreshToken) {
      store.dispatch(logout());
    }
    if (response.status === 401 && store.getState().login.jwtRefreshToken) {
      try {
        const responseToken = await fetch(_baseURL + '/login/refreshToken', {
          method: 'POST',
          headers: { ...defaultConfig.headers },
          body: JSON.stringify({
            "userId": store.getState().login.user.id,
            "refreshToken": store.getState().login.jwtRefreshToken
          })
        });
        if (responseToken.status === 200) {
          const content = await responseToken.json();
          store.dispatch({ type: USER_JWT_TOKEN_UPADATE, payload: content.data.accessToken });
          let newJwt = {
            "Authorization": 'Bearer ' + content.data.accessToken,
            "accesstokenidp": store.getState().login.accessToken
          }
          let newinitRequest = {
            method: config.method || defaultConfig.method,
            headers: { ...defaultConfig.headers, ...config.headers, ...newJwt },
            ...(config.body ? { body: JSON.stringify(config.body) } : {}),
          };
          try {
            const callBackResponse = await fetch(_baseURL + url, newinitRequest);
            return await callBackResponse.json();
          }
          catch (e) {
            return null;
          }
        }
        else {
          store.dispatch(logout());
        }
      }
      catch (e) {
        return null;
      }
    }
    if (response.status !== 401) {
      return await response.json();
    }
  } catch (e) {
    return null;
  }
};