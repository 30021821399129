import React from "react";
import { general } from "../../../../general/global";
import Switch from "../../wrapped-components/inputs/Switch";
import Label from "../../styled-components/general/Label"

const InputBoolean = ({
  contentStyle,
  value,
  label,
  required,
  onValueChange,
  disabled,
  id,
  contentClass,
  dictionary,
}) => {
  return (
    <div>
      <div className={contentClass} style={{ display: "flex", flexDirection: "row", marginBottom: "12px" }}>
        <Switch
          id={id}
          isOn={value}
          onChange={() => onValueChange(!value)}
          onColor={general.theme.primaryColor}
          offColor="#808080"
          width={38}
          height={22}
          handleDiameter={17}
        />
        <Label style={{ maxWidth: "80%", marginLeft: "8px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{label}</Label>
      </div>
      {required && (
        // <div style={{ marginTop: 5 }}>
        //   <Label style={{ fontSize: 10, color: general.theme.secondaryColor }}>
        //     *{dictionary.s("required")}
        //   </Label>
        // </div>
        <Label className="required">* {dictionary && dictionary.s("required")}</Label>
      )}
    </div>
  );
};

export default InputBoolean;