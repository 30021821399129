import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    :root {
        --top-input: calc(${({ theme }) => theme.label_font_size} + 8px);
    }

    body::-webkit-scrollbar {
        width: 5px;
    }

    /* ---------- Input ----------  */
    .input-container {
        position: relative;
        max-width: 500px;
        padding-top: var(--top-input);
    }

    input[type='search']::placeholder,
    input[type='text']::placeholder,
    input[type='password']::placeholder {
        opacity: 0.4;
    }

    .input-container input {
        position: relative;
        background: transparent;
        font-size: ${({ theme }) => theme.label_font_size};
        font-family: ${({ theme }) => theme.label_font_family};
        color: ${({ theme }) => theme.label_color};
    }

    .input-container .input-label {
        padding-left: 10px;
        position: absolute;
        left: 0;
        -webkit-transition: .3s;
        transition: .3s;
        opacity: 0.4;
        width: 100%;
    }

    .list-group-item .input-container .input-label {
        top: calc(var(--top-input) + 6px);
    }

    .input-wrapper .input-container .input-label {
        top: calc(50%);
    }

    .input-container .default-input:focus {
        border: 1px solid #4FA3FF !important;
    }

    .input-container input:focus~span {
        color: red;
    }

    .input-container input:focus~.input-label, 
    .input-container .top {
        top: 0 !important;
        opacity: 1;
        padding-left: 0;
    }

    /*  ---------- Input edition mode ----------    */
    .edition-input {
        border: none;
        background-color: transparent;
        outline: none;
        border-bottom: 1px solid #9a9b9e;
        width: 90%;
        color: #334856;
        margin-bottom: 10px;
        border-radius: 0;
        top: calc(50%);
    }

    .edition-input:focus {
        border: none;
        border-bottom: 2px solid #9a9b9e;
        background-color: transparent;
    }

    /*  ---------- Input search ----------  */
    .input-search-container .input-container {
        padding-top: 0;
    }

    /*  ---------- Avatar ----------    */
    .avatar-circle-small {
        border-radius: 50% !important;
        text-align: center;
        color: #ffffff;
        font-size: 12px;
        font-weight: bold;
        height: 40px !important;
        width: 40px !important;
        text-transform: uppercase;
        display: inline-block;
    }

    /*  ---------- InputButton styles ----------    */
    .text-container .input-container .input-label {
        top: calc(50%);
    }

    .text-container .input-container input:focus~.input-label,
    .text-container .input-container .top {
        font-size: 14px;
        top: 6px !important;
    }

    /*  ---------- MiltipleChoice styles ----------    */
    .option-container {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-vertical: 5px;
    }

    .option-radio {
        display: flex;
        width: 14px;
        height: 14px;
        border-width: 1px;
        border-color: ${({ theme }) => theme.colorPalette.PRIMARY};
        border-radius: 14px;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        border-style: solid;
        cursor: pointer;
    }

    .option-radio-dot {
        width: 8px;
        height: 8px;
        background-color: ${({ theme }) => theme.colorPalette.PRIMARY};
        border-radius: 8px;
    }

    /*  ---------- General classes ----------   */
    .dotted-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .required {
        margin-top: 5px;
        color: #E67070 !important;
        font-weight: 600;
        font-size: 12px !important;
        text-align: left;
        opacity: 1 !important;
        margin-left: 0 !important;
    }

    .border-radius-none {
        color: #665dff;
        border: 1px solid #665dff;
        border-radius: 4px;
        opacity: 1;
        background: #fff;
        width: 120px;
    }
      
    .border-radius-none:hover {
        
        border: 1px solid #665dff;
        border-radius: 4px;
        opacity: 1;
        background: #fff;
        width: 120px;
    }

    .heading_1 {
        color: ${({ theme }) => theme.heading_1.color};
        font-size: ${({ theme }) => theme.heading_1.font_size};
        font-family: ${({ theme }) => theme.heading_1.font_family};
        font-weight: ${({ theme }) => theme.heading_1.font_weight};
    }

    .bold {
        font-weight: 600;
    }

    .flex-end {
        display: flex;
        justify-content: flex-end;
    }

    /*  ---------- modal    ---------- */
    .modal-content {
        border-radius: 10px;
    }

    .modal-header {
        border-bottom: none;
        padding: 15px 15px 0px;
    }

    .modal-body {
        padding: 1rem 15px;
    }

    .modal-footer {
        border-top: 0;
        padding: 1rem 15px 15px 15px;
    }

    /*  ---------- Selfdeclaration, Verification forms ----------   */
    .padding-container {
        padding: 40px;
    }

    .edit-form-header {
        text-align: left;
        font-size: 18px;
        font-weight: 600;
        font-family: "Montserrat", Serif;
        letter-spacing: 0px;
        color: #2B2B42;
        opacity: 1;
    }

    .quill-editor-div {
        padding-top: 20px;
    }

    .input-list-wrapper {
        flex: 1 1;
        flex-direction: row;
        justify-content: space-around;
        align-content: center;
        margin-bottom: 10px;
    }

    .input-wrapper {
        width: 80%;
        height: 100%;
        background-color: #f2f5fe;
        margin: 15px 0;
        padding: 20px;
        border-width: 1px;
        border-radius: 4px;
        border-style: dashed;
        border-color: #b7c6e8;
    }

    .delete-icon, 
    .tablet-delete-icon,
    .phone-delete-icon
    {
        font-size: 20px;
        margin-right: 12%;
        cursor: pointer;
        float: right;
        margin-top: 0;
        text-align: center;
        color: #e67070;                           
    }

    .tablet-delete-icon {
        margin-right: 10%;
    }

    .phone-delete-icon {
        margin-right: 6%;
    }

    .add-input {
        color: #4FA3FF;
        font-size: 14px;
        font-weight: 400;
        margin-right: 17%;
        cursor: pointer;
    }

    @media screen and (max-width: 480px) and (orientation: landscape) {
        .input-wrapper {
            width: 95%;
        }
        
        .delete-icon, 
        .tablet-delete-icon,
        .phone-delete-icon {
            margin-right: 0;
        }
    }

    @media screen and (max-width: 480px) and (orientation: portrait) {
        .input-wrapper {
            width: 90%;
        }

        .delete-icon, 
        .tablet-delete-icon,
        .phone-delete-icon {
            margin-right: 0;
        }
    }

    /*  ---------- contentMarkdown ----------   */
    .text-content {
        margin-bottom: 30px;
    }

    .text-content-pre {
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
    }

    /*  ---------- Verification form ---------- */
    .editcheckpoint .user-profile {
        min-height: 115px;
    }

    /*  ---------- TimeLine ----------  */
    .timeLine .list-group-item {
        border: none;
        padding: 5px 0;
    }

    /*  ---------- Icons ---------- */
    .cursor-pointer {
        cursor: pointer;
    }

    /*  ---------- Edition Icon ----------  */
    .EditionDiv>svg .a {
        fill: #665dff;
    }

    .EditionDiv {
        cursor: pointer;
    }


    /*  ---------- Workspace Disable Modal ----------  */
    .workspace-disable-content { }

    .workspace-disable-title {
        display: flex;
        align-items: center;
    }

    .workspace-disable-title .heading_1 {
        margin-left: 20px;
    }

    .workspace-disable-body {
        display: flex;
        margin-left: 60px;
    }

    .workspace-disable-body span {
        font-size: 17px;
        color: #6E6E7C;
    }

    /*  ---------- Transactions ----------  */
    .transactions-options > div{
        padding-top: 20px;
    }

    .transaction-item {
        display: flex;
        flex-direction: row;
    }

    .transaction-viewer {
        width: 40px;
        height: 40px;
        background-color: #d9d9d9;
    }

    .transaction-name {
        font-size: 14px;
        font-weight: bold;
        color: #5A5A5A;
    }

    .transaction-details {
        padding-left: 10px;
        display: flex;
        flex-direction: column;
    }

    .transaction-actions {
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
    }

    .transaction-upload {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 70px 0;
    }

    .transaction-upload > label {
        padding-top: 10px;
        color: #8F96AA;
    }

    .content-upload {
        border: 1px solid #BABABA;
        background-color: #FAFAFA;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        position: relative;
    }

    .upload-icon {
        background-color: #BABABA;
        width: 47px;
        height: 47px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin: 30px 0 0;
    }

    .content-upload > button {
        margin: 25px 21px;
        width: 178px;
    }

    .uploading-pdf {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        // background: red 0% 0% no-repeat padding-box;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        opacity: 0.9;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .uploading-pdf-items {
        display: flex;
        align-items: flex-end;
    }

    .uploading-pdf-items * {
        color: #665DFF;
    }

    .uploading-pdf-items i {
        font-size: 25px;
    }

    .uploading-pdf-items label {
        font-size: 13px;
        padding-left: 10px;
    }

    .content-visualizer {
        padding: 20px 20px 10px 20px;
    }

    .close-visualizer {
        font-size: 25px;
        color: #E67070;
        position: absolute;
        right: -10px;
        top: -10px;
        background: #ffffff;
        cursor: pointer;
    }

    .content-visualizer > div {
        border: 1px solid #BABABA;
    }

    .content-visualizer > div > div {
        filter: blur(2px);
    }

    .content-file-name {
        border-top: 1px solid #bababa;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .content-file-name label {
        margin: 5px;
    }
    
`;