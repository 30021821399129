import React from "react";
import ModalImage from "react-modal-image";

const ImagePerview = props => {
  return (
    <div>
      <ModalImage
        small={props.image}
        large={props.image}
        hideDownload={true}
        hideZoom={true}
        showRotate={false}
        className="user-pic-reports"
      />
    </div>
  );
};

export default ImagePerview;
