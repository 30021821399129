import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import Modal from "../commons/wrapped-components/Modal";
import { translate } from "../../redux/reducers/languageReducer";
import { general } from "../../general/global";
import Multiselect from "../commons/multiselect";
import {
  updateCreateSubSpaceMember,
  addMemberSubWorkspace, getUserListByWorkspaceId, getUserListBySubspaceId, getSubspacesByUserIdWorkSpaceId
} from "../../redux/actions/workspaceActions";
import * as roles from "../../constants/roles";
import { showToast } from "../commons/Toast";
import * as toastType from "../../constants/toastTypes";

const AddMemberPopUP = props => {
  const [erroMessage, setMessage] = useState("");
  const [addMember, setAddMember] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [buttonAdd, setButtonAdd] = useState(false);

  const loadData = async () => {
    await props.onGetUserListByWorkspaceId(props.accesstokenidp, props.workSpaceId);
  }

  useEffect(() => {
    loadData();
  }, []);

  let dataValue = [];

  const filterUsers = () => {
    let users = [];
    if (props.workspaceUsers.length) {
      users = (props.type === 'addMember') ?
        props.workspaceUsers.filter((person) => !props.subspaceUsers?.users.some(user => user.id === person.id)) :
        props.workspaceUsers.filter((person) => person.roleId !== roles.ADMIN);
    }
    return users;
  }

  dataValue = useMemo(() => filterUsers(), [props.type, props.workspaceUsers, props.subspaceUsers?.users]);

  const result = params => {
    setAddMember(params);
    if (params.length > 0) {
      setButtonAdd(true);
    }
    else {
      setButtonAdd(false);
    }
  };
  const SubWorkspaceAddMember = () => (
    <Modal
      title={props.dictionary.s("addMembers")}
      dialogClassName="safely-modal renderModalCreatSubSpace"
      showModal={props.showAddMembers}
      onHide={() => {
        if (!buttonDisable) {
          props.closeAddmember();
        }
      }}
      onSave={async () => {
        if (addMember.length > 0 && !buttonDisable) {
          setButtonDisable(true);
          props.onAddMemberSubWorkspace(addMember, props.workSpaceId, props.subspaceFocused._id).then((resultData) => {
            props.onUpdateCreateSubSpaceMember(resultData.data, props.workSpacesProps, props.subspaceFocused._id);
            setButtonDisable(false);
            props.closeAddmember();
          }).catch(error => {
            setMessage(error.message);
            setButtonDisable(false);
          });
          setButtonAdd(false);
          if (props.type === 'addMember')
            await props.onGetUserListBySubspaceId('');
          else {
            showToast(toastType.SUCCESS, props.dictionary.s('subspaceCreated'));
            await props.onGetSubspacesByUserIdWorkSpaceId('');
          }
        }
      }}
      onClose={() => {
        if (!buttonDisable) {
          props.closeAddmember();
          setButtonAdd(false);
        }
        if (props.type === 'subWorkSpace')
          showToast(toastType.SUCCESS, props.dictionary.s('subspaceCreated'));
      }}
      textButtonSave={props.loading ? "..." : props.dictionary.s("done")}
      textButtonClose={props.type === 'subWorkSpace' ? props.dictionary.s("skip") : props.dictionary.s("cancel")}
      propertyButtonSave={{
        block: false,
        style: {
          backgroundColor: buttonAdd ? general.theme.primaryColor : general.theme.grayMedium,
          color: general.theme.whiteFontColor,
          border: buttonAdd ? `1px solid ${general.theme.primaryColor}` : general.theme.grayMedium
        }
      }}
      bsStyleButtonSave="secondary"
      buttonCloseProperties={{
        block: false,
        style: {
          backgroundColor: general.theme.whiteColor,
          border: `1px solid ${general.theme.primaryColor}`,
          color: general.theme.primaryColor
        }
      }}
      properties={{
        centered: true
      }}
      body={
        <>
          <Multiselect
            options={dataValue}
            onSelectOptions={result}
            autocomplete="off"
            placeholder={props.dictionary.s("searchbyname")}
            name="name"
            lastName="lastName"
            value="id"
          />
          {(props.workspaceMessage || erroMessage) && (
            <div
              className="label-div"
              style={{
                marginHorizontal: 20,
                marginVertical: 10,
                textAlign: "center",
                color: general.theme.redColor
              }}
            >
              <label>{props.message || erroMessage}</label>
            </div>
          )}
          {dataValue.length === 0 ?
            <div
              className="label-div"
              style={{
                marginHorizontal: 20,
                marginVertical: 10,
                textAlign: "center",
                color: general.theme.redColor
              }}
            >
              <label>{props.dictionary.s("noResultFound")}</label>
            </div> : null}
        </>
      }
      isLoadingButton={props.loading}
    />
  );

  return <>{SubWorkspaceAddMember()}</>;
};

const mapStateToProps = state => {
  const user = state.user;
  return {
    userId: user.id,
    workSpacesProps: state.workspace,
    workspaces: state.workspace.spacesListWithPagination.spaces,
    workSpaceId: state.workspace.spaceFocused._id,
    workSpaceName: state.workspace.spaceFocused.name,
    subspaceFocused: state.workspace.subspaceFocused,
    accesstokenidp: state.user.accesstokenidp,
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    },
    workspaceUsers: state.workspace.workspaceUsers,
    subspaceUsers: state.workspace.subspaceUsers,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateCreateSubSpaceMember: (data, workSpacesProps, subspaceId) => dispatch(updateCreateSubSpaceMember(data, workSpacesProps, subspaceId)),
  onAddMemberSubWorkspace: (userIds, workSpaceId, subspaceId) => dispatch(addMemberSubWorkspace(userIds, workSpaceId, subspaceId)),
  onGetUserListByWorkspaceId: (accesstokenidp, workSpaceId) => dispatch(getUserListByWorkspaceId(accesstokenidp, workSpaceId)),
  onGetUserListBySubspaceId: (name) =>
    dispatch(getUserListBySubspaceId(name)),
  onGetSubspacesByUserIdWorkSpaceId: (name) =>
    dispatch(getSubspacesByUserIdWorkSpaceId(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMemberPopUP);
