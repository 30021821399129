import React from "react";
import { ReactComponent as FaceBookIcon } from '../../icons/facebook.svg';
import { ReactComponent as MicroSoftIcon } from '../../icons/microsoft.svg';
import { ReactComponent as GoogleIcon } from '../../icons/google.svg';
import { ReactComponent as AppleIcon } from '../../icons/apple.svg';
import credentials from "../../auth.credentials.develop";

const Socialicons = () => {
    return (
        <div className="signup-icons">
            {/* <div className="round-icon">
                <a href={`https://tripi-dev.auth0.com/authorize?response_type=token&client_id=${credentials.clientId}&connection=facebook&redirect_uri=${credentials.urlCallback}`}>
                    <FaceBookIcon />
                </a>
            </div> */}
            <div className="round-icon">
                <a href={`https://tripi-dev.auth0.com/authorize?response_type=token&client_id=${credentials.clientId}&connection=google-oauth2&redirect_uri=${credentials.urlCallback}`}>
                    <GoogleIcon />
                </a>
            </div>
            {/* <div className="round-icon">
                <a href={`https://tripi-dev.auth0.com/authorize?response_type=token&client_id=${credentials.clientId}&connection=windowslive&redirect_uri=${credentials.urlCallback}`}>
                    <MicroSoftIcon />
                </a>
            </div> */}
            <div className="round-icon">
                <a href={`https://tripi-dev.auth0.com/authorize?response_type=token&client_id=${credentials.clientId}&connection=apple&redirect_uri=${credentials.urlCallback}`}>
                    <AppleIcon />
                </a>
            </div>
        </div>
    );
}

export default Socialicons;