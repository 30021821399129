import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/main-navigation.css";
import "./styles/login.css";
import "./styles/dashboard.css";
import "./styles/terms-and-conditions.css";
import "./styles/general.css";
import "./styles/userManagement.css";
import "./styles/reports.css";
import "./styles/createWorkSpace.css";
import "./styles/signUp.css";
import "./styles/welcome.css";
import "./styles/setWorkspace.css";
import "./styles/forgotPassword.css";
import "./styles/verifyEmailOrMobile.css";
import "./styles/safelyPass.css";
import "./styles/checkinuser.css";
import "./styles/editVerificationForm.css";
import "./styles/editUserVerification.css";
import "./styles/post.css";
import "./styles/marketPlace.css";
import "./styles//assignTo.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error:", err);
    });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
