import {
  OPERATION_USER_VERIFICATION_POST,
  OPERATION_USER_VERIFICATION_POST_SUCCESS,
  OPERATION_USER_VERIFICATION_POST_FAIL,
  OPERATION_CHECK_POINT_POST,
  OPERATION_CHECK_POINT_POST_SUCCESS,
  OPERATION_CHECK_POINT_POST_FAIL,
  OPERATION_LAST_VERIFIED_GET,
  OPERATION_LAST_VERIFIED_GET_SUCCESS,
  OPERATION_LAST_VERIFIED_GET_FAIL,
  OPERATION_USER_VERIFICATION_CHANGE_INPUT,
  OPERATION_USER_VERIFICATION_CHANGE_COMMENTS,
  OPERATION_CHECK_POINT_CHANGE_INPUT,
  OPERATION_CHECK_POINT_CHANGE_COMMENTS,
  OPERATION_SET_USER_VERIFICATION,
  OPERATION_SET_CHECK_POINT,
  OPERATION_SET_MESSAGE,
  OPERATION_RESET,
  OPERATION_CHANGE_SAFELY_PASS_STATUS,
  OPERATION_ACCESS_AUTHORIZATION_SUCCESS,
  OPERATION_ACCESS_AUTHORIZATION_FAIL,
  OPERATION_CHECK_OUT,
  OPERATION_ACCESS_AUTHORIZATION_ACTIVES_LIST_REQUEST,
  OPERATION_ACCESS_AUTHORIZATION_ACTIVES_LIST_SUCCESS,
  OPERATION_ACCESS_AUTHORIZATION_DENIED_REQUEST,
  OPERATION_ACCESS_AUTHORIZATION_DENIED_SUCCESS,
  OPERATION_ACCESS_AUTHORIZATION_DENIED_FAIL,
  OPERATION_ACCESS_AUTHORIZATION_REQUEST,
  OPERATION_ACCESS_AUTHORIZATION_GRANT_REQUEST,
  OPERATION_ACCESS_AUTHORIZATION_GRANT_FAIL,
  OPERATION_ACCESS_AUTHORIZATION_GRANT_SUCCESS,
  OPERATION_ACCESS_AUTHORIZATION_REQUEST_SUCCESS,
  OPERATION_ACCESS_AUTHORIZATION_REQUEST_FAIL,
  OPERATION_CHECK_OUT_FAIL,
  OPERATION_GET_CHECKOUT_TIME_REQUEST,
  OPERATION_GET_CHECKOUT_TIME_SUCCESS,
  OPERATION_GET_CHECKOUT_TIME_FAIL,
  OPERATION_REFRESH_CHECK_IN_REGISTER_SCREEN,
  OPERATION_ACCESS_AUTHORIZATION_ACTIVE_LIST_REQUEST,
  OPERATION_ACCESS_AUTHORIZATION_ACTIVES_LIST_FAIL,
  OPERATION_GET_PAGINATED_USERS_SUCCESS,
  OPERATION_GET_PAGINATED_USERS_FAILURE,
  OPERATION_GET_PAGINATED_USERS_REQUEST,
  OPERATION_GET_USERS_FOR_CHECKPOINT,
  OPERATION_GET_USERS_FOR_CHECKPOINT_SUCCESS,
  OPERATION_GET_USERS_FOR_CHECKPOINT_FAIL,
  OPERATION_SET_ACTIVE_KEY,
  OPERATION_SET_CHECK_IN_BY_CHECKPOINT
} from "../actions/types";

export const initialState = {
  userId: null,
  workSpaceId: null,
  userProfile: {},
  userVerificationId: "",
  userVerificationInputs: [],
  userVerificationComments: "",
  checkPointInputs: [],
  checkPointComments: "",
  message: null,
  loading: false,
  safelyPassGrantedDate: null,
  safelyPassStatus: 'inactive',
  activesList: [],
  checkoutTime: [],
  userVerification: {
    workSpaceId: null,
    // safelyPassStatus: 'inactive',
  },
  requestAccessAuthorizationId: null,
  accessAuthorizationId: null,
  refreshCheckInRegisterScreen: false,
  loadingGetActivesList: false,
  messageGetActivesList: null,
  workSpaceIdSafelyPassActive: null,
  paginatedUsers: [],
  totalUsers: 0,
  checkpointUsers: {
    users: [],
    hasNextPage: false,
    totalCount: 0,
    totalPages: 0,
    limit: 10,
    currentPage: 1
  },
  activeKey: null,
  checkInByCheckpoint: false
};

export default (state, action) => {
  const { type, payload } = action;
  if (typeof state === "undefined") {
    return initialState;
  }
  switch (type) {
    case OPERATION_SET_USER_VERIFICATION:
      return {
        ...state,
        userId: payload.userId,
        userVerification: { workSpaceId: payload.workSpaceId },
        // workSpaceId: payload.workSpaceId,
        userVerificationInputs: JSON.parse(JSON.stringify(payload.inputs)),
        message: null,
        // safelyPassStatus: 'inactive',
      };
    case OPERATION_USER_VERIFICATION_CHANGE_INPUT: {
      const inputs = [...state.userVerificationInputs];
      const input = inputs.find((x) => x._id === payload.id);
      if (input) input.value = payload.value;
      return { ...state, userVerificationInputs: inputs };
    }
    case OPERATION_USER_VERIFICATION_CHANGE_COMMENTS:
      return { ...state, userVerificationComments: payload };
    case OPERATION_SET_CHECK_POINT:
      return {
        ...state,
        userId: payload.userId,
        workSpaceId: payload.workSpaceId,
        checkPointInputs: JSON.parse(JSON.stringify(payload.inputs)),
        userProfile: payload.userProfile,
        userVerificationId: payload.userVerificationId,
        message: null,
      };
    case OPERATION_CHECK_POINT_CHANGE_INPUT: {
      const inputs = [...state.checkPointInputs];
      const input = inputs.find((x) => x._id === payload.id);
      if (input) input.value = payload.value;
      return { ...state, checkPointInputs: inputs };
    }
    case OPERATION_CHECK_POINT_CHANGE_COMMENTS:
      return { ...state, checkPointComments: payload };
    case OPERATION_USER_VERIFICATION_POST:
    case OPERATION_CHECK_POINT_POST:
    case OPERATION_LAST_VERIFIED_GET:
    case OPERATION_ACCESS_AUTHORIZATION_REQUEST:
    case OPERATION_ACCESS_AUTHORIZATION_GRANT_REQUEST:
    case OPERATION_ACCESS_AUTHORIZATION_DENIED_REQUEST:
    case OPERATION_GET_PAGINATED_USERS_REQUEST:
    case OPERATION_GET_CHECKOUT_TIME_REQUEST:
    case OPERATION_GET_USERS_FOR_CHECKPOINT:
      return { ...state, loading: true };
    case OPERATION_USER_VERIFICATION_POST_SUCCESS:
      return {
        ...state,
        ...initialState,
        loading: false,
        userVerificationComments: "",
        userVerificationInputs: state.userVerificationInputs,
        safelyPassStatus: state.safelyPassStatus,
      };
    case OPERATION_CHECK_POINT_POST_SUCCESS:
    case OPERATION_ACCESS_AUTHORIZATION_GRANT_SUCCESS:
    case OPERATION_ACCESS_AUTHORIZATION_DENIED_SUCCESS:
      return { ...state, loading: false };
    case OPERATION_ACCESS_AUTHORIZATION_REQUEST_SUCCESS:
    case OPERATION_ACCESS_AUTHORIZATION_SUCCESS:
    case OPERATION_LAST_VERIFIED_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        userId: payload.data.userId,
        workSpaceId: payload.data.workSpaceId,
        requestAccessAuthorizationId: payload.data._id
      };
    case OPERATION_ACCESS_AUTHORIZATION_REQUEST_FAIL:
    case OPERATION_USER_VERIFICATION_POST_FAIL:
    case OPERATION_CHECK_POINT_POST_FAIL:
    case OPERATION_LAST_VERIFIED_GET_FAIL:
    case OPERATION_ACCESS_AUTHORIZATION_FAIL:
    case OPERATION_ACCESS_AUTHORIZATION_GRANT_FAIL:
    case OPERATION_ACCESS_AUTHORIZATION_DENIED_FAIL:
    case OPERATION_GET_PAGINATED_USERS_FAILURE:
    case OPERATION_GET_CHECKOUT_TIME_FAIL:
    case OPERATION_GET_USERS_FOR_CHECKPOINT_FAIL:
      return { ...state, message: payload.message, loading: false };
    case OPERATION_SET_MESSAGE:
      return { ...state, message: null };
    case OPERATION_RESET:
      return { ...state, ...initialState };
    case OPERATION_CHANGE_SAFELY_PASS_STATUS:
      const { grantDate, _id, workSpaceId } = payload.extraInfo;
      return { ...state, safelyPassStatus: payload.status, safelyPassGrantedDate: grantDate || null, accessAuthorizationId: _id, workSpaceIdSafelyPassActive: workSpaceId };
    case OPERATION_CHECK_OUT_FAIL:
    case OPERATION_CHECK_OUT:
      return { ...state, safelyPassStatus: 'inactive', requestAccessAuthorizationId: null, safelyPassGrantedDate: null, workSpaceIdSafelyPassActive: null };
    case OPERATION_ACCESS_AUTHORIZATION_ACTIVE_LIST_REQUEST:
      return { ...state, activesList: payload.data };
    case OPERATION_ACCESS_AUTHORIZATION_ACTIVES_LIST_REQUEST:
      return { ...state, loadingGetActivesList: true };
    case OPERATION_ACCESS_AUTHORIZATION_ACTIVES_LIST_SUCCESS:
      return { ...state, loadingGetActivesList: false, activesList: payload.data };
    case OPERATION_ACCESS_AUTHORIZATION_ACTIVES_LIST_FAIL:
      return { ...state, loadingGetActivesList: false, messageGetActivesList: payload.message };
    case OPERATION_GET_CHECKOUT_TIME_SUCCESS:
      return { ...state, loading: false, checkoutTime: payload.data };
    case OPERATION_REFRESH_CHECK_IN_REGISTER_SCREEN:
      return { ...state, refreshCheckInRegisterScreen: payload.refresh };
    case OPERATION_GET_PAGINATED_USERS_SUCCESS:
      return { ...state, loading: false, paginatedUsers: payload.paginatedUsers, totalUsers: payload.totalCount };
    case OPERATION_GET_USERS_FOR_CHECKPOINT_SUCCESS: {
      const { data: users, hasNextPage, limit, totalCount, totalPages } = payload;
      const newList = {
        users,
        hasNextPage,
        limit,
        totalCount,
        totalPages
      }
      return { ...state, checkpointUsers: newList, totalUsers: payload.totalCount, loading: false };
    }
    case OPERATION_SET_ACTIVE_KEY:
      return { ...state, activeKey: payload }
    case OPERATION_SET_CHECK_IN_BY_CHECKPOINT:
      return { ...state, checkInByCheckpoint: payload }
    default:
      return state;
  }
};