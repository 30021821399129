import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "../../redux/reducers/languageReducer";
import { getUserListByWorkspaceId, workSpaceUploadImage } from "../../redux/actions/workspaceActions";
import moment from "moment";
import "moment/locale/es";
import {
  updateWorkSpaceImage,
  renameWorkspace,
  updateWorkSpaceName
} from "../../redux/actions/workspaceActions";
import { general } from "../../general/global";
import * as roles from "../../constants/roles";
import { Button } from "react-bootstrap";
import { ReactComponent as CameraIcon } from "../../icons/cameraIcon.svg";
import { ReactComponent as DownloadIcon } from "../../icons/Icon_Download.svg";
import UploadORTakeImageCopper from "../commons/wrapped-components/inputs/uploadORTakeImageCopper";
import {
  spinnerPost,
  postViewData,
  resetProgress
} from "../../redux/actions/postAction";
import QRCode from "../commons/wrapped-components/QRCode";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import WorkspacePreferences from "./WorkspacePreferences";
import DeleteSpaceImage from "./deleteSpaceImage";
import { ReactComponent as DeleteIcon } from "../../icons/deletered.svg";
import { ReactComponent as LeaveIcon } from "../../icons/icon_leavespace.svg";

import DeleteSpace from "./DeleteSpace";
import LeaveSpace from "./LeaveSpace";

const WorkspaceInfo = props => {
  const [showWorkNameEdit, setShowWorkNameEdit] = useState(false);
  const [deviceType, setdeviceType] = useState(window.deviceType);
  const [showimageEditView, setShowimageEditView] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [erroMessage, setMessage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [changedWorkspaceName, setChangedWorkspaceName] = useState(
    props.spaceFocused.name
  );
  const [showDeleteSpacePopup, setShowDeleteSpacePopup] = useState(false);
  const [showLeaveSpacePopup, setShowLeaveSpacePopup] = useState(false);

  const loadData = async () => {
    console.log('load users with this data:', props.accesstokenidp, props.spaceFocused._id)
    console.log('workspace users:', props.workspaceUsers)
    await props.onGetUserListByWorkspaceId(props.accesstokenidp, props.spaceFocused._id)
  }

  useEffect(() => {
    moment.locale(props.lang);
    function handleResize() {
      setdeviceType(window.deviceType);
    }
    loadData();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const getName = id => {

    let optionsValues = props.workspaceUsers.filter(
      person => person.id === id
    );
    if (optionsValues.length > 0) {
      return optionsValues[0].name + " " + optionsValues[0].lastName;
    } else {
      return "";
    }
  };
  const dataURLToBlob = dataURL => {
    let BASE64_MARKER = ";base64,";
    let parts;
    let contentType;
    let raw;
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      parts = dataURL.split(",");
      contentType = parts[0].split(":")[1];
      raw = decodeURIComponent(parts[1]);

      return new Blob([raw], { type: contentType });
    }
    parts = dataURL.split(BASE64_MARKER);
    contentType = parts[0].split(":")[1];
    raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  };
  const download = () => {
    html2canvas(document.querySelector("#qrCodeGenarator"), {
      onclone: function (document) {
        document.querySelector("#spaceName").style.display = "block";
        if (deviceType === "desktop") {
          document.querySelector("#qrCodeGenarator").style.marginTop = "50px";
        } else {
          document.querySelector("#qrCodeGenarator").style.marginTop = "120px";
        }
      },
      height: 750
    }).then(function (canvas) {
      if (!navigator.userAgent.match("CriOS")) {
        if (deviceType === "desktop") {
          var imgData = canvas.toDataURL("image/png");
          var doc = new jsPDF("p", "mm", "a4");
          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();
          doc.addImage(imgData, "PNG", 0, 0, width, height);
          doc.save(props.spaceFocused.name + "QRcode.pdf");
        } else {
          const fileBlob = dataURLToBlob(canvas.toDataURL("image/jpeg"));
          saveAs(fileBlob, props.spaceFocused.name + "QRcode.jpg");
        }
      } else {
        canvas.toBlob(function (blob) {
          let url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
        });
      }
    });
  };

  const renameChange = value => {
    setChangedWorkspaceName(value);
    setMessage("");
  };
  const onSaveRename = async () => {
    setShowWorkNameEdit(!showWorkNameEdit);
    if (changedWorkspaceName !== "") {
      const workspaceName = changedWorkspaceName.trim();
      if (workspaceName.length > 0) {
        const found = props.workspacesList.find(
          x => x.name.toLowerCase() === workspaceName.toLowerCase()
        );
        if (found) {
          setMessage(props.dictionary.s("theWorkspaceNameAlreadyExists"));
        } else {
          setShowSpinner(true);
          const response = await props.onRenameWorkspace(
            props.spaceFocused._id,
            workspaceName
          );
          if (response.status) {
            props.onUdateWorkSpaceName(response.data, props.workSpacesProps);
            setShowSpinner(false);
          } else {
            setShowSpinner(false);
            setMessage(props.workspaceMessage);
          }
        }
      } else {
        setMessage(props.dictionary.s("theWorkspaceNameIsInvalid"));
      }
    } else {
      setMessage(props.dictionary.s("theWorkspaceNameIsInvalid"));
    }
  };
  const spinnerdiv = () => (
    <div className="add-new-post-col">
      <div className="loader-container-spinner">
        <div className="spinner-loader">
          <div className="spinner-border" role="status"></div>
        </div>
      </div>
    </div>
  );
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };
  return (
    <div>
      <DeleteSpaceImage
        showDeleteModal={showDeleteModal}
        closeDeleteModal={closeDeleteModal}
      />
      <div className="space-image-block">
        {props.spaceFocused.picture ? (
          <img src={props.spaceFocused.picture} alt="space" />
        ) : (
            <div
              className="noSpaceImage-spaceInfo"
              style={{
                backgroundColor: props.spaceFocused.settings.backgroundColor
              }}
            >
              {props.spaceFocused.name.substring(0, 2)}
            </div>
          )}
        {props.spaceFocused.roleId ===
          roles.ADMIN ? (
            <div className="space-image-edit">
              <UploadORTakeImageCopper
                imagePreviewURL=""
                showUploadORTakeImage={showimageEditView}
                colseUploadORTakeImage={() => {
                  setShowimageEditView(false);
                }}
                onScreenShot={async (file, imagePreviewUrl) => {
                  props.onresetProgress(1);
                  props.onspinnerPost(true);
                  await props
                    .onworkSpaceUploadImage(
                      props.accesstokenidp,
                      props.userId,
                      props.spaceFocused._id,
                      imagePreviewUrl,
                      props.spaceFocused.picture
                    )
                    .then(responce => {
                      props.onUdateWorkSpaceImage(
                        responce.data,
                        props.workSpacesProps
                      );
                      setShowimageEditView(false);
                      props.onresetProgress(0);
                      props.onspinnerPost(false);
                    });
                }}
              />
              {props.spaceFocused.picture ? (
                <div
                  className="space-name-delete-circle dispalyLine"
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                >
                  <i className="material-icons delete-image-icon">close</i>
                </div>
              ) : null}
              <div
                className="space-name-edit-cricle dispalyLine"
                onClick={() => {
                  setShowimageEditView(true);
                }}
              >
                <CameraIcon />
              </div>
            </div>
          ) : null}
      </div>
      <div className="space-name-edit-block">
        <div className="dispalyLine">
          <div className="space-name-edit-name">
            {showSpinner ? spinnerdiv() : null}
            {showWorkNameEdit ? (
              <input
                type="text"
                name="name"
                maxLength="30"
                className="space-name-edit-name-input"
                onChange={e => {
                  renameChange(e.target.value);
                }}
                value={changedWorkspaceName}
                onKeyPress={event => {
                  let regex = new RegExp("[0-9A-Za-z\u00C0-\u017F ]+$");
                  let key = String.fromCharCode(
                    !event.charCode ? event.which : event.charCode
                  );
                  if (!regex.test(key)) {
                    event.preventDefault();
                    return false;
                  }
                }}
              />
            ) : !showSpinner ? (
              props.spaceFocused.name
            ) : (
                  ""
                )}
          </div>
          {erroMessage && (
            <div
              className="label-div"
              style={{
                marginHorizontal: 20,
                marginVertical: 10,
                textAlign: "center",
                width: "100%",
                paddingLeft: 10,
                color: general.theme.redColor
              }}
            >
              <label>{erroMessage}</label>
            </div>
          )}
        </div>
        <div className="dispalyLine space-name-edit">
          {showWorkNameEdit ? (
            <div>
              <div
                className="space-name-edit-cricle dispalyLine"
                onClick={() => {
                  setMessage("");
                  setChangedWorkspaceName(props.spaceFocused.name);
                  setShowWorkNameEdit(!showWorkNameEdit);
                }}
              >
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
              <div
                className="space-name-edit-cricle dispalyLine"
                onClick={() => {
                  onSaveRename();
                }}
              >
                <i className="fa fa-check" aria-hidden="true"></i>
              </div>
            </div>
          ) : props.spaceFocused.roleId ===
            roles.ADMIN ? (
                <div
                  className="space-name-edit-cricle dispalyLine"
                  onClick={() => {
                    setMessage("");
                    setChangedWorkspaceName(props.spaceFocused.name);
                    setShowWorkNameEdit(!showWorkNameEdit);
                  }}
                >
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                </div>
              ) : null}
        </div>

        <div className="space-name-edit-ownerdeatils">
          {props.dictionary.s("createBy") + " "}
          {getName(props.spaceFocused.spaceOwner)},
          {" " +
            moment(props.spaceFocused.createdDate)
              .format(props.lang === "en" ? "MMM DD YYYY" : "DD MMM YYYY")
              .replace(".", "")}
        </div>
      </div>
      <div className="space-qr-code-block">
        <div className="space-qr-code-block-header">
          <div className="space-qr-code-name">
            {props.dictionary.s("spaceQRCode")}
          </div>
          <DownloadIcon
            className="space-qr-code-download"
            onClick={() => {
              download();
            }}
          />
        </div>
        <div className="space-qr-code-container" id="qrCodeGenarator">
          <div
            id="spaceName"
            style={{
              marginBottom: 10,
              display: "none",
              fontWeight: "bold"
            }}
          >
            {props.spaceFocused.name}
          </div>
          <div
            className="Box-containerQR"
            style={{
              border:
                "1px solid " + props.spaceFocused.settings.backgroundColor
            }}
          >
            <div
              className="containerQR"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center"
              }}
            >
              <em></em>
              <QRCode
                value={
                  "space||" +
                  props.spaceFocused._id +
                  "||" +
                  props.spaceFocused.name
                }
                styles={{ borderRadius: "10px", margin: "0px", opacity: 1 }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="space-view-block">
        <Button
          className="view-space-button"
          onClick={() => {
            props.history.push("/subWorkspacelist");
          }}
        >
          {props.dictionary.s("viewSpace")}
        </Button>
      </div>
      {
        props.spaceFocused.roleId ===
          roles.ADMIN ? (
            <div className="space-preferences-block">
              <WorkspacePreferences />
            </div>
          ) : null
      }
      {
        props.spaceFocused.roleId ===
          roles.ADMIN && props.spaceFocused.spaceOwner === props.userId ? (
            <div className="space-delete-block">
              <DeleteSpace
                showDeleteSpace={showDeleteSpacePopup}
                closeDeleteSpace={() => {
                  setShowDeleteSpacePopup(false);
                }}
              />
              <div
                className="space-delete"
                onClick={() => {
                  setShowDeleteSpacePopup(true);
                }}
              >
                <DeleteIcon /> {props.dictionary.s("deleteSpace")}
              </div>
            </div>
          ) : null}

      {props.spaceFocused.spaceOwner !== props.userId ? (
        <div className="space-delete-block">
          <LeaveSpace
            showLeaveSpace={showLeaveSpacePopup}
            closeLeaveSpace={() => {
              setShowLeaveSpacePopup(false);
            }}
          />
          <div
            className={!props.isSafelyPassActivated ||
              (props.isSafelyPassActivated &&
                props.workspaceFocusedForSafelyPass._id !==
                props.spaceFocused._id) ? "space-delete" : "space-delete spaceleave-disable"}
            onClick={() => {
              if (!props.isSafelyPassActivated ||
                (props.isSafelyPassActivated &&
                  props.workspaceFocusedForSafelyPass._id !==
                  props.spaceFocused._id)) {
                setShowLeaveSpacePopup(true);
              }
            }}
          >
            <LeaveIcon className={!props.isSafelyPassActivated ||
              (props.isSafelyPassActivated &&
                props.workspaceFocusedForSafelyPass._id !==
                props.spaceFocused._id) ? "spaceleavesvg" : "spaceleavesvg-disable"}
            /> {props.dictionary.s("leaveSpace")}
          </div>
        </div>
      ) : null}

      <div className="end-block"></div>
    </div>
  );
};

const mapStateToProps = state => {
  const user = state.user;
  return {
    userId: user.id,
    accesstokenidp: state.user.accesstokenidp,
    lang: state.language.lang,
    postData: state.posts.postData,
    workspacesList: state.workspace.list,
    workspaceMessage: state.workspace.message,
    workSpacesProps: state.workspace,
    isSafelyPassActivated: state.operation.safelyPassStatus === "granted",
    workspaceFocusedForSafelyPass:
      state.workspace.workspaceFocusedForSafelyPass,
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    },
    spaceFocused: state.workspace.spaceFocused,
    workspaceUsers: state.workspace.workspaceUsers,
  };
};

const mapDispatchToProps = dispatch => ({
  onUdateWorkSpaceImage: (data, workSpacesProps) =>
    dispatch(updateWorkSpaceImage(data, workSpacesProps)),
  getPostViewData: (workspaceId, userId, accesstokenidp) =>
    dispatch(postViewData(workspaceId, userId, accesstokenidp)),
  onRenameWorkspace: (workSpaceId, name) =>
    dispatch(renameWorkspace(workSpaceId, name)),
  onspinnerPost: condition => dispatch(spinnerPost(condition)),
  onresetProgress: condition => dispatch(resetProgress(condition)),
  onworkSpaceUploadImage: (
    accesstokenidp,
    userId,
    workspaceId,
    base64,
    oldPic
  ) =>
    dispatch(
      workSpaceUploadImage(accesstokenidp, userId, workspaceId, base64, oldPic)
    ),
  onUdateWorkSpaceName: (data, workSpacesProps) =>
    dispatch(updateWorkSpaceName(data, workSpacesProps)),
  onGetUserListByWorkspaceId: (accesstokenidp, workSpaceId) => dispatch(getUserListByWorkspaceId(accesstokenidp, workSpaceId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WorkspaceInfo));
