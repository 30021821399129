import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import Modal from "./wrapped-components/Modal";
import { general } from "../../general/global";
import { translate } from "../../redux/reducers/languageReducer";
import { Button } from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker"; // this datepicker uses date-fns library for date formatting
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import enIN from "date-fns/locale/en-IN";
import { Radio, RadioGroup } from "react-radio-group";
import MultiSelect from "react-multi-select-component";
import { ToastSuccess, ToastError } from "../../redux/actions/toastAction";
import { disableUserSafelyPass } from "../../redux/actions/disableOrEnableUserSafelyPassAction";

const DisableSafelyPassPopUp = (props) => {
  let day = new Date().getDate();
  let year = new Date().getFullYear();
  let month = new Date().getMonth();
  let startdate = new Date(year, month, day, 0, 0, 0);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedValue, setSelectedValue] = useState("allSpaces"); //radio buttons
  const [spaceOptions, setSpaceOptions] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState([]);
  const [showDisableActiveUserPopup, setShowDisableActiveUserPopup] = useState(false);

  useEffect(() => {
    setStartDate(startdate);
    setSpaceOptions(setOptions(props.userData && props.userData.workspaceBelong));
  }, [props.userData]);

  const setOptions = (optionItems) => {
    const options = [];
    if (optionItems) {
      optionItems.forEach((option) => {
        options.push({ label: option.name, value: option._id });
      });
    }
    return options;
  };

  const CalendarCustomInput = ({ value, onClick }) => (
    <div onClick={onClick}>
      <input type="text" className="form-control" placeholder={props.dictionary.s('Dateplaceholder')} value={value} />
      <i className="fa fa-calendar" aria-hidden="true"></i>
    </div>
  );

  const onClickOfDisableSafelyPass = () => {
    let userIsActive = false;
    if(props.userData.active) {
      if(selectedValue === "allSpaces") {
        userIsActive = true;
      }else {  //specificSpaces scenario
        const selectionAlreadyActive = selectedSpace.length > 0 && selectedSpace.find((space) => space.value === props.userData.checkedInWorkSpaceId);
        if (selectionAlreadyActive) {
          userIsActive = true;
        } else {
          userIsActive = false;
        }
      }
    }
    if (!userIsActive) {
      disableSafelyPassBtnClick();
    } else {
      setShowDisableActiveUserPopup(props.userData.active);
    }
  }

  const handleEndDateChange = (date) => {
    let dt = new Date(date)
    let day = dt.getDate();
    let year = dt.getFullYear();
    let month = dt.getMonth();
    let enddate = new Date(year, month, day, 23, 59, 59);
    setEndDate(enddate);
  }

  const displayModalContent = (user) => {
    const btnDisabled = (!startDate || !endDate || (selectedValue === "specificSpaces" && selectedSpace.length === 0));
    return (
        <div
          className="row justify-content-center align-items-center"
          style={{ flexDirection: "column" }}
        >
          <div className="checkin-input-container">
            <RadioGroup
              name="space-selection"
              selectedValue={selectedValue}
              onChange={(e) => setSelectedValue(e)}
            >
              <div className="radio-button-background">
                <Radio value="allSpaces" className="radio-button" />
                <label>{props.dictionary.s('AllSpaces')}</label>
              </div>
              <div className="radio-button-background">
                <Radio value="specificSpaces" className="radio-button" />
                <label>{props.dictionary.s('SpecificSpaces')}</label>
              </div>
            </RadioGroup>

            {selectedValue === "specificSpaces" ? (
              <div className="multi-select-in-disable-pass">
                <label>{props.dictionary.s("space")}</label>
                <MultiSelect
                  className="custome-multiselect"
                  options={spaceOptions}
                  value={selectedSpace}
                  overrideStrings={{
                    selectSomeItems: props.dictionary.s('selectSpace'),
                    allItemsAreSelected: props.dictionary.s(
                      "allSubSpacesareselected"
                    ),
                    search: props.dictionary.s('SearchSpaces'),
                  }}
                  filterOptions={(options, filter) => {
                      if (!filter) {
                          return options;
                      }
                      const exp = new RegExp(filter, "i");
                      return options.filter(({ label }) => label && label.match(exp));
                  }}
                  hasSelectAll={false}
                  onChange={(e) => setSelectedSpace(e)}
                />
                <div style={{ marginBottom: 10 }}>
                  {selectedSpace.map((space) => (
                    <div key={space.value} className={"subspace-oval"}>
                      {space.label}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}

            <div className="date-picker-container disable-pass-date-picker pl-0">
              <label>{props.dictionary.s("StartDate")}</label>
              <DatePicker
                dateFormat="EEE, MMM dd, yyyy hh:mm aa"
                className="form-control date-picker disable-date"
                onChange={(date) => {
                  setEndDate(null);
                  setStartDate(date)
                }}
                minDate={new Date()}
                selected={startDate}
                locale={props.lang === "en" ? enIN : es}
                customInput={<CalendarCustomInput />}
              />
            </div>
            <div className="date-picker-container disable-pass-date-picker pl-0">
              <label>{props.dictionary.s("EndDate")}</label>
              <DatePicker
                dateFormat="EEE, MMM dd, yyyy hh:mm aa"
                className="form-control date-picker disable-date"
                onChange={(date) => handleEndDateChange(date)}
                minDate={startDate}
                selected={endDate}
                locale={props.lang === "en" ? enIN : es}
                customInput={<CalendarCustomInput />}
              />
            </div>

            <Button
              className="popup-disable"
              disabled={btnDisabled}
              onClick={onClickOfDisableSafelyPass}
            >
              {props.dictionary.s("DisableSafelyPass")}
            </Button>
          </div>
        </div>
    );
  };

  const displayModalSecondaryContent = data => {
    if (data) {
      const checkedInWorkSpace = data.workspaceBelong && data.workspaceBelong.find((space) => space._id === data.checkedInWorkSpaceId)
      if(props.lang === "es") {
        return (
          <>
            {props.dictionary.s('enableUserSafelyPassSuffix')}
            <span>{` ${props.userData && props.userData.userFullName} `}</span>
            {props.dictionary.s("safelyPassIsCurrentlyActivatedForTheSpace")}
            <span>{` '${checkedInWorkSpace && checkedInWorkSpace.name}'. `}</span>
            {props.dictionary.s("areyousureyouwanttodisabletheuser")}
          </>
        );
      } else {
        return (
          <>
            <span>{`${props.userData && props.userData.userFullName}'s `}</span>
            {props.dictionary.s("safelyPassIsCurrentlyActivatedForTheSpace")}
            <span>{` '${checkedInWorkSpace && checkedInWorkSpace.name}'. `}</span>
            {props.dictionary.s("areyousureyouwanttodisabletheuser")}
          </>
        );
      } 
    }
  };

  const closeDisablePopup = () => {
    props.closeDisableModal();
    setEndDate(null);
    setStartDate(null);
    setSelectedSpace([]);
    setSelectedValue('allSpaces');
    setShowDisableActiveUserPopup(false);
  };

  const disableSafelyPassBtnClick = async () => {
    const startDateUTC = startDate !== "" ? moment.utc(moment(startDate)).format() : "";
    const endDateUTC = endDate !== "" ? moment.utc(moment(endDate)).format() : "";
    let selectedSpaceId = [];
        if(selectedValue === "allSpaces") {
            selectedSpaceId = spaceOptions.map(space => space.value);
        } else {
            selectedSpaceId = selectedSpace.map(space => space.value);
        }
    if(startDateUTC && endDateUTC && selectedSpaceId && props.userId && props.userData ) {
        const response = await props.onDisableUserSafelyPass(props.userData.userId, selectedSpaceId, props.userId, startDateUTC, endDateUTC);
        if(response.status) {
            props.onToastSuccess(props.dictionary.s("DisabledUserSafelyPassSuccessfully"));
        } else {
            props.onToastError(props.dictionary.s("errorEncounteredPleaseTryAfterSometime"));
        }
        props.load();
    } else {
        props.onToastError(props.dictionary.s("errorEncounteredPleaseTryAfterSometime"));
    }  
    closeDisablePopup();
  };

  return (
    <>
      <Modal
        title={props.userData && props.lang === "en" ? 
        <>
          {props.dictionary.s("Disable")} {props.userData && props.userData.userFullName}'s{" "}
          {props.dictionary.s("safelyPass")}
        </> : <>{props.dictionary.s("DisableSafelyPassOfUserName")}{" "}{ props.userData && props.userData.userFullName}</>
        }
        dialogClassName="safely-modal renderModalCreatSubSpace checkpoint-modal"
        showModal={props.showDisableModal && !showDisableActiveUserPopup}
        onHide={() => closeDisablePopup()}
        properties={{
          centered: true,
        }}
        textButtonSave={props.dictionary.s("close")}
        body={props.userData && displayModalContent(props.userData)}
      />
      <Modal
        title={props.userData && props.lang === "en" ? 
          <>
            {props.dictionary.s("Disable")} {props.userData && props.userData.userFullName}'s{" "}
            {props.dictionary.s("safelyPass")}
          </> : <>{props.dictionary.s("DisableSafelyPassOfUserName")}{" "}{ props.userData && props.userData.userFullName}</>
        }
        dialogClassName="safely-modal renderModalCreatSubSpace checkpoint-modal"
        showModal={props.showDisableModal && showDisableActiveUserPopup}
        onHide={() => closeDisablePopup()}
        onSave={async () => disableSafelyPassBtnClick()}
        onClose={() => closeDisablePopup()}
        textButtonSave={props.dictionary.s("Disable")}
        textButtonClose={props.dictionary.s("cancel")}
        propertyButtonSave={{
          block: false,
          style: {
            backgroundColor: "#E67070",
            color: general.theme.whiteFontColor,
            border: `1px solid #E67070`,
          },
        }}
        bsStyleButtonSave="secondary"
        buttonCloseProperties={{
          block: false,
          style: {
            backgroundColor: general.theme.whiteColor,
            border: `1px solid ${general.theme.primaryColor}`,
            color: general.theme.primaryColor,
          },
        }}
        properties={{ centered: true }}
        body={<div className="removeText">{props.userData && displayModalSecondaryContent(props.userData)}</div>}
        isLoadingButton={props.loading}
      />
    </>
  );
};

const mapStateToProps = (state) => {
    const user = state.user;
    return {
        dictionary: {
            s: (key) => translate(state.language.dictionary, key),
        },
        lang: state.language.lang,
        userId: user.id,
    };
};

const mapDispatchToProps = (dispatch) => ({
  onDisableUserSafelyPass: (
    userId,
    workspaces,
    disabledById,
    startDate,
    endDate
  ) => dispatch(
    disableUserSafelyPass(
      userId,
      workspaces,
      disabledById,
      startDate,
      endDate
    )
  ),
  onToastSuccess: (message) => dispatch(ToastSuccess(message)),
  onToastError: (message) => dispatch(ToastError(message)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisableSafelyPassPopUp);
