import React, { Component } from "react";
import MainNavigation from "../Navigation/MainNavigation";
import { withRouter } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { translate } from "../../redux/reducers/languageReducer";

import Report from "./ReportGenerator"

class MainReports extends Component {
    constructor(props) {
        super(props);
        const activeKey = props.match.params.reportKey || 'active-pass';
        this.state = {
            activeKey
        }
    }

    render() {
        const { activeKey } = this.state;
        return (
            <MainNavigation title={this.props.title}>
                <span className="reports-header">{this.props.dictionary.s('safelyPassHistory')}</span>
                <Tabs id="reports-tabs" activeKey={activeKey} variant="pills" className="tabs-container"
                    onSelect={eventKey => {
                        this.props.history.push(`/reportlist/${eventKey}`);
                        this.setState({ activeKey: eventKey });
                    }}
                >
                    <Tab tabClassName="tab-reports" eventKey="active-pass" title={this.props.dictionary.s('ActivatedSafelyPasses')} >
                        <Report type='ActivatedSafelyPasses' />
                    </Tab>
                    <Tab tabClassName="tab-reports" eventKey="passes-rejected" title={this.props.dictionary.s('SafelyPassesRejected')} >
                        <Report type='SafelyPassesRejected' />
                    </Tab>
                    <Tab tabClassName="tab-reports" eventKey="check-ins" title={this.props.dictionary.s('CheckedInUsingRegister')} >
                        <Report type='CheckedInUsingRegister' />
                    </Tab>
                    <Tab tabClassName="tab-reports" eventKey="passes-disabled" title={this.props.dictionary.s('SafelyPassesDisabled')} >
                        <Report type='SafelyPassesDisabled' />
                    </Tab>
                </Tabs>
            </MainNavigation>
        );
    }

    componentWillUnmount() {
        localStorage.removeItem('reportSelected');
    }
}

const mapStateToProps = state => ({
    dictionary: {
        s: key => translate(state.language.dictionary, key)
    }
});
export default connect(mapStateToProps, null)(withRouter(MainReports));