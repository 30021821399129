import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

class PDF {

    static async modifyPDF(pdf, modifications) {
        const pdfDoc = await PDFDocument.load(pdf);
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const pages = pdfDoc.getPages();

        for (let m of modifications) {
            const { inputType, positionX, positionY, width, height, value, fontSize, pageNumber } = m;
            const currentPage = pages[pageNumber - 1];
            const pageWidth = currentPage.getWidth();
            const pageHeight = currentPage.getHeight();
            const x = (pageWidth / 100) * positionX;
            const y = pageHeight - ((pageHeight / 100) * (positionY + (fontSize || height)));

            switch (inputType) {
                case 'text':
                    currentPage.drawRectangle({
                        x,
                        y: pageHeight - ((pageHeight / 100) * (positionY + height)),
                        width: (pageWidth / 100) * width,
                        height: (pageHeight / 100) * height,
                        color: rgb(1, 1, 1),
                    });
                    currentPage.drawText(value || '', {
                        x,
                        y: pageHeight - ((pageHeight / 100) * (positionY + (height - fontSize))),
                        size: (pageHeight / 100) * fontSize,
                        font: helveticaFont,
                        color: rgb(0, 0, 0),
                    });
                    break;
                case 'signature':
                    if (value) {
                        const pngImageBytes = new Buffer.from(value.replace(/^data:image\/\w+;base64,/, ""), 'base64');
                        const pngImage = await pdfDoc.embedPng(pngImageBytes);

                        currentPage.drawImage(pngImage, {
                            x,
                            y,
                            width: (pageWidth / 100) * width,
                            height: (pageHeight / 100) * height,
                        });

                    }

                    break;

                default:
                    break;
            }
        }

        const pdfBytes = await pdfDoc.save();
        return pdfBytes;
    }
}

export default PDF;
