import React, { useEffect } from "react";

import {
  loginWithSocialMedia,
  loginUserExists,
  reset,
} from "../../redux/actions/loginActions";
import { createUserSocialMedia } from "../../redux/actions/userActions";
import {
  changedMagicLinkCode,
  changedEmailOnboarding,
  changedName,
  changedLastName,
} from "../../redux/actions/onboardingActions";
import { setWorkspaceAtLogin } from "../../redux/actions/workspaceActions";
import { getTransactions, setCurrentTransaction } from "../../redux/actions/transactionsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Notifications from "../../notifications";

const notifications = new Notifications();
const Callback = (props) => {
  useEffect(() => {
    async function loginSocial() {
      const params = props.match.params;
      const accessToken = params.access_token; //params = {access_token, expires_in, token_type }
      await loadLoginWithSocialMedia(accessToken, props.lang);
    }
    loginSocial();
  }, []);

  const loadLoginWithSocialMedia = async (accessToken, lang) => {
    //console.log("Login with social media started!");
    let fcmToken = '';
    if (notifications.isSupported()) {
      fcmToken = await notifications.getToken();
    }
    const responseUserExists = await props.onLoginUserExists(accessToken);
    if (responseUserExists.status) {
      await props.onLoginWithSocialMedia(accessToken, lang, fcmToken);
      const { params } = props.match;
      switch (true) {
        case params.reportKey !== undefined: // Reports callback
          await props.onSetWorkspaceAtLogin(params.userId, params.access_token, params.workSpaceId);
          if (params.reportKey === 'verificationReport' || params.reportKey === 'selfdeclarationReport') {
            props.history.push(`/${params.reportKey}`);
          } else if (params.reportKey === 'activity' || params.reportKey === 'history') {
            props.history.push(`/subspacesReport/${params.reportKey}`);
          } else if (params.reportKey === 'transactionsHistory') {
            props.history.push(`/${params.reportKey}`);
          } else {
            props.history.push(`/reportlist/${params.reportKey}`);
          }
          break;
        case params.view === 'executeTransaction': // Execute transaction callback
          const { workSpaceId, userId, transactionId, transactionName } = params;
          const response = await props.getTransactions({
            userId,
            workspaceId: workSpaceId,
            name: transactionName,
            pageNo: 1,
            limit: 10
          });

          if (response.status) {
            const transaction = response.data.find(x => x._id === transactionId);
            if (!transaction) {
              props.history.push(`/`);
              return;
            }
            await props.onSetCurrentTransaction(transaction);
            props.history.push(`/executeTransaction`);
          }
          break;
        default:
          props.history.push("/workspacelist");
          break;
      }
    } else {
      const responseCreateUser = await props.onCreateUserSocialMedia(
        accessToken,
        props.magicLinkCode,
        props.lang
      );
      if (responseCreateUser.status) {
        const { email, name, lastName } = responseCreateUser.data;
        props.onChangedEmailNumber(email);
        props.onChangedLastName(lastName);
        props.onChangedName(name);
        props.history.push("/signup");
      }
    }
  };

  return <div></div>;
};

const mapDispatchToProps = (dispatch) => ({
  onLoginWithSocialMedia: (accessToken, lang, fcmToken) =>
    dispatch(loginWithSocialMedia(accessToken, lang, fcmToken)),
  onLoginUserExists: (accessToken) => dispatch(loginUserExists(accessToken)),
  onCreateUserSocialMedia: (accessToken, magicLinkCode) =>
    dispatch(createUserSocialMedia(accessToken, magicLinkCode)),
  onChangedMagicLinkCode: (magicLinkCode) =>
    dispatch(changedMagicLinkCode(magicLinkCode)),
  onChangedEmailNumber: (emailNumber) =>
    dispatch(changedEmailOnboarding(emailNumber)),
  onChangedName: (name) => dispatch(changedName(name)),
  onChangedLastName: (lastName) => dispatch(changedLastName(lastName)),
  onSetWorkspaceAtLogin: (userId, accessToken, workSpaceId) => dispatch(setWorkspaceAtLogin(userId, accessToken, workSpaceId)),
  getTransactions: (body) => dispatch(getTransactions(body)),
  onSetCurrentTransaction: (transaction) => dispatch(setCurrentTransaction(transaction)),
  onReset: () => dispatch(reset()),
});

const mapStateToProps = (state) => ({
  isLoggingIn: state.login.isLoggingIn,
  magicLinkCode: state.onboarding.magicLinkCode,
  lang: state.language.lang,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Callback));
