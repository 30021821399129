import React from "react";
import moment from "moment";
import dateFormats from "./dateFormats";

import { str_truncate } from "../general/general";

import MeasuringBar from "../components/commons/wrapped-components/MeasuringBar";
import MeasuringCircle from "../components/commons/wrapped-components/MeasuringCircle";
import ImagePerview from "../components/commons/wrapped-components/ImagePerview";
import { ReactComponent as MapIcon } from "../icons/placeholder_blue.svg";
import { Link } from "react-router-dom";
import { general } from "../general/global";

const dateFormatter = cell => {
  const lang = localStorage.getItem("lang");
  const dateFormat = dateFormats[lang];
  const date = new Date(cell);
  if (date instanceof Date && !isNaN(date.valueOf())) {
    return cell ? moment(date).format(dateFormat) : null;
  } else {
    return cell;
  }
};

const dateDiff = (cell, row) => {
  const { checkInDate, checkoutDate } = row;
  const checkinTime = new Date(checkInDate).getTime();
  const checkoutTime = new Date(checkoutDate).getTime();
  if (isNaN(checkoutTime)) return null;
  const diff = moment.duration(checkoutTime - checkinTime);
  const { hours, minutes, seconds } = diff._data;

  return (
    (hours < 10 ? "0" + hours : hours) +
    ":" +
    (minutes < 10 ? "0" + minutes : minutes) +
    ":" +
    (seconds < 10 ? "0" + seconds : seconds)
  );
};

const longTextFormatter = (cell, row) => {
  return cell
    ? cell.length > 30
      ? cell.substring(0, 27) + "..."
      : cell
    : null;
}

const columnFormatter = (column, colIndex) => {
  return column.text.length > 15 ? (
    <span className="th-title" data-title={column.text}>
      {str_truncate(column.text, 15, "...")}
    </span>
  ) : (
      <span>{column.text}</span>
    );
};

const columns = {
  ActivatedSafelyPasses: [
    {
      dataField: "user_name",
      text: "userName",
      formatter: function (cell, row) {
        return (
          <div className="momentTime">
            {row.user_name || row.user_lastName
              ? row.user_name + " " + row.user_lastName
              : null}
          </div>
        );
      }
    },
    {
      dataField: "monitor_name",
      text: "monitorName",
      formatter: function (cell, row) {
        return (
          <div className="momentTime">
            {row.monitor_name || row.monitor_lastName
              ? row.monitor_name + " " + row.monitor_lastName
              : null}
          </div>
        );
      }
    },
    {
      dataField: "workspace_name",
      text: "workSpace",
      formatter: longTextFormatter
    },
    {
      dataField: "grantDate",
      text: "activationDate",
      formatter: dateFormatter
    },
    {
      dataField: "checkoutDate",
      text: "checkOutTime",
      formatter: dateFormatter
    },
    {
      dataField: "photoUrl",
      text: "photo",
      formatter: function (cell, row) {
        return cell ? <ImagePerview image={cell} /> : null;
      }
    }
  ],
  SafelyPassesNotGenerated: [
    {
      dataField: "user_name",
      text: "userName",
      formatter: function (cell, row) {
        return (
          <div className="momentTime">
            {row.user_name || row.user_lastName
              ? row.user_name + " " + row.user_lastName
              : null}
          </div>
        );
      }
    },
    {
      dataField: "workspace_name",
      text: "workSpace",
      formatter: longTextFormatter
    },
    {
      dataField: "requestDate",
      text: "passRequestedDate",
      formatter: dateFormatter
    }
  ],
  SafelyPassesRejected: [
    {
      dataField: "user_name",
      text: "userName",
      formatter: function (cell, row) {
        return (
          <div className="momentTime">
            {row.user_name || row.user_lastName
              ? row.user_name + " " + row.user_lastName
              : null}
          </div>
        );
      }
    },
    {
      dataField: "monitor_name",
      text: "monitorName",
      formatter: function (cell, row) {
        return (
          <div className="momentTime">
            {row.monitor_name || row.monitor_lastName
              ? row.monitor_name + " " + row.monitor_lastName
              : null}
          </div>
        );
      }
    },
    {
      dataField: "workspace_name",
      text: "workSpace",
      formatter: longTextFormatter
    },
    {
      dataField: "requestDate",
      text: "passRequestedDate",
      formatter: dateFormatter
    },
    {
      dataField: "grantDate",
      text: "passRejectedDate",
      formatter: dateFormatter
    },
    {
      dataField: "photoUrl",
      text: "photo",
      formatter: function (cell, row) {
        return cell ? <ImagePerview image={cell} /> : null;
      }
    }
  ],
  CheckedInUsingRegister: [
    {
      dataField: "user_name",
      text: "userName",
      formatter: function (cell, row) {
        return (
          <div className="momentTime">
            {row.user_name || row.user_lastName
              ? row.user_name + " " + row.user_lastName
              : null}
          </div>
        );
      }
    },
    {
      dataField: "user_roleName",
      text: "role",
      formatter: function (cell, row) {
        return cell;
      }
    },
    {
      dataField: "monitor_name",
      text: "monitorName",
      formatter: function (cell, row) {
        return (
          <div className="momentTime">
            {row.monitor_name || row.monitor_lastName
              ? row.monitor_name + " " + row.monitor_lastName
              : null}
          </div>
        );
      }
    },
    {
      dataField: "workspace_name",
      text: "workSpace",
      formatter: longTextFormatter
    },
    {
      dataField: "grantDate",
      text: "checkInTime",
      formatter: dateFormatter
    },
    {
      dataField: "checkoutDate",
      text: "checkOutTime",
      formatter: dateFormatter
    }
  ],
  SubspacesHistory: [
    {
      dataField: "user_name",
      text: "userName",
      formatter: longTextFormatter
    },
    {
      dataField: "subspace_name",
      text: "subspace",
      formatter: longTextFormatter
    },
    {
      dataField: "accessGranted",
      text: "accessGranted",
      formatter: function (cell, row) {
        return cell;
      }
    },
    {
      dataField: "checkInDate",
      text: "checkInTime",
      formatter: dateFormatter
    },
    {
      dataField: "checkoutDate",
      text: "checkOutTime",
      formatter: dateFormatter
    },
    {
      dataField: "timeSpent",
      text: "timeSpentInSubspace",
      formatter: dateDiff
    }
  ],
  UserSubspacesHistory: [
    {
      dataField: "user_name",
      text: "userName",
      formatter: function (cell, row) {
        return (
          <div className="momentTime">
            {row.user_name || row.user_lastName
              ? row.user_name + " " + row.user_lastName
              : null}
          </div>
        );
      }
    },
    {
      dataField: "subspace_name",
      text: "subspace",
      formatter: longTextFormatter
    },
    {
      dataField: "accessGranted",
      text: "accessGranted",
      formatter: function (cell, row) {
        return cell;
      }
    },
    {
      dataField: "checkInDate",
      text: "checkInTime",
      formatter: dateFormatter
    },
    {
      dataField: "checkoutDate",
      text: "checkOutTime",
      formatter: dateFormatter
    },
    {
      dataField: "timeSpent",
      text: "timeSpentInSubspace",
      formatter: dateDiff
    }
  ],
  SubspacesActivity: [
    {
      dataField: "subspaceName",
      text: "subspace",
      formatter: longTextFormatter
    },
    {
      dataField: "maxOccupancy",
      text: "maximumOccupancy",
      formatter: function (cell, row) {
        return cell;
      }
    },
    {
      dataField: "availableOccupancy",
      text: "availableOccupancy",
      formatter: function (cell, row) {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <MeasuringBar
              used={row.activeUsers}
              measure={"users"}
              total={row.maxOccupancy}
            />
            <div style={{ marginLeft: 64 }}>
              <MeasuringCircle
                used={row.activeUsers}
                total={row.maxOccupancy}
              />
            </div>
          </div>
        );
      }
    }
  ],
  selfDeclarationReport: [
    {
      dataField: "userName",
      text: "userName",
      formatter: longTextFormatter,
      headerFormatter: columnFormatter,
    },
    {
      dataField: "passGranted",
      text: "passGranted",
      headerFormatter: columnFormatter,
    },
    {
      dataField: "dateSubmitted",
      text: "dateSubmitted",
      formatter: dateFormatter,
      headerFormatter: columnFormatter,
    }
  ],
  verificationFormsReport: [
    {
      dataField: "userName",
      text: "userName",
      formatter: longTextFormatter,
      headerFormatter: columnFormatter,
    },
    {
      dataField: "accessGranted",
      text: "accessGrantedQustion",
      headerFormatter: columnFormatter,
    },
    {
      dataField: "dateSubmitted",
      text: "dateSubmitted",
      formatter: dateFormatter,
      headerFormatter: columnFormatter,
    }
  ],
  SafelyPassesDisabled: [
    {
      dataField: "user_fullName",
      text: "userName",
      formatter: longTextFormatter,
      headerFormatter: columnFormatter,
    },
    {
      dataField: "user_roleName",
      text: "role",
      formatter: function (cell, row) {
        return cell;
      }
    },
    {
      dataField: "spaceName",
      text: "space",
      formatter: longTextFormatter,
      headerFormatter: columnFormatter,
    },
    {
      dataField: "disabledBy_fullName",
      text: "disabledBy",
      formatter: longTextFormatter,
      headerFormatter: columnFormatter,
    },
    {
      dataField: "disabledDate",
      text: "disabledDate",
      formatter: dateFormatter
    },
    {
      dataField: "disabledPeriod",
      text: "disabledPeriod",
      formatter: function (cell, row) {
        return cell;
      }
    },
    {
      dataField: "enabledDate",
      text: "enabledDate",
      formatter: dateFormatter
    },
  ],
  transactionsHistory: [
    {
      dataField: "userFullName",
      text: "userName",
      formatter: function (cell, row) {
        return cell;
      }
    },
    {
      dataField: "transactionFormName",
      text: "transactionName",
      formatter: function (cell, row) {
        if (row.url) {
          const splitUrl = row.url.split('/');
          const fileName = splitUrl[splitUrl.length - 1];
          return <Link style={{ color: general.theme.primaryColor }} to={`/transactionPdfViewer/${fileName}`} >{row.transactionFormName}</Link>;
        } else {
          return <div></div>;
        }
      }
    },
    {
      dataField: "dateSubmitted",
      text: "dateSubmitted",
      formatter: dateFormatter
    },
    {
      dataField: "geolocation",
      text: "geolocation",
      headerAlign: 'center',
      formatter: function (cell, row) {
        if (row.geolocation) {
          const coordinates = row.geolocation.coordinates;
          return (
            <div className='text-center'>
              <MapIcon
                className="cursor-pointer"
                style={{ width: '25px' }}
                onClick={() => {
                  const url = `https://www.google.com/maps/search/?api=1&query=${coordinates[0]},${coordinates[1]}`;
                  window.open(url, '_blank');
                }}
              />
            </div>
          );
        } else {
          return <div></div>
        }
      }
    },
  ],
};

export const getCustomColumns = (type, dictionary, data = [], fixedColumns, insertIndex) => {
  const result = data.map((row) => {
    const obj = {};
    const rowKeys = Object.keys(row);
    rowKeys.map((key) => {
      if (fixedColumns.indexOf(key) < 0) {
        obj[key] = row[key]
      }
    });
    return obj
  });
  const keys = [];
  result.map((res) => {
    Object.keys(res).map((key) => {
      return keys.push(key);
    });
  });
  const newColumns = columns[type].map(column => {
    const columnTrnaslated = { ...column };
    columnTrnaslated.text = dictionary.s(column.text).toUpperCase();
    return columnTrnaslated;
  });
  const uniqueColumns = [...new Set(keys)];

  const insertColumns = uniqueColumns.map((res) => {
    return {
      dataField: res,
      text: res.toUpperCase(),
      formatter: longTextFormatter,
      headerFormatter: columnFormatter,
    };
  });
  newColumns.splice(insertIndex, 0, ...insertColumns);
  return newColumns;
};

export const getColumns = (type, dictionary, data) => {
  if (type === "ActivatedSafelyPasses" || type === "SafelyPassesRejected") {
    if (type === "ActivatedSafelyPasses" && data.ActivatedSafelyPasses) {
      let checkPhoto = data.ActivatedSafelyPasses.reportData.findIndex(
        obj => obj.photoUrl !== null
      );
      if (checkPhoto < 0) {
        columns.ActivatedSafelyPasses.splice(5, 1);
      }
      if (checkPhoto >= 0) {
        columns.ActivatedSafelyPasses[5] = {
          dataField: "photoUrl",
          text: "photo",
          formatter: function (cell, row) {
            return cell ? <ImagePerview image={cell} /> : null;
          }
        };
      }
    }
    if (type === "SafelyPassesRejected" && data.SafelyPassesRejected) {
      let checkPhoto1 = data.SafelyPassesRejected.reportData.findIndex(
        obj => obj.photoUrl !== null
      );
      if (checkPhoto1 < 0) {
        columns.SafelyPassesRejected.splice(5, 1);
      }
      if (checkPhoto1 >= 0) {
        columns.SafelyPassesRejected[5] = {
          dataField: "photoUrl",
          text: "photo",
          formatter: function (cell, row) {
            return cell ? <ImagePerview image={cell} /> : null;
          }
        };
      }
    }
  }
  return columns[type].map(column => {
    const columnTrnaslated = { ...column };
    columnTrnaslated.text = dictionary.s(column.text).toUpperCase();
    return columnTrnaslated;
  });
};
