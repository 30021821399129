import React, { useState, useLayoutEffect } from 'react'
import { connect } from 'react-redux';
import PDFVisualizer from '../commons/wrapped-components/PDFVisualizer';
import MainNavigation from '../Navigation/MainNavigation';


const TransactionPdfViewer = ({ match, title, activeTransaction, spaceFocused }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [canvasSize, setCanvasSize] = useState([0, 0]);
    const [size, setSize] = useState([0, 0]);
    const { fileName } = match.params;
    const urlPdf = `https://safely-media.s3.us-west-2.amazonaws.com/transactions/${spaceFocused._id}/transactionSubmissionForms/${fileName}`;
    useLayoutEffect(() => {
        function updateSize() {
            setSize([document.documentElement.clientWidth, document.documentElement.clientHeight]);
            updateCanvasSize();
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const updateCanvasSize = () => {
        const canvas = document.getElementsByClassName('react-pdf__Page__canvas')[0];
        if (canvas) {
            setCanvasSize([canvas.getBoundingClientRect().width, canvas.getBoundingClientRect().height]);
        }
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setTimeout(updateCanvasSize, 300)
    }

    const pdfWidth = Math.min(Math.max(size[0] * 0.45, 320), 1200);

    return (
        <MainNavigation title={title}>
            <div className="transaction-container execute">
                <div className="transaction-header">
                    <div className="transaction-filename">{fileName}</div>
                    <div className="transaction-pagination execute" style={{ width: pdfWidth - 170 }}>
                        {pageNumber === 1 ? null : <i style={{ cursor: 'pointer' }} onClick={() => setPageNumber(pageNumber - 1)} className="material-icons plus-symbol">navigate_before</i>}
                        {`Page ${pageNumber}/${numPages}`}
                        {pageNumber === numPages ? null : <i style={{ cursor: 'pointer' }} onClick={() => setPageNumber(pageNumber + 1)} className="material-icons plus-symbol">navigate_next</i>}
                    </div>
                </div>
                <div className="transaction-row">
                    <div className="pdf-viewer execute" style={{ width: pdfWidth }}>
                        <PDFVisualizer
                            file={urlPdf}
                            onLoadSuccess={onDocumentLoadSuccess}
                            PageProperties={{
                                pageNumber: pageNumber,
                                width: pdfWidth
                            }}
                        />
                    </div>
                </div>
            </div>
        </MainNavigation>
    )
}

const mapStateToProps = (state) => ({
    spaceFocused: state.workspace.spaceFocused,
});

export default connect(mapStateToProps, null)(TransactionPdfViewer);
