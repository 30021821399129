import React, { useState } from "react";
import {
  deleteSubWorkspace,
  getSubspacesByUserIdWorkSpaceId,
  removeSubspaceFromSubspaceList,
  setSubspaceFocused,
  updatedeleteSubSpace,
} from "../../redux/actions/workspaceActions";
import { connect } from "react-redux";
import { general } from "../../general/global";
import { translate } from "../../redux/reducers/languageReducer";
import Modal from "../commons/wrapped-components/Modal";
import { toastSuccess, toastDismissAll } from "../commons/Toast";

const DeleteSubSpace = (props) => {
  const [clickSaveButton, setClickSaveButton] = useState(false);
  return (
    <Modal
      title={props.dictionary.s("deleteSysbol") + ' ' + props.workspaceSubFocused.name + '?'}
      dialogClassName="safely-modal renderModalDeleteSubSpace"
      showModal={props.showDeleteSubSpace}
      onHide={() => {
        props.closeDeleteSubSpace();
      }}
      onSave={() => {
        toastDismissAll();
        if (!clickSaveButton) {
          setClickSaveButton(true);
          props.ondeleteSubWorkspace(props.workspaceSubFocused._id, props.workspaceFocused._id, props.userId, props.accesstokenidp).then((responceData) => {
            toastSuccess(responceData.sucessMessage);
            props.onUpdatedeleteSubSpace(responceData.data, props.workSpacesProps);
            props.onGetSubspacesByUserIdWorkSpaceId('').then(response => {
              if (response.status) {
                const subspace = response.data[0];
                props.onSetSubspaceFocused(subspace);
              }
            });
            props.closeDeleteSubSpace();
            setClickSaveButton(false);
          });
        }
      }}
      onClose={() => {
        props.closeDeleteSubSpace();
      }}
      textButtonSave={clickSaveButton ? props.dictionary.s("loading") + ' ...' : props.dictionary.s("delete")}
      textButtonClose={props.dictionary.s("cancel")}
      propertyButtonSave={{
        block: false,
        style: {
          backgroundColor: "#E67070",
          color: general.theme.whiteFontColor,
          border: `1px solid #E67070`,
        },
      }}
      bsStyleButtonSave="secondary"
      buttonCloseProperties={{
        block: false,
        style: {
          backgroundColor: general.theme.whiteColor,
          border: `1px solid ${general.theme.primaryColor}`,
          color: general.theme.primaryColor,
        },
      }}
      properties={{
        centered: true,
      }}
      body={
        <>
          <div className="deleteSubSpaceText">
            <div>
              {props.dictionary.s("deleteSubSpaceText1")}<b> '{props.workspaceSubFocused.name}'</b>.
            </div>
            <div>
              {props.dictionary.s("areyousureyouwanttocontinue")}
            </div>
          </div>
        </>
      }
    />
  );
};

const mapStateToProps = (state) => {
  const user = state.user;
  return {
    userId: user.id,
    accesstokenidp: state.user.accesstokenidp,
    lang: state.language.lang,
    workspaceFocused: state.workspace.spaceFocused,
    workSpacesProps: state.workspace,
    workspaceSubFocused: state.workspace.workspaceSubFocused,
    dictionary: {
      s: (key) => translate(state.language.dictionary, key),
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  ondeleteSubWorkspace: (subspaceId, workSpaceId, userId, accesstokenidp) =>
    dispatch(deleteSubWorkspace(subspaceId, workSpaceId, userId, accesstokenidp)),
  onUpdatedeleteSubSpace: (data, workSpacesProps) => dispatch(updatedeleteSubSpace(data, workSpacesProps)),
  onGetSubspacesByUserIdWorkSpaceId: (search) => dispatch(getSubspacesByUserIdWorkSpaceId(search)),
  onSetSubspaceFocused: (subspace) => dispatch(setSubspaceFocused(subspace))
});
export default connect(mapStateToProps, mapDispatchToProps)(DeleteSubSpace);
