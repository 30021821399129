import React, { useState, useEffect } from "react";
import Modal from "../commons/wrapped-components/Modal";
import { general } from "../../general/global";
import Webcam from "react-webcam";
import { translate } from "../../redux/reducers/languageReducer";
import { connect } from "react-redux";
import { ReactComponent as FlipCameraIcon } from "../../icons/flipCamera.svg";
import { ReactComponent as CameraIcon } from "../../icons/cameraIcon.svg";
import { ReactComponent as VedioIcon } from "../../icons/vedio.svg";
import EditImage from "./EditImage";
import VedioRecoding from "./VedioRecoding";
import Switch from "../commons/wrapped-components/inputs/Switch";

const TakePhotoOrVideo = props => {
  const webcamRef = React.useRef(null);
  const [deviceType, setdeviceType] = useState(window.deviceType);
  const [facingMode, setFacingMode] = useState(
    (general.isSafari && deviceType !== "desktop") ||
      (general.isChromeAndroid && deviceType !== "desktop")
      ? { exact: "environment" }
      : "user"
  );
  const [cameraValue, setCameraValue] = useState(false);
  const [showModalEditImage, setShowModalEditImage] = useState(false);
  const [imageData, setImage] = useState(null);
  const [showModalVedioRecoding, setShowModalVedioRecoding] = useState(false);

  useEffect(() => {
    function handleResize() {
      setdeviceType(window.deviceType);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const capturePhoto = React.useCallback(() => {
    let imageSrc = null;
    imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    setShowModalEditImage(true);
    props.onCloseTakePhotoOrVideo();
  }, [webcamRef]);
  
  const closeEditImage = () => {
    setShowModalEditImage(false);
  };
  const closeVedioRecoding = () => {
    setShowModalVedioRecoding(false);
  };
  const getFacingMode = () =>
    facingMode === "user" ? { exact: "environment" } : "user";

  const onClickOfFlipCamera = () => {
    if (general.isSafari) setFacingMode(getFacingMode());
    else if (general.isChromeAndroid) {
      setFacingMode(getFacingMode());
    } else {
      setFacingMode("user");
    }
  };

  const body = (
    <div>
      {props.showModal ? (
        <Webcam
          className="web-camera"
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width="100%"
          height={550}
          videoConstraints={{
            facingMode: facingMode
          }}
        />
      ) : null}
      <div className="camera-buttons-div">
        <div className="flip-icon-style" onClick={onClickOfFlipCamera}>
          {" "}
          <FlipCameraIcon /> {" "} 
        </div>
        <div>
          <div className="photo-icon-style camera-style">
            {cameraValue ? <VedioIcon onClick={() => {
                setShowModalVedioRecoding(true);
                props.onCloseTakePhotoOrVideo();
                setCameraValue(false);
              }}/> : <CameraIcon onClick={capturePhoto} />}
          </div>
        </div>
        <div
          className={
            cameraValue ? "switch-toggle-style" : "switch-toggle-style off"
          }
        >
          <Switch
            id={`switch_location`}
            isOn={cameraValue}
            onChange={() => setCameraValue(!cameraValue)}
            onColor={general.theme.primaryColor}
            offColor="#eee"
            width={38}
            height={22}
            handleDiameter={17}
          />
          <label>{props.dictionary.s("video")}</label>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <VedioRecoding showModalVedioRecoding= {showModalVedioRecoding}  onCloseVedioRecoding={closeVedioRecoding} facingMode={facingMode}/>
      <EditImage showModalEditImage= {showModalEditImage} image={imageData} onCloseEditImage={closeEditImage}/>
      <Modal
        className="takePhotoOrVideoPopup"
        center="true"
        size="lg"
        showModal={props.showModal}
        title={props.dictionary.s("takePhotoOrVideo")}
        onHide={() => props.onCloseTakePhotoOrVideo()}
        body={body}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    }
  };
};

export default connect(mapStateToProps, null)(TakePhotoOrVideo);
