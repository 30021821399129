import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFVisualizer = ({
    file,
    onLoadSuccess,
    PageProperties,
}) => {

    return (
        <Document
            file={file}
            onLoadSuccess={onLoadSuccess}
        >
            <Page
                {...PageProperties}
            ></Page>
        </Document>
    );
}

PDFVisualizer.defaultProps = {
    file: null,
    onLoadSuccess: () => { },
    PageProperties: {},
}

export default PDFVisualizer;