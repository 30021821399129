import {
  WORKSPACE_CREATE_SUCCESS,
  WORKSPACE_CREATE_FAIL,
  WORKSPACE_GET_LIST,
  WORKSPACE_GET_LIST_SUCCESS,
  WORKSPACE_GET_LIST_FAIL,
  WORKSPACE_SET_FOCUSED,
  CREATING_NEW_WORKPACE,
  WORKSPACE_ADD_USER,
  WORKSPACE_ADD_USER_SUCCESS,
  WORKSPACE_ADD_USER_FAIL,
  RESET_WORKSPACE_CREATED,
  SET_WORKSPACE_AT_LOGIN,
  SET_MESSAGE,
  WORKSPACE_ADD_ITEM_TO_LIST,
  WORKSPACE_ADD_SETTINGS,
  WORKSPACE_ADD_SETTINGS_SUCCESS,
  WORKSPACE_ADD_SETTINGS_FAIL,
  WORKSPACE_UPDATE_SETTINGS,
  WORKSPACE_RENAME,
  WORKSPACE_RENAME_SUCCESS,
  WORKSPACE_RENAME_FAIL,
  WORKSPACE_IMPORT_CONTACTS,
  WORKSPACE_IMPORT_CONTACTS_SUCCESS,
  WORKSPACE_IMPORT_CONTACTS_FAIL,
  WORKSPACE_SUB_SET_FOCUSED,
  RESET_WORKSPACE_FOCUSED,
  WORKSPACE_SAFELY_PASS_SET_FOCUSED,
  SPINNER_POST,
  SUBSPACE_UPDATE_MAX_OCCUPANCY,
  SUBSPACE_UPDATE_MAX_OCCUPANCY_SUCCESS,
  SUBSPACE_UPDATE_MAX_OCCUPANCY_FAIL,
  SUBSPACE_GET_CHECK_INTO_SUBSPACE_LIST,
  SUBSPACE_GET_CHECK_INTO_SUBSPACE_LIST_SUCCESS,
  SUBSPACE_GET_CHECK_INTO_SUBSPACE_LIST_FAIL,
  SUBSPACE_CHECK_INTO_SUBSPACE,
  SUBSPACE_CHECK_INTO_SUBSPACE_SUCCESS,
  SUBSPACE_CHECK_INTO_SUBSPACE_FAIL,
  SUBSPACE_CHECK_OUT_INTO_SUBSPACE,
  SUBSPACE_CHECK_OUT_INTO_SUBSPACE_SUCCESS,
  SUBSPACE_CHECK_OUT_INTO_SUBSPACE_FAIL,
  SUBSPACE_GET_ACTIVES_SUBSPACE_CHECK_IN,
  SUBSPACE_GET_ACTIVES_SUBSPACE_CHECK_IN_SUCCESS,
  SUBSPACE_GET_ACTIVES_SUBSPACE_CHECK_IN_FAIL,
  REFRESH_CHECK_INTO_SUBSPACE_SCREEEN,
  WORKSPACE_UPDATE_USER,
  SUBSPACE_REST_CHECK_INTO_SUBSPACE_LIST,
  SUBSPACE_CHECK_INTO_SUBSPACE_PUSH_NOTIFICATION,
  SUBSPACE_CHECK_OUT_PUSH_NOTIFICATION,
  SPACE_GET_LIST_OF_SPACES_WITH_PAGINATION,
  SPACE_GET_LIST_OF_SPACES_WITH_PAGINATION_SUCCESS,
  SPACE_GET_LIST_OF_SPACES_WITH_PAGINATION_FAIL,
  SPACE_GET_SUBSPACES_BY_USER_ID_WORKSPACE_ID,
  SPACE_GET_SUBSPACES_BY_USER_ID_WORKSPACE_ID_SUCCESS,
  SPACE_GET_SUBSPACES_BY_USER_ID_WORKSPACE_ID_FAIL,
  SPACE_GET_USER_LIST_BY_SUBSPACE_ID,
  SPACE_GET_USER_LIST_BY_SUBSPACE_ID_SUCCESS,
  SPACE_GET_USER_LIST_BY_SUBSPACE_ID_FAIL,
  SPACE_SHOW_MORE_SPACES,
  SPACE_SHOW_MORE_SPACES_SUCCESS,
  SPACE_SHOW_MORE_SPACES_FAIL,
  SPACE_SEARCH_SPACE,
  SPACE_SEARCH_SPACE_FAIL,
  SPACE_SEARCH_SPACE_SUCCESS,
  SPACE_RESET_PAGE_NUMBER,
  SPACE_INCREMENT_PAGE_NUMBER,
  SPACE_GET_PAGINATED_USERS_SUCCESS,
  SPACE_GET_PAGINATED_USERS_FAILURE,
  SPACE_GET_PAGINATED_USERS_REQUEST,
  WORKSPACE_UPDATE_USER_SETTINGS,
  GET_SPACES_AND_SUBSPACES_BY_USERID_AND_WORKSPACEID,
  GET_SPACES_AND_SUBSPACES_BY_USERID_AND_WORKSPACEID_SUCCESS,
  GET_SPACES_AND_SUBSPACES_BY_USERID_AND_WORKSPACEID_FAIL,
  SPACE_SET_SPACE_FOCUSED, SPACE_SET_SUBSPACE_FOCUSED, SPACE_GET_USERS_BY_WORKSPACE_ID, SPACE_GET_USERS_BY_WORKSPACE_ID_FAIL, SPACE_GET_USERS_BY_WORKSPACE_ID_SUCCESS,
  SUBSPACE_RENAME_SUBSPACE, SUBSPACE_SHOW_MORE_SUBSPACES, SUBSPACE_SHOW_MORE_SUBSPACES_FAIL, SUBSPACE_SHOW_MORE_SUBSPACES_SUCCESS, SUBSPACE_SHOW_MORE_SUBSPACE_USERS, SUBSPACE_SHOW_MORE_SUBSPACE_USERS_SUCCESS, SUBSPACE_SHOW_MORE_SUBSPACE_USERS_FAIL,
  SPACE_DELETE_SPACE_IMAGE,
  SUBSPACE_RESET_SUBSPACE_USER_LIST,
  SUBSPACE_RESET_SUBSPACE_LIST,
  SET_LOADING,
  SET_WORKSPACE_DISABLED,
  WORKSPACE_REFRESH_USER_MANAGEMENT_SCREEN,
} from "./types";
import { safelyApi } from "../../api/safely";
import ApiEndPoint from "../../constants/apiEndPoint";
import AmazonStorage from "../../amazonStorage";

export const workSpaceUploadImage = (
  accesstokenidp,
  userId,
  workSpaceId,
  base64,
  oldPic
) => async dispatch => {
  try {
    if (oldPic) {
      let imageLocation = oldPic.split("image-workspace/");
      await new AmazonStorage().deleteFileS3(
        "image-workspace/" + imageLocation[1]
      );
    }
    const response = await new AmazonStorage()
      .uploadImageKeyPrefix(
        base64,
        userId,
        workSpaceId,
        dispatch,
        "image-workspace/"
      )
      .then(async data => {
        const picture = data.Location;
        let apirespone = await safelyApi(ApiEndPoint.WorkspacePicture, {
          method: "post",
          headers: { accesstokenidp: accesstokenidp },
          body: { workSpaceId, picture }
        });
        if (apirespone.status) {
          dispatch({ type: SPINNER_POST, payload: false });
        }
        return apirespone;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const saveUserWorkspace = (
  accesstokenidp,
  name,
  userId,
  settings = {}
) => async dispatch => {
  try {
    const response = await safelyApi("/workspace", {
      method: "post",
      body: { name, userId, settings },
      headers: { accesstokenidp: accesstokenidp },
    });

    if (response.status) {
      dispatch({ type: WORKSPACE_CREATE_SUCCESS, payload: response });
    } else {
      dispatch({
        type: WORKSPACE_CREATE_FAIL,
        payload: response.systemError || response.description,
        ...response
      });
    }
    return response;
  } catch (error) {
    dispatch({ type: WORKSPACE_CREATE_FAIL, payload: error });
    return { error, status: false };
  }
};

export const addUserToWorkspace = (userId, workSpaceId, accesstokenidp) => {
  return async dispatch => {
    try {
      dispatch({ type: WORKSPACE_ADD_USER, payload: {} });
      const response = await safelyApi(ApiEndPoint.workSpaceAddUser, {
        method: "post",
        body: { userId, workSpaceId },
        headers: { accesstokenidp: accesstokenidp }
      });
      if (response.status) {
        dispatch({ type: WORKSPACE_ADD_USER_SUCCESS, payload: response });
      } else {
        dispatch({
          type: WORKSPACE_ADD_USER_FAIL,
          payload: {
            message: response.error_description || response.systemError,
            ...response
          }
        });
      }
      return response;
    } catch (e) {
      dispatch({ type: WORKSPACE_ADD_USER_FAIL, payload: e });
      return { e, status: false };
    }
  };
};

export const getWorkspacesList = (
  userId,
  accesstokenidp,
  isUserRegistered = false
) => {
  return async dispatch => {
    try {
      dispatch({ type: WORKSPACE_GET_LIST, payload: {} });
      const response = await safelyApi(
        "/workspace/list/" +
        userId +
        (isUserRegistered === true ? "/true" : ""),
        {
          method: "get",
          headers: { accesstokenidp: accesstokenidp }
        }
      );
      if (response.status) {
        dispatch({ type: WORKSPACE_GET_LIST_SUCCESS, payload: response });
      } else {
        dispatch({
          type: WORKSPACE_GET_LIST_FAIL,
          payload: {
            message: response.error_description || response.systemError,
            ...response
          }
        });
      }
      return response;
    } catch (e) {
      //dispatch({ type: WORKSPACE_GET_LIST_FAIL, payload: e });
      return { e, status: false };
    }
  };
};

export const changeWorkSpaceFocused = workspace => dispatch =>
  dispatch({ type: WORKSPACE_SET_FOCUSED, payload: workspace });

export const changeWorkspaceFocusedForSafelyPass = workspace => dispatch =>
  dispatch({ type: WORKSPACE_SAFELY_PASS_SET_FOCUSED, payload: workspace });

export const setWorkspaceFocused = workspace => dispatch =>
  dispatch({ type: WORKSPACE_SET_FOCUSED, payload: workspace });

export const setWorkspaceSubFocused = workspaceSub => dispatch =>
  dispatch({ type: WORKSPACE_SUB_SET_FOCUSED, payload: workspaceSub });

export const creatingNewWorkspace = creating => dispatch =>
  dispatch({ type: CREATING_NEW_WORKPACE, payload: creating });

export const resetWorkspaceCreated = () => dispatch =>
  dispatch({ type: RESET_WORKSPACE_CREATED, payload: {} });

export const setMessage = message => dispatch =>
  dispatch({ type: SET_MESSAGE, payload: message });

export const addWorkspaceToList = workspace => dispatch => ({
  type: WORKSPACE_ADD_ITEM_TO_LIST,
  payload: workspace
});

export const editWorkSpaceSettings = workspace => dispatch =>
  dispatch({ type: WORKSPACE_UPDATE_SETTINGS, payload: workspace });

export const addWorkspaceSettings = (
  id,
  selfDeclaration,
  verificationPoint,
  locationIsRequired,
  backgroundColor,
  accesstokenidp
) => {
  return async dispatch => {
    try {
      dispatch({ type: WORKSPACE_ADD_SETTINGS, payload: {} });
      const response = await safelyApi("/workspace/settings", {
        method: "put",
        headers: { accesstokenidp: accesstokenidp },
        body: {
          id,
          selfDeclaration,
          verificationPoint,
          locationIsRequired,
          backgroundColor
        }
      });
      if (response.status) {
        dispatch({ type: WORKSPACE_ADD_SETTINGS_SUCCESS, payload: response });
      } else {
        dispatch({
          type: WORKSPACE_ADD_SETTINGS_FAIL,
          payload: {
            message: response.systemError || response.error_description
          }
        });
      }
      return response;
    } catch (e) {
      return { e, status: false };
    }
  };
};

export const renameWorkspace = (id, name) => {
  return async dispatch => {
    try {
      dispatch({ type: WORKSPACE_RENAME, payload: {} });
      const response = await safelyApi("/workspace/", {
        method: "put",
        body: { id, name }
      });
      if (response.status) {
        dispatch({ type: WORKSPACE_RENAME_SUCCESS, payload: response.data });
      } else {
        dispatch({
          type: WORKSPACE_RENAME_FAIL,
          payload: {
            message: response.systemError || response.error_description
          }
        });
      }
      return response;
    } catch (e) {
      return { e, status: false };
    }
  };
};

export const renameWorkspaceSub = (body, accesstokenidp) => {
  return async dispatch => {
    try {
      dispatch({ type: WORKSPACE_RENAME, payload: {} });
      const response = await safelyApi("/workspace/subspace/rename", {
        body,
        method: "put",
        headers: { accesstokenidp: accesstokenidp }
      });
      if (response.status) {
        dispatch({ type: WORKSPACE_RENAME_SUCCESS, payload: response.data });
      } else {
        dispatch({
          type: WORKSPACE_RENAME_FAIL,
          payload: {
            message: response.systemError || response.error_description
          }
        });
      }
      return response;
    } catch (e) {
      return { e, status: false };
    }
  };
};

export const updateMaxOccupancy = (body, accesstokenidp) => {
  return async dispatch => {
    try {
      dispatch({ type: SUBSPACE_UPDATE_MAX_OCCUPANCY, payload: {} });
      const response = await safelyApi(ApiEndPoint.CreateSubWorkspace, {
        body,
        method: "put",
        headers: { accesstokenidp: accesstokenidp }
      });

      if (response.status) {
        dispatch({ type: SUBSPACE_UPDATE_MAX_OCCUPANCY_SUCCESS, payload: { data: response.data, ...body } });
      } else {
        dispatch({
          type: SUBSPACE_UPDATE_MAX_OCCUPANCY_FAIL,
          payload: {
            message: response.systemError || response.error_description
          }
        });
      }
      return response;
    } catch (e) {
      return { e, status: false };
    }
  };
};

export const importContacts = (workSpaceId, addedBy, contacts) => {
  return async dispatch => {
    try {
      dispatch({ type: WORKSPACE_IMPORT_CONTACTS, payload: {} });
      const response = await safelyApi("/magiclink/insertmany", {
        method: "post",
        body: { workSpaceId, addedBy, contacts }
      });
      if (response.status) {
        dispatch({ type: WORKSPACE_IMPORT_CONTACTS_SUCCESS, payload: {} });
      } else {
        dispatch({
          type: WORKSPACE_IMPORT_CONTACTS_FAIL,
          payload: {
            message: response.systemError || response.error_description
          }
        });
      }
      return response;
    } catch (error) {
      return { error, status: false };
    }
  };
};

export const removeMemberWorkspaceAPI = (userId, workSpaceId, checkout) => {
  return async dispatch => {
    try {
      const _checkout = checkout ? { checkOut: checkout } : {};
      const response = await safelyApi(ApiEndPoint.RemoveUserFromASpace, {
        method: "put",
        body: { workSpaceId, userId, ..._checkout }
      });
      return response;
    } catch (e) { }
  };
};

export const removeMemberMultipleSpaces = (userId, workSpaceIds, checkout) => {
  return async dispatch => {
    try {
      const _checkout = checkout ? { checkOut: checkout } : {};
      const response = await safelyApi(ApiEndPoint.RemoveUserFromMultipleSpaces, {
        method: "post",
        body: { workSpaceIds, userId, ..._checkout }
      });
      return response;
    } catch (e) { }
  };
};

export const removeMemberWorkspace = (_workSpacesProps, _userId, _workSpaceId) => dispatch => {
  _workSpacesProps.workspaceFocused.subspaces.forEach(sub => {
    sub.users = sub.users.filter(id => id !== _userId);
    sub.usersCount = sub.users.length;
  });
  _workSpacesProps.workspaceFocused.users = _workSpacesProps.workspaceFocused.users.filter(u => u.id !== _userId);

  _workSpacesProps.workspaceSubFocused.users = _workSpacesProps.workspaceSubFocused.users.filter(id => id !== _userId);
  _workSpacesProps.workspaceSubFocused.usersCount = _workSpacesProps.workspaceSubFocused.users.length;
  _workSpacesProps.list = _workSpacesProps.list.filter(space => space._id !== _workSpaceId);

  dispatch({ type: WORKSPACE_UPDATE_USER, payload: _workSpacesProps });
};

export const createSubWorkspace = (name, workSpaceId, userId, maxOccupancy) => {
  return async dispatch => {
    try {
      const response = await safelyApi(ApiEndPoint.CreateSubWorkspace, {
        method: "post",
        body: { name, workSpaceId, userId, maxOccupancy }
      });
      return response;
    } catch (e) { }
  };
};

export const deleteSubWorkspace = (
  subspaceId,
  workSpaceId,
  userId,
  accesstokenidp
) => {
  return async dispatch => {
    try {
      const response = await safelyApi(ApiEndPoint.CreateSubWorkspace, {
        method: "delete",
        body: { subspaceId, workSpaceId },
        headers: { accesstokenidp: accesstokenidp }
      });
      return response;
    } catch (e) {
      return { e, status: false };
    }
  };
};

export const addMemberSubWorkspace = (userIds, workSpaceId, subspaceId) => {
  return async dispatch => {
    try {
      const response = await safelyApi(ApiEndPoint.AdduserToSubWorkspace, {
        method: "post",
        body: { userIds, workSpaceId, subspaceId }
      });
      return response;
    } catch (e) { }
  };
};

export const removeMemberSubWorkspace = (userId, workSpaceId, subspaceId) => {
  return async dispatch => {
    try {
      const response = await safelyApi(ApiEndPoint.RemoveuserToSubWorkspace, {
        method: "put",
        body: { userId, workSpaceId, subspaceId }
      });

      return response;
    } catch (e) {
      return { e, status: false };
    }
  };
};

export const postSubspaceGrantaccess = (
  workSpaceId,
  userId,
  subspaceId,
  accesstokenidp
) => {
  return async dispatch => {
    try {
      const response = await safelyApi(ApiEndPoint.SubspaceGrantaccess, {
        method: "post",
        body: { workSpaceId, userId, subspaceId },
        headers: { accesstokenidp: accesstokenidp }
      });
      return response;
    } catch (e) { }
  };
};

export const setWorkspaceAtLogin = (userId, accesstokenidp, workSpaceId) => {
  return async dispatch => {
    try {
      dispatch({ type: WORKSPACE_GET_LIST, payload: {} });
      const response = await safelyApi("/workspace/list/" + userId, {
        method: "get",
        headers: { accesstokenidp: accesstokenidp }
      });
      if (response.status) {
        const list = response.data;
        const focused = list.find(x => workSpaceId === x._id) || list[0];
        dispatch({ type: SET_WORKSPACE_AT_LOGIN, payload: { focused, list } });
      } else {
        dispatch({
          type: WORKSPACE_GET_LIST_FAIL,
          payload: {
            message: response.error_description || response.systemError,
            ...response
          }
        });
      }
      return response;
    } catch (e) {
      //dispatch({ type: WORKSPACE_GET_LIST_FAIL, payload: e });
      return { e, status: false };
    }
  };
};

export const resetworkspaceFocused = () => dispatch =>
  dispatch({ type: RESET_WORKSPACE_FOCUSED });

export const deleteSpaceImageHandler = (
  accesstokenidp,
  workSpaceId,
  oldPic
) => async dispatch => {
  try {
    const response = await safelyApi(
      ApiEndPoint.WorkspacePicture + "/" + workSpaceId, {
      method: "delete",
      headers: { accesstokenidp: accesstokenidp }
    });

    if (response.status) {
      let imageLocation = oldPic.split("image-workspace/");
      await new AmazonStorage().deleteFileS3(
        "image-workspace/" + imageLocation[1]
      );
      dispatch({ type: SPACE_DELETE_SPACE_IMAGE, payload: {} })
    }

    return response;
  } catch (error) {
    return { error, status: false };
  }
};
export const checkDeleteSpace = (workSpaceId, accesstokenidp) => {
  return async dispatch => {
    try {
      const response = await safelyApi(ApiEndPoint.checkDeleteStatus, {
        method: "post",
        body: { workSpaceId },
        headers: { accesstokenidp: accesstokenidp }
      });
      return response;
    } catch (e) {
      return { e, status: false };
    }
  };
};

export const deleteSpace = (workSpaceId, accesstokenidp) => {
  return async dispatch => {
    try {
      const response = await safelyApi(ApiEndPoint.GetChecklistList, {
        method: "delete",
        body: { workSpaceId },
        headers: { accesstokenidp: accesstokenidp }
      });
      return response;
    } catch (e) {
      return { e, status: false };
    }
  };
};

export const checkIsDeleteSpace = (id, accesstokenidp) => {
  return async dispatch => {
    try {
      const response = await safelyApi(ApiEndPoint.GetChecklistList + id, {
        method: "get",
        headers: { accesstokenidp: accesstokenidp }
      });
      return response;
    } catch (e) {
      return { e, status: false };
    }
  };
};

export const checkIsDeletesubSpace = (id, subspaceId, accesstokenidp) => {
  return async dispatch => {
    try {
      const response = await safelyApi(
        ApiEndPoint.GetChecklistList + id + "/" + subspaceId,
        {
          method: "get",
          headers: { accesstokenidp: accesstokenidp }
        }
      );
      return response;
    } catch (e) {
      return { e, status: false };
    }
  };
};

export const getCheckIntoSusbpaceList = (
  workSpaceId,
  userId,
  accesstokenidp
) => {
  return async dispatch => {
    try {
      dispatch({ type: SUBSPACE_GET_CHECK_INTO_SUBSPACE_LIST, payload: {} });
      const response = await safelyApi(ApiEndPoint.getCheckIntoSubspaceList, {
        method: "post",
        body: { workSpaceId, userId },
        headers: { accesstokenidp: accesstokenidp }
      });

      if (response.status) {
        dispatch({
          type: SUBSPACE_GET_CHECK_INTO_SUBSPACE_LIST_SUCCESS,
          payload: response.data
        });
      } else {
        dispatch({
          type: SUBSPACE_GET_CHECK_INTO_SUBSPACE_LIST_FAIL,
          payload: {
            message: response.error_description || response.systemError,
            ...response
          }
        });
      }
      return response;
    } catch (e) {
      return { e, status: false };
    }
  };
};

export const checkIntoSubspace = (
  workSpaceId,
  userId,
  subspaceId,
  accesstokenidp
) => {
  return async dispatch => {
    try {
      dispatch({ type: SUBSPACE_CHECK_INTO_SUBSPACE, payload: {} });
      const response = await safelyApi(ApiEndPoint.checkIntoSubspace, {
        method: "post",
        body: { workSpaceId, userId, subspaceId },
        headers: { accesstokenidp: accesstokenidp }
      });

      if (response.status) {
        dispatch({ type: SUBSPACE_CHECK_INTO_SUBSPACE_SUCCESS, payload: response.data });
      } else {
        dispatch({
          type: SUBSPACE_CHECK_INTO_SUBSPACE_FAIL,
          payload: {
            message: response.error_description || response.systemError,
            ...response
          }
        });
      }

      return response;
    } catch (e) {
      return { e, status: false };
    }
  };
};

export const checkOutIntoSubspace = (accessAuthorizationId, accesstokenidp) => {
  return async dispatch => {
    try {
      dispatch({ type: SUBSPACE_CHECK_OUT_INTO_SUBSPACE, payload: {} });
      const response = await safelyApi(ApiEndPoint.checkOutIntoSubspace, {
        method: "post",
        body: { accessAuthorizationId },
        headers: { accesstokenidp: accesstokenidp }
      });

      if (response.status) {
        dispatch({ type: SUBSPACE_CHECK_OUT_INTO_SUBSPACE_SUCCESS, payload: response.data });
      } else {
        dispatch({
          type: SUBSPACE_CHECK_OUT_INTO_SUBSPACE_FAIL,
          payload: {
            message: response.error_description || response.systemError,
            ...response
          }
        });
      }

      return response;
    } catch (e) {
      return { e, status: false };
    }
  };
};

export const getActivesSubspaceCheckIn = (userId, accesstokenidp) => {
  return async dispatch => {
    try {
      dispatch({ type: SUBSPACE_GET_ACTIVES_SUBSPACE_CHECK_IN, payload: {} });
      const response = await safelyApi(
        ApiEndPoint.getActiveSubspaceCheckIn + userId,
        {
          method: "get",
          headers: { accesstokenidp: accesstokenidp }
        }
      );

      if (response.status) {
        dispatch({
          type: SUBSPACE_GET_ACTIVES_SUBSPACE_CHECK_IN_SUCCESS,
          payload: response.data
        });
      } else {
        dispatch({
          type: SUBSPACE_GET_ACTIVES_SUBSPACE_CHECK_IN_FAIL,
          payload: {
            message: response.error_description || response.systemError,
            ...response
          }
        });
      }

      return response;
    } catch (e) {
      return { e, status: false };
    }
  };
};

export const refreshCheckIntoSubspaceScreen = refresh => dispatch =>
  dispatch({ type: REFRESH_CHECK_INTO_SUBSPACE_SCREEEN, payload: { refresh } });

export const resetCheckIntoSubspaceList = () => dispatch =>
  dispatch({ type: SUBSPACE_REST_CHECK_INTO_SUBSPACE_LIST, payload: {} });

export const checkIntoSubspacePushNotification = (data) => dispatch =>
  dispatch({ type: SUBSPACE_CHECK_INTO_SUBSPACE_PUSH_NOTIFICATION, payload: data });

export const checkOutIntoSubspacePushNotification = (data) => dispatch => {
  dispatch({ type: SUBSPACE_CHECK_OUT_PUSH_NOTIFICATION, payload: data });
}

export const updateEditedUser = (data, workSpacesProps) => dispatch => {
  if (workSpacesProps.workspaceCreated) {
    let workspaceCreatedIndex = workSpacesProps.workspaceCreated.users.findIndex(
      obj => obj.id === data.id
    );
    workSpacesProps.workspaceCreated.users[workspaceCreatedIndex].name =
      data.name;
    workSpacesProps.workspaceCreated.users[workspaceCreatedIndex].lastName =
      data.lastName;
    workSpacesProps.workspaceCreated.users[
      workspaceCreatedIndex
    ].backgroundPicture = data.backgroundPicture;
    workSpacesProps.workspaceCreated.users[workspaceCreatedIndex].picture =
      data.picture;
  }
  if (workSpacesProps.workspaceFocused) {
    let workspaceFocusedIndex = workSpacesProps.workspaceFocused.users.findIndex(
      obj => obj.id === data.id
    );
    workSpacesProps.workspaceFocused.users[workspaceFocusedIndex].name =
      data.name;
    workSpacesProps.workspaceFocused.users[workspaceFocusedIndex].lastName =
      data.lastName;
    workSpacesProps.workspaceFocused.users[
      workspaceFocusedIndex
    ].backgroundPicture = data.backgroundPicture;
    workSpacesProps.workspaceFocused.users[workspaceFocusedIndex].picture =
      data.picture;
  }
  if (workSpacesProps.workspaceFocusedForSafelyPass) {
    let workspaceFocusedForSafelyPassIndex = workSpacesProps.workspaceFocusedForSafelyPass.users.findIndex(
      obj => obj.id === data.id
    );
    workSpacesProps.workspaceFocusedForSafelyPass.users[
      workspaceFocusedForSafelyPassIndex
    ].name = data.name;
    workSpacesProps.workspaceFocusedForSafelyPass.users[
      workspaceFocusedForSafelyPassIndex
    ].lastName = data.lastName;
    workSpacesProps.workspaceFocusedForSafelyPass.users[
      workspaceFocusedForSafelyPassIndex
    ].backgroundPicture = data.backgroundPicture;
    workSpacesProps.workspaceFocusedForSafelyPass.users[
      workspaceFocusedForSafelyPassIndex
    ].picture = data.picture;
  }
  if (workSpacesProps.list) {
    workSpacesProps.list.map(d => {
      let listIndex = d.users.findIndex(obj => obj.id === data.id);
      d.users[listIndex].name = data.name;
      d.users[listIndex].lastName = data.lastName;
      d.users[listIndex].backgroundPicture = data.backgroundPicture;
      d.users[listIndex].picture = data.picture;
      return d;
    });
  }
  dispatch({ type: WORKSPACE_UPDATE_USER, payload: workSpacesProps });
};

export const updateWorkSpacePreferences = (
  data,
  workSpacesProps
) => dispatch => {
  if (workSpacesProps.workspaceCreated) {
    if (data._id === workSpacesProps.workspaceCreated._id) {
      workSpacesProps.workspaceCreated.settings = data.settings;
    }
  }
  if (workSpacesProps.spaceFocused) {
    if (data._id === workSpacesProps.spaceFocused._id) {
      workSpacesProps.spaceFocused.settings = data.settings;
    }
  }
  if (workSpacesProps.workspaceFocused) {
    if (data._id === workSpacesProps.workspaceFocused._id) {
      workSpacesProps.workspaceFocused.settings = data.settings;
    }
  }
  if (workSpacesProps.workspaceFocusedForSafelyPass) {
    if (data._id === workSpacesProps.workspaceFocusedForSafelyPass._id) {
      workSpacesProps.workspaceFocusedForSafelyPass.settings = data.settings;
    }
  }
  if (workSpacesProps.list) {
    let listIndex = workSpacesProps.list.findIndex(obj => obj._id === data._id);
    workSpacesProps.list[listIndex].settings = data.settings;
  }
  dispatch({ type: WORKSPACE_UPDATE_USER, payload: workSpacesProps });
};

export const updateWorkSpaceName = (data, workSpacesProps) => dispatch => {
  if (workSpacesProps.workspaceCreated) {
    if (data._id === workSpacesProps.workspaceCreated._id) {
      workSpacesProps.workspaceCreated.name = data.name;
    }
  }
  if (workSpacesProps.spaceFocused) {
    if (data._id === workSpacesProps.spaceFocused._id) {
      workSpacesProps.spaceFocused.name = data.name;
    }
  }
  if (workSpacesProps.workspaceFocused) {
    if (data._id === workSpacesProps.workspaceFocused._id) {
      workSpacesProps.workspaceFocused.name = data.name;
    }
  }
  if (workSpacesProps.workspaceFocusedForSafelyPass) {
    if (data._id === workSpacesProps.workspaceFocusedForSafelyPass._id) {
      workSpacesProps.workspaceFocusedForSafelyPass.name = data.name;
    }
  }
  if (workSpacesProps.list) {
    let listIndex = workSpacesProps.list.findIndex(obj => obj._id === data._id);
    workSpacesProps.list[listIndex].name = data.name;
  }
  dispatch({ type: WORKSPACE_UPDATE_USER, payload: workSpacesProps });
};

export const updateWorkSpaceImage = (data, workSpacesProps) => dispatch => {
  if (workSpacesProps.workspaceCreated) {
    if (data._id === workSpacesProps.workspaceCreated._id) {
      workSpacesProps.workspaceCreated.picture = data.picture;
    }
  }
  if (workSpacesProps.spaceFocused) {
    if (data._id === workSpacesProps.spaceFocused._id) {
      workSpacesProps.spaceFocused.picture = data.picture;
    }
  }
  if (workSpacesProps.workspaceFocused) {
    if (data._id === workSpacesProps.workspaceFocused._id) {
      workSpacesProps.workspaceFocused.picture = data.picture;
    }
  }
  if (workSpacesProps.workspaceFocusedForSafelyPass) {
    if (data._id === workSpacesProps.workspaceFocusedForSafelyPass._id) {
      workSpacesProps.workspaceFocusedForSafelyPass.picture = data.picture;
    }
  }
  if (workSpacesProps.list) {
    let listIndex = workSpacesProps.list.findIndex(obj => obj._id === data._id);
    workSpacesProps.list[listIndex].picture = data.picture;
  }
  dispatch({ type: WORKSPACE_UPDATE_USER, payload: workSpacesProps });
};

export const updateRemoveUserformSpace = (userIDDelete, workSpaceId, subSpaceId, workSpacesProps) => dispatch => {
  if (workSpacesProps.workspaceFocused && workSpaceId === workSpacesProps.workspaceFocused._id &&
    workSpacesProps.workspaceSubFocused && subSpaceId === workSpacesProps.workspaceSubFocused._id) {
    let subSpaceIndex = workSpacesProps.workspaceSubFocused.users.indexOf(userIDDelete);
    if (subSpaceIndex > -1) {
      workSpacesProps.workspaceSubFocused.users.splice(subSpaceIndex, 1);
      workSpacesProps.workspaceSubFocused.usersCount = workSpacesProps.workspaceSubFocused.usersCount - 1;
    }
  }
  dispatch({ type: WORKSPACE_UPDATE_USER, payload: workSpacesProps });
};

export const updateCreateSubSpaceMember = (data, workSpacesProps, subspaceId) => dispatch => {
  let subSpaceIndex = data.subspaces.findIndex(obj => obj._id === subspaceId);
  workSpacesProps.workspaceFocused.subspaces = data.subspaces;
  workSpacesProps.workspaceSubFocused = data.subspaces[subSpaceIndex];
  dispatch({ type: WORKSPACE_UPDATE_USER, payload: workSpacesProps });
};

export const updatedeleteSubSpace = (data, workSpacesProps) => dispatch => {
  workSpacesProps.workspaceFocused.subspaces = data.subspaces;
  workSpacesProps.workspaceSubFocused = data.subspaces[0];
  dispatch({ type: WORKSPACE_UPDATE_USER, payload: workSpacesProps });
};


export const updatedeleteSpace = (workSpacesProps, workspaceId) => dispatch => {
  let spaceIndex = workSpacesProps.list.findIndex(obj => obj._id === workspaceId);
  if (spaceIndex > -1) {
    workSpacesProps.list.splice(spaceIndex, 1);
  }

  workSpacesProps.workspaceFocused = workSpacesProps.list.length > 0 ? workSpacesProps.list[0] : {
    _id: null,
    users: [],
    name: "",
    userVerificationChecklist: [
      {
        inputs: []
      }
    ],
    checkPointChecklist: [
      {
        inputs: []
      }
    ],
    settings: {
      locationFrequency: 30 //seg
    }
  };
  workSpacesProps.workspaceFocusedForSafelyPass = {
    _id: null,
    users: [],
    name: "",
    userVerificationChecklist: [
      {
        inputs: []
      }
    ],
    checkPointChecklist: [
      {
        inputs: []
      }
    ],
    settings: {
      locationFrequency: 30 //seg
    }
  };
  workSpacesProps.workspaceCreated = null;
  workSpacesProps.workspaceSubFocused = {
    _id: null,
    users: [],
    usersCount: null,
    name: ""
  };
  workSpacesProps.checkIntoSubspaceList = [];
  workSpacesProps.activesSubspaceCheckIn = [];
  workSpacesProps.refreshCheckIntoSubspaceScreen = false;
  dispatch({ type: WORKSPACE_UPDATE_USER, payload: workSpacesProps });
};

export const updateVerficationFrom = (data, workSpacesProps) => dispatch => {
  let spaceIndex = workSpacesProps.workspaceFocused.checkPointChecklist.findIndex(obj => obj._id === data._id);
  workSpacesProps.workspaceFocused.checkPointChecklist[spaceIndex] = data;
  dispatch({ type: WORKSPACE_UPDATE_USER, payload: workSpacesProps });
};

export const updateUserVerificationFrom = (data, workSpacesProps) => dispatch => {
  let spaceIndex = workSpacesProps.workspaceFocused.userVerificationChecklist.findIndex(obj => obj._id === data._id);
  workSpacesProps.workspaceFocused.userVerificationChecklist[spaceIndex] = data;
  dispatch({ type: WORKSPACE_UPDATE_USER, payload: workSpacesProps });
};

export const pushUpdatedeleteSpace = (workSpacesProps, workspaceId) => dispatch => {
  let spaceIndex = workSpacesProps.list.findIndex(obj => obj._id === workspaceId);
  if (spaceIndex > -1) {
    workSpacesProps.list.splice(spaceIndex, 1);
  }

  dispatch({ type: WORKSPACE_UPDATE_USER, payload: workSpacesProps });
};

export const pushUpdatedeleteSubspace = (workSpacesProps, workspaceId, subSpaceId) => dispatch => {
  let spaceIndex = workSpacesProps.list.findIndex(obj => obj._id === workspaceId);
  if (spaceIndex > -1) {
    let spaceSubIndex = workSpacesProps.list[spaceIndex].subspaces.findIndex(obj => obj._id === subSpaceId);
    if (spaceSubIndex > -1) {
      workSpacesProps.list[spaceIndex].subspaces.splice(spaceSubIndex, 1);
    }
    let spaceSubFoucsIndex = workSpacesProps.workspaceFocused.subspaces.findIndex(obj => obj._id === subSpaceId);
    if (spaceSubFoucsIndex > -1) {
      workSpacesProps.workspaceFocused.subspaces.splice(spaceSubFoucsIndex, 1);
    }
    if (subSpaceId === workSpacesProps.workspaceSubFocused._id) {
      workSpacesProps.workspaceSubFocused = workSpacesProps.list[spaceIndex].subspaces[0];
    }
  }
  dispatch({ type: WORKSPACE_UPDATE_USER, payload: workSpacesProps });
};

export const userLeaveSpace = (workSpaceId, userId, accesstokenidp) => {
  return async dispatch => {
    try {
      const response = await safelyApi(ApiEndPoint.LeaveSpace, {
        method: "put",
        body: { workSpaceId, userId },
        headers: { accesstokenidp: accesstokenidp }
      });
      return response;
    } catch (e) {
      return { e, status: false };
    }
  };
};

//TODO Get spaces & subSpaces list by wserId & workSpaceId
export const getSpacesAndUserSubSpaces = (data) => {
  return async (dispatch, getState) => {
    try {
      const {
        user: { accesstokenidp }
      } = getState();
      dispatch({ type: GET_SPACES_AND_SUBSPACES_BY_USERID_AND_WORKSPACEID, payload: {} });
      const response = await safelyApi(
        ApiEndPoint.GetSubspacesByUserIdWorkSpaceId,
        {
          method: "post",
          headers: { accesstokenidp: accesstokenidp },
          body: { ...data }
        }
      );
      if (response.status) {
        dispatch({ type: GET_SPACES_AND_SUBSPACES_BY_USERID_AND_WORKSPACEID_SUCCESS, payload: response });
      } else {
        dispatch({
          type: GET_SPACES_AND_SUBSPACES_BY_USERID_AND_WORKSPACEID_FAIL,
          payload: {
            message: response.error_description || response.systemError,
            ...response
          }
        });
      }
      return response;
    } catch (e) {
      dispatch({
        type: GET_SPACES_AND_SUBSPACES_BY_USERID_AND_WORKSPACEID_FAIL,
        payload: {
          message: e.message
        }
      });
      return { e, status: false };
    }
  }
}

//TODO Add new actions
export const getWorkspaceListWithPagination = (showAll = false) => {
  return async (dispatch, getState) => {
    try {
      const { id: userId, accesstokenidp } = getState().user;
      dispatch({ type: SPACE_GET_LIST_OF_SPACES_WITH_PAGINATION, payload: {} });
      dispatch({ type: SPACE_RESET_PAGE_NUMBER, payload: {} });
      const { pageNumber, pageLimit } = getState().workspace;
      let response = null;

      if (!showAll) {
        response = await safelyApi(
          ApiEndPoint.GetWorkspaceListWithPagination + userId + '/' + pageNumber + '/' + pageLimit,
          {
            method: "get",
            headers: { accesstokenidp: accesstokenidp }
          }
        );
      } else {
        response = await safelyApi(
          ApiEndPoint.GetWorkspaceListWithPagination + userId,
          {
            method: "get",
            headers: { accesstokenidp: accesstokenidp }
          }
        );
      }

      if (response.status) {
        dispatch({ type: SPACE_GET_LIST_OF_SPACES_WITH_PAGINATION_SUCCESS, payload: response });
      } else {
        dispatch({
          type: SPACE_GET_LIST_OF_SPACES_WITH_PAGINATION_FAIL,
          payload: {
            message: response.error_description || response.systemError,
            ...response
          }
        });
      }

      return response;
    } catch (e) {
      dispatch({
        type: SPACE_GET_LIST_OF_SPACES_WITH_PAGINATION_FAIL,
        payload: {
          message: e.message
        }
      });
      return { e, status: false };
    }
  }
}

export const showMoreSpaces = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SPACE_SHOW_MORE_SPACES, payload: {} });
      dispatch({ type: SPACE_INCREMENT_PAGE_NUMBER, payload: {} });
      const { id: userId, accesstokenidp } = getState().user;
      const { pageNumber, pageLimit } = getState().workspace;
      const response = await safelyApi(
        ApiEndPoint.GetWorkspaceListWithPagination + userId + '/' + pageNumber + '/' + pageLimit,
        {
          method: "get",
          headers: { accesstokenidp: accesstokenidp }
        }
      );

      if (response.status) {
        dispatch({ type: SPACE_SHOW_MORE_SPACES_SUCCESS, payload: response });
      } else {
        dispatch({
          type: SPACE_SHOW_MORE_SPACES_FAIL,
          payload: {
            message: response.error_description || response.systemError,
            ...response
          }
        });
      }

      return response;
    } catch (e) {
      dispatch({
        type: SPACE_SHOW_MORE_SPACES_FAIL,
        payload: {
          message: e.message
        }
      });
      return { e, status: false };
    }

  }
}

export const getAllPaginatedSpaces = () => {
  return async (dispatch, getState) => {
    try {
      const { id: userId, accesstokenidp } = getState().user;
      const { totalCount } = getState().workspace;
      const response = await safelyApi(ApiEndPoint.GetWorkspaceListWithPagination + userId + '/1/' + totalCount,
        {
          method: "get",
          headers: { accesstokenidp: accesstokenidp }
        }
      );
      return response;

    } catch (e) {
      return { e, status: false };
    }
  }
}

export const searchSpace = (searchedSpace = '') => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SPACE_SEARCH_SPACE, payload: {} });
      const { id: userId, accesstokenidp } = getState().user;
      const { pageNumber: pageNumberOld } = getState().workspace;
      if (pageNumberOld > 1) {
        dispatch({ type: SPACE_RESET_PAGE_NUMBER, payload: {} });
      }
      const { pageNumber: pageNumberUpdated, pageLimit } = getState().workspace;

      const response = await safelyApi(
        ApiEndPoint.searchSpace + userId + '/' + pageNumberUpdated + '/' + pageLimit + '?name=' + searchedSpace,
        {
          method: "get",
          headers: { accesstokenidp: accesstokenidp }
        }
      );

      if (response.status) {
        dispatch({ type: SPACE_SEARCH_SPACE_SUCCESS, payload: response });
      } else {
        dispatch({
          type: SPACE_SEARCH_SPACE_FAIL,
          payload: {
            message: response.error_description || response.systemError,
            ...response
          }
        });
      }
      return response;
    } catch (e) {
      dispatch({
        type: SPACE_SEARCH_SPACE_FAIL,
        payload: {
          message: e.message
        }
      });
      return { e, status: false };
    }
  }
}

export const getSubspacesByUserIdWorkSpaceId = (name = '') => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SPACE_GET_SUBSPACES_BY_USER_ID_WORKSPACE_ID, payload: {} });
      dispatch({ type: SPACE_RESET_PAGE_NUMBER, payload: {} });
      const { id: userId, accesstokenidp } = getState().user;
      const { pageNumber: pageNo, pageLimit: limit } = getState().workspace;
      const { _id: workSpaceId } = getState().workspace.spaceFocused;

      const response = await safelyApi(
        ApiEndPoint.GetSubspacesByUserIdWorkSpaceIdWithPagination,
        {
          method: "post",
          body: { workSpaceId, userId, name, pageNo, limit },
          headers: { accesstokenidp: accesstokenidp }
        }
      );

      if (response.status) {
        dispatch({ type: SPACE_GET_SUBSPACES_BY_USER_ID_WORKSPACE_ID_SUCCESS, payload: response });
      } else {
        dispatch({
          type: SPACE_GET_SUBSPACES_BY_USER_ID_WORKSPACE_ID_FAIL,
          payload: {
            message: response.error_description || response.systemError,
            ...response
          }
        });
      }
      return response;
    } catch (e) {
      dispatch({
        type: SPACE_GET_SUBSPACES_BY_USER_ID_WORKSPACE_ID_FAIL,
        payload: {
          message: e.message
        }
      });
      return { e, status: false };
    }
  }
}

export const getUserListBySubspaceId = (name) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SPACE_GET_USER_LIST_BY_SUBSPACE_ID, payload: {} });
      dispatch({ type: SPACE_RESET_PAGE_NUMBER, payload: {} });
      const { id: userId, accesstokenidp } = getState().user;
      const { pageNumber: pageNo, pageLimit: limit } = getState().workspace;

      const { _id: subspaceId } = getState().workspace.subspaceFocused;


      const response = await safelyApi(
        ApiEndPoint.GetUserListBySubspaceId,
        {
          method: "post",
          body: { subspaceId, name, pageNo, limit },
          headers: { accesstokenidp: accesstokenidp }
        }
      );
      if (response.status) {
        dispatch({ type: SPACE_GET_USER_LIST_BY_SUBSPACE_ID_SUCCESS, payload: response });
      } else {
        dispatch({
          type: SPACE_GET_USER_LIST_BY_SUBSPACE_ID_FAIL,
          payload: {
            message: response.error_description || response.systemError,
            ...response
          }
        });
      }
      return response;
    } catch (e) {
      dispatch({
        type: SPACE_GET_USER_LIST_BY_SUBSPACE_ID_FAIL,
        payload: {
          message: e.message
        }
      });
      return { e, status: false };
    }
  }
}

export const setSpaceFocused = space => dispatch =>
  dispatch({ type: SPACE_SET_SPACE_FOCUSED, payload: space });

export const setSubspaceFocused = subspace => dispatch =>
  dispatch({ type: SPACE_SET_SUBSPACE_FOCUSED, payload: subspace })

export const getUserListByWorkspaceId = (accesstokenidp, workSpaceId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SPACE_GET_USERS_BY_WORKSPACE_ID, payload: {} });
      const response = await safelyApi(
        ApiEndPoint.getUsersByWorkspaceId + workSpaceId,
        {
          method: "get",
          headers: { accesstokenidp: accesstokenidp }
        }
      );
      if (response.status) {
        dispatch({ type: SPACE_GET_USERS_BY_WORKSPACE_ID_SUCCESS, payload: response.data });
      } else {
        dispatch({
          type: SPACE_GET_USERS_BY_WORKSPACE_ID_FAIL,
          payload: {
            message: response.error_description || response.systemError,
            ...response
          }
        });
      }
      return response;
    } catch (e) {
      dispatch({
        type: SPACE_GET_USERS_BY_WORKSPACE_ID_FAIL,
        payload: {
          message: e.message
        }
      });

      return { e, status: false };
    }
  }
}

export const renameSubspace = (subspaceId, subspaceName) => dispatch =>
  dispatch({ type: SUBSPACE_RENAME_SUBSPACE, payload: { subspaceId, subspaceName } })

export const showMoreSubspaces = (name = '') => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SUBSPACE_SHOW_MORE_SUBSPACES, payload: {} });
      dispatch({ type: SPACE_INCREMENT_PAGE_NUMBER, payload: {} });

      const { id: userId, accesstokenidp } = getState().user;
      const { pageNumber: pageNo, pageLimit: limit } = getState().workspace;
      const { _id: workSpaceId } = getState().workspace.spaceFocused;

      const response = await safelyApi(
        ApiEndPoint.GetSubspacesByUserIdWorkSpaceIdWithPagination,
        {
          method: "post",
          body: { workSpaceId, userId, name, pageNo, limit },
          headers: { accesstokenidp: accesstokenidp }
        }
      );

      if (response.status) {
        dispatch({ type: SUBSPACE_SHOW_MORE_SUBSPACES_SUCCESS, payload: response });
      } else {
        dispatch({
          type: SUBSPACE_SHOW_MORE_SUBSPACES_FAIL,
          payload: {
            message: response.error_description || response.systemError,
            ...response
          }
        });
      }

      return response;
    } catch (e) {
      dispatch({
        type: SUBSPACE_SHOW_MORE_SUBSPACES_FAIL,
        payload: {
          message: e.message
        }
      });
      return { e, status: false };
    }
  }
}

export const showMoreSubspaceUsers = (name = '') => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SUBSPACE_SHOW_MORE_SUBSPACE_USERS, payload: {} });
      dispatch({ type: SPACE_INCREMENT_PAGE_NUMBER, payload: {} });

      const { accesstokenidp } = getState().user;
      const { pageNumber: pageNo, pageLimit: limit } = getState().workspace;

      const { _id: subspaceId } = getState().workspace.subspaceFocused;


      const response = await safelyApi(
        ApiEndPoint.GetUserListBySubspaceId,
        {
          method: "post",
          body: { subspaceId, name, pageNo, limit },
          headers: { accesstokenidp: accesstokenidp }
        }
      );
      if (response.status) {
        dispatch({ type: SUBSPACE_SHOW_MORE_SUBSPACE_USERS_SUCCESS, payload: response });
      } else {
        dispatch({
          type: SUBSPACE_SHOW_MORE_SUBSPACE_USERS_FAIL,
          payload: {
            message: response.error_description || response.systemError,
            ...response
          }
        });
      }
      return response;
    } catch (e) {
      dispatch({
        type: SUBSPACE_SHOW_MORE_SUBSPACE_USERS_FAIL,
        payload: {
          message: e.message
        }
      });
      return { e, status: false };
    }
  }
}

export const getsubSpaceUnderSpace = (workSpaceId, userId) => {
  return async (dispatch) => {
    try {
      const response = await safelyApi(
        ApiEndPoint.getsubSpaceUnderSpaceApi + '/' + userId + '/' + workSpaceId,
        {
          method: "get"
        }
      );
      return response;
    } catch (e) {
      return null;
    }
  }
}

export const refreshUserManagementScreen = (refresh) => dispatch => dispatch({ type: WORKSPACE_REFRESH_USER_MANAGEMENT_SCREEN, payload: { refresh } });

export const getPaginatedUser = (body, page, limit, accesstokenidp) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SPACE_GET_PAGINATED_USERS_REQUEST });
      const response = await safelyApi('/workspace/userManagement/' + page + '/' + limit, { body, method: 'post', headers: { accesstokenidp: accesstokenidp } });
      if (response.status) { dispatch({ type: SPACE_GET_PAGINATED_USERS_SUCCESS, payload: { paginatedUsers: response.data, totalCount: response.totalCount } }); }
      else { dispatch({ type: SPACE_GET_PAGINATED_USERS_FAILURE, payload: { message: response.error_description || response.systemError, ...response } }); }
      return response;
    } catch (e) {
      dispatch({ type: SPACE_GET_PAGINATED_USERS_FAILURE, payload: e });
      return { e, status: false };
    }
  }
}

export const updateSpaceSettingNotifation = (dataResponce, workSpacesProps) => {
  return async (dispatch) => {

    let spaceIndex = workSpacesProps.list.findIndex(obj => obj._id === dataResponce._id);
    if (spaceIndex > -1) {
      workSpacesProps.list[spaceIndex].settings = dataResponce.settings;
    }
    if (workSpacesProps.spacesListWithPagination) {
      let spaceIndex1 = workSpacesProps.spacesListWithPagination.spaces.findIndex(obj => obj._id === dataResponce._id);
      if (spaceIndex1 > -1) {
        workSpacesProps.spacesListWithPagination.spaces[spaceIndex1].settings = dataResponce.settings;
      }
    }
    if (workSpacesProps.workspaceFocused._id === dataResponce._id) {
      workSpacesProps.workspaceFocused.settings = dataResponce.settings
    }

    if (workSpacesProps.workspaceFocusedForSafelyPass._id === dataResponce._id) {
      workSpacesProps.workspaceFocusedForSafelyPass.settings = dataResponce.settings
    }

    if (workSpacesProps.spaceFocused._id === dataResponce._id) {
      workSpacesProps.spaceFocused.settings = dataResponce.settings
    }

    dispatch({ type: WORKSPACE_UPDATE_USER_SETTINGS, payload: workSpacesProps });

  }
}

export const resetSubspaceUserList = () => dispatch =>
  dispatch({ type: SUBSPACE_RESET_SUBSPACE_USER_LIST, payload: {} });

export const resetSubspaceList = () => dispatch =>
  dispatch({ type: SUBSPACE_RESET_SUBSPACE_LIST, payload: {} })

export const checkWorkspaceIsDisabled = (userId, workspaceId, redirect = "") => {
  return async dispatch => {
    try {
      dispatch({ type: SET_LOADING, payload: true });
      const response = await safelyApi(
        "/accessauthorization/disabledstatus",
        {
          method: "post",
          body: { userId, workspaceId }
        }
      );
      dispatch({ type: SET_LOADING, payload: false });
      if (response) {
        if (response.data)
          dispatch({
            type: SET_WORKSPACE_DISABLED,
            payload: { showModal: response.data.disabled, redirect: redirect, data: response.data }
          });
        return response;
      }
      else return { status: false };
    } catch (e) {
      dispatch({ type: SET_LOADING, payload: false });
      return { e, status: false };
    }
  }
}

export const setWorkspaceDisabled = (showModal, data = null) => dispatch =>
  dispatch({ type: SET_WORKSPACE_DISABLED, payload: { showModal, data } });