import React, { useState } from "react";
import StyledInput from "../../styled-components/general/Input";
import StyledIcon from "../../styled-components/general/Icon";

const InputPassword = (props) => {
  const [visible, showPassword] = useState(false);
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <StyledInput
        {...props}
        id="password"
        // className={props.className}
        borderradius={props.borderradius}
        border={props.border}
        paddingright="42.5px"
      />

      <a
        style={{
          marginRight: 0,
          position: "absolute",
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: "3",
          display: "block",
          width: "34px",
          lineHeight: "35px",
          textAlign: "center",
        }}
        onClick={(event) => {
          event.preventDefault();
          let inputPassword = document.getElementById("password");
          if (inputPassword.type === "text") {
            showPassword(false);
            inputPassword.type = "password";
          } else if (inputPassword.type === "password") {
            showPassword(true);
            inputPassword.type = "text";
          }
        }}
        id="show_hide_password"
      >
        <StyledIcon
          id="eyeIcon"
          className={`fa ${visible ? "fa-eye" : "fa-eye-slash"}`}
          color={props.eyeiconcolor}
          aria-hidden="true"
        />
      </a>
    </div>
  );
};

export default InputPassword;
