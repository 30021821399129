import {
  CHANGED_NAME,
  CHANGED_LAST_NAME,
  CHANGED_WORKSPACE,
  ADD_MEMBER_TO_WORKSPACE,
  CHANGED_MEMBER,
  CHANGED_LOCATION_SERVICE_WHILE_USING,
  CHANGED_LOCATION_SERVICE_REAL_TIME,
  CHANGED_EMAIL_NUMBER_ONBOARDING,
  CHANGED_PASSWORD_ONBOARDING,
  CHANGED_MAGIC_LINK_CODE_ONBOARDING,
  ONBOARDING_RESET,
  GET_COUNTRY_CODE_LIST_REQUEST,
  GET_COUNTRY_CODE_LIST_REQUEST_SUCCESS,
  GET_COUNTRY_CODE_LIST_REQUEST_FAIL,
  CHANGED_COUNTRYCODE
} from "./types";
import { safelyApi } from "../../api/safely";

export const changedEmailOnboarding = (email) => (dispatch) =>
  dispatch({ type: CHANGED_EMAIL_NUMBER_ONBOARDING, payload: email });

export const changedName = (name) => (dispatch) =>
  dispatch({ type: CHANGED_NAME, payload: name });

export const changedLastName = (lastName) => (dispatch) =>
  dispatch({ type: CHANGED_LAST_NAME, payload: lastName });

export const changedPasswordOnboarding = (password) => (dispatch) =>
  dispatch({ type: CHANGED_PASSWORD_ONBOARDING, payload: password });

export const changeWorkspaceName = (workspace) => (dispatch) =>
  dispatch({ type: CHANGED_WORKSPACE, payload: workspace });

export const changeMember = (member) => (dispatch) =>
  dispatch({ type: CHANGED_MEMBER, payload: member });

export const addMemberToWorkspace = () => (dispatch) =>
  dispatch({ type: ADD_MEMBER_TO_WORKSPACE, payload: "" });

export const changedLocationServiceWhenUsing = (active) => (dispatch) =>
  dispatch({ type: CHANGED_LOCATION_SERVICE_WHILE_USING, payload: active });

export const changedLocationServiceRealTime = (active) => (dispatch) =>
  dispatch({ type: CHANGED_LOCATION_SERVICE_REAL_TIME, payload: active });

export const changedMagicLinkCode = (magicLinkCode) => (dispatch) => {
  dispatch({
    type: CHANGED_MAGIC_LINK_CODE_ONBOARDING,
    payload: magicLinkCode,
  });
  localStorage.setItem("magicLinkCode", magicLinkCode);
};

export const resetOnboarding = () => (dispatch) => {
  dispatch({
    type: ONBOARDING_RESET,
    payload: {},
  });
};

export const changedCountryCode = countryCode => dispatch => dispatch({ type: CHANGED_COUNTRYCODE, payload: countryCode })

export const getPhoneCodeList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COUNTRY_CODE_LIST_REQUEST, payload: {} });
      const response = await safelyApi("/login/countrydialcode", { method: "get" });
      if (response.status) {
        dispatch({ type: GET_COUNTRY_CODE_LIST_REQUEST_SUCCESS, payload: response });
      } else {
        dispatch({
          type: GET_COUNTRY_CODE_LIST_REQUEST_FAIL, payload: {
            message: response.error_description || response.systemError,
            ...response,
          }
        });
      }
      return response;
    } catch (e) {
      return { e, status: false };
    }
  }
}