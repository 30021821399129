import {
    DISABLE_USER_SAFELY_PASS,
    DISABLE_USER_SAFELY_PASS_SUCCESS,
    DISABLE_USER_SAFELY_PASS_FAIL,
    ENABLE_USER_SAFELY_PASS,
    ENABLE_USER_SAFELY_PASS_SUCCESS,
    ENABLE_USER_SAFELY_PASS_FAIL,
  } from "../actions/types";
  
  export const initialState = {
    loading: true,
    data: {},  
  };
  
  export default (state, action) => {
    const { type, payload } = action;
    if (typeof state === "undefined") {
      return initialState;
    }
    switch (type) {
        case DISABLE_USER_SAFELY_PASS:
            return { ...state, loading: true };
        case DISABLE_USER_SAFELY_PASS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload
            };
        case DISABLE_USER_SAFELY_PASS_FAIL:
            return { 
                ...state, 
                loading: false, 
                data: payload 
            };
        case ENABLE_USER_SAFELY_PASS:
            return { ...state, loading: true };
        case ENABLE_USER_SAFELY_PASS_SUCCESS:
            return { 
                ...state, 
                loading: false,
                data: payload 
            };
        case ENABLE_USER_SAFELY_PASS_FAIL:
            return { 
                ...state, 
                loading: false, 
                data: payload
            };
        default:
            return state;
    }
  };  