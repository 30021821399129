export const USER_CHECKIN = 'USER_CHECKIN';
export const USER_CHECKOUT = 'USER_CHECKOUT';
export const USER = 'USER';
export const WORKSPACE = 'WORKSPACE';
export const ACCESSAUTHORIZATION = 'ACCESSAUTHORIZATION';
export const CHECKLIST = 'CHECKLIST';
export const POST_LIKE = 'POST_LIKE';
export const ADD_POST = 'ADD_POST';
export const EDIT_POST = 'EDIT_POST';
export const DELETE_POST = 'DELETE_POST';
export const DELETE_SUBSPACE = "DELETE_SUBSPACE";
export const DELETE_WORKSPACE = "DELETE_WORKSPACE";
export const SUBSPACE_CHECKOUT = "SUBSPACE_CHECKOUT";
export const USER_SUBSPACE_CHECKIN = 'USER_SUBSPACE_CHECKIN';
export const USER_SUBSPACE_CHECKOUT = 'USER_SUBSPACE_CHECKOUT';
export const REMOVE_USER_FROM_SPACE = "REMOVE_USER_FROM_SPACE";
export const EDIT_SPACE_SETTINGS = "EDIT_SPACE_SETTINGS";
export const DISABLED_SAFELY_PASS = "DISABLED_SAFELY_PASS";
export const ENABLED_SAFELY_PASS = "ENABLED_SAFELY_PASS";

