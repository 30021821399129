import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { translate } from "../../redux/reducers/languageReducer";
import { getUserListByWorkspaceId } from "../../redux/actions/workspaceActions";
import * as roles from "../../constants/roles";
import AssignToOptions from './AssignToOptions';


const AssignTo = ({
    dictionary,
    onGetUserListByWorkspaceId,
    accesstokenidp,
    spaceFocused,
    title,
    selectedValue,
    setSelectedValue,
    userOptions,
    setUserOptions,
    selectedUser,
    setSelectedUser
}) => {

    const setOptions = (optionItems) => {
        let options = [];
        if (optionItems) {
            options = optionItems.map((option) => {

                if (option.roleId === roles.ADMIN) {
                    return { label: option.name + ' ' + option.lastName, value: option.id, disabled: true }
                } else {
                    return { label: option.name + ' ' + option.lastName, value: option.id }
                }

            })
        }

        return options;
    }

    const selectedUserByDefault = (optionItems) => {
        let selected = [];
        if (optionItems) {
            optionItems.forEach((option) => {
                if (option.roleId === roles.ADMIN) {
                    selected.push({ label: option.name + ' ' + option.lastName, value: option.id });
                }
            });
        }

        return selected;
    }

    const fetchSpaceUsers = async () => {
        const response = await onGetUserListByWorkspaceId(accesstokenidp, spaceFocused._id)
        setUserOptions(setOptions(response.data));
        setSelectedUser(selectedUserByDefault(response.data));
    }

    useEffect(() => {
        fetchSpaceUsers();
    }, []);

    return (

        <div className='assign-to-container'>
            <p>{dictionary.s('assignTo')}</p>
            <AssignToOptions
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                userOptions={userOptions}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
            />
        </div>
    )
}

AssignTo.defaultProps = {
    selectedValue: "only_admin",
    setSelectedValue: () => { },
    userOptions: [],
    setUserOptions: () => { },
    selectedUser: [],
    setSelectedUser: () => { }
}

const mapStateToProps = (state) => ({
    accesstokenidp: state.user.accesstokenidp,
    spaceFocused: state.workspace.spaceFocused,
    dictionary: {
        s: key => translate(state.language.dictionary, key)
    },
});

const mapDispatchToProps = (dispatch) => ({
    onGetUserListByWorkspaceId: (accesstokenidp, workSpaceId) =>
        dispatch(getUserListByWorkspaceId(accesstokenidp, workSpaceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignTo);
