import { LANGUAGE_CHANGE, LANGUAGE_RESET } from "../actions/types";
import { en, es } from "../../dictionary/local";

export const translate = (dictionary, key, params) =>
  !dictionary[key]
    ? `-${key}-`
    : !params
    ? dictionary[key]
    : dictionary[key].replace(
        /\{\d\}/g,
        val => params[parseFloat(val.match(/\d/g)[0])]
      );

const setLang = lang => {
  let dictionary = "";
  switch (lang) {
    case "en":
      dictionary = en;
      break;
    case "es":
      dictionary = es;
      break;
    default:
      dictionary = en;
      break;
  }

  return dictionary;
};

export const initialState = {
  version: 1
};

export default (state, action) => {
  const { type, payload } = action;
  if (typeof state === "undefined") {
    return initialState;
  }
  switch (type) {
    case LANGUAGE_CHANGE:
      const dictionary = setLang(payload);
      return { ...state, lang: payload, dictionary: dictionary };
    case LANGUAGE_RESET:
      return { ...state, ...initialState };
    default:
      const dictionaryData = setLang(
        localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
      );
      return {
        ...state,
        lang: localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en",
        dictionary: dictionaryData
      };
  }
};
