import styled from "styled-components";

const MainContainer = styled.div`
  padding-left: ${props => props.paddingleft};
  padding-top: ${props => props.paddingTop};
  transition: padding-left 0.5s; /* If you want a transition effect */
  // height: 100%;
`;

export default MainContainer;
