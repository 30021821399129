import AmazonStorage from "../../amazonStorage";
import { safelyApi } from "../../api/safely";
import ApiEndPoint from "../../constants/apiEndPoint";
import {
    SPINNER_POST,
    GET_POST_VIEW_DATA,
    GET_POST_VIEW_DATA_SUCCESS,
    GET_POST_VIEW_DATA_FAIL,
    SPINNER_PROGRESS,
    GET_LIKE_DISLIKE_DATA,
    GET_POST_VIEW_LOAD_MORE,
    CHANGED_CAPTION,
    CHANGED_SUBSPACE,
} from "./types";

export const postData = (type, accesstokenidp, userId, workspaceId, caption, subspaces, base64,) =>  async (dispatch) => {
    try {
        //this.spinnerPost(true);
        if(type === 'text') {
            let apirespone = await safelyApi(ApiEndPoint.Post, {
                method: "post", 
                headers: { accesstokenidp: accesstokenidp },
                body: { userId, workspaceId, caption, subspaces }
            });
            if (apirespone.status) {
                dispatch({ type: SPINNER_POST, payload: false });
            }
            return apirespone;
        }
        else if(type === 'image') {
            const response = await new AmazonStorage().uploadImage(base64, userId, dispatch).then(
               async (data) => {
                    const media = [{
                        type : "image",
                        url: data.Location}];
                   let apirespone = await safelyApi(ApiEndPoint.Post, {
                        method: "post", 
                        headers: { accesstokenidp: accesstokenidp },
                        body: { userId, workspaceId, caption, media, subspaces }
                    });
                    if (apirespone.status) {
                        dispatch({ type: SPINNER_POST, payload: false });
                    }
                }
            );
            return response;
        } else if(type === 'video') {
            const response = await new AmazonStorage().uploadVedio(base64, userId, dispatch).then(
                async (data) => {
                    const media = [{
                        type : "video",
                        url: data.Location}];
                    let apirespone = await safelyApi(ApiEndPoint.Post, {
                         method: "post", 
                         headers: { accesstokenidp: accesstokenidp },
                         body: { userId, workspaceId, caption, media, subspaces }
                     });
                     if (apirespone.status) {
                        dispatch({ type: SPINNER_POST, payload: false });
                    }
                 }
             );
             return response;    
        }
    } catch (error) {
        return error;
    }
}

export const spinnerPost = (condition) => {
    return async (dispatch) => {
        dispatch({ type: SPINNER_POST, payload: condition });
    };
}

export const postViewData = (workspaceId, userId, accesstokenidp, pageNo, limit) => async (dispatch) => {
    try {
        dispatch({ type: GET_POST_VIEW_DATA, payload: {} });
        const response = await safelyApi(ApiEndPoint.Post +"/"+ workspaceId +"/"+ userId +"/"+ pageNo +"/"+ limit, {
            method: "get",
            headers: { accesstokenidp: accesstokenidp },
        });
        if(response.status) {
            dispatch({ type: GET_POST_VIEW_DATA_SUCCESS, payload: response.data, hasNextPage: response.hasNextPage  });
        } else {
            dispatch({ type: GET_POST_VIEW_DATA_FAIL, payload: response});
        }
        return response;
    } catch (error) {
        dispatch({ type: GET_POST_VIEW_DATA_FAIL, payload: error });
        return { error, status: false };
    }
}

export const postloadmore = (workspaceId, userId, accesstokenidp, pageNo, limit, beforeData) => async (dispatch) => {
    try {
        dispatch({ type: GET_POST_VIEW_LOAD_MORE });
        const response = await safelyApi(ApiEndPoint.Post +"/"+ workspaceId +"/"+ userId +"/"+ pageNo +"/"+ limit, {
            method: "get",
            headers: { accesstokenidp: accesstokenidp },
        });
        if(response.status) {
            dispatch({ type: GET_POST_VIEW_DATA_SUCCESS, payload: beforeData.concat(response.data), hasNextPage: response.hasNextPage });
        } else {
            dispatch({ type: GET_POST_VIEW_DATA_FAIL, payload: response});
        }
        return response;
    } catch (error) {
        dispatch({ type: GET_POST_VIEW_DATA_FAIL, payload: error });
        return { error, status: false };
    }
}

export const likeorDislikeData = (dislike, like, postId, postDetails) => async (dispatch) => {
    const focused = postDetails.findIndex(x => postId === x._id);
    if(focused >= 0){
    postDetails[focused].dislike = dislike;
    postDetails[focused].like = like;
    }
    dispatch({ type: GET_LIKE_DISLIKE_DATA, payload: postDetails });   
}

export const postPushData = (workspaceId, userId, accesstokenidp, pageNo, limit) => async (dispatch) => {
    try {
        const response = await safelyApi(ApiEndPoint.Post +"/"+ workspaceId +"/"+ userId +"/"+ pageNo +"/"+ limit, {
            method: "get",
            headers: { accesstokenidp: accesstokenidp },
        });
        if(response.status) {
            dispatch({ type: GET_LIKE_DISLIKE_DATA, payload: response.data });
        } else {
            dispatch({ type: GET_POST_VIEW_DATA_FAIL, payload: response});
        }
        return response;
    } catch (error) {
        dispatch({ type: GET_POST_VIEW_DATA_FAIL, payload: error });
        return { error, status: false };
    }
}


export const likeOrDislikeHandler = (postId, userId, type, accesstokenidp) => async (dispatch) => {
    try {
        const response = await safelyApi('/posts/likes', {
            method: "put",
            headers: { accesstokenidp: accesstokenidp },
            body: {
                postId,
                userId,
                type
            }
        });
        return response;
    } catch (error) {
        return { error, status: false };
    }
}

export const resetProgress = (valueData) => async (dispatch) => {
    dispatch({ type: SPINNER_PROGRESS, payload: valueData });

}

export const PostEditedData = (accesstokenidp, userId, postId, caption, subspaces) => async (dispatch) => {
    try {
        const response = await safelyApi(ApiEndPoint.Post, {
            method: "put",
            headers: { accesstokenidp: accesstokenidp },
            body: {
                userId,
                postId,
                caption,
                subspaces
            }
        });
        if (response.status) {
            dispatch({ type: SPINNER_POST, payload: false });
        }
        return response;
    } catch (error) {
        return { error, status: false };
    }
}

export const deletePostHandler = (postId, accesstokenidp, filePath) => async (dispatch) => {
    try {
        const response = await safelyApi(ApiEndPoint.Post + "/" + postId, {
            method: "delete",
            headers: { accesstokenidp: accesstokenidp }
        }).then(async () => {
            if(filePath) {
                await new AmazonStorage().deleteFileS3(filePath[3]+'/'+filePath[4]);
            }
        });
        
        return response;
    } catch (error) {
        return { error, status: false };
    }
}

export const changedCaption = (caption) => (dispatch) =>
dispatch({ type: CHANGED_CAPTION, payload: caption });

export const changedSubspace = (subspace) => (dispatch) =>
dispatch({ type: CHANGED_SUBSPACE, payload: subspace });