export const TEXT = "text";
export const NUMBER = "number";
export const BOOLEAN = "boolean";
export const LABEL = "label";
export const BUTTONGROUP = "buttongroup";
export const RANGE = "range";
export const MULTIPLE = "multiple";
export const DROPDOWN = "dropdownlist";

export const verificationInputOption = [
    { value: LABEL },
    { value: NUMBER },
    { value: TEXT },
    { value: BOOLEAN },
    { value: BUTTONGROUP },
    { value: RANGE },
    { value: MULTIPLE },
    { value: DROPDOWN },
];


export const declarationInputOptions = [
    { value: LABEL },
    { value: NUMBER },
    { value: TEXT },
    { value: BOOLEAN },
    { value: BUTTONGROUP },
    { value: RANGE },
    { value: MULTIPLE },
    { value: DROPDOWN },
];