import React, { useEffect, useState, useRef } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import SignupWelcome from "./SignupWelcome";
import { translate } from "../../redux/reducers/languageReducer";
import { languageChange } from "../../redux/actions/languageActions";
import InputSelect from "../commons/wrapped-components/inputs/InputSelect";
import Button from "../commons/styled-components/general/Button";
import PinInput from "react-pin-input";
import {
  verifyValidMobilePin,
  createUser,
  sendVerifyMobilePin,
} from "../../redux/actions/userActions";
import {
  loginWithEmailAndPassword
} from "../../redux/actions/loginActions";
import Notifications from "../../notifications";
import SpaceDeletedPopup from "../Workspace/spaceDeletedErrorPopup";
import { changedMagicLinkCode } from "../../redux/actions/onboardingActions";
import { pinTimeInSeconds } from '../../constants/constantValues';

const notifications = new Notifications();
const VerifyEmail = (props) => {
  const [deviceType, setdeviceType] = useState(window.deviceType);
  const [pinMessage, setpinMessage] = useState('');
  const [displayResend, setDisplayResend] = useState(false);
  const [greenBtn, setGreenBtn] = useState(false);
  const [showDeletedPopup, setShowDeletedPopup] = useState(false);
  const [pin, setPin] = useState(null);
  const [second, setSecond]= useState(pinTimeInSeconds);
  const inputEl = useRef(null);

  useEffect(() => {
    function handleResize() {
      setdeviceType(window.deviceType);
    }
    startCountdown(second);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onResendClick = () => {
    let second = pinTimeInSeconds;
    setSecond(second);
    setDisplayResend(false);
    setpinMessage(null);
    startCountdown(second);
    props.onClickSendVerifyMobilePin(props.emailNumber, props.countryCode, props.lang);
  }

  const startCountdown = (seconds) => {
    let counter = seconds;
    const interval = setInterval(() => {
      counter--;
      setSecond(counter);

      if (counter < 1) {
        clearInterval(interval);
        setDisplayResend(true);
        setpinMessage(null);
      }
    }, 1000);
  }

  const options = [
    {
      value: "en",
      label: props.dictionary.s('english'),
    },
    {
      value: "es",
      label: props.dictionary.s('spanish'),
    }
  ];

  const handleSignIn = () => {
    props.history.push("/");
  };

  const onChange = value => {
    setPin(value);
  };

  const validatePin = () => {
    return pin && pin.trim().length === 4;
  }

  const onClickofVerify = async (e) => {
    e.preventDefault();
    const { emailNumber, name, lastName, password, magicLinkCode, countryCode } = props;
    let verifySid = props.pinSentData.verifySid;
    const response = await props.onVerifyValidMobilePin(verifySid, pin, props.lang);
    if (response) {
      if (response.status) {
        setGreenBtn(true);
        setpinMessage(response.data.message)
        const signupResponse = await props.onCreateUser(
          emailNumber,
          name,
          lastName,
          password,
          countryCode,
          magicLinkCode
        );
        if (signupResponse.status) {
          let fcmToken = ''
          if (notifications.isSupported()) {
            fcmToken = await notifications.getToken();
          }
          const result = await props.onLoginWithEmailAndPassword(
            emailNumber,
            password,
            props.lang,
            countryCode,
            fcmToken
          );
            if (result.status) {
              if (magicLinkCode === null) {
                props.history.push("/Welcome");
              }
              if (magicLinkCode !== null) {
                props.history.push("/workspacelist");
              }
            }
        } else if (!signupResponse.status && signupResponse.systemError === "Magic link(s) not found" ) {
          setShowDeletedPopup(true);
          props.onChangeMagicLinkCode(null);
        }
      } else {
        setPin(null);
        inputEl.current.clear();
        setGreenBtn(false);
        setpinMessage(response.axActions[0][0].message);
      }
    }
  }

  const closeDeletedPopup = async () => {
    const { emailNumber, name, lastName, password, countryCode } = props;
    const signupResponse = await props.onCreateUser(
      emailNumber,
      name,
      lastName,
      password,
      countryCode
    );
    if (signupResponse.status) {
      let fcmToken = ''
      if (notifications.isSupported()) {
        fcmToken = await notifications.getToken();
      }
      const result = await props.onLoginWithEmailAndPassword(
        emailNumber,
        password,
        props.lang,
        countryCode,
        fcmToken
      );
      if (result.status) {
        props.history.push("/Welcome");
        setShowDeletedPopup(false);
      } else {
        setShowDeletedPopup(false);
        alert("Something went wrong!");
      }
    }
  }

  return (
    <div className={deviceType + " limiter"}>
      <SpaceDeletedPopup 
        showPopup={showDeletedPopup}
        closePopup={closeDeletedPopup}
      />
      <div className="container-login100">
        <div className="wrap-signup verify-wrapper">
          {deviceType !== "phone" ? (
            <SignupWelcome SignIn={handleSignIn} />
          ) : null}
          <div
            className={
              deviceType !== "phone"
                ? "signup-form col-7"
                : "signup-form col-10"
            }
          >
            <div className="language-select verify-header col-12">
              <InputSelect
                label={props.dictionary.s("language")}
                className="sws-select language-dropdown"
                value={options.find(x => x.value === props.lang)}
                options={options}
                onChange={lang => props.onLanguageChange(lang.value)}
                sort={false}
                displayLabel={false}
                menuContainerStyle={{}}
                clearable={false}
                searchable={false}
                styles={{
                  control: {
                    borderRadius: "4px",
                    width: "98px",
                    cursor: "pointer",
                    ':hover': {
                      border: "1px solid #4FA3FF",
                    }
                  }
                }}
                labelKey="label"
                valueKey="value"
              />
              <div className="backToSingup-link-div"><Link to="/signup">{props.dictionary.s("back")}</Link></div>
            </div>
            <div className="form-align-center">
              <form className="form-login verify-form">
                <span className="signUp-title">
                  {props.dictionary.s("verifyMobileNumber")}
                </span>
                <p className="paragraph-text-style">
                  {props.dictionary.s('sentPinToMobileandEnterPin')}
                </p>
                <div className="verify-pin-input-div">
                  <PinInput
                    className="verify-pin-input"
                    length={4}
                    focus
                    value={pin}
                    ref={inputEl}
                    type="numeric"
                    onChange={onChange}
                  />
                </div>
                <div className="verify-btn-div">
                  <Button
                    type="submit"
                    disabled={!validatePin()}
                    block
                    className={greenBtn ? "pin-success-btn" : "btn-submit-pin"}
                    width="206px"
                    height="40px"
                    position="relative"
                    display="block"
                    right="0"
                    borderradius="4px"
                    onClick={onClickofVerify}
                  >
                    {greenBtn ? <i className="material-icons pin-verified-icon">check</i> : props.dictionary.s("Verify")}
                  </Button>
                </div>
                <div className={greenBtn ? "pin-verified-success" : "pin-verified-fail"}>{pinMessage}</div>
              {second < 1 && pinMessage === null ? <div className="pin-verified-fail">{props.dictionary.s('thePinHasExpired')}</div> : null}
                <div className="resend-pin-div">
                  {displayResend ?
                    <button className="resend-pin-btn" onClick={onResendClick}>{props.dictionary.s("resendPin")}</button>
                    : <div>{props.dictionary.s("resendPinIn")}{" "}{second}{" "}{props.dictionary.s("seconds")}</div>
                  }
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  emailNumber: state.onboarding.emailNumber,
  name: state.onboarding.name,
  lastName: state.onboarding.lastName,
  password: state.onboarding.password,
  countryCode: state.onboarding.countryCode,
  magicLinkCode: state.onboarding.magicLinkCode,
  pinSentData: state.user.pinSentSuccessData.data,
  PinSuccess: state.user.verifiedPinSuccess,
  PinFail: state.user.verifiedPinFail,
  lang: state.language.lang,
  dictionary: {
    s: key => translate(state.language.dictionary, key)
  }
});

const mapDispatchToProps = (dispatch) => ({
  onLanguageChange: lang => dispatch(languageChange(lang)),
  onVerifyValidMobilePin: (verifySid, code, language) => dispatch(verifyValidMobilePin(verifySid, code, language)),
  onCreateUser: (emailNumber, name, lastName, password, countryCode, magicLinkCode) =>
    dispatch(createUser(emailNumber, name, lastName, password, countryCode, magicLinkCode)),
  onLoginWithEmailAndPassword: (email, password, lang, countryCode, fcmToken) =>
    dispatch(loginWithEmailAndPassword(email, password, lang, countryCode, fcmToken)),
  onClickSendVerifyMobilePin: (mobile, countryCode, language) => dispatch(sendVerifyMobilePin(mobile, countryCode, language)),
  onChangeMagicLinkCode: code => dispatch(changedMagicLinkCode(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
