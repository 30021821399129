import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { general } from '../../general/global';
import { assignTransaction } from '../../redux/actions/transactionsActions';
import { translate } from '../../redux/reducers/languageReducer';
import Modal from "../commons/wrapped-components/Modal";
import AssignToOptions from './AssignToOptions';
import { showToast } from "../commons/Toast";
import * as toastType from "../../constants/toastTypes";

const AssignTransactionModal = ({
    showModal,
    dictionary,
    selectedUser,
    userOptions,
    closeModal,
    setSelectedUser,
    loadData,
    activeTransaction,
    onAssignTransaction,
    accesstokenidp,
    setShowAssignToModal,
}) => {
    const [selectedOption, setSelectedOption] = useState('only_admin');
    useEffect(() => {
        setSelectedOption(activeTransaction.assignTo);
    }, [activeTransaction]);

    const onSaveAssignTransaction = async () => {
        let body = {};
        if (selectedOption === 'only_admin' || selectedOption === 'all_members') {
            body = {
                id: activeTransaction._id,
                workspaceId: activeTransaction.workspaceId,
                assignTo: selectedOption
            }
        } else if (selectedOption === 'specific_members') {
            const userIds = selectedUser.length ? selectedUser.map(({ value }) => value) : [];
            body = {
                id: activeTransaction._id,
                workspaceId: activeTransaction.workspaceId,
                assignee: userIds
            }
        }

        const response = await onAssignTransaction(accesstokenidp, body);
        if (response.status) {
            setShowAssignToModal(false);
            await loadData();
            showToast(toastType.SUCCESS, dictionary.s('assignTransactionSuccessfuly'));
        }
    }

    return (
        <Modal
            title={dictionary.s('assignTransactionTo')}
            showModal={showModal}
            onHide={closeModal}
            properties={{
                centered: true,
            }}
            textButtonSave={dictionary.s("assign")}
            className="safely-modal renderModalCreatSubSpace"
            onSave={onSaveAssignTransaction}
            propertyButtonSave={{
                block: false,
                style: {
                    backgroundColor: general.theme.primaryColor,
                    color: general.theme.whiteFontColor,
                },
            }}
            bsStyleButtonSave="secondary"
            properties={{
                centered: true,
            }}
            footerStyle={{ display: 'flex', justifyContent: 'center', padding: '10px' }}
            body={
                <AssignToOptions
                    selectedValue={selectedOption}
                    setSelectedValue={setSelectedOption}
                    userOptions={userOptions}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                />
            }
        />
    )
}

const mapStateToProps = (state) => ({
    dictionary: {
        s: (key) => translate(state.language.dictionary, key),
    },
    accesstokenidp: state.user.accesstokenidp,
});

const mapDispatchToProps = (dispatch) => ({
    onAssignTransaction: (accesstokenidp, body) =>
        dispatch(assignTransaction(accesstokenidp, body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignTransactionModal);
