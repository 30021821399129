import React from "react";
import InputNumber from "./inputNumber"
import { general } from "../../../../general/global";
import * as inputTypes from "../../../../constants/inputTypes";
import InputText from "./InputText";

export const addOrDelDefaultContent = (_input) => {
    _input.content = { min: "0", max: "0" };
    return _input;
}

export const rangeValidation = (inputList) => {
    const rangeList = inputList.filter(input => input.type === inputTypes.RANGE);
    let message = "";
    let rangeMin = [];
    let emptyValue = [];
    rangeList.forEach(range => {
        rangeMin.push(parseFloat(range.content.min) >= parseFloat(range.content.max));
        emptyValue.push(range.content.min === "" || range.content.max === "");
    });
    if (emptyValue.find(i => i === true))
        message = "rangeEmptyValue";
    else if (rangeMin.find(i => i === true))
        message = "rangeValidation";
    return message;
}

const InputRange = ({
    /*  ShowMode    */
    showInput,
    label,
    value,
    onValueChange,
    editable,

    /*  EditMode    */
    id,
    inputLabel,
    range,
    required,
    dictionary,
    onChangeText,
    onChangeMinMax,
    ...rest
}) => {

    if (showInput)
        return (
            <InputNumber
                label={label}
                value={value}
                editable={editable}
                required={required}
                returnkeytype="next"
                onChangeText={onValueChange}
                dictionary={dictionary}
                {...rest}
            />
        )
    else
        return (
            <div>
                <InputText
                    label={dictionary.s('inputLabel')}
                    value={inputLabel}
                    inputClassName="edition-input"
                    onChangeText={onChangeText}
                />
                <div style={styles.minmax_container}>
                    <div style={styles.minmax}>
                        <InputNumber
                            label={dictionary.s('minimumValue')}
                            value={range.min}
                            inputClassName="edition-input"
                            onChangeText={value => onChangeMinMax(id, "min", value)}
                        />
                    </div>
                    <div style={styles.minmax}>
                        <InputNumber
                            label={dictionary.s('maximumValue')}
                            value={range.max}
                            inputClassName="edition-input"
                            onChangeText={value => onChangeMinMax(id, "max", value)}
                        />
                    </div>
                </div>
                <label style={styles.message}>{dictionary.s('inputRangeMessage')}</label>
            </div>
        );
}

const styles = {
    minmax_container: { display: 'flex', width: "90%" },
    minmax: { display: 'flex', flexDirection: 'column', },
    message: { color: general.theme.redCoral, fontSize: 12, marginTop: 20, marginBottom: 20, fontWeight: 600 }
}

export default InputRange;