import React from "react";
import Select, { components, createFilter } from "react-select";
import { connect } from "react-redux";
import { translate } from "../../../../redux/reducers/languageReducer";
import Label from "../../styled-components/general/Label";

const Menu = (props) => (
  <components.Menu {...props} className="animated fadeIn animation-duration-06">
    {props.children}
  </components.Menu>
);

const CaretDownIcon = () => {
  return <i className="material-icons CaretDown">arrow_drop_down</i>;
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};

const InputSelect = (props) => (
  <div className={props.className} style={props.styleContent}>
    {props.displayLabel && props.label ? <label className="">{props.label}</label> : null}
    <Select
      id={props.id}
      name={props.name}
      label={props.label}
      placeholder={props.label}
      getOptionLabel={(item) => item[props.labelKey]}
      getOptionValue={(item) => item[props.valueKey]}
      value={props.value}
      options={props.options}
      isDisabled={props.disabled}
      isClearable={props.clearable}
      onChange={(item) => props.onChange(item)}
      isLoading={props.isLoading}
      noOptionsMessage={() =>
        props.noResultsText || props.dictionary.s("noResultFound")
      }
      required={props.required}
      isSearchable={props.searchable}
      isMulti={props.multi}
      classNamePrefix={props.classNamePrefix}
      filterOption={createFilter({
        stringify: (option) => `${option.label}`
      })}
      className={
        "select--container " + (props.value ? "container--has-value" : "")
      }
      components={{ Menu, DropdownIndicator }}
      styles={props.useCustomStyles ? styleSelect(props.styles) : {}}
      {...props.extraProperties}
    />
    {props.required && <Label className="required">* {props.dictionary.s("required")}</Label>}
  </div>
);

InputSelect.defaultProps = {
  className: "input-select",
  optionClassName: "option-input-select",
  id: new Date().getTime(),
  name: "",
  label: "",
  useCustomStyles: true,
  displayLabel: true,
  placeholder: "select...",
  labelKey: "name",
  valueKey: "id",
  sort: true,
  value: null,
  options: [],
  filters: {},
  disabled: false,
  clearable: true,
  onChange: () => { },
  isLoading: false,
  noResultsText: "",
  required: false,
  searchable: true,
  multi: false,
  styleContent: {
    margin: "12px 0",
  },
  classNamePrefix: "input-select",
  styles: {
    control: {
      backgroundColor: "#FFF",
      borderRadius: 0,
    },
  },
  extraProperties: {},
};

const styleSelect = (stylesSelect = {}) => ({
  control: (styles) => ({ ...styles, ...stylesSelect.control }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    //const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? "#F5F5F5"
          : isFocused
            ? "#F1F5FD"
            : null,
      color: "#5A5A5A",
      cursor: isDisabled ? "not-allowed" : "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "#F5F5F5" : "#455B6326"),
      },
    };
  },
  input: (styles) => ({ ...styles }),
  singleValue: (base) => ({ ...base, color: "#5A5A5A" }),
  indicatorSeparator: () => ({ color: "#fff" }),
});

const mapStateToProps = (state) => ({
  dictionary: {
    s: (key) => translate(state.language.dictionary, key),
  },
});
const mapDistchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDistchToProps)(InputSelect);