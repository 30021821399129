import React from "react";
import {
  checkOut
} from "../../redux/actions/operationActions";
import { connect } from "react-redux";
import { general } from "../../general/global";
import { translate } from "../../redux/reducers/languageReducer";
import Modal from "../commons/wrapped-components/Modal";
import {
  checkOutIntoSubspace,
  resetCheckIntoSubspaceList,
  changeWorkspaceFocusedForSafelyPass
} from "../../redux/actions/workspaceActions";
import { withRouter } from "react-router-dom";

const CheckOutActivePass = (props) => {

  const onCheckOutSubspace = async () => {
    if (props.activesSubspaceCheckIn !== undefined && props.activesSubspaceCheckIn.length) {
      const activeSubspace = props.activesSubspaceCheckIn[0];
      const { _id: accessAuthorizationId } = activeSubspace;
      await props.onCheckOutIntoSubspace(accessAuthorizationId, props.accesstokenidp);
    }
  }
  return (
    <Modal
      title={props.dictionary.s("safelyPass")}
      dialogClassName="safely-modal renderModalCreatSubSpace"
      showModal={props.showCheckOutModel}
      onHide={() => {
        props.closeCheckOutModel();
      }}
      onSave={async () => {
        await props.onCheckOut(props.accessAuthorizationId);
        await onCheckOutSubspace();
        props.onResetCheckIntoSubspaceList();
        props.closeCheckOutModel();
        props.onChangeWorkspaceFocusedForSafelyPass(
          props.workspaceFocused
        );
        props.history.push("/safelypass");
            }}
      onClose={() => {
        props.closeCheckOutModel();
      }}
      textButtonSave={props.loading ? "..." : props.dictionary.s("checkOut")}
      textButtonClose={props.dictionary.s("cancel")}
      propertyButtonSave={{
        block: false,
        style: {
          backgroundColor: "#E67070",
          color: general.theme.whiteFontColor,
          border: `1px solid #E67070`,
        },
      }}
      bsStyleButtonSave="secondary"
      buttonCloseProperties={{
        block: false,
        style: {
          backgroundColor: general.theme.whiteColor,
          border: `1px solid ${general.theme.primaryColor}`,
          color: general.theme.primaryColor,
        },
      }}
      properties={{
        centered: true,
      }}
      body={
        <>
          <div className="activeSafelypassdisplay">
            {props.dictionary.s("errorGenerateASafelyPass")}
            <span><b> {props.workspaceFocusedForSafelyPass.name}</b></span>
          </div>
        </>
      }
      isLoadingButton={props.loading}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    accesstokenidp: state.user.accesstokenidp,
    loading: state.posts.loading,
    lang: state.language.lang,
    dictionary: {
      s: (key) => translate(state.language.dictionary, key),
    },
    workspaceFocusedForSafelyPass:
      state.workspace.workspaceFocusedForSafelyPass,
    workspaceFocused: state.workspace.workspaceFocused,  
    accessAuthorizationId: state.operation.accessAuthorizationId,   
    activesSubspaceCheckIn: state.workspace.activesSubspaceCheckIn,
  
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCheckOut: (id) =>
    dispatch(checkOut(id)),
    onCheckOutIntoSubspace: (accessAuthorizationId, accesstokenidp) =>
    dispatch(checkOutIntoSubspace(accessAuthorizationId, accesstokenidp)),  
    onResetCheckIntoSubspaceList: () =>
    dispatch(resetCheckIntoSubspaceList()),
    onChangeWorkspaceFocusedForSafelyPass: workspace =>
    dispatch(changeWorkspaceFocusedForSafelyPass(workspace)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckOutActivePass));
