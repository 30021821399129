/*  Libraries */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, } from "react-bootstrap";
import { connect } from "react-redux";
import Select, { components } from "react-select";

/*  Configurations, Variables  */
import * as toastType from "../../constants/toastTypes";
import { general } from "../../general/global";
import { verificationInputOption as Options } from "../../constants/inputTypes";
import * as inputTypes from "../../constants/inputTypes";

/*  Components */
import MainNavigation from "../Navigation/MainNavigation";
import { showToast } from "../commons/Toast";
import TextInput from "../commons/wrapped-components/inputs/InputText";
import InputBoolean from "../commons/wrapped-components/inputs/inputBoolean";
import { ReactComponent as TrashIcon } from "../../icons/trash.svg";
import InputButtonGroup, {
  addDefaultButtons,
  buttonValidation,
  cleanPropertiesButton,
} from "../commons/wrapped-components/inputs/InputButtonGroup";
import InputRange, {
  addOrDelDefaultContent,
  rangeValidation,
} from "../commons/wrapped-components/inputs/InputRange";
import Label from "../commons/styled-components/general/Label";
import { GeneratorMultipleChoice, validate as multipleChoiseValidator } from '../commons/wrapped-components/inputs/MultipleChoice';
import DropDownList, { validate as DropDownListValidate } from '../commons/wrapped-components/inputs/DropDownList';

/*  Reducers */
import { translate } from "../../redux/reducers/languageReducer";

/*  Actions */
import { updateVerficationFrom, } from "../../redux/actions/workspaceActions";
import { getCheckList, updateInputs, updateContent } from "../../redux/actions/editFormActions";

const EditVerificatonForm = (props) => {
  const [contentMarkdown, setContentMarkdown] = useState(null);
  const [inputList, setInputList] = useState([]);
  const [deviceType, setdeviceType] = useState(window.deviceType);

  useEffect(() => {
    fetchCheckList();
    const handleResize = () => setdeviceType(window.deviceType);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [props.workspaceFocused._id]);

  const onChangeText = (_id, _value) => {
    const _inputList = general.copyObject(inputList);
    _inputList.find(i => i._id === _id).label = _value;
    setInputList(_inputList);
  }

  const setDefaultContent = (_inputList, _id, _type) => {
    let _input = _inputList.find(i => i._id === _id);
    _input.type = _type;
    switch (_type) {
      case inputTypes.BUTTONGROUP:
        _input = addDefaultButtons(_input, props.dictionary);
        _input.required = true;
        break;
      case inputTypes.RANGE:
        _input = addOrDelDefaultContent(_input);
        _input.required = true;
        break;
      case inputTypes.TEXT:
        _input.required = true;
        break;
      case inputTypes.NUMBER:
        _input.required = true;
        break;
      case inputTypes.MULTIPLE:
        _input.content = { options: [{ id: general.guid(), value: '', denyAccess: false }] };
        _input.required = true;
        break;
      case inputTypes.DROPDOWN:
        _input.content = { options: [{ id: general.guid(), value: '', denyAccess: false }] };
        _input.required = true;
        break;
      default:
        delete _input.content;
        _input.required = false;
        break;
    }
    return _inputList;
  }

  const onSelectValueChange = (_id, _type) => {
    let _inputList = general.copyObject(inputList);
    _inputList = setDefaultContent(_inputList, _id, _type);
    setInputList(_inputList);
  }

  const handleRemove = (_id) => {
    setInputList(inputList.filter(i => i._id !== _id));
  }

  const addInput = () => {
    const _inputList = general.copyObject(inputList);
    _inputList.push({
      denyPass: false,
      enabled: false,
      label: "",
      required: false,
      type: inputTypes.LABEL,
      _id: general.guid(),
    });
    setInputList(_inputList);
  }

  const onChangeDenySafelyPass = (_id, _deny) => {
    const _inputList = general.copyObject(inputList);
    _inputList.find(i => i._id === _id).denyPass = _deny;
    setInputList(_inputList);
  }

  const setButtonList = (_id, _buttonList) => {
    const _inputList = general.copyObject(inputList);
    _inputList.find(i => i._id === _id).content.buttonList = _buttonList;
    setInputList(_inputList);
  }

  const validateInputLabel = () => {
    let message = "";
    if (inputList.find(input => input.label === ""))
      message = "inputLabelValidation";
    return message;
  }

  const removeGUID = (_inputList) => {
    return _inputList.map(input => {
      if (input._id.match(/(^.{8}-.{4}-.{4}-.{4}-.{12}$)/g))
        delete input._id
      return input;
    });
  }

  const onChangeRequired = (_id, _required) => {
    const _inputList = general.copyObject(inputList);
    _inputList.find(i => i._id === _id).required = _required;
    setInputList(_inputList);
  }

  const onChangeMinMax = (_id, type, _value) => {
    const _inputList = general.copyObject(inputList);
    _inputList.find(i => i._id === _id).content[type] = _value;
    setInputList(_inputList);
  }

  const inputValidation = () => {
    let validation = true;
    let messageList = [];
    messageList.push(validateInputLabel());
    messageList.push(buttonValidation(inputList));
    messageList.push(rangeValidation(inputList));
    messageList.push(multipleChoiseValidator(inputList));
    messageList.push(DropDownListValidate(inputList));
    messageList.forEach(message => {
      if (message.length > 0) {
        showToast(toastType.ERROR, props.dictionary.s(message));
        validation = false;
      }
    });
    return validation;
  }

  const CaretDownIcon = () => {
    return <i className="material-icons CaretDown">{'arrow_drop_down'}</i>;
  };

  const DropdownIndicator = propsValue => {
    return (
      <components.DropdownIndicator {...propsValue}>
        {CaretDownIcon()}
      </components.DropdownIndicator>
    );
  };

  const onChangeContent = (e) => setContentMarkdown(e.target.value)

  const onClickOfSave = async () => {
    if (inputValidation()) {
      let _inputList = general.copyObject(inputList);
      _inputList = cleanPropertiesButton(_inputList)
      _inputList = removeGUID(_inputList);

      const inputUpdate = await props.onUpdateInputs(
        props.workspaceFocused.checkPointId,
        _inputList,
      );

      const contentUpdate = await props.onUpdateContent(
        props.workspaceFocused.checkPointId,
        contentMarkdown,
      );

      if (inputUpdate.message === 'success' && contentUpdate.message === 'success') {
        props.onUpdateVerficationFrom(contentUpdate.data, props.workSpacesProps);
        props.history.push('/editcheckpoint');
      }
    }
  }

  const onClickOfCancel = (e) => {
    e.preventDefault();
    props.history.push('/editcheckpoint');
  }

  const fetchCheckList = async () => {
    const response = await props.getChecklistList(props.workSpaceId, props.accesstokenidp);
    if (response.message === "success" && response.data) {
      setInputList(response.data.checkPointChecklist[0].inputs);
      setContentMarkdown(response.data.checkPointChecklist[0].contentMarkdown);
    }
  };

  const getOptions = (optionsValue) => {
    const optionsArray = [];
    optionsValue.map(option => {
      optionsArray.push({ label: props.dictionary.s(option.value), value: option.value })
    });
    return optionsArray;
  }

  const onChangeOptions = (id, options) => {
    const _inputList = general.copyObject(inputList);
    const inputToUpdate = _inputList.find(i => i._id === id);
    if (inputToUpdate) {
      inputToUpdate.content.options = options;
      setInputList(_inputList);
    }
  };

  return (
    <MainNavigation title={props.title}>
      <Container fluid className="padding-container">
        <Row>
          <Col xs={12} md={10}>
            <Label className="edit-form-header">{props.dictionary.s("editVerificationForm")}</Label>
            <div className="quill-editor-div">
              <Label className="quill-editor-label">{props.dictionary.s("content")}</Label>
              <textarea
                value={contentMarkdown}
                className="form-control form-control-textAera"
                rows="5"
                onChange={e => onChangeContent(e)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8}>
            {inputList.map((input, index) => {
              return (
                <div key={index} className="input-list-wrapper">
                  <TrashIcon
                    className={
                      deviceType === "desktop"
                        ? "delete-icon"
                        : deviceType + "-delete-icon"
                    }
                    onClick={() => handleRemove(input._id)}
                  />
                  <div className="input-wrapper">
                    <Label className="select-input-para">{props.dictionary.s('selectInput')}</Label>
                    <div className="drop-down-style">
                      <Select
                        styles={customStyles}
                        value={getOptions(Options).find(x => x.value === input.type)}
                        valueKey={index}
                        options={getOptions(Options)}
                        components={{ DropdownIndicator }}
                        onChange={(e) => onSelectValueChange(input._id, e.value)}
                      />
                    </div>
                    {
                      [
                        inputTypes.TEXT,
                        inputTypes.BOOLEAN,
                        inputTypes.LABEL,
                        inputTypes.NUMBER,
                      ].find(i => i === input.type) ?
                        (
                          <>
                            <TextInput
                              label={props.dictionary.s("inputLabel")}
                              value={input.label}
                              inputClassName="edition-input"
                              onChangeText={text => onChangeText(input._id, text)}
                            />
                            {input.type === inputTypes.BOOLEAN &&
                              <InputBoolean
                                id={index.toString()}
                                label={props.dictionary.s('denySafelyPass')}
                                onValueChange={value => onChangeDenySafelyPass(input._id, value)}
                                value={input.denyPass}
                              />
                            }
                          </>
                        ) : input.type === inputTypes.BUTTONGROUP ? (
                          <InputButtonGroup
                            id={input._id}
                            inputLabel={input.label}
                            buttonList={input.content.buttonList}
                            setButtonList={setButtonList}
                            onChangeText={text => onChangeText(input._id, text)}
                            dictionary={props.dictionary}
                          />
                        ) : input.type === inputTypes.MULTIPLE ? (
                          <GeneratorMultipleChoice
                            id={input._id}
                            label={input.label}
                            options={input.content.options}
                            onChangeOptions={onChangeOptions}
                            onChangeText={text => onChangeText(input._id, text)}
                            dictionary={props.dictionary}
                          />
                        ) : input.type === inputTypes.DROPDOWN ? (
                          <DropDownList
                            id={input._id}
                            label={input.label}
                            options={input.content.options}
                            onChangeOptions={onChangeOptions}
                            onChangeText={text => onChangeText(input._id, text)}
                            dictionary={props.dictionary}
                          />
                        ) : input.type === inputTypes.RANGE && (
                          <InputRange
                            id={input._id}
                            inputLabel={input.label}
                            range={input.content}
                            onChangeMinMax={onChangeMinMax}
                            dictionary={props.dictionary}
                            onChangeText={text => onChangeText(input._id, text)}
                          />
                        )
                    }
                    {
                      [
                        inputTypes.NUMBER,
                        inputTypes.TEXT,
                        inputTypes.BUTTONGROUP,
                        inputTypes.RANGE,
                        inputTypes.MULTIPLE,
                        inputTypes.DROPDOWN,
                      ].find(i => i === input.type) && (
                        <InputBoolean
                          id={general.guid()}
                          label={props.dictionary.s('required')}
                          onValueChange={value => {
                            input.type !== inputTypes.BUTTONGROUP && onChangeRequired(input._id, value)
                          }}
                          value={input.required}
                        />)
                    }
                  </div>
                </div>
              );
            })}
            <div className={inputList.length > 0 ? "text-right" : "text-left"}>
              <span onClick={addInput} className="add-input">
                <i className="material-icons plus-symbol">add</i>
                {props.dictionary.s(inputList.length > 0 ? 'addAnotherInput' : 'addInput')}
              </span>
            </div>
          </Col>
        </Row>
        <div className="edit-form-btn">
          <Button className="cancel-btn" onClick={onClickOfCancel}>
            {props.dictionary.s("cancel")}
          </Button>
          <Button className="save-btn" onClick={onClickOfSave}>
            {props.updateLoading ? (
              <span>
                {props.dictionary.s("loading")}...{" "}
                <i
                  className="fa fa-spinner fa-spin"
                  style={{ animation: "fa-spin 1s infinite linear" }}
                  aria-hidden="true"
                ></i>
              </span>
            ) : (
              props.dictionary.s("save")
            )}
          </Button>
        </div>
      </Container>
    </MainNavigation>
  );
}

const customStyles = {
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    color: '#6E6E7C',
    padding: 5,
    cursor: "pointer",
    backgroundColor: isDisabled
      ? null
      : isSelected
        ? "#F5F5F5"
        : isFocused
          ? "#F1F5FD"
          : null,
    ":active": {
      ...styles[":active"],
      backgroundColor: !isDisabled && (isSelected ? "#F5F5F5" : "#455B6326"),
    },

  }),
  control: (styles) => ({ ...styles, cursor: "pointer" }),
  indicatorSeparator: () => ({ display: 'none' }),
  singleValue: (base) => ({ ...base, color: "#5A5A5A" }),
}

const mapStateToProps = state => ({
  loading: state.editForm.loading,
  updateLoading: state.workspace.loading,
  workSpaceId: state.workspace.spaceFocused._id,
  workspaceFocused: state.workspace.workspaceFocused,
  accesstokenidp: state.user.accesstokenidp,
  userId: state.user.id,
  workSpacesProps: state.workspace,
  dictionary: {
    s: key => translate(state.language.dictionary, key)
  },
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateVerficationFrom: (data, workSpacesProps) => dispatch(updateVerficationFrom(data, workSpacesProps)),
  getChecklistList: (userId, accesstokenidp) => dispatch(getCheckList(userId, accesstokenidp)),
  onUpdateInputs: (id, inputs) => dispatch(updateInputs(id, inputs)),
  onUpdateContent: (id, contentMarkdown) => dispatch(updateContent(id, contentMarkdown)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditVerificatonForm);