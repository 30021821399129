import React, { useEffect, useState } from "react";
import Input from "../commons/styled-components/general/Input";
import Button from "../commons/styled-components/general/Button";
import Label from "../commons/styled-components/general/Label";
import { general } from "../../general/global";
import { ReactComponent as ImportContactIcon } from "../../icons/importContact.svg";
import { ReactComponent as InviteContactIcon } from "../../icons/inviteContact.svg";
import { ReactComponent as ShareLinkIcon } from "../../icons/shareLink.svg";
import { ReactComponent as QrCodeIcon } from "../../icons/create-qr-code.svg";
import { ReactComponent as DoneImportedContacts } from "../../icons/tick.svg";
import { connect } from "react-redux";
import {
  changeMember,
  addMemberToWorkspace
} from "../../redux/actions/onboardingActions";
import { createMagicLink } from "../../redux/actions/magicLinkAction";
import { withRouter } from "react-router-dom";
import "../../styles/workspace.css";
import {
  Modal,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Container,
  Row,
  Col
} from "react-bootstrap";
import { translate } from "../../redux/reducers/languageReducer";
import ProgessSteps from "./progessSteps";
import Header from "./header";
import { importContacts, creatingNewWorkspace, getWorkspacesList } from "../../redux/actions/workspaceActions";
import ModalComponent from "../commons/wrapped-components/Modal";
import Table from "../commons/wrapped-components/Table";
import { CSVReader } from "react-papaparse";
import ReactTooltip from "react-tooltip";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastSuccess, toastError } from "../commons/Toast.js";

const buttonRef = React.createRef();

const WorkspaceConfiguration = props => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [linkCode, setLinkCode] = useState("");
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [deviceType, setdeviceType] = useState(window.deviceType);
  /*  Import Contacts */
  const [showModalImport, setShowModalImport] = useState(false);
  const [showModalContactsTable, setShowModalContactsTable] = useState(false);
  const [isDisabledButtonImport, setIsDisabledButtonImport] = useState(true);
  const [contactData, setContactData] = useState([]);
  const [colsTableContacts, setColsTableContacts] = useState([]);
  const [rowsTableContacts, setRowsTableContacts] = useState([]);
  const [isContactsImport, setIsContactsImport] = useState(false);
  const [configImportContacts, setConfigImportContacts] = useState({ skipEmptyLines: true });
  const [isCSV, setIsCSV] = useState(true);

  //console.log("Props of workspace configuration", props);

  /*  Import Contacts */
  const validationsCSVComponent = () => {
    let inputFile = document.getElementsByClassName("CSVComponent")[0];
    if (inputFile) {
      inputFile.firstChild.accept = ".csv,.vcf";
      inputFile.onchange = () => {
        let extension = document.getElementsByClassName("CSVComponent")[0].firstChild.value.split(".")[1];
        setConfigImportContacts({ ...configImportContacts, delimitersToGuess: extension === "csv" ? [] : [':'] });
        setIsCSV(extension === "csv" ? true : false);
      };
    }
  };

  const cleanImport = () => {
    setIsDisabledButtonImport(true);
    setShowModalContactsTable(false);
    setShowModalImport(false)
    setRowsTableContacts([]);
    setContactData([]);
  }

  useEffect(() => {
    function handleResize() {
      setdeviceType(window.deviceType);
    }
    window.addEventListener("resize", handleResize);
    validationsCSVComponent();
    setIsDisabledButtonImport(rowsTableContacts.length > 0 ? false : true);
    return () => window.removeEventListener("resize", handleResize);
  }, [showModalImport, rowsTableContacts]);

  const renderImportContacts = () => (
    <ModalComponent
      title=" "
      className="animated fadeInDown ImportContact"
      showModal={showModalImport}
      onHide={cleanImport}
      onSave={async () => {
        builDataTable(contactData);
        setShowModalContactsTable(true);
        setShowModalImport(false);
      }}
      textButtonSave={props.dictionary.s("Import")}
      bsStyleButtonSave="primary ImportContacts-Buttons"
      propertyButtonSave={{ className: "border-radius-none" }}
      disabledButtonSave={isDisabledButtonImport}
      onClose={cleanImport}
      textButtonClose={props.dictionary.s("cancel")}
      bsStyleButtonClose="outline-primary ImportContacts-Buttons"
      properties={{ centered: true }}
      body={
        <>
          <Container className="">
            <Row>
              <Col>
                <Label className="ImportContact-title">
                  {props.dictionary.s("ImportContacts")}
                </Label>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Label>{props.dictionary.s("DescriptionImportContacts")}</Label>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>
                <a
                  href="https://safely-media.s3-us-west-2.amazonaws.com/templates/Download.csv"
                  download="CSVTemplate"
                  className="text-primary ImportContact-CSVTitle"
                >
                  {props.dictionary.s("DownloadCSVTemplate")}
                </a>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="CSVComponent">
                <CSVReader
                  ref={buttonRef}
                  onFileLoad={_data => {
                    setContactData(_data);
                    setIsDisabledButtonImport(_data.length > 0 ? false : true);
                  }}
                  onError={(err, file, inputElem, reason) => {
                    cleanImport();
                  }}
                  noClick
                  noDrag
                  config={configImportContacts}
                >
                  {({ file }) => (
                    <>
                      <Button
                        className="ImportContacts-Buttons"
                        onClick={e => {
                          if (buttonRef.current) buttonRef.current.open(e);
                        }}
                      >
                        {props.dictionary.s("UploadFile")}
                      </Button>
                      <span className="pl-3">{file && file.name}</span>
                      {file ? (
                        <i
                          className="fa fa-times CSVDelete"
                          aria-hidden="true"
                          onClick={e => {
                            if (buttonRef.current) {
                              buttonRef.current.removeFile(e);
                              setContactData([]);
                              setIsDisabledButtonImport(true);
                            }
                          }}
                        ></i>
                      ) : null}
                    </>
                  )}
                </CSVReader>
              </Col>
            </Row>
          </Container>
        </>
      }
    />
  );

  const builDataTable = data => {
    if (isCSV) {
      const _data = data.map(item => item);
      let _columns = _data.reverse().pop();
      let _rows = _data.reverse();

      _columns = builColumns(_columns.data);
      setColsTableContacts(_columns);
      _rows = _rows.filter(item => item.data[0] !== ""); //delete rows without name
      if (_rows.length > 0) {
        _rows = buildRows(_rows, _columns);
        setRowsTableContacts(_rows);
      } else setIsDisabledButtonImport(true);
    }
    else {
      let cols = ["^N", "^EMAIL", "^TEL"]
      let headers = ["Name", "Email", "Phone"]

      let arrayData = cols.map((col, colIndex) => {
        let newRow = {};
        let dataRow = data.find(item => item.data[0].match(col));
        if (dataRow) {
          let indexLast = dataRow.data.length - 1;
          let value = dataRow.data[indexLast].replace(/[\s\-]/g, "");

          newRow[headers[colIndex]] = isNaN(value) ?
            dataRow.data[indexLast].replace(/\;/g, " ").trim() :
            dataRow.data[indexLast].replace(/[\s\-]/g, "");
        }

        return newRow;
      })

      let _contactData = { id: 1 };
      arrayData.map(item => _contactData = Object.assign(_contactData, item));
      Object.assign(
        _contactData,
        {
          Remove: <i
            className="fa fa-times CSVDelete"
            aria-hidden="true"
            onClick={() => setRowsTableContacts([])}
          ></i>
        }
      );

      let _columns = builColumns(headers);
      let _rows = [_contactData];
      setColsTableContacts(_columns);
      setRowsTableContacts(_rows);
    }
  };
  const builColumns = columns => {
    let newColumns = [];
    newColumns.push({
      dataField: "id",
      hidden: true
    });

    let tempCol = columns.map(header => {
      return {
        dataField: header.replace(/[\s\.]/g, ""),
        text: header,
        searchable: header === "Name" ? true : false
      };
    });
    newColumns = [...newColumns, ...tempCol];
    newColumns.push({
      dataField: "Remove",
      text: "Remove",
      searchable: false,
      editable: false,
      align: "center",
      headerStyle: { width: "10%" }
    });

    return newColumns;
  };
  const buildRows = (_rows, _columns) => {
    let rows = _rows.map((row, rowIndex) => {
      let newRow = {};
      _columns.map((col, colIndex) => {
        newRow[col.dataField] =
          colIndex === 0 ?
            (rowIndex + 1) :
            colIndex !== _columns.length - 1 ?
              (row.data[colIndex - 1]) :
              (
                <i
                  className="fa fa-times CSVDelete"
                  aria-hidden="true"
                  onClick={() => {
                    let email = row.data[1];
                    let phone = row.data[2];
                    let _data = _rows.filter(item =>
                      email ?
                        item.data[1] !== email :
                        item.data[2] !== phone
                    );
                    setRowsTableContacts(buildRows(_data, _columns));
                  }}
                ></i>
              );
      });
      return newRow;
    });
    setRowsTableContacts(rows);

    return rows;
  };

  const renderContactsTable = () => (
    <ModalComponent
      size="lg"
      className="animated fadeInDown contactsTable"
      showModal={showModalContactsTable}
      onHide={cleanImport}
      onSave={async () => {
        //send contacts
        let contacts = rowsTableContacts.map(item => {
          let contact = {};
          contact["receiverId"] = item.Email ? item.Email : item.Phone;
          if (contact["receiverId"] === item.Email) contact["receiverId2"] = item.Phone;
          contact["name"] = item.Name
          return contact;
        });

        const response = await props.onImportContacts(
          (props.creatingNewWorkspace ? props.workspaceCreated._id : props.workspaceFocused._id),
          props.userId,
          contacts
          //workSpaceId, addedBy, contacts
        );
        if (response.status) {
          cleanImport();
          toastSuccess(props.dictionary.s("InvitesSent"));
          setIsContactsImport(true);
        } else toastError(props.dictionary.s("MessageErrorDefault"));
      }}
      textButtonSave={props.loadingImport ? "..." : props.dictionary.s("Import")}
      bsStyleButtonSave="primary"
      propertyButtonSave={{ className: "border-radius-none" }}
      disabledButtonSave={isDisabledButtonImport}
      textButtonClose={props.dictionary.s("cancel")}
      onClose={cleanImport}
      bsStyleButtonClose="outline-primary"
      properties={{ centered: true }}
      body={
        <>
          <Container className="">
            <Row>
              <Col className="pt-3">
                <Table
                  columns={colsTableContacts}
                  data={rowsTableContacts}
                  noDataIndication={props.dictionary.s("noResultFound")}
                  bordered={false}
                  search={true}
                  customPagination={true}
                  placeholderSearch="Search by Name"
                  tableTitle={props.dictionary.s("ImportedContacts")}
                />
              </Col>
            </Row>
          </Container>
        </>
      }
      isLoadingButton={props.loadingImport}
      dictionary={props.dictionary}
    />
  );

  const renderModal = (gethandleClose, getshowModal, getsetShowTooltip, getshowTooltip) => (
    <Modal
      show={getshowModal}
      onHide={() => {
        gethandleClose();
        getsetShowTooltip(false);
      }}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.dictionary.s("share")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-3">
          <Input id="myInput" readOnly={true} value={linkCode} />
          {/* <FormControl value /> */}
          <InputGroup.Append>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="button-tooltip" {...props}>
                  {getshowTooltip ? "Text Copied!" : "Copy"}
                </Tooltip>
              }
            >
              <Button
                variant="success"
                onClick={() => {
                  getsetShowTooltip(true);
                  //Copy to clipboard
                  var copyText = document.getElementById("myInput");
                  copyText.select();
                  copyText.setSelectionRange(0, 99999);
                  document.execCommand("copy");
                }}
              >
                {props.dictionary.s("copy")}
              </Button>
            </OverlayTrigger>
          </InputGroup.Append>
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            gethandleClose();
            getsetShowTooltip(false);
          }}
        >
          {props.dictionary.s("close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
  return (
    <div className={deviceType + " limiter create-workspace"}>
      <ToastContainer />
      {renderImportContacts()}
      {renderContactsTable()}
      <ReactTooltip place="bottom" backgroundColor="#eee" textColor="#606076" className="tool-Tip" />
      <Header />
      <div className="container-login100">
        <div className="workspace-created">
          {props.creatingNewWorkspace && <span>{props.dictionary.s("almostDone")}</span>}
        </div>
        <div className="wrap-login100">
          <div className="login100-form validate-form workspace-configuration-form">
            {props.creatingNewWorkspace && <ProgessSteps step="3" />}
            <span className="login100-form-title">
              {props.dictionary.s("wouldYouLikeToAddSomeoneElseFromYourSafelyWorkspace")}
            </span>
            <div className="invitationLink">
              <p>{props.dictionary.s("invitationLink")}</p>
              {renderModal(handleClose, showModal, setShowTooltip, showTooltip)}
            </div>
            {props.members.map((item, index) => (
              <div className="wrap-input100 validate-input m-b-16" key={index}>
                <Input
                  type="text"
                  name="email"
                  className="workspacesName"
                  onKeyDown={e => {
                    if (e.keyCode === 32) e.preventDefault();
                    return false;
                  }}
                  placeholder={props.dictionary.s("mobileNumberOrEmailId")}
                  borderradius="8px"
                  border={`1px solid ${general.theme.borderColorInputs}`}
                  key={index}
                  value={item.member}
                  onChange={e => props.onChangeMember(item.index, e.target.value)}
                />
              </div>
            ))}
            {props.members.length <= 5 ? (
              <div className="addMore">
                <label
                  style={{
                    position: "relative",
                    right: "0px",
                    display: "block",
                    textAlign: "right",
                    paddingBottom: "25px",
                    cursor: "pointer",
                    fontWeight: "500"
                  }}
                  onClick={() => props.onAddMemberToWorkspace()}
                >
                  <i className="material-icons plus-add">add</i>
                  {props.dictionary.s("addAnother")}
                </label>
              </div>
            ) : null}
            <div>
              <div className="button-Icon pb-2">
                <div style={{ display: 'inline-block' }}>
                  <div
                    className={
                      "cricle-icon " +
                      (isContactsImport ? "ImportedContacts-circle" : "")
                    }
                  >
                    {isContactsImport ? (
                      <DoneImportedContacts />
                    ) : (
                        <ImportContactIcon
                          onClick={() => setShowModalImport(true)}
                          data-tip={props.dictionary.s("ImportContacts")}
                        />
                      )}
                  </div>
                  {isContactsImport ? (
                    <div>
                      <span className="ImportedContacts-span">
                        {props.dictionary.s("ImportedContacts")}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div className="cricle-icon ml-3 mr-3 inviteMyContacts-div">
                  <InviteContactIcon data-tip={props.dictionary.s("inviteMyContacts")} />
                </div>
                <div
                  className="cricle-icon ml-3 mr-3"
                  data-tip={props.dictionary.s("shareaspaceinvitationlink")}
                >
                  <ShareLinkIcon
                    onClick={async () => {
                      const response = await props.onCreateMagicLink(
                        (props.workspaceFocused._id ? props.workspaceFocused._id : props.workspaceCreated._id),
                        "toshare",
                        undefined,
                        undefined,
                        props.name,
                        props.userId
                      );
                      if (response.status) {
                        const _url = response.data.url;
                        let indexOf = _url.indexOf("https://");
                        if (indexOf === -1)
                          indexOf = _url.indexOf(window.location.host);
                        const url = _url.substring(indexOf, _url.length);
                        setLinkCode(url);
                      }
                      handleShow();
                    }}
                  />
                </div>
                <div className="cricle-icon ml-3 mr-3 createQrCode-div">
                  <QrCodeIcon data-tip={props.dictionary.s("createQrCode")} />
                </div>
              </div>
            </div>
            <div>
              <div style={{ position: "relative", display: "flex" }}>
                <label
                  style={{
                    lineHeight: "39px",
                    color: "#1849E8",
                    textDecorationStyle: "solid",
                    textDecorationLine: "underline",
                    paddingBottom: "25px"
                  }}
                >
                </label>
              </div>
              <div className="bottom-button">
                <Button
                  className="backButton"
                  width="100px"
                  bgcolor="#1849E8"
                  position="absolute"
                  right="0"
                  borderradius="4px"
                  onClick={async () => {
                    if (props.creatingNewWorkspace)
                      props.history.push("/set-workspace");
                    else
                      props.history.push("/workspaceDetails");
                  }}
                >
                  {props.dictionary.s("back")}
                </Button>
                <Button
                  width="100px"
                  bgcolor="#1849E8"
                  position="absolute"
                  right="0"
                  borderradius="4px"
                  className="nextButton"
                  onClick={async () => {
                    for (let item of props.members) {
                      if (item.member.trim() !== "") {
                        //Response.status is required to continue
                        const response = await props.onCreateMagicLink(
                          (props.creatingNewWorkspace ? props.workspaceCreated._id : props.workspaceFocused._id),
                          undefined,
                          item.member,
                          "+52",
                          "",
                          props.userId
                        );
                        if (response.status)
                          props.onChangeMember(item.index, "");
                      }
                    }
                    if (props.creatingNewWorkspace){
                    props.onGetWorkspacesList(
                      props.userId,
                      props.accesstokenidp
                    ).then(responce => {
                      props.history.push("/workspacelist");
                      props.onCreatingNewWorkspace(false);
                    });
                    }
                    else {
                     props.history.push("/workspaceDetails");
                     props.onCreatingNewWorkspace(false);
                    }
                  }}
                >
                  {props.dictionary.s(props.loading ? "loading" : "finish")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  members: state.onboarding.members,
  workspaceCreated: state.workspace.workspaceCreated,
  userId: state.user.id,
  accesstokenidp: state.user.accesstokenidp,
  name: state.user.name,
  loading: state.magicLink.loading,
  message: state.magicLink.message,
  dictionary: {
    s: key => translate(state.language.dictionary, key)
  },
  loadingImport: state.workspace.loading,
  workspaceFocused: state.workspace.workspaceFocused,
  creatingNewWorkspace: state.workspace.creatingNewWorkspace,
});

const dispatchToProps = dispatch => ({
  onChangeMember: (index, member) => dispatch(changeMember({ index, member })),
  onAddMemberToWorkspace: () => dispatch(addMemberToWorkspace()),
  onCreateMagicLink: (
    workspaceId,
    verifiedBy,
    receiverId,
    countryCode,
    name,
    addedBy
  ) =>
    dispatch(
      createMagicLink(
        workspaceId,
        verifiedBy,
        receiverId,
        countryCode,
        name,
        addedBy
      )
    ),
  onImportContacts: (workSpaceId, addedBy, contacts) =>
    dispatch(importContacts(workSpaceId, addedBy, contacts)),
  onCreatingNewWorkspace: creating => dispatch(creatingNewWorkspace(creating)),
  onGetWorkspacesList: (id, accesstokenidp) =>
    dispatch(getWorkspacesList(id, accesstokenidp)),
});

export default connect(
  mapStateToProps,
  dispatchToProps
)(withRouter(WorkspaceConfiguration));