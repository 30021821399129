import React from "react";
import { connect } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { translate } from "../../redux/reducers/languageReducer";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    style={{ textDecoration: "none" }}
    href="#"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));

const ToggleMenu = (props) => {
  const renderListOptions = () => (
    <Dropdown drop={window.innerWidth > 480 ? "right" : "left"}
      onClick={props.onDropDownClick}
    >
      <Dropdown.Toggle as={CustomToggle} />
      <Dropdown.Menu style={{ transform: "translate(0px, 25px)" }} size="md">
        {props.dropDownList.map((item, index) => {
          return (
            <Dropdown.Item
              key={index}
              className="spaces-dropdown-item"
              onClick={() => {
                item.onDropDownItemClick();
              }}
            >
              {props.dictionary.s(item.name)}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );

  return (
    renderListOptions()
  );
};

ToggleMenu.defaultProps = {
  onDropDownClick: () => { }
};

const mapStateToProps = (state) => {
  return {
    workSpaceId: state.workspace.spaceFocused._id,
    isSafelyPassActivated: state.operation.safelyPassStatus === 'granted',
    isSafelyPassInactive: state.operation.safelyPassStatus === 'inactive' || state.operation.safelyPassStatus === 'pending' || state.operation.safelyPassStatus === 'canceled' || state.operation.safelyPassStatus === 'denied',
    dictionary: {
      s: (key) => translate(state.language.dictionary, key),
    }
  };
};

export default connect(mapStateToProps, null)(ToggleMenu);
