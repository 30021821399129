import React from "react";
import SidebarWrapper from "./SidebarWrapper";
import LogoContainer from "../commons/styled-components/dashboard/LogoContainer";
import SidebarContainer from "../commons/styled-components/dashboard/Sidebar";
import SidebarButton from "./SidebarButton";
import { general } from "../../general/global";
import { ReactComponent as LogoAndonix } from "../../icons/safely-logo-blue.svg";
import { ReactComponent as LogoAndonixCollapsed } from "../../icons/andonixLogoCollapsed.svg";
import { connect } from "react-redux";
import { ReactComponent as LogoAndonixbutttom } from "../../icons/andonix-logo.svg";

const Sidebar = (props) => {
  let drawerClases = "side-drawer";

  if (props.show) {
    drawerClases = "side-drawer open";
  }

  const openSidebarStyles = {
    paddingTop: "20px",
  };

  const collapsedSidebarStyles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const SidebarLargeScreen = (
    <SidebarContainer
      id="sidebarContainer"
      backgroundColor={general.theme.bgSidebar}
      className={
        props.collapseSidebar
          ? "side-drawer-desktop collapsed"
          : "side-drawer-desktop"
      }
    >
      {!props.collapseSidebar ? (
        <SidebarButton
          iconClassName="icon-angleLeft"
          onClick={props.handleCollapseSidebar}
          sidebarBgColor={general.theme.bgButtonSidebar}
          iconColor={general.theme.arrowLeftIcon}
          position="absolute"
          right="0px"
        />
      ) : (
          <SidebarButton
            iconClassName="icon-angleLeft"
            sidebarBgColor={general.theme.bgButtonSidebarTop}
            iconColor={general.theme.arrowLeftIcon}
            position="fixed"
            left={0}
            marginLeft={"82.58px"}
            rotate180dg="rotate(180deg)"
            visibility={props.collapseSidebar ? "visible" : "hidden"}
            transition="margin-left 0.3s linear 0.6s, opacity 0.3s linear 0.6s"
            top="0px"
            onClick={props.handleCollapseSidebar}
          />
        )}
      <LogoContainer
        height="165px"
        sidebarStyles={
          props.collapseSidebar ? collapsedSidebarStyles : openSidebarStyles
        }
      >
        {props.collapseSidebar ? (
          <LogoAndonix width="76" height="80" style={{ display: "block", margin: "0 auto" }}/>
        ) : (
            // <LogoAndonix className="sidebar-logo" />
            <>
              <LogoAndonix width="76" height="122" style={{ display: "block", margin: "0 auto" }}/>
              {/* <img
              alt=""
              height={40}
              width={84}
              src={require("../../images/logo-safely-pass.jpg")}
              style={{
                display: "block",
                margin: "0 auto",
                paddingTop: "5px",
              }}
            ></img> */}
            </>
          )}
      </LogoContainer>
     {/*  <div className="workspace-name-container">
        <p>{workpacename}</p>
          </div> */}
      <div
        className={`${
          props.collapseSidebar ? "scrollbar collpased" : "scrollbar"
          }`}
      >
        <SidebarWrapper
          collapsesidebar={props.collapseSidebar}
          onselectedmenuitem={props.onselectedmenuitem}
        />
        <LogoContainer className={`${
          props.collapseSidebar ? "powered-by powered-by-collapseSidebar" : "powered-by"
          }`}>
          <span className="powered-by-text">Powered By</span>
          <span className="powered-by-logo">
            <LogoAndonixbutttom />
          </span>
        </LogoContainer>
      </div>
    </SidebarContainer>
  );

  return (
    <>
      {props.width < 1200 ? (
        <SidebarContainer
          backgroundColor={general.theme.bgSidebar}
          widthSidebar={props.widthSidebar}
          className={drawerClases}
        >
          <SidebarButton
            iconClassName="icon-angleLeft"
            onClick={props.openSidebar}
            sidebarBgColor={general.theme.bgButtonSidebar}
            iconColor={general.theme.arrowLeftIcon}
            position="absolute"
            right="0px"
          />
          <LogoContainer
            height="165px"
            sidebarStyles={
              !props.buttonSidebarIsClicked
                ? openSidebarStyles
                : collapsedSidebarStyles
            }
          >
            {!props.buttonSidebarIsClicked ? (
              <>
              <LogoAndonix width="76" height="122" style={{ display: "block", margin: "0 auto" }}/>

                {/* <img
                  alt=""
                  height={40}
                  width={84}
                  src={require("../../images/logo-safely-pass.jpg")}
                  style={{
                    display: "block",
                    margin: "0 auto",
                    paddingTop: "5px",
                  }}
                ></img> */}
              </>
            ) : (
                <LogoAndonixCollapsed />
              )}
          </LogoContainer>
        {/* <div className="workspace-name-container">
            <p>{workpacename}</p>
          </div>
          <div className="scrollbar">
            <SidebarWrapper onselectedmenuitem={props.onselectedmenuitem} />
            </div>*/}
          <div
        className={`${
          props.collapseSidebar ? "scrollbar collpased" : "scrollbar"
          }`}
      >
        <SidebarWrapper
          collapsesidebar={props.collapseSidebar}
          onselectedmenuitem={props.onselectedmenuitem}
        />
        <LogoContainer className="powered-by">
          <span className="powered-by-text">Powered By</span>
          <span className="powered-by-logo">
            <LogoAndonixbutttom />
          </span>
        </LogoContainer>
      </div>  
        </SidebarContainer>
      ) : (
          SidebarLargeScreen
        )}
    </>
  );
};

const mapStateToProps = (state) => ({
  workspace: state.user.workSpaces,
  workspaceCreated: state.workspace.workspaceCreated,
});

export default connect(mapStateToProps, null)(Sidebar);
