import React from 'react';
import { Button } from 'react-bootstrap';
import { ReactComponent as CameraIcon } from '../../../images/svg/camera.svg';

const styles = {
    buttonMedia: { borderRadius: '50%', width: 50, height: 50, borderColor: '#13988A', },
}

const ButtonMultiMediaOfAStep = (props) => (
    <Button onClick={props.onClick} style={props.style || styles.buttonMedia}>
        <CameraIcon />
    </Button>);

export default ButtonMultiMediaOfAStep;