import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

function PrivateRoute({ component: Component, type, title, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (type === "login" || type === "signup") {
          if ((rest.isLoggingIn && type === "login") || (rest.isLoggingIn && type === "signup")) {
            return <Redirect to="/dashboard" />;
          }
          return <Component {...props} {...rest} />;
        } else if (type === "callback" || type === "report" || type === "executeTransaction") {
          return <Component {...props} {...rest} />;
        } else {
          if (rest.isLoggingIn) { 
            if((rest.workspaceFocused && rest.role.includes(rest.workspaceFocused.roleId)) || (type === 'bypass')){
            return <Component {...props} title={title} {...rest} />;
            }
            else
            {
              return <Redirect to="/" />;
            }
          }
          return <Redirect to="/" />;
        }
      }}
    />
  );
}

const mapStatetoProps = (state) => ({
  isLoggingIn: state.login.isLoggingIn,
  userdata: state.login.userdata,
  loading: state.login.loading,
  error: state.login.error,
  workspaceFocused: state.workspace.spaceFocused,
  userId: state.user.id,
});

export default connect(mapStatetoProps)(PrivateRoute);
