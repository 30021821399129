import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ReactComponent as CloseIcon } from "../../icons/closeIcon.svg";
import { translate } from "../../redux/reducers/languageReducer";
import Table from "../commons/wrapped-components/Table";
import Avatar from '../commons/wrapped-components/Avatar';
import Checkbox from "../commons/wrapped-components/inputs/Checkbox";

const RemoveFromSpace = (props) => {
    const { workspaces, user } = props;
    const spacesUserBelongs = workspaces.filter(space => space.users.find(spaceUser => user.userId == spaceUser.id) !== undefined)
        .map(filteredSpace => {
            return {
                spaceId: filteredSpace._id,
                spacePicture: filteredSpace.picture,
                spaceName: filteredSpace.name,
                backgroundColor: filteredSpace.settings.backgroundColor,
                userRole: filteredSpace.users.find(spaceUser => user.userId == spaceUser.id).roleName,
            }
        });

    const spacesToRemove = {};
    spacesUserBelongs.map(space => spacesToRemove[space.spaceId] = false);

    const toggleSpaceToRemove = (spaceId) => {
        spacesToRemove[spaceId] = !spacesToRemove[spaceId];
        props.updateSpacesToRemove({ ...spacesToRemove });
    }

    const columns = [
        {
            dataField: "spaceName",
            text: props.dictionary.s('space').toUpperCase(),
            formatter: function (cell, row) {
                return (
                    <div>
                        {
                            row.spacePicture ? <Avatar
                                src={row.spacePicture}
                                className="rounded-circle"
                                styles={{ boxShadow: '0px 0px 0px 5px #fff', margin: '5px 10px' }}
                                width={32}
                                height={32}
                            /> : <div
                                className="noSpaceImage-worksapce"
                                style={{
                                    backgroundColor: row.backgroundColor,
                                    height: 32,
                                    width: 32,
                                    display: 'inline-block',
                                    margin: '5px 10px',
                                    fontSize: 20,
                                }}
                            >
                                    {cell.substring(0, 2)}
                                </div>
                        }
                        {cell}
                    </div>
                );
            }
        },
        {
            dataField: "userRole",
            text: props.dictionary.s('role').toUpperCase(),
            formatter: function (cell, row) {
                return (
                    <div style={{ margin: '5px 50px 5px 0px' }}>{cell}</div>
                )
            }
        },
        {
            formatter: function (cell, row) {
                return (
                    <Checkbox
                        className={'space-checkbox checkbox'}
                        onChange={() => { toggleSpaceToRemove(row.spaceId) }}
                    />
                );
            }
        }
    ]

    return (
        <>
            <button
                onClick={() => props.setModalVisible(false)}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <CloseIcon />
            </button>
            <h3>
                {props.dictionary.s("remove") + ' ' + user.userFullName + ' ' + props.dictionary.s("fromSpaces")}
            </h3>
            <Table
                columns={columns}
                data={spacesUserBelongs}
                loading={false}
                customPagination={true}
                noDataIndication={props.dictionary.s("noResultFound")}
                striped={false}
                hover={false}
                editable={true}
                keyField={"spaceId"}
                condesed={true}
                dictionary={props.dictionary}
                paginationSize={10}
                sizePerPageList={[{
                    id: '10', text: '10', value: 10
                }, {
                    id: '20', text: '20', value: 20
                }, {
                    id: 'all', text: props.dictionary.s("All"), value: spacesUserBelongs.length
                }]
                }
            />
        </>
    );
};

const mapStateToProps = (state) => {
    const user = state.user;
    return {
        dictionary: {
            s: (key) => translate(state.language.dictionary, key),
        }
    };
};

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveFromSpace);