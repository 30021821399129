import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { UserProfile } from "../../components/commons/wrapped-components/UserProfile";
import { connect } from "react-redux";
import { ReactComponent as Logo } from "../../icons/safely-logo-blue.svg";
import { translate } from "../../redux/reducers/languageReducer";
import { general } from "../../general/global";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
        screenwidth: 0
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateSize);
    this.updateSize();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSize);
  }
  updateSize = () => {
    this.setState({ screenwidth: window.innerWidth });
  }
  render() {
    const { screenwidth } = this.state;
    const { user } = this.props;
    const getUserData = () => {
      let userName = user.name;

      let charArray = Array.from(userName);

      let hasSpace = charArray.includes(" ");
      let indexOfSpaceChar = charArray.indexOf(" ");

      if (hasSpace) {
        let shortName = "";
        indexOfSpaceChar += 1;

        for (let index = 0; index < charArray.length; index += 1) {
          shortName += charArray[index];

          if (indexOfSpaceChar === index) {
            break;
          }
        }
        return shortName.concat(".");
      } else {
        return userName;
      }
    };
    return (
      <div className="on-board-header">
        <Row>
          <Col md="6" sm="6" xs="6" className="logo">
            <Logo width="76" height="123" />
          </Col>
          <Col md="6" sm="6" xs="6" className="login-user">
            <UserProfile
              userImage={
                <div style={{ display: "inline-block" }}>
                  <img
                    className="rounded-circle mr-2"
                    width={35.8}
                    height={35.8}
                    src={user.picture}
                    alt="user pic"
                  />
                   {screenwidth <= 600 ? <i className="fa fa-caret-down dropdownicon" aria-hidden="true"></i> : null}
                  {screenwidth >= 600 ? (
                    <span
                      style={{
                        fontSize: 15,
                        color: general.theme.fontColorUserLogged,
                        fontWeight: "bold"
                      }}
                    >
                      <span>{getUserData()}</span><i className="fa fa-caret-down dropdownicon" aria-hidden="true"></i>
                    </span>
                  ) : null}
                </div>
              }
              id="basic-nav-dropdown"
              items={[]}
              logout={this.props.dictionary.s("logout")}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.user,
  dictionary: {
    s: key => translate(state.language.dictionary, key)
  }
});

export default connect(mapStateToProps, null)(Header);
