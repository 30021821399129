import React, { useEffect, useState } from "react";
import InputSelect from "../commons/wrapped-components/inputs/InputSelect";
import { connect } from "react-redux";
import {
  changeWorkSpaceFocused,
  getAllPaginatedSpaces,
  setSpaceFocused,
} from "../../redux/actions/workspaceActions";
import { translate } from "../../redux/reducers/languageReducer";
import { useLocation } from 'react-router-dom';

const SpaceDropdown = (props) => {
  const [paginatedSpaces, setPaginatedSpaces] = useState([]);
  const location = useLocation();
  const filterWorkspace = (workspaceList) => {
    const usersFiltered = [];
    workspaceList.forEach((workspace, index) => {
      if((location.pathname.replace("/", "") === 'users')){
      if (
        workspace.users.find((x) => x.id === props.userId && x.roleId === "0")
      ) {
        usersFiltered.push(workspace);
      }
    }
    else{
      usersFiltered.push(workspace);
    }
    });
    return usersFiltered;
  };

  const getPaginatedSpaces = async () => {
    const response = await props.onGetAllPaginatedSpaces();
    if (response && response.data) {
      setPaginatedSpaces([...response.data])
    }
  };

  useEffect(() => {
    getPaginatedSpaces();
  }, []);

  return (
    <InputSelect
      value={props.workSpaceFocused}
      className={"user-workspaces godal-space-dropdown " + props.className}
      options={filterWorkspace(props.workSpacesList)}
      onChange={(workspace) => {
        const spaceFocused = paginatedSpaces.find((x) => x._id === workspace._id);
        props.onSetSpaceFocused(spaceFocused);
        props.onChangeWorkSpaceFocused(workspace);
      }}
      valueKey="_id"
      clearable={false}
    />
  );
};

const mapStateToProps = (state) =>
  ({
    userId: state.user.id,
    workSpaceFocused: state.workspace.workspaceFocused,
    workSpacesList: state.workspace.list,
    workSpacesLoading: state.workspace.loading,
    dictionary: {
      s: (key) => translate(state.language.dictionary, key),
    },
  });

const mapDispatchToProps = (dispatch) => ({
  onChangeWorkSpaceFocused: (workspace) => dispatch(changeWorkSpaceFocused(workspace)),
  onGetAllPaginatedSpaces: () => dispatch(getAllPaginatedSpaces()),
  onSetSpaceFocused: (spaceFocused) => dispatch(setSpaceFocused(spaceFocused)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpaceDropdown);