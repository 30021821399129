import React from 'react';
import Modal from "../commons/wrapped-components/Modal";
import { connect } from 'react-redux';
import { translate } from '../../redux/reducers/languageReducer';
import { general } from '../../general/global';
import { deleteTransaction } from '../../redux/actions/transactionsActions';
import { showToast } from "../commons/Toast";
import * as toastType from "../../constants/toastTypes";

const DeleteTransactionModal = ({
    dictionary,
    showDeleteTransactionModal,
    setShowDeleteTransactionModal,
    activeTransaction,
    loadData,
    onDeleteTransation
}) => {

    const onDeleteTransaction = async () => {
        if (activeTransaction._id && activeTransaction.url) {
            const splitFilePath = activeTransaction.url.split("/");
            const response = await onDeleteTransation(activeTransaction._id, splitFilePath);
            if (response.status) {
                setShowDeleteTransactionModal(false);
                await loadData();
                showToast(toastType.ERROR, dictionary.s('deleteTransactionSuccessfully'));
            }

        }

    }

    return (
        <Modal
            title={dictionary.s("deleteTransactionQuestion")}
            dialogClassName="safely-modal renderModalDeleteSubSpace"
            showModal={showDeleteTransactionModal}
            onHide={() => {
                setShowDeleteTransactionModal(false);
            }}
            onSave={async () => {
                await onDeleteTransaction();

            }}
            onClose={() => {
                setShowDeleteTransactionModal(false);
            }}
            textButtonSave={dictionary.s("delete")}
            textButtonClose={dictionary.s("cancel")}
            propertyButtonSave={{
                block: false,
                style: {
                    backgroundColor: "#E67070",
                    color: general.theme.whiteFontColor,
                    border: `1px solid #E67070`,
                },
            }}
            bsStyleButtonSave="secondary"
            buttonCloseProperties={{
                block: false,
                style: {
                    backgroundColor: general.theme.whiteColor,
                    border: `1px solid ${general.theme.primaryColor}`,
                    color: general.theme.primaryColor,
                },
            }}
            properties={{
                centered: true,
            }}
            body={
                <>
                    <div className="deleteSubSpaceText">
                        <div>
                            {dictionary.s("deleteTransactionText1")}<b>'{activeTransaction.transactionName}'</b>{dictionary.s("deleteTransactionText2")}
                        </div>
                    </div>
                </>
            }
        />
    )
}

const mapStateToProps = (state) => ({
    dictionary: {
        s: key => translate(state.language.dictionary, key)
    },
});

const mapDispatchToProps = (dispatch) => ({
    onDeleteTransation: (transactionId, filePath) => dispatch(deleteTransaction(transactionId, filePath))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteTransactionModal);
