import React from 'react';
import { connect } from "react-redux";
import { translate } from "../../redux/reducers/languageReducer";
import Button from "../commons/styled-components/general/Button";
import { ReactComponent as AndonixWhiteIcon } from '../../icons/andonixWhite.svg';

const LoginRightDesign = (props) => {
    return (
        <div className="login-right-style col-5">
          <div className="signin-link">
            <div className="text-style-div">{props.dictionary.s("welcome")}</div>
              <p className="para-text">{props.dictionary.s("enterPersonalDetailsAndStartJourney")}</p>
              <div className="btn-style-div">
                <Button className="sign-in-btn" onClick={props.SignUpClicked}>{props.dictionary.s('signup')}</Button>
              </div>
            </div>
            <div className="safely-footer">
              <span>Powered By 
                <a
                  href={"http://andonix.com"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AndonixWhiteIcon />
                </a> 
              </span>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    }
});

export default connect(mapStateToProps)(LoginRightDesign);
