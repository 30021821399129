import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import ApiEndPoint from "../../constants/apiEndPoint";
import { Button } from "react-bootstrap";
import { translate } from "../../redux/reducers/languageReducer";
import { baseURL } from "../../api/safely";
import { general } from "../../general/global";

const ExportReportButton = (props) => {
  const [deviceType, setdeviceType] = useState(window.deviceType);

  const exportForm = useRef(null);

  useEffect(() => {
    function handleResize() {
      setdeviceType(window.deviceType);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const downloadReport = (event) => {
    event.preventDefault();
    exportForm.current.submit();
  }

  return (
    <>
      <div style={{ display: 'none' }}>
        <form method={'POST'} action={baseURL + ApiEndPoint.ExportReport} target={"_blank"} ref={exportForm}>
          <input key={'idp'} type={'text'} name={'accesstokenidp'} value={props.accesstokenidp} />
          {
            Object.keys(props.exportReportBody).map((key) => <input key={key} type={'text'} name={key} value={props.exportReportBody[key]} />)
          }
        </form>
      </div>
      {
        (general.isSafari && deviceType !== "desktop") ||
          (general.isChromeAndroid && deviceType !== "desktop") ? (
            <div className="mobile-filters">
              <i className="fa fa-filter" onClick={() => { props.setFilterVisible(!props.showFilter) }} aria-hidden="true"></i>
              {!props.disabled ?
                <i onClick={downloadReport} className="fa fa-download" aria-hidden="true"></i>
                : <i onClick={(e) => e.preventDefault()} className="fa fa-download disabled" aria-hidden="true"></i>
              }
            </div>
          ) : !props.disabled ? (
            <Button
              disabled={props.disabled}
              className="export-button"
              variant="light"
              onClick={downloadReport}
            >
              {props.dictionary.s("export")}
            </Button>
          ) : (
              <Button disabled={true} className="export-button" variant="light">
                {props.dictionary.s("export")}
              </Button>
            )
      }
    </>
  )
}


const mapStateToProps = (state) => ({
  accesstokenidp: state.user.accesstokenidp,
  dictionary: {
    s: (key) => translate(state.language.dictionary, key),
  },
});

export default connect(mapStateToProps, null)(ExportReportButton);
