import React from "react";
import InputText from "./InputText";

const InputNumber = ({ label, value, onChangeText, onBlur, id, ...rest }) => {
  return (
    <InputText
      id={id}
      label={label}
      value={value}
      inputMode="decimal"
      onChange={(event) => {
        const _val = event.target.value;
        if (/^-?[0-9]*\.?[0-9]*?$/g.test(_val)) onChangeText(_val);
      }}
      onBlur={() => {
        onBlur();
        if (isNaN(value)) { onChangeText("") }
      }}
      {...rest}
    />
  )
};

export default InputNumber;