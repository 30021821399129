import React, { Component } from "react";
import { BrowserMultiFormatReader } from "@zxing/library";
import { general, } from "../../general/global";
import Button from "../commons/styled-components/general/Button";
import { ReactComponent as ChangeDeviceIcon } from "../../icons/ChangeDevice.svg";

export default class ZXing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoInputDevices: [],
      selectedDeviceId: '',
    }
    this.codeReader = null;
  }

  componentDidMount() {
    this.initialize();
  }

  componentWillUnmount() {
    this.stop();
    this.codeReader = null;
  }

  initialize = async () => {
    await this.codeReaderInitialize();
    await this.start();
  };

  codeReaderInitialize = async () => {
    this.codeReader = new BrowserMultiFormatReader();
    const videoInputDeviceList = await this.codeReader.getVideoInputDevices();
    this.setState({ videoInputDevices: videoInputDeviceList });
    const selectVideoDevice = this.state.videoInputDevices.length > 0 && this.state.videoInputDevices[0].deviceId;
    this.setState({ selectedDeviceId: selectVideoDevice });
  };

  start = async () => {
    const result = await this.codeReader.decodeFromInputVideoDevice(
      this.state.selectedDeviceId === '' ? undefined : this.state.selectedDeviceId,
      "video"
    );
    this.props.onDecodeFromInputVideo(result);
    // await this.stop();
  };

  stop = async () => this.codeReader.reset();

  torch = async () => {
    const track = this.codeReader.stream.getVideoTracks()[0];
    const capabilities = track.getCapabilities();
    if (capabilities.torch) {
      track
        .applyConstraints({
          advanced: [{ torch: true }],
        })
        .catch((e) => console.log(e));
    }
  };

  changeVideoDevice = async () => {
    if (this.state.videoInputDevices.length > 1) {
      let index = this.state.videoInputDevices.findIndex(
        getelement => getelement.deviceId === this.state.selectedDeviceId
      );
      let element = this.state.videoInputDevices[index + 1];
      if (element) {
        this.onChangeVideoDevice(element.deviceId);
      } else {
        let _element = this.state.videoInputDevices[0];
        this.onChangeVideoDevice(_element.deviceId);
      }
    }
  };

  onChangeVideoDevice = async (deviceId) => {
    if (this.state.selectedDeviceId !== deviceId) {
      await this.stop();
      this.setState({ selectedDeviceId: deviceId });
      await this.start();
    }
  }


  render() {
    return (
      <div>
        <div className="d-flex justify-content-center align-items-center" style={{ position: 'relative' }}>
          <div className="containerScan">
            <em></em>
            <div className="scan"></div>
          </div>
          <div className="DescriptionScan"><span>{this.props.dictionary.s("AligntheQRCodewiththeframetoscan")}</span></div>
          {general.isChromeAndroid ? (
            <Button
              variant='light'
              onClick={this.torch}
              style={{
                fontSize: 13,
                position: "absolute",
                right: 25,
                color: "#000",
                zIndex: 10,
                border: 0,
                borderRadius: "50%",
                width: 32,
                height: 32,
                padding: 0,
                marginTop: 7,
              }}
            >
              <i className="fa fa-lightbulb-o" style={{ color: general.theme.primaryColor }}></i>
            </Button>
          ) : null}
          <video
            ref={(ref) => (this.videoElement = ref)}
            id="video"
            style={{ width: "100%" }}
          />
        </div>
        <div className="flipCamera">
          <ChangeDeviceIcon
            style={{ cursor: "pointer" }}
            onClick={async () => this.changeVideoDevice()}
          />
          <span>{this.props.dictionary.s("FlipCamera")}</span>
        </div>
      </div>
    );
  }
}

ZXing.defaultProps = {
  styleIcon: {
    border: '1px solid #ccc',
    borderRadius: '50%',
    width: 36,
    height: 36,
    color: general.theme.primaryColor
  }
}