export const datePeriodOptions = [
    {label: "Custom", value: "custom"},
    {label: "Today", value: "today"},
    {label: "Yesterday", value: "yesterday"},
    {label: "Current Week", value: "currentWeek"},
    {label: "Last Week", value: "lastWeek"},
    {label: "Current Month", value: "currentMonth"},
    {label: "Last Month", value: "lastMonth"},
    {label: "Current Year", value: "currentYear"},
    {label: "Last Year", value: "lastYear"},
    {label: "All", value: "all"},
];