import React, { useState, useEffect } from "react";
import Button from "../commons/styled-components/general/Button";
import InputStyled from "../commons/styled-components/general/Input";
import InputPassword from "../commons/wrapped-components/inputs/InputPassword";
import { general } from "../../general/global";
import { Form, Spinner } from "react-bootstrap";
import {
  changeEmailNumber,
  changePassword,
  loginWithEmailAndPassword,
  logout,
  resetLogin,
} from "../../redux/actions/loginActions";
import { translate } from "../../redux/reducers/languageReducer";
import { changedMagicLinkCode } from "../../redux/actions/onboardingActions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Socialicons from '../onboarding/Socialicons';
import LoginHeader from './LoginHeader';
import LoginRightDesign from './LoginRightDesign';
import Notifications from "../../notifications";

const notifications = new Notifications();
const Login = (props) => {
  useEffect(() => {
    props.onLogout();
    const url = window.location.search;
    if (url !== "") handleOpenUrl(url);
    function handleResize() {
      setdeviceType(window.deviceType);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [deviceType, setdeviceType] = useState(window.deviceType);

  const handleOpenUrl = (url) => {
    if (!url) return;
    const params = general.hashUrl(url);
    //setMagicLinkCode(params["https://safely.andonix.com/?i"]);
    setMagicLinkCode(params["?i"]);
  };

  const onClickOfSignUp = () => {
    props.history.push("/signup");
  }

  const setMagicLinkCode = (code) => {
    if (!code) return;
    props.onChangeMagicLinkCode(code);
  };

  const loadLoginWithEmailAndPassword = async (e) => {
    e.preventDefault();
    let fcmToken = ''
    if (notifications.isSupported()) {
      fcmToken = await notifications.getToken();
    }
    setButtonDisabled(true);
    const response = await props.onLoginWithEmailAndPassword(
      props.userName,
      props.password,
      props.lang,
      props.countryCode,
      fcmToken
    );
    if (response.status) {
      props.history.push("/dashboard");
    } else {
      alert(response.error_description || (response.axActions && response.axActions[0][0].message));
      props.onResetLogin();
      setButtonDisabled(false);
    }
  };

  const renderPopUpLogin = () => {
    return (
      <div className="login">
        <div className={deviceType !== 'phone' ? "login-form-column col-7" : "login-form-column col-12"}>
          {/* ======<Login Header>====== */}
          <LoginHeader />
          {/* ======<Formulario>===== */}
          <div className="safely-sigin-form row">
            <Form className="form-login" onSubmit={loadLoginWithEmailAndPassword}>
              <span className="signUp-title">
                {props.dictionary.s("signInToSafely")}
              </span>
              <Form.Group>
                <InputStyled
                  {...(window.localStorage.getItem("userName")
                    ? {}
                    : { autoFocus: true })}
                  id="emailLogin"
                  name="email"
                  required
                  type="text"
                  placeholder={props.dictionary.s("emailOrPhoneNumber")}
                  value={props.userName}
                  onChange={(e) => props.onChangeEmailNumber(e.target.value)}
                  className="border-bottom-input border-bottom-input-focus"
                  placeholdercolor="#6E6E7C"
                />
              </Form.Group>
              <Form.Group>
                <div className="input-group">
                  <InputPassword
                    id="passwordLogin"
                    className="border-bottom-input border-bottom-input-focus"
                    required
                    type="password"
                    name="password"
                    placeholder={props.dictionary.s("password")}
                    value={props.password}
                    onChange={(e) => props.onChangePassword(e.target.value)}
                    placeholdercolor="#6E6E7C"
                    eyeiconcolor="1px solid #000000"
                  />
                </div>
              </Form.Group>
              <div
                className="text-right"
                style={{ marginBottom: "12px", paddingTop: "12px" }}
              >
                <Button
                  variant="primary"
                  type="submit"
                  disabled={buttonDisabled}
                  block
                  className="btn-submit"
                  width="280px"
                  height="40px"
                  bgcolor="#665DFF"
                  position="relative"
                  display="block"
                  right="0"
                  border="1px solid #665DFF"
                  borderradius="4px"
                >
                  {buttonDisabled && (
                    <Spinner
                      as="span"
                      style={{ marginRight: "8px" }}
                      animation="grow"
                      variant="light"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  {buttonDisabled ? props.dictionary.s("logginIn") + "..." : props.dictionary.s("signin")}
                </Button>
              </div>
              <div className="forgot-password">
                <Link to="/forgotpassword">{props.dictionary.s("forgotPassword")}</Link>
              </div>
            </Form>
            <div className="social-icon-div col-12">
              <div className="signup-with-text">{props.dictionary.s("orSigninWith")}</div>
              <Socialicons />
            </div>
            {deviceType === 'phone' ?
              <div className="phoneSingin">
                <span>
                  {props.dictionary.s("dontHaveAnAccount")}
                  <Link to="/signup" className="phoneSigninLink">
                    {props.dictionary.s("signup")}
                  </Link>
                </span>
              </div>
              : null}
          </div>
        </div>
        {deviceType !== 'phone' ? <LoginRightDesign SignUpClicked={onClickOfSignUp} /> : null}
      </div>
    );
  };

  return (
    <>
      <div className="wrapper">
        {renderPopUpLogin()}
        <div className="split white-top-split"></div>
      </div>
      <footer className="footer py-1">
        <div className="container text-center">
          <a
            href={"http://andonix.com"}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <small
              style={{ color: general.theme.copyRightColor }}
              className="footer-copyright-text"
            >
              Safely by Andonix © Copyright 2020
            </small>
          </a>
        </div>
      </footer>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoggingIn: state.login.isLoggingIn,
  name: state.login.name,
  userName: state.login.emailNumber,
  password: state.login.password,
  userdata: state.login.userdata,
  userId: state.user.id,
  mobilePhone: state.user.mobilePhone,
  accessToken: state.user.accesstoken,
  loading: state.login.loading,
  error: state.login.error,
  message: state.login.message,
  user: state.user,
  lang: state.language.lang,
  dictionary: {
    s: key => translate(state.language.dictionary, key)
  },
  countryCode: state.onboarding.countryCode,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeEmailNumber: (emailNumber) =>
    dispatch(changeEmailNumber(emailNumber)),
  onChangePassword: (password) => dispatch(changePassword(password)),
  onChangeMagicLinkCode: (linkCode) => dispatch(changedMagicLinkCode(linkCode)),
  onLoginWithEmailAndPassword: (email, password, lang, countryCode, fcmToken) =>
    dispatch(loginWithEmailAndPassword(email, password, lang, countryCode, fcmToken)),
  onLogout: () => dispatch(logout()),
  onResetLogin: () => dispatch(resetLogin()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
