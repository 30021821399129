import {
  MAGIC_LINK_CREATE,
  MAGIC_LINK_CREATE_SUCCESS,
  MAGIC_LINK_CREATE_FAIL,
  MAGIC_LINK_SET_MESSAGE,
  MAGIC_LINK_SET_CODE,
  MAGIC_LINK_GET_INFO_BY_CODE,
  MAGIC_LINK_GET_INFO_BY_CODE_SUCCESS,
  MAGIC_LINK_GET_INFO_BY_CODE_FAIL
} from "./types";
import { safelyApi } from "../../api/safely";

export const createMagicLink = (
  workSpaceId,
  verifiedBy,
  receiverId,
  countryCode,
  name,
  addedBy
) => async (dispatch) => {
  try {
    dispatch({ type: MAGIC_LINK_CREATE, payload: {} });
    const response = await safelyApi("/magicLink", {
      method: "post",
      body: { workSpaceId, verifiedBy, receiverId, countryCode, name, addedBy },
    });
    if (response.status){
      dispatch({ type: MAGIC_LINK_CREATE_SUCCESS, payload: response });}
    //Save to localStorage is pending
    else{
      dispatch({
        type: MAGIC_LINK_CREATE_FAIL,
        payload: {
          message: response.systemError || response.description,
          ...response,
        },
      });}
    return response;
  } catch (error) {
    dispatch({ type: MAGIC_LINK_CREATE_FAIL, payload: error });
    return { error, status: false };
  }
};

export const setMessage = (message) => (dispatch) =>
  dispatch({ type: MAGIC_LINK_SET_MESSAGE, payload: message });

export const setMagicLinkCode = (code) => (dispatch) =>
  dispatch({ type: MAGIC_LINK_SET_CODE, payload: code });

export const getMagicLinkInfoByCode = code => {
  return async dispatch => {
      try {
          dispatch({ type: MAGIC_LINK_GET_INFO_BY_CODE, payload: {} });
          const response = await safelyApi('/magiclink/' + code, { method: 'get' });
          if (response.status) dispatch({ type: MAGIC_LINK_GET_INFO_BY_CODE_SUCCESS, payload: response });
          else dispatch({ type: MAGIC_LINK_GET_INFO_BY_CODE_FAIL, payload: { message: response.error_description || response.systemError, ...response } });
          return response;
      } catch (e) {
          dispatch({ type: MAGIC_LINK_GET_INFO_BY_CODE_FAIL, payload: e });
          return { e, status: false };
      }
  }
}
