import React from 'react';
import { ReactComponent as AndonixWhiteIcon } from '../../icons/andonixWhite.svg';
import Button from "../commons/styled-components/general/Button";
import { translate } from "../../redux/reducers/languageReducer";
import { ReactComponent as SafelyWhiteIcon } from '../../icons/safely-logo-white.svg';
import { connect } from 'react-redux';

const SignupWelcome = (props) => {
    return (
        <div className="safely-registration-left col-5">
          <div className="safely-logo-header">
            <SafelyWhiteIcon />
          </div>
          <div className="signin-link">
              <div className="text-style-div">{props.dictionary.s("welcomeBack")}</div>
              <p className="para-text">{props.dictionary.s("pleaseSigninWithPersonalInfo")}</p>
            <div className="btn-style-div">
              <Button className="sign-in-btn" onClick={props.SignIn}>{props.dictionary.s('signin')}</Button>
            </div>
          </div>
          <div className="safely-footer">
            <span>Powered By 
            <a
                href={"http://andonix.com"}
                target="_blank"
                rel="noopener noreferrer"
              >
              <AndonixWhiteIcon /> 
            </a>
            </span>
          </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    }
});

export default connect(mapStateToProps, null)(SignupWelcome);