import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { translate } from "../../redux/reducers/languageReducer";
import * as roles from "../../constants/roles";
import { Row, Col, Button } from "react-bootstrap";
import Spinnerpost from "./Spinnerpost";
import { ReactComponent as LikeIcon } from "../../icons/icon-like.svg";
import { ReactComponent as LikeBlueIcon } from "../../icons/like_bule.svg";
import { ReactComponent as DisLikeIcon } from "../../icons/icon-dislike.svg";
import { ReactComponent as DisLikeBlueIcon } from "../../icons/dislikeIconblue.svg";
import moment from "moment";
import "moment/locale/es";
import { general } from "../../general/global";
import {
  postViewData,
  likeOrDislikeHandler,
  postloadmore,
  likeorDislikeData,
  postPushData
} from "../../redux/actions/postAction";
import PostToggleMenu from "../Workspace/ToggleMenu";
import Addpost from "./Addpost";
import DeletePost from './DeletePost';
import AddText from './AddText';

const Post = props => {
  const [likeColor, setLikeColor] = useState(false);
  const [dislikeColor, setDislikeColor] = useState(false);
  const [showModalAddpost, setShowModalAddpost] = useState(false);
  const [showModalAddText, setShowModalAddText] = useState(false);
  const [editImage, setEditImage] = useState(null);
  const [editType, setEditType] = useState(null);
  const [postData, setPostData] = useState(null);
  const [showDeletePost, setShowDeletePost] = useState(false);
  const [disabledLoadMore, setDisabledLoadMore] = useState(false);
  const [postPage, setPostPage] = useState(1);
  const [postPageLimit, setPostPageLimit] = useState(10);


  const closeAddpostsEditImage = () => {
    setShowModalAddpost(false);
    setEditImage(null);
    setEditType(null);
    setPostData(null);
  };

  const closeDeletePost = () => {
    setShowDeletePost(false);
    setPostData(null);
  }

  useEffect(() => {
    moment.locale(props.lang);
    if(props.workspaceFocused._id){
      setPostPageLimit(10);
      setPostPage(1);
      PostViewData(1,10);
    }
  }, [props.workspaceFocused._id, props.workspaceFocused.name, props.userId]);

  const PostViewData = async (page,limit) => {
    await props.getPostViewData(
      props.workspaceFocused._id,
      props.userId,
      props.accesstokenidp,
      page,
      limit
    );
  };
 
  const postUpdatedAddEditViewData = async () => {
    PostViewData(1,props.postDetails.length > 10 ? (Math.ceil(props.postDetails.length / 10) * 10) : 10);
  };


  const postUpdatedViewData = async () => {
    await props.onpostPushData(
      props.workspaceFocused._id,
      props.userId,
      props.accesstokenidp,
      1,
      props.postDetails.length > 10 ? (Math.ceil(props.postDetails.length / 10) * 10) : 10
      );
  };

  const showNextPostData = async () =>{
   if(!disabledLoadMore){
    setDisabledLoadMore(true);
    let newpage = postPage + 1;
    setPostPage(newpage);
    setPostPageLimit(postPageLimit);
    await props.getpostloadmore(
      props.workspaceFocused._id,
      props.userId,
      props.accesstokenidp,
      newpage,
      postPageLimit,
      props.postDetails
    ).then(response => {
      setDisabledLoadMore(false);
    });
   }
  }

  const displayMedia = post => {
    if (post && post.media[0].type === "image") {
      return (
        <img
          src={post.media[0].url}
          alt="post-media"
          height="100%"
          width="100%"
        />
      );
    } else if (post && post.media[0].type === "video") {
      return (
        <video height="100%" width="100%" controls>
          <source src={post.media[0].url} />
        </video>
      );
    }
  };

  const getCreatedTime = updatedDate => {
    const now = moment();
    const created = moment(updatedDate);
    const diff = created.diff(now);
    return moment.duration(diff).humanize(true);
  };

  const onClickOfLike = postId => {
    props
      .handleLikeorDisLike(postId, props.userId, "like", props.accesstokenidp)
      .then(response => {
        if (response.status) {
          setLikeColor(!likeColor);
        }
        props.onClicklikeorDislikeData(response.data.dislike,response.data.like,postId,props.postDetails);
      });
  };

  const onClickOfDislike = postId => {
    props
      .handleLikeorDisLike(
        postId,
        props.userId,
        "dislike",
        props.accesstokenidp
      )
      .then(response => {
        if (response.status) {
          setDislikeColor(!dislikeColor);
        }
        props.onClicklikeorDislikeData(response.data.dislike,response.data.like,postId,props.postDetails);
      });
  };

  const closeAddTextModal = () => {
    setShowModalAddText(false);
    setPostData(null);
  }

  const editPostClicked = data => {
    if(data.media) {
      setShowModalAddpost(true);
      setEditImage(data.media[0].url);
      setEditType(data.media[0].type);
    } else {
      setShowModalAddText(true);
    }
    setPostData(data);
    props.onpostPushData(
      props.workspaceFocused._id,
      props.userId,
      props.accesstokenidp,
      1,
      props.postDetails.length > 10 ? (Math.ceil(props.postDetails.length / 10) * 10) : 10
    );
  };

  const deletePostClicked = data => {
    setShowDeletePost(true);
    setPostData(data);
    props.onpostPushData(
      props.workspaceFocused._id,
      props.userId,
      props.accesstokenidp,
      1,
      props.postDetails.length > 10 ? (Math.ceil(props.postDetails.length / 10) * 10) : 10
      );
  };

  return (
    <Row>
      <Addpost
        showModalAddpost={showModalAddpost}
        editData={postData}
        data={editImage}
        type={editType}
        onCloseAddpost={closeAddpostsEditImage}
      />
      <DeletePost 
        showDeletePost={showDeletePost}
        selectedData= {postData}
        closeDeletePost={closeDeletePost}
        PostViewData={postUpdatedViewData}
      />
      {props.workspaceFocused.roleId ===
      roles.ADMIN ? (
        <Col
          xs={12}
          sm={12}
          lg={12}
          md={12}
          xl={12}
          className="add-new-post-col"
        >
          <Spinnerpost />
          <AddText
            editData={postData}
            type="text"
            showModalAddText={showModalAddText}
            onCloseAddText={closeAddTextModal}
            PostViewData={postUpdatedAddEditViewData}
          />
          <div className="add-new-post-continer">
            <div className="add-new-post-text">
              {props.dictionary.s("sharephotosandvideoswithyourcolleagues")}
            </div>
            <div className="add-new-post-button-div">
              <Button
                className="add-new-post-button"
                onClick={() => {
                  setShowModalAddText(true);
                }}
              >
                {props.dictionary.s("addNewPost")}
              </Button>
            </div>
          </div>
        </Col>
      ) : null}
      {!props.loading &&
        props.postDetails &&
        props.postDetails.length > 0 &&
        props.postDetails.map(post => {
          return (
            <Col
              xs={12}
              sm={12}
              lg={12}
              md={12}
              xl={12}
              className="add-new-post-col"
              key={post._id + post.updatedDate}
            >
              <div className="add-new-post-div view-post-div">
                <div className="post-header">
                  <div className="post-header-left col-11">
                    <img
                      className="rounded-circle mr-2"
                      width="32px"
                      height="32px"
                      src={post.userPicture}
                      alt="user pic"
                    />
                    <div className="post-header-text">
                      <div className="post-user-name">
                        {post.userFirstName + " " + post.userLastName}
                      </div>
                      <div className="post-space-name">
                        {post.subspaces.map(subspace => (
                          <div className="post-space-style" key={subspace._id}>
                            {subspace.name}
                          </div>
                        ))}
                        <span className="post-time">
                          {getCreatedTime(post.updatedDate)}
                        </span>
                      </div>
                    </div>
                  </div>
                  {props.workspaceFocused.roleId ===
                  roles.ADMIN ? (
                    <div className="post-header-right col-1">
                      <PostToggleMenu
                        onDropDownClick={() => {}}
                        id={post._id}
                        dropDownList={[
                          {
                            name: "EditPost",
                            onDropDownItemClick: () => editPostClicked(post)
                          },
                          {
                            name: "DeletePost",
                            onDropDownItemClick: () => deletePostClicked(post)
                          }
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="post-body">{post.media? displayMedia(post) : null}</div>
                <div className="post-footer">
                <div className="post-caption-style">{post.caption}</div>
                  <div className="post-icons">
                    <span className="icon-space">
                      {post.like.users.includes(props.userId) ? (
                        <LikeBlueIcon onClick={() => onClickOfLike(post._id)} />
                      ) : (
                        <LikeIcon onClick={() => onClickOfLike(post._id)} />
                      )}
                      <div className="post-count-style">
                        {post.like.likesCount > 0 ? post.like.likesCount : null}
                      </div>
                    </span>
                    <span className="icon-space">
                      {post.dislike.users.includes(props.userId) ? (
                        <DisLikeBlueIcon
                          onClick={() => onClickOfDislike(post._id)}
                        />
                      ) : (
                        <DisLikeIcon
                          onClick={() => onClickOfDislike(post._id)}
                        />
                      )}
                      <div className="post-count-style">
                        {post.dislike.dislikesCount > 0
                          ? post.dislike.dislikesCount
                          : null}
                      </div>
                    </span>
                    {/* 
                    comment feature is hidden for this sprint
                    <span className="icon-space">
                      <CommentIcon />
                      <div className="post-count-style">{post.commentsCount > 0 ? post.commentsCount : null }</div>
                    </span> */}
                  </div>
                  {/* comment feature will be hidden for this sprint
                  
                  <div className="view-comment-text">View all 6 comments</div>
                  <div className="user-comment-section">
                    <img
                      className="rounded-circle mr-2"
                      width="32px"
                      height="32px"
                      src={props.userProfile}
                      alt="user pic"
                    />
                    <InputText 
                      className="form-control post-text-field"
                      placeholder="Add a comment"
                    />
                    <Button className="post-button-style" disabled>POST</Button>
                  </div> */}
                </div>
              </div>
            </Col>
          );
        })}
      {!props.loading && !props.loadingmore &&
        props.postDetails &&
        props.postDetails.length > 0 && props.hasNextPage ?  
      <div className="postloadmore"> 
      <Button className="add-new-post-button loadmore" onClick={()=> 
        showNextPostData()
        }> {props.dictionary.s("loadmore")}</Button> 
      </div>  
      : !props.loading && !props.loadingmore &&
      props.postDetails &&
      props.postDetails.length > 0 && !props.hasNextPage ? 
      <div className="postloadmore lastpagethatsAll"> 
       {props.dictionary.s("thatsAll")}
      </div> : null 
      }
      {props.loading ? (
        <Col xs={12} sm={12} lg={8} md={12} xl={8} className="add-new-post-col">
          <div className="loader-container-spinner">
            <Col className="spinner-loader">
              <div className="spinner-border" role="status"></div>
            </Col>
          </div>
        </Col>
      ) : null}
      {!props.loading && props.postDetails && props.postDetails.length === 0 ? (
        <Col xs={12} sm={12} lg={8} md={12} xl={8} className="add-new-post-col">
          <div
            className="label-div"
            style={{
              marginHorizontal: 20,
              marginVertical: 10,
              textAlign: "center",
              color: general.theme.redColor,
              marginTop: 20,
              fontSize: 18
            }}
          >
            <label>{props.dictionary.s("youhavenopostsyet")}</label>
          </div>
        </Col>
      ) : null}
    </Row>
  );
};

const mapStateToProps = state => {
  const user = state.user;
  return {
    userProfile: user.picture,
    userId: user.id,
    accesstokenidp: state.user.accesstokenidp,
    workspaceFocused: state.workspace.spaceFocused,
    postDetails: state.posts.postData,
    hasNextPage: state.posts.hasNextPage,
    loading: state.posts.loading,
    lang: state.language.lang,
    loadingmore:state.posts.loadingmore,
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    }
  };
};

const mapDispatchToProps = dispatch => ({
  getPostViewData: (workspaceId, userId, accesstokenidp, pageNo, limit) =>
    dispatch(postViewData(workspaceId, userId, accesstokenidp, pageNo, limit)),
  handleLikeorDisLike: (postId, userId, type, accesstokenidp) =>
    dispatch(likeOrDislikeHandler(postId, userId, type, accesstokenidp)),
   onpostPushData: (workspaceId, userId, accesstokenidp, pageNo, limit) =>
    dispatch(postPushData(workspaceId, userId, accesstokenidp, pageNo, limit)),
    getpostloadmore: (workspaceId, userId, accesstokenidp, pageNo, limit, beforeData) =>
    dispatch(postloadmore(workspaceId, userId, accesstokenidp, pageNo, limit, beforeData)),    
    onClicklikeorDislikeData: (dislike, like, postId, postDetails) =>
    dispatch(likeorDislikeData(dislike, like, postId, postDetails)), 
});

export default connect(mapStateToProps, mapDispatchToProps)(Post);
