import React from "react";
import { ReactComponent as DefaultUser } from "../../../images/svg/DefaultUser.svg"

const Avatar = ({ src, styles, className, width, height }) => {
  return (
    !src ? (
      <DefaultUser
        style={styles}
        className={className}
        width={width}
        height={height}
      />) : (
        <img
          alt="avatar"
          src={src}
          style={styles}
          className={className}
          width={width}
          height={height}
        />)
  );
};

Avatar.defaultProps = {
  width: '160px',
  height: '160px',
};

export default Avatar;