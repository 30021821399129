import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import MainNavigation from "../Navigation/MainNavigation";
import MyBarcodeScanner from "../commons/MyBarcodeScanner";

const ScanUserID = (props) => {
  const [visibleScanner, setVisibleScanner] = useState(true);
  const closeQrScannerVisible = () => {
    setVisibleScanner(false);
    props.setQrScannerVisible(false);
  };
 
  return (
    <MainNavigation>
      <Container fluid>
        <Row>
        <MyBarcodeScanner
         visibleScanner = {visibleScanner}
         closeQrScannerVisible = {closeQrScannerVisible}
       />
        </Row>
      </Container>
    </MainNavigation>
  );
};

export default ScanUserID;