import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { translate } from '../../../redux/reducers/languageReducer';

const MeasuringBar = (props) => {
    const { used, measure, total, dictionary } = props;
    const percent = 100 / total * used;
    let color = 'success';

    if (percent > 85) color = 'danger';
    else if (percent > 65) color = 'warning';
    // else if (percent > 25) color = 'success';

    return (
        <div className="measuringBar">
            <span style={{ fontWeight: 600 }}>
                {`${used} ${dictionary.s(measure)} ${dictionary.s('of')} ${total}`}
            </span>
            <ProgressBar
                now={percent}
                style={{ borderRadius: '.50rem' }}
                variant={color}
            />
        </div>
    );
}

MeasuringBar.defaultProps = {
    total: 100,
    used: 50,
    measure: 'users',
}

const mapStateToProps = (state) => {
    return {
        dictionary: {
            s: key => translate(state.language.dictionary, key)
        }
    }
}

export default connect(mapStateToProps, null)(MeasuringBar);