import React, { useState } from "react";
import {
  userLeaveSpace
} from "../../redux/actions/workspaceActions";
import { connect } from "react-redux";
import { general } from "../../general/global";
import { translate } from "../../redux/reducers/languageReducer";
import Modal from "../commons/wrapped-components/Modal";
import { toastSuccess, toastDismissAll } from "../commons/Toast";
import { withRouter } from "react-router-dom";

const LeaveSpace = props => {
  const [clickSaveButton, setClickSaveButton] = useState(false);

  return (
    <Modal
      title={ props.dictionary.s("leaveSpaceQus") +
            " " +
            props.workspaceFocused.name +
            "?"}
      dialogClassName="safely-modal renderModalDeleteSpace"
      showModal={props.showLeaveSpace}
      onHide={() => {     
        props.closeLeaveSpace();
      }}
      onSave={ async () => {
        if (!clickSaveButton) {
          toastDismissAll();
          setClickSaveButton(true);
          await props
          .onUserLeaveSpace(
            props.workspaceFocused._id,
            props.userId,
            props.accesstokenidp,
          )
          .then(responceData => {
            setClickSaveButton(false);
            props.closeLeaveSpace();
            props.history.push("/workspacelist");
            toastSuccess(props.dictionary.s("leavespaceUpdated"));
          }); 
        }
       }}
      onClose={ () => {    
        props.closeLeaveSpace();
      }}
      textButtonSave={
        clickSaveButton
          ? props.dictionary.s("loading") + " ..."
          : props.dictionary.s("leave")
      }
      textButtonClose={props.dictionary.s("cancel")}
      propertyButtonSave={{
        block: false,
        style: {
          backgroundColor:"#E67070" ,
          color:general.theme.whiteFontColor,
          border:`1px solid #E67070`,
          width:'130px'
        }
      }}
      bsStyleButtonSave="secondary"
      buttonCloseProperties={{
        block: false,
        style: {
          backgroundColor: general.theme.whiteColor,
          border: `1px solid ${general.theme.primaryColor}`,
          color: general.theme.primaryColor,
          width:'130px'
        }
      }}
      properties={{
        centered: true
      }}
      body={
                <div className="deleteSubSpaceText">
                  <div>
                    {props.dictionary.s("leaveSpaceText1")}
                    <b> '{props.workspaceFocused.name}'</b>.
                  </div>
                  <div>{props.dictionary.s("areyousureyouwanttocontinue")}</div>
                </div>
          }
    />
  );
};

const mapStateToProps = state => {
  const user = state.user;
  return {
    userId: user.id,
    accesstokenidp: state.user.accesstokenidp,
    workSpacesProps:state.workspace,
    lang: state.language.lang,
    workspaceFocused: state.workspace.spaceFocused,
    dictionary: {
      s: key => translate(state.language.dictionary, key)
    }
  };
};

const mapDispatchToProps = dispatch => ({
  onUserLeaveSpace: (workSpaceId,userId,accesstokenidp) =>
  dispatch(userLeaveSpace(workSpaceId,userId,accesstokenidp)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LeaveSpace));
